import { Component } from '@angular/core';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { Translate2Module } from '@common/translations/translate2.module';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { NativeAppConstants } from '@shared/native-app/constants/native-app.constants';
import { PlatformService } from '@shared/platform/service/platform.service';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';

@Component({
  selector: 'auk-old-native-app-update-required-dialog',
  templateUrl: './old-native-app-update-required-dialog.component.html',
  standalone: true,
  imports: [
    Translate2Module,
    ButtonComponent,
    InnerHtmlDirective,
    LinkFromHrefDirective,
  ],
})
export class OldNativeAppUpdateRequiredDialogComponent {

  private isAndroid: boolean = this.platformService.isOldAndroidNativeApp;

  constructor(
    private readonly platformService: PlatformService,
  ) {
  }

  protected get isCapacitorNativeApp(): boolean {
    return PlatformCommonService.isNativeApp;
  }

  protected updateLink: string = this.isAndroid ? NativeAppConstants.GOOGLE_PLAY_LINK : NativeAppConstants.APP_STORE_LINK;
  protected updateLinkCapacitor: string = PlatformCommonService.isNativeAndroidApp
    ? NativeAppConstants.GOOGLE_PLAY_LINK : NativeAppConstants.APP_STORE_LINK;

}
