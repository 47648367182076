import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Translate2Module } from '@common/translations/translate2.module';
import { SearchCategoryModel } from '@shared/app-header/module/app-header-search/model/search-category.model';
import { AukFormsModule } from '@common/auk-forms/auk-forms.module';
import { StringUtils } from '@util/util/string.utils';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Component({
  selector: 'auk-search-categories-list',
  templateUrl: './search-categories-list.component.html',
  styleUrl: './search-categories-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    Translate2Module,
    AukFormsModule,
  ],
})
export class SearchCategoriesListComponent extends NgUnsubscribe implements OnChanges, AfterViewInit {

  @ViewChild('categorySelect', { read: ElementRef }) protected selectRef: ElementRef<HTMLSelectElement>;

  @Input() public categories: SearchCategoryModel[] = [];
  @Input() public selectedCategory: SearchCategoryModel;
  @Input() public fitToContent: boolean = false;

  @Output() public categoryChange: EventEmitter<SearchCategoryModel> = new EventEmitter<SearchCategoryModel>();

  protected selectWidth: number;

  constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly renderer2: Renderer2,
    protected readonly platformCommonService: PlatformCommonService,
    protected readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<SearchCategoriesListComponent>): void {
    if (changes.categories) {
      this.changeDetectorRef.detectChanges();

      this.ngZoneUtilService.simpleTimerOut$(
        () => this.fitSelectToContent(),
        this.ngUnsubscribe,
      );
    }
  }

  protected trackByCategoryId(index: number, category: SearchCategoryModel): number {
    return category?.categoryId || 0;
  }

  public ngAfterViewInit(): void {
    this.fitSelectToContent();
  }

  protected fitSelectToContent(): void {
    if (!this.selectRef?.nativeElement || !this.fitToContent || this.platformCommonService.isServer) {
      return;
    }

    // Get the content of the selected option
    const selectElement = this.selectRef.nativeElement;
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedOptionText = selectedOption?.text;

    if (StringUtils.isEmpty(selectedOptionText)) {
      return;
    }

    // Create a temporary select element with just one option to calculate width
    const auxiliarySelect = this.renderer2.createElement('select') as HTMLSelectElement;
    const auxiliaryOption = this.renderer2.createElement('option') as HTMLOptionElement;

    this.renderer2.setStyle(auxiliarySelect, 'width', 'auto');
    this.renderer2.setStyle(auxiliarySelect, 'padding-right', '2.25rem');

    this.renderer2.appendChild(auxiliarySelect, auxiliaryOption);
    this.renderer2.setProperty(auxiliaryOption, 'textContent', selectedOptionText);

    // Add child to dom for calculation
    this.renderer2.appendChild(selectElement.parentNode, auxiliarySelect);

    this.renderer2.setStyle(selectElement, 'width', `${ auxiliarySelect.offsetWidth }px`);

    // Remove child from dom
    this.renderer2.removeChild(selectElement.parentNode, auxiliarySelect);

    // Force change detection to ensure the DOM is updated
    this.changeDetectorRef.markForCheck();

  }

  protected onCategorySelected(data: SearchCategoryModel): void {
    this.fitSelectToContent();
    this.categoryChange.emit(data);
  }

}
