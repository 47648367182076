import { UserTaskPersonalDataStepResolutionHandlerService } from './user-task-personal-data-step-resolution-handler.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserTaskPersonalDataStepNoPhoneVerifiedNoEmailVerifiedResolutionHandlerService extends
  UserTaskPersonalDataStepResolutionHandlerService<'NO_PHONE_VERIFIED_NO_EMAIL_VERIFIED'> {

  public handle(): null {
    return null;
  }

}
