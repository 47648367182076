import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener, Inject,
  Input,
} from '@angular/core';
import Debounce from 'debounce-decorator';

import { BrowserService } from '@shared/platform/browser.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'auk-app-back-to-top-button',
  styleUrls: ['./app-back-to-top-btn.component.scss'],
  templateUrl: './app-back-to-top-btn.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBackToTopBtnComponent implements AfterViewInit {

  @Input() public isHiddenOnTablet: boolean;

  public showButton: boolean = false;
  // after scroll down to limit show button, input pixels
  public readonly SCROLL_LIMIT: number = 200;

  constructor(private readonly browserService: BrowserService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly platformService: PlatformService,
              @Inject(DOCUMENT) private readonly document: Document) {
  }

  @HostListener('window:scroll.silent')
  @Debounce(200)
  private onWindowScroll(): void {
    this.checkScrollLimitAndShowScrollButton();
    this.hideButtonIfMessageInputIsFocused();
  }

  @HostListener('document:focusin')
  public onFocus(): void {
    this.hideButtonIfMessageInputIsFocused();
  }

  @HostListener('document:focusout')
  public onFocusout(): void {
    this.checkScrollLimitAndShowScrollButton();
  }

  public ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
  }

  public scrollToTopWindow(): void {
    this.browserService.smoothScroll(0, 1000);
  }

  private checkScrollLimitAndShowScrollButton(): void {
    const showButton: boolean = this.SCROLL_LIMIT < this.browserService.getScrollTop();
    if (showButton !== this.showButton) {
      this.showButton = showButton;
      this.changeDetectorRef.detectChanges();
    }
  }

  private hideButtonIfMessageInputIsFocused(): void {
    const isMessageFormInputFocused = this.document.activeElement?.id === 'message-form';
    if (isMessageFormInputFocused) {
      this.showButton = false;
      this.changeDetectorRef.detectChanges();
    }
  }

}
