import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { PopoverCloseIconDirective } from './popover-close-icon.directive';
import { PopoverContainerDirective } from './popover-container.directive';
import { PopoverToggleDirective } from './popover-toggle.directive';
import { PopoverDirective } from './popover.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    PopoverDirective,
    PopoverContainerDirective,
    PopoverToggleDirective,
    PopoverCloseIconDirective,
  ],
  exports: [
    PopoverDirective,
    PopoverContainerDirective,
    PopoverToggleDirective,
    PopoverCloseIconDirective,
  ],
})
export class PopoverModule {

  public static forRoot(): ModuleWithProviders<PopoverModule> {
    return {
      ngModule: PopoverModule,
      providers: [],
    };
  }

}
