import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserTaskStepType } from '../model/user-task-step.type';
import { UserTaskType } from '../model/user-task.type';
import { UserTaskStepResolutionUnionModel } from '../model/user-task-step-resolution-union.model';
import { UserTaskStepPayloadModel } from '../model/user-task-step-payload.model';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Component({
  template: '',
})
export abstract class UserTaskStepComponent<TASK_TYPE extends UserTaskType, STEP_TYPE extends UserTaskStepType<TASK_TYPE>>
  extends NgUnsubscribe {

  private readonly taskType: TASK_TYPE;

  private readonly stepType: STEP_TYPE;

  @Input()
  public payload: UserTaskStepPayloadModel<TASK_TYPE, STEP_TYPE>;

  @Output()
  public resolve: EventEmitter<UserTaskStepResolutionUnionModel<TASK_TYPE, STEP_TYPE>> = new EventEmitter();

}
