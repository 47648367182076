import { UserTaskExecutorService } from '../../base/service/user-task-executor.service';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';
import { UserTaskStepDefinitionModel } from '../../base/model/user-task-step-definition.model';
import { UserTaskStepType } from '../../base/model/user-task-step.type';
import { Injectable, Injector } from '@angular/core';
import { ModalViewsConfigService } from '../../base/service/modal-views-config.service';
import { Observable, of } from 'rxjs';
import {
  UserTaskVerifiedPhoneStepNoPhoneVerifiedComponent,
} from '../component/user-task-verified-phone-step-no-phone-verified.component';
import {
  UserTaskVerifiedPhoneStepNoPhoneVerifiedResolutionHandlerService,
} from './user-task-verified-phone-step-no-phone-verified-resolution-handler.service';
import { PopupFlowGaService } from '@shared/popup-flow/service/popup-flow-ga.service';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';

@Injectable({
  providedIn: 'root',
})
export class UserTaskVerifiedPhoneExecutorService extends UserTaskExecutorService<'VERIFIED_PHONE'> {

  protected taskType: 'VERIFIED_PHONE' = 'VERIFIED_PHONE' as const;

  protected steps: { [STEP_TYPE in UserTaskStepType<'VERIFIED_PHONE'>]: UserTaskStepDefinitionModel<'VERIFIED_PHONE', STEP_TYPE> } = {
    NO_PHONE_VERIFIED: {
      component: UserTaskVerifiedPhoneStepNoPhoneVerifiedComponent,
      resolutionHandler: UserTaskVerifiedPhoneStepNoPhoneVerifiedResolutionHandlerService,
    },
  };

  constructor(
    injector: Injector,
    modalViewsConfigService: ModalViewsConfigService,
    popupFlowGaService: PopupFlowGaService,
    aukMatDialogService: AukMatDialogService,
  ) {
    super(injector, modalViewsConfigService, popupFlowGaService, aukMatDialogService);
  }

  protected getFirstStep$(payload: UserTaskPayloadModel<'VERIFIED_PHONE'>): Observable<UserTaskStepUnionModel<'VERIFIED_PHONE'>> {

    return of({
      type: 'NO_PHONE_VERIFIED',
      payload: {
        ...payload?.stepsText?.NO_PHONE_VERIFIED,
      },
    });
  }

}
