import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FavouriteEmptyModel } from '@shared/favourite/model/favourite-empty.model';

@Component({
  selector: 'auk-favourite-popover-empty-tab-content',
  templateUrl: './favourite-popover-empty-tab-content.component.html',
  styleUrls: ['./favourite-popover-empty-tab-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouritePopoverEmptyTabContentComponent {

  @Input() public dataLoading: boolean = false;
  @Input() public emptyPopoverContent: FavouriteEmptyModel;

}
