import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import DOMPurify from 'dompurify';
import { takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { JsonLdScriptEntry, JsonLdType } from '../service/json-ld.helpers';
import { JsonLdService } from '../service/json-ld.service';
import { ArrayUtils } from '@util/util/array.utils';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

interface LuigisBox {
  Scan: (selector: string) => void;
}

declare let Luigis: LuigisBox;

const LUIGIS_BOX_SCAN_DELAY: number = 250;

@Component({
  selector: 'auk-json-ld',
  templateUrl: './json-ld.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JsonLdComponent extends NgUnsubscribe implements AfterViewInit {

  public scriptsHtml: string = '';

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly jsonLdService: JsonLdService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly platformCommonService: PlatformCommonService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.changeDetectorRef.detach();

    if (this.platformCommonService.isServer) {
      return;
    }

    this.initRedrawTrigger();
  }

  private initRedrawTrigger(): void {
    this.jsonLdService.redrawTrigger
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((key) => {

        this.scriptsHtml = this.generateScriptsHtml(this.jsonLdService.getScriptTags());
        this.changeDetectorRef.detectChanges();

        if (
          typeof Luigis !== 'undefined'
          && typeof Luigis.Scan === 'function'
          && (['LISTING', 'SUGGESTIONS'] as JsonLdType[]).indexOf(key) !== -1
        ) {
          this.ngZoneUtilService.simpleTimerOut$(
            () => (Luigis).Scan('#jsonld-' + key),
            this.ngUnsubscribe,
            LUIGIS_BOX_SCAN_DELAY,
          );
        }
      });
  }

  private generateScriptsHtml(entries: JsonLdScriptEntry[]): string {
    if (ArrayUtils.isEmpty(entries)) {
      return '';
    }

    return entries.map(e => this.getScriptTag(e)).join('');
  }

  /**
   * Creates a safe script tag and sanitizes the value. Angular DomSanitizer cannot be used for this,
   * because it also escapes all URL-unsafe characters, and it cannot be disabled.
   * DOMPurify removes any potentially harmful tags, but keeps the rest intact.
   * @param entry
   */
  private getScriptTag(entry: JsonLdScriptEntry): string {
    const sanitizedValue: string = DOMPurify.sanitize(entry.value);
    return `<script type="application/ld+json" id="${ entry.id }">${ sanitizedValue }</script>`;
  }

}
