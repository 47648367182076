import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy, OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TabMenuItemComponent } from '../../tab-menu-item/component/tab-menu-item.component';
import { TabMenuItemModel } from '../../tab-menu-item/model/tab-menu-item.model';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import isEmpty from 'lodash-es/isEmpty';
import { takeUntil } from 'rxjs';
import isNil from 'lodash-es/isNil';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Component({
  selector: 'auk-tab-menu-container',
  templateUrl: './tab-menu-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TabMenuItemComponent,
  ],
})
export class TabMenuContainerComponent<T> extends NgUnsubscribe implements OnChanges, OnDestroy, OnInit {

  @Input() public tabs: TabMenuItemModel<T>[] = [];
  @Input() public allowTrimTabs: boolean = false;
  @Input() public disableLeftBorder: boolean = false;

  @Output() public tabClick: EventEmitter<T> = new EventEmitter<T>();
  @Output() public tabHover: EventEmitter<TabMenuItemModel<T>> = new EventEmitter<TabMenuItemModel<T>>();
  @Output() public tabHoverEnd: EventEmitter<TabMenuItemModel<T>> = new EventEmitter<TabMenuItemModel<T>>();

  @ViewChild('tabMenuContainer') protected readonly tabMenuContainer: ElementRef<HTMLDivElement>;

  @ViewChildren('tabMenu', { read: ElementRef }) protected readonly tabMenuList: QueryList<ElementRef<HTMLElement>>;

  protected trimmedTabs: TabMenuItemModel<T>[];

  public isMdAndLower: boolean = false;
  private tabMenuContainerResizeObserver: ResizeObserver;
  private visibleTabs: TabMenuItemModel<T>[];

  constructor(
    private readonly responsivenessService: ResponsivenessService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly platformCommonService: PlatformCommonService,
  ) {
    super();

  }

  public ngOnInit(): void {
    this.responsivenessService.activeBreakpoint$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.isMdAndLower = this.responsivenessService.isMdAndLower;
        this.prepareData();
        this.changeDetectorRef.markForCheck();
      });
  }

  public ngOnChanges(changes: AukSimpleChanges<TabMenuContainerComponent<T>>): void {
    if (changes.tabs) {
      this.prepareData();
    }
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.tabMenuContainerResizeObserver?.disconnect();
  }

  private prepareData(): void {
    if (isEmpty(this.tabs)) {
      return;
    }

    this.visibleTabs = this.tabs
      .filter((tab) => this.responsivenessService.isActiveWidthType(tab.visible));

    this.trimmedTabs = this.visibleTabs;

    this.changeDetectorRef.detectChanges();

    if (this.allowTrimTabs) {
      this.showOnlyVisible();
      this.createResizeObserver();
    }
  }

  private createResizeObserver(): void {
    if (this.platformCommonService.isServer) {
      return;
    }

    this.tabMenuContainerResizeObserver = new ResizeObserver(() => {
      this.showOnlyVisible();
    });

    this.tabMenuContainerResizeObserver.observe(this.tabMenuContainer.nativeElement);
  }

  private showOnlyVisible(): void {
    this.trimmedTabs = [...this.visibleTabs];
    this.changeDetectorRef.detectChanges();

    let sumMenuWidth = 0;

    // Last item size - its always visible
    const lastItemWidth = this.tabMenuList.last?.nativeElement?.clientWidth;
    // Container width minus last item size
    const containerWidth = this.tabMenuContainer?.nativeElement?.clientWidth - lastItemWidth;

    if (isNil(lastItemWidth) || isNil(containerWidth)) {
      return;
    }

    const tabsToRemove: number[] = [];

    this.tabMenuList.toArray().forEach((tabMenu, index) => {
      sumMenuWidth += tabMenu.nativeElement?.clientWidth;

      // Skip the last item in the check
      if (index !== this.tabMenuList.length - 1 && sumMenuWidth > containerWidth) {
        tabsToRemove.push(index);
      }
    });

    //Trim tabs that are over the container
    this.trimmedTabs = this.trimmedTabs.filter((element, index) => !tabsToRemove.includes(index));

    this.changeDetectorRef.detectChanges();
  }

  protected onTabClick(data: T): void {
    this.tabClick.emit(data);
  }

  protected onTabHover(data: TabMenuItemModel<T>): void {
    this.tabHover.emit(data);
  }

  protected onTabHoverEnd(data: TabMenuItemModel<T>): void {
    this.tabHoverEnd.emit(data);
  }

}
