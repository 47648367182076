import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { Translate2Module } from '@common/translations/translate2.module';
import { TranslationSource } from '@common/translations/model/translation-source';

@Component({
  selector: 'auk-user-account-type-chip',
  templateUrl: './user-account-type-chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    Translate2Module,
  ],
})
export class UserAccountTypeChipComponent {

  @Input() public isBusinessAccount: boolean = false;
  @Input() public isBlocked: boolean = false;

  protected get chipColor(): string {
    if (this.isBlocked) {
      return 'tw-bg-silverstein-900';
    }
    if (this.isBusinessAccount) {
      return 'tw-bg-info';
    }
    return 'tw-bg-success';
  }

  protected get chipText(): TranslationSource {
    if (this.isBlocked) {
      return { key: 'AVATAR_BLOCKED_USER_TEXT' };
    }
    if (this.isBusinessAccount) {
      return { key: 'BUSINESS_ACCOUNT' };
    }
    return { key: 'PERSONAL_ACCOUNT' };
  }

}
