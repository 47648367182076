import { NgModule } from '@angular/core';

import { CurrencyApproximationComponent } from './currency-approximation.component';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipeModule } from '@shared/currency/pipe/currency-pipe.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [CurrencyApproximationComponent],
  exports: [CurrencyApproximationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CurrencyPipeModule,
  ],
})
export class CurrencyApproximationModule {

}
