import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonLdComponent } from './component/json-ld.component';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    InnerHtmlDirective,
  ],
  declarations: [
    JsonLdComponent,
  ],
  exports: [
    JsonLdComponent,
  ],
})
export class JsonLdModule {
}
