import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PopularSearchDataDto } from '@api/generated/defs/PopularSearchDataDto';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';

@Component({
  selector: 'auk-popular-search',
  templateUrl: './popular-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopularSearchComponent {

  @Input() public popularSearchData: PopularSearchDataDto;

  @Output() public popularSearchClick: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    protected responsivenessService: ResponsivenessService,
  ) {
  }

  protected onPopularSearchClick(index: number): void {
    this.popularSearchClick.emit(index);
  }

}
