import { NgModule } from '@angular/core';
import { CurrencyPipe } from './currency.pipe';
import { CurrencyCodePipe } from './currency-code.pipe';
import { CurrencyRoundPipe } from '@shared/currency/pipe/currency-round.pipe';

@NgModule({
  declarations: [
    CurrencyCodePipe,
  ],
  imports: [
    CurrencyRoundPipe,
    CurrencyPipe,
  ],
  exports: [
    CurrencyPipe,
    CurrencyCodePipe,
    CurrencyRoundPipe,
  ],
})
export class CurrencyPipeModule {
}
