import { DOCUMENT, Location as AngularLocation } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Event as RouterEvent, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { APP_CONSTANTS } from '@app-constants';
import isNil from 'lodash-es/isNil';
import includes from 'lodash-es/includes';
import { BehaviorSubject, merge, mergeWith, Observable, Subject } from 'rxjs';
import { filter, map, skip, take, takeUntil } from 'rxjs/operators';
import { PlatformService } from '@shared/platform/service/platform.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { ScrollPosition } from '@shared/platform/model/scroll-position.model';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { DomainService } from './domain.service';
import { UserAgentService } from '@shared/platform/service/user-agent.service';

@Injectable({
  providedIn: 'root',
})
export class BrowserService extends NgUnsubscribe {

  /** Whether page is scrolled to top (scroll Y is 0) ot not. Emit changes. */
  private _isScrolledUp$: Subject<boolean> = new Subject<boolean>();
  private _hasHistory$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _userAlreadyScrolled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(DOCUMENT) public readonly document: Document,
    @Inject(WINDOW_OBJECT) public readonly window: Window,
    private readonly platformService: PlatformService,
    private readonly router: Router,
    private readonly angularLocation: AngularLocation,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly domainService: DomainService,
    private readonly userAgentService: UserAgentService,
  ) {
    super();

    this.initHasHistory();

    if (this.platformService.isBrowser) {
      this.initUserAlreadyScrolledListener();
    }
  }

  public get href(): string {
    if (this.platformService.isBrowser) {
      return this.document.location.href;
    } else {
      return this.platformService.serverHref;
    }
  }

  public set href(newUrl: string) {
    this.document.location.href = newUrl;
  }

  public get hrefWithoutParams(): string {
    return this.getPathname(this.href);
  }

  public get baseUrl(): string {
    if (this.platformService.isBrowser) {
      if (PlatformCommonService.isNativeApp) {
        return 'https:'
          + '//' + this.domainService.hostname
          + (this.document.location.port ? (':' + this.document.location.port) : '')
          + APP_CONSTANTS.BASE_URL;
      }
      return this.document.location.protocol
        + '//' + this.document.location.hostname
        + (this.document.location.port ? (':' + this.document.location.port) : '')
        + APP_CONSTANTS.BASE_URL;
    } else {
      return this.platformService.serverHost + APP_CONSTANTS.BASE_URL;
    }
  }

  /**
   * @param url - absolute or relative URL
   * @returns full absolute URL
   * @example
   * ``` js
   * const relativeUrl: string = '/test';
   * HtmlUtilsService.getAbsoluteUrl(relativeUrl); // returns http://example.com/test
   * ```
   */
  public createAbsoluteUrl(url: string): string {
    const isAbsolute: boolean = new RegExp('^(?:[a-z]+:)?//', 'i').test(url);

    // Return input value without changes if URL is already absolute
    if (isAbsolute) {
      return url;
    }

    // Remove potential starting slash from a relative URL (because the base URL already has ending slash)
    const urlWithoutStartingSlash: string = url.startsWith('/') ? url.slice(1) : url;

    // Return full absolute URL
    return this.baseUrl + urlWithoutStartingSlash;
  }

  /**
   * Gets element coordinates relative to document
   * @param element HTMLElement
   */
  public getElementOffsetY(element: Element): number {
    return this.getCoords(element).top;
  }

  public getCoords(element: Element): { top: number; left: number } {
    const scroll: ScrollPosition = this.getScroll();
    const box = element.getBoundingClientRect();

    return {
      top: box.top + scroll.top,
      left: box.left + scroll.left,
    };
  }

  public getScrollTop(): number {
    return this.getScroll().top;
  }

  public getScroll(): ScrollPosition {
    return {
      top: this.window.pageYOffset ?? this.document.documentElement.scrollTop,
      left: this.window.pageXOffset ?? this.document.documentElement.scrollLeft,
    };
  }

  public resetScroll(): void {
    // Timeout fixes webkit bug that white page appears accidentally on backwards navigation
    this.ngZoneUtilService.simpleTimerOut$(
      () => {
        this.document.body.scrollTop = 0; // For Safari
        this.document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      },
      this.ngUnsubscribe,
    );
  }

  /**
   * @deprecated
   *
   * Use {@link ScrollService} scrollIntoViewSelector fn instead
   */
  public scrollToSelector(selector: string, options?: ScrollIntoViewOptions): void {
    if (typeof options === 'undefined') {
      options = {
        block: 'start',
        inline: 'nearest',
      };
    }

    const element = this.document.querySelector(selector);
    if (element) {
      element.scrollIntoView(options);
    }
  }

  /**
   * @deprecated
   * Use {@link ScrollService} scrollToSelector fn instead
   */
  public smoothScrollToSelector(selector: string, additionalOffset: number = 0, duration: number = 2000): void {
    const elem = this.document.querySelector(selector);
    this.smoothScrollToElement(elem, additionalOffset, duration);
  }

  /**
   * @deprecated
   *
   * Use {@link ScrollService} scrollToElement fn instead
   */
  public smoothScrollToElement(elem: Element, additionalOffset: number = 0, duration: number = 2000): void {
    if (isNil(elem)) {
      return;
    }

    const offset = this.getElementOffsetY(elem);
    this.smoothScroll(offset + additionalOffset, duration);
  }

  public smoothScroll(scrollTo: number, duration: number = 2000): void {
    /**
     * Emit a value when smoothScroll:
     * - was interrupted by user scroll
     * - completed
     */
    const interruption: Subject<void> = new Subject<void>();
    /**
     * Navigation keys, which should stop scrolling
     */
    const navigationKeys = [
      'ArrowDown',
      'ArrowUp',
      'End',
      'Home',
      'PageDown',
      'PageUp',
    ];
    const start = this.getScrollTop();
    const distance = scrollTo - start;
    /**
     * Holds a reference to a scheduled animationFrame
     */
    let animationFrame: number;
    let nextScrollTo: number;
    let timeStart: number;
    let timeElapsed: number;

    /**
     * Basic easing function
     * https://github.com/jaxgeller/ez.js
     * @param t
     * @param b
     * @param c
     * @param d
     */
    const easeInOutQuad = (t: number, b: number, c: number, d: number): number => {
      t /= d / 2;
      if (t < 1) {
        return c / 2 * t * t + b;
      }
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };

    /**
     * Dispose scrolling
     */
    const dispose = (): void => {
      interruption.next();
      interruption.complete();
    };

    /**
     * Perform the final scroll & complete
     */
    const end = (): void => {
      dispose();
      this.window.scrollTo(0, scrollTo);
    };

    /**
     * Recursive partial scroll
     * @param timeCurrent
     */
    const scroll = (timeCurrent: number): void => {
      // Set scrolling start time
      if (!timeStart) {
        timeStart = timeCurrent;
      }

      timeElapsed = timeCurrent - timeStart;
      nextScrollTo = easeInOutQuad(timeElapsed, start, distance, duration);

      this.window.scrollTo(0, nextScrollTo);

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      timeElapsed < duration ?
        animationFrame = this.window.requestAnimationFrame(scroll) :
        end();
    };

    const wheel$ = this.ngZoneUtilService.fromEventOut$(this.window, 'wheel');
    const navigationKeys$ = this.ngZoneUtilService.fromEventOut$(this.window, 'keydown')
      .pipe(
        filter((e) => includes(navigationKeys, e.key)),
      );

    // Handle any possible interruption from the user
    wheel$
      .pipe(
        mergeWith(navigationKeys$),
        takeUntil(merge(interruption, this.ngUnsubscribe)),
      )
      .subscribe(() => {
        this.window.cancelAnimationFrame(animationFrame);
        dispose();
      });

    // Start the scroll
    animationFrame = this.window.requestAnimationFrame(scroll);
  }

  public selectInputText(el: HTMLInputElement): void {
    el.select();
    // mobile Safari
    el.setSelectionRange(0, el.value.length);
  }

  public getSeoUrl(url?: string): string {
    return this.getPathname(url || this.document.location.pathname).substring(1);
  }

  public getPathname(url?: string): string {
    const pathName = (url || this.document.location.pathname)
      .replace(/;.*/gi, '')
      .replace(/\?.*/gi, '');

    return pathName[pathName.length - 1] === '/' ? pathName.slice(0, -1) : pathName;
  }

  public goToUrl(url: string): void {
    this.document.location.assign(url);
  }

  public openPopup(url: string, width: number = 640, height: number = 480): void {
    const popupWidth = Math.min(width, this.window.innerWidth);
    const popupHeight = Math.min(height, this.window.innerHeight);
    this.window.open(url, null, `width=${ popupWidth },height=${ popupHeight }`);
  }

  public getBrowserVersion(): { name: string; version: number } {
    if (this.platformService.isBrowser) {
      const ua = this.userAgentService.userAgent;
      if (ua.match(/FBAV|FBAN/i)) {
        // https://user-agents.net/applications/facebook-app
        return { name: 'Facebook', version: 0 };
      }
      if (ua.match(/BingWeb/i)) {
        //https://user-agents.net/applications/bing-search-app
        return { name: 'Bing', version: 0 };
      }
      let tem;
      let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      const mobile = ua.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i);
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
          return { name: tem[1].replace('OPR', 'Opera'), version: +tem[2] };
        }
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      tem = ua.match(/version\/(\d+)/i);
      if (tem != null) {
        M.splice(1, 1, tem[1]);
      }
      let browserName = M[0];
      if (mobile) {
        if (M[0] === 'Safari') {
          browserName = 'IOS';
        } else if (M[0] === 'Chrome') {
          browserName = 'Chrome Mobile';
        }
      }
      return { name: browserName, version: +M[1] };
    } else {
      return { name: 'Chrome', version: 63 };
    }
  }

  /**
   * If browser needs update, returns link for versions page, else returns empty string.
   */
  public getBrowserUpdateLink(): string {
    const supportedBrowsers = [
      { name: 'Chrome', version: 54, link: 'https://en.wikipedia.org/wiki/Google_Chrome_version_history' },
      { name: 'Chrome Mobile', version: 54, link: 'http://filehippo.com/download_google_chrome/history/4/' },
      { name: 'Firefox', version: 48, link: 'https://www.mozilla.org/en-US/firefox/releases/' },
      { name: 'Safari', version: 10, link: 'https://en.wikipedia.org/wiki/Safari_version_history' },
      { name: 'IOS', version: 10, link: 'https://en.wikipedia.org/wiki/IOS_version_history' },
      { name: 'EDGE', version: 14, link: 'https://www.microsoft.com/en-us/windows/microsoft-edge' },
    ];
    const currentBrowser = this.getBrowserVersion();
    const supportedBrowser = supportedBrowsers.find((sb) => sb.name === currentBrowser.name);
    if (!supportedBrowser) {
      return '';
    }
    return supportedBrowser.version > currentBrowser.version ? supportedBrowser.link : '';
  }

  /** Shows go back button after first navigation on page. */
  public initHasHistory(): void {
    this.router.events
      .pipe(
        filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationEnd),
        skip(1),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this._hasHistory$.next(true);
      });
  }

  /** Gets `hasHistory` observable. */
  public get hasHistory$(): Observable<boolean> {
    return this._hasHistory$.asObservable();
  }

  /** Gets `hasHistory` as boolean. */
  public get hasHistory(): boolean {
    return this._hasHistory$.value;
  }

  /**
   * Navigates back if browser has history, otherwise navigates to homepage
   * Use this when you need to navigate back instead of using location.back() directly
   */
  public navigateBack(): void {

    // if app has history, navigate back
    if (this.hasHistory) {
      // save current url
      const previousUrl = this.router.url;

      // navigate back
      this.angularLocation.back();

      const navigationStart$ = this.router.events
        .pipe(
          filter((routerEvent: RouterEvent) => routerEvent instanceof NavigationStart),
        );

      // there's case, where app is navigated, but the history is still empty,
      // so we need to also check if navigation starts, if not, navigate to homepage
      this.ngZoneUtilService.timerOut$(100)
        .pipe(
          take(1),
          takeUntil(navigationStart$),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => {
          // if current url is same as previous, it means that history is empty
          if (this.router.url === previousUrl) {
            // navigate to homepage
            void this.router.navigate(['/']);
          }
        });
    } else {
      // if app has no history, navigate to homepage
      void this.router.navigate(['/']);
    }
  }

  public get isScrolledUp$(): Observable<boolean> {
    return this._isScrolledUp$.asObservable();
  }

  public setIsScrolledUp$(isScrolledUp: boolean): void {
    this._isScrolledUp$.next(isScrolledUp);
  }

  private get viewportElement(): HTMLElement {
    return this.document.querySelector('meta[name=viewport]');
  }

  private setViewportScale(scaleString: 'maximum-scale' | 'minimum-scale', scale = '1.0'): void {
    if (!['maximum-scale', 'minimum-scale'].includes(scaleString)) {
      return;
    }
    const newScale = (scale === '' || scale === undefined) ? '' : `${ scaleString }=${ scale }`;
    const el: HTMLElement = this.viewportElement;
    if (!isNil(el)) {
      let content: string = el.getAttribute('content');
      const re = new RegExp(`${ scaleString }=[0-9.]+`);
      if (re.test(content)) {
        content = content.replace(re, newScale);
        content = content.replace(/(^,|,$|,,)/, '');
      } else {
        content = [content, newScale].join(',');
      }
      el.setAttribute('content', content);
    }
  }

  /**
   * @param scale string eg. '1.0', or empty string '' to remove from viewport
   *
   * maximum-scale=1.0 prevents autozoom on input element in Safari browser
   * https://stackoverflow.com/a/57527009
   */
  public setViewportMaximumScale(scale: string): void {
    this.setViewportScale('maximum-scale', scale);
  }

  /**
   * TRUE if user has already scrolled
   * FALSE until user has scrolled
   * Emits TRUE when user scroll more than 5 pixels from top for the first time (only once)
   * @returns
   */
  public userAlreadyScrolled$(): Observable<boolean> {
    return this._userAlreadyScrolled$.asObservable();
  }

  private initUserAlreadyScrolledListener(): void {
    const minScrollPx: number = 5;

    this.ngZoneUtilService.fromEventOut$(this.window, 'scroll')
      .pipe(
        map(() => this.getScroll().top),
        filter((scrollPositionTop: number) => scrollPositionTop >= minScrollPx),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this._userAlreadyScrolled$.next(true));
  }

  public clearQueryParamsByRouterNavigate(paramNames: string[], activatedRoute: ActivatedRoute): void {
    if (this.platformService.isServer) {
      return;
    }

    const queryParams = { ...activatedRoute.snapshot.queryParams };
    // check if query params are present, if not, then avoid navigate
    let avoidRouterNavigate = true;
    paramNames.forEach(param => {
      if (isNil(queryParams[param])) {
        return;
      }
      queryParams[param] = null;
      avoidRouterNavigate = false;
    });

    if (avoidRouterNavigate) {
      return;
    }

    void this.router.navigate(
      [],
      {
        relativeTo: activatedRoute,
        queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
  }

}
