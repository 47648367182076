<ng-container [formGroup]="basicRegistrationForm" aukValidationMessages>
  <!-- BUSINESS REGISTRATION TOGGLE -->
  <div class="tw-flex">
    <div class="tw-flex tw-items-center">
      <mat-slide-toggle class="tw-mt-4 tw-mb-4 tw-pr-3"
        [checked]="isBusinessRegistration" (change)="toggleBusinessRegistration()">
      </mat-slide-toggle>
      <div>
        <p>
          {{ 'BUSINESS_REGISTRATION_TOGGLE_LABEL' | translate }}
        </p>
      </div>
    </div>
  </div>
  <!-- ./BUSINESS REGISTRATION TOGGLE -->

  @if (isBusinessRegistration) {
    <!-- LEGAL FORM -->
    <div class="tw-flex">
      <mat-form-field class="tw-grow tw-mx-1" appearance="outline">
        <mat-label
          [aukInnerHtml]="{key: 'BUSINESS_REGISTRATION_INPUT_LABEL_LEGAL_FORM'}"
        ></mat-label>
        <mat-select formControlName="legalForm" (selectionChange)="legalFormChange()">
          @for (legalForm of legalForms; track trackByLegalFormId($index, legalForm)) {
            <mat-option
              [value]="legalForm.code">
              {{ legalForm.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <!-- ./LEGAL FORM -->
    <!-- COMPANY NAME -->
    @if (!isCompanyNameHidden) {
      <div class="tw-flex">
        <mat-form-field class="tw-grow tw-mx-1" appearance="outline">
          <mat-label
            [aukInnerHtml]="{key: 'BUSINESS_REGISTRATION_INPUT_LABEL_COMPANY_NAME'}"
          ></mat-label>
          <input formControlName="companyName"
            [placeholder]="{key: 'BUSINESS_REGISTRATION_INPUT_PLACEHOLDER_COMPANY_NAME'} | translateSource"
            aukInputTrim
            matInput
            >
          <auk-validation-message-legacy path="companyName"></auk-validation-message-legacy>
        </mat-form-field>
      </div>
    }
    <!-- ./COMPANY NAME -->
    <!-- REGISTRATION NUMBER -->
    <div class="tw-flex">
      <mat-form-field class="tw-grow tw-mx-1 tw-max-w-[40%]"
        appearance="outline">
        <mat-label
          [aukInnerHtml]="{key: 'BUSINESS_REGISTRATION_INPUT_LABEL_REG_NUMBER'}"
        ></mat-label>
        <input formControlName="regNumber"
          [aukLimitMaxLength]="19"
          [placeholder]="{key: 'BUSINESS_REGISTRATION_INPUT_LABEL_REG_NUMBER'} | translateSource"
          aukInputTrim
          matInput
          >
      </mat-form-field>
      <auk-alert-banner-legacy type="INFO">
        <div [aukInnerHtml]="{key: 'COMPANY_FORM_LABEL_TOOLTIP'}"></div>
      </auk-alert-banner-legacy>
    </div>
    <!-- ./REGISTRATION NUMBER -->
    <div class="tw-flex">
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-baseline tw-justify-between">
        <!-- VAT PAYER STATUS TOGGLE -->
        <div class="tw-flex tw-items-center">
          <mat-slide-toggle class="tw-mt-4 tw-mb-4 tw-pr-3" [checked]="isVatPayer"
            (change)="toggleVatPayer()">
            {{ 'BUSINESS_REGISTRATION_INPUT_LABEL_VAT_PAYER' | translate }}
          </mat-slide-toggle>
          <auk-hint [hintTooltip]="{key: 'BUSINESS_REGISTRATION_VAT_TOOLTIP_TEXT'}"
            tooltipPosition="top"
          ></auk-hint>
        </div>
        <!-- ./VAT PAYER STATUS TOGGLE -->
        <!-- VAT NUMBER -->
        @if (isVatPayer) {
          <mat-form-field
            class="tw-grow tw-mx-1 tw-max-w-[40%]"
            appearance="outline">
            <mat-label
              [aukInnerHtml]="{key: 'BUSINESS_REGISTRATION_INPUT_LABEL_VAT_REG_NUMBER'}"
            ></mat-label>
            <input formControlName="vatNumber"
              [aukLimitMaxLength]="20"
              [placeholder]="{key: 'BUSINESS_REGISTRATION_INPUT_LABEL_VAT_REG_NUMBER'} | translateSource"
              aukInputTrim
              matInput
              >
            <auk-validation-message-legacy path="vatNumber"></auk-validation-message-legacy>
          </mat-form-field>
        }
        <!-- ./VAT NUMBER -->
      </div>
    </div>
  }
</ng-container>
