import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
// eslint-disable-next-line import/no-restricted-paths
import { SentryService } from '@shared/services/logging/sentry.service';
import { firstValueFrom, Observable, race } from 'rxjs';
import { DateUtils } from '@util/util/date.utils';
import { Span, startSpan } from '@sentry/browser';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import moment, { Moment } from 'moment-mini-ts';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { RouteDataConstants } from '@common/routing/const/route-data.constants';

@Injectable({
  providedIn: 'root',
})
export class AppStateInitializerService extends NgUnsubscribe implements AppInitializer {

  private readonly MAX_TIME_TO_BECOME_STABLE_SEC: number = 10;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly applicationRef: ApplicationRef,
    private readonly sentryService: SentryService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly platformCommonService: PlatformCommonService,
  ) {
    super();
  }

  public init(): void {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        take(1),
        filter(() => this.appStateMonitoringEnabled),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.initApplicationIsStableMonitoring());
  }

  private initApplicationIsStableMonitoring(): void {
    // If browser tab is not focused when app is starting, Angular does not finish SSR hydration until user focuses the browser tab
    // That is why we skip all app stable monitoring it that case
    // But is tab lost focus later while app is starting, hydration should be finished properly
    // So we check only if the browser tab is focused when app is starting
    // If user is in Developer Tools, document.hasFocus will return false and will not function properly
    // Disable stable monitoring for iOS Application as it does not run async tasks outside ngZone
    if (this.platformCommonService.isServer || !this.document.hasFocus() || PlatformCommonService.isNativeIosApp) {
      return;
    }

    const appStable$: Observable<true> = this.applicationRef.isStable
      .pipe(
        filter((isStable: boolean) => isStable),
        take(1),
        // App became stable
        map(() => true),
      );

    if (PlatformCommonService.isDevMode) {
      const startTime: Moment = moment();
      appStable$
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => {
          console.debug(`Application has become stable in ${ moment().diff(startTime, 's') } seconds`);
        });
    }

    const maxTimeToBecameStable$: Observable<false> = this.getTimerForGivenSec$(this.MAX_TIME_TO_BECOME_STABLE_SEC)
      .pipe(
        // Limit exceeded - app has not become stable in time limit
        map(() => false),
      );

    const appBecomeStableInLimit$: Observable<boolean> = race(
      appStable$,
      maxTimeToBecameStable$,
    );

    // Init is not stable debugging in dev mode
    if (PlatformCommonService.isDevMode) {
      appBecomeStableInLimit$
        .pipe(
          take(1),
          filter((isStable: boolean) => !isStable),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => {
          console.error(`The app has not become stable in ${ this.MAX_TIME_TO_BECOME_STABLE_SEC } seconds. ` +
            'It could mean that app delays on some asynchronous tasks. If this continues, it should be investigated and fixed.');
        });
    }

    // Init time to stable measurement (it will be logging to sentry)
    if (this.sentryService.sentryEnabled) {
      void startSpan(
        {
          // 'Duration, after which app becomes stable (when appRef.isStable emits first time true) (in seconds)',
          name: 'APP_BECOME_STABLE',
          op: 'mark',
        },
        async(span: Span) =>
          firstValueFrom(
            appBecomeStableInLimit$
              .pipe(
                tap((value: boolean) => {
                  if (value === true) {
                    span.setStatus({ message: 'ok', code: 1 });
                  } else {
                    span.setStatus({ message: 'deadline_exceeded', code: 1 });
                  }
                }),
              ),
          ),
      );
    }
  }

  private get appStateMonitoringEnabled(): boolean {
    let route = this.activatedRoute;
    if (this.checkStableMonitoring(route)) {
      return true;
    }
    while (route.firstChild) {
      if (this.checkStableMonitoring(route)) {
        return true;
      }
      route = route.firstChild;
    }
    return false;
  }

  private checkStableMonitoring(route: ActivatedRoute): boolean {
    return route.snapshot.data[RouteDataConstants.APP_STABLE_MONITORING_ENABLED] === true;
  }

  private getTimerForGivenSec$(seconds: number): Observable<void> {
    return this.ngZoneUtilService.timerOut$(DateUtils.convertSecondsToMilliseconds(seconds));
  }

}
