import { ColorUtil } from '../util/color.util';
import { ColorName } from '../model/color-name';
import { ColorType } from '../model/color-type';
import { COLOR_NAMES } from './color-names';

export const COLORS: Record<ColorName, Record<ColorType, string>> = COLOR_NAMES
  .reduce((accColorNames, colorName) => ({
    ...accColorNames,
    [colorName]: ColorUtil.getColorTypeCssVars(colorName),
  }), {} as Record<ColorName, Record<ColorType, string>>);
