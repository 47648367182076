import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslationSource } from '@common/translations/model/translation-source';
import { Translate2Module } from '@common/translations/translate2.module';
import { Nil } from '@util/helper-types/nil';
import { NgClass } from '@angular/common';

@Component({
  selector: 'auk-label',
  templateUrl: './label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    Translate2Module,
    NgClass,
  ],
})
export class LabelComponent {

  @Input() public label: TranslationSource | Nil;
  @Input() public size: 'SMALL' | 'MEDIUM' = 'MEDIUM';

}
