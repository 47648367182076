import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { SubbrandType } from '@shared/subbrand/model/subbrand.type';
import { AppHeaderHamburgerService } from '@shared/app-header/service/app-header-hamburger.service';
import { CategoryDialogModel } from '@shared/app-header/component/category-dialog/model/category-dialog-model';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { debounceTime, filter, take, takeUntil } from 'rxjs/operators';
import { CategoryDialogContentComponent } from '@shared/app-header/component/category-dialog/category-dialog-content/category-dialog-content.component';
import isNil from 'lodash-es/isNil';
import { StopPropagationOnClickDirective } from '@shared/legacy/directive/stop-propagation/stop-propagation-on-click.directive';
import { mergeMap, Observable } from 'rxjs';
import { SpinnerComponent } from '@common/ui-kit/component/spinner/component/spinner.component';
import { Nil } from '@util/helper-types/nil';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { ScrollService } from '@common/scroll/service/scroll.service';

const DEFAULT_HAMBURGER_SUBBRAND: SubbrandType = 'BAZAAR';

@Component({
  selector: 'auk-category-dialog-modal',
  templateUrl: './category-dialog-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CategoryDialogContentComponent,
    StopPropagationOnClickDirective,
    SpinnerComponent,
  ],
})
export class CategoryDialogModalComponent extends NgUnsubscribe implements OnInit {

  protected isMdAndLower: boolean = false;
  protected categoryDialogModel: CategoryDialogModel | Nil = null;
  protected subbrand: SubbrandType = DEFAULT_HAMBURGER_SUBBRAND;
  protected isLoading: boolean = false;

  constructor(
    private readonly subbrandService: SubbrandService,
    private readonly appHeaderHamburgerService: AppHeaderHamburgerService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly scrollService: ScrollService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.responsivenessService.isMdAndLower$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMdAndLower) => this.isMdAndLower = isMdAndLower);

    // Show spinner when hamburger is opened for the first time
    this.appHeaderHamburgerService.hamburgerHasBeenOpened$()
      .pipe(
        filter((hasBeenOpened) => hasBeenOpened),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((hasBeenOpened => {
        this.isLoading = hasBeenOpened && isNil(this.categoryDialogModel);
        this.changeDetectorRef.detectChanges();
      }));

    this.appHeaderHamburgerService.hamburgerData$()
      .pipe(
        filter((data) => isNotNil(data)),
        // There's need for debounce to suppress emission on the same event loop tick. Spinner won't appear otherwise.
        debounceTime(0),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data) => {
        this.categoryDialogModel = data;
        this.changeDetectorRef.detectChanges();
      });

    this.subbrandService.sessionSubbrand$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((subbrand) => {
        this.subbrand = this.definedSubbrandOrHamburgerDefault(subbrand);
        this.changeDetectorRef.markForCheck();
      });

    // Keep correct subbrand as default when menu is opened, even after choosing other subbrand in menu before
    this.appHeaderHamburgerService.categoryMenuOpened$()
      .pipe(
        mergeMap(() => this.subbrandService.sessionSubbrand$),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((subbrand) => {
        this.subbrand = this.definedSubbrandOrHamburgerDefault(subbrand);
        this.scrollToTop();
      });
  }

  protected dialogContentInitialized(): void {
    this.isLoading = false;
    this.changeDetectorRef.detectChanges();
  }

  protected handleHamburgerDialogClose(): void {
    this.appHeaderHamburgerService.closeCategoryMenu();
  }

  protected categoryMenuOpened$(): Observable<boolean> {
    return this.appHeaderHamburgerService.categoryMenuOpened$();
  }

  protected subbrandSelected(subbrandType: SubbrandType): void {
    this.subbrand = subbrandType;
  }

  protected get hasCategoryData(): boolean {
    return isNotNil(this.categoryDialogModel);
  }

  private definedSubbrandOrHamburgerDefault(subbrand: SubbrandType): SubbrandType {
    return isNil(subbrand) || subbrand === 'NOT_SPECIFIED' ? DEFAULT_HAMBURGER_SUBBRAND : subbrand;
  }

  private scrollToTop(): void {
    this.scrollService.scrollIntoViewSelector('.header-dialog-content');
  }

}
