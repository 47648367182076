import { Injectable } from '@angular/core';
import { CurrencyEnumParams, CurrencyService } from '@api/generated/api/Currency';
import { ExchangeRatesDto } from '@api/generated/defs/ExchangeRatesDto';
import { Observable } from 'rxjs';
import { CacheService } from '@common/cache/service/cache.service';
import { CacheAware } from '@common/cache/model/cache-aware';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { CacheScope } from '@common/cache/model/cache-scope';
import { DateUtils } from '@util/util/date.utils';

@Injectable({
  providedIn: 'root',
})
export class ExchangeRatesService implements CacheAware {

  constructor(
    public readonly cacheService: CacheService,
    private readonly currencyService: CurrencyService,
  ) {
  }

  @Cacheable({
    cacheScope: CacheScope.PROCESS,
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(10),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(10),
    key: 'ExchangeRatesService#exchangeRates',
  })
  public exchangeRates(currency: CurrencyEnumParams): Observable<ExchangeRatesDto> {
    return this.currencyService.exchangeRates(currency);
  }

}
