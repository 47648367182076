import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { UserService } from '@shared/user/service/user.service';

export const registrationGuard: CanActivateFn =
  (): boolean | Observable<boolean> => {
    const router = inject(Router);
    const authenticationService = inject(AuthenticationService);
    const userService = inject(UserService);

    if (!authenticationService.isLoggedIn()) {
      return true;
    }

    return userService.getActualStatistics()
      .pipe(
        map((userStatistics) => {
          if (userStatistics?.hasPersonalDataFilled && userStatistics?.emailVerified) {
            void router.navigate(['/moje-aukro']);
            return false;
          } else {
            return true;
          }
        }),
      );
  };
