<auk-dialog-content
  [dialogTitle]="payload.title"
  [dialogSubtitle]="payload.subtitle"
  [dialogAlerts]="dialogAlerts"
>
  <ng-container content>
    <auk-user-task-login-step-not-you
        [email]="payload.email"
        [isNotThatYou]="payload.isNotThatYou"
        (isNotThatYouClick)="onBackClick()"
    ></auk-user-task-login-step-not-you>

    <re-captcha size="invisible"
                errorMode="handled"
                [siteKey]="CAPTCHA_SITE_KEY"
    ></re-captcha>
  </ng-container>

  <ng-container actions>
    <div class="tw-flex tw-flex-col tw-gap-y-6 tw-text-center tw-mt-3">
      <auk-button colorCombination="PRIMARY"
                  [chin]="true"
                  class="tw-w-full"
                  [isDisabled]="isSubmitting"
                  [isLoading]="isSubmitting"
                  (buttonClick)="onPrimaryButtonClick()"
      >
        {{ payload.primaryButton | translateSource }}
      </auk-button>
      <p class="tw-text-base tw-leading-sm" [aukInnerHtml]="payload.terms | aukTranslateSourceTerms"></p>
    </div>
  </ng-container>
</auk-dialog-content>

