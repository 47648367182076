import { Directive, Input, Renderer2, ElementRef, SkipSelf, Optional, OnChanges, OnInit } from '@angular/core';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import isNil from 'lodash-es/isNil';
import { Subject, takeUntil } from 'rxjs';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';

@Directive({
  selector: '[aukTestIdentification]',
  standalone: true,
})
export class TestIdentificationDirective extends BaseDestroy implements OnChanges, OnInit {

  @Input({ required: true }) public aukTestIdentification: string;

  public testIdentificationChange$: Subject<void> = new Subject<void>();

  public testId: string;

  constructor(
    @Optional() @SkipSelf() private readonly parentTestIdentificationDirective: TestIdentificationDirective,
    private readonly renderer2: Renderer2,
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (PlatformCommonService.isProductionMode) {
      return;
    }

    this.parentTestIdentificationDirective?.testIdentificationChange$
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.setTestIdAttribute();
      });
  }

  public ngOnChanges(simpleChanges: AukSimpleChanges<typeof this>): void {
    if (PlatformCommonService.isProductionMode) {
      return;
    }

    if (simpleChanges.aukTestIdentification) {
      this.setTestIdAttribute();
    }
  }

  // This will take the test-id from parent and combine it with local test-id. This is daisy-chained from all parents.
  private setTestIdAttribute(): void {
    // remove attribute if input is nil
    if (isNil(this.aukTestIdentification)) {
      this.renderer2.removeAttribute(this.elementRef.nativeElement, 'test-id');
      this.renderer2.removeAttribute(this.elementRef.nativeElement, 'accessibility-id');
      this.renderer2.removeAttribute(this.elementRef.nativeElement, 'content-desc');
      return;
    }

    const oldTestId = this.testId;

    const parentTestId = this.parentTestIdentificationDirective?.testId;
    let testId = this.aukTestIdentification;

    if (parentTestId) {
      testId = `${ parentTestId }-${ this.aukTestIdentification }`;
    }

    this.testId = testId;

    if (!isNil(oldTestId)) {
      this.testIdentificationChange$.next();
    }

    this.renderer2.setAttribute(this.elementRef.nativeElement, 'test-id', this.testId);

    if (PlatformCommonService.isNativeApp) {
      /**
       * This is only used for Android and iOS Testing. Its only attribute that we can use for tests in Appium.
       * Other attributes are not passed correctly for use in test.
       * It not used in Production.
       */
      this.renderer2.setAttribute(this.elementRef.nativeElement, 'aria-label', this.testId);
    }
  }

}

