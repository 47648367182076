import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { SanitizeHtmlConstants } from '@common/html/const/sanitize-html.constants';
import DOMPurify from 'dompurify';

@Injectable({
  providedIn: 'root',
})
export class HtmlSanitizeService {

  constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly platformCommonService: PlatformCommonService,
  ) {
  }

  public getSanitizedHtmlString(
    htmlString: string,
    /**
     * Whether to use DOMPurify for html sanitization
     */
    allowStrictSanitize: boolean = true,
  ): string {
    // TODO: [PDEV-21297] - DOMPurify.sanitize on SSR
    if (this.platformCommonService.isBrowser && allowStrictSanitize) {
      htmlString = DOMPurify.sanitize(htmlString, SanitizeHtmlConstants.getDOMPurifyConfig()) as string;
    }

    return this.domSanitizer.sanitize(
      SecurityContext.HTML,
      this.domSanitizer.bypassSecurityTrustHtml(htmlString),
    );
  }

}
