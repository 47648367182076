import { ChangeDetectorRef, Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe, TranslateService, TranslateCompiler } from '@ngx-translate/core';
import { TranslationSource } from '@common/translations/model/translation-source';
import { Nil } from '@util/helper-types/nil';
import { TranslateSourcePipeService } from '../service/translate-source-pipe.service';

// This pipe can be used as a pipe or as a service if needed to be used in class
@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'translateSource',
  pure: false,
})
export class TranslateSourcePipe implements PipeTransform {

  private readonly ngxTranslatePipe: TranslatePipe;

  constructor(
    private readonly translateService: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translateCompiler: TranslateCompiler,
    private readonly translateSourcePipeService: TranslateSourcePipeService,
  ) {
    // create ngx translate pipe
    this.ngxTranslatePipe = new TranslatePipe(this.translateService, this.changeDetectorRef);
  }

  public transform(value: TranslationSource): string | Nil {
    return this.translateSourcePipeService.transform(
      value,
      this.translateService,
      this.translateCompiler,
      this.ngxTranslatePipe,
    );
  }

}
