<auk-dialog-wrapper-with-content aukTestIdentification="dialog-picker" class="tw-break-words" [dialogTitle]="{ key: 'DOMAIN_PICKER_TITLE' }" (closeDialogClick)="onCloseClick()">
  <ng-container content>
    <p class="tw-text-center">{{ { key: 'DOMAIN_PICKER_SUBTITLE' } | translateSource }}</p>
    <div class="tw-mt-6">
      <form [formGroup]="form">
        <mat-form-field class="fluid" appearance="outline">
          <mat-label>{{ { key: "DOMAIN_PICKER_SELECT" } | translateSource }}</mat-label>
          <mat-select 
            aukTestIdentification="domain-dropdown"
            aukAccessibilityId="ACS_DOMAIN_DROPDOWN"
            [formControlName]="'domainList'"
          >
            @for (domain of domainList; track domain) {
              <mat-option [value]="domain.country">
                <div class="tw-flex tw-items-center tw-justify-center">
                  <auk-icon-with-text
                    [iconModel]="iconModel(domain.flag)"
                    class="tw-mr-2"
                  ></auk-icon-with-text>
                  <span>{{ { key: domain.label } | translateSource }}</span>
                </div>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </form>
    </div>
  </ng-container>

  <ng-container actions>
    <auk-button colorCombination="PRIMARY" [chin]="true" class="tw-w-full tw-mb-2" (buttonClick)="onSavePreferenceClick()">
      {{ { key: 'DOMAIN_PICKER_SAVE' } | translateSource }}
    </auk-button>

    <auk-button colorCombination="SECONDARY" class="tw-w-full tw-mb-2" (buttonClick)="onCloseClick()">
      {{ { key: 'DOMAIN_PICKER_CANCEL' } | translateSource }}
    </auk-button>
  </ng-container>
</auk-dialog-wrapper-with-content>
