import { Injectable } from '@angular/core';

import { AppInitializer } from '@util/helper-types/initializer.interface';
import { UrlAnnouncementService } from '@shared/url-announcements/service/url-announcement.service';

@Injectable({
  providedIn: 'root',
})
export class UrlAnnouncementInitializer implements AppInitializer {

  constructor(private readonly urlAnnouncementService: UrlAnnouncementService) {
  }

  public init(): void {
    this.urlAnnouncementService.init();
  }

}
