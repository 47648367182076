import { Directive, HostListener, Input } from '@angular/core';

import { KeyboardUtil } from '@util/util/keyboard.util';

@Directive({
  selector: '[aukLimitMaxLength]',
})
export class LimitMaxLengthDirective {

  @Input('aukLimitMaxLength') public limitMaxLength: number;
  private isSelectAllPressed: boolean = false;
  private isValueSelected: boolean = false;

  @HostListener('keypress', ['$event'])
  public onKeypress(e: KeyboardEvent): void {
    if ((e.target as HTMLInputElement).value.length === this.limitMaxLength && !this.isSelectAllPressed && !this.isValueSelected) {
      if (!KeyboardUtil.isRemovePressed(e) ) {
        e.preventDefault();
      }
    }
    if (this.isSelectAllPressed) {
      this.isSelectAllPressed = false;
    }
    if (this.isValueSelected) {
      this.isValueSelected = false;
    }
  }

  // FF fix for selectAll
  @HostListener('keydown', ['$event'])
  public onChangeInput(e: KeyboardEvent): void {
    if (KeyboardUtil.isSelectAllPressed(e)) {
      this.isSelectAllPressed = true;
      return;
    }
  }

  // FF fix for mark value
  @HostListener('select', ['$event'])
  public onInputSelect(e: any): void {
    this.isValueSelected = true;
  }

}
