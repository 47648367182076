import { Injectable } from '@angular/core';
import { from, map, Observable, ReplaySubject, take, takeUntil } from 'rxjs';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
// eslint-disable-next-line import/no-restricted-paths
import { DomainService } from '@shared/platform/domain.service';
// eslint-disable-next-line import/no-restricted-paths
import { LocaleConstants } from '@shared/platform/locale.constants';
import { AccessiblityTranslationKey } from '../model/accessibility-translation-key';

@Injectable({
  providedIn: 'root',
})
export class AccessibilityService extends BaseDestroy {

  private translation$: ReplaySubject<Record<AccessiblityTranslationKey, string>> =
    new ReplaySubject<Record<AccessiblityTranslationKey, string>>();

  constructor(
    private readonly domainService: DomainService,
  ) {
    super();
  }

  public init(): void {
    this.setTranslationData(this.domainService.lang);
  }

  private setTranslationData(lang: string): void {
    let translationFiles: Promise<Record<AccessiblityTranslationKey, string>>;
    switch (lang) {
      case LocaleConstants.CZ_LOCALE.language:
        translationFiles = import(`../../../common/translations/files/cs-accessibility.json`).then(res => res.default);
        break;
      case LocaleConstants.SK_LOCALE.language:
        translationFiles = import(`../../../common/translations/files/sk-accessibility.json`).then(res => res.default);
        break;
      default:
        translationFiles = import(`../../../common/translations/files/cs-accessibility.json`).then(res => res.default);
        break;
    }

    from(translationFiles)
      .pipe(
        take(1),
        takeUntil(this.destroy$),
      ).subscribe((translationData) => {
        this.translation$.next(translationData);
      });
  }

  public getTranslated$(id: AccessiblityTranslationKey): Observable<string> {
    return this.translation$
      .pipe(
        take(1),
        map((translationData) => translationData[id]),
      );
  }

}
