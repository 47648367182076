import { UserActionPrerequisiteTaskFulfillmentPayloadResolverService } from './user-action-prerequisite-task-fulfillment-payload-resolver.service';
import { UserTaskPayloadModel } from '@shared/user-task/base/model/user-task-payload.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserActionType } from '../../model/user-action.type';
import { UserActionPopupFlowConfigService } from '../user-action-popup-flow-config.service';
import { map } from 'rxjs/operators';
import { UserActionPopupFlowConfigElementModel } from '../../model/user-action-popup-flow-config-element.model';
import { recursiveStringsObjToTranslationSources } from '@util/helper-functions/recursive-strings-obj-to-translation.sources';

@Injectable({
  providedIn: 'root',
})
export class UserActionPrerequisiteTaskVerifiedBankAccountFulfillmentPayloadResolverService
  extends UserActionPrerequisiteTaskFulfillmentPayloadResolverService<'VERIFIED_BANK_ACCOUNT'> {

  constructor(
    private readonly userActionPopupFlowConfigService: UserActionPopupFlowConfigService,
  ) {
    super();
  }

  public resolve$(actionType: UserActionType): Observable<UserTaskPayloadModel<'VERIFIED_BANK_ACCOUNT'>> {
    return this.userActionPopupFlowConfigService.getTasksConfiguration$('VERIFIED_BANK_ACCOUNT', actionType)
      .pipe(
        map((taskConfig: UserActionPopupFlowConfigElementModel['tasks']['VERIFIED_BANK_ACCOUNT']) => ({
          stepsText: recursiveStringsObjToTranslationSources(taskConfig?.steps),
          source: `user task under action: ${ actionType }`,
        })),
      );
  }

}
