import { NgModule } from '@angular/core';
import { TranslateSourcePipe } from './pipe/translate-source.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    TranslateModule,
  ],
  declarations: [
    TranslateSourcePipe,
  ],
  exports: [
    TranslateModule,
    TranslateSourcePipe,
  ],
  providers: [
    TranslateSourcePipe,
  ],
})
export class Translate2Module {
}
