import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import isEqual from 'lodash-es/isEqual';

/**
 * Emits only if value is changed
 *
 * Extends `distinctUntilChanged` to support deep comparison of emitted items. Optionally accepts a function to extract
 * a specific value from each item for comparison, enabling more targeted change detection.
 * @param getValueFn - Optional function to extract a value for comparison. If provided, the operator
 * compares the extracted values instead of the entire items. Useful for comparing specific properties or applying
 * transformations before comparison. If not provided, a deep comparison of the entire items is performed.
 */
export const distinctUntilChangedDeep = <T>(getValueFn?: (val: T) => unknown) => (source$: Observable<T>): Observable<T> => source$
  .pipe(
    distinctUntilChanged((a, b) => {
      const valA = getValueFn ? getValueFn(a) : a;
      const valB = getValueFn ? getValueFn(b) : b;

      return isEqual(valA, valB);
    }),
  );
