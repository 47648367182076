import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SimpleFilterParamItem } from '../../../../../../../typings/original/deprecated';
import { ExtendedTileModel } from '@shared/legacy/component/mobile-filters';

/**
 * Maximum number of items to display before enabling "Show More" toggle feature
 */
const ITEM_COUNT_THRESHOLD = 7;
/**
 * Minimum items to display initially in case that "Show More" toggle feature is active
 */
const ITEM_COUNT_THRESHOLD_MINIMUM = 6;

@Component({
  selector: 'auk-mobile-box-layout-list',
  templateUrl: './mobile-box-layout-list.component.html',
})
export class MobileBoxLayoutListComponent implements OnChanges {

  /**
   * List of objects
   */
  @Input() public tileList: ExtendedTileModel<SimpleFilterParamItem>[] = [];

  /**
   * Whether is single-select or multi-select
   * Show tiles as checkboxes if it's multi-select
   */
  @Input() public isSingleSelection: boolean = false;

  @Output() public buttonClick = new EventEmitter<ExtendedTileModel<SimpleFilterParamItem>>();

  public _isToggledMoreSorting: boolean = false;
  public isShowMoreToggled: boolean = false;
  public visibleTiles: ExtendedTileModel<SimpleFilterParamItem>[] = [];
  public toggledTiles: ExtendedTileModel<SimpleFilterParamItem>[] = [];

  private isSingleSelected: ExtendedTileModel<SimpleFilterParamItem> = null;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.tileList) {
      // Check if the total items are more than the threshold
      if (this.hasMoreThanThreshold()) {
        // Set visible item to THRESHOLD_MINIMUM and show the toggle button
        this.visibleTiles = this.tileList.slice(0, ITEM_COUNT_THRESHOLD_MINIMUM);
        this.toggledTiles = this.tileList.slice(ITEM_COUNT_THRESHOLD_MINIMUM);
        this._isToggledMoreSorting = false;
      } else {
        // Show all items and no toggle button
        this._isToggledMoreSorting = true;
        this.visibleTiles = [...this.tileList];
        this.toggledTiles = [];
      }

      // Check if any of toggled items is selected
      if (this.toggledTiles.some(value => value.isSelected)) {
        this._isToggledMoreSorting = true;
        this.isShowMoreToggled = true;
      }
    }
  }

  public get isToggledMoreSorting(): boolean {
    return this._isToggledMoreSorting;
  }

  public onToggle(isToggled: boolean): void {
    this._isToggledMoreSorting = isToggled;
  }

  public onButtonClick(tile: ExtendedTileModel<SimpleFilterParamItem>): void {
    if (this.isSingleSelection) {
      // Update the `isSelected` and set `colorCombination`
      this.isSingleSelected = tile;
      this.tileList.forEach(singleObject => {
        singleObject.isSelected = this.isSingleSelected.data === singleObject.data;
        singleObject.defaultIcon.colorCombination = singleObject.isSelected ? 'PRIMARY_CONTRAST' : 'SILVERSTEIN_600_CONTRAST';
      });
    } else {
      tile.isSelected = !tile.isSelected;
    }

    this.buttonClick.emit(tile);
  }

  protected hasMoreThanThreshold(): boolean {
    return this.tileList.length > ITEM_COUNT_THRESHOLD;
  }

  public hasMoreThanMinimumThreshold(): boolean {
    return this.tileList.length > ITEM_COUNT_THRESHOLD_MINIMUM;
  }

}
