import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HideOnMobileSsrDirectiveModule } from '@shared/legacy/directive/hide-on-mobile-ssr/hide-on-mobile-ssr.directive.module';
import { AppHeaderComponent } from './component/app-header/app-header.component';
import { Translate2Module } from '@common/translations/translate2.module';
import { BannerModule } from '@shared/banner/banner.module';
import { DomainPickerModule } from '@shared/domain-picker/domain-picker.module';
import { AppHeaderUserControlsModule } from '@shared/app-header/module/app-header-user-controls/app-header-user-controls.module';
import { AppHeaderSearchModule } from '@shared/app-header/module/app-header-search/app-header-search.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { GoBackButtonComponent } from '@shared/legacy/component/go-back-button/go-back-button.component';
import { AppHeaderMeasurementServiceService } from '@shared/app-header/service/app-header-measurement-service.service';
import { HorizontalMenuNavbarComponent } from './component/horizontal-header-navbar/component/horizontal-menu-navbar.component';
import { AppHeaderV2Component } from '@shared/app-header/component/app-header-v2/app-header-v2.component';
import { StopPropagationOnClickDirective } from '@shared/legacy/directive/stop-propagation/stop-propagation-on-click.directive';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { AppHeaderSearchInputComponent } from './module/app-header-search/component/app-header-search-input/app-header-search-input.component';
import { IfIsActiveBreakpointDirective } from '@common/responsiveness/directive/if-is-active-breakpoint/if-is-active-breakpoint.directive';
import { AppHeaderLogoComponent } from './component/app-header-logo/app-header-logo.component';
import { CategoryDialogContentComponent } from '@shared/app-header/component/category-dialog/category-dialog-content/category-dialog-content.component';
import { CategoryDialogModalComponent } from '@shared/app-header/component/category-dialog/category-dialog-modal/category-dialog-modal.component';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { AppHeaderMenuComponent } from './component/app-header-menu/app-header-menu.component';
import { PreventDefaultLinkDirective } from '@common/routing/directive/prevent-default-link.directive';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';
import { SkeletonLoadingComponent } from '@shared/skeleton-loading/component/skeleton-loading.component';
import {
  AppHeaderStickyPanelComponent,
} from '@shared/app-header/component/app-header-sticky-panel/app-header-sticky-panel.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    Translate2Module,
    SkeletonLoadingComponent,
    DomainPickerModule,
    PreventDefaultLinkDirective,
    BannerModule,
    AppHeaderUserControlsModule,
    AppHeaderSearchModule,
    HideOnMobileSsrDirectiveModule,
    MatTooltipModule,
    GoBackButtonComponent,
    StopPropagationOnClickDirective,
    HorizontalMenuNavbarComponent,
    IconComponent,
    SvgIconComponent,
    AppHeaderSearchInputComponent,
    IfIsActiveBreakpointDirective,
    AppHeaderLogoComponent,
    CategoryDialogContentComponent,
    CategoryDialogModalComponent,
    ButtonComponent,
    InnerHtmlDirective,
    LinkFromHrefDirective,
    AppHeaderStickyPanelComponent,
  ],
  providers: [
    AppHeaderMeasurementServiceService,
  ],
  declarations: [
    AppHeaderComponent,
    AppHeaderV2Component,
    AppHeaderMenuComponent,
  ],
  exports: [
    AppHeaderComponent,
    AppHeaderV2Component,
    AppHeaderMenuComponent,
  ],
})
export class AppHeaderModule {
}
