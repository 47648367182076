import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserTaskLoginStepLoginEmailComponent } from './component/user-task-login-step-login-email/user-task-login-step-login-email.component';
import {
  UserTaskLoginStepEmailConfirmationNonRegisteredViaEmailComponent,
} from './component/user-task-login-step-email-confirmation-non-registered-via-email.component';
import { UserTaskBaseModule } from '../base/user-task-base.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDividerModule } from '@angular/material/divider';
import { SvgIconModule } from '@shared/legacy/component/svg-icon/svg-icon.module';
import { UserTaskLoginStepFacebookLoadComponent } from './component/user-task-login-step-facebook-load.component';
import {
  UserTaskLoginStepEmailConfirmationRegisteredViaFbComponent,
} from './component/user-task-login-step-email-confirmation-registered-via-fb.component';
import { AuthenticateWrapperModule } from '@shared/legacy/component/authenticate-wrapper/authenticate-wrapper.module';
import { RouterModule } from '@angular/router';
import { RecaptchaModule } from 'ng-recaptcha';
import { CommonDirectivesModule } from '@shared/legacy/directive/common-directives.module';
import { DialogWrapperModule } from '@common/dialog-wrapper/dialog-wrapper.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { SpinnerModule } from '@shared/legacy/component/spinner/spinner.module';
import {
  UserTaskLoginStepFacebookAuthorizeFailedComponent,
} from './component/user-task-login-step-facebook-authorize-failed.component';
import { AukroInfoHoverModule } from '@shared/legacy/component/html-hover/aukro-info-hover.module';
import { UserTaskLoginStepNotYouComponent } from './component/user-task-login-step-not-you/user-task-login-step-not-you.component';
import { UserTaskLoginStepSetPasswordComponent } from './component/user-task-login-step-set-password.component';
import { LoginWithPasswordFormComponent } from './component/login-with-password-form/login-with-password-form.component';
import { FacebookButtonComponent } from './component/facebook-button/facebook-button.component';
import { UserTaskLoginStepPasswordOrThirdPartyComponent } from './component/user-task-login-step-password-or-third-party.component';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import {
  DelayedAutofocusDirectiveModule,
} from '@shared/legacy/directive/delayed-autofocus/delayed-autofocus-directive.module';
import { TranslateSourceTermsPipeModule } from '@shared/legacy/pipe/translate-source-terms/translate-source-terms-pipe.module';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';

@NgModule({
  imports: [
    CommonModule,
    UserTaskBaseModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDividerModule,
    SvgIconModule,
    AuthenticateWrapperModule,
    RouterModule,
    RecaptchaModule,
    CommonDirectivesModule,
    DialogWrapperModule,
    IconComponent,
    Translate2Module,
    SpinnerModule,
    TestIdentificationDirective,
    AukroInfoHoverModule,
    ButtonComponent,
    DelayedAutofocusDirectiveModule,
    TranslateSourceTermsPipeModule,
    InnerHtmlDirective,
  ],
  declarations: [
    UserTaskLoginStepNotYouComponent,
    UserTaskLoginStepLoginEmailComponent,
    UserTaskLoginStepSetPasswordComponent,
    UserTaskLoginStepPasswordOrThirdPartyComponent,
    UserTaskLoginStepEmailConfirmationNonRegisteredViaEmailComponent,
    UserTaskLoginStepFacebookLoadComponent,
    UserTaskLoginStepEmailConfirmationRegisteredViaFbComponent,
    UserTaskLoginStepFacebookAuthorizeFailedComponent,
    LoginWithPasswordFormComponent,
    FacebookButtonComponent,
  ],
})
export class UserTaskLoginModule {

}
