import { Injectable } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Observable, of } from 'rxjs';
import { DynamicColorClasses } from './model/dynamic-color-classes.model';

@Injectable({
  providedIn: 'root',
})
export class DynamicColorResolver extends NgUnsubscribe {

  public getColorClasses$(): Observable<DynamicColorClasses> {
    return of(null);
  }

}
