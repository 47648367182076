import { DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment-mini-ts';

import { CalendarUtil } from '@util/util/calendar.util';

import { DateTypeFormatType } from '../model/medium-date-time';
import { Nil } from '@util/helper-types/nil';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'mediumDateTime',
  standalone: true,
})
export class MediumDateTimePipe implements PipeTransform {

  private readonly mediumFormat = 'd. MMMM yyyy, HH:mm';
  private readonly mediumWithSecFormat = 'd. MMMM yyyy, HH:mm:ss';
  private readonly mediumOnlyNumbersFormat = 'd. M. yyyy, HH:mm';
  private readonly mediumOnlyNumbersNoYearFormat = 'd. M. H:mm:ss';
  private readonly mediumOnlyNumbersNoTimeFormat = 'd. M. yyyy';
  private readonly mediumOnlyNumbersNoYearNoTimeFormat = 'd. M.';
  private readonly longerWithoutSecFormat = 'EEEEEE d. MMMM yyyy, HH:mm';
  private readonly longerFormat = 'EEEEEE d. MMMM yyyy, HH:mm:ss';
  private readonly fullFormat = 'EEEE, d. M. yyyy, HH:mm:ss';
  private readonly fullWithoutSeconds = 'EEEE, d. M. yyyy, HH:mm';
  private readonly fullWithoutHours = 'EEEE, d. M. yyyy';
  private readonly fullDayWithoutYearTimeFormat = 'EEEE, d. M.';
  private readonly shortFormat = 'd. MMMM yyyy';
  private readonly shortDateTimeFormat = 'dd.MM.YYYY HH:mm';

  constructor(private datePipe: DatePipe) {}

  public transform(value: string | Date, type?: DateTypeFormatType): string | Nil {
    if (value) {
      const date = moment(value);
      if (date.isValid()) {
        switch (type) {
          case 'longer':
            return this.datePipe.transform(date.toDate(), this.longerFormat);
          case 'longerWithoutSec':
            return this.datePipe.transform(date.toDate(), this.longerWithoutSecFormat);
          case 'short':
            return this.datePipe.transform(date.toDate(), this.shortFormat);
          case 'medium':
            return this.datePipe.transform(date.toDate(), this.mediumWithSecFormat);
          case 'mediumOnlyNumbersNoYear':
            return this.datePipe.transform(date.toDate(), this.mediumOnlyNumbersNoYearFormat);
          case 'mediumOnlyNumbersNoTime':
            return this.datePipe.transform(date.toDate(), this.mediumOnlyNumbersNoTimeFormat);
          case 'mediumOnlyNumbersNoYearNoTime':
            return this.datePipe.transform(date.toDate(), this.mediumOnlyNumbersNoYearNoTimeFormat);
          case 'mediumOnlyNumbersFormat':
            return this.datePipe.transform(date.toDate(), this.mediumOnlyNumbersFormat);
          case 'full':
            return this.datePipe.transform(date.toDate(), this.fullFormat);
          case 'fullDayWithoutYearTime':
            return this.datePipe.transform(date.toDate(), this.fullDayWithoutYearTimeFormat);
          case 'fullWithoutSeconds':
            return this.datePipe.transform(date.toDate(), this.fullWithoutSeconds);
          case 'fullWithoutHours':
            return this.datePipe.transform(date.toDate(), this.fullWithoutHours);
          case 'economical':
            return this.getEconomicalDate(date);
          case 'shortDateTime':
            return this.datePipe.transform(date.toDate(), this.shortDateTimeFormat);
          case 'shortEconomical':
            return this.getShortEconomicalDate(date);
          default:
            return this.datePipe.transform(date.toDate(), this.mediumFormat);
        }
      }
    }
    // if value is in invalid date format, return value?.toString() or undefined
    return value?.toString();
  }

  private getEconomicalDate(date: Moment): string {
    const today = moment().startOf('day');
    const tomorrow = moment().add(1, 'days').startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');

    if (CalendarUtil.isSameDay(date, today)) {
      return 'dnes ' + this.datePipe.transform(date.toDate(), 'HH:mm');
    }
    if (CalendarUtil.isSameDay(date, tomorrow)) {
      return 'zítra ' + this.datePipe.transform(date.toDate(), 'HH:mm');
    }
    if (CalendarUtil.isSameDay(date, yesterday)) {
      return 'včera ' + this.datePipe.transform(date.toDate(), 'HH:mm');
    }
    if (CalendarUtil.isSameYear(date, today)) {
      return this.datePipe.transform(date.toDate(), 'EEEEEE d.M. HH:mm');
    }

    return this.datePipe.transform(date.toDate(), 'EEEEEE d.M.yyyy HH:mm');
  }

  /**
   * Get just date and skip year when it is same as now
   * @param date
   * @private
   */
  private getShortEconomicalDate(date: Moment): string {
    const dateFormat = CalendarUtil.isSameYear(date, moment()) ? 'd.M.' : 'd.M.yyyy';
    return this.datePipe.transform(date.toDate(), dateFormat);
  }

}
