<div class="tw-flex tw-flex-col tw-w-full" *ngIf="userInfo">
  <div [ngClass]="chipTypeClass"
  >
    <ng-container [ngSwitch]="chipType">

      <!--
        Added template for small chip, regarding refactoring of user chips PDEV-21936.
        Other variants should have also templates to be more editable
      -->

      <!-- SMALL-SIMPLE CHIP -->
      <ng-container *ngSwitchCase="'SMALL-SIMPLE'">

        <div class="chip-wrapper tw-group">
          <!-- AVATAR -->
          <a class="tw-pr-2.5 !tw-no-underline" [routerLink]="itemRouterLink">
            <ng-container *ngTemplateOutlet="smallSimpleAvatarTmpl"></ng-container>
          </a>
          <!-- ./ AVATAR -->

          <!-- INFO CONTAINER -->
          <div class="info-container">
            <a [routerLink]="itemRouterLink">
              <ng-container *ngTemplateOutlet="smallSimpleInfoTmpl"></ng-container>
            </a>
          </div>
          <!-- ./ INFO CONTAINER -->
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'SMALL-SIMPLE-INACTIVE'">

        <div class="chip-wrapper tw-group">
          <!-- AVATAR -->
          <span class="tw-pr-2.5">
            <ng-container *ngTemplateOutlet="smallSimpleAvatarTmpl"></ng-container>
          </span>
          <!-- ./ AVATAR -->

          <!-- INFO CONTAINER -->
          <div class="info-container">
            <span>
              <ng-container *ngTemplateOutlet="smallSimpleInfoTmpl"></ng-container>
            </span>
          </div>
          <!-- ./ INFO CONTAINER -->
        </div>
      </ng-container>


      <ng-template #smallSimpleAvatarTmpl>
        <auk-avatar class="tw-h-fit"
                    [url]="userInfo?.avatarUrlMedium"
                    avatarSize="SM"
        ></auk-avatar>
      </ng-template>

      <ng-template #smallSimpleInfoTmpl>
        <div class="main-info">
          <!-- USER NAME -->
          <span aukTestIdentification="user-chip-username" class="user-name-display group-hover:tw-underline">
                            {{ userInfo?.showName }}
                          </span>
          <!-- ./USER NAME -->
        </div>
      </ng-template>
      <!-- ./ SMALL-SIMPLE CHIP -->

      <!-- SMALL CHIP -->
      <ng-container *ngSwitchCase="'SMALL'">

        <div class="chip-wrapper tw-group">

          <a class="tw-pr-2 !tw-no-underline" [routerLink]="itemRouterLink">

            <!-- AVATAR WITH BADGE -->
            <auk-avatar class="tw-h-fit"
                        [url]="userInfo?.avatarUrlMedium"
                        [isReadOnly]="!isCurrentlyLoggedUser"
                        [showAccountState]="true"
                        [accountActivated]="userInfo?.accountActivated"
                        [accountBlockType]="userInfo?.accountBlockType"
                        [accountBusiness]="userInfo?.companyAccount"
                        (avatarDialogClose)="onUserAvatarChange()"
                        avatarSize="SM"
            ></auk-avatar>
            <!-- ./ AVATAR WITH BADGE -->

          </a>

          <!-- INFO CONTAINER -->
          <div class="info-container">
            <a [routerLink]="itemRouterLink">
              <div class="main-info">

                <!-- USER NAME -->
                <span aukTestIdentification="user-chip-username" class="user-name-display group-hover:tw-underline">
                {{ userInfo?.showName }}
              </span>
                <!-- ./USER NAME -->

                <!-- AUKRO PLUS ICON -->
                <div class="aukro-plus-container" *ngIf="userInfo?.aukroPlus && !isBlocked">
                  <auk-icon
                      source="aukro-crown"
                      size="SM"
                      iconType="SVG"
                      colorCombination="PRIMARY"
                  ></auk-icon>
                </div>
                <!-- ./AUKRO PLUS ICON -->

              </div>
            </a>
            <div class="other-info">

              <!-- RATING -->
              <ng-container *ngIf="!isBlocked; else userBlocked">

                <!-- RATING NUMBERS -->
                <a class="rating-container no-hover-underline" *ngIf="hasRating; else noRating"
                   [routerLink]="ratingRouterLink"
                   aukStopPropagationOnClick
                >
                  <auk-icon source="user-chip-star"
                            size="XSM"
                            iconType="SVG"
                  ></auk-icon>
                  <div class="rating-number">
                    <span class="tw-font-bold">
                      {{ userInfo?.positiveFeedbackPercentage | percent:'1.2' }}
                    </span>
                    <span>
                      ({{ userInfo?.feedbackUniqueUserCount | number: '1.0-1' }})
                    </span>
                  </div>
                </a>
                <!-- ./ RATING NUMBERS -->

              </ng-container>
              <!-- ./ RATING -->

              <!-- EMPTY <a> TO FULFILL AREA -->
              <a class="tw-flex-grow" [routerLink]="itemRouterLink">
              </a>
              <!-- ./ EMPTY <a> TO FULFILL AREA -->

            </div>
          </div>
          <!-- ./ INFO CONTAINER -->

        </div>
      </ng-container>
      <!-- ./ SMALL CHIP -->

      <!-- MEDIUM CHIP -->
      <ng-container *ngSwitchCase="'MEDIUM'">

        <div class="chip-wrapper tw-group">

          <a class="tw-flex tw-self-stretch tw-items-center tw-pr-5 !tw-no-underline" [routerLink]="itemRouterLink">

            <!-- AVATAR WITH BADGE -->
            <auk-avatar class="tw-h-fit"
                        [url]="userInfo?.avatarUrlMedium"
                        [isReadOnly]="!isCurrentlyLoggedUser"
                        [showAccountState]="true"
                        [accountActivated]="userInfo?.accountActivated"
                        [accountBlockType]="userInfo?.accountBlockType"
                        [accountBusiness]="userInfo?.companyAccount"
                        (avatarDialogClose)="onUserAvatarChange()"
                        avatarSize="LG"
            ></auk-avatar>
            <!-- ./ AVATAR WITH BADGE -->

          </a>

          <!-- INFO CONTAINER -->
          <div class="info-container">
            <a class="!tw-no-underline" [routerLink]="itemRouterLink">
              <div class="main-info">

                <!-- USER NAME -->
                <span aukTestIdentification="user-chip-username" class="user-name-display group-hover:tw-underline">
                {{ userInfo?.showName }}
              </span>
                <!-- ./USER NAME -->

                <!-- AUKRO PLUS ICON -->
                <auk-chip *ngIf="userInfo?.aukroPlus && !isBlocked"
                          [chipInfo]="aukroTopSellerInfo"
                          [colorCombination]="aukroTopSellerInfo.colorCombination">
                </auk-chip>
                <!-- ./AUKRO PLUS ICON -->

              </div>
            </a>
            <div class="other-info">

              <!-- RATING -->
              <ng-container *ngIf="!isBlocked; else userBlocked">

                <!-- RATING NUMBERS -->
                <a class="rating-container no-hover-underline tw-pr-3" *ngIf="hasRating; else noRating"
                   [routerLink]="ratingRouterLink"
                   aukStopPropagationOnClick
                >
                  <auk-icon source="user-chip-star"
                            size="MD"
                            iconType="SVG"
                  ></auk-icon>

                  <span class="rating-number">
                    {{ userInfo?.positiveFeedbackPercentage | percent:'1.2' }}
                  </span>
                  <span class="rating-number tw-text-matter-neutral-secondary">
                    ({{ userInfo?.feedbackUniqueUserCount | number: '1.0-1' }})
                  </span>

                </a>
                <!-- ./ RATING NUMBERS -->

                <!-- FAVOURITE NUMBERS -->
                <a class="rating-container no-hover-underline" *ngIf="hasRating"
                   [routerLink]="ratingRouterLink"
                   aukStopPropagationOnClick
                >
                  <auk-icon
                      source="heart-filled"
                      size="MD"
                      iconType="SVG"
                      colorCombination="DANGER_CONTRAST"
                  ></auk-icon>

                  <span class="rating-number">
                    {{ userInfo?.favouritedByCount | number: '1.0-1' }}
                  </span>
                </a>
                <!-- ./ FAVOURITE NUMBERS -->

              </ng-container>

              <!-- ./ RATING -->

              <!-- EMPTY <a> TO FULFILL AREA -->
              <a class="tw-flex-grow" [routerLink]="itemRouterLink">
              </a>
              <!-- ./ EMPTY <a> TO FULFILL AREA -->

            </div>

            <!-- LOCATION -->
            <div class="other-info">
              <a class="location-container no-hover-underline !tw-no-underline"
                 [routerLink]="itemRouterLink"
                 aukStopPropagationOnClick
              >
                <auk-icon source="location-pin"
                          size="MD"
                          iconType="SVG"
                ></auk-icon>

                <span class="location-text tw-flex-grow">
                    {{ $any(userInfo)?.location }}
                  </span>
              </a>

              <!-- EMPTY <a> TO FULFILL AREA -->
              <a class="tw-flex-grow" [routerLink]="itemRouterLink">
              </a>
              <!-- ./ EMPTY <a> TO FULFILL AREA -->

            </div>
            <!-- ./ LOCATION -->

          </div>
          <!-- ./ INFO CONTAINER -->

        </div>
      </ng-container>
      <!-- ./ MEDIUM CHIP -->

      <!-- MINI & BIG CHIPs -->
      <ng-container *ngSwitchDefault>
        <div class="chip-wrapper">
          <!-- AVATAR -->
          <ng-container [ngSwitch]="chipType">

            <!--AVATAR WITH BADGE-->
            <auk-avatar class="tw-h-fit"
                        *ngSwitchDefault
                        [url]="chipType === 'BIG' ? userInfo?.avatarUrl : userInfo?.avatarUrlMedium"
                        [isReadOnly]="!isCurrentlyLoggedUser"
                        [showAccountState]="true"
                        [accountActivated]="userInfo?.accountActivated"
                        [accountBlockType]="userInfo?.accountBlockType"
                        [accountBusiness]="userInfo?.companyAccount"
                        (avatarDialogClose)="onUserAvatarChange()"
                        avatarSize="XL"
            ></auk-avatar>
            <!-- ./AVATAR WITH BADGE -->

            <!-- JUST BADGE -->
            <a *ngSwitchCase="'MINI'"
               class="tw-flex tw-w-5 tw-h-5"
               routerLink="/kontakt"
               [openInNewTab]="true"
               aukStopPropagationOnClick
            >
              <auk-user-account-type-badge [userInfo]="userInfo"></auk-user-account-type-badge>
            </a>
            <!-- ./ JUST BADGE -->
          </ng-container>
          <!-- ./AVATAR -->

          <!-- INFORMATION -->
          <div class="info-container">
            <div class="main-info">
              <!-- USER NAME -->
              <ng-container [ngSwitch]="chipType">
                <h1 *ngSwitchCase="'BIG'" class="big-user-name">
                  {{ userInfo?.showName }}
                </h1>

                <a *ngSwitchDefault class="user-name-display !tw-text-matter-accent-primary"
                   [routerLink]="itemRouterLink"
                   [matTooltip]="{key : 'ITEM_DETAIL_PAYMENT_TO_SELLER_DETAIL', params: { sellerName: userInfo?.showName }} | translateSource"
                   [matTooltipShowDelay]="250"
                   matTooltipClass="tooltip-lg"
                   matTooltipPosition="above"
                   aukStopPropagationOnClick
                   aukTestIdentification="username-link"
                   aukAccessibilityId="ACS_USERNAME_LINK"
                >
                  {{ userInfo?.showName }}
                </a>
              </ng-container>
              <!-- ./USER NAME -->

              <!-- AUKRO PLUS ICON -->
              <ng-container *ngIf="chipType !== 'MINI'">

                <auk-svg-icon class="aukro-plus-container !tw-fill-primary"
                              *ngIf="userInfo?.aukroPlus"
                              iconId="aukro-topSeller"
                              [matTooltip]="{key: 'TOP_SELLER_ICON_TOOLTIP'} | translateSource"
                              [matTooltipShowDelay]="250"
                              matTooltipClass="tooltip-lg"
                              matTooltipPosition="above"
                ></auk-svg-icon>

              </ng-container>
              <!-- ./AUKRO PLUS ICON -->

              <!-- ACCOUNT CHIP -->
              <auk-user-account-type-chip *ngIf="chipType === 'BIG'"
                                          [isBusinessAccount]="userInfo?.companyAccount"
                                          [isBlocked]="userInfo?.accountBlockType === 'BLOCK'"
              ></auk-user-account-type-chip>
              <!-- ./ACCOUNT CHIP -->
            </div>

            <div class="other-info">
              <!-- ADMIN ACTIONS -->
              <auk-user-profile-admin-actions *ngIf="chipType === 'BIG'"
                                              [userId]="userInfo?.userId"
                                              [isUserLinkVisible]="hasUserReadPrivilege"
                                              [isClipboardIconVisible]="hasUserReadPrivilege"
              ></auk-user-profile-admin-actions>
              <!-- ./ADMIN ACTIONS -->

              <!-- RATING -->
              <ng-container *ngIf="chipType !== 'MINI'">

                <div class="rating-container" *ngIf="userInfo?.starType && userInfo?.rating >= 0">
                  <auk-star-icon [starType]="userInfo?.rating !== 0 ? userInfo?.starType : 'GREEN'"
                                 [size]="chipType === 'BIG' ? 'MD' : 'SM'"
                  ></auk-star-icon>

                  <span class="rating-number"
                  >
                    {{ userInfo?.rating }}
                  </span>
                </div>

              </ng-container>
              <!-- ./ RATING -->

              <!-- FAVOURITES -->
              <ng-container *ngIf="chipType === 'BIG'">

                <div *ngIf="userInfo?.favouritedByCount > 0" class="favourites-container">
                  <auk-icon class="heart-icon"
                            source="favorite"
                  ></auk-icon>

                  <span class="tw-flex tw-font-normal">
                    {{ {key: 'FAVORITE'} | translateSource }}
                    <strong class="tw-flex tw-font-bold tw-mx-1">{{ userInfo?.favouritedByCount }}</strong>
                    {{ 'USERS_INSTRUMENTAL' | aukUnitPluralize: userInfo?.favouritedByCount }}
                  </span>

                </div>

              </ng-container>
              <!-- ./FAVOURITES -->

            </div>
          </div>
        </div>
      </ng-container>
      <!-- ./ MINI & BIG CHIPs -->

    </ng-container>
  </div>

  <!-- DATE -->
  <ng-container *ngIf="chipType === 'BIG'">

    <div *ngIf="($any(userInfo)?.registrationDate || $any(userInfo)?.lastLoginTime)" class="date-container">

      <span *ngIf="$any(userInfo)?.registrationDate" class="registration-date">
        {{ {key: 'REGISTRATION_DATE'} | translateSource }}
        {{ $any(userInfo)?.registrationDate | mediumDateTime: 'medium' }}
      </span>

      <span *ngIf="$any(userInfo)?.lastLoginTime" class="last-seen">
        {{ {key: 'LAST_LOGGED_IN'} | translateSource }}
        {{ $any(userInfo)?.lastLoginTime | mediumDateTime: 'medium' }}
      </span>

    </div>
  </ng-container>
  <!-- ./DATE -->
</div>

<!-- NO RATING TEXT -->
<ng-template #noRating>
  <a class="!tw-decoration-silverstein-500 no-rating-text no-hover-underline"
     [routerLink]="ratingRouterLink">
    {{ {key: 'USER_CHIP_NO_RATING_TEXT'} | translateSource }}
  </a>
</ng-template>
<!-- ./ NO RATING TEXT -->

<!-- USER BLOCKED TEXT -->
<ng-template #userBlocked>
  <a class="user-blocked tw-uppercase !tw-decoration-danger no-hover-underline"
     [routerLink]="['/kontakt/poruseni-pravidel/okolnosti-blokace-uzivatelskeho-uctu']">
    {{ {key: 'AVATAR_BLOCKED_USER_TEXT'} | translateSource }}
  </a>
</ng-template>
<!-- ./ USER BLOCKED TEXT -->
