@if (className !== 'error' && message) {
  <auk-alert-banner-legacy type="SUCCESS">
    <div [aukInnerHtml]="message"></div>
  </auk-alert-banner-legacy>
}
@if (className === 'error' && message) {
  <auk-alert-banner-legacy type="WARNING">
    <div [aukInnerHtml]="message"></div>
  </auk-alert-banner-legacy>
}
