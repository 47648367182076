import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';

@Injectable()
export class SaveJwtTokenInterceptor implements HttpInterceptor {

  private readonly AUTHORIZATION_RESPONSE_HEADER_NAME: string = 'Authorization';

  constructor(private readonly authenticationService: AuthenticationService) {
  }

  public intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        tap((httpEvent: HttpEvent<any>) => {
          // Save new auth token if HTTP response contains authorization header
          if (httpEvent instanceof HttpResponse && httpEvent.headers.has(this.AUTHORIZATION_RESPONSE_HEADER_NAME)) {
            const authToken: string = httpEvent.headers.get(this.AUTHORIZATION_RESPONSE_HEADER_NAME);
            this.authenticationService.setAuthToken(authToken);
          }
        }),
      );
  }

}
