import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'charactersLeft' })
export class CharactersLeftPipe implements PipeTransform {

  /**
   * Return the number of characters left from the string,
   * limited by the 'max' variable.
   * In case string is longer than the 'max', output will be zero.
   * @param value String which will be measured
   * @param max Limit of how many characters are left
   * @param trim Whether the whitespace is trimmed
   */
  public transform(value: string, max: number = 140, trim: boolean = false): number {
    if (!value) {
      return max;
    }

    const v = trim ? value.trim() : value;
    const left = max - v.length;
    return left >= 0 ? left : 0;
  }

}
