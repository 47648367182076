import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';

@Component({
  selector: 'auk-icon-button',
  templateUrl: './icon-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
  ],
})
export class IconButtonComponent extends ColorCombinationInputComponent implements OnChanges {

  @Input() public iconModel: IconModel;
  @Input() public isDisabled: boolean = false;
  @Input() public hasBackground: boolean = true;
  @Input() public hasShadow: boolean = true;
  @Input() public paddingClass: string = 'tw-p-2';
  @Input() public isCircle: boolean = true;

  @Output() public buttonClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  protected iconClasses: string[] = [];

  public override ngOnChanges(changes: AukSimpleChanges<IconButtonComponent>): void {
    super.ngOnChanges(changes);

    this.iconClasses = this.getIconClasses();
  }

  private getIconClasses(): string[] {
    const classes: string[] = [];

    classes.push(this.isCircle ? 'tw-rounded-full' : 'tw-rounded-lg');

    if (this.hasBackground) {
      classes.push('tw-bg-background-color', 'hover:tw-bg-background-color-hover');
    }

    if (this.hasBackground && this.hasShadow) {
      classes.push('tw-shadow-md');
    }

    classes.push(this.paddingClass);

    return classes;
  }

  protected onButtonClick(event: MouseEvent): void {
    if (this.isDisabled) {
      return;
    }

    this.buttonClick.emit(event);
  }

}
