import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ItemsCollectionService } from '@api/generated/api/ItemsCollection';
import { StringUtils } from '@util/util/string.utils';
import isNil from 'lodash-es/isNil';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ItemIdAndSeoUrlDto } from '@api/generated/defs/ItemIdAndSeoUrlDto';
import { ProductUtil } from '@shared/util/product/product.util';

export const currentOfferInCollectionGuard: CanActivateFn =
  (route): boolean | Observable<boolean> => {
    const router = inject(Router);
    const itemsCollectionService = inject(ItemsCollectionService);

    const redirectToNotFoundPage = (): void => {
      void router.navigate(['/404']);
    };

    const itemsCollectionId: number = StringUtils.parseNumber(route.paramMap.get('collectionId'));

    if (isNil(itemsCollectionId)) {
      redirectToNotFoundPage();
      return false;
    }

    return itemsCollectionService.current({ itemsCollectionId })
      .pipe(
        take(1),
        catchError(() => {
          redirectToNotFoundPage();
          return of(false);
        }),
        tap((itemIdAndSeoUrlDto: ItemIdAndSeoUrlDto) => {
          if (isNil(itemIdAndSeoUrlDto)) {
            redirectToNotFoundPage();
            return;
          }
          const offerUrl: string[] = ProductUtil.getOfferUrl(itemIdAndSeoUrlDto);
          void router.navigate(offerUrl);
        }),
        map(() => false),
      );
  };
