import { ColorVariant } from './color-variant.class';

export class ColorCombination {

  constructor(
    public backgroundColor: ColorVariant,
    public foregroundColor: ColorVariant,
    public borderColor: ColorVariant,
    public anchorColor: ColorVariant = backgroundColor,
  ) {
  }

}
