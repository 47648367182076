<auk-authenticate-wrapper>
  <auk-dialog-content
      [dialogTitle]="payload.title"
      [dialogSubtitle]="payload.subtitle"
      [dialogAlerts]="dialogAlerts"
  >
    <ng-container content>
      <auk-spinner-legacy></auk-spinner-legacy>
    </ng-container>
  </auk-dialog-content>
</auk-authenticate-wrapper>
