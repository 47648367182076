import { Observable, of, switchMap } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

export const DEFAULT_LOADING_INDICATOR_TIME = 150;

/**
 * Use if you want to have some delay on showing loading
 */
export const loadingIndicator = (
  ngZoneUtilService: NgZoneUtilService,
) => (source: Observable<boolean>): Observable<boolean> => source
  .pipe(
    switchMap((isLoading) => {
      if (isLoading) {
        return ngZoneUtilService.timerOut$(DEFAULT_LOADING_INDICATOR_TIME)
          .pipe(
            map(() => true),
            startWith(false),
          );
      } else {
        return of(false);
      }
    }),
  );

