import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[aukNativeDragScrollChild]',
  standalone: true,
})
export class NativeDragScrollChildDirective {

  @HostBinding('class') protected classes: string[] = [
    'tw-flex',
    'tw-no-underline',
    'hover:tw-no-underline',
    'tw-snap-center',
    'tw-basis-[240px]',
    'tw-shrink-0',
    'tw-grow-0',
  ];

  constructor(
    public readonly elementRef: ElementRef<HTMLElement>,
  ) {
  }

}
