import { Injectable } from '@angular/core';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { OldNativeAppUpdateRequiredDialogComponent } from '@shared/native-app/component/old-native-app-update-required-dialog/old-native-app-update-required-dialog.component';
import { take, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { LayoutService } from '@common/layout/layout.service';
import { App, AppInfo } from '@capacitor/app';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root',
})
export class OldNativeAppUpdateRequiredInitializer extends NgUnsubscribe implements AppInitializer {

  constructor(
    private readonly platformService: PlatformService,
    private readonly aukMatDialogService: AukMatDialogService,
    private readonly layoutService: LayoutService,
  ) {
    super();
  }

  public init(): void {
    void this.openModalCheck();
  }

  public openModalCheck(): Promise<void> {
    if (this.platformService.isOldAndroidNativeApp || this.platformService.isOldIosNativeApp) {
      this.openUpdateModal();
    }

    /**
     * This will get version info from Capacitor App and can Force Update to users if version is smaller
     * then MOBILE_UPDATE_VERSION_ANDROID or MOBILE_UPDATE_VERSION_IOS. In future we should use update banner for minor update and
     * non-skippable banner for major updates.
     */
    if (PlatformCommonService.isNativeApp) {
      return App.getInfo().then((info: AppInfo) => {
        const updateVersionAndroid =
          info.version?.localeCompare(environment?.MOBILE_UPDATE_VERSION_ANDROID || '0', undefined, { numeric: true, sensitivity: 'base' });
        const updateVersionIos =
          info.version?.localeCompare(environment?.MOBILE_UPDATE_VERSION_IOS || '0', undefined, { numeric: true, sensitivity: 'base' });

        if ((PlatformCommonService.isNativeAndroidApp && updateVersionAndroid === -1)
          || (PlatformCommonService.isNativeIosApp && updateVersionIos === -1)) {
          this.openUpdateModal();
        }
      });
    }
  }

  private openUpdateModal(): void {
    this.layoutService.updateIsAppScrollable(false);

    this.aukMatDialogService.openSimple$(OldNativeAppUpdateRequiredDialogComponent, {
      width: '100vw',
      height: '100vh',
      minWidth: '100vw',
      minHeight: '100vh',
      enterAnimationDuration: '0s',
      disableClose: true,
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

}
