import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { trimSpaces } from '@util/util/method/trim-spaces.util';

@Directive({
  selector: 'input[aukInputTrim]',
})
export class TrimDirective {

  constructor(
    private readonly renderer2: Renderer2,
    private readonly elementRef: ElementRef<HTMLInputElement>,
  ) {
  }

  @HostListener('change', ['$event.target.value'])
  public onInputChange(value: string): void {
    this.renderer2.setProperty(
      this.elementRef.nativeElement, 'value', trimSpaces(value),
    );

    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
  }

}
