<div class="tw-flex tw-w-full tw-items-center tw-gap-2 tw-pr-0 tw-border-b-surface-accent-3 tw-bg-surface-neutral-1 tw-border-b tw-border-solid tw-py-1">
  <div class="tw-flex tw-items-center tw-flex-[1_0_0] tw-self-stretch">
    <div class="tw-flex tw-w-12 tw-h-12 tw-justify-center tw-items-center tw-gap-2 tw-p-2 tw-cursor-pointer">
      <auk-icon (click)="close()" iconType="SVG" source="chevron_left" size="MD"></auk-icon>
    </div>
    <div class="tw-flex tw-items-center tw-flex-[1_0_0] tw-text-md tw-font-normal tw-leading-[24px]">
      <input
        #searchInputElement
        tabindex="0"
        [placeholder]="{ key: 'MY_AUKRO_HEADER_SEARCH_V2' } | translateSource"
        type="search"
        class="tw-border-0 tw-rounded-2xl tw-text-contrast-opposite tw-bg-transparent tw-text-md tw-h-10 tw-w-full tw-pl-2 md:focus:tw-bg-contrast"
        enterkeyhint="search"
        autocomplete="off"
        (keydown)="handleKeyboardActions.emit($event)"
        (input)="onInputChange()"
        />
    </div>
  </div>
  @if (showClearButton) {
    <div class="tw-mr-2 tw-w-4 tw-h-4">
      <auk-icon iconType="SVG" source="cross-new" size="MD" (click)="clearText()"></auk-icon>
    </div>
  }
  <div aukTestIdentification="search-button" class="tw-flex tw-w-10 tw-rounded-lg tw-h-10 tw-justify-center tw-items-center tw-shrink-0 tw-bg-surface-accent-3 tw-p-2.5 tw-mr-2">
    <auk-icon (click)="searchSubmit()" iconType="SVG" source="new-search" size="MD"></auk-icon>
  </div>
</div>
