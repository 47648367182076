<auk-dialog-wrapper-with-content
    [dialogTitle]="data.textContent.title"
    (closeDialogClick)="closeDialog()"
>
  <ng-container content>
    <div [aukInnerHtml]="data.textContent.body"></div>
    <auk-hint-disabler [hintKey]="'HINT_ADD_WATCH'" class="tw-block tw-mt-5"></auk-hint-disabler>
  </ng-container>

  <ng-container actions>
    <auk-button colorCombination="PRIMARY"
                [chin]="true"
                class="tw-w-full tw-mb-2"
                (buttonClick)="closeDialog()"
    >
      {{ data.textContent.closeButton | translateSource }}
    </auk-button>
  </ng-container>
</auk-dialog-wrapper-with-content>
