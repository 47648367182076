/**
 * Defined the priority of an preloading LCP candidate
 */
export enum PreloadingLcpCandidatePriority {
  LOW = 1,
  LOW_MEDIUM,
  MEDIUM,
  MEDIUM_HIGH,
  HIGH,
}
