import { Injectable } from '@angular/core';
import isEmpty from 'lodash-es/isEmpty';
import { StringUtils } from '@util/util/string.utils';
import isNil from 'lodash-es/isNil';
import { YuspItemResultInternal } from '@shared/services/personalization/model/yusp-item-result-internal.model';
import { Nil } from '@util/helper-types/nil';
import { ProductUtil } from '@shared/util/product/product.util';
import moment from 'moment-mini-ts';
import { ItemCardMapperService } from '@shared/item-card/service/item-card-mapper.service';
import { BasicItemCardItemModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-item.model';
import { ItemCardMapperItemModel } from '@shared/item-card/model/item-card-mapper-item.model';
import { SavingRateModel } from '@shared/retail-price/model/saving-rate.model';
import { ItemScrollSliderDtoType } from '@shared/item-scroll-slider/model/item-scroll-slider-dto.type';
import { ItemCardConfig } from '@shared/item-card/model/item-card-config.model';
import { ItemScrollSliderItemType } from '@shared/item-scroll-slider/model/item-scroll-slider-item.type';
import { ItemScrollSliderItemModel } from '@shared/item-scroll-slider/model/item-scroll-slider-item.model';
import { ItemScrollSliderTrackItemModel } from '@shared/item-scroll-slider/model/item-scroll-slider-track-item.model';
import { ItemViewDto } from '@api/aukro-api/model/item-view-dto';
import { HomepageItemDto } from '@api/aukro-api/model/homepage-item-dto';
import { ListingType } from '@shared/listing/model/listing-type.enum';
import { ItemImagesUtil } from '@shared/item/util/item-images.util';
import { UrlUtils } from '@util/util/url.utils';

@Injectable({
  providedIn: 'root',
})
export class ItemScrollSliderMapperService {

  constructor(
    private readonly itemCardMapperService: ItemCardMapperService,
  ) {
  }

  public mapToBasicItemCard(
    value: ItemScrollSliderItemType,
    dtoType: ItemScrollSliderDtoType,
    savingRatesModel: SavingRateModel,
    itemCardConfig: ItemCardConfig,
    index: number,
    activeListingType: ListingType,
    isWatchLoading: boolean,
  ): ItemScrollSliderItemModel | Nil {
    let mapperItem: ItemCardMapperItemModel | Nil = null;

    switch (dtoType) {
      case 'YuspItemResultInternal':
        mapperItem = this.mapYuspItemResultInternal(value as YuspItemResultInternal);
        break;
      case 'ItemViewDto':
        mapperItem = this.mapItemViewDto(value as ItemViewDto, isWatchLoading);
        break;
      case 'HomepageItemDto':
        mapperItem = this.mapHomepageItemDto(value as HomepageItemDto, isWatchLoading);
        break;
      default:
        mapperItem = null;
    }

    if (isNil(mapperItem)) {
      return null;
    }

    const showCharityChip = mapperItem.charity && activeListingType !== ListingType.CHARITY_OFFERS;

    const cardItem: BasicItemCardItemModel = this.itemCardMapperService.mapToBasicItemCardItem(
      mapperItem,
      savingRatesModel,
      itemCardConfig,
      'SMALL',
      'VERTICAL',
      showCharityChip,
    );

    const trackItem: ItemScrollSliderTrackItemModel = {
      id: cardItem.id,
      position: index + 1,
      name: mapperItem?.name,
      followersCount: mapperItem.watchingInfo?.watchersCount,
      isFollowed: mapperItem.watchingInfo?.isWatchedByCurrentUser,
    };

    return {
      id: cardItem.id,
      cardItem,
      trackItem,
    };
  }

  private mapYuspItemResultInternal(item: YuspItemResultInternal): ItemCardMapperItemModel {
    return {
      id: item.itemId,
      link: this.getRelativeItemLinkFromAbsoluteUrl(item.link, item.itemId),
      name: item.title,
      isTopSeller: StringUtils.parseBoolean(item.sellerAukroPlus),
      itemType: item.type === 'BUYNOW' ? 'BUY_NOW' : 'BIDDING',
      priceInfo: {
        price: item.biddingPrice,
        buyNowActive: false,
        buyNowPrice: item.buyNowPrice,
      },
      endingTime: !isNil(item.endingTime) ? moment.unix(item.endingTime).toISOString() : null,
      mainImageUrl: !isEmpty(item.imageUrlMedium) ? item.imageUrlMedium : null,
      // YUSP should NOT send us adult content at non adult content pages, therefore there is no need to add overlay
      adultRequired: false,
    };
  }

  private getRelativeItemLinkFromAbsoluteUrl(link: string, itemId: number): string | string[] {
    const relativeUrl: string = UrlUtils.getRelativeUrl(link);
    if (!StringUtils.isBlank(relativeUrl)) {
      return relativeUrl;
    }

    return ProductUtil.getOfferUrl({ itemId });
  }

  private mapItemViewDto(item: ItemViewDto, isWatchLoading: boolean): ItemCardMapperItemModel {
    return {
      ...this.mapCommonItemViewAndHomepageItemDto(item, isWatchLoading),
    };
  }

  private mapHomepageItemDto(item: HomepageItemDto, isWatchLoading: boolean): ItemCardMapperItemModel {
    return {
      ...this.mapCommonItemViewAndHomepageItemDto(item, isWatchLoading),
      endingTime: item.endingTime,
    };
  }

  private mapCommonItemViewAndHomepageItemDto(item: ItemViewDto | HomepageItemDto, isWatchLoading: boolean): ItemCardMapperItemModel {
    return {
      id: item.id,
      itemType: item.itemTypeEnumCode === 'BUYNOW' ? 'BUY_NOW' : 'BIDDING',
      name: item.name,
      link: ProductUtil.getOfferUrl({ seoUrl: item.seoUrl, itemId: item.id }),
      priceInfo: {
        price: item.biddingPrice,
        buyNowActive: item.buyNowActive,
        buyNowPrice: item.buyNowPrice,
        retailPrice: item.retailPrice,
      },
      mainImageUrl: ItemImagesUtil.getTitleImage(item.images, 'MEDIUM_PREVIEW'),
      adultRequired: item.statusAdultContent,
      paymentViaAukro: item.buyersProtectionAvailable,
      isTopSeller: item.topSeller,
      categoryPath: item.categoryPath,
      buyersCount: item.bidderCount,
      biddersCount: item.bidderCount,
      attributes: item.attributes,
      state: item.status,
      watchingInfo: {
        watchersCount: item.watchersCount,
        isWatchedByCurrentUser: item.userWatching,
        isLoading: isWatchLoading,
      },
      collectionStartingTime: item.collectionStartingTime,
      charity: item.charity,
      buyersProtectionAvailable: item.buyersProtectionAvailable,
    };
  }

}
