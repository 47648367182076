/**
 *
 * @param value
 */
export function trimSpaces(value: string): string {
  if (typeof value !== 'string') {
    return value;
  }
  return value.replace(/\s{2,}/g, ' ').trim();
}
