<div class="tw-flex tw-rounded-xl tw-justify-center tw-items-start tw-w-full tw-gap-1.5 tw-p-1.5"
  [ngClass]="getBackgroundClass">
  @for (tab of tabList; track trackByOptionIdFn($index, tab)) {
    <auk-button
      [colorCombination]="selectedTabId === tab.id ? tab.backgroundColor : 'TRANSPARENT'"
      [class.tw-h-10]="type === 'NORMAL'"
      [class.tw-h-8]="type === 'COMPACT'"
      class="tw-flex-1 tw-gap-2"
      size="SM-NORMAL"
      [chin]="false"
      (buttonClick)="onChangeSelectedTab(tab.id)"
      >
      {{ tab.label | translateSource }}
    </auk-button>
  }
</div>
