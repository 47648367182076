import { Injectable } from '@angular/core';
import { filter, from, Observable, switchMap } from 'rxjs';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import isNil from 'lodash-es/isNil';

@Injectable({
  providedIn: 'root',
})
export class SocialLoginService {

  constructor(
    private readonly socialAuthService: SocialAuthService,
  ) {
  }

  public signInWithFb(): Observable<SocialUser> {
    return this.socialAuthService.initState
      .pipe(
        switchMap(() => from(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID))
          .pipe(
            filter((socialUser: SocialUser) => !isNil(socialUser) && socialUser.provider === 'FACEBOOK'),
          )));
  }

}
