import { Pipe, PipeTransform } from '@angular/core';

import { TimeToEnd, timeToEnd } from '@util/util/time-to-end.util';

@Pipe({
  name: 'relativeTime',
  standalone: true,
})
export class RelativeTimePipe implements PipeTransform {

  public transform(date: string): string {
    if (date) {
      const relativeDate: TimeToEnd = timeToEnd(date);
      const prefix: string = relativeDate.past ? 'před' : 'za';
      return prefix + ' ' + relativeDate.label;
    }
    return date;
  }

}
