import { Injectable } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ModalViewsConfigService } from '@shared/user-task/base/service/modal-views-config.service';
import { DomainPickerDialogComponent } from '@shared/domain-picker/domain-picker-dialog/domain-picker-dialog.component';
import { filter, take, takeUntil } from 'rxjs/operators';
import { DomainCode } from '@shared/platform/domain.model';
import isNil from 'lodash-es/isNil';
import { ICULocale } from '@shared/platform/icu-locale.model';
import { switchMap } from 'rxjs';
import { DialogModel } from '@common/dialog/model/dialog.model';
import { AukDialogType } from '@shared/dialog/model/auk-dialog.type';
import { DialogUtil } from '@common/dialog/utils/dialog.util';
import { LocaleConstants } from '@shared/platform/locale.constants';
import { DomainPickerMatDialogConfigModel } from '@shared/domain-picker/model/domain-picker-mat-dialog-config.model';
import { DomainService } from '@shared/platform/domain.service';
import { MatDialogService } from '@common/dialog/service/mat-dialog.service';

const DOMAIN_PICKER_MODAL: DialogModel<AukDialogType> = DialogUtil.of('NAVIGATION', 'domain-picker');

@Injectable({
  providedIn: 'root',
})
export class DomainPickerService extends NgUnsubscribe {

  constructor(
    private readonly modalViewsConfigService: ModalViewsConfigService,
    private readonly matDialogService: MatDialogService<AukDialogType>,
    private readonly domainService: DomainService,
  ) {
    super();
  }

  public openRegionPreferenceModal(currentLocale: ICULocale): void {
    this.matDialogService.open$<DomainPickerDialogComponent, DomainPickerMatDialogConfigModel, DomainCode>(
      DomainPickerDialogComponent,
      DOMAIN_PICKER_MODAL,
      {
        ...this.modalViewsConfigService.getModalConfig(),
        data: {
          domainList: LocaleConstants.DOMAIN_LIST,
          actualDomain: currentLocale,
        },
      },
    )
      .pipe(
        switchMap((dialogRef) => dialogRef.afterClosed()),
        filter((dialogResult: DomainCode) => !isNil(dialogResult)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((selectedDomain) => void this.domainService.saveDomainPreference(selectedDomain));
  }

}
