import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { JWTInfoWithAukroToken } from '../model/jwt-info-with-aukro-token';
import { LoginVM } from '../model/login-vm';
import { BASE_PATH } from '../variables';

export interface AuthenticateRequestParams {
    loginVM: LoginVM;
    platformType?: 'WEB' | 'OLD_MOBILE_APP' | 'NATIVE_APP_ANDROID' | 'NATIVE_APP_IOS';
    userAgent?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticateApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Authenticate user
   */
  public authenticate$(
    requestParameters: AuthenticateRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<JWTInfoWithAukroToken> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      platformType: requestParameters.platformType,
      userAgent: requestParameters.userAgent,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.loginVM,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'JWTInfoWithAukroToken'),
      headers,
    };
    return this.httpClient.request<JWTInfoWithAukroToken>(
      'post',
      `${ BASE_PATH }/authenticate`,
      options,
    );
  }

}
