import isNil from 'lodash-es/isNil';
import moment, { Moment } from 'moment-mini-ts';

export class CalendarUtil {

  public static daysLabels: string[] = [
    'Neděle',
    'Pondělí',
    'Úterý',
    'Středa',
    'Čtvrtek',
    'Pátek',
    'Sobota',
  ];

  public static monthsLabel: string[] = [
    'leden',
    'únor',
    'březen',
    'duben',
    'květen',
    'červen',
    'červenec',
    'srpen',
    'září',
    'říjen',
    'listopad',
    'prosinec',
  ];

  public static get next30daysIncludingToday(): { value: Date; label: string }[] {
    let currentDate = new Date();
    const days: { value: Date; label: string }[] = [];

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const addDays = function(days: number): Date {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    for (let i = 0; i < 30; i++) {
      const label = CalendarUtil.getDayFullLabel(currentDate);
      days.push({ value: currentDate, label });
      currentDate = addDays.call(currentDate, 1);
    }
    return days;
  }

  public static get hours(): { value: number; label: string }[] {
    const hours: { value: number; label: string }[] = [];
    for (let i = 0; i < 24; i++) {
      const value = i;
      const label = i > 9 ? i.toString() : '0' + i.toString();
      hours.push({ value, label });
    }
    return hours;
  }

  public static get minutes(): { value: number; label: string }[] {
    const minutes: { value: number; label: string }[] = [];
    for (let i = 0; i < 60; i += 5) {
      const value = i;
      const label = i > 9 ? i.toString() : '0' + i.toString();
      minutes.push({ value, label });
    }
    return minutes;
  }

  public static getDayFullLabel(date: Date): string {
    const dayLabel: string = CalendarUtil.daysLabels[date.getDay()];
    const monthLabel: string = CalendarUtil.monthsLabel[date.getMonth()];
    const dayNumber: number = date.getDate();
    return `${ dayLabel } - ${ dayNumber } ${ monthLabel }`;
  }

  public static isToday(date: string | Date): boolean {
    return CalendarUtil.isSameDay(moment(date), moment());
  }

  public static isSameDay(dayOne: Moment, dayTwo: Moment): boolean {
    if (isNil(dayOne) || isNil(dayTwo)) {
      return false;
    }
    return dayOne.isSame(dayTwo, 'day');
  }

  public static isSameYear(yearOne: Moment, yearTwo: Moment): boolean {
    if (isNil(yearOne) || isNil(yearTwo)) {
      return false;
    }
    return yearOne.isSame(yearTwo, 'year');
  }

}
