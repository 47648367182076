import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { IntersectionStatus } from '../model/intersection-status.type';

export const fromIntersectionObserver = (
  elements: Element[],
  config: IntersectionObserverInit,
  unload: boolean,
): Observable<{ status: IntersectionStatus; value: string }> =>
  new Observable<{ status: IntersectionStatus; value: string }>(subscriber => {
    const subject$ = new Subject<{
      entry: IntersectionObserverEntry;
      observer: IntersectionObserver;
    }>();

    const intersectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          subject$.next({ entry, observer });
        });
      },
      config,
    );

    const subscription = subject$
      .pipe(
        filter(Boolean),
        tap(({ entry, observer }) => {
          const isEntryVisible = entry.isIntersecting || entry.intersectionRatio > 0;

          if (isEntryVisible) {
            subscriber.next({ status: IntersectionStatus.Visible, value: entry.target.id });
            if (!unload) {
              observer.unobserve(entry.target);
            }
          } else {
            if (unload) {
              subscriber.next({ status: IntersectionStatus.NotVisible, value: entry.target.id });
            }
          }
        }),
      )
      .subscribe();

    elements.forEach((element) => {
      intersectionObserver.observe(element);
    });

    return {
      unsubscribe(): void {
        intersectionObserver.disconnect();
        subscription.unsubscribe();
      },
    };
  });
