@if (canShow) {
  <div
    class="go-back-button z-2 tw-inline-block"
    aukTestIdentification="go-back-button"
    aukAccessibilityId="ACS_GO_BACK_BUTTON"
    [class.global-go-back]="isGlobalButton"
    >
    <i class="material-icons cursor-pointer" (click)="goBack()">
      keyboard_arrow_left
    </i>
  </div>
}
