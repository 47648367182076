@if (tab) {
  <a
    class="tw-flex tw-items-center tw-justify-center !tw-no-underline tw-px-4 tw-py-2 tw-bg-silverstein-100 tw-h-full tw-cursor-pointer active:tw-bg-surface-3 tw-border-solid tw-border-b-[3px] tw-border-b-silverstein-100 tw-whitespace-nowrap"
    [aukDynamicColor]="true"
    [aukLinkFromHref]="tab.url"
    [enableInAppNavigation]="false"
    [matTooltip]="tooltip | translateSource"
    (mouseenter)="onTabHover()"
    (mouseleave)="onTabHoverEnd()"
    (click)="onTabClick($event)"
    [matTooltipDisabled]="tooltipDisabled"
    >
    @if (tab.icon) {
      <auk-icon
        class="tw-cursor-pointer tw-pt-[3px]"
        [class.tw-mr-2]="doesLabelExist"
        iconType="SVG"
        [source]="tab.icon"
        [size]="doesLabelExist ? 'SM' : 'LG'"
        [colorCombination]="tab.theme"
      ></auk-icon>
    }
    @if (doesLabelExist) {
      <span class="tw-text-sm tw-font-normal tw-text-contrast-opposite tw-pt-[3px]">{{ tab.label | translateSource }}</span>
    }
  </a>
}
