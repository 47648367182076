import { Injectable } from '@angular/core';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { CookieConsentService } from '@shared/cookie-consent/service/cookie-consent.service';
import { CookieConsentVendorEnum } from '@shared/cookie-consent/model/cookie-consent-vendor.enum';
import { VivnetworksService } from '@shared/services/vivnetworks/vivnetworks.service';

@Injectable({
  providedIn: 'root',
})
export class VivnetworksInitializer implements AppInitializer {

  constructor(
    private readonly cookieConsentService: CookieConsentService,
    private readonly vivnetworksService: VivnetworksService,
  ) {
  }

  public init(): void {
    // save cjevent if user has chosen to give a cookie consent for Vivnetworks
    const cookieConsentCallback: (status: boolean) => void = (status: boolean) => {
      if (status === true) {
        this.vivnetworksService.saveVivnetworksCje();
      }
    };

    this.cookieConsentService.executeWhenConsentStatusRecognized(CookieConsentVendorEnum.VIVNETWORKS, cookieConsentCallback);
  }

}
