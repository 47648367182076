import { Component, Input } from '@angular/core';
import { environment } from '@environment';
import { AdminClipboardService } from '@shared/admin-clipboard/admin-clipboard.service';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { Translate2Module } from '@common/translations/translate2.module';

import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';

@Component({
  selector: 'auk-user-profile-admin-actions',
  templateUrl: './user-profile-admin-actions.component.html',
  standalone: true,
  imports: [
    IconComponent,
    Translate2Module,
    LinkFromHrefDirective,
  ],
})
export class UserProfileAdminActionsComponent {

  @Input() public userId: number;

  @Input() public isUserLinkVisible: boolean = false;

  @Input() public isClipboardIconVisible: boolean = false;

  constructor(
    private readonly adminClipboardService: AdminClipboardService,
  ) {
  }

  public get userDetailAdminLink(): string {
    return `${ environment.APP_BACKOFFICEFRONTEND_HOST }user/${ this.userId }`;
  }

  public openClipboardDialog(): void {
    this.adminClipboardService.openClipboardDialogForUsers(this.userId?.toString());
  }

}
