import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { CartCheckoutDto } from '../model/cart-checkout-dto';
import { CartCheckoutFormDto } from '../model/cart-checkout-form-dto';
import { CartDetailDto } from '../model/cart-detail-dto';
import { CartItemFormDto } from '../model/cart-item-form-dto';
import { CartOverviewDto } from '../model/cart-overview-dto';
import { CartThankYouDto } from '../model/cart-thank-you-dto';
import { EmailDto } from '../model/email-dto';
import { PaymentDetailDto } from '../model/payment-detail-dto';
import { BASE_PATH } from '../variables';

export interface CheckoutCart1RequestParams {
    cartCheckoutFormDto: CartCheckoutFormDto;
}

export interface DeleteCartItemRequestParams {
    /** Cart item ID */
    id: number;
}

export interface GetPaymentDetailRequestParams {
    paymentId: number;
}

export interface InsertCartItemRequestParams {
    cartItemFormDto: CartItemFormDto;
}

export interface SaveCartEmailRequestParams {
    emailDto: EmailDto;
}

export interface ShowCartOptionsChoiceRequestParams {
    /** Cart item ids */
    cartItemIds: number[];
}

@Injectable({
  providedIn: 'root',
})
export class CartApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Checkout cart.
   */
  public checkoutCart1$(
    requestParameters: CheckoutCart1RequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CartThankYouDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.cartCheckoutFormDto,
      responseType: this.configuration.getResponseType(false, 'CartThankYouDto'),
      headers,
    };
    return this.httpClient.request<CartThankYouDto>(
      'post',
      `${ BASE_PATH }/cart/checkoutCart`,
      options,
    );
  }

  /**
   * Delete item from the shopping cart.
   */
  public deleteCartItem$(
    requestParameters: DeleteCartItemRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<boolean> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'boolean'),
      headers,
    };
    return this.httpClient.request<boolean>(
      'delete',
      `${ BASE_PATH }/cart/item/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }`,
      options,
    );
  }

  /**
   * Get details about a payment
   */
  public getPaymentDetail$(
    requestParameters: GetPaymentDetailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PaymentDetailDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentId: requestParameters.paymentId,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PaymentDetailDto'),
      headers,
    };
    return this.httpClient.request<PaymentDetailDto>(
      'get',
      `${ BASE_PATH }/cart/paymentDetail`,
      options,
    );
  }

  /**
   * Insert item in to the shopping cart. Cart info is returned.
   */
  public insertCartItem$(
    requestParameters: InsertCartItemRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CartOverviewDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.cartItemFormDto,
      responseType: this.configuration.getResponseType(false, 'CartOverviewDto'),
      headers,
    };
    return this.httpClient.request<CartOverviewDto>(
      'post',
      `${ BASE_PATH }/cart/item`,
      options,
    );
  }

  /**
   * Save cart email
   */
  public saveCartEmail$(
    requestParameters: SaveCartEmailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.emailDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/cart/saveEmail`,
      options,
    );
  }

  /**
   * Show basic information about shopping cart
   */
  public showCart$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CartOverviewDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'CartOverviewDto'),
      headers,
    };
    return this.httpClient.request<CartOverviewDto>(
      'get',
      `${ BASE_PATH }/cart`,
      options,
    );
  }

  /**
   * Show content of cart
   */
  public showCartDetail$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CartDetailDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'CartDetailDto'),
      headers,
    };
    return this.httpClient.request<CartDetailDto>(
      'get',
      `${ BASE_PATH }/cart/detail`,
      options,
    );
  }

  /**
   * Show form with shipping options and payment options.
   */
  public showCartOptionsChoice$(
    requestParameters: ShowCartOptionsChoiceRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CartCheckoutDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      cartItemIds: requestParameters.cartItemIds,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'CartCheckoutDto'),
      headers,
    };
    return this.httpClient.request<CartCheckoutDto>(
      'post',
      `${ BASE_PATH }/cart/cartOptionsChoice`,
      options,
    );
  }

}
