import { MoneyDto } from '@api/generated/defs/MoneyDto';
import isNil from 'lodash-es/isNil';
import { MoneyUtils } from '@shared/currency/util/money.utils';

export class MoneyCalcUtils {

  public static sum(a: MoneyDto, b: MoneyDto): MoneyDto {
    return this.operation(a, b, () => a.amount + b.amount);
  }

  public static sumNum(a: MoneyDto, b: number): MoneyDto {
    const bMoney = MoneyUtils.of(b, a.currency);
    return this.operation(a, bMoney, () => a.amount + bMoney.amount);
  }

  public static sumRounded(a: MoneyDto, b: MoneyDto): MoneyDto {
    return this.operation(a, b, () => Math.round((a.amount + b.amount) * 100) / 100);
  }

  public static sub(a: MoneyDto, b: MoneyDto): MoneyDto {
    return this.operation(a, b, () => a.amount - b.amount);
  }

  public static smallerThan(a: MoneyDto, b: MoneyDto): boolean {
    if (a.currency !== b.currency) {
      throw new Error('Mixing currencies not allowed');
    }

    return a.amount < b.amount;
  }

  public static biggerThen(a: MoneyDto, b: MoneyDto): boolean {
    if (a.currency !== b.currency) {
      throw new Error('Mixing currencies not allowed');
    }

    return a.amount > b.amount;
  }

  public static div(a: MoneyDto, b: number): MoneyDto {
    return this.operation(a, { amount: b, currency: a?.currency },
      () => isNil(b) || b === 0 ? a.amount : a.amount / b);
  }

  public static multiply(a: MoneyDto, b: number): MoneyDto {
    return this.operation(a, { amount: b, currency: a?.currency },
      () => isNil(b) || b === 0 ? a.amount : a.amount * b);
  }

  public static negate(a: MoneyDto): MoneyDto {
    return this.multiply(a, -1);
  }

  private static operation(a: MoneyDto, b: MoneyDto, amountOperation: (a: MoneyDto, b: MoneyDto) => number): MoneyDto {
    if (isNil(a)) {
      return b;
    }
    if (isNil(b)) {
      return a;
    }
    if (a.currency !== b.currency) {
      throw new Error('Mixing currencies not allowed');
    }

    return { amount: amountOperation(a, b), currency: a.currency };
  }

}
