<div class="tw-mx-2 tw-mt-2">
  <div
    class="tw-flex tw-items-center tw-rounded-full tw-cursor-pointer lg:tw-max-w-xl tw-w-full tw-bg-surface-neutral-2 tw-border tw-border-solid tw-border-transparent"
    [ngClass]="searchInFocus ? 'md:tw-border-surface-yellow-4 md:tw-shadow-spread md:tw-bg-contrast' : 'md:hover:tw-border-surface-neutral-5'"
    >
    <!-- SEARCH BUTTON -->
    <div class="tw-ml-4 tw-w-4 tw-h-4">
      <auk-svg-icon
        iconId='search-new'
        alt="search icon"
        (click)="searchClick($event)"
      ></auk-svg-icon>
    </div>
    <!-- ./SEARCH BUTTON -->

    <!-- SEARCH INPUT -->
    <input
      #searchInputElement
      [placeholder]="{ key: 'MY_AUKRO_HEADER_SEARCH_V2' } | translateSource"
      [readonly]="type === 'dummy'"
      type="search"
      class="tw-border-0 tw-rounded-2xl tw-text-contrast-opposite tw-bg-transparent tw-text-md tw-h-10 tw-w-full tw-pl-2 md:focus:tw-bg-contrast"
      enterkeyhint="search"
      id="headerSearchInput"
      aukTestIdentification="search-input"
      autocomplete="false"
      (focus)="inputFocused(true)"
      (focusout)="inputFocused(false)"
      (click)="inputClick.emit($event)"
      (keydown)="inputKeydown.emit($event)"
      (input)="inputType.emit()"
      />
    <!-- ./SEARCH INPUT -->

    <!-- SEARCH CATEGORIES SELECT -->
    @if (!isMdAndLower) {
      <auk-search-categories-list
        [categories]="searchCategories"
        (focus)="inputFocused(false)"
        [selectedCategory]="searchCategory"
        (categoryChange)="categoryChange.emit($event)"
        [fitToContent]="true"
      ></auk-search-categories-list>
    }
    <!-- ./SEARCH CATEGORIES SELECT -->

    <!-- SEARCH BUTTON -->
    @if (showSearchButton) {
      <auk-button
        class="tw-h-[26px] tw-mr-2"
        size="SM-NORMAL"
        radius="ROUNDED"
        colorCombination="PRIMARY"
        [chin]="true"
        (buttonClick)="searchClick($event)"
        >
        {{ 'MY_AUKRO_FILTERS_SEARCH' | translate }}
      </auk-button>
    }
    <!-- ./SEARCH BUTTON -->
  </div>
</div>
