<div (click)="onAvatarClick()" class="tw-flex">
  @if (url && !avatarImgLoadError) {
    <img
      [aukReplaceByPlaceholderOnError]="url"
      (imageLoadError)="onAvatarImgLoadError()"
      [alt]="{ key: 'AVATAR_COMPONENT_HTML_2' } | translateSource"
      [class.avatar-img-sm]="avatarSize === 'SM'"
      [class.avatar-img-md]="avatarSize === 'MD'"
      [class.avatar-img-lg]="avatarSize === 'LG'"
      [class.avatar-img-xl]="avatarSize === 'XL'"
      class="tw-rounded-full tw-border tw-border-solid tw-border-surface-neutral-020">
  } @else {
    <span class="material-icons pointer-events-none user-icon"
          [class.avatar-img-sm]="avatarSize === 'SM'"
          [class.avatar-img-md]="avatarSize === 'MD'"
          [class.avatar-img-lg]="avatarSize === 'LG'"
          [class.avatar-img-xl]="avatarSize === 'XL'"
    >account_circle
    </span>
  }

  @if (!isReadOnly && showUploadAnchor) {
    <span
      class="m-t-1 display-block p-absolute text-center full-width">
      {{ { key: 'MY_AUKRO_MY_DATA_UPLOAD' } | translateSource }}
    </span>
  }

  <i class="material-icons camera-icon text-white absolute-center">camera_alt</i>
</div>
@if (!avatarSize) {
  @if (showAccountState && badgeIcon) {
    <span class="icon-wrapper">
      <auk-svg-icon-legacy
        [class.no-avatar]="!url"
        [id]="badgeIcon.icon"
        aukToolTip2
        placement="top"
        [showDelay]="250"
        [delayOnClose]="0"
        [tooltipModel]="$any({
          type: 'INFORMATIVE',
          text: { key: badgeIcon.info },
          size: 'SM',
        })"
      />
    </span>
  }
} @else {
  @if (showAccountState && badgeIcon) {
    <div class="icon-wrapper"
         [class.icon-wrapper-sm]="avatarSize === 'SM'"
         [class.icon-wrapper-lg]="avatarSize === 'LG'"
         [class.icon-wrapper-xl]="avatarSize === 'XL'">
      <auk-svg-icon
        [class.no-avatar]="!url"
        [iconId]="badgeIcon.icon"
        aukToolTip2
        placement="top"
        [showDelay]="250"
        [delayOnClose]="0"
        [tooltipModel]="$any({
          type: 'INFORMATIVE',
          text: { key: badgeIcon.info },
          size: 'SM',
        })"
      />
    </div>
  }
}

<!--
Once we will do all types of userChips, we will use only auk-svg-icon.
Currently there are some styles, which are linked to page and legacy icon, so we cant delete it now
-->

