import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[aukAccordionItemContent]',
})
export class AccordionItemContentDirective {

  public constructor(public readonly templateRef: TemplateRef<unknown>) {}

}
