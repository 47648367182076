import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'auk-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWrapperComponent {

  @Input()
  public showCloseInHeading: boolean = true;

  @Output()
  public closeDialogClick: EventEmitter<void> = new EventEmitter<void>();

  public onCloseDialogClick(): void {
    this.closeDialogClick.emit();
  }

}
