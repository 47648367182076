<h2 mat-dialog-title>
  {{ 'DIALOG_CHANGE_AVATAR_COMPONENT_HTML_1' | translate }}
  <a aukPreventDefaultLink (click)="close()" class="m-l-auto"><i class="material-icons big">close</i></a>
</h2>
<mat-dialog-content
  class="mat-typography"
  [class.drop-area-active]="isDropAreaActive"
  (drop)="onDropFile($event)"
  [attr.data-drop-area-active-text]="{ key: 'SET_AVATAR_DRAG_HERE' } | translateSource"
  >
  @if (errorMessage) {
    <auk-alert-banner-legacy type="WARNING">
      {{ errorMessage | translateSource }}
    </auk-alert-banner-legacy>
  }
  @if (image) {
    @if (isEditableImage) {
      <div class="croppie-area">
        <div #croppieArea></div>
      </div>
    } @else {
      <img [src]="image.src" class="static-image" alt="">
    }
    @if (uploadInProgress) {
      <div>{{ 'DIALOG_CHANGE_AVATAR_COMPONENT_HTML_2' | translate }}</div>
    }
  } @else {
    <div class="no-image text-center line-height m-t-3">
      <p class="h3">
        {{ 'DIALOG_CHANGE_AVATAR_COMPONENT_HTML_3' | translate }}<br>
        {{ 'DIALOG_CHANGE_AVATAR_COMPONENT_HTML_4' | translate }}
      </p>
      <span class="material-icons text-gray m-t-4 display-block m-b-4">account_circle</span>
      <p class="m-b-3">
        {{ 'DIALOG_CHANGE_AVATAR_COMPONENT_HTML_5' | translate }}
      </p>
    </div>
  }
  <input type="file"
    [accept]="allowedFileFormats"
    (change)="onFileSelected($event)"
    #fileInput
    >
</mat-dialog-content>
<mat-dialog-actions>
  @if (image) {
    <a aukPreventDefaultLink
      (click)="remove(true)"
      class="btn-secondary fluid-max-tablet m-b-2-max-tablet m-r-2-min-tablet"
    >{{ 'DIALOG_CHANGE_AVATAR_COMPONENT_HTML_6' | translate }}</a>
    <a aukPreventDefaultLink
      (click)="openFileInput()"
      class="btn-secondary fluid-max-tablet m-b-2-max-tablet m-r-auto"
    >{{ 'DIALOG_CHANGE_AVATAR_COMPONENT_HTML_7' | translate }}</a>
    @if (isEditableImage) {
      <a aukPreventDefaultLink
        (click)="save()"
        class="btn-primary fluid-max-tablet"
      >{{ 'DIALOG_CHANGE_AVATAR_COMPONENT_HTML_8' | translate }}</a>
    }
  } @else {
    <a aukPreventDefaultLink
      (click)="openFileInput()"
      class="btn-primary fluid-max-tablet m-l-auto"
    >{{ 'DIALOG_CHANGE_AVATAR_COMPONENT_HTML_9' | translate }}</a>
  }
</mat-dialog-actions>
