import { TileModel } from '@common/ui-kit/component/tile/model/tile.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { MouseUtils } from '@util/util/mouse.utils';

@Component({
  template: '',
})
export abstract class BaseTileComponent<T> {

  @Input() public tile: TileModel<T>;

  @Output() public tileClick: EventEmitter<T> = new EventEmitter<T>();

  protected onTileClick(event: MouseEvent): void {
    // if ctrl or meta key is pressed and link is not nil, do nothing (so the link will be opened in the new tab)
    // and nothing more happens
    if (!MouseUtils.canDoAppNavigation(event) && isNotNil(this.tile.link)) {
      return;
    }

    // We do not want to open link by default
    // Link is rendered only for SSR usage and for opening in a new tab
    event.preventDefault();

    this.tileClick.emit(this.tile.data);
  }

}
