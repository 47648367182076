import { Directive, ElementRef, EventEmitter, Output, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { Nil } from '@util/helper-types/nil';

/**
 *
 * @example
 * ```html
 * <p
 *  class="foo"
 *  aukViewportDetector
 *  (inViewportDetectorChange)="myEventHandler($event)">
 *  bar
 * </p>
 * ```
 */
@Directive({
  selector: '[aukViewportDetector]',
  standalone: true,
})
export class InViewportDetectorDirective implements AfterViewInit, OnDestroy {

  @Input() public inViewportDetectorOptions: IntersectionObserverInit;
  @Input() public checkOncePerPage: boolean = true;
  @Input() public checkOnlyForOneItem: boolean = false;
  @Input() public emitInSsr: boolean = true;

  @Output() public inViewportDetectorChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private intersectionObserver: IntersectionObserver | Nil;
  private inViewport: boolean = false;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly platformCommonService: PlatformCommonService,
  ) {
  }

  public ngAfterViewInit(): void {
    //For SSR we need to fake that everything is in viewport, as we need to show all data
    if (this.platformCommonService.isServer) {
      if (!this.emitInSsr) {
        return;
      }
      this.inViewport = true;
      this.inViewportDetectorChange.emit(this.inViewport);
      return;
    }
    this.intersectionObserver = new IntersectionObserver(
      (entries) => this.intersectionObserverCallback(entries),
      this.inViewportDetectorOptions,
    );
    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }

  public ngOnDestroy(): void {
    this.intersectionObserver?.disconnect();
  }

  private intersectionObserverCallback(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      // check only one element and unobserve rest of elements
      if (this.checkOnlyForOneItem) {
        this.inViewport = entry.isIntersecting;
        this.inViewportDetectorChange.emit(this.inViewport);
        this.intersectionObserver.unobserve(entry.target);
      } else {
        if (this.inViewport === entry.isIntersecting && this.checkOnlyForOneItem === false) {
          return;
        }
        this.inViewport = entry.isIntersecting;
        this.inViewportDetectorChange.emit(this.inViewport);
        if (this.checkOncePerPage && this.inViewport && this.checkOnlyForOneItem === false) {
          this.intersectionObserver.unobserve(entry.target);
        }
      }
    });
  }

}
