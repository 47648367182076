import { Injectable } from '@angular/core';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';
import { UserActionPrerequisitePersonalDataFulfillmentService } from './user-action-prerequisite-personal-data-fulfillment.service';
import { UserTaskPayloadModel } from '@shared/user-task/base/model/user-task-payload.model';

@Injectable({
  providedIn: 'root',
})
export class UserActionPrerequisitePersonalDataAndOptionalVerifyPhoneFulfillmentService
  extends UserActionPrerequisitePersonalDataFulfillmentService<['FILLED_PERSONAL_DATA']> {

  public readonly prerequisiteCombination: ['FILLED_PERSONAL_DATA'] = ['FILLED_PERSONAL_DATA'];

  protected readonly customTaskPayload: Partial<UserTaskPayloadModel<typeof this.taskType>> = {
    enforceVerifyPhone: false,
  };

  constructor(
    userTaskService: UserTaskService,
  ) {
    super(userTaskService);
  }

}
