import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FavouritePopoverDataModel } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-popover-data.model';
import { FavouriteItemModel } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-item.model';
import { takeUntil } from 'rxjs/operators';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { trackByIndexFn } from '@util/helper-functions/track-by/track-by-index.fn';
import { FavouriteClickModel } from '@shared/favourite/model/favourite-click.model';
import { FavouriteTabModelNameEnum } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-tab-name.type';
import { FavouriteTabModel } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-tab.model';

@Component({
  selector: 'auk-favourite-popover',
  templateUrl: './favourite-popover.component.html',
  styleUrls: ['./favourite-popover.component.scss'],
})
export class FavouritePopoverComponent extends NgUnsubscribe {

  @Output() public buyItem: EventEmitter<FavouriteItemModel> = new EventEmitter<FavouriteItemModel>();
  @Output() public measuredClick: EventEmitter<FavouriteClickModel> = new EventEmitter<FavouriteClickModel>();
  @Output() public tabClick: EventEmitter<FavouriteTabModelNameEnum> = new EventEmitter<FavouriteTabModelNameEnum>();
  @Output() public redirectClick: EventEmitter<string> = new EventEmitter<string>();

  @Input() public favourites: FavouritePopoverDataModel;

  protected readonly trackByIndexFn = trackByIndexFn;

  protected activeTab: string = 'ITEMS';
  protected isMdAndLower: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly responsivenessService: ResponsivenessService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {

    super();

    this.responsivenessService.isMdAndLower$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMdAndLower) => {
        this.isMdAndLower = isMdAndLower;
        this.changeDetectorRef.markForCheck();
      });
  }

  protected handleBuyItem(item: FavouriteItemModel): void {
    this.buyItem.emit(item);
  }

  protected handleMeasuredClick(click: FavouriteClickModel): void {
    this.measuredClick.emit(click);
  }

  protected onTabClick(tabName: FavouriteTabModelNameEnum): void {
    this.tabClick.emit(tabName);
    this.activeTab = tabName;
  }

  protected showAllClicked(): void {
    const tabToRedirectTab: FavouriteTabModel = this.favourites.favouriteTabs.find(tab => tab.name === this.activeTab);
    if (tabToRedirectTab?.url) {
      this.measuredClick.emit(
        {
          tab: tabToRedirectTab.name,
          properties: {
            action: 'showAll',
          },
        });
      void this.router.navigate([tabToRedirectTab.url]);
    }
  }

  protected shouldShowFooter(): boolean {
    if (this.activeTab === 'ITEMS' && this.favourites?.favouriteItemsPreviewData?.countOfAllFavouriteItems > 0) {
      return true;
    } else if (this.activeTab === 'SELLERS' && this.favourites?.favouriteSellersPreviewData?.countOfAllFavouriteSellers > 0) {
      return true;
    } else if (this.activeTab === 'SEARCH' && this.favourites?.countOfAllFavouriteSearchTerms > 0) {
      return true;
    }
    return false;
  }

}
