@if (gridMenuItems?.length) {
  <div class="tw-flex tw-flex-wrap tw-py-6"
       [ngClass]="{'tw-justify-between': justifyContentBetween }"
  >
    @for (subItem of gridMenuItems; let index = $index; track trackByTitleFn) {
      <div class="tw-relative"
           [ngClass]="{'tw-w-1/4': !justifyContentBetween }">

        @if (subItem.imageSrcUrl) {
          <div class="tw-w-1/6">
            <img class="tw-absolute tw-left-0 tw-top-0"
                 [src]="subItem.imageSrcUrl"
                 alt="Icon"
            >
          </div>
        }

        <div class="tw-text-contrast-opposite tw-text-md tw-font-bold tw-pb-4"
             [aukInnerHtml]="subItem.title"
        >
        </div>

        @if (subItem.items?.length) {
          <ul class="tw-flex tw-flex-col tw-gap-4 tw-text-sm">
            @for (item of subItem.items; let index = $index; track index) {
              <li [aukInnerHtml]="item"></li>
            }
          </ul>
        }

      </div>
    }

  </div>
}

