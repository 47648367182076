import { InjectionToken } from '@angular/core';

export const SERVER_TIME_INTERCEPTOR_CONFIG = new InjectionToken<ServerTimeInterceptorConfig>('serverTimeInterceptorConfig');

export interface ServerTimeInterceptorConfig {
  /**
   * Server time response header name
   */
  responseHeaderName: string;
  /**
   * Endpoints names with server time header in response
   */
  endpoints: string[];
  /**
   * Frames slower than this treshold are not taken in account
   */
  maxTresholdMilis: number;
}
