import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '@environment';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AdminClipboardAddDialogComponent } from '../admin-clipboard-add-dialog/admin-clipboard-add-dialog.component';
import isNil from 'lodash-es/isNil';
import { ChooseAdminClipboardResult } from '../admin-clipboard-add-dialog/model/choose-admin-clipboard-result.interface';
import { ToastService } from '@common/toast/service/toast.service';
import { AddParams, ClipboardResourceService } from '@api/generated/api/Clipboard';
import { ClipboardDto } from '@api/generated/defs/ClipboardDto';

@Injectable({
  providedIn: 'root',
})
export class AdminClipboardService {

  constructor(
    private readonly matDialog: MatDialog,
    private readonly clipboardResourceService: ClipboardResourceService,
    private readonly toastService: ToastService,
  ) {
  }

  public getClipboards(): Observable<ClipboardDto[]> {
    return this.clipboardResourceService.getClipboardsUsingGET();
  }

  public openClipboardDialogForItems(itemIds: string): void {
    this.openClipboardDialog(itemIds, null);
  }

  public openClipboardDialogForUsers(userIds: string): void {
    this.openClipboardDialog(null, userIds);
  }

  private openClipboardDialog(itemIds: string, userIds: string): void {
    if (isNil(itemIds) && isNil(userIds)) {
      return;
    }

    const dialogRef: MatDialogRef<AdminClipboardAddDialogComponent, ChooseAdminClipboardResult> = this.matDialog
      .open(AdminClipboardAddDialogComponent);

    dialogRef.afterClosed()
      .pipe(
        take(1),
      )
      .subscribe((result: ChooseAdminClipboardResult) => {
        if (isNil(result)) {
          return;
        }

        if (!isNil(itemIds)) {
          this.addItemToClipboard(result.clipboard, itemIds, result.redirect);
        } else {
          this.addUserToClipboard(result.clipboard, userIds, result.redirect);
        }
      });
  }

  private addItemToClipboard(clipboard: ClipboardDto, itemIds: string, redirect: boolean): void {
    const itemsCount = itemIds.split(',').length;
    const successKey = itemsCount > 1 ? 'INSERT_ITEMS_TO_ADMIN_CLIPBOARD_SUCCESS' : 'INSERT_ITEM_TO_ADMIN_CLIPBOARD_SUCCESS';
    const errorKey = itemsCount > 1 ? 'INSERT_ITEMS_TO_ADMIN_CLIPBOARD_ERROR' : 'INSERT_ITEM_TO_ADMIN_CLIPBOARD_ERROR';

    const params: AddParams = {
      clipboardId: clipboard.id,
      data: {
        itemId: itemIds,
      },
    };
    this.addToClipboard(params, clipboard.name, redirect, successKey, errorKey);
  }

  private addUserToClipboard(clipboard: ClipboardDto, userIds: string, redirect: boolean): void {
    const usersCount = userIds.split(',').length;
    const successKey = usersCount > 1 ? 'INSERT_USERS_TO_ADMIN_CLIPBOARD_SUCCESS' : 'INSERT_USER_TO_ADMIN_CLIPBOARD_SUCCESS';
    const errorKey = usersCount > 1 ? 'INSERT_USERS_TO_ADMIN_CLIPBOARD_ERROR' : 'INSERT_USER_TO_ADMIN_CLIPBOARD_ERROR';

    const params: AddParams = {
      clipboardId: clipboard.id,
      data: {
        userId: userIds,
      },
    };
    this.addToClipboard(params, clipboard.name, redirect, successKey, errorKey);
  }

  private addToClipboard(params: AddParams, clipboardName: string, redirect: boolean, successKey: string, errorKey: string): void {
    this.clipboardResourceService.add(params)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.toastService.showSuccess({
            key: successKey,
            params: {
              clipboardName,
            },
          });
          if (redirect) {
            window.open(`${ environment.APP_BACKOFFICEFRONTEND_HOST }clipboard`, '_blank');
          }
        },
        error: () => {
          this.toastService.showDanger({
            key: errorKey,
            params: {
              clipboardName,
            },
          });
        },
      });
  }

}
