<a class="main-logo tw-z-50 tw-cursor-pointer"
   aukTestIdentification="header-logo"
   aukAccessibilityId="ACS_LOGO_ID"
   routerLink="/"
   (click)="onLogoClick($event)"
>
  @if ((getLogoIdInfo$() | async); as logoInfo) {
    <auk-svg-icon
      [iconId]="logoInfo.id"
      [ngClass]="logoInfo.classes"
    />
  }
</a>
