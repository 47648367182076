import { NgModule } from '@angular/core';
import { AukroInfoHoverComponent } from './aukro-info-hover.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    InnerHtmlDirective,
  ],
  declarations: [
    AukroInfoHoverComponent,
  ],
  exports: [
    AukroInfoHoverComponent,
  ],
})
export class AukroInfoHoverModule {
}
