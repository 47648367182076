import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { distinctUntilChanged, takeUntil, map } from 'rxjs/operators';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

@Component({
  selector: 'auk-disclaimers',
  templateUrl: './disclaimers.component.html',
  styleUrls: ['./disclaimers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisclaimersComponent extends NgUnsubscribe implements OnInit {

  public showCookieDisclaimer: boolean = false;

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly platformService: PlatformCommonService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngOnInit(): void {
    // init cookie change listener, only in browser
    if (this.platformService.isBrowser) {
      this.initCookieChangeListener();
    }
  }

  public initCookieChangeListener(): void {
    this.ngZoneUtilService.fromEventOut$(this.window, 'focus')
      .pipe(
        // Map the event to the current cookie enabled state
        map(() => this.window.navigator.cookieEnabled),
        // Only emit when the cookie state changes
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(cookieEnabled => {
        this.showCookieDisclaimer = !cookieEnabled;
        this.changeDetectorRef.detectChanges();
      });
  }

}
