@if (countdownTime() && !!countdownTime().timeText) {
  <div
    class="tw-flex tw-justify-end"
    [class.justify-start]="isLessThan30Seconds"
    >
    <!--  COUNTDOWN TEXT - WITH ICON & BACKGROUND WHEN TIME IS LESS THAN `MINUTES_THRESHOLD_TO_DISPLAY_SECONDS`  -->
    <div
      class="tw-flex tw-items-center tw-whitespace-nowrap"
      [ngClass]="containerClass"
      >
      @if (areSecondsDisplayed && panelSize === 'DEFAULT') {
        <auk-icon source="access_time" iconType="MATERIAL" size="MD" [colorCombination]="colorCombination"/>
      }
      @if (isInfoText) {
        <div class="tw-font-normal tw-text-sm">
          <span class="tw-mr-1">{{ { key: 'BASIC_ITEM_CARD_COUNTDOWN_LABEL' } | translateSource }}</span>
          <span>{{ countdownTime().timeText }}</span>
        </div>
      } @else {
        <span [class.tw-text-foreground-color]="areSecondsDisplayed" class="tw-text-left tw-min-w-[3ch]">{{ countdownTime().timeText }}</span>
      }
    </div>
    <!--  ./COUNTDOWN TEXT - WITH ICON & BACKGROUND WHEN TIME IS LESS THAN `MINUTES_THRESHOLD_TO_DISPLAY_SECONDS`  -->
    <!-- ANIMATION -->
    @if (isLessThan30Seconds) {
      <div
        class="tw-flex-1">
        <div [style.width.%]="getCountdownWidthPercent" class="tw-bg-border-color tw-h-full tw-rounded-full tw-rounded-l-none tw-transition-width tw-ease-linear tw-duration-1000"></div>
      </div>
    }
    <!-- ./ANIMATION -->
  </div>
}
