import isNil from 'lodash-es/isNil';
import { ArrayUtils } from '@util/util/array.utils';
import { ItemSearchAggregationBucket } from '@api/generated/defs/ItemSearchAggregationBucket';

const ABROAD_KEY = 'abroad';

export class DestinationCountryUtils {

  private static readonly collator = new Intl.Collator();

  public static getSelectedDestinationCountries(destinationCountriesToBeSelected: string[], destinationCountry: string): string[] {
    if (isNil(destinationCountry)) {
      return destinationCountriesToBeSelected;
    }
    if (ArrayUtils.isEmpty(destinationCountriesToBeSelected)) {
      return [];
    }
    return destinationCountriesToBeSelected?.filter((country) => country !== destinationCountry);
  }

  public static isAbroad(destinationCountryKey: string): boolean {
    if (isNil(destinationCountryKey)) {
      return false;
    }
    return destinationCountryKey === ABROAD_KEY;
  }

  public static sortDestinationCountries(buckets: ItemSearchAggregationBucket[]): ItemSearchAggregationBucket[] {
    if (ArrayUtils.isEmpty(buckets)) {
      return [];
    }
    buckets.sort((a: ItemSearchAggregationBucket, b: ItemSearchAggregationBucket) => {
      if (DestinationCountryUtils.isAbroad(a.value as string)) {
        return 1;
      }
      return this.collator.compare(a.message, b.message);
    });
    return buckets;
  }

}
