import { Injectable } from '@angular/core';
import { DomainService } from '@shared/platform/domain.service';

import { AppInitializer } from '@util/helper-types/initializer.interface';

@Injectable({
  providedIn: 'root',
})
export class DomainInitializer implements AppInitializer {

  constructor(
    private readonly domainService: DomainService,
  ) {
  }

  public async init(): Promise<void> {
    return this.domainService.readDomainPreference();
  }

}
