import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserTaskBaseModule } from '../base/user-task-base.module';
import { DialogWrapperModule } from '@common/dialog-wrapper/dialog-wrapper.module';
import { Translate2Module } from '@common/translations/translate2.module';

import {
  UserTaskVerifiedPhoneStepNoPhoneVerifiedComponent,
} from './component/user-task-verified-phone-step-no-phone-verified.component';
import { PhoneFieldModule } from '@shared/phone-field/phone-field.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { TwoFactorVerificationModule } from '@shared/two-factor-verification/two-factor-verification.module';
import { LimitMaxLengthDirectiveModule } from '@shared/legacy/directive/limit-max-length/limit-max-length.directive.module';
import { OnlyNumberInputDirectiveModule } from '@shared/legacy/directive/only-number-input/only-number-input.directive.module';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    UserTaskBaseModule,
    DialogWrapperModule,
    Translate2Module,

    ButtonComponent,
    PhoneFieldModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    TwoFactorVerificationModule,
    LimitMaxLengthDirectiveModule,
    OnlyNumberInputDirectiveModule,
    InnerHtmlDirective,
  ],
  declarations: [
    UserTaskVerifiedPhoneStepNoPhoneVerifiedComponent,
  ],
})
export class UserTaskVerifiedPhoneModule {}
