import { NgModule } from '@angular/core';

import { HideOnMobileSsrDirective } from './hide-on-mobile-ssr.directive';

@NgModule({
  declarations: [
    HideOnMobileSsrDirective,
  ],
  exports: [
    HideOnMobileSsrDirective,
  ],
})
export class HideOnMobileSsrDirectiveModule {
}
