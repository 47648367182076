<auk-button colorCombination="TERTIARY_CONTRAST"
            class="tw-w-full"
            (buttonClick)="onFacebookButtonClick()"
            [isDisabled]="buttonDisabled"
>
  <auk-icon iconType="SVG"
            source="facebook"
            size="LG"
            colorCombination="INFO_CONTRAST"
  ></auk-icon>
  {{ buttonText | translateSource }}
</auk-button>
