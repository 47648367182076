import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UrlTrackingParamsEnum } from '@shared/google-analytics/model/url-tracking-params.enum';
import { Nil } from '@util/helper-types/nil';
import { StringUtils } from '@util/util/string.utils';
import { ItemDetailModVisitType } from '@shared/item-detail/mod/model/item-detail-mod-visit.type';
import { CookieService } from '@common/cookie/service/cookie.service';
import { BooleanUtils } from '@util/util/boolean.utils';
import moment from 'moment-mini-ts';

@Injectable({
  providedIn: 'root',
})
export class ItemDetailModPersonalizationService {

  public static readonly ITEM_DETAIL_MOD_PERSONALIZATION_COOKIE_NAME: string = 'item-detail';
  private readonly COOKIE_VALUE_ENABLED: string = 'A';
  private readonly COOKIE_VALUE_DISABLED: string = 'B';

  private readonly UTM_PARAM_VALUE_CPC: string = 'cpc';

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly cookieService: CookieService,
  ) {
  }

  public get itemDetailModsEnabled(): boolean {
    const cookieValue: string = this.cookieService.get(ItemDetailModPersonalizationService.ITEM_DETAIL_MOD_PERSONALIZATION_COOKIE_NAME);

    if ([this.COOKIE_VALUE_ENABLED, this.COOKIE_VALUE_DISABLED].includes(cookieValue)) {
      return cookieValue === this.COOKIE_VALUE_ENABLED;
    }

    const newValue: boolean = BooleanUtils.getRandomBoolean();
    const newCookieValue: string = newValue ? this.COOKIE_VALUE_ENABLED : this.COOKIE_VALUE_DISABLED;
    this.cookieService.put(
      ItemDetailModPersonalizationService.ITEM_DETAIL_MOD_PERSONALIZATION_COOKIE_NAME,
      newCookieValue,
      { expires: moment().add(365, 'days').toDate() },
    );

    return newValue;
  }

  public resolveVisitType(): ItemDetailModVisitType {
    const utmMedium: string | Nil = this.activatedRoute.snapshot.queryParamMap.get(UrlTrackingParamsEnum.UTM_MEDIUM);

    if (utmMedium?.toLowerCase() === this.UTM_PARAM_VALUE_CPC) {
      return 'CAMPAIGN_CPC';
    } else if (!StringUtils.isBlank(utmMedium)) {
      return 'CAMPAIGN_OTHER';
    } else {
      return 'DIRECT';
    }
  }

}
