import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { delayWhen, filter, map, mergeMap, retryWhen, take, takeUntil, takeWhile } from 'rxjs/operators';

import {
  AnnouncementApiService,
  AnnouncementStateStatusParamsEnum,
} from '@api/generated/api/Announcement';
import { UserAnnouncementStatusDto } from '@api/generated/defs/UserAnnouncementStatusDto';
import { UserAnnouncementStatusDtoPage } from '@api/generated/defs/UserAnnouncementStatusDtoPage';
import { UserTopAnnouncementsDto } from '@api/generated/defs/UserTopAnnouncementsDto';
import { AnnouncementDisabledComponentType } from '../../../../typings/original/internal';
import { AnnouncementPopupComponent } from '@shared/legacy/component/announcement-popup/announcement-popup.component';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { HttpError } from '@shared/rest/model/http-error';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { DateUtils } from '@util/util/date.utils';
import { Nil } from '@util/helper-types/nil';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

export interface AnnouncementDialogResult {
  announcement: UserAnnouncementStatusDto;
  changes: 'REMOVE' | 'READ' | 'UNREAD';
}

@Injectable({
  providedIn: 'root',
})
export class AnnouncementCustomService extends NgUnsubscribe {

  private announcementsHeaderPreview: BehaviorSubject<UserTopAnnouncementsDto | Nil> = new BehaviorSubject<UserTopAnnouncementsDto>(null);
  private reloadMyAukroAnnouncements: BehaviorSubject<boolean | Nil> = new BehaviorSubject<boolean>(null);
  private announcementPollingSubscription: Subscription;
  private componentChanges: Observable<boolean>;
  private allowedInComponent: boolean = false;
  private announcementPopupAllowed: boolean = false;
  private importantAnnouncements: UserAnnouncementStatusDto[] = []; // announcements to show in popup
  private readonly topAnnouncementsCount: number = 3;
  private readonly refreshIntervalSec: number = 60; // interval in minutes

  constructor(
    private readonly authService: AuthenticationService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly platformCommonService: PlatformCommonService,
    private readonly announcementApiService: AnnouncementApiService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public openAnnouncementPopup(announcement: UserAnnouncementStatusDto): void {
    const dialogRef: MatDialogRef<AnnouncementPopupComponent, AnnouncementDialogResult> = this.dialog.open(
      AnnouncementPopupComponent, {
        panelClass: ['dialog-no-padding', 'disable-default-styles'],
        data: { announcement },
        disableClose: true,
        closeOnNavigation: true,
      });

    dialogRef.afterClosed()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((result: AnnouncementDialogResult) => {
        this.updateDisplayedStatus([announcement.id]);
        if (!result) {
          this.reloadAnnouncementsHeader();
        } else {
          if (result.changes === 'REMOVE') {
            this.deleteAnnouncement([result.announcement.id])
              .pipe(
                take(1),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe(() => {
                this.reloadMyAukroAnnouncements.next(true);
                this.reloadAnnouncementsHeader();
              });
          }
          if (result.changes === 'READ') {
            this.updateReadStatus(true, [result.announcement.id])
              .pipe(
                take(1),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe(() => {
                this.reloadMyAukroAnnouncements.next(true);
                this.reloadAnnouncementsHeader();
              });
          }
          if (result.changes === 'UNREAD') {
            this.updateReadStatus(false, [result.announcement.id])
              .pipe(
                take(1),
                takeUntil(this.ngUnsubscribe),
              )
              .subscribe(() => {
                this.reloadMyAukroAnnouncements.next(true);
                this.reloadAnnouncementsHeader();
              });
          }
        }
        if (this.importantAnnouncements.length > 0) {
          this.showNextAnnouncement();
        }
      });
  }

  public showImportantAnnouncements(): void {
    if (this.authService.isLoggedIn()) {
      this.handleUserLoggedInAnnouncementRefresh();
    }

    this.authService.getLoginStatusChange()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isSignedIn: boolean) => {
        if (isSignedIn) { // user authorized himself
          this.handleUserLoggedInAnnouncementRefresh();
        } else {
          if (this.announcementPollingSubscription) {
            this.announcementPollingSubscription.unsubscribe();
            this.announcementPollingSubscription = null;
            this.announcementPopupAllowed = false;
          }
        }
      });
  }

  public getReloadMyAukroAnnouncements(): Observable<boolean> {
    return this.reloadMyAukroAnnouncements.asObservable();
  }

  public getAnnouncementsPreview(): Observable<UserTopAnnouncementsDto> {
    return this.announcementsHeaderPreview.asObservable();
  }

  public setAnnouncementPopupAllowed(value: boolean): void {
    this.announcementPopupAllowed = value;
    if (value && this.importantAnnouncements.length > 0) {
      this.showNextAnnouncement();
    }
  }

  public handleUserLoggedInAnnouncementRefresh(): void {
    this.reloadAnnouncementsHeader();
    this.initAnnouncementsPolling();
  }

  public reloadAnnouncementsHeader(): void {
    this.getTopAnnouncements(this.topAnnouncementsCount)
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((topAnnouncements: UserTopAnnouncementsDto) => {
        this.announcementsHeaderPreview.next(topAnnouncements);
      },
      (error: HttpError) => {
        if (error.code !== 401) {
          throw error;
        }
      },
      );
  }

  public getTopAnnouncements(count: number): Observable<UserTopAnnouncementsDto> {
    return this.announcementApiService.top({ count });
  }

  public getAnnouncements(
    announcementState: AnnouncementStateStatusParamsEnum,
    page: number,
    size: number,
  ): Observable<UserAnnouncementStatusDtoPage> {
    return this.announcementApiService.status({ announcementState, page, size });
  }

  public updateReadStatus(read: boolean, ids: number[]): Observable<void> {
    return this.announcementApiService.read({
      changeReadStatusAnnouncementDto: {
        read,
        userAnnouncementIds: ids,
      },
    });
  }

  public deleteAnnouncement(ids: number[]): Observable<void> {
    return this.announcementApiService.statusTEMP({ userAnnouncementStatusIds: ids });
  }

  private updateDisplayedStatus(ids: number[]): void {
    this.announcementApiService.displayed({ userAnnouncementStatusIds: ids })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  private initAnnouncementsPolling(): void {
    if (this.platformCommonService.isServer) {
      return;
    }

    this.announcementPollingSubscription?.unsubscribe();

    this.announcementPollingSubscription =
      this.ngZoneUtilService.timerOut$(0, DateUtils.convertMinutesToMilliseconds(this.refreshIntervalSec))
        .pipe(
          retryWhen((errors) =>
            // retry when platform down
            errors.pipe(
              delayWhen(() => this.ngZoneUtilService.timerOut$(DateUtils.convertSecondsToMilliseconds(5))),
            ),
          ),
          mergeMap(() => this.getTopAnnouncements(this.topAnnouncementsCount)),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: (topAnnouncements: UserTopAnnouncementsDto) => {
            const count: number = topAnnouncements.importantAnnouncementStatusDtoList.length;
            if (count > 0) {
              if (count <= 3) {
                this.importantAnnouncements = topAnnouncements.importantAnnouncementStatusDtoList;
              } else {
                this.importantAnnouncements = topAnnouncements.importantAnnouncementStatusDtoList.splice(0, 3);
              }
              if (this.announcementPopupAllowed) {
                this.showNextAnnouncement();
              }
            }
          },
          error: (error: HttpError) => {
            if (error.code !== 401) {
              throw error;
            }
          },
        });

    // Emit if announcements are allowed in current page.
    this.componentChanges = this.router.events
      .pipe(
        filter((event) => (event instanceof ActivationEnd && typeof event.snapshot.component !== 'undefined')),
        map((event) => {
          const activationEnd: ActivationEnd = event as ActivationEnd;
          if (activationEnd.snapshot.component) {
            const component = activationEnd.snapshot.component as AnnouncementDisabledComponentType;
            return !component.announcementDisabled;
          }
        }),
      );

    this.componentChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((allowed: boolean) => {
        this.allowedInComponent = allowed;
      });
  }

  // Announcement is not shown till announcements are allowed in page.
  private showNextAnnouncement(): void {
    const announcement: UserAnnouncementStatusDto = this.importantAnnouncements.shift();
    if (this.allowedInComponent) {
      this.openAnnouncementPopup(announcement);
    } else {
      this.componentChanges
        .pipe(
          takeWhile((allowed: boolean) => !allowed),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          complete: () => {
            // This condition is to eliminate showing announcement after sign out.
            if (this.announcementPopupAllowed) {
              this.openAnnouncementPopup(announcement);
            }
          },
        });
    }
  }

}
