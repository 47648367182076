import { Directive, HostListener, Inject, Input } from '@angular/core';

import { KeyboardUtil } from '@util/util/keyboard.util';
import { WINDOW_OBJECT } from '@util/const/window-object';

@Directive({
  selector: '[aukOnlyNumberInput]',
})
export class OnlyNumberInputDirective {

  @Input() public allowDecimal: boolean = true;

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
  ) {
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    const e = event;
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }

  @HostListener('keydown', ['$event'])
  public onChangeInput(event: KeyboardEvent): void {
    const invalidDecimalPoint = !this.allowDecimal
      && (KeyboardUtil.isPeriodDown(event) || KeyboardUtil.isCommaDown(event));
    if ((!KeyboardUtil.isNumericCombinationPressed(event) || invalidDecimalPoint)
      && !KeyboardUtil.isNumberPressed(event)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  public onPasteInput(event: Event): void {
    const pastedValue = (
      event instanceof ClipboardEvent ?
        event.clipboardData : (this.window as any).clipboardData
    ).getData('text');
    if (isNaN(parseInt(pastedValue, 10))) {
      event.preventDefault();
      return;
    }
  }

}
