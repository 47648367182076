import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ClickParams {
  /** clickEventDto */
  clickEventDto: model.ClickEventDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ContactFormEventParams {
  /** contactFormEventDto */
  contactFormEventDto: model.ContactFormEventDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ShowParams {
  /** showEventDto */
  showEventDto: model.ShowEventDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root'
})
export class MeasurementService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public click(params: ClickParams, headers: { [key: string]: string } = {}): Observable<model.ClickEventResponseDto> {
    let bodyParams = params.clickEventDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/measurement/click`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public contactFormEvent(params: ContactFormEventParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.contactFormEventDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/measurement/contactFormEvent`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public show(params: ShowParams, headers: { [key: string]: string } = {}): Observable<model.ShowListingEventResponseDto> {
    let bodyParams = params.showEventDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/measurement/show`, bodyParams, queryParams, formDataParams, headers);
  }
}
