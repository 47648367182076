import { Injectable } from '@angular/core';

import { TranslateRoutesService } from '@shared/translate-routes-module/translate-routes.service';

import { AppInitializer } from '@util/helper-types/initializer.interface';

@Injectable({
  providedIn: 'root',
})
export class TranslateRoutesInitializer implements AppInitializer {

  constructor(private readonly translateRoutesService: TranslateRoutesService) {
  }

  public init(): void {
    this.translateRoutesService.init();
  }

}
