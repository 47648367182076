import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment-mini-ts';
import { StringUtils } from '@util/util/string.utils';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CjeLocalStorageModel } from '@shared/google-analytics/model/cje-local-storage.model';
import { GA_PARTNERS_VIVNETWORKS_CJEVENT, GA_PARTNERS_VIVNETWORKS_EXPIRATION_DAYS } from '@shared/services/vivnetworks/vivnetworks.helpers';

@Injectable({
  providedIn: 'root',
})
export class VivnetworksService extends NgUnsubscribe {

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly localStorageService: LocalStorageService,
  ) {
    super();
  }

  public saveVivnetworksCje(): void {
    const vivnetworksCjeventInUrl = this.activatedRoute.snapshot.queryParams['cjevent'] as string;
    if (!StringUtils.isBlank(vivnetworksCjeventInUrl) && vivnetworksCjeventInUrl !== this.getValidVivnetworksCje()) {
      const cjeventLS: CjeLocalStorageModel = {
        cje: vivnetworksCjeventInUrl,
        expirationTime: moment().add(GA_PARTNERS_VIVNETWORKS_EXPIRATION_DAYS, 'days'),
      };
      this.localStorageService.setItem(GA_PARTNERS_VIVNETWORKS_CJEVENT, cjeventLS);
    }
  }

  /**
   * Get vivnetworks cje stored in Local Storage if it exists and has expiration in the future.
   * If it exists but has already expired, remove the record from Local Storage.
   */
  public getValidVivnetworksCje(): string | Nil {
    const cjeLS = this.localStorageService.getItem<CjeLocalStorageModel>(GA_PARTNERS_VIVNETWORKS_CJEVENT);

    if (isNil(cjeLS)) {
      return null;
    }

    if (!StringUtils.isBlank(cjeLS.cje) && !isNil(cjeLS.expirationTime) && moment(cjeLS.expirationTime).isAfter(moment())) {
      return cjeLS.cje;
    } else {
      this.localStorageService.removeItem(GA_PARTNERS_VIVNETWORKS_CJEVENT);
      return null;
    }
  }

}
