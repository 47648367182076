import { InjectionToken } from '@angular/core';
import { MessageFormatConfigModel } from './model/message-format-config.model';

export const MESSAGE_FORMAT_CONFIG = new InjectionToken<MessageFormatConfigModel>(
  'MESSAGE_FORMAT_CONFIG',
);

export const defaultConfig: MessageFormatConfigModel = {
  biDiSupport: false,
  formatters: null,
  locales: null,
  strictNumberSign: false,
  disablePluralKeyChecks: false,
};
