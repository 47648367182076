import { ChangeDetectorRef, Directive, Host, OnInit } from '@angular/core';
import { startWith, takeUntil } from 'rxjs/operators';
import { PlatformService } from '@shared/platform/service/platform.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

/**
 * @deprecated
 * Use {@link IfIsActiveBreakpointDirective} instead
 */
@Directive({
  selector: '[aukIsMobile]',
  exportAs: 'isMobile',
  standalone: true,
})
export class IsMobileDirective extends NgUnsubscribe implements OnInit {

  public value: boolean = false;

  constructor(
    @Host() private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly platformService: PlatformService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.platformService.isMobile$
      .pipe(
        startWith(this.platformService.isMobile),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMobile) => {
        this.value = isMobile;
        this.changeDetectorRef.markForCheck();
      });
  }

}
