import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DynamicTemplateModel } from '@shared/app-header/model/dynamic-template.model';
import { BannerPlacementDto } from '@api/aukro-api/model/banner-placement-dto';
import { Nil } from '@util/helper-types/nil';

@Injectable({ providedIn: 'root' })
export class AppHeaderService {

  private setTopLineBanner$ = new BehaviorSubject<BannerPlacementDto | Nil>(null);

  private _dynamicComponentTemplate$: BehaviorSubject<DynamicTemplateModel> =
    new BehaviorSubject<DynamicTemplateModel>({ contentTemplate: null });

  public static getBadgeCount(count: number): string {
    return count ? (count > 99 ? '99+' : count.toString()) : null;
  }

  public setTopLineBanner(value: BannerPlacementDto | Nil): void {
    this.setTopLineBanner$.next(value);
  }

  public get topLineBanner$(): Observable<BannerPlacementDto | Nil> {
    return this.setTopLineBanner$.asObservable();
  }

  public get dynamicTemplate$(): Observable<DynamicTemplateModel> {
    return this._dynamicComponentTemplate$.asObservable();
  }

  public setDynamicTemplate(value: DynamicTemplateModel): void {
    this._dynamicComponentTemplate$.next(value);
  }

}
