import { Injectable } from '@angular/core';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { GaEcommerceTrackEventModel } from '@shared/google-analytics/model/ga-ecommerce-track-event.model';

@Injectable({
  providedIn: 'root',
})
export class ItemScrollSliderGaService {

  constructor(
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
  ) {
  }

  public trackCarouselItemClickEvent(itemId: number, itemName: string): void {
    const event: GaEcommerceTrackEventModel = {
      event: 'carouselItemClick',
      ecommerce: {
        carouselItemClick: {
          id: itemId,
          name: itemName,
        },
      },
    };

    void this.googleAnalyticsTrackingService.createTrackPromise(event);
  }

}
