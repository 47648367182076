import { Injectable } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';
import { Nil } from '@util/helper-types/nil';
import { Observable, raceWith } from 'rxjs';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CookieService } from '@common/cookie/service/cookie.service';
import { StringUtils } from '@util/util/string.utils';
import { ArrayUtils } from '@util/util/array.utils';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService extends NgUnsubscribe {

  private readonly GA_COOKIE_NAME: string = '_ga';

  constructor(
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly cookieService: CookieService,
  ) {
    super();
  }

  /**
   * Gets the Google Analytics Client ID if available
   * @returns observable which emits GA Client ID when available, or `null` if 10 seconds timeout is reached
   */
  public getGaClientId$(): Observable<string | Nil> {
    const checkingIntervalMs: number = 200;
    const maxAttempts: number = 50;

    return this.ngZoneUtilService.intervalOut$(checkingIntervalMs)
      .pipe(
        map(() => this.getGaClientIdFromCookie()),
        filter((gaClientID: string | Nil) => !StringUtils.isBlank(gaClientID)),
        raceWith<string, [Nil]>(
          this.ngZoneUtilService.timerOut$(checkingIntervalMs * maxAttempts)
            .pipe(
              map(() => null),
            ),
        ),
        take(1),
      );
  }

  private getGaClientIdFromCookie(): string | Nil {
    const gaCookie: string = this.cookieService.get(this.GA_COOKIE_NAME);

    const separator: string = '.';

    const cookieParts: string[] = gaCookie
      ?.split(separator)
      ?.filter((part: string) => !StringUtils.isBlank(part));

    if (ArrayUtils.isEmpty(cookieParts) || cookieParts?.length < 4) {
      return null;
    }

    return cookieParts.splice(-2).join(separator);
  }

}
