import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AccessibilityDirective } from '@common/accessibility/directive/accessibility.directive';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface LogoInfo {
  id: string;
  classes: string[];
}

@Component({
  selector: 'auk-app-header-logo',
  templateUrl: 'app-header-logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    SvgIconComponent,
    RouterLink,
    AsyncPipe,
    NgClass,
    AccessibilityDirective,
    TestIdentificationDirective,
  ],
})
export class AppHeaderLogoComponent {

  @Input() public forceSmallLogo: boolean = false;
  @Input() public preventDefaultLink: boolean = false;

  constructor(
    private readonly subbrandService: SubbrandService,
  ) {
  }

  protected getLogoIdInfo$(): Observable<LogoInfo> {
    return this.subbrandService.sessionSubbrandLogoLocalized$
      .pipe(
        map((logo) => this.getLogoInfo(logo)),
      );
  }

  protected onLogoClick(event: MouseEvent): void {
    if (this.preventDefaultLink) {
      event.preventDefault();
    }
  }

  private getLogoInfo(logo: string): LogoInfo {
    let id: string;
    let classes: string[];

    switch (logo) {
      case 'logo':
        id = this.forceSmallLogo ? 'antique-bazaar-link' : 'logo';
        classes = this.forceSmallLogo
          ? ['!tw-w-[34px]', '!tw-fill-antique']
          : ['!tw-w-[75px]', 'lg:!tw-w-[125px]', '!tw-h-[26px]', 'lg:!tw-h-[40px]', '!tw-fill-primary'];
        break;
      case 'logo-antique':
        id = this.forceSmallLogo ? 'antique-logo' : 'logo-antique';
        classes = this.forceSmallLogo
          ? ['!tw-w-[34px]', '!tw-fill-antique']
          : ['lg:!tw-w-[155px]', '!tw-w-[138px]', 'lg:!tw-h-[50px]', '!tw-fill-antique'];
        break;
      case 'logo-antique-sk':
        id = this.forceSmallLogo ? 'antique-logo' : 'logo-antique-sk';
        classes = this.forceSmallLogo
          ? ['!tw-w-[34px]', '!tw-fill-antique']
          : ['lg:!tw-w-[155px]', '!tw-w-[138px]', 'lg:!tw-h-[50px]', '!tw-fill-antique'];
        break;
    }

    return {
      id,
      classes,
    };
  }

}
