import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConsentTermsComponent } from './consent-terms/consent-terms.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { CommonDirectivesModule } from '@shared/legacy/directive/common-directives.module';
import { CommonPipesModule } from '@shared/legacy/pipe/common-pipes.module';
import { AccordionItemComponent } from './accordion/accordion-item.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AlertBannerModule } from './alert-banner/alert-banner.module';
import { AnnouncementPopupComponent } from './announcement-popup/announcement-popup.component';
import { BannerModule } from '@shared/banner/banner.module';
import { BuildInfoComponent } from './build-info/build-info.component';
import { DialogRotateImageComponent } from './dialog-rotate-image/dialog-rotate-image.component';
import { ErrorBannerComponent } from './error-banner/error-banner.component';
import { GoBackButtonComponent } from './go-back-button/go-back-button.component';
import { InfoHintComponent } from './info-hint/info-hint.component';
import { MobileFiltersModule } from './mobile-filters/mobile-filters.module';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { OptionSelectComponent } from './option-select/option-select.component';
import { PaginationControlMobileComponent } from './pagination-control-mobile/pagination-control-mobile.component';
import { PaginationControlComponent } from './pagination-control/pagination-control.component';
import { SelectModule } from './select/select-module';
import { SuccessMessageModule } from '@shared/legacy/component/success-message/success-message.module';
import { SvgIconModule } from '@shared/legacy/component/svg-icon/svg-icon.module';
import { TwoFactorVerificationModule } from '@shared/two-factor-verification/two-factor-verification.module';
import { UserRatingComponent } from './user-rating/user-rating.component';
import { ValidationMessageComponent } from './validation-message/validation-message.component';
import { SpinnerModule } from '@shared/legacy/component/spinner/spinner.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { AukroInfoHoverModule } from './html-hover/aukro-info-hover.module';
import { UnpaidAlertComponent } from './unpaid-alert/component/unpaid-alert.component';
import { TooltipModule } from '@shared/legacy/component/tooltip/tooltip.module';
import { StarIconModule } from '@shared/legacy/component/star-icon/star-icon.module';
import { RecoBoxComponent } from '@shared/legacy/component/reco-box/reco-box.component';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { AppHeaderLogoComponent } from '@shared/app-header/component/app-header-logo/app-header-logo.component';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';

/**
 * @deprecated
 * Do not use, if you need to use some page from this module, create module only for that page and use that
 * (don't forget to remove the page from declarations in this module, and put the module into exports only there)
 */
@NgModule({
  imports: [
    CommonModule,
    CommonPipesModule,
    CommonDirectivesModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    MatTooltipModule,
    MatDialogModule,
    SvgIconModule,
    TwoFactorVerificationModule,
    AlertBannerModule,
    SuccessMessageModule,
    MobileFiltersModule,
    SuccessMessageModule,
    BannerModule,
    SpinnerModule,
    Translate2Module,
    AukroInfoHoverModule,
    TooltipModule,
    StarIconModule,
    ValidationMessageComponent,
    RecoBoxComponent,
    GoBackButtonComponent,
    PaginationControlComponent,
    SvgIconComponent,
    AppHeaderLogoComponent,
    InnerHtmlDirective,
    LinkFromHrefDirective,
  ],
  declarations: [
    AccordionComponent,
    AccordionItemComponent,
    AnnouncementPopupComponent,
    BuildInfoComponent,
    InfoHintComponent,
    MultiSelectComponent,
    OptionSelectComponent,
    PaginationControlMobileComponent,
    UserRatingComponent,
    DisclaimerComponent,
    ConsentTermsComponent,
    UnpaidAlertComponent,
    ErrorBannerComponent,
    DialogRotateImageComponent,
  ],
  exports: [
    AccordionComponent,
    AccordionItemComponent,
    AnnouncementPopupComponent,
    BuildInfoComponent,
    ValidationMessageComponent,
    InfoHintComponent,
    MultiSelectComponent,
    OptionSelectComponent,
    PaginationControlComponent,
    PaginationControlMobileComponent,
    UserRatingComponent,
    DisclaimerComponent,
    ConsentTermsComponent,
    UnpaidAlertComponent,
    ErrorBannerComponent,
    DialogRotateImageComponent,
    SelectModule,
    GoBackButtonComponent,
    SvgIconModule,
    TwoFactorVerificationModule,
    AlertBannerModule,
    SuccessMessageModule,
    BannerModule,
    SpinnerModule,
    AukroInfoHoverModule,
    TooltipModule,
    StarIconModule,
    RecoBoxComponent,
    PaginationControlComponent,
  ],
})
export class CommonComponentsModule {
}
