import { HttpHeaders, HttpParams } from '@angular/common/http';
import isObject from 'lodash-es/isObject';
import isArray from 'lodash-es/isArray';

import { UrlUtils } from '@util/util/url.utils';
import isNil from 'lodash-es/isNil';
import { ArrayUtils } from '@util/util/array.utils';

export class TransferStateUtils {

  public static generateKeyForRequest(
    req: {
      method: string;
      url: string;
      params?: HttpParams;
      body?: unknown;
      headers?: HttpHeaders;
    },
  ): string {
    const method: string = req.method;
    const url: string = UrlUtils.getRelativeUrl(req.url);
    const params: string = req.params?.toString() ?? '';
    const body: string = this.serializeRequestBody(req.body);
    const headers: string = this.serializeHeaders(req.headers);

    return [method, url, params, body, headers].join('\u001c');
  }

  private static serializeRequestBody(body: unknown): string {
    if (isObject(body) || isArray(body)) {
      return JSON.stringify(body);
    }

    return String(body);
  }

  private static serializeHeaders(headers?: HttpHeaders): string {
    if (isNil(headers) || ArrayUtils.isEmpty(headers.keys())) {
      return '';
    }

    const headerMap = {};
    for (const name of headers.keys()) {
      headerMap[name] = headers.get(name);
    }

    return JSON.stringify(headerMap);
  }

}
