import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from '@shared/legacy/component/tooltip/tooltip.directive';

@NgModule({
  imports: [
    CommonModule,
    TooltipComponent,
    TooltipDirective,
  ],
  exports: [
    TooltipComponent,
    TooltipDirective,
  ],
})
export class TooltipModule {

}
