import { StarIcon } from '@common/ui-kit/component/star-icon/model/star-icon.model';

export const starIconContainer: StarIcon[] = [
  {
    type: 'DIAMOND',
    icon: 'star-full',
    fillClass: '!tw-fill-info',
  },
  {
    type: 'GOLD',
    icon: 'star-full',
    fillClass: '!tw-fill-vip',
  },
  {
    type: 'SILVER',
    icon: 'star-full',
    fillClass: '!tw-fill-tertiary-400',
  },
  {
    type: 'BRONZE',
    icon: 'star-full',
    fillClass: '!tw-fill-warning',
  },
  {
    type: 'GREEN',
    icon: 'leaf',
    fillClass: '!tw-fill-success',
  },
  {
    type: 'WHITE',
    icon: 'star-empty',
    fillClass: '!tw-fill-tertiary-400',
  },
];
