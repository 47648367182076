import { FILE_TYPES } from '@util/util/file-format/constant/file-types.constant';
import { FileType } from '@util/util/file-format/model/file-type.model';
import isEqual from 'lodash-es/isEqual';
import { Nil } from '@util/helper-types/nil';
import { StringUtils } from '@util/util/string.utils';

export class FileFormatUtil {

  private static ALL_ALLOWED: string = '*/*';
  private static CAPTURE_CAMERA: string = 'capture=camera';
  private static IMAGE_PREFIX: string = 'image/';
  private static COMMON_PREFIX: string = '.';

  public static getFormatsForAcceptAttribute(allowedTypes: FileType[]): string {
    // Get file formats
    let fileFormats: string[] = [];
    allowedTypes?.forEach((type: FileType) => fileFormats.push(...FILE_TYPES[type]));

    if (fileFormats.length === 0) {
      return this.ALL_ALLOWED;
    }

    // Remove duplicities
    fileFormats = [...new Set(fileFormats)];

    // Check whether only images are allowed
    const isOnlyImages: boolean = isEqual(allowedTypes, ['photos']);

    // Add prefix
    const prefix: string = isOnlyImages ? this.IMAGE_PREFIX : this.COMMON_PREFIX;
    fileFormats = fileFormats.map((format: string) => prefix + format);

    // Add capture=camera value if only images
    if (isOnlyImages) {
      fileFormats.push(this.CAPTURE_CAMERA);
    }

    return fileFormats.join(',');
  }

  public static getFormatsReadable(allowedTypes: FileType[]): string {
    // Get file formats
    let fileFormats: string[] = [];
    allowedTypes?.forEach((type: FileType) => fileFormats.push(...FILE_TYPES[type]));

    if (fileFormats.length === 0) {
      return '';
    }

    // Remove duplicities
    fileFormats = [...new Set(fileFormats)];

    return fileFormats
      .map((format: string) => this.COMMON_PREFIX + format)
      .join(', ');
  }

  public static validateAcceptableFileFormat(file: File, allowedTypes: FileType[]): boolean {
    const extension = file.name.split('.').pop().toLowerCase();
    return allowedTypes.some((key: FileType) => FILE_TYPES[key as string].includes(extension));
  }

  public static withPngBase64Prefix(base64image: string): string | Nil {
    if (StringUtils.isEmpty(base64image)) {
      return null;
    }

    return 'data:image/png;base64,' + base64image;
  }

}
