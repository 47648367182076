<div class="tw-flex tw-items-center tw-h-full"
  aukMouseOver
  (isMouseOver)="setDropdownOpenState($event)"
  >
  <auk-header-item
    (click)="onAnnouncementsClick()"
    [colorCombination]="getColorCombination"
    [headerItemModel]="headerItemData"
    [isActive]="isDropdownOpened"
    />

  @if (announcements?.unreadCount > 0) {
    <div class="dropdown-submenu right announcements">
      <div class="popover-header">
        <b>{{ 'ANNOUNCEMENTS_CONTROL_COMPONENT_HTML_1' | translate }}</b>
      </div>
      @for (announcement of announcements.announcementStatusDtoList; track trackByAnnouncementId($index, announcement)) {
        <a routerLink="/moje-aukro/muj-ucet/sdeleni"
          class="announcement-wrapper"
          (click)="showAnnouncementPopup(announcement)"
          >
          <div class="announcement unread">
            @if (!announcement.announcementDto.important) {
              <i class="material-icons text-blue m-r-2 biggest">
                info
              </i>
            }
            @if (announcement.announcementDto.important) {
              <i class="material-icons text-red m-r-2 biggest">
                warning
              </i>
            }
            <div class="message">
              <span>{{announcement.announcementDto.subject}}</span>
              {{announcement.announcementDto.publishDate | relativeTime}}
            </div>
          </div>
        </a>
      }
      <a class="show-all" (click)="closeDropdown()" routerLink="/moje-aukro/muj-ucet/sdeleni">
        <p>{{ 'ANNOUNCEMENTS_CONTROL_COMPONENT_HTML_2' | translate }}</p>
      </a>
    </div>
  }
</div>
