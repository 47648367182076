import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Translate2Module } from '@common/translations/translate2.module';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { Tooltip2CustomModel } from '@common/tooltip2/model/tooltip2-custom.model';
import { NgTemplateOutlet } from '@angular/common';
import { Nil } from '@util/helper-types/nil';

@Component({
  selector: 'auk-tooltip2-custom',
  templateUrl: './tooltip2-custom.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    Translate2Module,
    IconComponent,
    NgTemplateOutlet,
  ],
})

export class Tooltip2CustomComponent {

  @Input({ required: true }) public tooltipModel: Tooltip2CustomModel | Nil;

}
