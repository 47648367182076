import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  Renderer2,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  HostBinding,
} from '@angular/core';
import { Tooltip2Position } from '../tooltip2-position/model/tooltip2-position.type';
import { TooltipPositionService } from '../tooltip2-position/model/service/tooltip2-position.service';
import { Translate2Module } from '@common/translations/translate2.module';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { Tooltip2Model } from '../model/tooltip2.model';
import { Tooltip2ButtonsComponent } from './tooltip2-buttons/tooltip2-buttons.component';
import { Tooltip2InformativeComponent } from './tooltip2-informative/tooltip2-informative.component';
import { Tooltip2CustomComponent } from '@common/tooltip2/component/tooltip2-custom/tooltip2-custom.component';
import { Tooltip2ArrowPlacementType } from '@common/tooltip2/model/tooltip2-arrow-placement.type';
import { Nil } from '@util/helper-types/nil';

const HALF_PADDING: number = 4;

/**
 * This component will be used to show custom tooltip
 * This component will be rendered using OverlayModule of angular material
 * This component will be rendered by directive on an Overlay
 *
 * This component will not be used directly
 * This component will be hosted in an overlay by ToolTipDirective directive
 */
@Component({
  selector: 'auk-tooltip2',
  templateUrl: './tooltip2.component.html',
  styleUrls: ['./tooltip2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    Translate2Module,

    ButtonComponent,
    IconComponent,
    Tooltip2ButtonsComponent,
    Tooltip2InformativeComponent,
    Tooltip2CustomComponent,
  ],
})

export class Tooltip2Component {

  @Input() public tooltipModel: Tooltip2Model;
  @Input() public placement: Tooltip2Position = 'bottom';
  @Input() public arrowPlacement: Tooltip2ArrowPlacementType = 'auto';
  @Input() public hostElement: ElementRef<HTMLElement>;
  @Input() public showCloseButton: boolean = false;

  @Output() public clickAction: EventEmitter<string> = new EventEmitter<string>();
  @Output() public closeClick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('arrowElm', { static: false }) protected arrowElm: ElementRef<HTMLDivElement>;

  @HostBinding('style.height') protected styleHeight: string | Nil;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly renderer2: Renderer2,
    private readonly tooltipPositionService: TooltipPositionService,
  ) {
  }

  // This is used so we can calculate the correct height for scrolling inside
  public setCorrectHeight(topPosition: number): void {
    this.styleHeight = `calc(100vh - ${ topPosition }px)`;
  }

  @HostBinding('class.full-width-on-mobile')
  public get makeFullWidth(): boolean {
    return this.tooltipModel.type === 'CUSTOM';
  }

  public updatePlacement(newPosition: Tooltip2Position): void {
    this.placement = newPosition;
    this.updateArrowPosition();
  }

  public updateArrowPosition(): void {
    if (this.arrowPlacement === 'none') {
      return;
    }

    const arrowPosition = this.tooltipPositionService.getArrowPosition(
      this.hostElement,
      this.elementRef,
      this.arrowElm,
      this.arrowPlacement,
    );

    // Update arrow position
    if (this.placement.includes('bottom')) {
      // If the tooltip is on the bottom, the arrow should be on the top
      this.renderer2.setStyle(this.arrowElm.nativeElement, 'top', `-${ HALF_PADDING }px`);
      this.renderer2.setStyle(this.arrowElm.nativeElement, 'bottom', 'auto');
    } else if (this.placement.includes('top')) {
      // If the tooltip is on the top, the arrow should be on the bottom
      this.renderer2.setStyle(this.arrowElm.nativeElement, 'bottom', `-${ HALF_PADDING }px`);
      this.renderer2.setStyle(this.arrowElm.nativeElement, 'top', 'auto');
    }

    // Always set the 'left' position of the arrow (centered)
    this.renderer2.setStyle(this.arrowElm.nativeElement, 'left', `${ arrowPosition - HALF_PADDING }px`);
  }

  protected handleButtonClick(action: string): void {
    this.clickAction.emit(action);
  }

  protected get containerClass(): string[] {
    if (this.tooltipModel.type === 'CUSTOM') {
      return [];
    }

    if (this.tooltipModel.type === 'BUTTONS') {
      return ['tw-p-2'];
    }

    // else INFORMATIVE
    const size = this.tooltipModel.size ?? 'MD';

    if (size === 'MD') {
      return ['tw-p-6', 'tw-max-w-[380px]'];
    }
    // SM size
    return ['tw-p-2', 'tw-max-w-[250px]'];
  }

}
