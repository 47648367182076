import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ShareDataService } from '@shared/services/share-data/share-data.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Nil } from '@util/helper-types/nil';

@Component({
  selector: 'auk-success-message',
  templateUrl: './success-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessMessageComponent extends NgUnsubscribe implements OnInit, OnDestroy {

  protected message: string | Nil;
  protected className: string | Nil;

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly shareDataService: ShareDataService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.message = this.shareDataService.successMessageText;
    this.className = this.shareDataService.successMessageClass;
    this.scrollTop();

    this.shareDataService.successMessageHandle
      .asObservable()
      .pipe(
        debounceTime(100),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(({ text, className }: { text: string; className?: string }) => {
        this.message = text;
        this.className = className;
        this.scrollTop();
        this.changeDetectorRef.detectChanges();
      });
  }

  public override ngOnDestroy(): void {
    if (!this.shareDataService.preserveOnNavigation) {
      this.shareDataService.clearMessage();
    }

    super.ngOnDestroy();
  }

  private scrollTop(): void {
    if (this.message) {
      this.window.scrollTo(0, 0);
    }
  }

}
