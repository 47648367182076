import { Injectable } from '@angular/core';
import { MatLegacyDateFormats as MatDateFormats, LegacyNativeDateAdapter as NativeDateAdapter } from '@angular/material/legacy-core';

export const DATEPICKER_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD. MM. YYYY',
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'inputMonth',
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

// This adapter is used by Material Datepicker.
@Injectable()
export class MyDateAdapter extends NativeDateAdapter {

  /* Formatting Date to 1. 1. 2019 or 1/2019 format. */
  public override format(date: Date, displayFormat: string): string {
    if (displayFormat === 'input') {
      const day: number = date.getDate();
      const month: number = date.getMonth() + 1;
      const year: number = date.getFullYear();
      return day.toString() + '. ' + month.toString() + '. ' + year.toString();
    } else if (displayFormat === 'inputMonth') {
      const month: number = date.getMonth() + 1;
      const year: number = date.getFullYear();
      return  month.toString() + '/' + year.toString();
    } else {
      return date.toDateString();
    }
  }

  public override getFirstDayOfWeek(): number {
    return 1;
  }

}
