import { Injectable } from '@angular/core';
import find from 'lodash-es/find';
import get from 'lodash-es/get';
import isNil from 'lodash-es/isNil';
import {
  DealTransactionItemDto,
  ItemBidDto,
  ItemImageDto,
  ItemImageListsDto,
  ItemPurchaseDto,
  MoneyDto,
  MySaleItemDto,
} from '@api/generated/model';
// TODO: [PDEV-18307] - fix dependency
// eslint-disable-next-line import/no-restricted-paths
import { WatchingItemActiveDto } from '@section/my-aukro/module/my-purchases/watching/model/WatchingItemActiveDto';
// TODO: [PDEV-18307] - fix dependency
// eslint-disable-next-line import/no-restricted-paths
import { WatchingItemEndedDto } from '@section/my-aukro/module/my-purchases/watching/model/WatchingItemEndedDto';
// TODO: [PDEV-18307] - fix dependency
// eslint-disable-next-line import/no-restricted-paths
import { WatchingItemScheduledDto } from '@section/my-aukro/module/my-purchases/watching/model/WatchingItemScheduledDto';
import { Nil } from '@util/helper-types/nil';

export type WatchingItemDto = WatchingItemActiveDto | WatchingItemEndedDto | WatchingItemScheduledDto;
type ItemTypeDto = MySaleItemDto | ItemBidDto | ItemPurchaseDto | WatchingItemDto;
export type CommissionFeeModel = ItemPurchaseDto | DealTransactionItemDto;

/**
 * @deprecated
 *
 * Do not use, create more specific service for your own usage
 */
@Injectable({
  providedIn: 'root',
})
export class HelperService {

  public isItemBidDto(product: ItemTypeDto): product is ItemBidDto {
    const itemBid = product as ItemBidDto;

    return itemBid.highestBidPrice !== undefined;
  }

  public isWatchingItemDto(product: ItemTypeDto): product is WatchingItemDto {
    const watchingItem = product as WatchingItemDto;

    return watchingItem.itemTypeEnum !== undefined && watchingItem.soldItems !== undefined;
  }

  public isSaleItemDto(product: ItemTypeDto): product is MySaleItemDto {
    const mySaleItem = product as MySaleItemDto;

    return mySaleItem.itemType !== undefined && mySaleItem.itemSoldQuantity !== undefined;
  }

  public displayAuctionPrice(product: ItemTypeDto): boolean {
    // active auction/bidding price
    if (!this.displayBuyNowPrice(product)) {
      return true;
    }
    // if the item is in auction model and has not been sold yet
    if (this.isWatchingItemDto(product)) {
      if (product.itemTypeEnum === 'BIDDING' &&
        product.soldItems === 0) {
        return true;
      }
    }
    if (this.isSaleItemDto(product)) {
      if (product.itemType === 'BIDDING' &&
        product.itemSoldQuantity === 0) {
        return true;
      }
    }
    // probably covered by 1st option
    // just for sake of understandability / future cases
    if (this.isItemBidDto(product)) {
      return true;
    }

    return false;
  }

  public getAuctionPrice(product: ItemTypeDto): MoneyDto {
    // check if it makes sense
    if (!this.displayAuctionPrice(product)) {
      return null;
    }

    let res = (product as ItemPurchaseDto).dealAmount;
    if (!res) {
      res = (product as (MySaleItemDto | WatchingItemDto)).price;
    }
    if (!res) {
      res = (product as ItemBidDto).highestBidPrice;
    }

    return res;
  }

  public displayBuyNowPrice(product: ItemTypeDto): boolean {
    return (product as (MySaleItemDto | WatchingItemDto)).buyNowActive ||
      (product as ItemPurchaseDto).buyNowOffer;
  }

  public getBuyNowPrice(product: ItemTypeDto): MoneyDto {
    // check if it makes sense
    if (!this.displayBuyNowPrice(product)) {
      return null;
    }

    let res = (product as ItemPurchaseDto).dealAmount;
    if (!res) {
      res = (product as (ItemPurchaseDto | MySaleItemDto | WatchingItemDto)).buyNowPrice;
    }
    return res;
  }

  /**
   * @deprecated
   * use {@link ItemImagesUtil.getTitleImage } instead of this
   */
  public getTitleImage(images: ItemImageListsDto, size = 'medium'): string | Nil {
    const imagesArray: ItemImageDto[] = get(images, ['lists', size], []);
    const titleImage = find(imagesArray, (image) => image.titleImage);
    return titleImage ? titleImage.url : null;
  }

  public hasCommissionFee(o: CommissionFeeModel): boolean {
    return !isNil(o?.sellerCommissionFee) && o?.sellerCommissionFee?.amount > 0;
  }

}
