const BUY_NOW_INACTIVE: string = 'Možnost Kup teď už není dostupná, někdo přihodil';
const OFFER_EXPIRED: string = 'Nabídka skončila';
const PRICE_CHANGED: string = 'Prodejce změnil cenu';
const OWN_ITEMS: string = 'Nemůžete nakoupit své produkty.';

export const cartErrorMessages = {
  GENERAL_CART_ERROR: 'Odeslat objednávku se nepodařilo. Zkuste to prosím později nebo nás '
  + '<a href="/kontakt/nakup-nedari-se/technicke-nakup" target="_blank">kontaktujte</a>.',
  MISSING_DELIVERY_ADDRESS: 'Pro úspěšné dokončení nákupu si prosím doplňte své '
    + '<a href="/moje-aukro/muj-ucet/moje-udaje">osobní údaje</a>.'
      + 'V případě dalších dotazů nás můžete <a href="/kontakt/nakup-nedari-se/technicke-nakup" target="_blank">kontaktovat</a>.',
  NOT_ALLOWED_TO_BUY: 'Nemůžete zakoupit zboží, Váš uživatelský účet byl zablokován.'
    + '<br>V případě dalších dotazů se na nás prosím obraťte pomocí <a href="/kontakt">Kontaktního formuláře</a>.',
  REQUIRED_BUT_MISSING_PICKUP_POINT: 'U některého zvoleného způsobu dopravy chybí vybrané odběrné místo.'
    + 'Ověřte prosím, že jste odběrné místo vybrali.'
      + 'V případě dotazů nás můžete <a href="/kontakt/nakup-nedari-se/technicke-nakup" target="_blank">kontaktovat</a>.',
  INVALID_PHONE_NUMBER_ZASILKOVNA: 'Zadejte prosím validní telefonní číslo pro komunikaci se Zásilkovnou.',
  INVALID_PHONE_NUMBER_BALIKOVNA: 'Zadejte prosím validní telefonní číslo pro komunikaci s Balíkovnou.',
  INVALID_PHONE_NUMBER: 'Zadejte prosím validní telefonní číslo.',
  ITEM: {
    NOT_ENOUGH_QUANTITY: 'Snížili jsme množství dle aktuálního',
    BUY_NOW_INACTIVE,
    OFFER_EXPIRED,
    PRICE_CHANGED,
    USER_IS_ON_BLACKLIST: 'Prodejce vás zařadil na svou <a href="https://napoveda.aukro.cz/node/466" target="_blank">černou listinu</a>',
    USER_NOT_VERIFIED: 'Prodejce u této nabídky nově neumožňuje nákup ' +
      '<a target="_blank" href="https://napoveda.aukro.cz/prvni-kroky-na-aukru-plne-overeni-uctu/proc-je-dobre-si-ucet-overit-jak-provest">'
        + ' neověřeným uživatelům</a>',
    UNREGISTERED_USER_CANT_PAY_VIA_AUKRO:
    'Tento předmět nemohou zakoupit neregistrovaní uživatelé. Prosím nejprve se <a href="/registrace">zaregistrujte.</a>',
    OFFER_IS_HIDDEN: OFFER_EXPIRED,
  },
  ITEM_SINGLE: {
    'validation.item.shopping.quantity': 'Požadované množství již není dostupné',
    'validation.item.shopping.auction': BUY_NOW_INACTIVE,
    'validation.item.shopping.expiration': OFFER_EXPIRED,
    'validation.item.shopping.priceChanged': PRICE_CHANGED,
    'validation.item.shopping.right': 'Nemůžete zakoupit zboží, Váš uživatelský účet byl zablokován',
    'validation.item.shopping.myself': OWN_ITEMS,
  },
  // custom errors not from BE:
  OWN_ITEMS,
  FIRSTNAME_NOT_ALLOWED_HTML: 'Nepovolený výraz ve jménu.',
  LASTNAME_NOT_ALLOWED_HTML: 'Nepovolený výraz v příjmení.',
};
