import { ChangeDetectorRef, Injectable, Injector, PipeTransform } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { TranslationSource } from '@common/translations/model/translation-source';
import { Nil } from '@util/helper-types/nil';
import { TranslateCompiler, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { merge, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateSourcePipeService implements PipeTransform {

  public transform(
    value: TranslationSource,
    translateService: TranslateService,
    translateCompiler: TranslateCompiler,
    ngxTranslatePipe: TranslatePipe,
  ): string | Nil {
    // if there's no value return Nil
    if (isNil(value)) {
      return undefined;
    }

    // if there's userValue don't process it by translation lib and only return it
    if (!isNil(value.userValue)) {
      return value.userValue;
    }

    if (isNil(value.params)) {
      value.params = {};
    }

    if (value.defaultValue) {
      value.params.defaultValue = value.defaultValue;
    }

    const translatedVal = ngxTranslatePipe.transform(value.key, value.params) as string;

    // return translated value if it's not Nil and not ''
    if (!isNil(translatedVal) && translatedVal !== '') {
      return translatedVal;
    }

    // Take text from `defaultValue` and interpolate params
    // Compilation of translation is necessary for ICU support
    if (!isNil(value.defaultValue)) {
      return translateService.getParsedResult(
        { KEY: translateCompiler.compile(value.defaultValue, null) },
        'KEY',
        value.params ?? {},
      ) as string;
    }

    return '';
  }

  /**
   * same like {@link transform} but gets the injectable instances from given injector
   */
  public transformWithInjector(
    value: TranslationSource,
    injector: Injector,
  ): string | Nil {
    const translateService = injector.get(TranslateService);
    const ngxTranslatePipe = new TranslatePipe(translateService, injector.get(ChangeDetectorRef));

    return this.transform(
      value,
      translateService,
      injector.get(TranslateCompiler),
      ngxTranslatePipe,
    );
  }

  /**
   * same like {@link transformWithInjector} but instead returns observable which emits on every translation change event
   */
  public transformWithInjector$(
    value: TranslationSource,
    injector: Injector,
  ): Observable<string | Nil> {
    const translateService = injector.get(TranslateService);
    const ngxTranslatePipe = new TranslatePipe(translateService, injector.get(ChangeDetectorRef));
    const translateCompiler = injector.get(TranslateCompiler);

    return merge(
      translateService.onTranslationChange,
      translateService.onLangChange,
      translateService.onDefaultLangChange,
    )
      .pipe(
        // skip events from same tick window
        debounceTime(0),
        // always emit first value immediately
        startWith(null),
        map(() => this.transform(
          value,
          translateService,
          translateCompiler,
          ngxTranslatePipe,
        )),
        distinctUntilChanged(),
      );
  }

}
