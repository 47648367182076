import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import isNil from 'lodash-es/isNil';
import moment from 'moment-mini-ts';
import { CookieService } from '@common/cookie/service/cookie.service';
import { TokenMonitoringService } from '@shared/services/token-monitoring/token-monitoring.service';
import { JwtDataModel } from '../model/jwt-data.model';
import { Nil } from '@util/helper-types/nil';
import { CartConstants } from '@shared/cart/constant/cart.constants';

const AUTHENTICATON_TOKEN = 'auth-token';

@Injectable({
  providedIn: 'root',
})
export class AuthCacheService {

  private jwtHelper = new JwtHelperService();

  constructor(
    private readonly cookieService: CookieService,
  ) {
  }

  public get currentLoggedUserId(): number | Nil {
    return this.decodedToken?.uid;
  }

  public get authToken(): string | Nil {
    const token = this.cookieService.get(AUTHENTICATON_TOKEN);
    return token?.startsWith('Bearer') ? token : null;
  }

  public set authToken(token: string) {
    if (!token) {
      try {
        this.cookieService.remove(AUTHENTICATON_TOKEN);
        this.cookieService.remove(CartConstants.COOKIE_CART_NAME);
      } catch (e) {
        console.log('Error during removing cookie on server', e.toString());
      }
    } else {
      // expiration parameter is needed for android and ios native app - 30 days is reserve for future increasing expiration jwt token on BE
      // TODO: PDEV-11308 create config parameter for auth-token expiration
      this.cookieService.put(AUTHENTICATON_TOKEN, token, { expires: moment().add(300, 'days').toDate() });
    }
  }

  public get aukroToken(): string | Nil {
    const token = this.cookieService.get(TokenMonitoringService.AUKRO_TOKEN_COOKIE_AND_LS_KEY);
    if (!token) {
      return null;
    }

    return token;
  }

  public get authTokenWithExpirationCheck(): string | Nil {
    if (
      this.authToken?.startsWith('Bearer') &&
      !this.isAuthTokenExpired()
    ) {
      return this.authToken;
    }

    return null;
  }

  public isAuthTokenExpired(): boolean {
    return (
      isNil(this.authToken) || this.jwtHelper.isTokenExpired(this.authToken)
    );
  }

  public userAuthenticatedWithValidToken(): boolean {
    return !isNil(this.authTokenWithExpirationCheck);
  }

  public isLoggedIn(): boolean {
    return this.userAuthenticatedWithValidToken();
  }

  public get decodedToken(): JwtDataModel {
    if (!this.authToken) {
      return null;
    }

    return this.jwtHelper.decodeToken(this.authToken);
  }

}
