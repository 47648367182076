import { UserActionPrerequisiteFulfillmentService } from '../fulfillment/user-action-prerequisite-fulfillment.service';
import { UserActionType } from '../../model/user-action.type';
import { Observable } from 'rxjs';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';
import { UserTaskType } from '@shared/user-task/base/model/user-task.type';
import { UserActionPrerequisiteType } from '../../model/user-action-prerequisite.type';

export abstract class UserActionPrerequisiteTaskFulfillmentService
  <PREREQUISITE_COMBINATION extends UserActionPrerequisiteType[]>
  extends UserActionPrerequisiteFulfillmentService<PREREQUISITE_COMBINATION> {

  protected abstract readonly taskType: UserTaskType;

  constructor(
    protected readonly userTaskService: UserTaskService,
  ) {
    super();
  }

  public fulfillPrerequisite(userAction: UserActionType): Observable<void> {
    return this.userTaskService.executeTaskWithCommonAndCustomPayload$(
      this.taskType,
      { action: userAction },
      { sourceAction: userAction },
      userAction,
    );
  }

}
