// TODO(PDEV-11487) - refactor shipping ID to CODE
export class RegistryUtil {

  /**
   * Contains shipping codes for "Zasilkovna na vydejni misto CR" and its additional information ("priplatek doruceni na adresu")
   * @param id
   */
  public static isZbAShipping(id: number): boolean {
    return (
      id === 17 || // ZASILKOVNA
      id === 19 || // ZASILKOVNA_ADDRESS
      id === 20 || // ZASILKOVNA_SK
      id === 21    // ZASILKOVNA_ADDRESS_SK
    );
  }

}
