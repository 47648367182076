<div
    #tabMenuContainer
    [class.tw-border-l-0]="disableLeftBorder"
    class="tw-flex tw-h-full tw-bg-surface-4 tw-border-x tw-border-t tw-border-solid tw-border-surface-4 tw-overflow-hidden tw-gap-px lg:tw-rounded-t-lg"
>
  @for (trimmedTab of trimmedTabs; let isLast = $last; let index = $index; track index) {
    <auk-tab-menu-item
        #tabMenu
        [showTooltip]="!isMdAndLower"
        (tabClick)="onTabClick($event)"
        (tabHover)="onTabHover($event)"
        (tabHoverEnd)="onTabHoverEnd($event)"
        [class.tw-grow]="isLast"
        [tab]="trimmedTab"
    />
  }
</div>
