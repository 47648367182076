import { Injectable } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { AnnouncementApiService } from '@api/generated/api/Announcement';
import { BehaviorSubject, combineLatest, mergeMap, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { UrlAnnouncementDto } from '@api/generated/defs/UrlAnnouncementDto';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { Nil } from '@util/helper-types/nil';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { ArrayUtils } from '@util/util/array.utils';
import { distinctUntilChangedDeep } from '@util/rxjs-operators/distinct-until-changed-deep';
import { UrlService } from '@shared/platform/url.service';
import { InformationDialogComponent } from '@common/information-dialog/information-dialog.component';
import { InformationDialogData } from '@common/information-dialog/model/information-dialog-data';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';
import isNil from 'lodash-es/isNil';

interface UrlAnnouncementDtoWithRegex extends UrlAnnouncementDto {
  regex: RegExp;
}

@Injectable({
  providedIn: 'root',
})
export class UrlAnnouncementService extends NgUnsubscribe {

  private unreadAnnouncements$ = new BehaviorSubject<UrlAnnouncementDtoWithRegex[] | Nil>(null);

  constructor(private readonly announcementApiService: AnnouncementApiService,
              private readonly authenticationService: AuthenticationService,
              private readonly urlService: UrlService,
              private readonly aukMatDialogService: AukMatDialogService,
              private readonly router: Router) {
    super();
  }

  public init(): void {
    this.loadDataOnLoginStatusChange();
    this.openAnnouncementOnUrl();
  }

  private loadDataOnLoginStatusChange(): void {
    this.authenticationService.getLoginStatusChangeWithStartValue()
      .pipe(
        mergeMap((isLoggedIn) => isLoggedIn
          ? this.loadData$()
          : of(null)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((a: UrlAnnouncementDtoWithRegex[] | Nil) => this.unreadAnnouncements$.next(a));
  }

  private openAnnouncementOnUrl(): void {
    combineLatest([
      // Emit when user logged/logged out
      this.authenticationService.getLoginStatusChangeWithStartValueDistinct$(),
      // Emit on url changed
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map((event: NavigationEnd) => event.url),
          // Translate to have url in current app language
          mergeMap((url) => this.urlService.translateUrl(url)),
        ),
    ])
      .pipe(
        distinctUntilChangedDeep(),
        withLatestFrom(this.unreadAnnouncements$),
        // Do not process further if user is not logged
        filter(([[loggedIn, _], __]: [[boolean, string], UrlAnnouncementDtoWithRegex[]]) => loggedIn),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([[_, translatedUrl], announcements]: [[boolean, string], UrlAnnouncementDtoWithRegex[]]) => {

        if (ArrayUtils.isEmpty(announcements)) {
          return;
        }

        const matchingAnnouncement = this.findFirstMatching(announcements, translatedUrl);

        if (isNotNil(matchingAnnouncement)) {
          this.openAnnouncement(matchingAnnouncement);
        }
      });
  }

  private loadData$(): Observable<UrlAnnouncementDtoWithRegex[]> {
    return this.announcementApiService.getUrlAnnouncementsForUser()
      .pipe(
        take(1),
        map<UrlAnnouncementDto[], UrlAnnouncementDtoWithRegex[]>((urlAnnouncements) => urlAnnouncements?.map((entry) => ({
          ...entry,
          regex: new RegExp(entry.targetUrlRegex),
        })) ?? []),
      );
  }

  private findFirstMatching(announcements: UrlAnnouncementDto[], url: string): UrlAnnouncementDto | Nil {
    return announcements.find((a: UrlAnnouncementDtoWithRegex) => a?.regex.test(url));
  }

  private openAnnouncement(announcement: UrlAnnouncementDto): void {
    if (isNil(announcement)) {
      return;
    }

    this.aukMatDialogService.openSimple$(InformationDialogComponent, {
      disableClose: true,
      data: {
        title: { defaultValue: announcement.subject },
        content: announcement.body,
        confirmBtnLabel: { key: 'URL_ANNOUNCEMENT_DIALOG_CONFIRM' },
      } as InformationDialogData,
      panelClass: ['announcement-modal-dialog'],
    })
      .pipe(
        take(1),
        switchMap((dialogRef) => dialogRef.afterClosed()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.confirmRead(announcement.id));
  }

  private confirmRead(dialogId: number): void {
    // Break if user was logged out meantime
    if (!this.authenticationService.isLoggedIn()) {
      return;
    }

    this.announcementApiService.setUrlAnnouncementsRead({ id: dialogId })
      .pipe(
        // Reload data after confirm
        switchMap(() => this.loadData$()),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data) => this.unreadAnnouncements$.next(data));
  }

}
