import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BuildInfoService } from '@shared/version/service/build-info.service';

@Injectable()
export class ClientTypeInterceptor implements HttpInterceptor {

  constructor(
    private readonly buildInfoService: BuildInfoService,
  ) {
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authReq = req.clone({
      setHeaders: {
        'x-aukro-client': 'platform-frontend',
        'x-aukro-client-version': this.buildInfoService.version,
      },
    });
    return next.handle(authReq);
  }

}
