import { Injectable } from '@angular/core';
import { PreloadingLcpCandidatePriority } from '../model/preloading-lcp-candidate-priority';
import { PreloadingLcpCandidateModel } from '../model/preloading-lcp-candidate.model';
import isNil from 'lodash-es/isNil';
import { ArrayUtils } from '@util/util/array.utils';

@Injectable({
  providedIn: 'root',
})
export class PreloadingRegisterService {

  private priorityToLcpCandidatesMap: ReadonlyMap<PreloadingLcpCandidatePriority, PreloadingLcpCandidateModel[]> = new Map([
    [PreloadingLcpCandidatePriority.LOW, []],
    [PreloadingLcpCandidatePriority.LOW_MEDIUM, []],
    [PreloadingLcpCandidatePriority.MEDIUM, []],
    [PreloadingLcpCandidatePriority.MEDIUM_HIGH, []],
    [PreloadingLcpCandidatePriority.HIGH, []],
  ]);

  public registerLcpScriptPreloadingCandidate(url: string, priority: PreloadingLcpCandidatePriority): void {
    const candidate: PreloadingLcpCandidateModel = {
      url,
      priority,
      type: 'script',
    };
    this.registerPreloadingCandidates(priority, candidate);
  }

  public registerLcpImagePreloadingCandidate(url: string, priority: PreloadingLcpCandidatePriority): void {
    if (isNil(url)) {
      return;
    }

    const candidate: PreloadingLcpCandidateModel = {
      url,
      priority,
      type: 'image',
    };
    this.registerPreloadingCandidates(priority, candidate);
  }

  public registerLcpImagesPreloadingCandidates(
    priority: PreloadingLcpCandidatePriority,
    ...imageUrls: string[]
  ): void {
    if (ArrayUtils.isEmpty(imageUrls)) {
      return;
    }

    const candidates: PreloadingLcpCandidateModel[] = imageUrls.map(imageUrl => ({
      url: imageUrl,
      priority,
      type: 'image',
    }));

    this.registerPreloadingCandidates(priority, ...candidates);
  }

  public getHighestPriorityCandidates(): PreloadingLcpCandidateModel[] {
    const nonEmptyPriorities: PreloadingLcpCandidatePriority[] = Array.from(this.priorityToLcpCandidatesMap.keys())
      .filter(k => !ArrayUtils.isEmpty(this.priorityToLcpCandidatesMap.get(k)));

    if (ArrayUtils.isEmpty(nonEmptyPriorities)) {
      return [];
    }

    return this.priorityToLcpCandidatesMap.get(Math.max(...nonEmptyPriorities));
  }

  private registerPreloadingCandidates(priority: PreloadingLcpCandidatePriority, ...candidates: PreloadingLcpCandidateModel[]): void {
    if (isNil(priority) || ArrayUtils.isEmpty(candidates)) {
      return;
    }

    this.priorityToLcpCandidatesMap.get(priority).push(...candidates);
  }

}
