@if (favouriteSearch?.pageFavouriteSearchDto.numberOfElements > 0) {
  <div>
    @for (favouriteSearch of favouriteSearch.pageFavouriteSearchDto.content; track trackByIndexFn($index)) {
      <a
        class="!tw-no-underline !tw-text-matter-neutral-primary"
        [routerLink]="getFavouriteSearchUrlInfo(favouriteSearch).routerLink"
        [queryParams]="getFavouriteSearchUrlInfo(favouriteSearch).queryParams"
        (click)="onSearchNewItemsClick($event)"
        >
        <div class="tw-flex tw-flex-col">
          <div class="content">
            <!-- INFO SECTION -->
            <div class="tw-flex tw-flex-row tw-gap-2.5 tw-flex-grow tw-min-w-0 tw-items-center">
              <!-- IMAGE -->
              <div class="tw-flex tw-shrink-0">
                <auk-icon source="search-new"
                  size="2XMD"
                  iconType="SVG"
                  />
              </div>
              <!-- ./IMAGE -->
              <!-- DESCRIPTION -->
              <div class="tw-flex tw-flex-col tw-gap-1 tw-flex-1 tw-pr-2 tw-items-start tw-min-w-0">
                <!-- ITEM NAME -->
                <span aukTestIdentification="favourite-search-title" class="tw-text-sm tw-font-medium tw-truncate tw-w-full">
                  {{ favouriteSearch.title }}
                </span>
                <span class="tw-truncate tw-w-full">
                  {{ favouriteSearch.searchParamText }}
                </span>
                <!--./ITEM NAME -->
              </div>
              <!-- ./DESCRIPTION -->
            </div>
            <!-- ./INFO SECTION -->

            <!-- NEW ITEMS COUNT -->
            <div class="tw-flex tw-flex-row tw-items-end tw-gap-1 tw-content-center tw-whitespace-nowrap"
                 [ngClass]="(favouriteSearch.newItemsCount > 0) ? '' : 'tw-text-matter-neutral-secondary'"
            >
              <span class="tw-text-sm tw-font-bold">
                {{ favouriteSearch.newItemsCount }}
                <span class="tw-font-normal tw-whitespace-nowrap">
                  {{ {key: 'FAVOURITES_POPOVER_COMMON_ITEMS_PLACED'} | translateSource }}
                </span>
              </span>
            </div>
            <!-- ./NEW ITEMS COUNT -->

          </div>
        </div>
      </a>
    }
  </div>
} @else {
  <auk-favourite-popover-empty-tab-content
    [dataLoading]="dataLoading"
    [emptyPopoverContent]="emptyPopoverContent"
    />
}

