import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';
import { DOCUMENT } from '@angular/common';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { DomainPickerService } from '@shared/domain-picker/service/domain-picker-service';
import { DomainService } from '@shared/platform/domain.service';

@Component({
  selector: 'auk-domain-picker',
  templateUrl: './domain-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainPickerComponent extends NgUnsubscribe {

  @Input()
  public showTitle: boolean = true;

  constructor(
    private readonly domainPickerService: DomainPickerService,
    private readonly domainService: DomainService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super();
  }

  protected get getIconModel(): IconModel {
    return {
      type: 'SVG',
      source: this.domainService.locale.flag,
      size: 'XL',
    };
  }

  protected onClick(): void {
    this.openRegionPreferenceModal();
  }

  private openRegionPreferenceModal(): void {
    this.domainPickerService.openRegionPreferenceModal(this.domainService.locale);
  }

}
