import { Injectable } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrencyCode } from '../model/currency-code.model';

@Injectable({
  providedIn: 'root',
})
export class UserCurrencyPreferenceDataService extends NgUnsubscribe {

  private _currentCurrencyCode$: BehaviorSubject<CurrencyCode> = new BehaviorSubject<CurrencyCode>(null);

  constructor() {
    super();
  }

  public set currentCurrencyCode(val: CurrencyCode) {
    if (this.currentCurrencyCodeValue !== val) {
      this._currentCurrencyCode$.next(val);
    }
  }

  public get currentCurrencyCode$(): Observable<CurrencyCode> {
    return this._currentCurrencyCode$.asObservable();
  }

  public get currentCurrencyCodeValue(): CurrencyCode {
    return this._currentCurrencyCode$.value;
  }

}
