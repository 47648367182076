import isNil from 'lodash-es/isNil';
import { ArrayUtils } from '@util/util/array.utils';
import { Nil } from '@util/helper-types/nil';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export class ObjectUtils {

  public static reverseRecord<T extends PropertyKey, U extends PropertyKey>(input: Record<T, U>): Record<U, T> | Nil {

    if (isNil(input)) {
      return null;
    }

    const reversed: unknown[][] = [];

    for (const entry of Object.entries(input)) {

      const key: string = entry[0];
      const value: unknown = entry[1];

      if (!ObjectUtils.isPropertyKeyType(value)) {
        console.error(`Cant reverse provided object:
         value of key ${ key } is of type ${ typeof value }, which is not usable as an object key`);
        return null;
      }

      reversed.push(entry.reverse());
    }

    const duplicates = ArrayUtils.findDuplicates(reversed.flatMap(e => e[0]));
    if (ArrayUtils.isNotEmpty(duplicates)) {
      console.error(`Cant reverse provided object: there are duplicate among values with keys: ${ duplicates } `);
      return null;
    }

    return Object.fromEntries(reversed) as Record<U, T>;
  }

  public static isPropertyKeyType(val: unknown): boolean {
    return typeof val === 'string' || typeof val === 'number' || typeof val === 'symbol';
  }

  /**
   * @returns true, if given value is object
   * @param value - value which should be checked
   */
  public static isObject(value: unknown): value is object {
    return typeof value === 'object' &&
      !Array.isArray(value) &&
      value !== null;
  }

  /**
   * @returns First object key from given object
   * @param value - object, from which the first key should be returned
   */
  public static getFirstObjectKey<OBJECT_TYPE extends object>(value: OBJECT_TYPE): keyof OBJECT_TYPE | Nil {
    if (!ObjectUtils.isObject(value)) {
      return null;
    }
    return (Object.keys(value) as (keyof OBJECT_TYPE)[])[0];
  }

  /**
   * @returns object value by first key of the object
   * @param value - object, from which the value should be returned
   */
  public static getFirstObjectValue<OBJECT_TYPE extends object>(value: OBJECT_TYPE): OBJECT_TYPE[keyof OBJECT_TYPE] | Nil {
    if (!ObjectUtils.isObject(value)) {
      return null;
    }

    // find first object key
    const firstObjectKey = this.getFirstObjectKey(value);

    // return object value by first object key
    return value[firstObjectKey];
  }

  /**
   * Works exactly like `Object.keys` but is strongly typed
   * @param value - object of which keys should be returned
   * @returns array of keys of the object
   */
  public static keys<OBJECT_TYPE extends object>(value: OBJECT_TYPE): (keyof OBJECT_TYPE)[] {
    return Object.keys(value) as (keyof OBJECT_TYPE)[];
  }

  /**
   * Works exactly like `Object.entries` but is strongly typed
   * @param value - object of which entries should be returned
   * @returns array of entries of the object
   */
  public static entries<OBJECT_TYPE extends object>(value: OBJECT_TYPE): Entries<OBJECT_TYPE> {
    return Object.entries(value) as Entries<OBJECT_TYPE>;
  }

  /**
   * Works exactly like `Object.values` but is strongly typed
   * @param value - object of which values should be returned
   * @returns array of values of the object
   */
  public static values<OBJECT_TYPE extends object>(value: OBJECT_TYPE): (OBJECT_TYPE[keyof OBJECT_TYPE])[] {
    return Object.values(value) as (OBJECT_TYPE[keyof OBJECT_TYPE])[];
  }

  /**
   * @returns first non NIL key of the object
   * @param value - object to check
   */
  public static getFirstNonNilKey<OBJECT_TYPE extends object>(value: OBJECT_TYPE): keyof typeof value | Nil {
    if (isNil(value)) {
      return null;
    }

    let nonNilKey: keyof typeof value;

    for (const [objectKey, objectValue] of ObjectUtils.entries(value)) {
      if (!isNil(objectValue)) {
        nonNilKey = objectKey;
        break;
      }
    }

    return nonNilKey;
  }

  /**
   * @returns false when the object contains at least 1 field, otherwise true (if value is Nil, returns true)
   * @param value - object to check
   */
  public static isObjectEmpty<T extends object = object>(value: T): boolean {
    if (isNil(value)) {
      return true;
    }

    return Object.keys(value).length === 0;
  }

  /**
   * @returns Object with filtered fields recording to keys
   * @param value - object to reduce
   * @param keys - field names of object
   */
  public static filterObjectByKeys<OBJECT_TYPE extends object>(value: OBJECT_TYPE, keys: (keyof OBJECT_TYPE)[]): Partial<OBJECT_TYPE> {
    return ObjectUtils.entries(value)
      .reduce((acc, [fieldKey, fieldValue]) => {
        if (!keys.includes(fieldKey)) {
          return acc;
        }
        return {
          ...acc,
          [fieldKey]: fieldValue,
        };
      }, {} as Partial<OBJECT_TYPE>);
  }

  public static isNilOrEmptyOrHasAllNilValues(value: object | Nil): boolean {
    if (isNil(value)) {
      return true;
    }

    return Object.values(value).every(isNil);
  }

}
