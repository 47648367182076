import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { GridMenuItemGroupModel } from '@common/ui-kit/component/grid-menu/component/model/grid-menu.model';
import { getTrackByTranslationSourceFn } from '@util/helper-functions/get-track-by-translation-source-fn';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@Component({
  selector: 'auk-grid-menu',
  templateUrl: './grid-menu.component.html',
  styleUrls: ['./grid-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    InnerHtmlDirective,
    NgClass,
  ],
})
export class GridMenuComponent {

  @Input() public gridMenuItems: GridMenuItemGroupModel[];
  @Input() public justifyContentBetween: boolean = false;

  protected trackByTitleFn = getTrackByTranslationSourceFn('title');

}
