import { defer, Observable, of, OperatorFunction, retry, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import isNil from 'lodash-es/isNil';
import { isNotNil } from '@util/helper-functions/is-not-nil';

interface RetryBackoffConfig<TResult> {
  /**
   * Called everytime when source observable returns result
   * @returns true if source observable should be retried
   * @param result - result of the which is being retried
   */
  retryIfFn: (result: TResult) => boolean;
  /**
   * Initial interval, when the obs$ should be retried on the first time
   */
  initialRetryInterval: number;
  /**
   * Maximum number of retry attempts.
   */
  maxRetries?: number;
  /**
   * Maximum interval time of retry attempts.
   */
  maxRetryTime?: number;
  /**
   * Delay increment function
   * Calculates after which time should be the source observable retried
   * @param currentIteration - current count of iteration
   * @param initialInterval - value of initial interval {@link initialRetryInterval}
   */
  delayIncrementFn?: (currentIteration: number, initialInterval: number) => number;
  /**
   * Called if {@link retryIfFn } doesn't match even after max retries
   */
  onFailFn?: (lastResult: TResult) => void;
}

/**
 * Returns an Observable that mirrors the source Observable except with an error.
 * If the source Observable calls error, rather than propagating
 * the error call this method will resubscribe to the source Observable with
 * exponentially increasing interval and up to a maximum of count
 * re-subscriptions (if provided). Retrying can be cancelled at any point if
 * shouldRetry returns false.
 */
export function retryIf<T>(
  config: RetryBackoffConfig<T>,
  ngZoneUtilService: NgZoneUtilService,
): OperatorFunction<T, T> {
  const {
    retryIfFn,
    initialRetryInterval,
    maxRetries,
    maxRetryTime,
    delayIncrementFn = exponentialIncrementFn,
    onFailFn,
  } = config;

  return (source$: Observable<T>) => {
    let currentRetryCount = 0;

    return defer(() => source$
      .pipe(
        mergeMap((value) => {
          const shouldRetry = retryIfFn(value);

          if (isNotNil(maxRetries) && shouldRetry && currentRetryCount === maxRetries) {
            onFailFn?.(value);
          }

          return shouldRetry
            ? throwError(() => value)
            : of(value);
        }),
        retry({
          delay: (_, retryCount) => {
            currentRetryCount = retryCount;

            /**
             * Calculates the delay before the next retry attempt.
             * If `maxRetryTime` is not specified (nil), the delay is determined by the `delayIncrementFn`,
             * which typically increases the delay exponentially based on the current retry iteration and
             * the initial retry interval. If `maxRetryTime` is specified, the delay is the minimum between
             * the calculated delay and `maxRetryTime`, ensuring the delay does not exceed `maxRetryTime`.
             */
            const retryTime = isNil(maxRetryTime)
              ? delayIncrementFn(retryCount, initialRetryInterval)
              : Math.min(delayIncrementFn(retryCount, initialRetryInterval), maxRetryTime);

            return ngZoneUtilService.timerOut$(retryTime);
          },
          count: isNil(maxRetries) ? undefined : maxRetries,
        }),
      ),
    );
  };

}

function exponentialIncrementFn(
  iteration: number,
  initialInterval: number,
): number {
  return Math.pow(2, iteration) * initialInterval;
}
