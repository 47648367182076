import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { ConfirmEmailDto } from '../model/confirm-email-dto';
import { PasswordRecoveryChangeDto } from '../model/password-recovery-change-dto';
import { PasswordRecoveryDto } from '../model/password-recovery-dto';
import { PasswordRecoveryOptionDto } from '../model/password-recovery-option-dto';
import { UserLoginDto } from '../model/user-login-dto';
import { BASE_PATH } from '../variables';

export interface ActivateAccountRequestParams {
    /** Activation token */
    token: string;
    xAukroToken?: string;
}

export interface ActivateNewAccountEmailRequestParams {
    /** Activation token */
    token: string;
}

export interface ConfirmEmailRequestParams {
    /** Activation token */
    token: string;
    xAukroToken?: string;
}

export interface EntrySmsCodeRequestParams {
    /** Code from SMS */
    smsCode: string;
    /** Id of user */
    userId: number;
}

export interface GetPasswordRecoveryInfoRequestParams {
    /** Login or e-email */
    loginOrEmail: string;
}

export interface RecoverUserPasswordRequestParams {
    passwordRecoveryDto: PasswordRecoveryDto;
}

export interface SetNewRecoveredPasswordRequestParams {
    passwordRecoveryChangeDto: PasswordRecoveryChangeDto;
    xAukroToken?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AccountsApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * [UCU010.7] Activate user account by token
   * @deprecated
   */
  public activateAccount$(
    requestParameters: ActivateAccountRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserLoginDto> {
    const token: string = requestParameters.token;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'UserLoginDto'),
      headers,
    };
    return this.httpClient.request<UserLoginDto>(
      'post',
      `${ BASE_PATH }/accounts/activate/${ this.configuration.encodeParam({ name: 'token', value: token, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * Confirm email change
   * @deprecated
   */
  public activateNewAccountEmail$(
    requestParameters: ActivateNewAccountEmailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<boolean> {
    const token: string = requestParameters.token;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'boolean'),
      headers,
    };
    return this.httpClient.request<boolean>(
      'post',
      `${ BASE_PATH }/accounts/confirmEmailChange/${ this.configuration.encodeParam({ name: 'token', value: token, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * Confirm email
   */
  public confirmEmail$(
    requestParameters: ConfirmEmailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ConfirmEmailDto> {
    const token: string = requestParameters.token;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'ConfirmEmailDto'),
      headers,
    };
    return this.httpClient.request<ConfirmEmailDto>(
      'post',
      `${ BASE_PATH }/accounts/confirm-email/${ this.configuration.encodeParam({ name: 'token', value: token, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * [UCU010.4] Check SMS code and get token for password recovery
   */
  public entrySmsCode$(
    requestParameters: EntrySmsCodeRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<string> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      smsCode: requestParameters.smsCode,
      userId: requestParameters.userId,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'string'),
      headers,
    };
    return this.httpClient.request<string>(
      'post',
      `${ BASE_PATH }/accounts/passwordRecovery/smsCode`,
      options,
    );
  }

  /**
   * [UCU010.4] Get user\&#39;s password recovery information by login or e-mail address
   */
  public getPasswordRecoveryInfo$(
    requestParameters: GetPasswordRecoveryInfoRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PasswordRecoveryOptionDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      loginOrEmail: requestParameters.loginOrEmail,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PasswordRecoveryOptionDto'),
      headers,
    };
    return this.httpClient.request<PasswordRecoveryOptionDto>(
      'get',
      `${ BASE_PATH }/accounts/passwordRecoveryOption`,
      options,
    );
  }

  /**
   * [UCU010.4] Recover user\&#39;s password by login or e-email address.
   */
  public recoverUserPassword$(
    requestParameters: RecoverUserPasswordRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<string> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.passwordRecoveryDto,
      responseType: this.configuration.getResponseType(false, 'string'),
      headers,
    };
    return this.httpClient.request<string>(
      'post',
      `${ BASE_PATH }/accounts/passwordRecovery`,
      options,
    );
  }

  /**
   * [UCU010.4] Set new password for user
   */
  public setNewRecoveredPassword$(
    requestParameters: SetNewRecoveredPasswordRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.passwordRecoveryChangeDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/accounts/passwordRecovery/change`,
      options,
    );
  }

}
