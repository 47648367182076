import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface BusinessFormsForCountryParams {
  /**
   * countryId
   * format: int64
   */
  countryId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetCountriesUsingGETParams {
  /** orderEnum */
  orderEnum?: OrderEnumGetCountriesUsingGETParams;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type OrderEnumGetCountriesUsingGETParams =
  'CODE' |
  'NAME' |
  'POPULAR';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface EuCountriesParams {
  /** orderEnum */
  orderEnum?: OrderEnumEuCountriesParams;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type OrderEnumEuCountriesParams =
  'CODE' |
  'NAME' |
  'POPULAR';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ListItemDurationValuesParams {
  /** itemType */
  itemType: ItemTypeListItemDurationValuesParamsEnum;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type ItemTypeListItemDurationValuesParamsEnum =
  'BIDDING' |
  'BUYNOW';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root',
})
export class RegistryApiService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public accountingEntryTypes(): Observable<model.RegistryItemDto[]> {
    return this.getGenerated(`/registry/accountingEntryTypes`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public businessFormsForCountry(params: BusinessFormsForCountryParams): Observable<model.RegistryItemDto[]> {
    let pathParams = {
      countryId: params.countryId,
    };
    let queryParams;
    return this.getGenerated(`/registry/businessFormsForCountry/${pathParams.countryId}`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public reasons(): Observable<model.RegistryItemDto[]> {
    return this.getGenerated(`/registry/change/name/reasons`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getCountriesUsingGET(params: GetCountriesUsingGETParams): Observable<model.RegistryCountryItemDto[]> {
    let queryParams = {
      orderEnum: params.orderEnum,
    };
    return this.getGenerated(`/registry/countries`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public detail(): Observable<model.RegistryDetailDto> {
    return this.getGenerated(`/registry/detail`);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public expose(): Observable<model.RegistryDetailDto> {
    return this.getGenerated(`/registry/expose`);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public simpleExpose(): Observable<model.RegistryDetailDto> {
    return this.getGenerated(`/registry/expose-se`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public euCountries(params: EuCountriesParams): Observable<model.RegistryCountryItemDto[]> {
    let queryParams = {
      orderEnum: params.orderEnum,
    };
    return this.getGenerated(`/registry/euCountries`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public receiver(): Observable<model.RegistryItemDto[]> {
    return this.getGenerated(`/registry/feedback/receiver`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public types(): Observable<model.RegistryItemDto[]> {
    return this.getGenerated(`/registry/feedback/types`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public type(): Observable<model.RegistryItemDto[]> {
    return this.getGenerated(`/registry/invoice/type`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public listItemDurationValues(params: ListItemDurationValuesParams): Observable<model.RegistryItemDto[]> {
    let queryParams = {
      itemType: params.itemType,
    };
    return this.getGenerated(`/registry/listItemDurationValues`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public listItemReexposeTypeValues(): Observable<model.RegistryItemDto[]> {
    return this.getGenerated(`/registry/listItemReexposeTypeValues`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public listItemShipmentPayerTypeValues(): Observable<model.RegistryItemDto[]> {
    return this.getGenerated(`/registry/listItemShipmentPayerTypeValues`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public notificationTerms(): Observable<model.RegistryItemDto[]> {
    return this.getGenerated(`/registry/notificationTerms`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public states(): Observable<model.RegistryItemDto[]> {
    return this.getGenerated(`/registry/offer/states`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public methods(): Observable<model.RegistryItemDto[]> {
    return this.getGenerated(`/registry/payment/methods`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public refundReasons(): Observable<model.RegistryItemDto[]> {
    return this.getGenerated(`/registry/payment/refundReasons`);
  }

}
