import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Host,
  Input,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import isUndefined from 'lodash-es/isUndefined';
import { ValidationMessage } from '@shared/legacy/directive/validation-messages/validation-messages';
import { NgClass } from '@angular/common';
import { Translate2Module } from '@common/translations/translate2.module';

/**
 * This page must be used with ValidationMessagesDirective
 */
@Component({
  selector: 'auk-validation-message-legacy',
  templateUrl: 'validation-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    Translate2Module,
  ],
  standalone: true,
})
export class ValidationMessageComponent {

  /**
   * Used to check if control has specified error.
   */
  @Input() public errorCode?: string;

  /**
   * Used to get validation message if path is different from default validation message key.
   */
  @Input() public fieldName?: string;

  /**
   * Ignore messages from fields-validation-messages.ts.
   */
  @Input() public ignoreFields?: boolean = false;

  /**
   * Custom message text.
   */
  @Input() public text?: string;

  /**
   * Show the green valid checkbox based on the valid state
   */
  @Input() public showValid: boolean = false;

  /**
   * Used if you need html as a validation message
   */
  @ViewChild('content', { static: false }) public content: ElementRef;
  public _fieldName: string;
  public hasContent: boolean = false;

  constructor(@Host() public formGroupDirective: FormGroupDirective,
              private cd: ChangeDetectorRef) {
  }

  public _message: ValidationMessage;

  public set message(msg: ValidationMessage) {
    this._message = msg;
    const el: Element = this.content && this.content.nativeElement;
    this.hasContent = !!(el && el.children.length > 0);
    this.cd.detectChanges();
  }

  public _path: string;

  /**
   * Path to get control in FormGroup.
   * If fieldName is not set it is also used to get default validation message.
   * If path contains dots only last part is used to get default validation message.
   */
  @Input()
  public set path(path: string) {
    this._path = path;
    if (this.fieldName) {
      return;
    }
    const dotIndex = path.lastIndexOf('.');
    this._fieldName = dotIndex !== -1 ? path.substring(dotIndex + 1, path.length) : path;
  }

  private _valid: boolean;

  /**
   * Get valid state either from _valid variable
   * or from the control.valid state (Default)
   */
  public get valid(): boolean {
    return !isUndefined(this._valid) && this._valid || this.control.valid;
  }

  /**
   * Override default valid state checking (control.valid)
   * by passing any boolean value here
   */
  @Input()
  public set valid(v: boolean) {
    this._valid = v;
  }

  public get control(): AbstractControl {
    return this.formGroupDirective.form.get(this._path);
  }

}
