import { CurrencyEnumParams } from '@api/generated/api/Currency';
import { UserInterestStatisticsDtoCurrencyEnum } from '@api/generated/defs/UserInterestStatisticsDto';
import { MyAukroUserPreferencesDataDtoCurrencyEnum } from '@api/generated/defs/MyAukroUserPreferencesDataDto';
import { MyAukroUserPreferencesChangeDtoCurrencyEnum } from '@api/generated/defs/MyAukroUserPreferencesChangeDto';

export const CurrenciesCodeArr = ['CZK', 'EUR'] as const;

export type CurrencyCode =
  | CurrencyEnumParams
  | UserInterestStatisticsDtoCurrencyEnum
  | MyAukroUserPreferencesDataDtoCurrencyEnum
  | MyAukroUserPreferencesChangeDtoCurrencyEnum;
