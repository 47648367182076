import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ColorCombinationInputComponent,
} from '@common/colors/component/color-combination-input/color-combination-input.component';

@Component({
  selector: 'auk-sticker',
  templateUrl: './sticker.component.html',
  styleUrls: ['./sticker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class StickerComponent extends ColorCombinationInputComponent {

}
