<div class="tw-flex tw-h-screen tw-max-w-md tw-mx-auto tw-overflow-auto">
  <div class="tw-my-auto tw-w-full tw-text-center">
    <h3 [aukInnerHtml]="{ key: isCapacitorNativeApp ? 'NEW_NATIVE_APP_UPDATE_REQUIRED_HEADING' : 'OLD_NATIVE_APP_UPDATE_REQUIRED_HEADING' }"
        class="tw-font-bold tw-text-xl tw-mb-7 tw-text-silverstein-700"
    ></h3>

    <p [aukInnerHtml]="{ key: isCapacitorNativeApp ? 'NEW_NATIVE_APP_UPDATE_REQUIRED_TEXT' : 'OLD_NATIVE_APP_UPDATE_REQUIRED_TEXT'}"
       class="tw-mb-9 tw-text-md tw-text-silverstein-800"
    ></p>

    <a [aukLinkFromHref]="isCapacitorNativeApp ? updateLinkCapacitor : updateLink">
      <auk-button [chin]="true"
                  class="tw-w-full"
                  colorCombination="PRIMARY"
      >
        {{ { key: 'OLD_NATIVE_APP_UPDATE_REQUIRED_BUTTON' } | translateSource }}
      </auk-button>
    </a>
  </div>
</div>
