import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { BannerPlacementDto } from '@api/generated/defs/BannerPlacementDto';
import { takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { BannerDeploymentDto } from '@api/generated/defs/BannerDeploymentDto';
import { merge, Subject } from 'rxjs';
import { BannerCookieService } from '@shared/banner/service/banner-cookie.service';
import { BannerGoogleAnalyticsService } from '@shared/banner/service/banner-google-analytics.service';
import { BannerConstants } from '@shared/banner/domain/banner.constants';
import isNil from 'lodash-es/isNil';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

@Component({
  selector: 'auk-top-line-banner',
  templateUrl: 'top-line-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopLineBannerComponent extends NgUnsubscribe implements OnChanges {

  @Input()
  public bannerPlacement: BannerPlacementDto;

  protected bannerDeployment: BannerDeploymentDto;
  protected isVisible: boolean;

  private readonly hideTimerChange$: Subject<void> = new Subject<void>();

  constructor(
    private readonly bannerGoogleAnalyticsService: BannerGoogleAnalyticsService,
    private readonly bannerCookieService: BannerCookieService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<TopLineBannerComponent>): void {
    if (isNil(changes.bannerPlacement)) {
      return;
    }

    // for top-line banner we take always just one particular deployment
    this.bannerDeployment = this.bannerPlacement?.bannerPositions?.[0]?.bannerDeployments?.[0];
    void this.bannerGoogleAnalyticsService.trackBannerView([this.bannerPlacement]);
    this.hideTimerChange$.next();
    this.initTimer();
    this.isVisible = true;
  }

  /**
   * If user clicks somewhere in the top-line banner, we have to detect whether the click comes from a link or not.
   * If the click comes from a link, we track the event for GA.
   * @param event
   */
  protected onContentClick(event: MouseEvent): void {
    const path = event.composedPath() as HTMLElement[];
    const isClickFromLink: boolean = !!path.find((item: HTMLElement) => item.tagName?.toUpperCase() === 'A');
    if (!isClickFromLink) {
      return;
    }

    void this.bannerGoogleAnalyticsService.trackBannerClick(
      this.bannerPlacement,
      this.bannerPlacement?.bannerPositions?.[0], // top-line banner always has just one position
      this.bannerDeployment,
    );
  }

  protected onCloseClick(): void {
    this.isVisible = false;
    this.updateBannerCookies();
    this.changeDetectorRef.markForCheck();
  }

  private initTimer(): void {
    if (!this.bannerDeployment?.showTimeInSeconds) { // null or 0
      return;
    }

    this.ngZoneUtilService.timerOut$(this.bannerDeployment.showTimeInSeconds * 1000)
      .pipe(
        takeUntil(
          merge(
            this.hideTimerChange$,
            this.ngUnsubscribe,
          ),
        ),
      )
      .subscribe(() => {
        this.onCloseClick();
      });
  }

  private updateBannerCookies(): void {
    this.bannerCookieService.updateBannerDeploymentIdsInCookie(
      BannerConstants.TOP_LINE_BANNER_DEPLOYMENT_IDS_SHOWN_COOKIE_KEY,
      this.bannerPlacement,
    );
  }

}
