import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationSkipped, NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { AppInitializer } from '@util/helper-types/initializer.interface';

@Injectable({
  providedIn: 'root',
})
export class RouterOutletWithLoaderService extends BaseDestroy implements AppInitializer {

  private _resetLoader$ = new Subject<void>();
  private _isNavigationInProgress$ = new Subject<boolean>();

  constructor(
    private router: Router,
  ) {
    super();
  }

  public init(): void {
    this.router.events
      .pipe(
        filter((event) => !(event instanceof NavigationSkipped)),
        takeUntil(this.destroy$),
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this._isNavigationInProgress$.next(true);
        }

        if (
          event instanceof NavigationEnd
          || event instanceof NavigationCancel
          || event instanceof NavigationError
        ) {
          this._isNavigationInProgress$.next(false);
        }
      });
  }

  public get resetLoader$(): Observable<void> {
    return this._resetLoader$.asObservable();
  }

  public get isNavigationInProgress$(): Observable<boolean> {
    return this._isNavigationInProgress$.asObservable();
  }

  public resetLoader(): void {
    this._resetLoader$.next();
  }

}
