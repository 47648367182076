<auk-dialog-wrapper
    [showCloseInHeading]="showCloseInHeading"
    (closeDialogClick)="onCloseDialogClick()"
>
  <auk-dialog-content
      [dialogTitle]="dialogTitle"
      [dialogSubtitle]="subtitle"
  >
    <ng-content content select="[content]"></ng-content>
    <ng-content actions select="[actions]"></ng-content>
  </auk-dialog-content>
</auk-dialog-wrapper>
