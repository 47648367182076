import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { UserAnnouncementStatusDto } from '@api/generated/defs/UserAnnouncementStatusDto';
import { AnnouncementDialogResult } from '@shared/services/annoucement/announcement-custom.service';

@Component({
  selector: 'auk-announcement-popup',
  templateUrl: './announcement-popup.component.html',
  styleUrls: ['./announcement-popup.component.scss'],
})
export class AnnouncementPopupComponent {

  public announcement: UserAnnouncementStatusDto;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {announcement: UserAnnouncementStatusDto},
              private dialogRef: MatDialogRef<AnnouncementPopupComponent>) {
    this.announcement = data.announcement;
  }

  public closeWithoutChanges(): void {
    this.dialogRef.close(null);
  }

  public changeReadStatus(read: boolean): void {
    if (read) {
      this.dialogRef.close({ announcement: this.announcement, changes: 'READ' } as AnnouncementDialogResult);
    } else {
      this.dialogRef.close({ announcement: this.announcement, changes: 'UNREAD' } as AnnouncementDialogResult);
    }
  }

  public removeAnnouncement(): void {
    this.dialogRef.close({ announcement: this.announcement, changes: 'REMOVE' } as AnnouncementDialogResult);
  }

}
