import { Directive, Host, OnInit } from '@angular/core';
import { ItemScrollSliderComponent } from '@shared/item-scroll-slider/component/item-scroll-slider.component';
import { ItemScrollSliderGaService } from '@shared/item-scroll-slider/service/item-scroll-slider-ga.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { filter, takeUntil } from 'rxjs/operators';
import { ItemScrollSliderTrackItemModel } from '@shared/item-scroll-slider/model/item-scroll-slider-track-item.model';
import { isNotNil } from '@util/helper-functions/is-not-nil';

@Directive({
  selector: '[aukItemScrollSliderGa]auk-item-scroll-slider',
  standalone: true,
})
export class ItemScrollSliderGaDirective extends NgUnsubscribe implements OnInit {

  constructor(
    @Host() private readonly productScrollSliderComponent: ItemScrollSliderComponent,
    private readonly itemScrollSliderGaService: ItemScrollSliderGaService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initItemClickHandler();
  }

  private initItemClickHandler(): void {
    this.productScrollSliderComponent.itemClick
      .pipe(
        filter(isNotNil),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((trackModel: ItemScrollSliderTrackItemModel) => {
        this.itemScrollSliderGaService.trackCarouselItemClickEvent(trackModel.id, trackModel.name);
      });
  }

}
