import { Injectable } from '@angular/core';

import { AppInitializer } from '@util/helper-types/initializer.interface';
import { NativeAppService } from '../service/native-app.service';

@Injectable({
  providedIn: 'root',
})
export class NativeAppInitializer implements AppInitializer {

  constructor(private readonly nativeAppService: NativeAppService) {
  }

  public init(): void {
    this.nativeAppService.init();
  }

}
