import { TranslationSource } from '@common/translations/model/translation-source';
import { ElementIdentifierUtil } from '@common/element-identifier/util/element-identifier.util';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import isObject from 'lodash-es/isObject';
import isNil from 'lodash-es/isNil';

export class TranslationUtil {

  /**
   * @param translationSource
   * @returns tries to extract value from translation source in following order `key` > `defaultValue`,
   *  if both are Nil, returns empty string
   */
  public static extractValueFromTranslationSource(translationSource: TranslationSource): string {
    return translationSource?.key ?? translationSource?.defaultValue ?? '';
  }

  /**
   * Creates element identifier by combining all strings or {@link TranslationSource}
   * @param values - strings or {@link TranslationSource} from which should be the identifier constructed
   */
  public static createElementIdentifier(
    ...values: (string | TranslationSource)[]
  ): string {
    const processedStrings = values
      // filter out Nil values
      .filter(isNotNil)
      // map translation sources to string value
      .map((value) => {
        if (TranslationUtil.isTranslationSource(value)) {
          return TranslationUtil.extractValueFromTranslationSource(value);
        }
        return value;
      });

    return ElementIdentifierUtil.createIdentifier(
      ...processedStrings,
    );
  }

  public static isTranslationSource(value: unknown | TranslationSource): value is TranslationSource {
    if (!isObject(value)) {
      return;
    }

    return !isNil((value as TranslationSource).key) || !isNil((value as TranslationSource).defaultValue);
  }

}
