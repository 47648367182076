<auk-dialog-wrapper-with-content
    [dialogTitle]="data.textContent.title"
    (closeDialogClick)="closeDialog()"
>
  <div content [aukInnerHtml]="bodyTranslationSource"></div>

  <ng-container actions>
    <auk-button colorCombination="SECONDARY"
                class="tw-w-full tw-mb-2"
                (buttonClick)="onFillBasicData()"
    >
      {{ data.textContent.fillBasicDataButton | translateSource }}
    </auk-button>
    <auk-button colorCombination="PRIMARY"
                [chin]="true"
                class="tw-w-full"
                (buttonClick)="closeDialog()"
    >
      {{ data.textContent.closeButton | translateSource }}
    </auk-button>
  </ng-container>
</auk-dialog-wrapper-with-content>
