import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { Translate2Module } from '@common/translations/translate2.module';

import { switchMap } from 'rxjs';
import { ConnectionService } from '@shared/connection/service/connection.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { ConnectionInfoModel } from '@shared/connection/model/connection-info.model';
import { SpinnerComponent } from '@common/ui-kit/component/spinner/component/spinner.component';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { take, takeUntil, tap } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { DateUtils } from '@util/util/date.utils';
import { SvgAutoCacheService } from '@common/ui-kit/component/svg-icon/service/svg-auto-cache.service';

@Component({
  selector: 'auk-connection-info-panel',
  standalone: true,
  templateUrl: './connection-info-panel.component.html',
  styleUrls: ['./connection-info-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    Translate2Module,
    AsyncPipe,
    IconComponent,
    SpinnerComponent,
    NgTemplateOutlet,
    NgClass,
  ],
})
export class ConnectionInfoPanelComponent extends NgUnsubscribe implements OnInit {

  protected connectionCheckSpinner: boolean = false;

  protected connectionStatus: ConnectionInfoModel;

  private spinnerShowTime: number = DateUtils.convertSecondsToMilliseconds(1); // Time to wait until hiding spinner

  /**
   * Added preload to avoid the issue with the SVG icons being loaded while the user is offline.
   * So that causes network-error and the SVG icons were not displayed.
   */
  protected svgImagesToBePreLoaded: string[] = ['aukro-offline', 'aukro-refresh'];

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    public readonly connectionService: ConnectionService,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly svgAutoCacheService: SvgAutoCacheService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.preloadSvgImages();

    this.connectionService.connectivityStatus$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((connectionStatus: ConnectionInfoModel) => {
        this.connectionStatus = connectionStatus;
        this.changeDetectorRef.markForCheck();
      });
  }

  protected onOnlineReloadPageClick(): void {
    this.connectionCheckSpinner = true;
    // check if there is internet connection
    this.connectionService.checkConnectivityRequest$()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((connection: ConnectionInfoModel) => {
        if (connection?.connectionStatusType === 'OFFLINE') {
          // initiate check again if there is no connection
          this.connectionService.initiateConnectivityCheck();
          this.connectionCheckSpinner = false;
        } else {
          // reload page if there is internet connection
          this.window.location.reload();
        }
      });
  }

  protected onOfflineReloadPageClick(): void {
    this.connectionCheckSpinner = true;
    // check if there is internet connection
    this.connectionService.checkConnectivityRequest$()
      .pipe(
        take(1),
        switchMap((connection: ConnectionInfoModel) => {
          if (connection?.connectionStatusType === 'ONLINE') {
            this.window.location.reload();
          } else {
            return this.ngZoneUtilService.timerOut$(this.spinnerShowTime)
              .pipe(
                tap(() => {
                  // hide spinner if there is no internet connection
                  this.connectionCheckSpinner = false;
                }),
              );
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  protected preloadSvgImages(): void {
    if (this.platformCommonService.isServer) {
      return;
    }
    this.svgImagesToBePreLoaded.forEach(svgPath => {
      this.svgAutoCacheService.getSvg(`/assets/icon/${ svgPath }.svg`)
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe();
    });
  }

}
