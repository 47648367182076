<div class="tw-cursor-pointer"
     [title]="'CLOSE' | translate"
     (click)="close()"
>
  <auk-icon iconType="MATERIAL"
            source="close"
            size="MD"
            colorCombination="PRIMARY"
  ></auk-icon>
</div>
