import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { StringUtils } from '@util/util/string.utils';

@Component({
  selector: 'auk-search-compact',
  templateUrl: './search-compact.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchCompactComponent implements AfterViewInit {

  @Output() public handleKeyboardActions: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  @Output() public handleSuggestions: EventEmitter<void> = new EventEmitter<void>();
  @Output() public inputValueChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() public searchClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public closeDropdown: EventEmitter<void> = new EventEmitter<void>();
  @Output() public searchRef: EventEmitter<ElementRef<HTMLInputElement>> = new EventEmitter<ElementRef<HTMLInputElement>>();

  @ViewChild('searchInputElement') public readonly searchInputElm: ElementRef<HTMLInputElement>;

  protected showClearButton: boolean = false;

  public ngAfterViewInit(): void {
    this.searchRef.emit(this.searchInputElm);
    this.searchInputElm?.nativeElement?.focus();
  }

  protected searchSubmit(): void {
    this.searchClick.emit();
  }

  protected onInputChange(): void {
    this.inputValueChanged.emit();
    if (this.searchTextNotEmpty()) {
      this.showClearButton = true;
    } else {
      this.showClearButton = false;
    }
  }

  protected clearText(): void {
    this.searchInputElm.nativeElement.value = '';
    this.showClearButton = false;
    this.inputValueChanged.emit();
  }

  private searchTextNotEmpty(): boolean {
    return !StringUtils.isEmpty(this.searchInputElm?.nativeElement?.value);
  }

  protected close(): void {
    this.closeDropdown.emit();
  }

}
