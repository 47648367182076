
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { BasicRegistrationFormModel } from '@shared/registration/module/basic-registration-form/basic-registration-form.model';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { RegistryApiService } from '@api/generated/api/Registry';
import { ValidationMessagesDirective } from '@shared/legacy/directive/validation-messages/validation-messages.directive';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { RegistryItemDto } from '@api/generated/defs/RegistryItemDto';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { ONLY_NUMBER_PATTERN } from '@shared/validators/patterns';
import { LimitMaxLengthDirectiveModule } from '@shared/legacy/directive/limit-max-length/limit-max-length.directive.module';
import { vatValidator } from '@shared/validators/validators';
import { Translate2Module } from '@common/translations/translate2.module';
import { TrimDirectiveModule } from '@util/directive/trim/trim.directive.module';

import { ValidationMessageComponent } from '@shared/legacy/component/validation-message/validation-message.component';
import { TooltipDirective } from '@shared/legacy/component/tooltip/tooltip.directive';
import { takeUntil } from 'rxjs/operators';
import { TooltipComponent } from '@shared/legacy/component/tooltip/tooltip.component';
import isNil from 'lodash-es/isNil';
import { ToastService } from '@common/toast/service/toast.service';
import { HintComponent } from '@common/ui-kit/component/hint/component/hint.component';
import { AlertBannerModule } from '@shared/legacy/component/alert-banner/alert-banner.module';
import { TextMaskDirective } from '@common/text-mask/directive/text-mask.directive';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@Component({
  selector: 'auk-business-registration',
  templateUrl: './business-registration-form.component.html',
  styleUrls: ['./business-registration-form.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TextMaskDirective,
    Translate2Module,
    ButtonComponent,
    MatSlideToggleModule,
    LimitMaxLengthDirectiveModule,
    TooltipComponent,
    ValidationMessageComponent,
    ValidationMessagesDirective,
    TrimDirectiveModule,
    TooltipDirective,
    HintComponent,
    AlertBannerModule,
    InnerHtmlDirective,
  ],
})
export class BusinessRegistrationFormComponent extends NgUnsubscribe implements OnInit {

  @Input() public basicRegistrationForm: FormGroup<BasicRegistrationFormModel>;

  protected legalForms: RegistryItemDto[];
  protected isCompanyNameHidden: boolean = false;
  constructor(private readonly registryApiService: RegistryApiService, private readonly toastService: ToastService) {
    super();
  }

  protected get isVatPayer(): boolean {
    return this.basicRegistrationForm?.controls['isVatPayer']?.value;
  }

  protected get isBusinessRegistration(): boolean {
    return this.basicRegistrationForm?.controls['isBusinessRegistration']?.value;
  }

  public ngOnInit(): void {
    this.getLegalForms();
  }

  protected toggleBusinessRegistration(): void {
    this.basicRegistrationForm?.controls['isBusinessRegistration'].setValue(!this.isBusinessRegistration);
    this.setValidatorsForBusinessRegistration();
  }

  protected toggleVatPayer(): void {
    this.basicRegistrationForm?.controls['isVatPayer'].setValue(!this.isVatPayer);
    this.setValidatorsForVatPayer();
  }

  protected trackByLegalFormId(index: number, legalForm: RegistryItemDto): number {
    return legalForm.id ?? index;
  }

  protected legalFormChange(): void {
    if (this.basicRegistrationForm?.controls['legalForm']?.value === 'TRADESMAN') {
      this.basicRegistrationForm.removeControl('companyName');
      this.isCompanyNameHidden = true;
    } else {
      this.basicRegistrationForm.addControl('companyName', new FormControl(null, [Validators.required, Validators.minLength(1)]));
      this.isCompanyNameHidden = false;
    }
  }

  private getLegalForms(): void {
    const countryId = this.basicRegistrationForm?.controls['address']?.controls['country']?.value;
    if (!isNil(countryId)) {
      this.registryApiService.businessFormsForCountry({ countryId })
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe({
          next: (forms: RegistryItemDto[]) => {
            this.legalForms = forms;
          },
          error: () => {
            this.toastService.showDanger({ key: 'DEFAULT_API_ERROR' });
          },
        });
    }
  }

  private setValidatorsForBusinessRegistration(): void {
    if (this.isBusinessRegistration === true) {
      this.basicRegistrationForm.addControl('companyName', new FormControl(null, [Validators.required, Validators.minLength(1)]));
      this.basicRegistrationForm.addControl('regNumber', new FormControl(null, [
        Validators.required,
        Validators.pattern(ONLY_NUMBER_PATTERN),
        Validators.minLength(8)]));
      this.basicRegistrationForm.addControl('legalForm', new FormControl(null, [Validators.required]));
      this.basicRegistrationForm.addControl('vatNumber', new FormControl(null, null));
    } else {
      this.basicRegistrationForm.removeControl('companyName');
      this.basicRegistrationForm.removeControl('regNumber');
      this.basicRegistrationForm.removeControl('legalForm');
      this.basicRegistrationForm.removeControl('vatNumber');
    }
  }

  private setValidatorsForVatPayer(): void {
    if (this.isVatPayer === true) {
      this.basicRegistrationForm.removeControl('vatNumber');
      this.basicRegistrationForm.addControl('vatNumber', new FormControl(null, [Validators.required, vatValidator('address.country')]));
    } else {
      this.basicRegistrationForm.removeControl('vatNumber');
      this.basicRegistrationForm.addControl('vatNumber', new FormControl(null, null));
    }
  }

}
