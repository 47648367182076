import { Injectable } from '@angular/core';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';

@Injectable({
  providedIn: 'root',
})
export class TransparencyService {

  public async transparencyIosRequest(): Promise<boolean> {
    const appTrackingStatus = await AppTrackingTransparency.getStatus();
    if (appTrackingStatus.status === 'authorized') {
      return Promise.resolve(true);
    }
    if (appTrackingStatus.status !== 'notDetermined') {
      return Promise.resolve(false);
    }

    const responseTracking = await AppTrackingTransparency.requestPermission();

    if (responseTracking.status === 'authorized') {
      return Promise.resolve(true);
    } else {
      return Promise.resolve(false);
    }
  }

}
