import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import isNil from 'lodash-es/isNil';

/**
 * @deprecated
 *
 * This directive should be never used (because according to HTML spec you can't have <a> without href or empty link),
 * so use other element instead of <a>
 */
@Directive({
  selector: '[aukPreventDefaultLink]',
  standalone: true,
})
export class PreventDefaultLinkDirective implements OnInit {

  @Input() public href: string;
  @Input() public preventDefaultNonEmpty: boolean = false;

  constructor(
    private readonly renderer2: Renderer2,
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {
  }

  public ngOnInit(): void {
    this.renderer2.setAttribute(this.elementRef.nativeElement, 'href', this.isLinkEmpty() ? '#' : this.href);
  }

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    this.preventDefault(event);
  }

  private preventDefault(event: MouseEvent): void {
    if (this.preventDefaultNonEmpty || this.isLinkEmpty()) {
      event.preventDefault();
    }
  }

  private isLinkEmpty(): boolean {
    return (isNil(this.href) || this.href.length === 0 || this.href === '#');
  }

}
