import { MatDialogService } from '@common/dialog/service/mat-dialog.service';
import { AukDialogType } from '@shared/dialog/model/auk-dialog.type';
import { Injectable } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { AukDialogService } from '@shared/dialog/service/auk-dialog-service';

@Injectable({
  providedIn: 'root',
})
export class AukMatDialogService extends MatDialogService<AukDialogType> {

  constructor(matDialog: MatLegacyDialog, modalService: AukDialogService) {
    super(
      matDialog,
      modalService);
  }

}
