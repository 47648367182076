import { Injectable } from '@angular/core';
import { ExchangedPriceService } from '@shared/currency/services/exchanged-price.service';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ExchangeRatesDto } from '@api/generated/defs/ExchangeRatesDto';
import { ExchangeRatesService } from '@shared/currency/services/exchange-rates.service';
import { GoogleAnalyticsConstants } from '../const/google-analytics.constats';
import { MoneyDto } from '@api/generated/defs/MoneyDto';
import { CartItemsBySellerDto } from '@api/generated/defs/CartItemsBySellerDto';
import { CartItemDetailDto } from '@api/generated/defs/CartItemDetailDto';
import isNil from 'lodash-es/isNil';
import round from 'lodash-es/round';
import { ItemSearchItem } from '@api/generated/defs/ItemSearchItem';
import { CartCheckoutOptionsDto } from '@api/generated/defs/CartCheckoutOptionsDto';
import { CartCheckoutShippingOptionDto } from '@api/generated/defs/CartCheckoutShippingOptionDto';
import { ItemShippingOptionsCombinationDto } from '@api/generated/defs/ItemShippingOptionsCombinationDto';
import { BidResponseDto } from '@api/generated/defs/BidResponseDto';
import { GaCartItemMergedInfoModel } from '@shared/google-analytics/model/ga-cart-item-merged-info.model';
import { GaCartParamsModel } from '@shared/google-analytics/model/ga-cart-params.model';
import { BidGaParamsModel } from '@shared/google-analytics/model/bid-ga-params.model';

/**
 * Performs currency exchange for data which is sent to Google Tag Manager (GTM)
 */
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsPriceExchangeService {

  constructor(private readonly exchangedPriceService: ExchangedPriceService,
                private readonly exchangeRatesService: ExchangeRatesService) {
  }

  /**
   * Recalculate all prices in GA DTO to DEFAULT currency (CZK)
   * @param gaDto
   */
  public recalculateCartPrices(gaDto: GaCartItemMergedInfoModel): Observable<GaCartItemMergedInfoModel> {
    if (gaDto.cartItem?.totalPrice?.currency === GoogleAnalyticsConstants.GTM_CURRENCY &&
            (gaDto.product?.price?.currency === GoogleAnalyticsConstants.GTM_CURRENCY ||
                gaDto.product?.buyNowPrice?.currency === GoogleAnalyticsConstants.GTM_CURRENCY)) {
      return of(gaDto);
    }

    // TODO needs to be refactored when adding another currency (e.g. USD)
    return this.exchangeRatesService.exchangeRates('EUR')
      .pipe(
        take(1),
        map((rates: ExchangeRatesDto) => {

          // Exchange to DEFAULT_CURRENCY for all prices used in GA
          gaDto.product.price = this.exchangeForGa(gaDto.product?.price, rates);
          gaDto.product.buyNowPrice = this.exchangeForGa(gaDto.product?.buyNowPrice, rates);
          gaDto.cartItem.totalPrice = this.exchangeForGa(gaDto.cartItem?.totalPrice, rates);

          return gaDto;
        }),
      );
  }

  public recalculateCartStepPrices(gaDto: GaCartParamsModel): Observable<GaCartParamsModel> {
    // TODO needs to be refactored when adding another currency (e.g. USD)
    return this.exchangeRatesService.exchangeRates('EUR')
      .pipe(
        take(1),
        map((rates: ExchangeRatesDto) => {

          gaDto.cartItemsBySeller?.forEach((cartItemsBySeller: CartItemsBySellerDto) => {
            cartItemsBySeller.cartItemsDetailDto.forEach((cartItem: CartItemDetailDto) => {
              cartItem.itemPrice = this.exchangeForGa(cartItem.itemPrice, rates);
              cartItem.totalPrice = this.exchangeForGa(cartItem.totalPrice, rates);
            });

            cartItemsBySeller.cheapestDeliveryPrice = this.exchangeForGa(cartItemsBySeller.cheapestDeliveryPrice, rates);
            cartItemsBySeller.freeDeliveryLimitPrice = this.exchangeForGa(cartItemsBySeller.freeDeliveryLimitPrice, rates);
          });

          gaDto.sellerOptions?.forEach((options: CartCheckoutOptionsDto) => {
            options.groupedByShippingOptions.forEach((itemShippingOptions: ItemShippingOptionsCombinationDto) => {
              itemShippingOptions.shippingOptions.forEach((cso: CartCheckoutShippingOptionDto) => {
                cso.price = this.exchangeForGa(cso.price, rates);
              });
            });
          });

          return gaDto;
        }),
      );
  }

  public recalculateItemSearchItems(searchItems: ItemSearchItem[]): Observable<ItemSearchItem[]> {
    // TODO needs to be refactored when adding another currency (e.g. USD)
    return this.exchangeRatesService.exchangeRates('EUR')
      .pipe(
        take(1),
        map((rates: ExchangeRatesDto) => {

          [...searchItems].forEach((item: ItemSearchItem) => {
            item.price = this.exchangeForGa(item.price, rates);
          });

          return searchItems;
        }),
      );
  }

  /**
   * Create BidGaParams for sending to GA in CZK currency (performs money exchange if needed)
   * @param itemId
   * @param bidResponseDto
   * @param revenueDifference
   */
  public recalculateBidEvent(itemId: number, bidResponseDto: BidResponseDto, revenueDifference: MoneyDto): Observable<BidGaParamsModel> {
    // TODO needs to be refactored when adding another currency (e.g. USD)
    return this.exchangeRatesService.exchangeRates('EUR')
      .pipe(
        take(1),
        map((rates: ExchangeRatesDto) => ({
          itemId,
          userId: bidResponseDto.userId,
          bidId: bidResponseDto.id,
          currentPrice: this.exchangeForGa(bidResponseDto.currentPrice, rates)?.amount,
          nextMinimumBidPrice: this.exchangeForGa(bidResponseDto.nextMinimumBid, rates)?.amount,
          // Sent zero to GA if price difference is missing
          priceDifference: this.exchangeForGa(bidResponseDto.priceDifference, rates)?.amount || 0,
          priceDifferenceRevenue: revenueDifference?.amount, // always in czk
        })),
      );
  }

  /**
   * Exchanges to GTM currency and rounds amount
   * @param value
   */
  public exchangeForGa$(value: MoneyDto): Observable<MoneyDto> {
    if (isNil(value)) {
      return null;
    }

    if (value.currency === GoogleAnalyticsConstants.GTM_CURRENCY) {
      // Round only
      return of({ ...value, amount: round(value.amount) });
    }

    return this.exchangedPriceService.priceMoney(value, GoogleAnalyticsConstants.GTM_CURRENCY)
      .pipe(
        map((exchangedAmount) => ({
          amount: round(exchangedAmount),
          currency: GoogleAnalyticsConstants.GTM_CURRENCY,
        })),
      );
  }

  private exchangeForGa(price: MoneyDto, exchangeRates: ExchangeRatesDto): MoneyDto {

    const moneyDto = this.exchangedPriceService.exchange(price, exchangeRates, GoogleAnalyticsConstants.GTM_CURRENCY);

    if (isNil(moneyDto) || moneyDto.amount === 0) {
      return moneyDto;
    }

    moneyDto.amount = round(moneyDto.amount);

    return moneyDto;
  }

}
