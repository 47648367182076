<!--TODO(PDEV-18285) remove the various isDesktop conditions and use CSS BP -->
<!--TODO(PDEV-18285) replace old ui kit classes with tw -->
<header class="tw-flex tw-justify-between tw-align-center" [ngClass]="{ 'tw-mb-8': isDesktop }">
  <auk-icon
    source="verified_user"
    class="verified-user-icon"
    iconType="SVG"
    [size]="isDesktop ? '5XL' : '3XL'"
    [ngClass]="{'mobile': !isDesktop}"
  ></auk-icon>
</header>

<div class="wrapper">
  <h1 class="tw-text-center" [aukInnerHtml]="payload.title"></h1>

  <div class="tw-text-center tw-leading-sm tw-mb-8" [ngClass]="{'tw-ml-8 tw-mr-8': isDesktop}">
    <span [aukInnerHtml]="payload.subtitle"></span>
    <br>
      <br>
        <ng-container>
          @if (isUserAccountType) {
            <span [aukInnerHtml]="payload.bodyUserAccountType"></span>
            <b class="tw-text-matter-accent-primary">{{userDetail?.firstName}} {{userDetail?.lastName}}</b>
          }
          @if (isCompanyAccountType) {
            <span [aukInnerHtml]="payload.bodyCompanyAccountType"></span>
            <b class="tw-text-matter-accent-primary">{{userDetail?.address?.company || $any(userDetail)?.companyName }}</b>
          }
        </ng-container>
        @if (payload.bankAccountNumberToVerify && payload.bankAccountNumberToVerifyInfo) {
          <div>
            <br>
              <span [aukInnerHtml]="payload.bankAccountNumberToVerifyInfo"></span>
              <b class="tw-text-matter-accent-primary">{{ payload.bankAccountNumberToVerify }}</b>
            </div>
          }
        </div>

        @if (loading) {
          <auk-spinner-legacy></auk-spinner-legacy>
        }

        @if (!loading) {
          <div id="payment-selection" [ngClass]="{'mobile': !isDesktop}">
            <div class="tw-grid tw-mb-4 tw-gap-y-2.5"
      [ngClass]="{
        'tw-grid-cols-8 text-big': isDesktop,
        'tw-grid-cols-5 tw-gap-y-4': !isDesktop
      }"
              >
              <div class="tw-col-span-4">
                @if (isDesktop) {
                  <b>{{ payload.transferPaymentLabelDesktop | translateSource }}</b>
                }
                @if (!isDesktop) {
                  <b>{{ payload.transferPaymentLabelMobile | translateSource }}</b>
                }
              </div>
              <div class="!tw-text-matter-accent-primary tw-text-right">
                <b>{{ MINIMAL_BANK_TRANSFER_AMOUNT | currency }}</b>
              </div>
              @if (isDesktop) {
                <div class="tw-col-start-7 tw-col-span-2 tw-row-span-4">
                  <img alt="QR code" [src]="qrPaymentData">
                </div>
              }
              <div class="tw-col-span-2">
                {{ { key: 'BANK_ACCOUNT' } | translateSource }}
              </div>
              <div class="tw-col-span-3 !tw-text-matter-accent-primary tw-text-right">
                <b>{{bankTransferData?.bankAccount}}</b>
              </div>
              <div class="tw-row-start-3 tw-col-span-3">
                {{ { key: 'VARIABLE_SYMBOL' } | translateSource }}
              </div>
              <div class="tw-row-start-3 tw-col-span-2 !tw-text-matter-accent-primary tw-text-right">
                <b>{{bankTransferData?.variableSymbol}}</b>
              </div>
              <div class="tw-row-start-4 tw-col-span-3">
                {{ { key: 'SPECIFIC_SYMBOL' } | translateSource }}
              </div>
              <div class="tw-row-start-4 tw-col-span-2 tw-text-right">
                <b>{{ payload.specificSymbol | translateSource }}</b>
              </div>
              @if (isDesktop) {
                <div class="tw-col-start-7 tw-col-span-2 tw-text-center tw-font-normal text-gray-md">
                  <ng-container>
                    {{ { key: 'QR_PAYMENT' } | translateSource }}
                  </ng-container>
                </div>
              }
              @if (!isDesktop) {
                <div class="tw-mt-4 tw-text-center tw-col-span-5 tw-flex tw-justify-center tw-flex-col">
                  <div>{{ { key: 'QR_PAYMENT' } | translateSource }}</div>
                  <div>
                    <img alt="QR code" [src]="qrPaymentData">
                  </div>
                </div>
              }
            </div>
          </div>
        }

        @if (showMaPaymentVerificationPrelinkInfo) {
          <div class="tw-text-center tw-font-normal tw-text-xs">
            {{ payload.maPaymentVerificationPrelinkInfo | translateSource }}
            <a id="my-account-verification" routerLink="/moje-aukro/muj-ucet/overeni">{{ { key: 'MY_AUKRO' } | translateSource }}</a>.
          </div>
        }
      </div>
