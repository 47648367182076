import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BasicRegistrationFormComponent } from './basic-registration-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonDirectivesModule } from '@shared/legacy/directive/common-directives.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CommonPipesModule } from '@shared/legacy/pipe/common-pipes.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { PhoneFieldModule } from '@shared/phone-field/phone-field.module';
import { SpinnerModule } from '@shared/legacy/component/spinner/spinner.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { BusinessRegistrationFormComponent } from '@shared/registration/component/business-registration-form/business-registration-form.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TextMaskDirective } from '@common/text-mask/directive/text-mask.directive';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CommonDirectivesModule,
    MatFormFieldModule,
    MatInputModule,
    CommonPipesModule,
    MatSelectModule,
    PhoneFieldModule,
    SpinnerModule,
    Translate2Module,
    ButtonComponent,
    BusinessRegistrationFormComponent,
    MatDatepickerModule,
    TextMaskDirective,
    InnerHtmlDirective,
    TestIdentificationDirective,
  ],
  declarations: [
    BasicRegistrationFormComponent,
  ],
  exports: [
    BasicRegistrationFormComponent,
  ],
})
export class BasicRegistrationFormModule {
}
