import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SelectComponent } from './select.component';
import { Translate2Module } from '@common/translations/translate2.module';

@NgModule({
  imports: [
    CommonModule,
    Translate2Module,
  ],
  declarations: [SelectComponent],
  exports: [SelectComponent],
})
export class SelectModule {

}
