<!-- LOADER -->
@if ((isLoaderShown$ | async) === true) {
  <div class="loader">
    <auk-spinner
      [v2]="true"
      size="4XL"
    />
  </div>
  <!-- ./LOADER -->
}

<div [class.tw-opacity-20]="(isLoaderShown$ | async) === true"
     class="outlet-container"
>
  <router-outlet/>
</div>
