import { Injectable } from '@angular/core';
import { UserTaskVerifiedBankAccountStepResolutionHandlerService } from './user-task-verified-bank-account-step-resolution-handler.service';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';

@Injectable({
  providedIn: 'root',
})
export class UserTaskVerifiedBankAccountStepNoAccountVerifiedResolutionHandlerService extends
  UserTaskVerifiedBankAccountStepResolutionHandlerService<'NO_ACCOUNT_VERIFIED'> {

  public handle(): UserTaskStepUnionModel<'VERIFIED_BANK_ACCOUNT'> {
    return null;
  }

}
