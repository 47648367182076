import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppBackToTopBtnComponent } from './component/app-back-to-top-btn.component';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';
import { AccessibilityDirective } from '@common/accessibility/directive/accessibility.directive';

@NgModule({
  imports: [
    CommonModule,
    AccessibilityDirective,
    TestIdentificationDirective,
  ],
  declarations: [
    AppBackToTopBtnComponent,
  ],
  exports: [
    AppBackToTopBtnComponent,
  ],
})
export class AppBackToTopBtnModule { }
