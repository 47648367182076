export class KeyboardUtil {

  public static isNumericCombinationPressed(e: KeyboardEvent): boolean {
    return (
      KeyboardUtil.isEnterPressed(e) ||
      KeyboardUtil.isEscapePressed(e) ||
      KeyboardUtil.isTabPressed(e) ||
      KeyboardUtil.isRemovePressed(e) ||
      KeyboardUtil.isPeriodDown(e) ||
      KeyboardUtil.isCommaDown(e) ||
      KeyboardUtil.isSelectAllPressed(e) ||
      KeyboardUtil.isCopyPressed(e) ||
      KeyboardUtil.isPastePressed(e) ||
      KeyboardUtil.isCutPressed(e) ||
      KeyboardUtil.isArrowPressed(e) ||
      KeyboardUtil.isHomeEndPressed(e) ||
      KeyboardUtil.isUndoPressed(e) ||
      KeyboardUtil.isRedoPressed(e)
    );
  }

  /**
   * @function isNumberPressed
   * @description Used for checking pressing numbers for keyPress event
   * @param e
   * @returns
   */
  public static isNumberPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return (event >= 48 && event <= 57) || (event >= 96 && event <= 105);
  }

  public static isEnterPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 13;
  }

  public static isCapsLockPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 20;
  }

  public static isEscapePressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 27;
  }

  public static isTabPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 9;
  }

  public static isRemovePressed(e: KeyboardEvent): boolean {
    // Delete and backspace
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 8 || event === 46;
  }

  public static isHomeEndPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 35 || event === 36;
  }

  public static isArrowPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event >= 37 && event <= 40;
  }

  public static isLeftArrowPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 37;
  }

  public static isUpArrowPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 38;
  }

  public static isRightArrowPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 39;
  }

  public static isDownArrowPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 40;
  }

  /**
   * @function isPeriodDown
   * @description Used for checking pressing symbol "." for keyDown event
   * @param e
   * @returns
   */
  public static isPeriodDown(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 110 || event === 190;
  }

  public static isCommaDown(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 188;
  }

  public static isControlPressed(e: KeyboardEvent): boolean {
    return e.ctrlKey === true || e.metaKey === true;
  }

  public static isSelectAllPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 65 && KeyboardUtil.isControlPressed(e);
  }

  public static isCopyPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 67 && KeyboardUtil.isControlPressed(e);
  }

  public static isPastePressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 86 && KeyboardUtil.isControlPressed(e);
  }

  public static isCutPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 88 && KeyboardUtil.isControlPressed(e);
  }

  public static isUndoPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 90 && KeyboardUtil.isControlPressed(e);
  }

  public static isRedoPressed(e: KeyboardEvent): boolean {
    const event = e.keyCode ? e.keyCode : e.charCode;
    return event === 89 && KeyboardUtil.isControlPressed(e);
  }

}
