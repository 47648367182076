import { AdElementIdType } from '@shared/google-analytics/model/ad-element-id.type';
import { AdNameType } from '@shared/google-analytics/model/ad-name.type';
import isNil from 'lodash-es/isNil';
import { AdContainerReadyGaParamsModel } from '@shared/google-analytics/model/ad-container-ready-ga-params.model';
import { filter, take, takeUntil } from 'rxjs/operators';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { BrowserService } from '@shared/platform/browser.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Injectable } from '@angular/core';
import { Nil } from '@util/helper-types/nil';
import { captureException } from '@sentry/browser';

@Injectable({
  providedIn: 'root',
})
export class ThirdPartyBannerService extends NgUnsubscribe {

  constructor(
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
    private readonly browserService: BrowserService,
  ) {
    super();
  }

  public adContainerReady(adElementId: AdElementIdType | Nil): void {
    const adElementIdTypeToAdName: Record<AdElementIdType, AdNameType> = {
      'ad-wrapper-skyscraper': 'AD-SKYSCRAPER',
      'ad-wrapper-listing-list-first': 'AD-LIST-FIRST',
      'ad-wrapper-listing-list-last': 'AD-LIST-LAST',
      'ad-wrapper-listing-grid-first': 'AD-GRID-FIRST',
      'ad-wrapper-listing-grid-last': 'AD-GRID-LAST',
      'ad-wrapper-product-active': 'AD-PRODUCT-ACTIVE',
      'ad-wrapper-product-inactive': 'AD-PRODUCT-INACTIVE',
    };

    const adName: AdNameType | Nil = adElementIdTypeToAdName[adElementId];

    if (isNil(adName)) {
      captureException('Unknown ad element ID: ' + adElementId, {
        extra: {
          adElementId,
        },
        fingerprint: ['UNKNOWN_AD_ELEMENT_ID'],
      });
      return;
    }

    const params: AdContainerReadyGaParamsModel = {
      name: adName,
      containerId: adElementId,
    };

    // Push `adContainerReady` event to data layer if user has scrolled at least once
    // Or wait until user has scrolled
    this.browserService.userAlreadyScrolled$()
      .pipe(
        filter((hasScrolled: boolean) => hasScrolled),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => void this.googleAnalyticsTrackingService.trackAdContainerReadyEvent(params));
  }

}
