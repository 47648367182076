import { NgModule } from '@angular/core';
import { UserTaskModule } from '@shared/user-task/user-task.module';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { USER_ACTION_PREREQUISITE_FULFILLMENT_SERVICE_TOKEN } from './token/user-action-prerequisite-fulfillment-service.token';
import { UserActionPrerequisiteLoginFulfillmentService } from './service/fulfillment/user-action-prerequisite-login-fulfillment.service';
import { UserActionPrerequisiteVerifiedCompanyFulfillmentService } from './service/fulfillment/user-action-prerequisite-verified-company-fulfillment.service';
import { UserActionPrerequisiteVerifiedEmailFulfillmentService } from './service/fulfillment/user-action-prerequisite-verified-email-fulfillment.service';
import { UserActionPrerequisiteVerifiedPhoneFulfillmentService } from './service/fulfillment/user-action-prerequisite-verified-phone-fulfillment.service';
import { UserActionPrerequisitePersonalDataAndVerifyPhoneFulfillmentService } from './service/fulfillment/user-action-prerequisite-personal-data-and-verify-phone-fulfillment.service';
import { UserActionPrerequisitePersonalDataAndOptionalVerifyPhoneFulfillmentService } from './service/fulfillment/user-action-prerequisite-personal-data-and-optional-verify-phone-fulfillment.service';
import { UserActionPrerequisitePersonalDataAndVerifyEmailAndOptionalVerifyPhoneFulfillmentService } from './service/fulfillment/user-action-prerequisite-personal-data-and-verify-email-and-optional-verify-phone-fulfillment.service';
import { UserActionPrerequisitePersonalDataAndVerifyPhoneAndVerifyEmailFulfillmentService } from './service/fulfillment/user-action-prerequisite-personal-data-and-verify-phone-and-verify-email-fulfillment.service';
import { UserActionPrerequisiteVerifiedBankAccountFulfillmentService } from './service/fulfillment/user-action-prerequisite-verified-bank-account-fulfillment.service';
import { ExecuteTaskViewResolverService } from '@shared/user-action-view/view-resolver/execute-task-view-resolver.service';
import { NoopViewResolver } from '@shared/user-action-view/view-resolver/noop-view.resolver';

@NgModule({
  imports: [
    UserTaskModule,
    ButtonComponent,
  ],
  providers: [
    ExecuteTaskViewResolverService,
    NoopViewResolver,
    {
      provide: USER_ACTION_PREREQUISITE_FULFILLMENT_SERVICE_TOKEN,
      useValue: [
        UserActionPrerequisiteVerifiedCompanyFulfillmentService,
        UserActionPrerequisiteLoginFulfillmentService,
        UserActionPrerequisiteVerifiedEmailFulfillmentService,
        UserActionPrerequisiteVerifiedPhoneFulfillmentService,
        UserActionPrerequisitePersonalDataAndVerifyPhoneFulfillmentService,
        UserActionPrerequisitePersonalDataAndVerifyPhoneAndVerifyEmailFulfillmentService,
        UserActionPrerequisitePersonalDataAndOptionalVerifyPhoneFulfillmentService,
        UserActionPrerequisitePersonalDataAndVerifyEmailAndOptionalVerifyPhoneFulfillmentService,
        UserActionPrerequisiteVerifiedBankAccountFulfillmentService,
      ],
    },
  ],
})
export class UserActionModule {
}
