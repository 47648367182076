import { Param } from './param';
import { HttpParams } from '@angular/common/http';
import { isNotNil } from '@util/helper-functions/is-not-nil';

export class Configuration {

  public encodeParam(param: Param): string {
    const value = param?.dataFormat === 'date-time' && param?.value instanceof Date
      ? param?.value?.toISOString()
      : param?.value;

    return encodeURIComponent(String(value));
  }

  public toHttpParams(params: object): HttpParams {
    let httpParams: HttpParams = new HttpParams();

    Object.keys(params)
      .forEach((key: string) => {
        if (isNotNil(params[key])) {
          httpParams = httpParams.append(key, params[key] as string | number | boolean);
        }
      });

    return httpParams;
  }

  public toFormData(params: object): FormData {
    const data: FormData = new FormData();
    Object.keys(params).forEach((key) => data.append(key, params[key] as string | Blob));
    return data;
  }

  public getResponseType(isResponseFile: boolean, returnType: string): 'text' | 'json' | 'blob' {
    if (isResponseFile) {
      return 'blob';
    }

    if (returnType === 'string') {
      return 'text';
    }

    return 'json';
  }

}
