import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';
import { Translate2Module } from '@common/translations/translate2.module';
import { TileComponent } from '@common/ui-kit/component/tile/component/tile/tile.component';
import { TileModel } from '@common/ui-kit/component/tile/model/tile.model';
import { ToggleTileItemModel } from '@common/ui-kit/component/toggle-tile/model/toggle-tile-item.model';

@Component({
  selector: 'auk-toggle-tile',
  templateUrl: './toggle-tile.component.html',
  standalone: true,
  imports: [
    Translate2Module,
    TileComponent,
    TestIdentificationDirective,
  ],
})
export class ToggleTileComponent {

  @Input() public data: TileModel<ToggleTileItemModel>;

  @Output() public tileChange: EventEmitter<string> = new EventEmitter<string>();

  public onTileClick(selected: ToggleTileItemModel): void {
    this.tileChange.emit(selected.id);
  }

}
