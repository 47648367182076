import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LinkService {

  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2,
              @Inject(DOCUMENT) private document: Document) {
    this.renderer = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {},
    });
  }

  /**
   * Inject the State into the bottom of the <head>
   * @param tag
   * @param forceCreation
   */
  public addTag(tag: LinkDefinition, forceCreation?: boolean): void {
    if (forceCreation) {
      this.removeTag('rel=' + tag.rel);
    }
    const link = this.renderer.createElement('link');
    const head = this.document.head;

    if (head === null) {
      throw new Error('<head> not found within DOCUMENT.');
    }

    Object.keys(tag).forEach((prop: string) => this.renderer.setAttribute(link, prop, tag[prop]));

    this.renderer.appendChild(head, link);
  }

  public removeTag(attrSelector: string): void {
    if (attrSelector) {
      try {
        const head = this.document.head;
        if (head === null) {
          throw new Error('<head> not found within DOCUMENT.');
        }
        const linkTags: any = this.document.querySelectorAll('link[' + attrSelector + ']');
        linkTags.forEach((link: HTMLElement) => this.renderer.removeChild(head, link));
      } catch (e) {
        console.log('Error while removing tag ' + String(e.message));
      }
    }
  }

}

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};
