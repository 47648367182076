import { isDevMode, mergeApplicationConfig } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { APP_CONFIG } from './app/app.config';
import { APP_BROWSER_CONFIG } from './app/browser/const/app.browser-config';

console.info(`running in ${ isDevMode() ? 'DEVELOPMENT' : 'PRODUCTION' } mode`);

const bootstrap = (): void => {
  bootstrapApplication(AppComponent, mergeApplicationConfig(APP_CONFIG, APP_BROWSER_CONFIG))
    .catch(err => console.error(err));
};

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
