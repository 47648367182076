import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { IconType } from './model/icon-type';
import { IconSize } from './model/icon-size';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import isNil from 'lodash-es/isNil';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { SvgIconComponent } from '../../svg-icon/component/svg-icon.component';
import { IconRatioType } from '@common/ui-kit/component/icon/component/model/icon-ratio.type';

@Component({
  selector: 'auk-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    SvgIconComponent,
  ],
})
export class IconComponent extends ColorCombinationInputComponent implements OnChanges {

  @Input() public source: string;
  @Input() public iconType: IconType = 'MATERIAL';
  @Input() public size: IconSize = 'MD';
  /**
   * SQUARE - Fixed icon height and width according to given icon size.
   * FIXED_HEIGHT - Only height is fixed according to given icon size (Width is calculated based on the icon real width)
   */
  @Input() public ratio: IconRatioType = 'SQUARE';

  public iconSizeClass: string;

  public override ngOnChanges(changes: AukSimpleChanges<IconComponent>): void {
    super.ngOnChanges(changes);

    if (changes.size || changes.ratio || isNil(this.iconSizeClass) ) {
      this.iconSizeClass = IconComponent.getIconSizeClass(
        this.size ?? 'MD',
        this.ratio ?? 'SQUARE',
      );
    }
  }

  private static getIconSizeClass(
    size: IconSize,
    ratio: IconRatioType,
  ): string {
    const ratioShortcut = ratio == 'SQUARE' ? '' : 'h-';

    return `size-${ ratioShortcut }${ size.toLowerCase() }`;
  }

}
