<h2>{{ 'INSERT_TO_ADMIN_CLIPBOARD_DIALOG_HEADER' | translate }}</h2>

<div class="tw-mt-8 tw-mb-8">
  <auk-aukro-select
      placeholder="{{ 'INSERT_TO_ADMIN_CLIPBOARD_CHOOSE_PLACEHOLDER' | translate }}"
      [options]="clipboards"
      [arrayOfObjects]="true"
      [selected]="selectedClipboard"
      (optionSelected)="onClipboardSelected($any($event))"
  ></auk-aukro-select>
</div>

<div class="tw-text-right">
  <auk-button
      colorCombination="PRIMARY"
      [chin]="true"
      size="SM"
      class="tw-mr-2"
      [isDisabled]="isSubmitDisabled"
      (buttonClick)="onSubmit(false)"
  >
    {{ 'INSERT_TO_ADMIN_CLIPBOARD_SAVE_BTN' | translate }}
  </auk-button>

  <auk-button
      colorCombination="PRIMARY"
      [chin]="true"
      size="SM"
      class="tw-mr-2"
      [isDisabled]="isSubmitDisabled"
      (buttonClick)="onSubmit(true)"
  >
    {{ 'INSERT_TO_ADMIN_CLIPBOARD_SAVE_AND_GO_BTN' | translate }}
  </auk-button>

  <auk-button
      size="SM"
      (buttonClick)="onClose()"
  >
    {{ 'INSERT_TO_ADMIN_CLIPBOARD_CLOSE_BTN' | translate }}
  </auk-button>
</div>
