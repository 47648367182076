import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './component/spinner.component';

/**
 * @deprecated
 * use {@link SpinnerComponent} from 'ui-kit/spinner' folder
 */
@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SpinnerComponent,
  ],
  exports: [
    SpinnerComponent,
  ],
})
export class SpinnerModule {
}
