import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from '@common/cookie/service/cookie.service';
import { HttpRequestCloneConfig } from './model/http-request-clone-config';
import isNil from 'lodash-es/isNil';
import omit from 'lodash-es/omit';
import { tap } from 'rxjs/operators';
import { CookieDict, CookieOptions } from 'ngx-cookie';
import { parse, splitCookiesString } from 'set-cookie-parser';
import { WITH_CREDENTIALS } from '@shared/rest/token/with-credentials-http-context.token';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { CartConstants } from '@shared/cart/constant/cart.constants';
import { Nil } from '@util/helper-types/nil';
import { CookieUtil } from '@common/cookie/util/cookie.util';

const SANITIZE_EMPTY_VALUE_COOKIE_NAMES = [CartConstants.COOKIE_CART_NAME];

@Injectable()
export class CookieInterceptor implements HttpInterceptor {

  private readonly cookiesSerializeSeparator: string = ';';
  private readonly keyValueSeparator: string = '=';
  private readonly setCookieKey: string = 'set-cookie';
  private readonly cookieKey: string = 'Cookie';

  constructor(
    private readonly cookieService: CookieService,
    private readonly platformCommonService: PlatformCommonService,
  ) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.platformCommonService.isServer) {
      return this.handleOnServer(request, next);
    }

    return next.handle(request);
  }

  private handleOnServer(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestCloneConfig: HttpRequestCloneConfig = {
      withCredentials: request.context.has(WITH_CREDENTIALS) ? request.context.get(WITH_CREDENTIALS) : true,
    };

    // set cookies to http request on ssr
    // create new cookies string
    const allCookiesSerialized = this.getAllCookiesSerialized(request.headers);

    // only set cookie string, if it has value
    if (!isNil(allCookiesSerialized)) {
      requestCloneConfig.headers = request.headers.set(this.cookieKey, allCookiesSerialized);
    }

    return next.handle(request.clone(requestCloneConfig))
      .pipe(
        tap((httpEvent: HttpEvent<any>) => {
          if (httpEvent instanceof HttpResponse) {
            splitCookiesString(httpEvent.headers?.get(this.setCookieKey))
              .forEach((cookieToSet) => this.setCookie(cookieToSet));
          }
        }));
  }

  private getAllCookiesSerialized(existingHeaders?: HttpHeaders): string | undefined {
    const existingCookies = existingHeaders?.get(this.cookieKey);
    const preparedExistingCookies = existingCookies
      ? existingCookies + this.cookiesSerializeSeparator
      : '';

    const allCookiesDict = this.cookieService.getAll();
    if (isNil(allCookiesDict)) {
      return existingCookies;
    }

    const newCookiesSerialized = Object.keys(allCookiesDict)?.reduce((acc, cookieName) => {
      const value = this.shouldBeSanitized(cookieName, allCookiesDict[cookieName])
        ? ''
        : allCookiesDict[cookieName];

      acc += `${ cookieName }${ this.keyValueSeparator }${ value }${ this.cookiesSerializeSeparator }`;
      return acc;
    }, preparedExistingCookies);

    return newCookiesSerialized
      ? newCookiesSerialized.substring(0, newCookiesSerialized.lastIndexOf(this.cookiesSerializeSeparator))
      : undefined;
  }

  private setCookie(cookieToSet: string): void {
    const parsedCookieToSet: CookieDict = parse(cookieToSet)?.[0] as unknown as CookieDict;
    if (!isNil(parsedCookieToSet)) {
      const cookieOptions: CookieOptions = omit(parsedCookieToSet, ['name', 'value']);
      const value = this.shouldBeSanitized(parsedCookieToSet.name, parsedCookieToSet.value)
        ? ''
        : parsedCookieToSet.value;

      this.cookieService.put(parsedCookieToSet.name, value, cookieOptions);
    }
  }

  private shouldBeSanitized(cookieName: string, cookieVal: string | Nil): boolean {
    return SANITIZE_EMPTY_VALUE_COOKIE_NAMES.includes(cookieName) && CookieUtil.shouldBeNumberValSanitized(cookieVal);
  }

}
