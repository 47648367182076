import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlatformService } from '@shared/platform/service/platform.service';

@Injectable()
export class BotDetectionInterceptor implements HttpInterceptor {

  constructor(private platformService: PlatformService) {
  }

  public intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<string>> {
    if (!this.platformService.isBot) {
      return next.handle(req);
    }

    return next.handle(req.clone({
      setHeaders: { 'x-is-bot': 'true' },
    }));
  }

}
