import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';
import { HttpContext } from '@angular/common/http';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CartOptionsChoiceParams {
  /** Cart item ids */
  cartItemIds: number[];
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CheckoutCartParams {
  /** Cart checkout options data */
  cartCheckoutFormDto: model.CartCheckoutFormDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface InsertCartItemUsingPOSTParams {
  /** Cart item data */
  cartItemFormDto: model.CartItemFormDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface DeleteCartItemUsingDELETEParams {
  /**
   * Cart item ID
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface PaymentDetailParams {
  /**
   * paymentId
   * format: int64
   */
  paymentId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SaveEmailParams {
  /** emailDto */
  emailDto: model.EmailDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root',
})
export class CartApiService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public showCartUsingGET(): Observable<model.CartOverviewDto> {
    let queryParams;
    return this.getGenerated(`/cart`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public cartOptionsChoice(params: CartOptionsChoiceParams, headers: { [key: string]: string } = {}): Observable<model.CartCheckoutDto> {
    let queryParams = {
      cartItemIds: params.cartItemIds,
    };
    let bodyParams;
    let formDataParams;
    return this.postGenerated(`/cart/cartOptionsChoice`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public checkoutCart(params: CheckoutCartParams, headers: { [key: string]: string } = {}): Observable<model.CartThankYouDto> {
    let bodyParams = params.cartCheckoutFormDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/cart/checkoutCart`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public detail(): Observable<model.CartDetailDto> {
    let queryParams;
    return this.getGenerated(`/cart/detail`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public insertCartItemUsingPOST(params: InsertCartItemUsingPOSTParams, headers: { [key: string]: string } = {}, context?: HttpContext): Observable<model.CartOverviewDto> {
    let bodyParams = params.cartItemFormDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/cart/item`, bodyParams, queryParams, formDataParams, headers, null, context);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public deleteCartItemUsingDELETE(params: DeleteCartItemUsingDELETEParams): Observable<boolean> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.deleteGenerated(`/cart/item/${pathParams.id}`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public paymentDetail(params: PaymentDetailParams): Observable<model.PaymentDetailDto> {
    let queryParams = {
      paymentId: params.paymentId,
    };
    return this.getGenerated(`/cart/paymentDetail`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public saveEmail(params: SaveEmailParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.emailDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/cart/saveEmail`, bodyParams, queryParams, formDataParams, headers);
  }
}
