import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ColorCombinationId } from '@common/colors/model/color-combination-id';

export type AlertBannerType = 'INFO' | 'SUCCESS' | 'WARNING' | 'ABSENCE' | 'CAMPAIGN' | 'PRIMARYOUTLINE' | 'POSTPONE' | 'DANGER';

/**
 * @deprecated
 * use {@link AlertBannerComponent} from 'ui-kit/alert-banner' folder
 */
@Component({
  selector: 'auk-alert-banner-legacy',
  templateUrl: 'alert-banner.component.html',
  styleUrls: ['./alert-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertBannerComponent {

  @Input() public customIconId: string;
  @Input() public iconColorCombination: ColorCombinationId;
  @HostBinding('class.banner-info') public info: boolean = false;
  @HostBinding('class.banner-success') public success: boolean = false;
  @HostBinding('class.banner-warning') public warning: boolean = false;
  @HostBinding('class.banner-absence') public absence: boolean = false;
  @HostBinding('class.banner-postpone') public postpone: boolean = false;
  @HostBinding('class.banner-campaign') public campaign: boolean = false;
  @HostBinding('class.banner-primary-outline') public primaryoutline: boolean = false;
  @HostBinding('class.banner-danger') public danger: boolean = false;
  private _type: AlertBannerType;

  @Input()
  public set type(value: AlertBannerType) {
    this._type = value;

    this.info = false;
    this.success = false;
    this.warning = false;
    this.absence = false;
    this.postpone = false;
    this.campaign = false;
    this.primaryoutline = false;
    this.danger = false;

    this[value.toLowerCase()] = true;
  }

  public get type(): AlertBannerType {
    return this._type;
  }

}
