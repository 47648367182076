<div class="tw-text-tertiary-500 tw-pb-safe-bottom">
  @if (dialogTitle) {
    <h2
      class="tw-text-center !tw-mb-2 tw-text-tertiary-900"
      mat-dialog-title
      >
      {{ dialogTitle | translateSource }}
    </h2>
  }

  @if (dialogSubtitle) {
    <p
      class="tw-text-base tw-leading-sm tw-text-center"
      [aukInnerHtml]="dialogSubtitle"
    ></p>
  }

  <mat-dialog-content [ngStyle]="{'overflow': isOverflowAuto ? 'auto' : 'inherit' }">
    <div class="tw-block tw-mt-8 tw-mb-5 tw-leading-sm">
      @for (dialogAlert of dialogAlerts; track $any(trackByDialogAlertTextFn)($index, dialogAlert)) {
        <auk-alert-banner
          [type]="dialogAlert.type"
          [text]="dialogAlert.text"
          textAlign="CENTER"
          class="tw-block tw-mb-5"
        ></auk-alert-banner>
      }
      <ng-content select="[content]"></ng-content>
    </div>
  </mat-dialog-content>

  @if (isActionsShown) {
    <mat-dialog-actions class="tw-block !tw-box-border">
      <ng-content select="[actions]"></ng-content>
    </mat-dialog-actions>
  }
</div>
