import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';
import { environment } from '@environment'; // TODO: [PDEV-18307] - fix dependency
import isNil from 'lodash-es/isNil';
import { Observable } from 'rxjs';

export abstract class RestHttpClientService {

  public static API_URL: string = environment.API_URL || '/backend-web/api'; // in serve mode is API_URL empty
  public static WS_URL: string = environment.WS_URL;
  private http: HttpClient;

  protected constructor(injector: Injector,
                        protected endpointName: string = '') {
    this.http = injector.get(HttpClient);
  }

  public get(url: string = '', headers?: object, search?: object, responseType?: string, context?: HttpContext): Observable<any> {
    const requestOptions: HttpClientRequestParams = this.prepareRequest(url, null, headers, search, responseType);
    return this.http.get(requestOptions.requestUrl, {
      ...requestOptions.options,
      context,
    });
  }

  public save(
    url: string,
    data?: unknown,
    headers?: object,
    search?: object,
    isBlob?: boolean,
    customResponseType?: string,
    context?: HttpContext): Observable<unknown> {
    const responseType: string = customResponseType ?? (isBlob ? 'blob' : 'json');
    const observe: string = isBlob ? 'response' : 'body';
    const requestOptions: HttpClientRequestParams = this.prepareRequest(url, data, headers, search, responseType, observe);
    return this.http.post(requestOptions.requestUrl, requestOptions.body, {
      ...requestOptions.options,
      context,
    });
  }

  public update(url: string | any, data?: any, headers?: object, search?: object): Observable<any> {
    const requestOptions: HttpClientRequestParams = this.prepareRequest(url, data, headers, search);
    return this.http.put(requestOptions.requestUrl, requestOptions.body, requestOptions.options);
  }

  public deleteRequest(url: string, params?: any): Observable<any> {
    const requestOptions: HttpClientRequestParams = this.prepareRequest(`${ url }`);
    const request = Object.assign({}, requestOptions, { params });
    return this.http.delete(request.requestUrl, { params: request.params });
  }

  public head(url: string = '', search?: object): Observable<any> {
    const requestOptions: HttpClientRequestParams = this.prepareRequest(url, null, null, search);
    return this.http.head(requestOptions.requestUrl, requestOptions.options);
  }

  public patch(url: string, data?: any, headers?: object, search?: object): Observable<any> {
    const requestOptions: HttpClientRequestParams = this.prepareRequest(url, data, headers, search);
    return this.http.patch(requestOptions.requestUrl, requestOptions.body, requestOptions.options);
  }

  /**
   * Method from generated services
   * @deprecated
   * @param url
   * @param data
   * @param query
   * @param form
   * @param headers - HTTP headers to be send with request
   * @param responseType
   * @param context
   * @returns
   */
  public postGenerated(url: string,
    data?: unknown,
    query?: object,
    form?: object,
    headers: { [key: string]: string } = {}, responseType?: string, context?: HttpContext): Observable<any> {
    const saveData: unknown | FormData = form ? this.getFormData(form) : data;

    return this.save(url, saveData, headers, query, false, responseType, context);
  }

  private getFormData(form: object): FormData {
    const data = new FormData();
    Object.keys(form).forEach((key) => data.append(key, form[key]));

    return data;
  }

  // todo: fix return type
  public postGeneratedBlob(url: string | any,
    data?: any,
    query?: object,
    form?: object,
    headers: { [key: string]: string } = {}): Observable<any> {
    if (form) {
      data = new FormData();
      Object.keys(form).forEach((key) => (data as FormData).append('key', form[key]));
    }

    return this.save(url, data, headers, query, true);
  }

  /**
   * Method from generated services
   * @deprecated
   * @param url
   * @param data
   * @param query
   * @param headers - HTTP headers to be send with request
   * @returns
   */
  public putGenerated(url: string, data?: any, query?: object, headers: { [key: string]: string } = {}): Observable<any> {
    return this.update(url, data, headers, query);
  }

  /**
   * Method from generated services
   * @deprecated
   * @param url
   * @param search
   * @param responseType
   * @param context
   * @returns
   */
  public getGenerated(url: string = '', search?: object, responseType?: string, context?: HttpContext): Observable<any> {
    return this.get(url, null, search, responseType, context);
  }

  /**
   * Method from generated services
   * @param url
   * @param search
   * @returns
   */
  public headGenerated(
    url: string = '',
    search?: object): Observable<any> {
    return this.head(url, search);
  }

  /**
   * @deprecated
   * @param url
   * @param queryParams
   * @returns
   */
  public deleteGenerated(url: string, queryParams?: any): Observable<any> {
    return this.deleteRequest(url, queryParams);
  }

  /**
   * Method from generated services
   * @deprecated
   * @param url
   * @param data
   * @param query
   * @param headers - HTTP headers to be send with request
   * @returns
   */
  public patchGenerated(url: string, data?: any, query?: object, headers: { [key: string]: string } = {}): Observable<any> {
    return this.patch(url, data, headers, query);
  }

  protected prepareRequest(
    url: string,
    body?: unknown,
    headersObj?: object,
    params?: object,
    responseType: string = 'json',
    observe: string = 'body',
  ): HttpClientRequestParams {
    const requestUrl = `${ RestHttpClientService.API_URL }${ this.endpointName }${ url }`;
    const newHeaders = Object.assign({}, headersObj, {
      /* Default headers */
    });

    const httpHeaders = this.toHttpHeaders(newHeaders);
    let httpParams;
    if (params) {
      httpParams = this.toHttpParams(params);
    }

    const options: HttpClientRequestParams = {
      requestUrl,
      body,
      options: {
        headers: httpHeaders,
        params: httpParams,
        responseType,
        observe,
      },
    };

    return options;
  }

  /**
   * Set http params - remove param if values is null or undefined
   * @param params
   * @returns
   */
  protected toHttpParams(params: object): HttpParams {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function(key) {
      if (!isNil(params[key])) {
        httpParams = httpParams.append(key, params[key]);
      }
    });
    return httpParams;
  }

  /**
   * Set http headers - remove param if values is null or undefined
   * @param headers
   * @returns
   */
  protected toHttpHeaders(headers: object): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    Object.keys(headers).forEach(function(key) {
      if (!isNil(headers[key])) {
        httpHeaders = httpHeaders.append(key, headers[key]);
      }
    });
    return httpHeaders;
  }

}

export interface HttpClientRequestParams {
  requestUrl: string;
  body: any;
  options: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    params?: HttpParams | {
      [param: string]: string | string[];
    };
    responseType?: any;
    observe?: any;
    reportProgress?: boolean;
  };
}
