<mat-accordion>
  @for (accordionItem of accordionItems; track $any(trackByTitleFn)($index, accordionItem)) {
    <mat-expansion-panel [expanded]="accordionItem.isActive">
      <mat-expansion-panel-header (click)="selectTab(accordionItem)">
        <mat-panel-title>
          @if (accordionItem.titleText) {
            <div>
              {{ accordionItem.titleText | translateSource  }}
            </div>
          }
          @if (accordionItem.titleTpl) {
            <div>
              <ng-container *ngTemplateOutlet="accordionItem.titleTpl"></ng-container>
            </div>
          }
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="accordionItem.accordionItemContent.templateRef"></ng-container>
    </mat-expansion-panel>
  }
</mat-accordion>
