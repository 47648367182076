import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { DropdownContainerDirective } from './dropdown-container.directive';
import { DropdownFilterButtonDirective } from './dropdown-filter-button.directive';
import { DropdownToggleDirective } from './dropdown-toggle.directive';
import { DropdownDirective } from './dropdown.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    DropdownDirective,
    DropdownToggleDirective,
    DropdownContainerDirective,
    DropdownFilterButtonDirective,
  ],
  exports: [
    DropdownDirective,
    DropdownToggleDirective,
    DropdownContainerDirective,
    DropdownFilterButtonDirective,
  ],
})
export class DropdownModule {

  public static forRoot(): ModuleWithProviders<DropdownModule> {
    return {
      ngModule: DropdownModule,
      providers: [],
    };
  }

}
