import { CurrencyCode } from '@shared/currency/model/currency-code.model';

export class YuspPersonalizationConstants {

  /**
   * Default currency used in Yusp.
   */
  public static YUSP_CURRENCY: CurrencyCode = 'CZK';

}
