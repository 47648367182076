import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HintDisablerComponent } from './component/hint-disabler.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { CommonPipesModule } from '@shared/legacy/pipe/common-pipes.module';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    TranslateModule,
    CommonPipesModule,
    InnerHtmlDirective,
  ],
  declarations: [
    HintDisablerComponent,
  ],
  exports: [
    HintDisablerComponent,
  ],
})
export class HintDisablerModule {
}
