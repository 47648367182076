<div aukTestIdentification="footer-navigation" class="footer-navigation">
  <!-- ACCORDION -->
  <auk-accordion-new class="md:tw-hidden tw-block tw-my-3">
    @for (item of footerMenu; track $any(trackByTitleFn)($index, item)) {
      <auk-accordion-item-new
        [titleText]="item.title">
        <ng-template aukAccordionItemContent>
          <ul>
            @for (subItem of item.items; track subItem) {
              <li class="tw-pb-2"
                [aukInnerHtml]="subItem">
              </li>
            }
          </ul>
        </ng-template>
      </auk-accordion-item-new>
    }
  </auk-accordion-new>
  <!-- ./ACCORDION -->

  <!-- APP UPDATE -->
  @if (isNativeApp) {
    <div class="tw-text-center tw-my-4">
      <auk-button
        size="SM"
        [colorCombination]="updateButtonDisabled ? 'FADED' : 'SILVERSTEIN_700_CONTRAST'"
        [isDisabled]="updateButtonDisabled"
        (buttonClick)="checkForUpdate()"
        >
        {{ updateButtonValue | translate }}
      </auk-button>
    </div>
  }

  <!-- GRID MENU  -->
  <auk-grid-menu class="tw-hidden md:tw-inline"
    [gridMenuItems]="footerMenu"
    [justifyContentBetween]="true">
  </auk-grid-menu>
  <!-- ./GRID MENU -->
</div>

<!-- BACKGROUND-BOX-->
<auk-background-box>
  <div class="tw-last:tw-block sm:tw-flex tw-flex-wrap tw-gap-y-4">

    <!-- EMAIL ICON + OTHER SOCIAL ICONS -->
    <div class="tw-flex tw-my-4 sm:tw-my-0">

      <!-- EMAIL ICON + CONTACT US -->
      <div class="lg:tw-ml-12">
        <auk-social-icon-button
          [hrefUrl]="{ defaultValue: '/kontakt' }"
          source="email"
          [text]="{ key: 'APP_FOOTER_SOCIAL_BUTTON_EMAIL_TEXT' }"
          colorCombination="TERTIARY_600_CONTRAST"
          [changeColorOnHover]="true">
        </auk-social-icon-button>
      </div>
      <!-- ./EMAIL ICON + CONTACT US -->

      <!-- TWITTER,YOUTUBE.FACEBOOK,INSTAGRAM ICONS -->
      <div class="tw-flex lg:tw-ml-16">
        <!-- TWITTER ICON -->
        <auk-social-icon-button
          [hrefUrl]="{ key: 'LINK_X' }"
          source="twitter"
          colorCombination="TERTIARY_600_CONTRAST">
        </auk-social-icon-button>
        <!-- ./TWITTER ICON -->

        <!-- FACEBOOK ICON -->
        <auk-social-icon-button
          [hrefUrl]="{ key: 'LINK_FB' }"
          source="facebook"
          colorCombination="TERTIARY_600_CONTRAST">
        </auk-social-icon-button>
        <!-- ./FACEBOOK ICON -->

        <!-- YOUTUBE ICON -->
        <auk-social-icon-button
          [hrefUrl]="{ key: 'LINK_YT' }"
          source="youtube"
          colorCombination="TERTIARY_600_CONTRAST">
        </auk-social-icon-button>
        <!-- ./YOUTUBE ICON -->

        <!-- INSTAGRAM ICON -->
        <auk-social-icon-button
          [hrefUrl]="{ key: 'LINK_IG' }"
          source="instagram"
          colorCombination="TERTIARY_600_CONTRAST">
        </auk-social-icon-button>
        <!-- ./INSTAGRAM ICON -->
      </div>
      <!-- ./TWITTER,YOUTUBE.FACEBOOK,INSTAGRAM ICONS-->
    </div>
    <!-- ./EMAIL ICON + OTHER SOCIAL ICONS -->

    <!-- GOOGLE ICON,APPLE ICON + DOWNLOAD ON STORE -->
    <div *aukHideIfMobileApp class="tw-flex lg:tw-ml-16">
      <auk-social-store-button
        [hrefUrl]="googlePlayLink"
        source="google"
        [text]="{ key: 'APP_FOOTER_STORE_BUTTON_NAME_GOOGLE_PLAY' }"
        [spanText]="{ key: 'APP_FOOTER_STORE_BUTTON_TITLE' }"
        colorCombination="TERTIARY_600_CONTRAST">
      </auk-social-store-button>

      <auk-social-store-button
        [hrefUrl]="appStoreLink"
        source="apple"
        [text]="{ key: 'APP_FOOTER_STORE_BUTTON_NAME_APPLE_STORE' }"
        [spanText]="{ key: 'APP_FOOTER_STORE_BUTTON_TITLE' }"
        colorCombination="TERTIARY_600_CONTRAST"
        class="tw-mx-4">
      </auk-social-store-button>
    </div>
    <!-- ./GOOGLE ICON,APPLE ICON + DOWNLOAD ON STORE -->

    <!-- BARION & PAYMENT METHODS ICONS -->
    <auk-svg-icon class="!tw-h-6 tw-mt-6 sm:tw-my-auto lg:tw-ml-12" iconId="payments-stripe" />
    <!-- ./BARION & PAYMENT METHODS ICONS -->
  </div>
</auk-background-box>
<!-- ./BACKGROUND-BOX -->

<!-- COPYRIGHT PANEL -->
<div class="tw-block md:tw-flex md:tw-justify-between tw-text-tertiary-400 tw-text-xs tw-py-5 tw-w-full">
  <div>
    <p class="tw-mb-2">
      © 2003&ndash;{{ currentYear }} AUKRO s.r.o &nbsp;
      <auk-build-info></auk-build-info>
    </p>
  </div>
  <div>
    <span class="tw-mb-2 tw-block lg:tw-inline-block lg:tw-pr-2 lg:tw-pr-0 tw-text-tertiary-400">
      {{ { key: 'APP_FOOTER_COPYRIGHT_TITLE' } | translateSource }}
    </span>

    <a class="footer-link tw-pr-0 lg:tw-px-3" aukLinkFromHref="https://obchodnipodminky.aukro.cz" [openInNewTab]="true">
      {{ { key: 'APP_FOOTER_COPYRIGHT_BUSINESS_CONDITIONS' } | translateSource }}
    </a>

    <a class="footer-link" routerLink="/dokumenty/cookies" [openInNewTab]="true">
      {{ { key: 'APP_FOOTER_COPYRIGHT_COOKIE_INFO' } | translateSource }}
    </a><!-- copy-info -->

    <a class="footer-link" aukPreventDefaultLink
      (click)="openCookiesPreferences()"
      [aukInnerHtml]="{key:'COOKIE_PREFERENCE_CENTER_LINK_TEXT'}"
    ></a>

    <a class="footer-link" routerLink="/mapa-kategorii">
      {{ { key: 'APP_FOOTER_COPYRIGHT_CATEGORY_MAP' } | translateSource }}
    </a>

    @if (showDesktopViewToggle) {
      @if (isMobile && !isDesktopViewToggled) {
        <a aukPreventDefaultLink
          class="tw-text-tertiary-600 hover:tw-underline sm:tw-hidden"
          data-gtm-click-event="toggle-view-mode|desktop"
          (click)="toggleDesktopView(true)"
          >{{ { key: 'APP_FOOTER_COPYRIGHT_BUTTON_DESKTOP_VERSION' } | translateSource }}
        </a>
      }

      @if (isDesktopViewToggled) {
        <a aukPreventDefaultLink
          class="tw-text-tertiary-600 hover:tw-underline"
          data-gtm-click-event="toggle-view-mode|mobile"
          (click)="toggleDesktopView(false)"
          >{{ { key: 'APP_FOOTER_COPYRIGHT_BUTTON_MOBILE_VERSION' } | translateSource }}
        </a>
      }
    }

    @if (isNativeApp) {
      <div class="tw-mt-2" [aukInnerHtml]="{ key:'APP_FOOTER_INFO_VERSION', params: { appVersion: appInfo?.appVersion, appBuild: appInfo?.appBuild }}"></div>
      <div [aukInnerHtml]="{ key: 'APP_FOOTER_INFO_DEVICE', params: { appBundle: appInfo?.appBundle }}"></div>
      <div [aukInnerHtml]="{ key: 'APP_FOOTER_INFO_BUNDLE', params: { deviceModel: appInfo?.deviceModel, deviceOS: appInfo?.deviceOS, deviceVersion: appInfo?.deviceVersion }}"></div>
      @if (!isProduction) {
        <div [aukInnerHtml]="{ key: 'APP_FOOTER_INFO_ENVIRONMENT', params: { environment: environment }}"></div>
      }
    }
  </div>
</div>
<!-- ./COPYRIGHT PANEL -->

<!-- WARNING BANNER  -->
<div class="tw-bg-info tw-bottom-0 tw-left-0 tw-fixed tw-w-full tw-z-100000 tw-text-xs">
  @if (browserUpdateLink) {
    <div class="tw-relative tw-p-4 tw-w-full">
      <div class="tw-inline-block tw-mx-4 tw-w-8/12 "><!-- banner-text -->
        <span>{{ { key: 'APP_FOOTER_WARNING_BANNER_TEXT' } | translateSource }}</span>
        <a class="tw-font-bold hover:tw-underline" [aukLinkFromHref]="browserUpdateLink">
          {{ { key: 'APP_FOOTER_WARNING_BANNER_TEXT_UPDATE_BROWSER' } | translateSource }}
        </a>
      </div>
      <auk-icon
        source="close"
        class="material-icons tw-float-right tw-relative tw-cursor-pointer tw-right-12"
        (click)="closeBrowserWarning()">
      </auk-icon>
    </div>
  }
</div>
<!-- ./WARNING BANNER  -->
