import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { RegistryCountryItemDto } from '../model/registry-country-item-dto';
import { RegistryDetailDto } from '../model/registry-detail-dto';
import { RegistryItemDto } from '../model/registry-item-dto';
import { BASE_PATH } from '../variables';

export interface GetBusinessFormsForCountryRequestParams {
    countryId: number;
}

export interface GetCountriesRequestParams {
    orderEnum?: 'NAME' | 'CODE' | 'POPULAR';
}

export interface GetEUCountriesRequestParams {
    orderEnum?: 'NAME' | 'CODE' | 'POPULAR';
}

export interface ListItemDurationValuesRequestParams {
    itemType: 'BUYNOW' | 'BIDDING';
}

@Injectable({
  providedIn: 'root',
})
export class RegistryApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Account type of user
   */
  public getAccountTypes$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/accountTypes`,
      options,
    );
  }

  /**
   * [UCU130.2] Accounting entry types used for billing
   */
  public getAccountingEntryTypes$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/accountingEntryTypes`,
      options,
    );
  }

  /**
   * [UCU010.2] Business forms for country
   */
  public getBusinessFormsForCountry$(
    requestParameters: GetBusinessFormsForCountryRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const countryId: number = requestParameters.countryId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/businessFormsForCountry/${ this.configuration.encodeParam({ name: 'countryId', value: countryId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }`,
      options,
    );
  }

  /**
   * [UCU600.5] Změnit jméno a příjmení (soukromý účet)
   */
  public getChangeNameRequestReasons$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/change/name/reasons`,
      options,
    );
  }

  /**
   * [UCU100.7] Countries
   */
  public getCountries$(
    requestParameters: GetCountriesRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryCountryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      orderEnum: requestParameters.orderEnum,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<RegistryCountryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryCountryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/countries`,
      options,
    );
  }

  /**
   * All mandatory registers for item detail.
   */
  public getDetailRegistry$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryDetailDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'RegistryDetailDto'),
      headers,
    };
    return this.httpClient.request<RegistryDetailDto>(
      'get',
      `${ BASE_PATH }/registry/detail`,
      options,
    );
  }

  /**
   * EU Countries
   */
  public getEUCountries$(
    requestParameters: GetEUCountriesRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryCountryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      orderEnum: requestParameters.orderEnum,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<RegistryCountryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryCountryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/euCountries`,
      options,
    );
  }

  /**
   * All mandatory registers for expose forms.
   */
  public getExposeRegistry$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryDetailDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'RegistryDetailDto'),
      headers,
    };
    return this.httpClient.request<RegistryDetailDto>(
      'get',
      `${ BASE_PATH }/registry/expose`,
      options,
    );
  }

  /**
   * All mandatory registers for expose forms.
   * @deprecated
   */
  public getExposeRegistrySe$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryDetailDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'RegistryDetailDto'),
      headers,
    };
    return this.httpClient.request<RegistryDetailDto>(
      'get',
      `${ BASE_PATH }/registry/expose-se`,
      options,
    );
  }

  /**
   * [UCU220.1] Receiver types
   */
  public getFeedbackReceiverTypes$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/feedback/receiver`,
      options,
    );
  }

  /**
   * [UCU220.1] Feedback types
   */
  public getFeedbackTypes$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/feedback/types`,
      options,
    );
  }

  /**
   * UCU130.5 Zobrazení faktur k vyúčtování
   */
  public getInvoiceDocumentTypes$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/invoice/type`,
      options,
    );
  }

  /**
   * [UCU310.1] Watched items notification setup
   */
  public getNotificationTerms$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/notificationTerms`,
      options,
    );
  }

  /**
   * [UCU105] Offer states
   */
  public getOfferStates$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/offer/states`,
      options,
    );
  }

  /**
   * Payment methods
   */
  public getPaymentMethods$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/payment/methods`,
      options,
    );
  }

  /**
   * [UCU125.5] Payment refund reasons
   */
  public getPaymentRefundReasons$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/payment/refundReasons`,
      options,
    );
  }

  /**
   * Push notification types
   */
  public getPushNotificationTypes$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/pushNotificationTypes`,
      options,
    );
  }

  /**
   * [UCU100.6,11] Get item duration list values
   */
  public listItemDurationValues$(
    requestParameters: ListItemDurationValuesRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      itemType: requestParameters.itemType,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/listItemDurationValues`,
      options,
    );
  }

  /**
   * [UCU100.6,11] Get item reexpose type list values
   */
  public listItemReexposeTypeValues$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/listItemReexposeTypeValues`,
      options,
    );
  }

  /**
   * [UCU100.6,11] Get item shipment payer type list values
   */
  public listItemShipmentPayerValues$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<RegistryItemDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<RegistryItemDto>'),
      headers,
    };
    return this.httpClient.request<RegistryItemDto[]>(
      'get',
      `${ BASE_PATH }/registry/listItemShipmentPayerTypeValues`,
      options,
    );
  }

}
