import { Directive, ElementRef, Injector, Input, OnChanges, Renderer2 } from '@angular/core';
import { Nil } from '@util/helper-types/nil';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { TranslationSource } from '@common/translations/model/translation-source';
import { TranslationUtil } from '@common/translations/util/translation.util';
import isNil from 'lodash-es/isNil';
import { TranslateSourcePipeService } from '@common/translations/service/translate-source-pipe.service';
import { HtmlSanitizeService } from '@common/html/service/html-sanitize.service';
import { merge, Subject } from 'rxjs';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { RoutingService } from '@common/routing/service/routing.service';
import { StringUtils } from '@util/util/string.utils';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[aukInnerHtml]',
  standalone: true,
})
export class InnerHtmlDirective extends NgUnsubscribe implements OnChanges {

  /**
   * Html string, which will be passed as innerHtml on this element
   */
  @Input({ required: true }) public aukInnerHtml: TranslationSource | string | Nil;
  /**
   * see {@link HtmlSanitizeService#getSanitizedHtmlString}
   */
  @Input() public allowStrictSanitize: boolean = true;

  private readonly innerHtmlChange$: Subject<void> = new Subject<void>();

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly translateSourcePipeService: TranslateSourcePipeService,
    private readonly injector: Injector,
    private readonly htmlSanitizeService: HtmlSanitizeService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly routingService: RoutingService,
    private readonly renderer: Renderer2,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (changes.aukInnerHtml) {
      this.innerHtmlChange$.next();
      this.processHtmlValue();
    }
  }

  private processHtmlValue(): void {
    const processValueSync = (htmlValue: string | Nil): void => {
      const htmlString = this.getUnifiedStringValue(htmlValue);

      if (StringUtils.isEmpty(htmlString)) {
        this.setInnerHtml('');
        return;
      }

      const processedHtmlInfo = this.getParsedHtml(htmlString);

      this.processHtmlBeforeInsert(processedHtmlInfo);

      this.setInnerHtml(processedHtmlInfo.parsedHtml.innerHTML);

      this.processHtmlAfterInsert(processedHtmlInfo);
    };

    // handle async translation
    if (TranslationUtil.isTranslationSource(this.aukInnerHtml)) {
      this.translateSourcePipeService.transformWithInjector$(this.aukInnerHtml, this.injector)
        .pipe(
          takeUntil(
            merge(
              this.innerHtmlChange$,
              this.ngUnsubscribe,
            ),
          ),
        )
        .subscribe((value) => {
          processValueSync(value);
        });
      return;
    }

    processValueSync(this.aukInnerHtml);
  }

  private getUnifiedStringValue(aukInnerHtml: string | Nil): string | Nil {
    if (isNil(aukInnerHtml)) {
      return null;
    }
    if (typeof aukInnerHtml === 'string') {
      return aukInnerHtml;
    }
    return null;
  }

  private setInnerHtml(htmlString: string): void {
    this.elementRef.nativeElement.innerHTML = this.htmlSanitizeService.getSanitizedHtmlString(htmlString, this.allowStrictSanitize);
  }

  private getParsedHtml(
    htmlString: string,
  ): ProcessedHtmlInfo {
    const htmlContainsImages = htmlString.includes('<img ');
    const htmlContainsAnchors = htmlString.includes('<a ');

    const processedHtmlInfo: ProcessedHtmlInfo = {
      hasAnchors: htmlContainsAnchors,
      hasImages: htmlContainsImages,
    };

    // if the html string contains images, we need to use DomParser for parsing it into html because if we would
    // just create some dummy element (like on server), browser would automatically upon the parsing time already
    // start loading the images, because they are not having loading=lazy attribute set (which we want to set afterward)
    if (this.platformCommonService.isBrowser && htmlContainsImages) {
      const domParser = new DOMParser();

      // parse html string
      const parsedHtml = domParser.parseFromString(htmlString, 'text/html');

      // some html can have also <head> children, so we need it to append into body element
      if (parsedHtml.head.children.length > 0) {
        parsedHtml.body.append(...Array.from(parsedHtml.head.children));
      }

      // return body element (which contains the parsed html)
      processedHtmlInfo.parsedHtml = parsedHtml.body;
    } else {
      const bodyElm = this.renderer.createElement('div') as HTMLDivElement;

      // NOTE: we don't need to manually move elements from <head> (like above) in this approach, because we are not using DomParser

      bodyElm.innerHTML = htmlString;

      processedHtmlInfo.parsedHtml = bodyElm;
    }

    return processedHtmlInfo;
  }

  /**
   * Processes HTML before it is inserted into DOM
   */
  private processHtmlBeforeInsert(
    processedHtmlInfo: ProcessedHtmlInfo,
  ): void {
    if (processedHtmlInfo.hasImages) {
      const imgElms = Array.from(processedHtmlInfo.parsedHtml.querySelectorAll('img'));

      imgElms?.forEach(imgElm => {
        this.processImgElm(imgElm);
      });
    }
  }

  private processAnchorElm(anchorElm: HTMLAnchorElement): void {
    const routingInfo = this.routingService.getHrefRoutingResult(
      anchorElm.href,
      anchorElm.target === '_blank',
    );

    // remove attributes if there's no routing info
    if (isNil(routingInfo)) {
      anchorElm.removeAttribute('href');
      anchorElm.removeAttribute('target');
      return;
    }

    // set attributes
    // target can be nil
    if (isNotNil(routingInfo.target)) {
      anchorElm.target = routingInfo.target;
    }
    anchorElm.href = routingInfo.href;

    // create click listener for in-app navigation
    this.routingService.createAnchorClickListener(
      routingInfo,
      anchorElm,
      merge(
        this.innerHtmlChange$,
        this.ngUnsubscribe,
      ),
    );
  }

  private processImgElm(imgElm: HTMLImageElement): void {
    // set image to lazy load
    imgElm.setAttribute('loading', 'lazy');
  }

  /**
   * Processes HTML before after it is inserted into DOM
   */
  private processHtmlAfterInsert(processedHtmlInfo: ProcessedHtmlInfo): void {
    if (this.platformCommonService.isServer) {
      return;
    }

    // we need to process anchors only after it is inserted into DOM, because otherwise click listeners wouldn't work
    if (processedHtmlInfo.hasAnchors) {
      const anchorElms = Array.from(this.elementRef.nativeElement.querySelectorAll('a'));

      anchorElms?.forEach(anchorElm => {
        this.processAnchorElm(anchorElm);
      });
    }

  }

}

interface ProcessedHtmlInfo {
  parsedHtml?: HTMLElement;
  hasAnchors: boolean;
  hasImages: boolean;
}
