import { ChangeDetectionStrategy, Component, OnInit, Input, Output, EventEmitter, OnChanges, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Translate2Module } from '@common/translations/translate2.module';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { RouterModule } from '@angular/router';
import { TabControlModel } from '../../model/tab-control';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { trackByIdFn } from '@util/helper-functions/track-by/track-by-id-fn';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';

@Component({
  selector: 'auk-tab-control',
  templateUrl: './tab-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    RouterModule,
    Translate2Module,
    IconComponent,
  ],
})
export class TabControlComponent extends ColorCombinationInputComponent implements OnInit, OnChanges {

  @Input() public tabList: TabControlModel[] = [];

  @Input() public type: 'NORMAL' | 'COMPACT' = 'COMPACT';

  @Input() public tabBackground: 'CONTRAST' | 'NEUTRAL' = 'NEUTRAL';

  @Input() public defaultSelectedTabId: string;

  @Output() public tabChange: EventEmitter<string> = new EventEmitter<string>();

  protected selectedTabId: string;
  protected trackByOptionIdFn = trackByIdFn;

  constructor(elementRef: ElementRef<HTMLElement>) {
    super(elementRef);
  }

  public ngOnInit(): void {
    this.selectedTabId = this.tabList?.find((tab) => tab?.default === true)?.id ?? (this.defaultSelectedTabId || this.tabList[0]?.id);
    this.moveDefaultTrueObjectsToStart(this.tabList);
  }

  public override ngOnChanges(changes: AukSimpleChanges<TabControlComponent>): void {
    super.ngOnChanges(changes);

    if (changes.defaultSelectedTabId) {
      this.onChangeSelectedTab(changes.defaultSelectedTabId.currentValue);
    }
  }

  private moveDefaultTrueObjectsToStart(tabList: TabControlModel[]): void {
    tabList?.sort((a, b) => (a?.default === b?.default ? 0 : a?.default ? -1 : 1));
  }

  protected onChangeSelectedTab(selectedTabId: string): void {
    this.tabChange.emit(selectedTabId);
    this.selectedTabId = selectedTabId;
  }

  protected get getBackgroundClass(): string {
    if (this.tabBackground === 'CONTRAST') {
      return 'tw-bg-surface-neutral-0';
    } else if (this.type === 'COMPACT') {
      return 'tw-bg-surface-neutral-3';
    } else {
      return 'tw-bg-surface-neutral-2';
    }
  }

}
