export class BannerConstants {

  /**
   * Maximal amount of banner deployment IDs stored in the cookie.
   */
  public static readonly MAX_AMOUNT_OF_SHOWN_BANNER_DEPLOYMENT_IDS: number = 50;

  public static readonly BANNER_DEFAULT_SUPPRESS_TIME: number = 24;

  public static readonly POPUP_BANNER_SUPPRESSED_COOKIE_KEY: string = 'web-popup-suppressed';

  public static readonly POPUP_BANNER_IDS_SHOWN_COOKIE_KEY: string = 'web-popups-shown';

  public static readonly TOP_LINE_BANNER_SUPPRESSED_COOKIE_KEY: string = 'top-line-banner-suppressed';

  public static readonly TOP_LINE_BANNER_DEPLOYMENT_IDS_SHOWN_COOKIE_KEY: string = 'top-line-banners-shown';

  public static readonly BANNER_PROMOTION_ID: string = 'DEFAULT_PROMO';

  public static readonly BANNER_PROMOTION_CAMPAIGN: string = 'Defaultní promo kampaň';

}
