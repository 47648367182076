import isNil from 'lodash-es/isNil';
import { ArrayUtils } from '@util/util/array.utils';
import { RegistryCountryItemDto } from '@api/generated/defs/RegistryCountryItemDto';

/**
 * Exclude "Cadska republika" from the listed countries. It's used because of frequent mistakes with "Ceska republika".
 * @param countries
 */
export function excludeCadRepublic(countries: RegistryCountryItemDto[]): RegistryCountryItemDto[] {
  if (isNil(countries) || ArrayUtils.isEmpty(countries)) {
    return [];
  }

  const CAD_ID = 43; // ID of "Cadska republika"
  return countries.filter(country => country?.id !== CAD_ID);
}
