import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomainCode } from '@shared/platform/domain.model';
import { ICULocale } from '@shared/platform/icu-locale.model';
import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';
import { DomainPickerMatDialogConfigModel } from '@shared/domain-picker/model/domain-picker-mat-dialog-config.model';

@Component({
  templateUrl: './domain-picker-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainPickerDialogComponent {

  protected form: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly matDialogRef: MatDialogRef<DomainPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DomainPickerMatDialogConfigModel,
  ) {
    this.initForm();
  }

  public get domainList(): ICULocale[] {
    return this.data?.domainList;
  }

  public iconModel(flagIcon: string): IconModel {
    return {
      type: 'SVG',
      source: flagIcon,
      size: 'MD',
    };
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      domainList: [this.data?.actualDomain.country],
    });
  }

  public onCloseClick(): void {
    this.matDialogRef.close();
  }

  public onSavePreferenceClick(): void {
    this.matDialogRef.close(
      this.form.get('domainList').value as DomainCode,
    );
  }

}
