import { Injectable } from '@angular/core';
import { map, take, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PersonalizationMeasurementService } from '@shared/services/personalization/personalization-measurement.service';
import { Observable, of } from 'rxjs';
import { ClickEventDto } from '@api/aukro-api/model/click-event-dto';
import { ShowListingEventResponseDto } from '@api/aukro-api/model/show-listing-event-response-dto';
import { FavouriteTabModelNameEnum } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-tab-name.type';
import { Nil } from '@util/helper-types/nil';
// Needed as PersonalizationMeasurementService still use old api models
// eslint-disable-next-line auk-rules/no-mixed-api-files
import { ClickEventDto as LegacyClickEventDto } from '@api/generated/defs/ClickEventDto';
import isNil from 'lodash-es/isNil';

@Injectable({
  providedIn: 'root',
})
export class HeaderUserPersonalizationMeasurementService extends NgUnsubscribe {

  private readonly ACCOUNT_MEASUREMENT_OBJECT_CODE: string = 'POPOVER_MY_AUKRO';
  private readonly FAVOURITE_ITEMS_MEASUREMENT_OBJECT_CODE: string = 'POPOVER_FAVOURITES';
  private readonly FAVOURITE_SELLERS_MEASUREMENT_OBJECT_CODE: string = 'POPOVER_FAVOURITE_SELLERS';
  private readonly FAVOURITE_SEARCHES_MEASUREMENT_OBJECT_CODE: string = 'POPOVER_FAVOURITE_SEARCH';
  private readonly WATCHED_ITEMS_MEASUREMENT_OBJECT_CODE: string = 'POPOVER_WATCHED';
  private readonly CART_MEASUREMENT_OBJECT_CODE: string = 'POPOVER_CART';

  constructor(
    private readonly personalizationMeasurementService: PersonalizationMeasurementService,
  ) {
    super();
  }

  public clickAccountPopover(clickEventDto: ClickEventDto): void {
    this.clickPopover(clickEventDto, this.ACCOUNT_MEASUREMENT_OBJECT_CODE);
  }

  public showAccountPopover$(): Observable<number> {
    return this.showPopover$(this.ACCOUNT_MEASUREMENT_OBJECT_CODE);
  }

  public clickFavouritesPopover(clickEventDto: ClickEventDto, tab: FavouriteTabModelNameEnum): void {

    const code = this.getFavouriteTabCodeByName(tab);

    if (isNil(code)) {
      return;
    }

    this.clickPopover(clickEventDto, code);
  }

  public showFavouritesPopover$(tab: FavouriteTabModelNameEnum): Observable<number | Nil> {

    const code = this.getFavouriteTabCodeByName(tab);

    if (isNil(code)) {
      return of(null);
    }

    return this.showPopover$(code);
  }

  public getFavouriteTabCodeByName(tab: FavouriteTabModelNameEnum): string | Nil {
    if (tab === 'ITEMS') {
      return this.FAVOURITE_ITEMS_MEASUREMENT_OBJECT_CODE;
    }

    if (tab === 'SELLERS') {
      return this.FAVOURITE_SELLERS_MEASUREMENT_OBJECT_CODE;
    }

    if (tab === 'SEARCH') {
      return this.FAVOURITE_SEARCHES_MEASUREMENT_OBJECT_CODE;
    }

    return null;
  }

  public clickWatchedItemsPopover(clickEventDto: ClickEventDto): void {
    this.clickPopover(clickEventDto, this.WATCHED_ITEMS_MEASUREMENT_OBJECT_CODE);
  }

  public showWatchedItemsPopover$(): Observable<number> {
    return this.showPopover$(this.WATCHED_ITEMS_MEASUREMENT_OBJECT_CODE);
  }

  public clickCartPopover(clickEventDto: ClickEventDto): void {
    this.clickPopover(clickEventDto, this.CART_MEASUREMENT_OBJECT_CODE);
  }

  public showCartPopover$(): Observable<number> {
    return this.showPopover$(this.CART_MEASUREMENT_OBJECT_CODE);
  }

  private clickPopover(clickEventDto: ClickEventDto, moCode: string): void {
    this.personalizationMeasurementService.clickMeasurement$({
      ...clickEventDto as LegacyClickEventDto,
      measurementObjectCode: moCode,
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  private showPopover$(measurementObjectCode: string): Observable<number> {
    return this.personalizationMeasurementService.showMeasurement$({
      measurementObjectCode,
    })
      .pipe(
        map((response: ShowListingEventResponseDto) => response?.showId),
      );
  }

}
