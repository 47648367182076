import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ContactFormParams {
  /** contactFormMessageDto */
  contactFormMessageDto: model.ContactFormMessageDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface AttachmentParams {
  /** file */
  file: File;
  /**
   * position
   * format: int32
   */
  position: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SearchInMessageCommunicationHistoryUsingGETParams {
  /** term */
  term: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SlicedParams {
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetConversationByTopicAndObjectIdUsingPOSTParams {
  /** findConversationParams */
  findConversationParams: model.FindConversationParams;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetMessageUsingGETParams {
  /** extId */
  extId: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface AnswerParams {
  /** messageAnswerSaveDto */
  messageAnswerSaveDto: model.MessageAnswerSaveDto;
  /** extId */
  extId: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ExtendedParams {
  /** messageAnswerSaveDto */
  messageAnswerSaveDto: model.MessageAnswerSaveDto;
  /** extId */
  extId: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetMessageCommunicationHistoryUsingGETParams {
  /** extId */
  extId: string;
  /** messagesOnly */
  messagesOnly?: boolean;
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface DeleteParams {
  /** extId */
  extId: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ReadParams {
  /** extId */
  extId: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CreateMessageFromReplyContactUsingPOSTParams {
  /**
   * replyContactId
   * format: int64
   */
  replyContactId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root'
})
export class ConversationService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public contactForm(params: ContactFormParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.contactFormMessageDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/conversation/contactForm`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public attachment(params: AttachmentParams, headers: { [key: string]: string } = {}): Observable<model.AttachmentDto> {
    let formDataParams = {
      file: params.file,
    };
    let queryParams = {
      position: params.position,
    };
    let bodyParams;
    return this.postGenerated(`/conversation/message/attachment`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public searchInMessageCommunicationHistoryUsingGET(params: SearchInMessageCommunicationHistoryUsingGETParams): Observable<model.MessageOverviewDto[]> {
    let queryParams = {
      term: params.term,
    };
    return this.getGenerated(`/conversation/message/communicationHistory/search`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public sliced(params: SlicedParams): Observable<model.ConversationsDto> {
    let queryParams = {
      page: params.page,
      size: params.size,
    };
    return this.getGenerated(`/conversation/message/communicationHistory/sliced`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getConversationByTopicAndObjectIdUsingPOST(params: GetConversationByTopicAndObjectIdUsingPOSTParams, headers: { [key: string]: string } = {}): Observable<model.ConversationSearchResult> {
    let bodyParams = params.findConversationParams;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/conversation/message/search`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getMessageUsingGET(params: GetMessageUsingGETParams): Observable<model.MessageShortDto> {
    let pathParams = {
      extId: params.extId,
    };
    let queryParams;
    return this.getGenerated(`/conversation/message/${pathParams.extId}`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public answer(params: AnswerParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.messageAnswerSaveDto;
    let pathParams = {
      extId: params.extId,
    };
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/conversation/message/${pathParams.extId}/answer`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public extended(params: ExtendedParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.messageAnswerSaveDto;
    let pathParams = {
      extId: params.extId,
    };
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/conversation/message/${pathParams.extId}/answer/extended`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getMessageCommunicationHistoryUsingGET(params: GetMessageCommunicationHistoryUsingGETParams): Observable<model.MessagesDto> {
    let pathParams = {
      extId: params.extId,
    };
    let queryParams = {
      messagesOnly: params.messagesOnly,
      page: params.page,
      size: params.size,
    };
    return this.getGenerated(`/conversation/message/${pathParams.extId}/communicationHistory`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public delete(params: DeleteParams, headers: { [key: string]: string } = {}): Observable<void> {
    let pathParams = {
      extId: params.extId,
    };
    let bodyParams;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/conversation/message/${pathParams.extId}/delete`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public read(params: ReadParams, headers: { [key: string]: string } = {}): Observable<void> {
    let pathParams = {
      extId: params.extId,
    };
    let bodyParams;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/conversation/message/${pathParams.extId}/read`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public createMessageFromReplyContactUsingPOST(params: CreateMessageFromReplyContactUsingPOSTParams, headers: { [key: string]: string } = {}): Observable<model.MessageShortDto> {
    let pathParams = {
      replyContactId: params.replyContactId,
    };
    let bodyParams;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/conversation/message/${pathParams.replyContactId}`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public unreadPrimeMessageIds(): Observable<string[]> {
    return this.getGenerated(`/conversation/unreadPrimeMessageExtIds`);
  }
}
