import { Injectable } from '@angular/core';
import { RxStomp } from '@stomp/rx-stomp';

@Injectable({
  providedIn: 'root',
})
export class RxStompService extends RxStomp {

  // explicit constructor needed, because the extended service doesn't have @Injectable() decorator
  constructor() {
    super();
  }

}
