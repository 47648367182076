import isString from 'lodash-es/isString';
import isNil from 'lodash-es/isNil';
import moment, { Moment } from 'moment-mini-ts';
import { Nil } from '@util/helper-types/nil';
import upperCase from 'lodash-es/upperCase';
import kebabCase from 'lodash-es/kebabCase';

export class StringUtils {

  /**
   * @param s - tested string
   * @returns whether the given string is empty
   * @example
   * // returns true
   * StringUtils.isEmpty(null)
   * // returns true
   * StringUtils.isEmpty(undefined)
   * // returns true
   * StringUtils.isEmpty('')
   * // returns false
   * StringUtils.isEmpty('text')
   * // returns false
   * StringUtils.isEmpty('  ')
   */
  public static isEmpty(s: string): boolean {
    return s == null || s.length === 0;
  }

  /**
   * @param value - tested string
   * @returns whether the given string is blank
   * @example
   * // returns true
   * StringUtils.isBlank(null)
   * // returns true
   * StringUtils.isBlank(undefined)
   * // returns true
   * StringUtils.isBlank('')
   * // returns true
   * StringUtils.isBlank('  ')
   * // returns false
   * StringUtils.isBlank('text')
   */
  public static isBlank(value: string): boolean {
    return value == null || value.trim?.().length === 0;
  }

  /**
   * Method converts string to boolean. Implicit value is false for all other cases than 'true' string is present.
   * @param s string we want to convert
   * @returns true if input is not nil and contains exactly 'true' string (case insensitive), false otherwise
   */
  public static parseBoolean(s: string): boolean {
    if (!isString(s)) {
      return false;
    }

    return s.trim().toLowerCase() === 'true';
  }

  /**
   * Method converts a string to a number.
   * @param s string we want to convert
   * @returns for any string that is not a valid number. Number representation of the string otherwise.
   */
  public static parseNumber(s: string): number {
    if (!isString(s)) {
      return null;
    }

    const num = Number(s);

    if (isNaN(num)) {
      return null;
    }

    return num;
  }

  public static parseDate(input: string): Moment {
    if (this.isEmpty(input)) {
      return null;
    }

    const date: Moment = moment(input);

    if (!date.isValid()) {
      return null;
    }

    return date;
  }

  public static isIntegerString(string: string): boolean {
    if (isNil(string)) {
      return false;
    }

    return parseInt(string, 10).toString() === string;
  }

  public static stripWhitespace(string: string): string | null {
    if (isNil(string)) {
      return null;
    }

    return string.replace(/\s/g, '');
  }

  public static capitalizeFirstLetter(string: string): string | null {
    if (this.isEmpty(string)) {
      return string;
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * converts string to SCREAM_CASE
   * @example
   *   Happy dog => HAPPY_DOG
   * @param value - string to transform
   */
  public static toScreamCase(value: string): string | Nil {
    if (isNil(value)) {
      return null;
    }

    return upperCase(value.trim()).replace(/ /g, '_');
  }

  public static toKebabCase(value: string): string | Nil {
    if (isNil(value)) {
      return null;
    }

    return kebabCase(value.trim());
  }

  public static formatZipCode(zipCode: string): string {
    if (/^\d{5}$/.test(zipCode)) {
      return zipCode.replace(/(\d{3})(\d{2})/, '$1 $2');
    } else {
      return zipCode;
    }
  }

  /**
   * Combines all strings with dash
   * @param {...any} strings
   */
  public static joinStringsWithDash(...strings: string[]): string {
    return strings.join('-');
  }

  /**
   * Combines all strings with specific delimiter
   * @param delimiter
   * @param {...any} strings
   */
  public static joinStringsWithDelimiter(delimiter: string, ...strings: string[]): string {
    return strings.join(delimiter);
  }

}
