import isNil from 'lodash-es/isNil';

/**
 * Remove html from string
 * Usable also for preventing XSS
 * @param content
 * @param document
 */
export function stripHtml(content: string, document: Document = null): string {
  if (isNil(content)) {
    return '';
  }

  let contentToReplace: string = content;
  if (document) {
    const el = document.createElement('div');
    el.innerHTML = content;
    contentToReplace = el.innerText;
  }
  return contentToReplace.replace(/<[^>]*>/g, '');

}
