import { Injectable } from '@angular/core';
import { UserActionPrerequisiteTaskFulfillmentService } from '../task-fulfillment/user-action-prerequisite-task-fulfillment.service';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';
import { UserTaskType } from '@shared/user-task/base/model/user-task.type';

@Injectable({
  providedIn: 'root',
})
export class UserActionPrerequisiteVerifiedPhoneFulfillmentService
  extends UserActionPrerequisiteTaskFulfillmentService<['VERIFIED_PHONE']> {

  public readonly prerequisiteCombination: ['VERIFIED_PHONE'] = ['VERIFIED_PHONE'];

  protected readonly taskType: UserTaskType = 'VERIFIED_PHONE';

  constructor(
    userTaskService: UserTaskService,
  ) {
    super(userTaskService);
  }

}
