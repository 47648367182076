import { UserTaskExecutorService } from '../../base/service/user-task-executor.service';
import { Injectable, Injector } from '@angular/core';
import { UserTaskStepDefinitionModel } from '../../base/model/user-task-step-definition.model';
import { UserTaskStepType } from '../../base/model/user-task-step.type';
import { UserTaskLoginStepLoginEmailComponent } from '../component/user-task-login-step-login-email/user-task-login-step-login-email.component';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskLoginStepEmailConfirmationNonRegisteredViaEmailComponent } from '../component/user-task-login-step-email-confirmation-non-registered-via-email.component';
import { UserTaskLoginStepLoginEmailResolutionHandlerService } from './user-task-login-step-login-email-resolution-handler.service';
import { UserTaskLoginStepEmailConfirmationNonRegisteredViaEmailResolutionHandlerService } from './user-task-login-step-email-confirmation-non-registered-via-email-resolution-handler.service';
import { UserTaskLoginStepFacebookLoadComponent } from '../component/user-task-login-step-facebook-load.component';
import { UserTaskLoginStepFacebookLoadResolutionHandlerService } from './user-task-login-step-facebook-load-resolution-handler.service';
import { UserTaskLoginStepPasswordOrThirdPartyResolutionHandlerService } from './user-task-login-step-password-or-third-party-resolution-handler.service';
import { UserTaskLoginStepEmailConfirmationRegisteredViaFbComponent } from '../component/user-task-login-step-email-confirmation-registered-via-fb.component';
import { UserTaskLoginStepEmailConfirmationRegisteredViaFbResolutionHandlerService } from './user-task-login-step-email-confirmation-registered-via-fb-resolution-handler.service';
import { UserTaskLoginStepFacebookAuthorizeFailedComponent } from '../component/user-task-login-step-facebook-authorize-failed.component';
import { UserTaskLoginStepFacebookAuthorizeFailedResolutionHandlerService } from './user-task-login-step-facebook-authorize-failed-resolution-handler.service';
import { ModalViewsConfigService } from '../../base/service/modal-views-config.service';
import { Observable, of } from 'rxjs';
import { UserTaskLoginStepSetPasswordComponent } from '../component/user-task-login-step-set-password.component';
import { UserTaskLoginStepSetPasswordResolutionHandlerService } from './user-task-login-step-set-password-resolution-handler.service';
import { UserTaskLoginStepPasswordOrThirdPartyComponent } from '../component/user-task-login-step-password-or-third-party.component';
import { PopupFlowGaService } from '@shared/popup-flow/service/popup-flow-ga.service';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';

@Injectable({
  providedIn: 'root',
})
export class UserTaskLoginExecutorService extends UserTaskExecutorService<'LOGIN'> {

  protected steps: { [STEP_TYPE in UserTaskStepType<'LOGIN'>]: UserTaskStepDefinitionModel<'LOGIN', STEP_TYPE> } = {
    LOGIN_EMAIL: {
      component: UserTaskLoginStepLoginEmailComponent,
      resolutionHandler: UserTaskLoginStepLoginEmailResolutionHandlerService,
    },
    SET_PASSWORD: {
      component: UserTaskLoginStepSetPasswordComponent,
      resolutionHandler: UserTaskLoginStepSetPasswordResolutionHandlerService,
    },
    PASSWORD_OR_THIRD_PARTY: {
      component: UserTaskLoginStepPasswordOrThirdPartyComponent,
      resolutionHandler: UserTaskLoginStepPasswordOrThirdPartyResolutionHandlerService,
    },
    EMAIL_CONFIRMATION_NON_REGISTERED_VIA_EMAIL: {
      component: UserTaskLoginStepEmailConfirmationNonRegisteredViaEmailComponent,
      resolutionHandler: UserTaskLoginStepEmailConfirmationNonRegisteredViaEmailResolutionHandlerService,
    },
    FB_LOAD: {
      component: UserTaskLoginStepFacebookLoadComponent,
      resolutionHandler: UserTaskLoginStepFacebookLoadResolutionHandlerService,
    },
    EMAIL_CONFIRMATION_REGISTERED_VIA_FB: {
      component: UserTaskLoginStepEmailConfirmationRegisteredViaFbComponent,
      resolutionHandler: UserTaskLoginStepEmailConfirmationRegisteredViaFbResolutionHandlerService,
    },
    FACEBOOK_AUTHORIZE_FAILED: {
      component: UserTaskLoginStepFacebookAuthorizeFailedComponent,
      resolutionHandler: UserTaskLoginStepFacebookAuthorizeFailedResolutionHandlerService,
    },
  };

  protected taskType: 'LOGIN' = 'LOGIN' as const;

  constructor(
    injector: Injector,
    modalViewsConfigService: ModalViewsConfigService,
    popupFlowGaService: PopupFlowGaService,
    aukMatDialogService: AukMatDialogService,
  ) {
    super(injector, modalViewsConfigService, popupFlowGaService, aukMatDialogService);
  }

  protected getFirstStep$(payload: UserTaskPayloadModel<'LOGIN'>): Observable<UserTaskStepUnionModel<'LOGIN'>> {

    return of({
      type: 'LOGIN_EMAIL',
      payload: {
        sourceAction: payload?.sourceAction,
        ...payload?.stepsText?.LOGIN_EMAIL,
      },
    });
  }

}
