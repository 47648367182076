import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'auk-banner-close-button',
  templateUrl: 'banner-close-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerCloseButtonComponent {

  @Output() public closeClick: EventEmitter<void> = new EventEmitter<void>();

  protected close(): void {
    this.closeClick.emit();
  }

}
