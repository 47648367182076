import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TrackByFunction,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TileComponent } from '@common/ui-kit/component/tile/component/tile/tile.component';
import { TileModel } from '@common/ui-kit/component/tile/model/tile.model';
import { trackByIndexFn } from '@util/helper-functions/track-by/track-by-index.fn';
import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';
import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { ToggleTileComponent } from '@common/ui-kit/component/toggle-tile/component/toggle-tile.component';
import { TileContainerStyleModel } from '@common/ui-kit/component/tile/model/tile-container-style.model';
import { IfIsActiveBreakpointDirective } from '@common/responsiveness/directive/if-is-active-breakpoint/if-is-active-breakpoint.directive';
import { TileConstant } from '@common/ui-kit/component/tile/constant/tile.constant';
import { EmptyObject } from '@util/helper-types/empty-object';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ToggleTileItemModel } from '@common/ui-kit/component/toggle-tile/model/toggle-tile-item.model';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';

@Component({
  selector: 'auk-tile-container',
  templateUrl: './tile-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TileComponent,
    IfIsActiveBreakpointDirective,
    ToggleTileComponent,
    TestIdentificationDirective,
  ],
})
export class TileContainerComponent<T> extends NgUnsubscribe implements OnInit, OnChanges {

  @Input() public tileList: TileModel<T>[] = [];

  @Input() public tileType: 'SIMPLE' | 'NORMAL' = 'NORMAL';

  @Input() public tileSize: 'BIG' | 'SMALL' = 'SMALL';

  @Input() public type: 'ONE-LINE' | 'MULTI-LINE' = 'MULTI-LINE';

  /**
   * Show more tile with custom behaviour
   */
  @Input() public customShowMoreTile: TileModel<T> | Nil = null;

  /**
   * @deprecated
   * see comment on TileContainerStyleModel
   */
  @Input() public tileContainerStyle: TileContainerStyleModel;

  /**
   * Max count of tiles which could be displayed without show more button
   */
  @Input() public showMoreThreshold: number | Nil = null;

  /**
   * Count of tiles which will be visible if show more button is displayed
   */
  @Input() public showMoreInitiallyDisplayedCount: number | Nil = null;

  @Input() public toggleTiles: TileModel<ToggleTileItemModel>[] = [];

  /**
   * Override all container styles from the configuration with a custom style
   * Tiles are displayed in a single row with horizontal scrolling
   * Mainly used for mobile devices
   */
  @Input() public hasHorizontalScroll: boolean = false;

  @Output() public tileClick: EventEmitter<T> = new EventEmitter<T>();

  @Output() public moreClick: EventEmitter<T> = new EventEmitter<T>();

  @Output() public tileToggleChange: EventEmitter<string> = new EventEmitter<string>();

  protected trackByIndexFn: TrackByFunction<TileModel<T>> = trackByIndexFn;

  protected containerDesignClass: string = '';

  protected columnsStyle: string;

  protected readonly SHOW_MORE_ICON: IconModel = {
    source: 'ellipsis-circle-fill',
    type: 'SVG',
    size: 'LG',
    colorCombination: 'PRIMARY_CONTRAST',
  };

  private showMore: boolean = false;

  constructor(
    private readonly responsivenessService: ResponsivenessService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initActiveBreakpointListener();
    this.generateDesignClass();
  }

  public onTileToggleChange(selected: string): void {
    this.tileToggleChange.emit(selected);
  }

  private generateDesignClass(): void {
    if (this.type === 'ONE-LINE') {
      this.containerDesignClass = 'tw-grid-cols-1';
    } else {
      this.containerDesignClass = 'tw-grid-cols-2 tw-gap-2.5';
    }
  }

  public ngOnChanges(changes: AukSimpleChanges<TileContainerComponent<T>>): void {
    if (!isNil(changes.tileList) || !isNil(changes.showMoreThreshold)) {
      this.showMore = false;
    }
    if (changes.type) {
      this.generateDesignClass();
    }
  }

  protected get tiles(): TileModel<T>[] {
    if (this.showMoreThresholdExceeded && !this.showMore) {
      return this.tileList.slice(0, this.showMoreInitiallyDisplayedCount);
    }
    return this.tileList;
  }

  protected defaultMoreTile: TileModel<null> = {
    type: 'IMAGE_TEXT',
    label: { key: 'NAV_TILES_SHOW_MORE_BUTTON_TEXT' },
    defaultIcon: this.SHOW_MORE_ICON,
  };

  protected get moreTile(): TileModel<T> {
    return this.customShowMoreTile ? { ...this.customShowMoreTile } : this.defaultMoreTile;
  }

  protected get lessTile(): TileModel<EmptyObject> {
    return {
      type: 'IMAGE_TEXT',
      backgroundColor: this.tileContainerStyle?.extraTilesStyle?.backgroundColor,
      textColor: this.tileContainerStyle?.extraTilesStyle?.textColor,
      label: { key: 'NAV_TILES_SHOW_LESS_BUTTON_TEXT' },
      defaultIcon: this.SHOW_MORE_ICON,
    };
  }

  protected get showMoreButton(): boolean {
    if (isNil(this.showMoreThreshold) || this.showMore) {
      return false;
    }

    return this.showMoreThresholdExceeded;
  }

  private get showMoreThresholdExceeded(): boolean {
    if (isNil(this.showMoreThreshold)) {
      return false;
    }

    return this.tileList?.length > this.showMoreThreshold;
  }

  protected get showLessButton(): boolean {
    return this.showMore;
  }

  protected onMoreClick(): void {
    if (!isNil(this.customShowMoreTile)) {
      this.tileClick.emit(this.moreTile.data);
    } else {
      this.showMore = true;
    }
  }

  protected onLessClick(): void {
    this.showMore = false;
  }

  protected onTileClick(data: T): void {
    this.tileClick.emit(data);
  }

  private initActiveBreakpointListener(): void {
    this.columnsStyle = this.getColumnStyle(this.responsivenessService.isActiveBreakpointInRange({ min: 'MD' }));
    this.responsivenessService.isActiveBreakpointInRange$({ min: 'MD' })
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isMdBreakpointOrHigher: boolean) => {
        const columnsStyle = this.getColumnStyle(isMdBreakpointOrHigher);
        if (this.columnsStyle !== columnsStyle) {
          this.columnsStyle = columnsStyle;
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  private getColumnStyle(isMdBreakpointOrHigher: boolean): string {
    return isMdBreakpointOrHigher ? this.columnsStyleOnDesktop : this.columnsStyleOnMobile;
  }

  private get columnsStyleOnDesktop(): string {
    const useFullPageWidth: string = this.tileContainerStyle?.useFullPageWidth ? 'fit' : 'fill';
    const minTileWidth: string = this.tileContainerStyle?.minTileWidth || TileConstant.DEFAULT_MIN_TILE_WIDTH;
    return `repeat(auto-${ useFullPageWidth }, minmax(${ minTileWidth }, 1fr))`;
  }

  private get columnsStyleOnMobile(): string {
    const columns: number = this.tileContainerStyle?.columnsOnMobile || TileConstant.DEFAULT_COLUMNS_ON_MOBILE;
    return `repeat(${ columns }, minmax(0, 1fr))`;
  }

}
