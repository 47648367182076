import { UserTaskPersonalDataStepComponent } from './user-task-personal-data-step.component';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslationSource } from '@common/translations/model/translation-source';
import { PlatformService } from '@shared/platform/service/platform.service';
import { DomainService } from '@shared/platform/domain.service';

@Component({
  selector: 'auk-user-task-personal-data-step-phone-verified-email-verified',
  templateUrl: 'user-task-personal-data-step-phone-verified-email-verified.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskPersonalDataStepPhoneVerifiedEmailVerifiedComponent extends
  UserTaskPersonalDataStepComponent<'PHONE_VERIFIED_EMAIL_VERIFIED'> {

  constructor(private readonly platformService: PlatformService,
              private readonly domainService: DomainService) {
    super();
  }

  public get bodyTranslationSource(): TranslationSource {
    return {
      ...this.payload?.body,
      params: {
        ...this.payload?.body?.params,
        app_host: this.platformService.getDomainHost(this.domainService.domain),
      },
    };
  }

  public onCloseButtonClick(): void {
    this.resolve.emit({
      type: 'RESOLUTION_CLOSE',
      payload: {},
    });
  }

  public onSetPasswordButtonClick(): void {
    this.resolve.emit({
      type: 'RESOLUTION_SET_PASSWORD',
      payload: {},
    });
  }

}
