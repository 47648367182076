<span (mouseout)="hideInfo()" (mouseover)="showInfo()">
  <ng-content></ng-content>
</span>
<span class="info-hover">
    <em class="material-icons text-gray cursor-pointer"
        [ngClass]="iconSizeClass"
        (mouseout)="hideInfo()"
        (mouseover)="showInfo()"
    >
      {{ isInfoShow ? 'info_outline' : 'info' }}
    </em>

    <span class="info-hover-cnt">
      <span [aukInnerHtml]="{key: messageKey}" class="info-hover-info"></span>
	  </span>
</span>
