import { Injectable } from '@angular/core';

import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { AppInitializer } from '@util/helper-types/initializer.interface';

@Injectable({
  providedIn: 'root',
})
export class SubbrandInitializer implements AppInitializer {

  constructor(private readonly subbrandService: SubbrandService) {
  }

  public init(): void {
    this.subbrandService.initSessionSubbrandListener();
  }

}
