import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
import { BasicItemCardItemImageModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-item-image.model';
import { ReplaceByPlaceholderOnErrorDirective } from '@common/image-loading/directive/replace-by-placeholder-on-error.directive';

@Component({
  selector: 'auk-item-card-image',
  templateUrl: './item-card-image.component.html',
  styleUrls: ['./item-card-image.component.scss'],
  standalone: true,
  imports: [
    ReplaceByPlaceholderOnErrorDirective,
    NgClass,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCardImageComponent {

  @Input() public itemCardImage: BasicItemCardItemImageModel;
  @Input() public isFreeform: boolean = false;
  @Input() public type: 'loose' | 'tight' = 'tight';
  @Input() public size: 'DEFAULT' | 'COMPACT' = 'DEFAULT';
  @Input() public lazyLoadImage: boolean = true;

}
