import { Injectable } from '@angular/core';
import moment, { duration, Duration, Moment } from 'moment-mini-ts';
import { BehaviorSubject, Observable } from 'rxjs';
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';
import { StringDate } from '@util/helper-types/string-date';

export const MINUTES_THRESHOLD_TO_DISPLAY_SECONDS: number = 5;

@Injectable({
  providedIn: 'root',
})
export class TimeService {

  /**
   * This value is calculated from server time send in WS header
   */
  private _serverTimeDiff$: BehaviorSubject<Duration | Nil> = new BehaviorSubject<Duration>(null);

  public get serverTimeDiff(): Duration {
    return this._serverTimeDiff$.value;
  }

  public set serverTimeDiff(serverTimeDiff: Duration) {
    this._serverTimeDiff$.next(serverTimeDiff);
  }

  public syncServerDiff(serverTime: Moment | Nil, now: Moment = moment()): void {

    if (isNil(serverTime)) {
      return;
    }

    const diffClientServerMilliseconds = serverTime.diff(now);
    this.serverTimeDiff = moment.duration(diffClientServerMilliseconds);
  }

  public get serverTimeDiff$(): Observable<Duration> {
    return this._serverTimeDiff$.asObservable();
  }

  /**
   * Get time NOW adjusted by difference from server time
   */
  public get syncedCurrentTime(): Moment {
    return this.synchronizeClientTime(moment(), this.serverTimeDiff);
  }

  public getRemainingTime(endingTime: Moment | Nil): Duration {
    if (isNil(endingTime)) {
      return duration(0);
    }

    const parsedDuration = moment.duration(endingTime.diff(this.syncedCurrentTime));

    // duration can be negative, if the ending time is sooner than current time
    // so in that case we return 0 duration
    if (parsedDuration.asMilliseconds() <= 0) {
      return duration(0);
    }

    return parsedDuration;
  }

  /**
   * Time adjusted by server time difference
   */
  private synchronizeClientTime(clientTime: Moment, serverTimeDiff: Duration): Moment {
    if (!serverTimeDiff) {
      return clientTime;
    }
    return clientTime.add(serverTimeDiff);
  }

  /**
   * Checks if given dateTime is lower than given threshold
   */
  public isRemainingTimeLowerThanThreshold(
    dateTime: StringDate,
    threshold: Duration,
  ): boolean {
    if (isNil(dateTime) || isNil(threshold)) {
      return false;
    }
    return this.getRemainingTime(moment(dateTime)).asMilliseconds() < threshold.asMilliseconds();
  }

}
