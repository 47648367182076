import { NgModule } from '@angular/core';
import { LimitMaxLengthDirective } from './limit-max-length.directive';

@NgModule({
  declarations: [
    LimitMaxLengthDirective,
  ],
  exports: [
    LimitMaxLengthDirective,
  ],
})
export class LimitMaxLengthDirectiveModule { }
