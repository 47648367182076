import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { ColorCombinationId } from '@common/colors/model/color-combination-id';
import { ToastConfig } from '../model/toast-config';
import { BaseToastComponent } from '../component/base-toast/base-toast.component';
import { ToastRef } from '../model/toast-ref';
import { BaseToastService } from './base-toast.service';
import { take, takeUntil } from 'rxjs/operators';
import { ToastComponentConfig } from '../model/toast-component.config';
import { DEFAULT_TOAST_CONFIG } from '../constant/default-toast-config';
import { ToastContent } from '../model/toast-content';
import { ToastStaticConfig } from '../model/toast-static-config';
import isNil from 'lodash-es/isNil';
import { merge } from 'rxjs';
// eslint-disable-next-line import/no-restricted-paths
import { RoutesService } from '@shared/services/app/routes.service';

@Injectable({
  providedIn: 'root',
})
export class ToastService extends BaseToastService {

  /**
   * Stores every last toast id per toast group, which is used then to limiting visible toasts to only 1 per group
   */
  private lastToastIdPerGroupIdMap: Record<string, number> = {};

  constructor(
    private readonly toastrService: ToastrService,
    private readonly routesService: RoutesService,
  ) {
    super();
  }

  private static setColorCombination(
    colorCombination: ColorCombinationId,
    toastComponent: BaseToastComponent,
    isFirst: boolean = false,
  ): void {
    // set color combination input
    toastComponent.colorCombinationId = colorCombination;
    // ngx-toastr, uses some sort of different page creation,
    // so dynamic input changes are not working
    toastComponent.ngOnChanges({
      colorCombinationId: {
        isFirstChange: () => isFirst,
        firstChange: isFirst,
        currentValue: colorCombination,
        previousValue: toastComponent.colorCombinationId,
      },
    });
  }

  /** @inheritDoc */
  protected showToast<C extends BaseToastComponent = BaseToastComponent>(
    content: ToastContent,
    staticConfig: ToastStaticConfig,
    config?: ToastConfig,
  ): ToastRef<C> | undefined {
    const mergedConfig: ToastComponentConfig = this.getToastComponentConfig(
      content,
      staticConfig,
      config,
    );

    // if there's no config, don't show toast
    if (isNil(mergedConfig)) {
      return;
    }

    const activeToast: ActiveToast<C> = this.toastrService.show() as ActiveToast<C>;

    const toastComponent = activeToast.portal.instance;

    // initialize toast page
    toastComponent.init(mergedConfig);

    // listen to toast page close event
    toastComponent.closeToast
      .pipe(
        takeUntil(
          merge(toastComponent.ngUnsubscribe, this.ngUnsubscribe),
        ),
      )
      .subscribe(() => {
        // close toast
        this.close(activeToast.toastId);
      });

    if (mergedConfig.closeOnUrlChange) {
      this.routesService.routeChange$
        .pipe(
          take(1),
          takeUntil(
            merge(toastComponent.ngUnsubscribe, this.ngUnsubscribe),
          ),
        )
        .subscribe(() => {
          // close toast
          this.close(activeToast.toastId);
        });
    }

    // limit display to one toast of same type
    if (mergedConfig.maxOneToastTypeGroup) {

      const prevToastId = this.lastToastIdPerGroupIdMap?.[mergedConfig.maxOneToastTypeGroup];

      this.lastToastIdPerGroupIdMap[mergedConfig.maxOneToastTypeGroup] = activeToast.toastId;

      if (!isNil(prevToastId)) {
        this.close(prevToastId);
      }
    }

    // set color combination input
    ToastService.setColorCombination(mergedConfig.colorCombination, toastComponent, true);

    return {
      id: activeToast.toastId,
      instance: activeToast.portal.instance,
      setColorCombination: (colorCombination: ColorCombinationId) =>
        ToastService.setColorCombination(colorCombination, toastComponent),
      close: () => this.close(activeToast.toastId),
    };
  }

  /** @inheritDoc */
  public closeAll(): void {
    this.toastrService.clear();
  }

  /** @inheritDoc */
  public close(toastId: number): void {
    this.toastrService.remove(toastId);
  }

  private getToastComponentConfig(
    content: ToastContent,
    staticConfig: ToastStaticConfig,
    config: ToastConfig,
  ): ToastComponentConfig | undefined {
    const toastComponentConfig: ToastComponentConfig = {
      ...DEFAULT_TOAST_CONFIG,
      ...(config ? config : {}),
      ...staticConfig,
    };

    const message = content?.message?.key ?? content?.message?.defaultValue;
    const title = content?.title?.key ?? content?.title?.defaultValue;

    if (isNil(message) && isNil(title)) {
      // return nothing, if there's not set any content
      if (isNil(content?.key) && isNil(content?.defaultValue)) {
        return;
      }
      toastComponentConfig.message = content;
    } else {
      toastComponentConfig.message = content.message;
      toastComponentConfig.title = content.title;
    }

    return toastComponentConfig;
  }

}
