import { NgModule } from '@angular/core';
import { OnlyNumberInputDirective } from './only-number-input.directive';

@NgModule({
  declarations: [
    OnlyNumberInputDirective,
  ],
  exports: [
    OnlyNumberInputDirective,
  ],
})
export class OnlyNumberInputDirectiveModule {
}
