<div class="tw-p-4">
  @if (popularSearchData.title) {
    <p
      class="tw-mb-2 tw-text-tertiary-500"
    >{{ popularSearchData.title }}</p>
  }

  @for (popularSearchLink of popularSearchData.links; track popularSearchLink; let index = $index) {
    <span
      class="tw-px-2 tw-my-0.5 tw-mr-1 tw-rounded tw-bg-surface-neutral-2 hover:tw-bg-surface-neutral-3 tw-text-silverstein-900 tw-cursor-pointer tw-inline-block"
      [ngClass]="(responsivenessService.isMdAndLower$ | async) ? 'tw-py-2.5' : 'tw-py-1.5'"
      (click)="onPopularSearchClick(index)"
      >
      {{ popularSearchLink.title }}
    </span>
  }
</div>
