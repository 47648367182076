<ng-container #isMobile="isMobile"
  aukIsMobile
  >
  <div class="tw-overflow-hidden tw-transition-[max-height, opacity] tw-duration-300"
    [style.max-height.px]="isVisible ? '100' : '0'"
    [style.opacity]="isVisible ? '100' : '0'"
    >
    <div [aukInnerHtml]="isMobile.value ? bannerDeployment.shortText : bannerDeployment.text"
      class="tw-text-center tw-leading-[20px] tw-px-[35px] md:tw-px-[50px]"
      [style.color]="bannerDeployment.textColor"
      [style.background-color]="bannerDeployment.backgroundColor"
      (click)="onContentClick($event)"
    ></div>

    @if (bannerDeployment.manualCloseEnabled) {
      <auk-banner-close-button
        class="tw-absolute tw-top-0 tw-bottom-0 tw-right-[15px] md:tw-right-[30px] tw-m-auto tw-h-[20px]"
        (closeClick)="onCloseClick()"
      ></auk-banner-close-button>
    }
  </div>
</ng-container>
