<div class="tw-relative tw-flex tw-items-center tw-gap-1 tw-px-2 tw-h-8 tw-bg-background-color active:tw-bg-background-color-active tw-rounded-full lg:tw-px-3 lg:tw-h-10 lg:tw-mx-0 lg:hover:tw-bg-background-color-hover"
     [ngClass]="{
       'tw-rounded-lg tw-h-10': !headerItemModel.countdown,
       'tw-bg-background-color-active': isActive,
       'tw-mx-1.5 tw-h-8': headerItemModel.countdown,
     }"
  >
  <!-- ICON -->
  <auk-icon [size]="!headerItemModel.countdown ? 'LG' : responsivenessService.isLgAndLower ? 'MMD' : 'XMD'"
    [source]="headerItemModel.icon.source"
    [iconType]="headerItemModel.icon.type"
    [colorCombination]="headerItemModel.icon.colorCombination"
    />
  <!-- ./ICON -->

  @if (headerItemModel.countdown) {
    <auk-countdown-panel
      panelSize="COMPACT"
      [endingTime]="headerItemModel.countdown"
      class="tw-text-xs tw-font-medium tw-whitespace-nowrap tw-contents"
      />
  }

  <!-- BADGE -->
  @if (headerItemModel.badgeText) {
    <auk-badge
      [badgeText]="headerItemModel.badgeText"
      class="tw-absolute lg:tw--top-0.5"
      [ngClass]="headerItemModel.countdown ? 'tw--top-1.5 tw--right-1.5 lg:tw--right-1' : 'tw--top-0.5 tw-right-0 lg:tw-right-1'"
      />
  }
  <!-- ./BADGE -->

</div>
