<auk-two-factor-verification [requestCallback]="verifyPhoneCallback"
  [showSkipButton]="false">

  <auk-dialog-content
    [dialogTitle]="payload.title"
    >

    <div content>
      @if (payload.body) {
        <div
          class="tw-mb-6 tw-mx-2"
          [aukInnerHtml]="payload.body"
        ></div>
      }

      @if (phoneFormGroup) {
        <form
          class="tw-mt-2 tw-block"
          [formGroup]="phoneFormGroup"
          >
          <div>
            <auk-phone-field class="tw-mb-1 tw-mx-auto tw-w-full"
              [control]="phoneControl"
              [aukLimitMaxLength]="15"
              aukOnlyNumberInput
            ></auk-phone-field>
            @if (phoneFormGroup.touched) {
              <mat-error class="tw-block tw-h-3">
                {{ phoneErrorMessage | translateSource }}
              </mat-error>
            }
          </div>
        </form>
      }
    </div>

    <ng-container actions>
      <auk-button colorCombination="PRIMARY"
        class="tw-w-full"
        [chin]="true"
        [isDisabled]="!phoneFormGroup?.valid || isSubmitInProgress"
        [isLoading]="isSubmitInProgress"
        (buttonClick)="onSubmit()"
        >
        {{ payload.continueButtonText | translateSource }}
      </auk-button>
    </ng-container>

  </auk-dialog-content>

</auk-two-factor-verification>
