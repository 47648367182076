import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { CardPaymentCompleteDto } from '../model/card-payment-complete-dto';
import { CardPaymentCreatedDto } from '../model/card-payment-created-dto';
import { CardPaymentRefundDto } from '../model/card-payment-refund-dto';
import { CardPaymentStatusDto } from '../model/card-payment-status-dto';
import { CreateCardPaymentDto } from '../model/create-card-payment-dto';
import { BASE_PATH } from '../variables';

export interface BarionCallBackRequestParams {
    paymentId: string;
    source?: string;
}

export interface CompletePaymentRequestParams {
    cardPaymentCompleteDto: CardPaymentCompleteDto;
}

export interface CreatePaymentRequestParams {
    createCardPaymentDto: CreateCardPaymentDto;
}

export interface GetStatus1RequestParams {
    orderId: string;
}

export interface RefundCardPaymentRequestParams {
    cardPaymentRefundDto: CardPaymentRefundDto;
}

export interface ValidateApplePaySessionRequestParams {
    sessionRequestUrl: string;
    shopUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class CardPaymentApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Endpoint for Barion callback
   */
  public barionCallBack$(
    requestParameters: BarionCallBackRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentId: requestParameters.paymentId,
      source: requestParameters.source,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/cardPayment/barionCallback`,
      options,
    );
  }

  /**
   * Completes payment in case of Google Pay
   */
  public completePayment$(
    requestParameters: CompletePaymentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.cardPaymentCompleteDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/cardPayment/complete`,
      options,
    );
  }

  /**
   * Creates new card payment.
   */
  public createPayment$(
    requestParameters: CreatePaymentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CardPaymentCreatedDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.createCardPaymentDto,
      responseType: this.configuration.getResponseType(false, 'CardPaymentCreatedDto'),
      headers,
    };
    return this.httpClient.request<CardPaymentCreatedDto>(
      'post',
      `${ BASE_PATH }/cardPayment`,
      options,
    );
  }

  /**
   * Gets latest known payment status without querying a payment provider.
   */
  public getStatus1$(
    requestParameters: GetStatus1RequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CardPaymentStatusDto> {
    const orderId: string = requestParameters.orderId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'CardPaymentStatusDto'),
      headers,
    };
    return this.httpClient.request<CardPaymentStatusDto>(
      'get',
      `${ BASE_PATH }/cardPayment/status/${ this.configuration.encodeParam({ name: 'orderId', value: orderId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * Just sets finished status of sending Payment via Aukro back to buyer.
   */
  public refundCardPayment$(
    requestParameters: RefundCardPaymentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.cardPaymentRefundDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/cardPayment/refund`,
      options,
    );
  }

  /**
   * Apple pay validation session
   */
  public validateApplePaySession$(
    requestParameters: ValidateApplePaySessionRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      sessionRequestUrl: requestParameters.sessionRequestUrl,
      shopUrl: requestParameters.shopUrl,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'get',
      `${ BASE_PATH }/cardPayment/applepay/validateSession`,
      options,
    );
  }

}
