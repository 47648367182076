import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { StringUtils } from '@util/util/string.utils';

type AnalyticsNameTrackingType = 'button' | 'textarea' | 'input' | 'select';

/**
 * Used to apply custom attribute on element which is used for tracking purposes (currently Zuko)
 */
@Directive({
  selector: '[aukAnalyticsTracking]',
  standalone: true,
})
export class AnalyticsTrackingDirective implements OnChanges, OnInit {

  /**
   * Identifier which will be used as value for the custom tracking element
   */
  @Input() public aukAnalyticsTracking: AnalyticsNameTrackingType | '' = 'button';

  /**
   * This is the name of the CustomEvent which is fired on the element on which this directive is set
   * @private
   */
  private readonly customTrackEventName: string = 'aukTrackEvent';

  constructor(
    private readonly renderer2: Renderer2,
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {
  }

  public ngOnInit(): void {
    this.setCustomAttributeTagName();
  }

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (changes.aukAnalyticsTracking) {
      this.setCustomAttributeTagName();
    }
  }

  private setCustomAttributeTagName(): void {
    this.renderer2.setAttribute(
      this.elementRef.nativeElement,
      'data-zuko-tag-name',
      StringUtils.isEmpty(this.aukAnalyticsTracking)
        // use `button` name if @Input() is empty/Nil
        ? 'button'
        : this.aukAnalyticsTracking,
    );
  }

  /**
   * Registers click host listener on this element and everytime fires CustomEvent which will be
   * caught by the 3rd party tracking lib
   * @param event
   */
  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    // don't fire event, if the element which fired event equals to the same element where the click event listener is registered
    // otherwise 3rd party tracking lib would register this event twice
    if (event.target === event.currentTarget) {
      return;
    }

    const customEvent = new CustomEvent(
      this.customTrackEventName,
      {
        // we want this event to bubble, so it can be caught in event listener on DOM body element
        bubbles: true,
      });

    this.elementRef.nativeElement.dispatchEvent(customEvent);
  }

}
