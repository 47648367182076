import { Inject, Injectable } from '@angular/core';
import { Nil } from '@util/helper-types/nil';
import { WINDOW_OBJECT } from '@util/const/window-object';

/**
 * Service for work with browser's local storage.
 *
 * Try/catch in set and get methods is needed in situations,
 * when user has disallowed their storages or storages are unavailable.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
  ) {
  }

  private get localStorage(): Storage {
    return this.window.localStorage;
  }

  public getKeys(): string[] {
    try {
      return Object.keys(this.window.localStorage) ?? [];
    } catch (e) {
      return [];
    }
  }

  public setItem(key: string, data: unknown): void {
    try {
      this.localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      // do nothing
    }
  }

  public getItem<T = unknown>(
    key: string,
    /**
     * Whether item should be removed if item failed to parse
     */
    removeOnError: boolean = false,
  ): Nil | T {
    try {
      return JSON.parse(this.localStorage.getItem(key)) as T;
    } catch (error) {
      if (removeOnError) {
        this.removeItem(key);
      }
      return null;
    }
  }

  public removeItem(key: string): void {
    try {
      this.localStorage.removeItem(key);
    } catch (error) {
      // do nothing
    }
  }

}
