<a [id]="source" class="tw-flex tw-items-center tw-text-tertiary-600 hover:tw-no-underline visited:tw-text-tertiary-600"
  [ngClass]="{'hover:tw-text-info': changeColorOnHover }"
  [aukLinkFromHref]="hrefUrl | translateSource"
  [openInNewTab]="true"
  >
  <div class="tw-bg-secondary tw-rounded-full tw-p-3 tw-inline-flex tw-mr-1.5">
    <auk-icon [source]="source"
      [colorCombination]="colorCombination"
      iconType="SVG"
      size="LG">
    </auk-icon>
  </div>
  @if (text) {
    <p class="tw-hidden md:tw-block tw-text-md tw-leading-none tw-mr-4">{{ text | translateSource }}</p>
  }
</a>

