import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ItemsParams {
  /**
   * ItemsCollection ID
   * format: int64
   */
  id: number;
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface RegistrationStepsUsingGETParams {
  /**
   * Items collection ID
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface RegisterUsingPOSTParams {
  /**
   * Items collection ID
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CurrentParams {
  /**
   * ItemsCollection ID
   * format: int64
   */
  itemsCollectionId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root'
})
export class ItemsCollectionService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public items(params: ItemsParams): Observable<model.ItemsCollectionItemCarouselDtoPage> {
    let pathParams = {
      id: params.id,
    };
    let queryParams = {
      page: params.page,
      size: params.size,
    };
    return this.getGenerated(`/itemsCollection/${pathParams.id}/items`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public registrationStepsUsingGET(params: RegistrationStepsUsingGETParams): Observable<model.ItemsCollectionRegistrationStatusDto> {
    let pathParams = {
      id: params.id,
    };
    let queryParams;
    return this.getGenerated(`/itemsCollection/${pathParams.id}/registration`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public registerUsingPOST(params: RegisterUsingPOSTParams, headers: { [key: string]: string } = {}): Observable<model.ItemsCollectionRegistrationStatusDto> {
    let pathParams = {
      id: params.id,
    };
    let bodyParams;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/itemsCollection/${pathParams.id}/registration`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public current(params: CurrentParams): Observable<model.ItemIdAndSeoUrlDto> {
    let pathParams = {
      itemsCollectionId: params.itemsCollectionId,
    };
    let queryParams;
    return this.getGenerated(`/itemsCollection/${pathParams.itemsCollectionId}/items/current`, queryParams);
  }
}
