import { Injectable } from '@angular/core';
import { BannerDeploymentDto } from '@api/generated/defs/BannerDeploymentDto';
import { BannerPlacementDto } from '@api/generated/defs/BannerPlacementDto';
import { BannerPositionDto } from '@api/generated/defs/BannerPositionDto';
import { BrowserService } from '@shared/platform/browser.service';
import isNil from 'lodash-es/isNil';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { BannerConstants } from '@shared/banner/domain/banner.constants';
import { GaBannerModel } from '@shared/google-analytics/model/ga-banner.model';
import { GaTrackEventModel } from '@shared/google-analytics/model/ga-track-event.model';
import { GaBannerTrackEventModel } from '@shared/google-analytics/model/ga-banner-track-event.model';

@Injectable({
  providedIn: 'root',
})
export class BannerGoogleAnalyticsService {

  constructor(
    private readonly browserService: BrowserService,
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
  ) {
  }

  public trackBannerClick(
    bannerPlacement: BannerPlacementDto,
    bannerPosition: BannerPositionDto,
    bannerDeployment: BannerDeploymentDto,
  ): Promise<GaTrackEventModel> {
    return this.googleAnalyticsTrackingService
      .trackEvent(() => this.createTrackBannerClickEvent(bannerPlacement, bannerPosition, bannerDeployment));
  }

  public trackBannerView(bannerPlacements: BannerPlacementDto[]): Promise<GaTrackEventModel> {
    return this.googleAnalyticsTrackingService.trackEvent(() => this.createTrackBannerViewEvent(bannerPlacements));
  }

  /**
   * Create an event object for tracking the banner click. The event is specific by event name 'promotionClick'
   * and has other promotion-related attributes containing also the names of banner and banner position.
   * @param bannerPlacement
   * @param bannerPosition
   * @param bannerDeployment
   */
  private createTrackBannerClickEvent(
    bannerPlacement: BannerPlacementDto,
    bannerPosition: BannerPositionDto,
    bannerDeployment: BannerDeploymentDto,
  ): Promise<GaTrackEventModel> {
    const event: GaBannerTrackEventModel = {
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [this.getPromotion(bannerPlacement, bannerPosition, bannerDeployment)],
          deploymentId: bannerPlacement?.bannerPositions?.[0]?.bannerDeployments?.[0]?.deploymentId,
        },
      },
      eventCallback: () => {
        if (!isNil(bannerDeployment.destinationUrl)) {
          this.browserService.goToUrl(bannerDeployment.destinationUrl);
        }
      },
    };

    return this.googleAnalyticsTrackingService.createTrackPromise(event);
  }

  /**
   * Create an event object for tracking the banner view. The event is specific by event name 'promotionView'
   * and has other promotion-related attributes containing also the names of banner and banner position.
   * @param bannerPlacements
   */
  private createTrackBannerViewEvent(
    bannerPlacements: BannerPlacementDto[],
  ): Promise<GaTrackEventModel> {
    const promotions: GaBannerModel[] = [];

    bannerPlacements.forEach((bannerPlacement: BannerPlacementDto) => {
      bannerPlacement.bannerPositions.forEach((bannerPosition: BannerPositionDto) => {
        if (bannerPosition.bannerDeployments[0]) {
          promotions.push(this.getPromotion(bannerPlacement, bannerPosition, bannerPosition.bannerDeployments[0]));
        }
      });
    });

    const event: GaBannerTrackEventModel = {
      event: 'promotionView',
      ecommerce: {
        promoView: {
          promotions,
        },
      },
    };

    return this.googleAnalyticsTrackingService.createTrackPromise(event);
  }

  private getPromotion(
    bannerPlacement: BannerPlacementDto,
    bannerPosition: BannerPositionDto,
    bannerDeployment: BannerDeploymentDto,
  ): GaBannerModel {
    return {
      id: BannerConstants.BANNER_PROMOTION_ID,
      name: BannerConstants.BANNER_PROMOTION_CAMPAIGN,
      creative: bannerDeployment.bannerName,
      position: this.getBannerPosition(bannerPlacement, bannerPosition),
    };
  }

  private getBannerPosition(bannerPlacement: BannerPlacementDto, bannerPosition: BannerPositionDto): string {
    return bannerPlacement.placementName.endsWith(bannerPosition.positionName)
      ? bannerPlacement.placementName
      : bannerPlacement.placementName + ' ' + bannerPosition.positionName;
  }

}
