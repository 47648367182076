import { I18nPluralPipe } from '@angular/common';
import { UnitCodeMappings } from '../model/unit-code-mappings';
import { UNIT_CODE_MAPPINGS_SK } from '../constant/unit-code-mappings-sk';
import { UNIT_CODE_MAPPINGS_CS } from '../constant/unit-code-mappings-cs';
// eslint-disable-next-line import/no-restricted-paths
import { Lang } from '@shared/platform/lang.const';

const UNIT_CODE_MAP_IMPORTS: Record<Lang, UnitCodeMappings> = {
  cs: UNIT_CODE_MAPPINGS_CS,
  sk: UNIT_CODE_MAPPINGS_SK,
};

export class UnitUtil {

  public static unitPluralize(
    value: string,
    count: number,
    i18nPluralPipe: I18nPluralPipe,
    lang: Lang = 'cs',
  ): string {
    const UNIT_CODE_MAP = UNIT_CODE_MAP_IMPORTS[lang];
    if (value !== null && value !== '') {
      let map = UNIT_CODE_MAP[value];
      if (typeof map === 'string') {
        map = UNIT_CODE_MAP[map];
      }
      if (typeof map === 'object') {
        return i18nPluralPipe.transform(count, map);
      } else {
        throw new Error(`Invalid Data in unit-code-mapping: ${ typeof map }`);
      }
    }

    return value;
  }

}
