import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface BreadcrumbsParams {
  /**
   * Category ID
   * format: int64
   */
  categoryId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ActiveTreePathParams {
  /**
   * Category ID
   * format: int64
   */
  categoryId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface AttributesParams {
  /**
   * Cateogry ID
   * format: int64
   */
  categoryId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface CategoryParams {
  /**
   * Category ID
   * format: int64
   */
  categoryId: number;

  onlyExposable?: boolean;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SubcategoriesParams {
  /**
   * Category ID
   * format: int64
   */
  categoryId: number;

  onlyExposable?: boolean;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface TreePathParams {
  /**
   * Category ID
   * format: int64
   */
  categoryId: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface GetSuggestedShippingPricesForCategoryUsingGETParams {
  /**
   * categoryId
   * format: int64
   */
  categoryId: number;
  /**
   * maxRank
   * format: int32
   */
  maxRank: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SuggestCategoryByOfferNameUsingPOSTParams {
  /** Offer name (at least 3 chars) */
  itemName: string;
  /** suggestCategoriesDto */
  suggestCategoriesDto: model.SuggestCategoriesDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root'
})
export class OfferCategoryService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public all(): Observable<model.CategoryMapNode[]> {
    return this.getGenerated(`/categories/aggregations/all`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public root(): Observable<model.CategoryMapNode[]> {
    return this.getGenerated(`/categories/aggregations/root`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public breadcrumbs(params: BreadcrumbsParams): Observable<model.BreadcrumbDto> {
    let pathParams = {
      categoryId: params.categoryId,
    };
    let queryParams;
    return this.getGenerated(`/categories/breadcrumbs/${pathParams.categoryId}`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public activeTreePath(params: ActiveTreePathParams): Observable<model.ItemCategorySeoDto[]> {
    let pathParams = {
      categoryId: params.categoryId,
    };
    let queryParams;
    return this.getGenerated(`/categories/categories/${pathParams.categoryId}/activeTreePath`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public attributes(params: AttributesParams): Observable<model.ItemAttributeDto[]> {
    let pathParams = {
      categoryId: params.categoryId,
    };
    let queryParams;
    return this.getGenerated(`/categories/categories/${pathParams.categoryId}/attributes`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public category(params: CategoryParams): Observable<model.ItemCategoryDto> {
    let pathParams = {
      categoryId: params.categoryId,
    };
    let queryParams = {
      onlyExposable: params.onlyExposable,
    };
    return this.getGenerated(`/categories/categories/${pathParams.categoryId}`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public subcategories(params: SubcategoriesParams): Observable<model.ItemCategoryDto[]> {
    let pathParams = {
      categoryId: params.categoryId,
    };
    let queryParams = {
      onlyExposable: params.onlyExposable,
    };
    return this.getGenerated(`/categories/categories/${pathParams.categoryId}/subcategories`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public subcategoriesWithItemsCount(params: SubcategoriesParams): Observable<model.ItemCategoryWithItemCountDto[]> {
    let pathParams = {
      categoryId: params.categoryId,
    };
    return this.getGenerated(`/categories/categories/${pathParams.categoryId}/subcategoriesWithItemsCount`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public treePath(params: TreePathParams): Observable<model.ItemCategorySeoDto[]> {
    let pathParams = {
      categoryId: params.categoryId,
    };
    let queryParams;
    return this.getGenerated(`/categories/categories/${pathParams.categoryId}/treePath`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public rootCategories(): Observable<model.ItemCategoryDto[]> {
    return this.getGenerated(`/categories/rootCategories`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public topSearchLevelCategoriesBySubbrand(): Observable<model.ItemCategoryDto[]> {
    return this.getGenerated(`/categories/topSearchLevelCategoriesBySubbrand`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getSuggestedShippingPricesForCategoryUsingGET(params: GetSuggestedShippingPricesForCategoryUsingGETParams): Observable<model.ItemCategoryShippingSuggestedDto[]> {
    let queryParams = {
      categoryId: params.categoryId,
      maxRank: params.maxRank,
    };
    return this.getGenerated(`/categories/shipping/suggest`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public suggestCategoryByOfferNameUsingPOST(params: SuggestCategoryByOfferNameUsingPOSTParams, headers: { [key: string]: string } = {}): Observable<model.SuggestCategoriesResponseDto> {
    let queryParams = {
      itemName: params.itemName,
    };
    let bodyParams = params.suggestCategoriesDto;
    let formDataParams;
    return this.postGenerated(`/categories/suggest`, bodyParams, queryParams, formDataParams, headers);
  }
}
