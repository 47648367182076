import { Observable } from 'rxjs';
import { UserActionPrerequisiteType } from '../../model/user-action-prerequisite.type';
import { UserActionType } from '../../model/user-action.type';
import { FulfillPrerequisiteResultModel } from '../../model/fulfill-prerequisite-result.model';
import { PrerequisiteFulfillmentInterface } from '../../interface/prerequisite-fulfillment.interface';
import { UserActionPrerequisiteModel } from '../../model/user-action-prerequisite.model';

export abstract class UserActionPrerequisiteFulfillmentService<PREREQUISITE_COMBINATION extends UserActionPrerequisiteType[]>
implements PrerequisiteFulfillmentInterface<PREREQUISITE_COMBINATION> {

  public abstract readonly prerequisiteCombination: PREREQUISITE_COMBINATION;

  public abstract fulfillPrerequisite(
    actionType: UserActionType,
    payload?:  UserActionPrerequisiteModel[]
  ): Observable<FulfillPrerequisiteResultModel | void>;

}
