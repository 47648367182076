import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import * as model from '../model';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface AddParams {
  /**
   * clipboardId
   * format: int64
   */
  clipboardId: number;
  /** data */
  data: model.ClipboardAddDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ItemsParams {
  /**
   * Page number of the requested page
   * format: int32
   */
  page?: number;
  /**
   * Size of a page
   * format: int32
   */
  size?: number;
  /** filter */
  filter: model.ClipboardItemFilterDto;
  /**
   * clipboardId
   * format: int64
   */
  clipboardId: number;
  /** Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
  sort?: string[];
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root',
})
export class ClipboardResourceService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getClipboardsUsingGET(): Observable<model.ClipboardDto[]> {
    return this.getGenerated(`/clipboard`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public add(params: AddParams): Observable<void> {
    let pathParams = {
      clipboardId: params.clipboardId,
    };
    let bodyParams = params.data;
    let queryParams;
    let formDataParams;
    return this.putGenerated(`/clipboard/${pathParams.clipboardId}/add`, bodyParams, queryParams, formDataParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public items(params: ItemsParams): Observable<model.ClipboardItemResultDto> {
    let queryParams = {
      page: params.page,
      size: params.size,
      sort: params.sort,
    };
    let bodyParams = params.filter;
    let pathParams = {
      clipboardId: params.clipboardId,
    };
    let formDataParams;
    return this.postGenerated(`/clipboard/${pathParams.clipboardId}/search/items`, bodyParams, queryParams, formDataParams);
  }
}
