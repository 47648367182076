/**
 * Unit maps for key from API to czech strings including plurals
 */
export const UNIT_CODE_MAPPINGS_CS = {
  PAIR: {
    '=0': 'párů',
    '=1': 'pár',
    '=2': 'páry',
    '=3': 'páry',
    '=4': 'páry',
    other: 'párů',
  },
  PAIRS: 'PAIR',
  PIECE: {
    '=0': 'kusů',
    '=1': 'kus',
    '=2': 'kusy',
    '=3': 'kusy',
    '=4': 'kusy',
    other: 'kusů',
  },
  PIECES: 'PIECE',
  PIECE_SHORT: {
    other: 'ks',
  },
  SET: {
    '=0': 'sad',
    '=1': 'sada',
    '=2': 'sady',
    '=3': 'sady',
    '=4': 'sady',
    other: 'sad',
  },
  SETS: 'SET',
  BUY: {
    '=0': 'koupilo',
    '=1': 'koupil',
    '=2': 'koupili',
    '=3': 'koupili',
    '=4': 'koupili',
    other: 'koupilo',
  },
  BUYS: 'BUY',
  BET: {
    '=1': 'přihazuje',
    '=2': 'přihazují',
    '=3': 'přihazují',
    '=4': 'přihazují',
    other: 'přihazuje',
  },
  BETS: 'BET',
  BET_PAST: {
    '=1': 'přihazoval',
    '=2': 'přihazovali',
    '=3': 'přihazovali',
    '=4': 'přihazovali',
    other: 'přihazovalo',
  },
  BETS_PAST: 'BET_PAST',
  ITEM: {
    '=0': 'položek',
    '=1': 'položku',
    '=2': 'položky',
    '=3': 'položky',
    '=4': 'položky',
    other: 'položek',
  },
  ITEMS: 'ITEM',
  DAY: {
    '=0': 'dní',
    '=1': 'den',
    '=2': 'dny',
    '=3': 'dny',
    '=4': 'dny',
    other: 'dní',
  },
  DAYS: 'DAY',
  DAY_LOKATIV: {
    '=0': 'dní',
    '=1': 'dni',
    '=2': 'dnech',
    '=3': 'dnech',
    '=4': 'dnech',
    other: 'dnech',
  },
  DAYS_LOKATIV: 'DAY_LOKATIV',
  HOUR: {
    '=0': 'hodin',
    '=1': 'hodina',
    '=2': 'hodiny',
    '=3': 'hodiny',
    '=4': 'hodiny',
    other: 'hodin',
  },
  HOURS: 'HOUR',
  MINUTE: {
    '=0': 'minut',
    '=1': 'minuta',
    '=2': 'minuty',
    '=3': 'minuty',
    '=4': 'minuty',
    other: 'minut',
  },
  MINUTES: 'MINUTE',
  SECOND: {
    '=0': 'sekund',
    '=1': 'sekunda',
    '=2': 'sekundy',
    '=3': 'sekundy',
    '=4': 'sekundy',
    other: 'sekund',
  },
  SECONDS: 'SECOND',
  OTHER: {
    '=0': 'další',
    '=1': 'další',
    '=2': 'další',
    '=3': 'další',
    '=4': 'další',
    other: 'dalších',
  },
  OTHERS: 'OTHER',
  FILE: {
    '=0': 'souborů',
    '=1': 'soubor',
    '=2': 'soubory',
    '=3': 'soubory',
    '=4': 'soubory',
    other: 'souborů',
  },
  FILES: 'FILE',
  IMAGE: {
    '=0': 'obrázků',
    '=1': 'obrázek',
    '=2': 'obrázky',
    '=3': 'obrázky',
    '=4': 'obrázky',
    other: 'obrázků',
  },
  IMAGES: 'IMAGE',
  OFFER: {
    '=0': 'nabídek',
    '=1': 'nabídka',
    '=2': 'nabídky',
    '=3': 'nabídky',
    '=4': 'nabídky',
    other: 'nabídek',
  },
  OFFERS: 'OFFER',
  RESULT: {
    '=0': 'výsledků',
    '=1': 'výsledek',
    '=2': 'výsledky',
    '=3': 'výsledky',
    '=4': 'výsledky',
    other: 'výsledků',
  },
  RESULTS: 'RESULT',
  POSITIVE_COMMENT: {
    '=0': 'pozitivních hodnocení',
    '=1': 'pozitivní hodnocení',
    '=2': 'pozitivní hodnocení',
    '=3': 'pozitivní hodnocení',
    '=4': 'pozitivní hodnocení',
    other: 'pozitivních hodnocení',
  },
  POSITIVE_COMMENTS: 'POSITIVE_COMMENT',
  USER_GENITIV: {
    '=0': 'uživatelů',
    '=1': 'uživatele',
    '=2': 'uživatelů',
    '=3': 'uživatelů',
    '=4': 'uživatelů',
    other: 'uživatelů',
  },
  USERS_GENITIV: 'USER_GENITIV',
  USER_INSTRUMENTAL: {
    '=0': 'uživatelem',
    '=1': 'uživatelem',
    other: 'uživateli',
  },
  USERS_INSTRUMENTAL: 'USER_INSTRUMENTAL',
  NEW_EXPOSED_OFFER: {
    '=0': 'včera vystavených',
    '=1': 'včera vystavená',
    '=2': 'včera vystavené',
    '=3': 'včera vystavené',
    '=4': 'včera vystavené',
    other: 'včera vystavených',
  },
  NEW_EXPOSED_OFFERS: 'NEW_EXPOSED_OFFER',
  EXPOSED_OFFER: {
    '=0': 'vystavených',
    '=1': 'vystavenou',
    '=2': 'vystavené',
    '=3': 'vystavené',
    '=4': 'vystavené',
    other: 'vystavených',
  },
  EXPOSED_OFFERS: 'EXPOSED_OFFER',
  OFFER_AKUZATIV: {
    '=0': 'nabídek',
    '=1': 'nabídku',
    '=2': 'nabídky',
    '=3': 'nabídky',
    '=4': 'nabídky',
    other: 'nabídek',
  },
  OFFERS_AKUZATIV: 'OFFER_AKUZATIV',
  SELLER_GENITIV: {
    '=0': 'prodejců',
    '=1': 'prodejce',
    '=2': 'prodejců',
    '=3': 'prodejců',
    '=4': 'prodejců',
    other: 'prodejců',
  },
  SELLERS_GENITIV: 'SELLER_GENITIV',
  ADDED_PASSIVE: {
    '=0': 'přidáno',
    '=1': 'přidána',
    '=2': 'přidány',
    '=3': 'přidány',
    '=4': 'přidány',
    other: 'přidáno',
  },
  CHARS: {
    '=0': 'znaků',
    '=1': 'znak',
    '=2': 'znaky',
    '=3': 'znaky',
    '=4': 'znaky',
    other: 'znaků',
  },
  IN_LOKATIV: {
    '=0': 'v',
    '=1': 'v',
    '=2': 've',
    '=3': 've',
    '=4': 've',
    other: 'v',
  },
};
