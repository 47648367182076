@if ((routesService.isSimpleExposePage$ | async) === false) {
  <!-- DISCLAIMERS -->
  <auk-disclaimers *aukHideOnMobileSsr></auk-disclaimers>
  <!-- ./DISCLAIMERS -->
  <ng-container #isMobile="isMobile" aukIsMobile>
    @if (displayUpdateBanner) {
      <div class="alert-panel flex-center"
        (click)="hideUpdateBanner()"
        [class.column]="isMobile.value"
        [aukInnerHtml]="{ key: 'SW_UPDATE_BANNER_CONTENT' }">
      </div>
    }
  </ng-container>
  @if (hasBanner) {
    <div class="container has-banner">
      @if (outage?.message) {
        <auk-alert-banner-legacy
          type="WARNING"
          >
          <div [aukInnerHtml]="outage.message"></div>
        </auk-alert-banner-legacy>
      }
      @if (blockBannerType) {
        <auk-alert-banner-legacy type="WARNING">
          @switch (blockBannerType) {
            @case ('BLOCK') {
              @if (isBlockedDueToDuplicatePhone) {
                <div
                [aukInnerHtml]="{ key: 'ACCOUNT_BLOCKED_DUPLICATE_PHONE_WARN_TEXT' }"></div>
              } @else {
                <div [aukInnerHtml]="{key:'ACCOUNT_BLOCKED_WARN_TEXT'}"></div>
              }
            }
            @case ('LIGHT_BLOCK') {
              <div [aukInnerHtml]="{ key: 'ACCOUNT_LIGHT_BLOCKED_WARN_TEXT' }"></div>
            }
          }
        </auk-alert-banner-legacy>
      }
      @if (showMalformedEmailBanner) {
        <auk-alert-banner-legacy type="WARNING">
          <span [aukInnerHtml]="{ key: 'MALFORMED_EMAIL_WARNING' }"></span>
        </auk-alert-banner-legacy>
      }
      @if (showDac7DataValidationRequired) {
        <auk-alert-banner-legacy type="WARNING">
          <span [aukInnerHtml]="{ key: 'BANNER_USER_NOT_FILLED_FULL_DATA', params: { destination: routeToEditPersonalDataByUserType} }"></span>
        </auk-alert-banner-legacy>
      }
      @if (showCrossDomainLoginWarning) {
        <auk-alert-banner-legacy type="WARNING">
          <span [aukInnerHtml]="{ key: 'SYSTEM_BANNER_CROSS_DOMAIN_SING_IN' }"></span>
        </auk-alert-banner-legacy>
      }
      @if (showUnconfirmedRegistrationBanner) {
        <auk-alert-banner-legacy type="WARNING">
          <span [aukInnerHtml]="{ key: 'CHECK_EMAIL_DUE_TO_UNCONFIRMED_REGISTRATION_PART_1' }"></span>
          <a aukPreventDefaultLink
            (click)="sendConfirmationEmailAgain()"
            [aukInnerHtml]="{ key: 'CHECK_EMAIL_DUE_TO_UNCONFIRMED_REGISTRATION_PART_2' }"
          ></a>
        </auk-alert-banner-legacy>
      }
      @if (showSetPasswordBanner) {
        <auk-alert-banner-legacy type="WARNING">
          <span [aukInnerHtml]="{ key: 'SET_PASSWORD_BANNER_TEXT' }"></span>
        </auk-alert-banner-legacy>
      }
      @if (showPhoneVerificationRequiredBanner) {
        <auk-alert-banner-legacy type="WARNING">
          <span
            [aukInnerHtml]="{ key: 'PHONE_VERIFICATION_REQUIRED_BANNER_TEXT' }"
          ></span>&nbsp;<a
          aukPreventDefaultLink
          (click)="onPhoneVerificationEndingClick()"
        >{{ { key: 'PHONE_VERIFICATION_ALERT_ENDING' } | translateSource }}</a>.
      </auk-alert-banner-legacy>
    }
    @if (pvaBanner) {
      <auk-alert-banner-legacy type="WARNING">
        <span [aukInnerHtml]="{ key: pvaBanner.translationKey, params: { price: pvaBanner.totalPrice | currency, offerId: pvaBanner.offerId } }"></span>
      </auk-alert-banner-legacy>
    }
  </div>
}
}
