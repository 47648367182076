import { NgModule } from '@angular/core';
import { UnitPluralizePipe } from './pipe/unit-pluralize.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    UnitPluralizePipe,
  ],
  exports: [
    UnitPluralizePipe,
  ],
  providers: [
    UnitPluralizePipe,
  ],
})
export class UnitPluralizeModule {
}
