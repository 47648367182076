import { CurrencyEnumParams } from '@api/generated/api/Currency';

export type JsonLdType =
  'ORGANIZATION'
  | 'LISTING'
  | 'PRODUCT'
  | 'SUGGESTIONS'
  | 'BREADCRUMBS'
  | 'SALE';

export type JsonLdStructure =
  JsonLdListing
  | JsonLdOrganization
  | JsonLdWebPage
  | JsonLdBreadcrumbs
  | JsonLdOfferDetail
  | JsonLdSale;

export interface JsonLdScriptEntry {
  id: string;
  value: string;
}

/* common */

export interface JsonLdInstrument {
  '@type': string;
  name: string;
  actionOption: number | string | string[];
}

export type JsonLdListItem = {
  position: number;
} & JsonLdOfferDetail;

export interface JsonLdListing {
  '@context': string;
  '@type': string;
  result: {
    '@type': string;
    name: string;
    itemListElement: JsonLdListItem[];
  };
  query?: string;
  instrument: JsonLdInstrument[];
}

export interface JsonLdImage {
  '@type': 'ImageObject';
  contentUrl: string;
  caption: string;
  representativeOfPage: boolean;
  uploadDate: string;
  author: {
    '@type': 'Person';
    alternateName: string;
  };
}

export interface JsonLdAddress {
  '@type': 'PostalAddress';
  addressCountry: 'CZ';
  addressLocality: string;
  postalCode: string;
  addressRegion?: string;
  streetAddress?: string;
}

export enum JsonLdItemCondition {
  NEW_CONDITION = 'http://schema.org/NewCondition',
  USED_CONDITION = 'http://schema.org/UsedCondition',
}

export enum JsonLdAvailability {
  IN_STOCK = 'http://schema.org/InStock',
  DISCONTINUED = 'http://schema.org/Discontinued',
  SOLD_OUT = 'http://schema.org/SoldOut',
}

/* JsonLdOrganization */

export interface JsonLdOrganization {
  '@context': 'https://schema.org';
  '@type': 'Organization';
  name: string;
  url: string;
  sameAs: string[];
  logo: string;
  image: string;
  description: string;
  address: JsonLdAddress;
  areaServed: {
    '@type': string;
    geo: {
      '@type': string;
      latitude: string;
      longitude: string;
    };
    hasMap: string;
    openingHoursSpecification: JsonLdOpeningHoursSpecification[];
  };
  contactPoint: {
    '@type': string;
    telephone: string;
    contactType: string;
  };
}

export interface JsonLdOpeningHoursSpecification {
  '@type': string;
  closes: string;
  dayOfWeek: string;
  opens: string;
}

/* JsonLdWebPage */

export interface JsonLdWebPage {
  '@context': string;
  '@type': string;
  name: string;
  url: string;
  mainEntity: {
    '@type': string;
    offers: {
      '@type': string;
      itemOffered: JsonLdOfferDetail[];
    };
  };
}

/* JsonLdBreadcrumbs */

export interface JsonLdBreadcrumbsListItem {
  '@type': 'ListItem';
  position: number;
  name: string;
  item?: string;
}

export interface JsonLdBreadcrumbs {
  '@context': 'http://schema.org';
  '@type': 'BreadcrumbList';
  itemListElement: JsonLdBreadcrumbsListItem[];
}

/* JsonLdOfferDetail */

export interface JsonLdOfferDetailBrand {
  '@type': 'Brand';
  name: string;
}

export interface JsonLdOfferDetailOffer {
  '@type': 'Offer';
  availability?: JsonLdAvailability;
  price: number;
  priceCurrency: CurrencyEnumParams;
  url?: string;
  category?: string;
}

type JsonLdOfferDetailType = 'Product' | 'ListItem' | 'CategorySearch';

export interface JsonLdOfferDetail {
  '@context': 'http://schema.org';
  '@type': JsonLdOfferDetailType | JsonLdOfferDetailType[];
  name?: string;
  image?: JsonLdImage;
  category?: string;
  offers?: JsonLdOfferDetailOffer;
  description?: string;
  brand?: JsonLdOfferDetailBrand;
  sku?: string;
  itemCondition?: JsonLdItemCondition;
  url?: string;
}

/* JsonLdSale */

export interface JsonLdSale {
  '@context': 'http://schema.org';
  '@type': 'SaleEvent';
  name: string;
  description: string;
  image: JsonLdImage;
  startDate: string;
  endDate: string;
  url: string;
  location: {
    '@type': 'Place';
    name: string;
    address: JsonLdAddress;
  };
}

/* constants */

export const STATE_OF_GOODS_NEW: string = '1';
export const STATE_OF_GOODS_USED: string = '2';
// SELECT "id" FROM "item_attribute" WHERE "name" ILIKE '%značka%'
export const BRAND_ATTRIBUTE_IDS: number[] =
  [3668, 1474, 3927, 4066, 5688, 5708, 5728, 1534, 1574, 9744, 9747, 9749, 9753, 9764, 9785, 9787,
    9790, 9793, 9797, 9800, 9804, 9834, 9841, 9850, 9852, 9854, 9856, 9859, 9864, 9867, 9870,
    9875, 9905, 9911, 9916, 9918, 9923, 9928, 9945, 9948, 9957, 9979, 9989, 10002, 10054, 10058, 10064,
    10065, 10083, 10085, 10090, 10095, 10097, 10099, 10103, 10108, 10118, 10121, 10133, 10140, 10145,
    10170, 10178, 10183, 10187, 10191, 10196, 10204, 10206, 10212, 10218, 10223, 10227, 10232, 10235,
    10239, 10245, 10253, 10257, 10264, 10267, 10269, 10271, 10283, 10303, 10308, 10312, 10315, 10318,
    10323, 10329, 10333, 10336, 10339, 10343, 10346, 10353, 10360, 10364, 10386, 10387, 10392, 10397,
    10403, 10447, 10470, 10471, 10478, 10483, 10489, 10495, 10498, 10505, 10513, 10584, 10591, 10609,
    10617, 10703, 10383, 10426, 10463, 11872, 12748, 12788, 12789, 12790, 12791, 12792, 12808, 12809,
    874, 15308, 1614, 8495, 11873, 25788, 25808, 25848, 25888, 25908, 25928, 25948, 25968, 25988, 26008,
    127982, 127983, 128370, 128391, 10047, 128914, 128918, 129614, 129769, 129782, 129783, 129784, 129785,
    129786, 129787, 129788, 129790, 129791, 129792, 129832, 129833, 129834, 129835, 129836, 129837, 129838,
    129839, 129840, 129841, 129842, 129843, 129844, 129846, 129847, 129848, 129849, 129850, 129851, 129852,
    129853, 129854, 129855, 129856, 10247, 11874, 754, 1554];

