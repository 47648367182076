import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { ToastState } from './model/toast-state';
import { takeUntil } from 'rxjs/operators';
import { BaseToastComponent } from './base-toast/base-toast.component';
import { ToastComponentConfig } from '../model/toast-component.config';
import { FADE_ANIMATION } from '@common/animations/const/fade-animation';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

type State = ToastState;

// TODO: [PDEV-18307] - move to ui kit
@Component({
  selector: 'auk-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FADE_ANIMATION],
})
export class ToastComponent extends BaseToastComponent<State> {

  constructor(
    elementRef: ElementRef<HTMLElement>,
    private readonly platformCommonService: PlatformCommonService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super(elementRef);
  }

  private static getDefaultState(toastConfig: ToastComponentConfig): State {
    return {
      iconSource: toastConfig.iconName,
      title: toastConfig.title,
      message: toastConfig.message,
      closeTimeout: toastConfig.timeout,
      showCloseIcon: toastConfig.canClose,
      actions: toastConfig.actions,
      iconType: toastConfig.iconType,
      colorCombination: toastConfig.colorCombination,
    };
  }

  public init(toastConfig: ToastComponentConfig): void {
    this.updateState(
      ToastComponent.getDefaultState(toastConfig),
    );

    // start close timeout if is defined
    if (this.state.closeTimeout && this.platformCommonService.isBrowser) {
      this.ngZoneUtilService.timerOut$(this.state.closeTimeout)
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => {
          // close notification
          this.onCloseToast();
        });
    }
  }

  public onCloseToast(): void {
    this.closeToast.emit();
  }

}
