<div class="tw-flex tw-items-center tw-h-full">
  <div
    class="tw-flex tw-items-center tw-h-10 toggle-on-touch"
    aukToolTip2
    trigger="hover"
    placement="bottom"
    arrowPlacement="none"
    [delayOnClose]="0"
    [enableBackdrop]="true"
    [hideUnderHeader]="true"
    [tooltipModel]="{ type: 'CUSTOM', contentTemplate: popoverTmpl }"
    (click)="onFavouritePopoverClick()"
    (isTooltipShown)="setPopoverOpenState($event)"
  >
    <auk-header-item [colorCombination]="favouriteColorCombination" [headerItemModel]="headerItemData" [isActive]="isPopoverOpened" />
  </div>
</div>

<ng-template #popoverTmpl>
  <auk-favourite-popover
    aukViewportDetector
    [checkOncePerPage]="false"
    (inViewportDetectorChange)="onPopoverInViewport($event)"
    [favourites]="favourites"
    (buyItem)="onBuyButtonClick($event)"
    (measuredClick)="onPopoverMeasuredClick($event)"
    (tabClick)="onPopoverTabClick($event)"
  />
</ng-template>
