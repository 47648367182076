import { FieldError } from '@api/generated/defs/FieldError';
import { HttpError } from '@shared/rest/model/http-error';

export class UserErrorUtil {

  public static userNotFound(error: HttpError): boolean {
    return error.body.errors.some(
      (e: FieldError) => e.code === 'validation.User.notFound.path');
  }

  public static firstNameNotValid(httpError: HttpError): boolean {
    return httpError?.body?.fields?.some((field: FieldError) => field?.codes.some((code: string) => code.endsWith('firstName.notAllowed')));
  }

  public static lastNameNotValid(httpError: HttpError): boolean {
    return httpError?.body?.fields?.some((field: FieldError) => field?.codes.some((code: string) => code.endsWith('lastName.notAllowed')));
  }

}
