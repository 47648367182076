import { UserService } from '@shared/user/service/user.service';
import { AuthCacheService } from '@shared/authentication/service/auth-cache.service';
import { WebsocketsService } from './websockets.service';
import { BaseWebsocketTopicService } from './base-websocket-topic.service';
import { Injectable } from '@angular/core';
import { MessageWebsocketDto } from '@shared/model/message-websocket-dto.model';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { WsTopicPropertiesModel } from '@shared/websocket/model/ws-topic-properties.model';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { WsDestinationModel } from '@shared/websocket/model/ws-destination.model';
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';

@Injectable({ providedIn: 'root' })
export class UserMessagesWsService extends BaseWebsocketTopicService<MessageWebsocketDto> {

  protected readonly wsProperties: WsTopicPropertiesModel = {
    authorized: true,
  };

  protected override readonly isMultiple: boolean = false;

  constructor(
    authCacheService: AuthCacheService,
    websocketsService: WebsocketsService<MessageWebsocketDto>,
    platformCommonService: PlatformCommonService,
    protected readonly userService: UserService,
    protected readonly authenticationService: AuthenticationService,
  ) {
    super(authCacheService, websocketsService, platformCommonService);
  }

  protected wsDestinations$(): Observable<WsDestinationModel | Nil> {
    return this.userService.getActualStatistics()
      .pipe(
        map((userDto) => userDto?.userId),
        distinctUntilChanged(),
        map((userId) =>
          isNil(userId)
            ? null
            : ({
              destination: `/topic/user.${ userId }`,
            }),
        ),
      );
  }

}
