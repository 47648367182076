import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { take, takeUntil } from 'rxjs/operators';
import isNil from 'lodash-es/isNil';
import { AdminClipboardService } from '../admin-clipboard/admin-clipboard.service';
import { ClipboardDto } from '@api/generated/defs/ClipboardDto';
import { ToastService } from '@common/toast/service/toast.service';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Component({
  selector: 'auk-admin-clipboard-add-dialog',
  templateUrl: './admin-clipboard-add-dialog.component.html',
})
export class AdminClipboardAddDialogComponent extends NgUnsubscribe implements OnInit {

  public clipboards: ClipboardDto[] = [];
  public selectedClipboard: ClipboardDto;

  private readonly SELECTED_CLIPBOARD_LS_KEY: string = 'selected-clipboard';

  constructor(
    private readonly adminClipboardService: AdminClipboardService,
    private readonly localStorageService: LocalStorageService,
    private readonly toastService: ToastService,
    private readonly matDialogRef: MatDialogRef<AdminClipboardAddDialogComponent>,
  ) {
    super();
  }

  protected get isSubmitDisabled(): boolean {
    return isNil(this.selectedClipboard);
  }

  public ngOnInit(): void {
    this.loadClipboards();
  }

  public onSubmit(redirect: boolean = false): void {
    this.localStorageService.setItem(this.SELECTED_CLIPBOARD_LS_KEY, this.selectedClipboard?.id);

    this.matDialogRef.close({
      clipboard: this.selectedClipboard,
      redirect,
    });
  }

  public onClose(): void {
    this.matDialogRef.close(null);
  }

  private loadClipboards(): void {
    this.adminClipboardService.getClipboards()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (data: ClipboardDto[]) => {
          this.clipboards = data;
          this.preselectClipboard();
        },
        error: () => {
          this.toastService.showDanger({ key: 'INSERT_TO_ADMIN_CLIPBOARD_LOADING_ERROR' });
        },
      });
  }

  /**
   * If user has just one clipboard (there's no other to choose from), it pre-selects it straight away.
   * If user has multiple clipboards, it tries to pre-select the clipboard which has been used the last time (if it still exists).
   */
  private preselectClipboard(): void {
    if (this.clipboards.length === 1) {
      this.onClipboardSelected(this.clipboards[0]);
      return;
    }

    const selectedClipboardLS: number = this.localStorageService.getItem(this.SELECTED_CLIPBOARD_LS_KEY);
    if (!isNil(selectedClipboardLS)) {
      this.onClipboardSelected(this.clipboards.find((clipboard: ClipboardDto) => clipboard.id === selectedClipboardLS));
    }
  }

  public onClipboardSelected(clipboard: ClipboardDto): void {
    this.selectedClipboard = clipboard;
  }

}
