import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { TfvDialogData } from '@shared/two-factor-verification/tfv-dialog-data.interface';
import {
  TfvEnforcedDialogComponent,
} from '@shared/two-factor-verification/tfv-enforced-dialog/tfv-enforced-dialog.component';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';

@Injectable({
  providedIn: 'root',
})
export class TwoFactorVerificationService {

  constructor(
    private readonly aukMatDialogService: AukMatDialogService,
  ) {
  }

  public openTfvEnforcedDialog(tfvDialogData: TfvDialogData): Observable<boolean> {
    return this.aukMatDialogService.openSimple$<TfvEnforcedDialogComponent, TfvDialogData, boolean>(TfvEnforcedDialogComponent, {
      data: tfvDialogData,
    })
      .pipe(switchMap((dialogRef) => dialogRef.afterClosed()));
  }

}
