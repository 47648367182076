import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { AukTwoButtonDialogModel } from './auk-two-button-dialog.model';
import { DialogWrapperModule } from '@common/dialog-wrapper/dialog-wrapper.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';

@Component({
  selector: 'auk-two-button-dialog',
  templateUrl: './auk-two-button-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    Translate2Module,
    ButtonComponent,
    DialogWrapperModule,
  ],
  standalone: true,
})
export class AukTwoButtonDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: AukTwoButtonDialogModel,
    private dialogRef: MatDialogRef<AukTwoButtonDialogComponent>,
  ) {
  }

  public onClickButton1(): void {
    this.dialogRef.close(true);
  }

  public onClickButton2(): void {
    this.dialogRef.close(false);
  }

  protected onCloseClick(): void {
    this.dialogRef.close(false);
  }

}
