import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CapsLockDirective } from './capslock.directive';
import { ChangeDomainDirective } from './change-domain.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { DropdownModule } from './dropdown/dropdown.module';
import { FocusDirective } from './focus.directive';
import { IsMobileDirective } from './is-mobile.directive';
import { DragScrollDirective } from './ngx-drag-scroll/drag-scroll.directive';
import { PopoverModule } from './popover/popover.module';
import { PreventMultipleButtonClickDirective } from './prevent-multi-click/prevent-multi-click.directive';
import { ScrollToDirective } from './scroll-to/scroll-to.directive';
import { ValidationFormScrollDirective } from './validation-form-scroll.directive';
import { ValidationMessagesDirective } from './validation-messages/validation-messages.directive';
import { TrimDirectiveModule } from '@util/directive/trim/trim.directive.module';
import { OnlyNumberInputDirectiveModule } from './only-number-input/only-number-input.directive.module';
import { LimitMaxLengthDirectiveModule } from './limit-max-length/limit-max-length.directive.module';
import { TooltipModule } from '@shared/legacy/component/tooltip/tooltip.module';
import { InViewportDetectorDirective } from '@shared/legacy/directive/in-viewport/in-viewport-detector.directive';
import { ReplaceByPlaceholderOnErrorDirective } from '@common/image-loading/directive/replace-by-placeholder-on-error.directive';
import { PreventDefaultLinkDirective } from '@common/routing/directive/prevent-default-link.directive';

/**
 * @deprecated
 * Do not use, if you need to use some directive from this module, create module only for that directive and use that
 * (don't forget to remove the directive from declarations in this module, and put the module into exports only there
 */
@NgModule({
  imports: [
    CommonModule,
    PopoverModule.forRoot(),
    DropdownModule.forRoot(),
    TrimDirectiveModule,
    OnlyNumberInputDirectiveModule,
    LimitMaxLengthDirectiveModule,
    ReplaceByPlaceholderOnErrorDirective,
    IsMobileDirective,
    TooltipModule,
    ValidationMessagesDirective,
    InViewportDetectorDirective,
    PreventDefaultLinkDirective,
  ],
  declarations: [
    ClickOutsideDirective,
    ValidationFormScrollDirective,
    CapsLockDirective,
    ScrollToDirective,
    ChangeDomainDirective,
    FocusDirective,
    DragScrollDirective,
    PreventMultipleButtonClickDirective,
  ],
  exports: [
    ClickOutsideDirective,
    ValidationFormScrollDirective,
    OnlyNumberInputDirectiveModule,
    LimitMaxLengthDirectiveModule,
    ReplaceByPlaceholderOnErrorDirective,
    CapsLockDirective,
    TrimDirectiveModule,
    PopoverModule,
    ScrollToDirective,
    ValidationMessagesDirective,
    ChangeDomainDirective,
    DropdownModule,
    FocusDirective,
    DragScrollDirective,
    PreventDefaultLinkDirective,
    IsMobileDirective,
    PreventMultipleButtonClickDirective,
    TooltipModule,
    InViewportDetectorDirective,
  ],
})
export class CommonDirectivesModule {
}
