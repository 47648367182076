import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { TranslateModule } from '@ngx-translate/core';

import { PhoneFieldModule } from '@shared/phone-field/phone-field.module';
import { CommonDirectivesModule } from '@shared/legacy/directive/common-directives.module';
import { CommonPipesModule } from '@shared/legacy/pipe/common-pipes.module';
import { SuccessMessageModule } from '@shared/legacy/component/success-message/success-message.module';

import { TfvEnforcedDialogComponent } from './tfv-enforced-dialog/tfv-enforced-dialog.component';
import { TwoFactorVerificationComponent } from './two-factor-verification.component';
import { VerificationCodeInputComponent } from './verification-code-input.component';
import { Translate2Module } from '@common/translations/translate2.module';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CommonPipesModule,
    CommonDirectivesModule,
    PhoneFieldModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDialogModule,
    SuccessMessageModule,
    ButtonComponent,
    Translate2Module,
    InnerHtmlDirective,
    TestIdentificationDirective,
  ],
  declarations: [
    TwoFactorVerificationComponent,
    VerificationCodeInputComponent,
    TfvEnforcedDialogComponent,
  ],
  exports: [
    TwoFactorVerificationComponent,
    TfvEnforcedDialogComponent,
  ],
})
export class TwoFactorVerificationModule {
}
