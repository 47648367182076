export enum ListingType {
  CATEGORY_OFFERS = 1, // 1-based enum to prevent human mistakes in conditions (0 is falsy)
  INTERESTING_OFFERS,
  SEARCH,
  FAVOURITE_SEARCH,
  SPECIAL_ENDING,
  SPECIAL_FROM_1,
  USER_OFFERS,
  HOT_AUCTIONS,
  USER_OFFERS_SEARCH,
  MULTI_USER_OFFERS,
  SPECIAL_300_CZK,
  BLACK_FRIDAY,
  CRAZY_PRICES_INTERNAL,
  SMALL_NEW_SELLERS_INTERNAL,
  APV_INTERNAL,
  LANDING_PAGE,
  ITEMS_COLLECTION,
  INDEXED_SEARCH,
  NEW_OFFERS,
  POPULAR_OFFERS,
  PRICE_HITS,
  PREMIUM_OFFERS,
  CHARITY_OFFERS,
}
