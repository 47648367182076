import { UserTaskLoginStepResolutionHandlerService } from './user-task-login-step-resolution-handler.service';
import { Injectable } from '@angular/core';
import { UserTaskStepResolutionUnionModel } from '../../base/model/user-task-step-resolution-union.model';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';
import { captureException } from '@sentry/browser';
import { PlatformService } from '@shared/platform/service/platform.service';
import isNil from 'lodash-es/isNil';

@Injectable({
  providedIn: 'root',
})
export class UserTaskLoginStepFacebookLoadResolutionHandlerService extends UserTaskLoginStepResolutionHandlerService<'FB_LOAD'> {

  constructor(private readonly platformService: PlatformService) {
    super();
  }

  public handle(resolution: UserTaskStepResolutionUnionModel<'LOGIN', 'FB_LOAD'>,
    taskPayload: UserTaskPayloadModel<'LOGIN'>): UserTaskStepUnionModel<'LOGIN'> {

    if (resolution.type === 'RESOLUTION_PASSWORD_NON_REGISTERED_VIA_FB') {
      return {
        type: 'PASSWORD_OR_THIRD_PARTY',
        payload: {
          title: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.title,
          subtitle: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.subtitle,
          inputPlaceholder: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.inputPlaceholder,
          isNotThatYou: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.isNotThatYou,
          didYouForgetThePassword: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.didYouForgetThePassword,
          loginButton: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.loginButton,
          orDividerText: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.orDividerText,
          continueViaFacebook: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.continueViaFacebook,
          terms: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.terms,
          email: resolution.payload.email,
          allowedLoginMethods: ['EMAIL'],
          dialogAlerts: [
            {
              text: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.fbLoginNotAllowed,
              type: 'WARNING',
            },
          ],
        },
      };
    }

    if (resolution.type === 'RESOLUTION_NON_REGISTERED_VIA_FACEBOOK_NO_PASSWORD') {
      return {
        type: 'SET_PASSWORD',
        payload: {
          title: taskPayload?.stepsText?.SET_PASSWORD?.title,
          subtitle: taskPayload?.stepsText?.SET_PASSWORD?.subtitle,
          isNotThatYou: taskPayload?.stepsText?.SET_PASSWORD?.isNotThatYou,
          setPasswordButtonText: taskPayload?.stepsText?.SET_PASSWORD?.setPasswordButtonText,
          email: resolution.payload.email,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_SUCCESS') {
      return null;
    }

    if (resolution.type === 'RESOLUTION_FACEBOOK_AUTHORIZE_FAILED') {
      captureException(
        'Facebook authorize failed',
        { tags: { context: 'login', result: 'error' }, fingerprint: ['step-facebook-load-error'] },
      );

      let commonDialogAlerts = resolution?.payload?.dialogAlerts;
      if (isNil(commonDialogAlerts) || commonDialogAlerts.length === 0) {
        commonDialogAlerts = resolution?.payload?.dialogAlerts;
      }
      return {
        type: 'FACEBOOK_AUTHORIZE_FAILED',
        payload: {
          sourceAction: taskPayload?.sourceAction,
          ...taskPayload?.stepsText?.FACEBOOK_AUTHORIZE_FAILED,
          dialogAlerts: commonDialogAlerts,
          regDomainCode: resolution?.payload?.regDomainCode,
          crossDomainLogin:
            resolution.payload?.regDomainCode ? {
              key: 'LOGIN_CROSS_DOMAIN_REDIRECT',
              params: { regDomain: this.platformService.getDomainHost(resolution.payload?.regDomainCode) },
            } : null,
        },
      };
    }
  }

}
