import { Inject, Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { TransferStateUtils } from '@util/util/transfer-state.utils';
import { SubbrandConfigModel } from '@util/util/model/subbrand-config.model';
import { SUBBRAND_CONFIG } from '@shared/subbrand/di/subbrand-config.injection-token';

@Injectable({
  providedIn: 'root',
})
export class TransferStateService {

  constructor(@Inject(SUBBRAND_CONFIG) protected subbrandConfig: SubbrandConfigModel) {
  }

  public generateKeyForRequest(req: {
    method: string;
    url: string;
    params?: HttpParams;
    body?: unknown;
    headers?: HttpHeaders;
  }): string {

    let significantHeaders = new HttpHeaders();
    this.significantHeaderNames().forEach(h => {
      if (req.headers.has(h)) {
        significantHeaders = significantHeaders.append(h, req.headers.get(h));
      }
    });

    return TransferStateUtils.generateKeyForRequest({ ...req, headers: significantHeaders });
  }

  /**
   * Requests headers which should be taken account of while creating transfer state key.
   */
  private significantHeaderNames(): string[] {
    return [this.subbrandConfig.subbrandHeaderName];
  }

}
