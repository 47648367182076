import { BadgeIcon } from '@shared/user-chip/components/user-account-type-badge/modul/user-account-type-badge-data.modul';

export const userAccountTypeBadgeContainer: BadgeIcon[] = [
  {
    icon: 'blocked_user',
    info: 'AVATAR_BLOCKED_USER_TEXT',
  },
  {
    icon: 'unverified_user',
    info: 'AVATAR_NOT_VERIFIED_USER_TEXT',
  },
  {
    icon: 'verified_user',
    info: 'AVATAR_VERIFIED_USER_TEXT',
  },
  {
    icon: 'business_user',
    info: 'AVATAR_VERIFIED_BUSINESS_USER_TEXT',
  },
];
