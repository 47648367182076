import { Injectable } from '@angular/core';
import { FavouritePopoverDataService } from '@shared/favourite/service/favourite-popover-data.service';
import { combineLatest, of, Subject, switchMap } from 'rxjs';
import { CrossTabMessagingService } from '@shared/services/cross-tab-messaging/service/cross-tab-messaging.service';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { FavouritePopoverDataAsyncUpdateService } from '@shared/favourite/service/favourite-popover-data-async-update.service';
import { ArrayUtils } from '@util/util/array.utils';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { FavouritePopoverDataProviderService } from '@shared/favourite/service/favourite-popover-data-provider.service';
import { TimeService } from '@common/time/service/time.service';
import { ProductStateUtils } from '@shared/item-detail/service/mapper/base-item/utils/product-state.utils';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import moment from 'moment-mini-ts';
import clamp from 'lodash-es/clamp';
import { DateUtils } from '@util/util/date.utils';
import { WatchingItemPreviewDto } from '@api/aukro-api/model/watching-item-preview-dto';
import first from 'lodash-es/first';
import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';

@Injectable({
  providedIn: 'root',
})
export class FavouritePopoverService extends BaseDestroy {

  /**
   * Count of items which will be loaded in popover
   */
  private readonly maxFavouritePopoverItemsCount: number = 5;

  constructor(
    private readonly favouritePopoverDataService: FavouritePopoverDataService,
    private readonly favouritePopoverDataProviderService: FavouritePopoverDataProviderService,
    private readonly crossTabMessagingService: CrossTabMessagingService,
    private readonly favouritePopoverDataAsyncUpdateService: FavouritePopoverDataAsyncUpdateService,
    private readonly timeService: TimeService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public init(): void {
    this.initAsyncUpdateData();

    // refresh favourites on force reload event
    this.favouritePopoverDataService.forceLoadPopoverData$
      .pipe(
        // always start, so we have data at initialize time
        startWith(void 0),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.loadFavouritePopoverData(this.maxFavouritePopoverItemsCount);
      });
  }

  /**
   * Refreshes data in favourite icon & popover
   */
  public refreshPopoverData(): void {
    this.favouritePopoverDataService.reloadPopoverData();
  }

  /**
   * Fetches and updates the favourite popover data.
   */
  public loadFavouritePopoverData(itemsCount?: number): void {
    this.favouritePopoverDataProviderService.getFavouritePopoverData$(itemsCount)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: (data) => {
          this.favouritePopoverDataService.setPopoverData({
            favouriteItemsPreviewDto: data.favouriteItemsPreviewDto,
            favouriteSellersPreviewDto: data.favouriteSellersPreviewDto,
            countOfAllFavouriteSearchTerms: data.countOfAllFavouriteSearchTerms,
          });
        },
      });
  }

  private initAsyncUpdateData(): void {
    // sync data across multiple tabs
    this.crossTabMessagingService.listenToMessageType$('RELOAD_POPOVER_DATA')
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((data) => {
        this.favouritePopoverDataService.setPopoverData(data, false);
      });

    // watch for async data update if it is enabled
    this.favouritePopoverDataProviderService.isAsyncUpdateEnabled$
      .pipe(
        switchMap((isRealtimeUpdateEnabled) =>
          isRealtimeUpdateEnabled
            ? this.favouritePopoverDataAsyncUpdateService.watchForDataUpdate$()
              .pipe(
                tap((event) => {
                  if (event.type !== 'SUCCESS' || ArrayUtils.isEmpty(event.payload)) {
                    return;
                  }

                  this.favouritePopoverDataService.updatePopoverItems(event.payload, false);
                }),
              )
            : of(null),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();

    // create refresh sub, which can be called later, to repeat some logic after calculated duration
    const refreshSub$ = new Subject<void>();

    // we need to update first to end popover item in realtime, based on it's ending time
    // e.g. when the remaining time to end becomes 0, we need to refresh the popover data, etc...
    combineLatest([
      this.favouritePopoverDataService.firstToEndPopoverItem$(),
      this.favouritePopoverDataProviderService.urgentCountdownThresholdDuration$,
      this.favouritePopoverDataProviderService.isAsyncUpdateEnabled$
        .pipe(
          switchMap((isAsyncUpdateEnabled) =>
            isAsyncUpdateEnabled
              ? this.favouritePopoverDataProviderService.asyncUpdateThresholdDuration$
              : of(null),
          ),
        ),
    ])
      .pipe(
        switchMap((data) =>
          // return refreshSub$, so when it emits value, it will repeatedly call following code
          refreshSub$.asObservable()
            .pipe(
              // always emit first value immediately
              startWith(void 0),
              map(() => data),
            ),
        ),
        switchMap(
          ([
            firstToEndPopoverItem,
            urgentCountdownThresholdDuration,
            asyncUpdateThresholdDuration,
          ]) => {
            if (isNil(firstToEndPopoverItem?.endTime)) {
              return of(null);
            }

            const refreshData = this.getRefreshDataForItem(
              firstToEndPopoverItem,
              urgentCountdownThresholdDuration,
              asyncUpdateThresholdDuration,
            );

            if (isNil(refreshData)) {
              return of(null);
            }

            // init delay, after which we refresh data or repeat this func
            return this.ngZoneUtilService.timerOut$(refreshData.nextRefreshDelay)
              .pipe(
                tap(() => {
                  // in case of refreshing date we don't need to call refresh sub, as new data will cause this whole subscription
                  // to be called again
                  if (refreshData.refreshDataAfterDelay) {
                    // refresh popover data (calls API)
                    this.refreshPopoverData();
                  } else if (refreshData.recalculateFirstToEndPopoverItem) {
                    // recalculate first to end popover item
                    this.favouritePopoverDataService.recalculateFirstToEndPopoverItem();
                  } else {
                    // repeat previous func
                    refreshSub$.next();
                  }
                }),
              );
          }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private getRefreshDataForItem(
    firstToEndPopoverItem: WatchingItemPreviewDto,
    urgentCountdownThresholdDuration: moment.Duration,
    asyncUpdateThresholdDuration: moment.Duration | Nil,
  ): RefreshDataModel | Nil {
    // if item has ENDED state -> set lower refresh delay, because we expect that in next call
    // the ended item won't be there
    if (ProductStateUtils.isEnded({ state: firstToEndPopoverItem.state })) {
      return {
        nextRefreshDelay: DateUtils.convertSecondsToMilliseconds(1),
        refreshDataAfterDelay: true,
      };
    }

    // create remaining times for calculation
    // NOTE:
    // - we don't take into account item end remaining time on purpose, as this is handled through WS/POLLING, because
    //   we need to wait for the item state to be ENDED and it can take in worst case scenarios like 10 seconds etc...
    //   (see FavouritePopoverDataAsyncUpdateService)
    let remainingTimesAvailableForCalculation = [
      // urgent countdown start remaining time
      {
        duration: moment.duration(
          moment(firstToEndPopoverItem.endTime).subtract(urgentCountdownThresholdDuration).diff(this.timeService.syncedCurrentTime),
        ),
        recalculateFirstToEndItem: true,
        refreshDataAfterDelay: false,
        minNextRefreshDelay: DateUtils.convertSecondsToMilliseconds(1),
      },
    ];

    // because async threshold can be nil, we add it only if it is not
    if (!isNil(asyncUpdateThresholdDuration)) {
      // async update start remaining time
      remainingTimesAvailableForCalculation.push({
        duration: moment.duration(
          moment(firstToEndPopoverItem.endTime).subtract(asyncUpdateThresholdDuration).diff(this.timeService.syncedCurrentTime),
        ),
        recalculateFirstToEndItem: true,
        minNextRefreshDelay: DateUtils.convertSecondsToMilliseconds(1),
        refreshDataAfterDelay: false,
      });
    }

    remainingTimesAvailableForCalculation = remainingTimesAvailableForCalculation
      // if the duration is negative and at least 1 second, we consider that it is already active
      .filter((remainingTimeInfo) => remainingTimeInfo.duration.asMilliseconds() > -1);

    // find shortest remaining time, after which we will set next refresh delay
    const shortestRemainingTimeInfo = remainingTimesAvailableForCalculation
      .reduce(
        (acc, remainingTimeInfo) =>
          acc.duration.asMilliseconds() > remainingTimeInfo.duration.asMilliseconds() ? remainingTimeInfo : acc,
        first(remainingTimesAvailableForCalculation),
      );

    if (isNil(shortestRemainingTimeInfo)) {
      return null;
    }

    // calculate next refresh delay by setting the shortest remaining time from items above
    // additionally set lower/upper bounds for that number (so we don't spam BE, if the ending time
    // will be e.g zero or too big
    const nextRefreshDelay =
      clamp(
        shortestRemainingTimeInfo.duration.asMilliseconds(),
        shortestRemainingTimeInfo.minNextRefreshDelay,
        DateUtils.convertMinutesToMilliseconds(5),
      );

    return {
      refreshDataAfterDelay: shortestRemainingTimeInfo.refreshDataAfterDelay,
      recalculateFirstToEndPopoverItem: shortestRemainingTimeInfo.recalculateFirstToEndItem,
      nextRefreshDelay,
    };
  }

}

interface RefreshDataModel {
  nextRefreshDelay?: number;
  refreshDataAfterDelay?: boolean;
  recalculateFirstToEndPopoverItem?: boolean;
}
