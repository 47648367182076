import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { TranslationSource } from '@common/translations/model/translation-source';
import { SocialIconButtonId } from '@common/ui-kit/component/social-icon-button/model/social-icon-button-id';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';
import { Translate2Module } from '@common/translations/translate2.module';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';

@Component({
  selector: 'auk-social-store-button',
  templateUrl: './social-store-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconComponent,
    SvgIconComponent,
    Translate2Module,
    LinkFromHrefDirective,
  ],
})
export class SocialStoreButtonComponent extends ColorCombinationInputComponent{

  @Input() public source: SocialIconButtonId;
  @Input() public hrefUrl: string;
  @Input() public text: TranslationSource;
  @Input() public spanText: TranslationSource;

}
