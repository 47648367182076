// TODO: [PDEV-18307] - fix dependency
// eslint-disable-next-line import/no-restricted-paths
import { TranslationSource } from '@common/translations/model/translation-source';

/**
 * returns trackByFn to use in ng-for trackBy for objects which has TranslationSource in 'path' property
 * Credit to @Ludvík Bobek
 * @param path
 */
export const getTrackByTranslationSourceFn: <T extends PropertyKey>(path: T) =>
  (index: number, object: ({ [KEY in T]: TranslationSource } & { [key in string]: unknown })) =>
    string | number = <T extends PropertyKey>(path: T) =>
      (index: number, object: { [KEY in T]: TranslationSource } & { [key in string]: unknown }) =>
        object?.[path]?.key ?? object?.[path]?.defaultValue ?? index;
