<auk-dialog-wrapper-with-content
    class="tw-break-words"
    [dialogTitle]="title"
    (closeDialogClick)="onCloseClick()"
>

  <div content
       [aukInnerHtml]="content"
  ></div>

  <auk-button
      actions
      colorCombination="PRIMARY"
      [chin]="true"
      class="tw-w-full tw-mb-2"
      mat-dialog-close
  >
    {{ confirmBtnLabel | translateSource }}
  </auk-button>

</auk-dialog-wrapper-with-content>
