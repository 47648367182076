import { NgModule } from '@angular/core';
import { DelayedAutofocusDirective } from './delayed-autofocus.directive';

@NgModule({
  declarations: [
    DelayedAutofocusDirective,
  ],
  exports: [
    DelayedAutofocusDirective,
  ],
})
export class DelayedAutofocusDirectiveModule {}
