import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { captureException, Scope, withScope } from '@sentry/browser';
import {
  GetOfferDealsAndTransactionsRequestParams, GetOfferDetailRequestParams,
  GetOfferDetailUsersDataWithPageTypeRequestParams,
  OffersApiService,
  ShowShortOfferDetailShortRequestParams,
} from '@api/aukro-api/api/offers-api.service';
import { OfferDetailDto } from '@api/aukro-api/model/offer-detail-dto';
import { OfferDetailShort } from '@api/aukro-api/model/offer-detail-short';
import { ItemDetailUsersDataDto } from '@api/aukro-api/model/item-detail-users-data-dto';
import { PagedModelItemDealDto } from '@api/aukro-api/model/paged-model-item-deal-dto';
import { HttpErrorResponse } from '@angular/common/http';
import { ItemDetailModPersonalizationService } from '@shared/item-detail/mod/service/item-detail-mod-personalization.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { ViewportWidthType } from '@common/responsiveness/model/viewport-width.type';
import { ItemDetailRequestedForType } from '@shared/item/model/item-detail-requested-for.type';

@Injectable({
  providedIn: 'root',
})
export class ItemDataProviderService {

  constructor(
    private readonly offersApiService: OffersApiService,
    private readonly router: Router,
    private readonly itemDetailModPersonalizationService: ItemDetailModPersonalizationService,
    private readonly responsivenessService: ResponsivenessService,
  ) {
  }

  /**
   * Fetches the item detail and handles 5xx errors
   * @deprecated Errors should not be handled in such a general way – use getItemDetail method instead
   * @param itemId ID of the item
   * @returns observable that emits the item detail
   */
  public getItemDetailWithErrorHandled(itemId: number): Observable<OfferDetailDto> {
    return this.getItemDetail(itemId)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (Math.floor(error.status / 100) === 5) {
            withScope((scope: Scope) => {
              scope.setExtra('extra', error);
              captureException(new Error('Error 500 – Can not get offer detail in OfferService'));
            });
            void this.router.navigate(['/500']);
          }
          throw error;
        }),
      );
  }

  public getItemDetail(
    itemId: number,
    itemDetailModsEnabled: boolean = false,
    requestedFor?: ItemDetailRequestedForType,
  ): Observable<OfferDetailDto> {
    const params: GetOfferDetailRequestParams = {
      id: itemId,
      pageType: 'DETAIL',
      requestedFor,
      itemDetailModsEnabled,
    };

    if (itemDetailModsEnabled) {
      params.itemModVisitType = this.itemDetailModPersonalizationService.resolveVisitType();
      params.itemModDeviceType = this.responsivenessService.viewportWidthType.toUpperCase() as Uppercase<ViewportWidthType>;
    }

    return this.offersApiService.getOfferDetail$(params);
  }

  public offerDetailShort(params: ShowShortOfferDetailShortRequestParams): Observable<OfferDetailShort> {
    return this.offersApiService.showShortOfferDetailShort$(params);
  }

  public detailUserData(params: GetOfferDetailUsersDataWithPageTypeRequestParams): Observable<ItemDetailUsersDataDto> {
    return this.offersApiService.getOfferDetailUsersDataWithPageType$(params);
  }

  // TODO(PDEV-9704) Refactor this/ remove Payment tab after New My aukro sold/bought page are implemented
  public getOfferTransactions(offerId: number, meta?: { page?: number; size?: number }, dealId?: number):
    Observable<PagedModelItemDealDto> {

    const params: GetOfferDealsAndTransactionsRequestParams = {
      id: offerId,
      dealId,
      page: meta.page,
      size: meta.size,
    };

    return this.offersApiService.getOfferDealsAndTransactions$(params);
  }

}
