import { NgModule } from '@angular/core';
import { TranslateSourceTermsPipe } from './pipe/translate-source-terms.pipe';
import { Translate2Module } from '@common/translations/translate2.module';

@NgModule({
  imports: [
    Translate2Module,
  ],
  declarations: [
    TranslateSourceTermsPipe,
  ],
  exports: [
    TranslateSourceTermsPipe,
  ],
})
export class TranslateSourceTermsPipeModule {
}
