import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Translate2Module } from '@common/translations/translate2.module';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { CanvasMenuModel } from '../model/canvas-menu.model';
import { RouterModule } from '@angular/router';
import { Nil } from '@util/helper-types/nil';
import { trackByIdFn } from '@util/helper-functions/track-by/track-by-id-fn';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CanvasMenuItemModel } from '@common/ui-kit/component/tab-menu-overlay/model/canvas-menu-item.model';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { MouseUtils } from '@util/util/mouse.utils';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';

@Component({
  selector: 'auk-tab-menu-overlay',
  templateUrl: './tab-menu-overlay.component.html',
  styleUrls: ['./tab-menu-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    Translate2Module,
    IconComponent,
    RouterModule,
    LinkFromHrefDirective,
  ],
})
export class TabMenuOverlayComponent extends NgUnsubscribe {

  @Input() public canvasMenu: CanvasMenuModel | Nil;

  @Output() public itemClick: EventEmitter<CanvasMenuItemModel> = new EventEmitter<CanvasMenuItemModel>();

  protected trackByOptionIdFn = trackByIdFn;

  public onItemClick(event: MouseEvent, item: CanvasMenuItemModel): void {
    // if ctrl or meta key is pressed and link is not nil, do nothing (so the link will be opened in the new tab)
    // and nothing more happens
    if (!MouseUtils.canDoAppNavigation(event) && isNotNil(item.url)) {
      return;
    }

    event.preventDefault();

    this.itemClick.emit(item);
  }

}
