import { Injectable } from '@angular/core';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { AccessibilityService } from '../service/accessibility.service';

@Injectable({
  providedIn: 'root',
})
export class AccessibilityInitializer implements AppInitializer {

  constructor(private readonly accessibilityService: AccessibilityService) {
  }

  public init(): void {
    this.accessibilityService.init();
  }

}
