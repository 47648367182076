import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SimpleFilterParamItem } from '../../../../../../../typings/original/deprecated';
import { ExtendedTileModel } from '@shared/legacy/component/mobile-filters';

@Component({
  selector: 'auk-mobile-box-layout',
  templateUrl: './mobile-box-layout.component.html',
})
export class MobileBoxLayoutComponent {

  /**
   * Input interfaced object. Inside, is dynamic item attribute,
   * which can be filled with different kind of objects
   */
  @Input() public tile: ExtendedTileModel<SimpleFilterParamItem> = null;

  /**
   * Display tiles as checkboxes
   */
  @Input() public isCheckboxTile: boolean = false;

  /**
   * Emit button click
   */
  @Output() public buttonClick = new EventEmitter<void>();

  public onClick(): void {
    this.buttonClick.emit();
  }

}
