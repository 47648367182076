import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StarIconComponent } from '@shared/legacy/component/star-icon/star-icon.component';
import { SvgIconModule } from '@shared/legacy/component/svg-icon/svg-icon.module';

/**
 * @deprecated
 * use {@link StarIconComponent} from 'ui-kit/star-icon' folder
 */
@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
  ],
  declarations: [
    StarIconComponent,
  ],
  exports: [
    StarIconComponent,
  ],
})
export class StarIconModule {

}
