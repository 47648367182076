import { ValidationMessagesWithArguments } from '@shared/services/errors/validation-messages-with-arguments';

export const VALIDATION_MESSAGES = {
  'validation.user.not.activated':
    'Bankovní účet nelze vložit, protože nemáte aktivovaný účet. Aktivujte si nejprve účet a potom zadejte číslo bankovního účtu',
  'validation.bankAccount.not.owner': 'Nelze editovat bankovní účet, jehož nejste vlastníkem.',
  'validation.bankAccount.exists': 'Toto číslo účtu u Vašeho profilu již evidujeme',
  'validation.bankAccount.accountName.exists': 'Tento název účtu u Vašeho profilu již evidujeme',
  'validation.bankAccount.invalid.pva.iban': 'Pro Platbu přes Aukro není možné použít účet ve tvaru IBAN',
  'validation.bankAccount.invalid.iban': 'IBAN je zadán ve špatném tvaru',
  'validation.bankAccount.invalid.number': 'Číslo účtu není platné, nebo ve správném tvaru',
  'validation.bank.account.czkNotSupportedForForeignIban': 'Mena účtu v korunách je povolená len pre české bankové účty.',
  'validation.bankAccount.pva.default.deletion.disallowed':
    'Účet je použitý pro Platbu přes Aukro. Není možné jej smazat. Lze jej pouze editovat, nebo vyberte jiný z Vašich účtů.',
  'validation.bankAccount.pva.not.enabled':
    'Před označením bankovního účtu pro Platbu přes Aukro musíte aktivovat Platbu přes Aukro v nastavení Vašeho uživatelského účtu',
  'validation.bankAccount.in.use.by.items':
    'Účet nelze smazat, protože je použit na běžících nabídkách. Nejdříve vložte nové číslo účtu do všech běžících nabídek.',
};

export const BA_VALIDATION_MESSAGES_WITH_ARGUMENTS: ValidationMessagesWithArguments = {
  'validation.bankAccount.ppa.in.shipping.price.list':
    (a: string[]) => `Není možné změnit účet pro Platbu přes Aukro,
    protože je přiřazen k ceníku přepravy. Nejprve prosím odeberte účet z těchto ceníků: ${ a[0] }.`,
  'validation.bankAccount.paired.on.shipping.price.list':
    (a: string[]) => `Není možné odstranit účet,
    protože je přiřazen k ceníku přepravy. Nejprve prosím odeberte účet z těchto ceníků: ${ a[0] }.`,
};
