import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { DialogWrapperModule } from '@common/dialog-wrapper/dialog-wrapper.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { TranslationSource } from '@common/translations/model/translation-source';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { InformationDialogData } from '@common/information-dialog/model/information-dialog-data';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@Component({
  selector: 'auk-information-dialog',
  imports: [
    MatDialogModule,
    Translate2Module,
    ButtonComponent,
    DialogWrapperModule,
    InnerHtmlDirective,
  ],
  templateUrl: './information-dialog.component.html',
  standalone: true,
})
export class InformationDialogComponent {

  protected content: TranslationSource;
  protected title: TranslationSource;
  protected confirmBtnLabel: TranslationSource;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InformationDialogData,
    private readonly matDialogRef: MatDialogRef<InformationDialogComponent>,
  ) {
    this.content = data.content;
    this.title = data.title;
    this.confirmBtnLabel = data.confirmBtnLabel ?? { key: 'CONFIRM' };
  }

  protected onCloseClick(): void {
    this.matDialogRef.close();
  }

}
