@if (!customIconId) {
  @switch (type) {
    @case ('INFO') {
      <i class="material-icons text-blue big m-r-3">
        info
      </i>
    }
    @case ('WARNING') {
      <i class="material-icons text-red big m-r-3">
        warning
      </i>
    }
    @case ('SUCCESS') {
      <i class="material-icons text-green big m-r-3">
        check_circle
      </i>
    }
    @case ('ABSENCE') {
      <i class="material-icons text-white big m-r-3">
        flight_takeoff
      </i>
    }
    @case ('POSTPONE') {
      <i class="material-icons !tw-text-matter-accent-primary big m-r-3">
        update
      </i>
    }
  }
} @else {
  <auk-icon [colorCombination]="iconColorCombination" [source]="customIconId" class="big m-r-2" iconType="SVG" size="XMD"></auk-icon>
}

<div class="banner-content">
  <ng-content></ng-content>
</div>
