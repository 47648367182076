import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, switchMap, Observable, of, merge } from 'rxjs';
import { HttpClient, HttpContext } from '@angular/common/http';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { Nil } from '@util/helper-types/nil';
import { WITH_CREDENTIALS } from '@shared/rest/token/with-credentials-http-context.token';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import isNil from 'lodash-es/isNil';
import { ConnectionInfoModel } from '@shared/connection/model/connection-info.model';
import { DateUtils } from '@util/util/date.utils';
import { retryIf } from '@util/rxjs-operators/retry-if';
import { StringUtils } from '@util/util/string.utils';
import { Network } from '@capacitor/network';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { PluginListenerHandle } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService extends NgUnsubscribe implements OnDestroy {

  private _connectivityStatus$: BehaviorSubject<ConnectionInfoModel | Nil> =
    new BehaviorSubject<ConnectionInfoModel | Nil>({ connectionStatusType: 'NO_CHECK' });

  private connectivityCheckInProgress: boolean = false; // Flag to track if a check is in progress

  private startingInterval: number = DateUtils.convertSecondsToMilliseconds(1); // Starting value of interval between connection checks
  private maxInterval: number = DateUtils.convertSecondsToMilliseconds(20); // Maximum value of interval between connection checks
  private lastConnectionStatusNative: ConnectionInfoModel | Nil;
  private networkStatusChangeListener: PluginListenerHandle;

  constructor(private readonly httpClient: HttpClient,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly ngZoneUtilService: NgZoneUtilService) {
    super();
    void this.registerNetworkStatusNative();
  }

  private async registerNetworkStatusNative(): Promise<void> {
    if (PlatformCommonService.isNativeApp) {
      this.networkStatusChangeListener = await Network.addListener('networkStatusChange', status => {
        const state = (status.connected ? { connectionStatusType: 'ONLINE' } : { connectionStatusType: 'OFFLINE' }) as ConnectionInfoModel;

        if (this.lastConnectionStatusNative?.connectionStatusType === 'OFFLINE' || state?.connectionStatusType === 'OFFLINE') {
          this._connectivityStatus$.next(state);
        }

        this.lastConnectionStatusNative = state;
      });
    }
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();

    void Network.removeAllListeners();
  }

  private fetchUrlToCheck$(): Observable<string | Nil> {
    return this.configuratorCacheService.getFeSystemParam<string>('URL_TO_CHECK_CONNECTION', 'STRING')
      .pipe(
        take(1),
        map((url: string) => isNil(url) ? null : url),
      );
  }

  /**
   * This method starts a process to periodically check by sending request to the given URL.
   * The interval starts from 1 sec increased up to 20s.
   *
   * If a connectivity check is already in progress, this method will not start another check.
   * It also verifies that the URL for checking connectivity is not null or undefined before initiating the check.
   *
   * Note:
   * - The check continues even if the user is currently online, as the connection status might change.
   * - The connectivity check will stop once you get success response.
   */
  public initiateConnectivityCheck(): void {
    if (this.isConnectivityCheckRunning || PlatformCommonService.isNativeApp) {
      return;
    }

    this.fetchUrlToCheck$()
      .pipe(
        switchMap((url) => {
          if (StringUtils.isEmpty(url)) {
            return of(null);
          }
          this.connectivityCheckInProgress = true;

          return this.checkConnectivityRequest$()
            .pipe(
              retryIf(
                {
                  retryIfFn: (connection: ConnectionInfoModel) => (connection?.connectionStatusType === 'OFFLINE'),
                  initialRetryInterval: this.startingInterval,
                  maxRetryTime: this.maxInterval,
                },
                this.ngZoneUtilService,
              ),
              tap(() => {
                this.connectivityCheckInProgress = false;
                this.startingInterval = DateUtils.convertSecondsToMilliseconds(1); // reset starting interval to 1 sec
              },
              ),
            );
        }),
        takeUntil(
          merge(
            this.connectionIsOnline$,
            this.ngUnsubscribe,
          ),
        ),
      )
      .subscribe();
  }

  public checkConnectivityRequest$(): Observable<ConnectionInfoModel | Nil> {
    return this.fetchUrlToCheck$()
      .pipe(
        switchMap((url) => {
          if (!StringUtils.isEmpty(url)) {
            // Proceed with the HTTP request if url is not null
            return this.httpClient.get(url, {
              responseType: 'text',
              // setting withCredentials : false to avoid CORS issues'
              context: new HttpContext().set(WITH_CREDENTIALS, false),
            })
              .pipe(
                // Map successful response to ConnectionInfoModel, emit ONLINE
                map(() => ({ connectionStatusType: 'ONLINE' } as ConnectionInfoModel)),
                // Catch any errors, but instead of emitting ConnectionInfoModel, emit OFFLINE
                catchError(() => of({ connectionStatusType: 'OFFLINE' } as ConnectionInfoModel)),
              );
          } else {
            // If there is no URL, do not perform the check and emit NO_CHECK
            return of({ connectionStatusType: 'NO_CHECK' } as ConnectionInfoModel);
          }
        }),
        tap((connection: ConnectionInfoModel) => {
          this._connectivityStatus$.next(connection);
        }),
      );
  }

  public get isConnectivityCheckRunning(): boolean {
    return this.connectivityCheckInProgress;
  }

  public get connectivityStatus$(): Observable<ConnectionInfoModel | Nil> {
    return this._connectivityStatus$.asObservable();
  }

  private get connectionIsOnline$(): Observable<ConnectionInfoModel | Nil> {
    return this.connectivityStatus$
      .pipe(
        filter((connection: ConnectionInfoModel | Nil) => connection?.connectionStatusType === 'ONLINE'),
        take(1),
      );
  }

}
