import { ColorValue } from '../model/color-value';
import { ColorType } from '../model/color-type';

export class ColorVariant implements Record<ColorType, ColorValue> {

  constructor(
    public base: ColorValue,
    public hover: ColorValue = base,
    public active: ColorValue = base,
    public disabled: ColorValue = base,
  ) {
  }

}
