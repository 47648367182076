import { ToastConfig } from '../model/toast-config';

/**
 * Default toast config
 */
export const DEFAULT_TOAST_CONFIG: ToastConfig = {
  canClose: true,
  timeout: 5000,
  closeOnUrlChange: false,
};
