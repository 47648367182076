import { Injectable } from '@angular/core';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { UserActionType } from '../model/user-action.type';
import { UserTaskType } from '@shared/user-task/base/model/user-task.type';
import { ActionViewType } from '@shared/user-action-view/model/action-view-type';
import { map } from 'rxjs/operators';
import { UserActionPopupFlowConfigElementModel } from '../model/user-action-popup-flow-config-element.model';
import { captureException } from '@sentry/browser';
import { StringUtils } from '@util/util/string.utils';
import { Observable } from 'rxjs';
import merge from 'lodash-es/merge';
import isNil from 'lodash-es/isNil';
import { ConfigElementValueDto } from '@api/aukro-api/model/config-element-value-dto';

@Injectable({
  providedIn: 'root',
})
export class UserActionPopupFlowConfigService {

  private readonly DEFAULT_ACTION: string = 'DEFAULT';

  constructor(
    private readonly configuratorCacheService: ConfiguratorCacheService,
  ) {
  }

  public getTasksConfiguration$<T extends UserTaskType>(
    task: T,
    action?: UserActionType,
  ): Observable<UserActionPopupFlowConfigElementModel['tasks'][T]> {
    return this.getMergedPopupFlowConfigElementModel$(action)
      .pipe(
        map((configElementModel: UserActionPopupFlowConfigElementModel) => configElementModel?.tasks?.[task]),
      );
  }

  public getViewsConfiguration$<T extends ActionViewType>(
    view: T,
    action: UserActionType,
  ): Observable<UserActionPopupFlowConfigElementModel['views'][T]> {
    return this.getMergedPopupFlowConfigElementModel$(action)
      .pipe(
        map((configElementModel: UserActionPopupFlowConfigElementModel) => configElementModel?.views?.[view]),
      );
  }

  private getMergedPopupFlowConfigElementModel$(action?: UserActionType): Observable<UserActionPopupFlowConfigElementModel> {
    return this.configuratorCacheService.getPopupFlows()
      .pipe(
        map((configElements: ConfigElementValueDto[]) => {
          const defaultCe: ConfigElementValueDto = configElements.find((ce: ConfigElementValueDto) => ce.key === this.DEFAULT_ACTION);
          const defaultModel: UserActionPopupFlowConfigElementModel = this.mapConfigElementToConfigElementModel(defaultCe);

          if (isNil(action)) {
            return defaultModel;
          }

          const actionCE: ConfigElementValueDto = configElements.find((ce: ConfigElementValueDto) => ce.key === action);
          const actionModel: UserActionPopupFlowConfigElementModel = this.mapConfigElementToConfigElementModel(actionCE);

          return merge(defaultModel, actionModel);
        }),
      );
  }

  private mapConfigElementToConfigElementModel(configElement: ConfigElementValueDto): UserActionPopupFlowConfigElementModel {
    if (StringUtils.isEmpty(configElement?.value)) {
      return null;
    }

    let result: UserActionPopupFlowConfigElementModel = null;

    try {
      result = JSON.parse(configElement.value) as UserActionPopupFlowConfigElementModel;
    } catch (e) {
      const message: string = `Error while parsing config element of type POPUP_FLOW and key ${ configElement.key }`;
      console.error(message, e);
      captureException(message, {
        extra: {
          error: e,
        },
      });
    }

    return result;
  }

}
