<div>
  @if (showCloseInHeading) {
    <div
      class="close-button tw-text-right tw-pt-4 tw-px-4"
      >
      <auk-icon
        class="tw-cursor-pointer"
        iconType="MATERIAL"
        source="close"
        size="XL"
        colorCombination="TERTIARY_CONTRAST"
        (click)="onCloseDialogClick()"
      ></auk-icon>
    </div>
  }

  <div class="wrapper-content tw-overflow-y-auto tw-p-4 tw-overflow-x-hidden">
    <ng-content></ng-content>
  </div>
</div>
