import { Directive, ElementRef, forwardRef, Input, Inject, OnChanges, Optional, Renderer2, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { ɵgetDOM as getDOM } from '@angular/platform-browser';
import { createTextMaskInputElement } from 'text-mask-core';
import { TextMaskConfigModel } from '@common/text-mask/model/text-mask-config.model';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { TextMaskInputElementModel } from '@common/text-mask/model/text-mask-input-element.model';
import isNil from 'lodash-es/isNil';

/**
 * We must check whether the agent is Android because composition events
 * behave differently between iOS and Android.
 *
 * We cannot use {@link PlatformService} android check, because this check can behave differently than ours
 */
function isAndroid(): boolean {
  const dom = getDOM();
  const userAgent = isNotNil(dom) ? dom.getUserAgent() : '';

  return /android (\d+)/.test(userAgent.toLowerCase());
}

@Directive({
  selector: '[aukInputTextMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextMaskDirective),
      multi: true,
    },
  ],
  standalone: true,
})
export class TextMaskDirective implements ControlValueAccessor, OnChanges {

  @Input({ required: true }) public aukInputTextMask: TextMaskConfigModel;

  private textMaskInputElement: TextMaskInputElementModel;
  private inputElement: HTMLInputElement;

  /** Whether the user is creating a composition string (IME events). */
  private composing: boolean = false;

  constructor(
    @Optional() @Inject(COMPOSITION_BUFFER_MODE) private readonly compositionMode: boolean,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef<HTMLInputElement>,
  ) {
    if (isNil(this.compositionMode)) {
      this.compositionMode = !isAndroid();
    }
  }

  public ngOnChanges(): void {
    this.setupMask(true);

    if (isNotNil(this.textMaskInputElement)) {
      this.textMaskInputElement.update(this.inputElement.value);
    }
  }

  public writeValue(value: string): void {
    this.setupMask();

    // set the initial value for cases where the mask is disabled
    const normalizedValue = isNil(value) ? '' : value;
    this.renderer.setProperty(this.inputElement, 'value', normalizedValue);

    if (isNotNil(this.textMaskInputElement)) {
      this.textMaskInputElement.update(value);
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  public registerOnChange(fn: (v: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  @HostListener('input', ['$event'])
  protected onClick(event: Event): void {
    this.handleInput((event.target as HTMLInputElement).value);
  }

  @HostListener('blur')
  protected onBlur(): void {
    this.onTouched();
  }

  @HostListener('compositionstart', ['$event'])
  protected onCompositionStart(): void {
    this.compositionStart();
  }

  @HostListener('compositionend', ['$event'])
  protected onCompositionEnd(event: Event): void {
    this.compositionEnd((event.target as HTMLInputElement).value);
  }

  private handleInput(value: string): void {
    if (!this.compositionMode || (this.compositionMode && !this.composing)) {
      this.setupMask();

      if (isNotNil(this.textMaskInputElement)) {
        this.textMaskInputElement.update(value);

        // get the updated value
        value = this.inputElement.value;
        this.onChange(value);
      }
    }
  }

  private setupMask(create: boolean = false): void {
    if (!this.inputElement) {
      this.inputElement = this.elementRef.nativeElement;
    }

    if (this.inputElement && create) {
      this.textMaskInputElement = createTextMaskInputElement({
        inputElement: this.inputElement,
        ...this.aukInputTextMask,
        guide: true,
        placeholderChar: '_',
        keepCharPositions: false,
      });
    }

  }

  private compositionStart(): void {
    this.composing = true;
  }

  private compositionEnd(value: string): void {
    this.composing = false;
    this.compositionMode && this.handleInput(value);
  }

  private onChange: (_: string) => void = (_: string) => {
    // do nothing
  };

  protected onTouched: () => void = () => {
    // do nothing
  };

}

