import { Injectable } from '@angular/core';
import { UserActionPrerequisiteTaskFulfillmentService } from '../task-fulfillment/user-action-prerequisite-task-fulfillment.service';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';
import { UserTaskType } from '@shared/user-task/base/model/user-task.type';

@Injectable({
  providedIn: 'root',
})
export class UserActionPrerequisiteLoginFulfillmentService extends UserActionPrerequisiteTaskFulfillmentService<['LOGIN']> {

  public readonly prerequisiteCombination: ['LOGIN'] = ['LOGIN'];

  protected readonly taskType: UserTaskType = 'LOGIN';

  constructor(
    userTaskService: UserTaskService,
  ) {
    super(userTaskService);
  }

}
