import isNil from 'lodash-es/isNil';
import { Constants } from '@shared/constants';
import { MyAukroUserDataDto } from '@api/generated/defs/MyAukroUserDataDto';
import { DomainCode } from '@shared/platform/domain.model';
import { Nil } from '@util/helper-types/nil';
import * as model from '../../../../../api/generated/model';

export class MyDataUtils {

  private static getInitCountry(domain: DomainCode | null): string {
    return Constants.DEFAULT_COUNTRY_LIST.find(country => country.code === domain)?.code ?? Constants.DEFAULT_COUNTRY_CZ.code;
  }

  public static getDefaultCountryCode(domain: DomainCode, selectedCountry: string | null): string {
    return isNil(selectedCountry) ? this.getInitCountry(domain) : selectedCountry;
  }

  public static getDefaultCountryFormControlValue(
    appDomain: DomainCode,
    userRegistrationDomain: DomainCode | Nil,
    selectedCountry: string | null): string | {
    value: string;
    disabled?: boolean;
  } {
    return appDomain === 'SK' || userRegistrationDomain === 'SK'
      ? { value: 'SK', disabled: true }
      : this.getDefaultCountryCode(appDomain, selectedCountry);
  }

  public static getAccountAddress(userData: MyAukroUserDataDto): model.BaseAddressDto | Nil {
    return userData?.userAccountTypeDataDto?.accountType === 'USER' ?
      userData?.userPersonalDataDto?.address : userData?.userCompanyDataDto?.address;
  }

}
