import { Injectable } from '@angular/core';
import { UserActionPrerequisiteTaskFulfillmentService } from '../task-fulfillment/user-action-prerequisite-task-fulfillment.service';
import { UserTaskType } from '@shared/user-task/base/model/user-task.type';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';

@Injectable({
  providedIn: 'root',
})
export class UserActionPrerequisiteVerifiedEmailFulfillmentService
  extends UserActionPrerequisiteTaskFulfillmentService<['VERIFIED_EMAIL']> {

  public readonly prerequisiteCombination: ['VERIFIED_EMAIL'] = ['VERIFIED_EMAIL'];

  protected readonly taskType: UserTaskType = 'VERIFIED_EMAIL';

  constructor(
    userTaskService: UserTaskService,
  ) {
    super(userTaskService);
  }

}
