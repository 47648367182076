/**
 * Unit maps for key from API to Slovak strings including plurals
 */
export const UNIT_CODE_MAPPINGS_SK = {
  PAIR: {
    '=0': 'párov',
    '=1': 'pár',
    '=2': 'páry',
    '=3': 'páry',
    '=4': 'páry',
    other: 'párov',
  },
  PAIRS: 'PAIR',
  PIECE: {
    '=0': 'kusov',
    '=1': 'kus',
    '=2': 'kusy',
    '=3': 'kusy',
    '=4': 'kusy',
    other: 'kusov',
  },
  PIECES: 'PIECE',
  PIECE_SHORT: {
    other: 'ks',
  },
  SET: {
    '=0': 'sad',
    '=1': 'sada',
    '=2': 'sady',
    '=3': 'sady',
    '=4': 'sady',
    other: 'sad',
  },
  SETS: 'SET',
  BUY: {
    '=0': 'kúpilo',
    '=1': 'kúpil',
    '=2': 'kúpili',
    '=3': 'kúpili',
    '=4': 'kúpili',
    other: 'kúpilo',
  },
  BUYS: 'BUY',
  BET: {
    '=1': 'prihadzuje',
    '=2': 'prihadzujú',
    '=3': 'prihadzujú',
    '=4': 'prihadzujú',
    other: 'prihadzuje',
  },
  BETS: 'BET',
  BET_PAST: {
    '=1': 'prihadzoval',
    '=2': 'prihadzovali',
    '=3': 'prihadzovali',
    '=4': 'prihadzovali',
    other: 'prihadzovalo',
  },
  BETS_PAST: 'BET_PAST',
  ITEM: {
    '=0': 'položiek',
    '=1': 'položku',
    '=2': 'položky',
    '=3': 'položky',
    '=4': 'položky',
    other: 'položiek',
  },
  ITEMS: 'ITEM',
  DAY: {
    '=0': 'dní',
    '=1': 'deň',
    '=2': 'dni',
    '=3': 'dni',
    '=4': 'dni',
    other: 'dní',
  },
  DAYS: 'DAY',
  DAY_LOKATIV: {
    '=0': 'dní',
    '=1': 'dni',
    '=2': 'dňoch',
    '=3': 'dňoch',
    '=4': 'dňoch',
    other: 'dňoch',
  },
  DAYS_LOKATIV: 'DAY_LOKATIV',
  HOUR: {
    '=0': 'hodín',
    '=1': 'hodina',
    '=2': 'hodiny',
    '=3': 'hodiny',
    '=4': 'hodiny',
    other: 'hodín',
  },
  HOURS: 'HOUR',
  MINUTE: {
    '=0': 'minút',
    '=1': 'minúta',
    '=2': 'minúty',
    '=3': 'minúty',
    '=4': 'minúty',
    other: 'minút',
  },
  MINUTES: 'MINUTE',
  SECOND: {
    '=0': 'sekund',
    '=1': 'sekunda',
    '=2': 'sekundy',
    '=3': 'sekundy',
    '=4': 'sekundy',
    other: 'sekund',
  },
  SECONDS: 'SECOND',
  OTHER: {
    '=0': 'ďalší',
    '=1': 'ďalší',
    '=2': 'ďalší',
    '=3': 'ďalší',
    '=4': 'ďalší',
    other: 'ďalších',
  },
  OTHERS: 'OTHER',
  FILE: {
    '=0': 'súborov',
    '=1': 'súbor',
    '=2': 'súbory',
    '=3': 'súbory',
    '=4': 'súbory',
    other: 'súborov',
  },
  FILES: 'FILE',
  IMAGE: {
    '=0': 'obrázkov',
    '=1': 'obrázok',
    '=2': 'obrázky',
    '=3': 'obrázky',
    '=4': 'obrázky',
    other: 'obrázkov',
  },
  IMAGES: 'IMAGE',
  OFFER: {
    '=0': 'ponúk',
    '=1': 'ponuka',
    '=2': 'ponuky',
    '=3': 'ponuky',
    '=4': 'ponuky',
    other: 'ponúk',
  },
  OFFERS: 'OFFER',
  RESULT: {
    '=0': 'výsledkov',
    '=1': 'výsledok',
    '=2': 'výsledky',
    '=3': 'výsledky',
    '=4': 'výsledky',
    other: 'výsledkov',
  },
  RESULTS: 'RESULT',
  POSITIVE_COMMENT: {
    '=0': 'pozitívnych hodnotení',
    '=1': 'pozitívne hodnotenie',
    '=2': 'pozitívne hodnotenie',
    '=3': 'pozitívne hodnotenie',
    '=4': 'pozitívne hodnotenie',
    other: 'pozitívne hodnotenie',
  },
  POSITIVE_COMMENTS: 'POSITIVE_COMMENT',
  USER_GENITIV: {
    '=0': 'užívateľov',
    '=1': 'užívateľa',
    '=2': 'užívateľov',
    '=3': 'užívateľov',
    '=4': 'užívateľov',
    other: 'užívateľov',
  },
  USERS_GENITIV: 'USER_GENITIV',
  USER_INSTRUMENTAL: {
    '=0': 'užívateľom',
    '=1': 'užívateľom',
    other: 'užívatelia',
  },
  USERS_INSTRUMENTAL: 'USER_INSTRUMENTAL',
  NEW_EXPOSED_OFFER: {
    '=0': 'včera vystavených',
    '=1': 'včera vystavená',
    '=2': 'včera vystavené',
    '=3': 'včera vystavené',
    '=4': 'včera vystavené',
    other: 'včera vystavených',
  },
  NEW_EXPOSED_OFFERS: 'NEW_EXPOSED_OFFER',
  EXPOSED_OFFER: {
    '=0': 'vystavených',
    '=1': 'vystavenú',
    '=2': 'vystavené',
    '=3': 'vystavené',
    '=4': 'vystavené',
    other: 'vystavených',
  },
  EXPOSED_OFFERS: 'EXPOSED_OFFER',
  OFFER_AKUZATIV: {
    '=0': 'ponúk',
    '=1': 'ponuku',
    '=2': 'ponuky',
    '=3': 'ponuky',
    '=4': 'ponuky',
    other: 'ponúk',
  },
  OFFERS_AKUZATIV: 'OFFER_AKUZATIV',
  SELLER_GENITIV: {
    '=0': 'predajcov',
    '=1': 'predajca',
    '=2': 'predajcov',
    '=3': 'predajcov',
    '=4': 'predajcov',
    other: 'predajcov',
  },
  SELLERS_GENITIV: 'SELLER_GENITIV',
  ADDED_PASSIVE: {
    '=0': 'pridané',
    '=1': 'pridaná',
    '=2': 'pridané',
    '=3': 'pridané',
    '=4': 'pridané',
    other: 'pridané',
  },
  CHARS: {
    '=0': 'znakov',
    '=1': 'znak',
    '=2': 'znaky',
    '=3': 'znaky',
    '=4': 'znaky',
    other: 'znakov',
  },
  IN_LOKATIV: {
    '=0': 'v',
    '=1': 'v',
    '=2': 've',
    '=3': 've',
    '=4': 've',
    other: 'v',
  },
};
