import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslationSource } from '@common/translations/model/translation-source';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { Tooltip2Directive } from '@common/tooltip2/directive/tooltip2.directive';
import { Tooltip2Position } from '@common/tooltip2/tooltip2-position/model/tooltip2-position.type';
import { ColorCombinationId } from '@common/colors/model/color-combination-id';

@Component({
  selector: 'auk-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconComponent,
    Tooltip2Directive,
  ],
  host: {
    class: 'tw-inline-flex',
  },
})
export class HintComponent {

  @Input() public hintTooltip: TranslationSource;
  @Input() public tooltipPosition: Tooltip2Position = 'bottom';
  @Input() public iconColorCombination: ColorCombinationId = 'SILVERSTEIN_500_CONTRAST';

  /** Flag which tells if tooltip is disabled */
  @Input() public isTooltipDisabled: boolean = false;

}
