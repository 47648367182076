import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PhoneFieldComponent } from './phone-field.component';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { TrimDirectiveModule } from '@util/directive/trim/trim.directive.module';
import { Translate2Module } from '@common/translations/translate2.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    TrimDirectiveModule,
    Translate2Module,
  ],
  declarations: [
    PhoneFieldComponent,
  ],
  exports: [
    PhoneFieldComponent,
  ],
})
export class PhoneFieldModule {
}
