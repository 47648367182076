import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';

export const authorizationGuard: CanActivateFn | CanActivateChildFn =
  (_, state: RouterStateSnapshot): boolean => {
    const authenticationService = inject(AuthenticationService);

    if (authenticationService.isLoggedIn()) {
      return true;
    }

    void authenticationService.redirectToLoginPage({ queryParams: { returnUrl: state.url } });

    return false;
  };
