export class AukError extends Error {

  constructor(
    message: string,
    /**
     * Whether error should be logged
     */
    public readonly logError: boolean = true,
  ) {
    super(message);
  }

}
