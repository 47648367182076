<div class="tw-relative">

  <!-- CUSTOM -->
  @if (tooltipModel.type === 'CUSTOM') {
    <auk-tooltip2-custom [tooltipModel]="tooltipModel"/>
  } @else {
    <div class="tw-relative tw-flex tw-gap-4 tw-bg-silverstein-700 tw-rounded-lg tw-text-md tw-text-secondary tw-z-10"
      [ngClass]="containerClass"
      >
      <!-- INFORMATIVE -->
      @if (tooltipModel.type === 'INFORMATIVE') {
        <auk-tooltip2-informative
          [tooltipModel]="tooltipModel"
          />
      }
      <!-- ./INFORMATIVE -->
      <!-- BUTTONS -->
      @if (tooltipModel.type === 'BUTTONS') {
        <auk-tooltip2-buttons
          (clickAction)="handleButtonClick($event)"
          [tooltipModel]="tooltipModel"
          />
      }
      <!-- ./BUTTONS -->
      <!-- CLOSE BUTTON -->
      @if (showCloseButton) {
        <auk-button
          class="tw-absolute tw-top-3 tw-right-3"
          size="SM"
          colorCombination="SILVERSTEIN_700"
          (buttonClick)="closeClick.emit()"
          >
          <auk-icon source="close" iconType="MATERIAL" size="MD"/>
        </auk-button>
      }
      <!-- ./CLOSE BUTTON -->
    </div>
    <!-- The arrow is drawn based on the position of the host element -->
    <div #arrowElm
      [ngClass]="placement"
      class="tw-absolute tw-w-4 tw-h-4 tw-bg-silverstein-700 tw-rotate-45 tw-left-[-0.5rem] tw-right-[-0.5rem]"
    ></div>
  }
  <!-- CUSTOM -->

</div>
