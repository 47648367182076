import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGConfig, InlineSVGModule } from 'ng-inline-svg-2';

import { CustomInlineSVGConfig } from './inline-svg.config';
import { SvgIconComponent } from './svg-icon.component';

/**
 * @deprecated
 * use {@link SvgIconComponent} from 'ui-kit/svg-icon' folder
 */
@NgModule({
  imports: [
    CommonModule,
    InlineSVGModule.forRoot(),
  ],
  providers: [
    {
      provide: InlineSVGConfig, useClass: CustomInlineSVGConfig,
    },
  ],
  declarations: [SvgIconComponent],
  exports: [SvgIconComponent],
})
export class SvgIconModule {

}
