import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { NgClass } from '@angular/common';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';
import { BadgeComponent } from '@common/ui-kit/component/badge/component/badge/badge.component';
import { HeaderItemModel } from '@shared/app-header/module/app-header-user-controls/component/header-item/model/header-item.model';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { Nil } from '@util/helper-types/nil';
import { CountdownPanelComponent } from '@common/ui-kit/component/countdown-panel/component/countdown-panel.component';

@Component({
  selector: 'auk-header-item',
  templateUrl: './header-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconComponent,
    NgClass,
    BadgeComponent,
    CountdownPanelComponent,
  ],
})
export class HeaderItemComponent extends ColorCombinationInputComponent {

  @Input() public headerItemModel: HeaderItemModel | Nil;
  @Input() public isActive: boolean = false;

  constructor(
    elementRef: ElementRef<HTMLElement>,
    protected readonly responsivenessService: ResponsivenessService,
  ) {
    super(elementRef);
  }

}
