import { Injectable } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ComponentType } from '@angular/cdk/overlay';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import isNil from 'lodash-es/isNil';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { DialogService } from '@common/dialog/service/dialog.service';
import { DialogModel } from '@common/dialog/model/dialog.model';
import { BaseDialogType } from '@common/dialog/model/base-dialog.type';

/**
 * Service for opening material dialogs that ensures that multiple dialogs are not opened at the same time.
 */
@Injectable({
  providedIn: 'root',
})
export class MatDialogService<DIALOG_TYPE extends string> extends NgUnsubscribe {

  protected constructor(private readonly matDialog: MatDialog,
    private readonly modalService: DialogService<DIALOG_TYPE>) {
    super();
  }

  /**
   * Opens simple dialog when no other dialog is opened. Emits true when dialog is actually opened.
   * Use this method instead of this.matDialog.open(...)
   *
   * Usage:
   *
   * this.aukMatDialogService.openSimple$(SomeDialogComponent, { data: {anyData: data } } )
   *       .pipe(take(1))
   *       .subscribe();
   *
   *
   * Usage with further dialogResult processing:
   *
   * this.aukMatDialogService.openSimple$(SomeDialogComponent)
   *       .pipe(
   *          switchMap((dialogRef) => dialogRef.afterClosed()),
   *          take(1),
   *          takeUntil(this.ngUnsubscribe),
   *       )
   *       .subscribe(dialogResult => ...)
   *
   *    this.aukMatDialogService.openSimple$(SomeDialogComponent, { data: {anyData: data } } )
   *       .pipe(take(1))
   *       .subscribe();
   *
   */
  public openSimple$<COMPONENT_TYPE, DIALOG_CONFIG, DIALOG_RESULT = unknown>(
    component: ComponentType<COMPONENT_TYPE>,
    config?: MatDialogConfig<DIALOG_CONFIG>,
  ): Observable<MatDialogRef<COMPONENT_TYPE, DIALOG_RESULT>> {

    if (isNil(component)) {
      return EMPTY;
    }

    return this.open$(component, { type: 'SIMPLE_ACTION', id: this.simpleHash(component.name) }, config);
  }

  /**
   * Opens dialog when no other dialog is opened. Emits true when dialog is actually opened.
   * By specifying dialog model you can control priority and prevent duplicity of dialogs.
   */
  public open$<COMPONENT_TYPE, DIALOG_CONFIG, DIALOG_RESULT>(
    component: ComponentType<COMPONENT_TYPE>,
    modal: DialogModel<BaseDialogType | DIALOG_TYPE>,
    config?: MatDialogConfig<DIALOG_CONFIG>,
  ): Observable<MatDialogRef<COMPONENT_TYPE, DIALOG_RESULT>> {

    if (isNil(component)) {
      return EMPTY;
    }

    return this.modalService.open$(modal)
      .pipe(
        filter((isOpened) => isOpened === true),
        map(() => this.matDialog.open(component, config) as MatDialogRef<never, never>),
        tap((dialog) => this.closeAfterMatDialogClosed(dialog, modal)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      );
  }

  /**
   * Automatically closes dialog (needs to be done to queue works fine) when dialog is closed.
   */
  private closeAfterMatDialogClosed<COMPONENT_TYPE, DIALOG_RESULT>(
    dialog: MatDialogRef<COMPONENT_TYPE, DIALOG_RESULT>,
    modal: DialogModel<BaseDialogType | DIALOG_TYPE>): void {

    if (isNil(dialog)) {
      return;
    }

    dialog.afterClosed()
      .pipe(
        tap(() => this.modalService.close(modal)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  private simpleHash(input: string): string {
    let hash = 0;

    for (let i = 0; i < input.length; i++) {
      const charCode = input.charCodeAt(i);
      hash += charCode;
    }

    return String(hash);
  }

}
