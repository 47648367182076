import { Inject, Injectable } from '@angular/core';
// eslint-disable-next-line import/no-restricted-paths
import { DomainService } from '@shared/platform/domain.service';
import { APP_SCOPED_MEMORY_CACHE } from '../di/app-scoped-memory-cache.injection-token';
import { PROCESS_SCOPED_MEMORY_CACHE } from '../di/process-scoped-memory-cache.injection-token';
import { MemoryCache } from '../domain/memory-cache';
import { CacheBaseOperation } from '../model/cache-base-operation';
import { CacheScope } from '../model/cache-scope';
import { CacheSetOperation } from '../model/cache-set-operation';
import { CacheSimpleOperation } from '../model/cache-simple-operation';
import { Nil } from '@util/helper-types/nil';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

/**
 * Handles caching based on a config provided for each method.
 */
@Injectable({
  providedIn: 'root',
})
export class CacheService {

  constructor(
    @Inject(APP_SCOPED_MEMORY_CACHE) private appCache: MemoryCache<unknown>,
    @Inject(PROCESS_SCOPED_MEMORY_CACHE) private processCache: MemoryCache<unknown>,
    private readonly platformCommonService: PlatformCommonService,
    private readonly domainService: DomainService,
  ) { }

  public get(cfg: CacheSimpleOperation<string>): unknown {
    return this.getCacheToUse(cfg).get(cfg.key);
  }

  public has(cfg: CacheSimpleOperation<string>): boolean {
    return this.getCacheToUse(cfg).has(cfg.key);
  }

  public set(cfg: CacheSetOperation<string, unknown>): void {
    this.getCacheToUse(cfg).set(cfg.key, cfg.value, this.getTimeToLive(cfg));
  }

  public delete(cfg: CacheSimpleOperation<string>): boolean {
    return this.getCacheToUse(cfg).delete(cfg.key);
  }

  public addSsrLocaleSuffix(cacheKey: string): string | Nil {
    return `${ cacheKey }-${ this.domainService.locale.language }`;
  }

  /**
   * Decides the final ttl value based on a config.
   * @param cfg config for the cache operation.
   */
  private getTimeToLive(cfg: CacheSetOperation<string, unknown>): number {
    return (this.platformCommonService.isServer ? cfg.timeToLiveServer : cfg.timeToLiveClient) ?? Infinity;
  }

  /**
   * Decides which cache should be used based on a config.
   * @param cfg config for the cache operation.
   */
  private getCacheToUse(cfg: CacheBaseOperation): MemoryCache<unknown> {
    switch (cfg?.cacheScope) {
      case CacheScope.PROCESS:
        return this.processCache;
      case CacheScope.APP:
      default:
        return this.appCache;
    }
  }

}
