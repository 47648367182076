/**
 * Defines a scope of a cache
 */
export enum CacheScope {
  /**
   * Cache that exists as long as the process runs. This means it can persist through ng application runs.
   * On server side, this cache exists as long as the node process runs.
   * On client side, the scope is the same as {@link CacheScope.APP}.
   */
  PROCESS,
  /**
   * Cache that exists only for a single ng application run.
   * On server side, this cache is reinitialized for each render.
   * On client side it lives forever with the ng application.
   */
  APP,
}
