import { Injectable } from '@angular/core';
import {
  UserTaskVerifiedEmailStepResolutionHandlerService,
} from './user-task-verified-email-step-resolution-handler.service';
import { UserTaskStepResolutionUnionModel } from '../../base/model/user-task-step-resolution-union.model';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';

@Injectable({
  providedIn: 'root',
})
export class UserTaskVerifiedEmailStepNoEmailVerifiedResolutionHandlerService extends
  UserTaskVerifiedEmailStepResolutionHandlerService<'NO_EMAIL_VERIFIED'> {

  public handle(
    resolution: UserTaskStepResolutionUnionModel<'VERIFIED_EMAIL', 'NO_EMAIL_VERIFIED'>,
    taskPayload: UserTaskPayloadModel<'VERIFIED_EMAIL'>,
  ): UserTaskStepUnionModel<'VERIFIED_EMAIL'> {

    if (resolution.type === 'RESOLUTION_CLOSE') {
      throw new Error('User failed to verify email');
    }

    return null;
  }

}
