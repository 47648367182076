import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ChildrenOutletContexts, RouterOutlet } from '@angular/router';
import { AsyncPipe, NgClass } from '@angular/common';
import { SpinnerComponent } from '@common/ui-kit/component/spinner/component/spinner.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { BasePageComponent } from '@shared/base-page/component/base-page.component';
import isNil from 'lodash-es/isNil';
import { RouterOutletWithLoaderService } from '@shared/router-outlet-with-loader/service/router-outlet-with-loader.service';

@Component({
  selector: 'auk-router-outlet-with-loader',
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    SpinnerComponent,
    NgClass,
  ],
  templateUrl: './router-outlet-with-loader.component.html',
  styleUrl: './router-outlet-with-loader.component.scss',
  host: {
    class: 'tw-flex tw-w-full tw-justify-center tw-flex-grow',
  },
})
export class RouterOutletWithLoaderComponent extends BaseDestroy implements OnInit, OnDestroy {

  @ViewChild(RouterOutlet) private readonly routerOutlet: RouterOutlet;

  protected readonly _isLoaderShown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly childrenOutletContexts: ChildrenOutletContexts,
    private readonly routerOutletWithLoaderService: RouterOutletWithLoaderService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.routerOutletWithLoaderService.resetLoader();

    this.routerOutletWithLoaderService.resetLoader$
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.hideLoader();
      });

    this.routerOutletWithLoaderService.isNavigationInProgress$
      .pipe(
        startWith(true),
        switchMap((isInProgress) => {
          if (!this.routerOutlet?.isActivated || this.routerOutlet?.activatedRoute?.firstChild) {
            this.hideLoader();
            return of(null);
          }

          const primaryLeafOutlet = this.childrenOutletContexts.getContext('primary')?.outlet;
          const pageComponent = primaryLeafOutlet?.component as BasePageComponent;

          if (isNil(pageComponent?.isPageLoading$) || !pageComponent?.canShowOutletLoader) {
            this.hideLoader();
            return of(null);
          }

          if (isInProgress) {
            this.showLoader();
            return of(null);
          } else {
            if (isNil(pageComponent.isPageLoading$)) {
              this.hideLoader();
              return of(null);
            }
            return pageComponent.isPageLoading$
              .pipe(
                filter(isPageLoading => !isPageLoading),
                tap(() => {
                  this.hideLoader();
                }),
              );
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();

    this.routerOutletWithLoaderService.resetLoader();
  }

  protected get isLoaderShown$(): Observable<boolean> {
    return this._isLoaderShown$.asObservable();
  }

  private showLoader(): void {
    this._isLoaderShown$.next(true);
  }

  private hideLoader(): void {
    this._isLoaderShown$.next(false);
  }

}
