import { UserTaskPersonalDataStepComponent } from './user-task-personal-data-step.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UserService } from '@shared/user/service/user.service';
import {
  RequestWithHeadersCallback,
  TwoFactorVerificationComponent,
} from '@shared/two-factor-verification/two-factor-verification.component';
import { PhoneParams } from '@api/generated/api/UserMe';
import { InitBasicDataParams } from '@api/generated/api/User';
import { finalize, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import isNil from 'lodash-es/isNil';
import { DialogAlert } from '@common/dialog-wrapper/dialog-alert';
import { StringUtils } from '@util/util/string.utils';
import { HttpError } from '@shared/rest/model/http-error';
import { UserErrorUtil } from '@shared/user/util/user-error.util';
import { GaRegisterFormSectionEnum } from '@shared/google-analytics/model/ga-register-form-section.enum';

@Component({
  selector: 'auk-user-task-personal-data-step-user-basic-data',
  templateUrl: 'user-task-personal-data-step-user-basic-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskPersonalDataStepUserBasicDataComponent extends UserTaskPersonalDataStepComponent<'USER_BASIC_DATA'> {

  public gaRegisterFormSectionType = GaRegisterFormSectionEnum;
  public isSubmitting: boolean = false;
  public dialogAlerts: DialogAlert[] = [];

  /**
   * When basic data has been already initiated, user is allowed to change only phone number
   */
  public basicDataInit: boolean = false;

  @ViewChild(TwoFactorVerificationComponent, { static: false })
  public twoFactorVerificationComponent: TwoFactorVerificationComponent<void, PhoneParams>;

  constructor(
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  private isPhoneNotRequiredAndNotFilled(basicData: InitBasicDataParams): boolean {
    return !!(!this.payload.enforceVerifyPhone && StringUtils.isEmpty(basicData.initBasicDataFormDto.phone));
  }

  public onSubmit(basicData: InitBasicDataParams): void {
    if (isNil(basicData) || this.isSubmitting) {
      return;
    }

    // User's basic data could be initiated only once
    // If already initiated, call directly phone verification
    if (this.basicDataInit) {
      // if phone is not required and not filled skip TFV
      if (this.isPhoneNotRequiredAndNotFilled(basicData)) {
        this.onTfvSkip();
      }

      const params: PhoneParams = {
        verifyPhoneDto: {
          phone: basicData.initBasicDataFormDto.phone,
        },
      };

      this.sendTrackEvent(GaRegisterFormSectionEnum.REGISTER_PERSONAL_DATA_INPUT);
      this.twoFactorVerificationComponent.sendTfvWrappedRequest(params)
        .pipe(
          tap(() => this.onTfvSuccess()),
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe();
      return;
    }

    this.isSubmitting = true;
    this.dialogAlerts = [];
    this.userService.initBasicData(basicData)
      .pipe(
        take(1),
        finalize(() => {
          this.isSubmitting = false;
          this.changeDetectorRef.markForCheck();
        }),
        tap(() => {
          // JWT token is saved in interceptor
          this.onBasicDataInitiated();
        }),
        mergeMap(() => {
          this.sendTrackEvent(GaRegisterFormSectionEnum.REGISTER_PERSONAL_DATA_INPUT);

          // if phone is not required and not filled skip TFV
          if (this.isPhoneNotRequiredAndNotFilled(basicData)) {
            return of(void 0)
              .pipe(
                tap(() => this.onTfvSkip()),
              );
          }

          const params: PhoneParams = {
            verifyPhoneDto: {
              phone: basicData.initBasicDataFormDto.phone,
            },
          };

          return this.twoFactorVerificationComponent.sendTfvWrappedRequest(params)
            .pipe(
              tap(() => this.onTfvSuccess()),
            );
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        error: (httpError: HttpError) => {
          console.error(httpError);
          this.setDialogAlerts(httpError);
        },
      });
  }

  private setDialogAlerts(httpError: HttpError): void {
    if (UserErrorUtil.firstNameNotValid(httpError)) {
      this.dialogAlerts = [{ type: 'DANGER', text: { key: 'FIRSTNAME_NOT_ALLOWED_HTML' } }];
    } else if (UserErrorUtil.lastNameNotValid(httpError)) {
      this.dialogAlerts = [{ type: 'DANGER', text: { key: 'LASTNAME_NOT_ALLOWED_HTML' } }];
    } else {
      this.dialogAlerts = [{ type: 'DANGER', text: { key: 'BASIC_REGISTRATION_OVERALL_ERROR' } }];
    }
  }

  public sendTrackEvent(section: GaRegisterFormSectionEnum): void {
    // TODO(PDEV-15478) verify if we want to track TFV events like this in personal data task (copy from basic registration)
    // this.googleAnalyticsService.trackRegisterFormParams(section);
  }

  /**
   * Sets basic data already initiated
   * Disables form controls except phone number control
   * When basic data has been already initiated, user is allowed to change only phone number
   */
  private onBasicDataInitiated(): void {
    this.basicDataInit = true;
  }

  public verifyPhoneCallback: RequestWithHeadersCallback<void, PhoneParams> = (params: PhoneParams, headers: { [key: string]: string }) =>
    this.userService.updatePhone(params, headers);

  private checkIsEmailVerified(): Observable<boolean> {
    return this.userService.getActualStatistics(true)
      .pipe(
        map((data) => !!data?.emailVerified),
        take(1),
        takeUntil(this.ngUnsubscribe),
      );
  }

  private onTfvSuccess(): void {
    this.checkIsEmailVerified()
      .subscribe((isEmailVerified) => {

        if (isEmailVerified) {
          this.resolve.emit({
            type: 'RESOLUTION_PHONE_VERIFIED_EMAIL_VERIFIED',
            payload: {},
          });
        } else {
          this.resolve.emit({
            type: 'RESOLUTION_PHONE_VERIFIED_NO_EMAIL_VERIFIED',
            payload: {},
          });
        }

      });
  }

  public onTfvSkip(): void {
    this.checkIsEmailVerified()
      .subscribe((isEmailVerified) => {

        if (isEmailVerified) {
          this.resolve.emit({
            type: 'RESOLUTION_NO_PHONE_VERIFIED_EMAIL_VERIFIED',
            payload: {},
          });
        } else {
          this.resolve.emit({
            type: 'RESOLUTION_NO_PHONE_VERIFIED_NO_EMAIL_VERIFIED',
            payload: {},
          });
        }

      });
  }

  public onClose(): void {
    this.resolve.emit({
      type: 'RESOLUTION_CLOSE',
      payload: {},
    });
  }

}
