import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TranslateModule } from '@ngx-translate/core';

import { AvatarComponent } from './avatar.component';
import { DialogChangeAvatarComponent } from './dialog-images/dialog-change-avatar.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { Translate2Module } from '@common/translations/translate2.module';
import { Tooltip2Directive } from '@common/tooltip2/directive/tooltip2.directive';
import { ReplaceByPlaceholderOnErrorDirective } from '@common/image-loading/directive/replace-by-placeholder-on-error.directive';
import { SvgIconModule } from '@shared/legacy/component/svg-icon/svg-icon.module';
import { PreventDefaultLinkDirective } from '@common/routing/directive/prevent-default-link.directive';
import { AlertBannerModule } from '@shared/legacy/component/alert-banner/alert-banner.module';

@NgModule({
  imports: [
    MatDialogModule,
    TranslateModule,
    IconComponent,
    SvgIconComponent,
    Translate2Module,
    Tooltip2Directive,
    ReplaceByPlaceholderOnErrorDirective,
    SvgIconModule,
    PreventDefaultLinkDirective,
    AlertBannerModule,
  ],
  declarations: [
    AvatarComponent,
    DialogChangeAvatarComponent,
  ],
  exports: [
    AvatarComponent,
  ],
})
export class AvatarModule {
}
