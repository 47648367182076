import { Directive, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[aukStopPropagationOnClick]',
})
export class StopPropagationOnClickDirective {

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    event?.stopPropagation();
  }

}
