import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { UserBounceInfoDto } from '../model/user-bounce-info-dto';
import { BASE_PATH } from '../variables';

export interface ClickRecordedRequestParams {
    sleId: string;
}

export interface DeleteUserBounceRequestParams {
    userId: number;
}

export interface GetEmailBounceInfoRequestParams {
    userId: number;
}

export interface OneClickUnsubscribeRequestParams {
    sleId: string;
}

export interface UnsubscribeRequestParams {
    sleId: string;
}

export interface UnsubscribeClickRecordedRequestParams {
    sleId: string;
}

@Injectable({
  providedIn: 'root',
})
export class MailApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Record click
   */
  public clickRecorded$(
    requestParameters: ClickRecordedRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const sleId: string = requestParameters.sleId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'put',
      `${ BASE_PATH }/mail/clickRecorded/${ this.configuration.encodeParam({ name: 'sleId', value: sleId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * Delete user\&#39;s e-mail bounce
   */
  public deleteUserBounce$(
    requestParameters: DeleteUserBounceRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const userId: number = requestParameters.userId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'delete',
      `${ BASE_PATH }/mail/bounce/${ this.configuration.encodeParam({ name: 'userId', value: userId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }`,
      options,
    );
  }

  /**
   * Get user\&#39;s email bounce info
   */
  public getEmailBounceInfo$(
    requestParameters: GetEmailBounceInfoRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserBounceInfoDto> {
    const userId: number = requestParameters.userId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'UserBounceInfoDto'),
      headers,
    };
    return this.httpClient.request<UserBounceInfoDto>(
      'get',
      `${ BASE_PATH }/mail/bounce/${ this.configuration.encodeParam({ name: 'userId', value: userId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }`,
      options,
    );
  }

  /**
   * One-click unsubscribe
   */
  public oneClickUnsubscribe$(
    requestParameters: OneClickUnsubscribeRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const sleId: string = requestParameters.sleId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/mail/unsubscribe/${ this.configuration.encodeParam({ name: 'sleId', value: sleId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * Unsubscribe
   */
  public unsubscribe$(
    requestParameters: UnsubscribeRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const sleId: string = requestParameters.sleId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'put',
      `${ BASE_PATH }/mail/unsubscribe/${ this.configuration.encodeParam({ name: 'sleId', value: sleId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * Record click on unsubscribe link
   */
  public unsubscribeClickRecorded$(
    requestParameters: UnsubscribeClickRecordedRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const sleId: string = requestParameters.sleId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'put',
      `${ BASE_PATH }/mail/unsubscribeClickRecorded/${ this.configuration.encodeParam({ name: 'sleId', value: sleId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }`,
      options,
    );
  }

}
