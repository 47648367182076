import { TranslateLoader } from '@ngx-translate/core';
import { combineLatest, from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
// TODO: [PDEV-18307] - fix dependency
// eslint-disable-next-line import/no-restricted-paths
import { DomainService } from '@shared/platform/domain.service';
// eslint-disable-next-line import/no-restricted-paths
import { LocaleConstants } from '@shared/platform/locale.constants';
// eslint-disable-next-line import/no-restricted-paths
import { TranslationCacheService } from '@shared/services/translation-cache/translation-cache.service';

export class TranslationsLoader implements TranslateLoader {

  constructor(
    private readonly translationCacheService: TranslationCacheService,
    private readonly domainService: DomainService,
  ) {
  }

  //Combine translations from i18n assets and from Web Text.
  public getTranslation(lang: string): Observable<Record<string, string>> {
    const domainLang = this.domainService.lang;
    const translations$ = this.translationCacheService.getAll();
    const titlesJSON$ = this.getTitlesTranslationFiles(domainLang);
    const textJSON$ = this.getTextTranslationFiles(domainLang);

    return combineLatest([translations$, titlesJSON$, textJSON$])
      .pipe(
        map(([webTexts, titlesJSON, textJSON]) => ({
          ...textJSON,
          ...webTexts,
          ...titlesJSON,
        })),
        take(1),
      );
  }

  private getTitlesTranslationFiles(lang: string): Observable<Record<string, string>> {
    let translationFiles: Promise<Record<string, string>>;
    switch (lang) {
      case LocaleConstants.CZ_LOCALE.language:
        translationFiles = import(`../files/cs-titles.json`).then(res => res.default);
        break;
      case LocaleConstants.SK_LOCALE.language:
        translationFiles = import(`../files/sk-titles.json`).then(res => res.default);
        break;
      default:
        translationFiles = import(`../files/cs-titles.json`).then(res => res.default);
        break;
    }

    return from(translationFiles);
  }

  private getTextTranslationFiles(lang: string): Observable<Record<string, string>> {
    let translationFiles: Promise<Record<string, string>>;
    switch (lang) {
      case LocaleConstants.CZ_LOCALE.language:
        translationFiles = import(`../files/cs-text.json`).then(res => res.default);
        break;
      case LocaleConstants.SK_LOCALE.language:
        translationFiles = import(`../files/sk-text.json`).then(res => res.default);
        break;
      default:
        translationFiles = import(`../files/cs-text.json`).then(res => res.default);
        break;
    }

    return from(translationFiles);
  }

}
