import { Nil } from '@util/helper-types/nil';
import get from 'lodash-es/get';
import find from 'lodash-es/find';
import { ItemImageDto, ItemImageDtoSizeEnum } from '@api/aukro-api/model/item-image-dto';
import { ItemImageListsDto } from '@api/aukro-api/model/item-image-lists-dto';
import { ArrayUtils } from '@util/util/array.utils';

export class ItemImagesUtil {

  public static getTitleImage(images: ItemImageListsDto, size: ItemImageDtoSizeEnum = 'MEDIUM'): string | Nil {
    // Attempt to find images with specific size
    let imagesArray: ItemImageDto[] = get(images, ['lists', size.toLowerCase()], []);

    // Fallback to 'medium' if 'medium_preview' was requested but not found
    if (ArrayUtils.isEmpty(imagesArray) && size === 'MEDIUM_PREVIEW') {
      imagesArray = get(images, ['lists', 'medium'], []);
    }

    const titleImage = find(imagesArray, (image) => image.titleImage);

    return titleImage ? titleImage.url : null;
  }

}
