import { Inject, Injectable, Injector, Type } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { UserActionPrerequisiteModel } from '../model/user-action-prerequisite.model';
import isNil from 'lodash-es/isNil';
import { UserActionType } from '../model/user-action.type';
import { FulfillPrerequisiteResultModel } from '../model/fulfill-prerequisite-result.model';
import { UserActionPrerequisiteCombinationFulfillFnModel } from '../model/user-action-prerequisite-combination-fulfill-fn.model';
import { UserActionPrerequisiteFulfillmentService } from './fulfillment/user-action-prerequisite-fulfillment.service';
import { last, map, mergeMap } from 'rxjs/operators';
import merge from 'lodash-es/merge';
import { UserActionPrerequisiteType } from '../model/user-action-prerequisite.type';
import { ArrayUtils } from '@util/util/array.utils';
import { PrerequisiteFulfillmentInterface } from '../interface/prerequisite-fulfillment.interface';
import { Nil } from '@util/helper-types/nil';
import { USER_ACTION_PREREQUISITE_FULFILLMENT_SERVICE_TOKEN } from '../token/user-action-prerequisite-fulfillment-service.token';

@Injectable({
  providedIn: 'root',
})
export class UserActionPrerequisiteService {

  constructor(
    private readonly injector: Injector,
    @Inject(USER_ACTION_PREREQUISITE_FULFILLMENT_SERVICE_TOKEN)
    private readonly userActionPrerequisiteFulfillmentServiceList:
      Type<UserActionPrerequisiteFulfillmentService<UserActionPrerequisiteType[]>>[],
  ) {
  }

  public fulfillPrerequisites(
    prerequisites: UserActionPrerequisiteModel[],
    actionType: UserActionType,
    customFulfillFnArray: UserActionPrerequisiteCombinationFulfillFnModel[] = [],
    defaultResult: FulfillPrerequisiteResultModel | void = { requestUpdates: {} },
  ): Observable<FulfillPrerequisiteResultModel | void> {
    if (ArrayUtils.isEmpty(prerequisites)) {
      return throwError(() => new Error(`Can't fulfill any prerequisite because prerequisites to solve array is empty`));
    }

    const fulfillmentFnModels: PrerequisiteFulfillmentInterface<UserActionPrerequisiteType[]>[] =
      this.getFulfillmentFnModels(customFulfillFnArray);

    const allPrerequisiteTypesToSolve: UserActionPrerequisiteType[] = prerequisites
      .map((prerequisite) => prerequisite.type);

    // find fulfillmentFnModel which can solve the most prerequisites
    let idealFulfillmentFnModel: PrerequisiteFulfillmentInterface<UserActionPrerequisiteType[]> | Nil = null;
    const currentPrerequisiteTypesToSolve = [...allPrerequisiteTypesToSolve];
    while (isNil(idealFulfillmentFnModel) && !ArrayUtils.isEmpty(currentPrerequisiteTypesToSolve)) {
      // find all fulfillmentFnsModels that can solve the most of the prerequisites in single run
      idealFulfillmentFnModel = fulfillmentFnModels
        // every prerequisite from fulfillmentFnModel must be included in the wanted prerequisites
        // but the fulfillmentFnModel must not solve any unwanted prerequisite
        .find((fulfillmentFnModel) => ArrayUtils.hasSameLengthAndValues(
          currentPrerequisiteTypesToSolve,
          fulfillmentFnModel.prerequisiteCombination,
        ));

      // if possibleFulfillmentFnModels is empty, then remove last element from requiredPrerequisites and try again until we find any
      if (isNil(idealFulfillmentFnModel)) {
        currentPrerequisiteTypesToSolve.pop();
      }
    }

    if (isNil(idealFulfillmentFnModel)) {
      // throw Error if there is no any fulfillment model to resolve prerequisites
      return throwError(
        () => new Error(`Action taker not specified for prerequisites ${ JSON.stringify(allPrerequisiteTypesToSolve) }`),
      );
    }

    const [ prerequisitesToBeSolved, remainingPrerequisitesToSolve]: [UserActionPrerequisiteModel[], UserActionPrerequisiteModel[]] =
      prerequisites
        .reduce(
          ([toBeSolved, ToSolve], prerequisite) => {
            if (idealFulfillmentFnModel.prerequisiteCombination.includes(prerequisite.type)) {
              toBeSolved.push(prerequisite);
            } else {
              ToSolve.push(prerequisite);
            }
            return [toBeSolved, ToSolve];
          },
          [[], []] as [UserActionPrerequisiteModel[], UserActionPrerequisiteModel[]],
        );

    // fulfill by found fulfillmentFnModel
    return idealFulfillmentFnModel.fulfillPrerequisite(actionType, prerequisitesToBeSolved)
      .pipe(
        mergeMap((result) => {
          // if there are no remaining prerequisites to solve, return result
          if (ArrayUtils.isEmpty(remainingPrerequisitesToSolve)) {
            return of(result);
          }
          // otherwise recursively try to solve all prerequisites
          return this.fulfillPrerequisites(
            remainingPrerequisitesToSolve,
            actionType,
            customFulfillFnArray,
            result ?? { requestUpdates: {} },
          );
        }),
        map((result) => merge(result, defaultResult)),
        last(),
      );
  }

  private getFulfillmentFnModels(
    customFulfillFnArray: UserActionPrerequisiteCombinationFulfillFnModel[] = [],
  ): PrerequisiteFulfillmentInterface<UserActionPrerequisiteType[]>[] {
    return [
      ...customFulfillFnArray,
      ...this.getFulfillmentServiceInstances(),
    ];
  }

  private getFulfillmentServiceInstances(): PrerequisiteFulfillmentInterface<UserActionPrerequisiteType[]>[] {
    return this.userActionPrerequisiteFulfillmentServiceList
      .map((service) => this.injector.get(service));
  }

}
