// eslint-disable-next-line import/no-restricted-paths
import { DropdownDirective } from '@shared/legacy/directive/dropdown/dropdown.directive';
import { ChangeDetectorRef, Directive, ElementRef, Inject, Input } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { LayoutService } from '@common/layout/layout.service';
import { DialogService } from '@common/dialog/service/dialog.service';
import { DialogModel } from '@common/dialog/model/dialog.model';
import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

@Directive({
  selector: '[aukDialogDropdown]',
  standalone: true,
  exportAs: 'dialogDropdownExport',
  providers: [DropdownDirective],
})
export class DialogDropdownDirective extends DropdownDirective {

  @Input() public dialogModel: DialogModel<string> | Nil = null;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    layoutService: LayoutService,
    elementRef: ElementRef<HTMLElement>,
    ngZoneUtilService: NgZoneUtilService,
    @Inject(WINDOW_OBJECT) window: Window,
    protected dialogService: DialogService<string>,
  ) {
    super(window, changeDetectorRef, layoutService, elementRef, ngZoneUtilService);
  }

  public override toggle(isOpen: boolean = !this.isOpen): void {
    if (isNil(this.dialogModel)) {
      super.toggle(isOpen);
    }

    if (isOpen === true) {
      this.dialogService.open$(this.dialogModel)
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => super.toggle(isOpen));
    } else {
      this.dialogService.close(this.dialogModel);
      super.toggle(isOpen);
    }
  }

}
