import { ChangeDetectorRef, Directive, Host, Input, OnChanges, OnInit } from '@angular/core';
import { ItemScrollSliderItemType } from '@shared/item-scroll-slider/model/item-scroll-slider-item.type';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { ItemScrollSliderComponent } from '@shared/item-scroll-slider/component/item-scroll-slider.component';
import { ItemScrollSliderMapperService } from '@shared/item-scroll-slider/service/item-scroll-slider-mapper.service';
import { Nil } from '@util/helper-types/nil';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { forkJoin, Observable, of } from 'rxjs';
import { SavingRateModel } from '@shared/retail-price/model/saving-rate.model';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ItemScrollSliderDtoType } from '@shared/item-scroll-slider/model/item-scroll-slider-dto.type';
import { ItemCardConfig } from '@shared/item-card/model/item-card-config.model';
import { AdultContentService } from '@shared/services/adult-content/adult-content.service';
import { ArrayUtils } from '@util/util/array.utils';
import { ListingTypeService } from '@shared/listing/service/listing-type.service';
import { ItemScrollSliderItemModel } from '@shared/item-scroll-slider/model/item-scroll-slider-item.model';

/**
 * This directive is only applicable on auk-item-scroll-slider
 * It can map all the different data structures of items into single structure which is accepted by the slider component
 */
@Directive({
  selector: '[aukItemSliderItemMapper]auk-item-scroll-slider',
  standalone: true,
})
export class ItemScrollSliderItemMapperDirective extends NgUnsubscribe implements OnChanges, OnInit {

  @Input() public sliderMapperItems: ItemScrollSliderItemType[] = [];

  @Input() public dtoType: ItemScrollSliderDtoType | Nil;

  /**
   * IDs of items that are currently being added or removed from watched items and whose actions have not yet ended
   */
  @Input() public watchLoadingItemIds: number[] = [];

  private firstItemsChange: boolean = true;

  constructor(
    @Host() private readonly productScrollSliderComponent: ItemScrollSliderComponent,
    private readonly itemScrollSliderMapperService: ItemScrollSliderMapperService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly listingTypeService: ListingTypeService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly adultContentService: AdultContentService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.listenToUserAdultConfirmChange();
  }

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (changes.dtoType || changes.sliderMapperItems) {
      this.processMapperItems(this.sliderMapperItems, this.dtoType);
    } else if (changes.watchLoadingItemIds) {
      const previous: number[] = changes.watchLoadingItemIds.previousValue;
      const current: number[] = changes.watchLoadingItemIds.currentValue;

      // Find changed items by selecting those present in either the previous or current list, but not both
      const changed: number[] = [...previous, ...current].filter((id: number) => !previous.includes(id) || !current.includes(id));
      this.updateOnlySomeItems(changed);
    }
  }

  private processMapperItems(
    items: ItemScrollSliderItemType[],
    dtoType: ItemScrollSliderDtoType,
  ): void {
    if (ArrayUtils.isEmpty(items)) {
      return;
    }

    this.getConfigs$()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([savingRatesModel, itemCardConfig]: [SavingRateModel, ItemCardConfig]) => {
        const processedItems: ItemScrollSliderItemModel[] = items
          .map((item: ItemScrollSliderItemType, index: number) => this.itemScrollSliderMapperService.mapToBasicItemCard(
            item,
            dtoType,
            savingRatesModel,
            itemCardConfig,
            index,
            this.listingTypeService.getActiveListingType(),
            this.isWatchLoading(item.id),
          ))
          .filter(isNotNil);

        const previousValue: ItemScrollSliderItemModel[] = this.productScrollSliderComponent.items;

        this.productScrollSliderComponent.items = processedItems;

        this.productScrollSliderComponent.ngOnChanges({
          items: {
            isFirstChange: () => this.firstItemsChange,
            firstChange: this.firstItemsChange,
            currentValue: processedItems,
            previousValue,
          },
        });

        this.firstItemsChange = false;

        this.changeDetectorRef.markForCheck();
      });
  }

  private updateOnlySomeItems(itemIds: number[]): void {
    if (ArrayUtils.isEmpty(itemIds)) {
      return;
    }

    this.getConfigs$()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([savingRatesModel, itemCardConfig]: [SavingRateModel, ItemCardConfig]) => {
        const processedItems: ItemScrollSliderItemModel[] = this.sliderMapperItems
          .map((item: ItemScrollSliderItemType, index: number) => this.itemScrollSliderMapperService.mapToBasicItemCard(
            item,
            this.dtoType,
            savingRatesModel,
            itemCardConfig,
            index,
            this.listingTypeService.getActiveListingType(),
            this.isWatchLoading(item.id),
          ))
          .filter(isNotNil);

        const previousValue: ItemScrollSliderItemModel[] = this.productScrollSliderComponent.items;

        const updatedList: ItemScrollSliderItemModel[] = processedItems.reduce(
          (items: ItemScrollSliderItemModel[], item: ItemScrollSliderItemModel) => ArrayUtils.withReplacedItem(items, item),
          [ ...previousValue ],
        );

        this.productScrollSliderComponent.items = updatedList;

        this.productScrollSliderComponent.ngOnChanges({
          items: {
            isFirstChange: () => this.firstItemsChange,
            firstChange: this.firstItemsChange,
            currentValue: updatedList,
            previousValue,
          },
        });

        this.firstItemsChange = false;

        this.changeDetectorRef.markForCheck();
      });
  }

  private isWatchLoading(itemId: number): boolean {
    return this.watchLoadingItemIds?.includes(itemId) ?? false;
  }

  private listenToUserAdultConfirmChange(): void {
    this.adultContentService.afterAdultConfirmDialogClosed$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.processMapperItems(this.sliderMapperItems, this.dtoType);
      });
  }

  private getConfigs$(): Observable<[SavingRateModel, ItemCardConfig]> {
    return forkJoin([
      this.configuratorCacheService.getFeSystemParam<SavingRateModel>('MINIMUM_SAVINGS_RATE', 'JSON'),
      this.configuratorCacheService.getFeSystemParam<ItemCardConfig>('ITEM_CARD_CONFIG', 'JSON'),
    ])
      .pipe(
        take(1),
        catchError(() => of<[SavingRateModel, ItemCardConfig]>([null, null])),
      );
  }

}
