import { Injectable, Injector } from '@angular/core';
import { InlineSVGConfig } from 'ng-inline-svg-2';
import { PlatformService } from '@shared/platform/service/platform.service';

/**
 * @deprecated
 * use {@link CustomInlineSVGConfig} from 'ui-kit/svg-icon' folder
 */
@Injectable()
export class CustomInlineSVGConfig extends InlineSVGConfig {

  constructor(
    private platformService: PlatformService,
    private injector: Injector,
  ) {
    super();

    if (this.platformService.isServer) {
      this.baseUrl = `http://localhost:${ this.injector.get('SSR_PORT') }`;
    }
  }

}
