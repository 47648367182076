import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UserTaskVerifiedPhoneStepComponent } from './user-task-verified-phone-step.component';
import { UserService } from '@shared/user/service/user.service';
import { finalize, mergeMap, take, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PhoneNumberService } from '@shared/services/phone-number/phone-number.service';
import {
  RequestWithHeadersCallback,
  TwoFactorVerificationComponent,
} from '@shared/two-factor-verification/two-factor-verification.component';
import { PhoneParams } from '@api/generated/api/UserMe';
import { TranslationSource } from '@common/translations/model/translation-source';
import { HttpError } from '@shared/rest/model/http-error';

@Component({
  selector: 'auk-user-task-verified-phone-step-no-phone-verified',
  templateUrl: 'user-task-verified-phone-step-no-phone-verified.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskVerifiedPhoneStepNoPhoneVerifiedComponent
  extends UserTaskVerifiedPhoneStepComponent<'NO_PHONE_VERIFIED'>
  implements OnInit {

  @ViewChild(TwoFactorVerificationComponent, { static: false })
  public twoFactorVerificationComponent: TwoFactorVerificationComponent<void, PhoneParams>;

  public phoneFormGroup: FormGroup;
  public isSubmitInProgress: boolean = false;

  constructor(
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly phoneNumberService: PhoneNumberService,
  ) {
    super();
  }

  public get phoneControl(): FormControl {
    return this.phoneFormGroup?.get('phoneNumber') as FormControl;
  }

  public get phoneErrorMessage(): TranslationSource {
    return PhoneNumberService.getPhoneErrorMessageForFormControl(this.phoneControl);
  }

  public ngOnInit(): void {
    this.fetchUserPhoneNumber();
  }

  public onSubmit(): void {
    if (!this.phoneFormGroup?.valid) {
      this.phoneFormGroup?.markAllAsTouched();
      return;
    }

    const phone: string = this.phoneControl.value as string;

    const params: PhoneParams = {
      verifyPhoneDto: {
        phone,
      },
    };

    this.twoFactorVerificationComponent.sendTfvWrappedRequest(params)
      .pipe(
        finalize(() => this.isSubmitInProgress = false),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: () => {
          this.onSuccessfulVerify();
        },
        error: (error: HttpError) => {
          throw error;
        },
      });
  }

  public onSuccessfulVerify(): void {
    this.resolve.emit({
      type: 'RESOLUTION_SUCCESS',
      payload: {},
    });
  }

  private initPhoneFormGroup(currentPhoneNumber: string): void {
    this.phoneFormGroup = this.formBuilder.group({
      phoneNumber: [
        currentPhoneNumber,
        {
          validators: [Validators.required, Validators.maxLength(19)],
          asyncValidators: [this.phoneNumberService.phoneNumberValidator(this.changeDetectorRef)],
        },
      ],
    });

    this.phoneFormGroup.markAsUntouched();
  }

  private fetchUserPhoneNumber(): void {
    this.userService.getActualStatistics()
      .pipe(
        mergeMap((userStats) => this.userService.detail(userStats?.userId)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((accountDetailDto) => {
        this.initPhoneFormGroup(accountDetailDto.phone);
        this.changeDetectorRef.markForCheck();
      });
  }

  public verifyPhoneCallback: RequestWithHeadersCallback<void, PhoneParams> =
    (params: PhoneParams, headers: Record<string, string>) =>
      this.userService.updatePhone(params, headers);

}
