import { Inject, Injectable } from '@angular/core';
import { browserTracingIntegration, captureException, init, setUser } from '@sentry/browser';
import { Integration } from '@sentry/types';
import { takeUntil } from 'rxjs/operators';
import { environment } from '@environment';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { AuthCacheService } from '@shared/authentication/service/auth-cache.service';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { BuildInfoService } from '@shared/version/service/build-info.service';
import { StringUtils } from '@util/util/string.utils';
import { SentryUserModel } from './sentry-user.model';
import { AukWindow } from '@shared/model/auk-window.interface';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { ErrorUtil } from '@common/error/util/error-util';

@Injectable({
  providedIn: 'root',
})
export class SentryService extends NgUnsubscribe implements AppInitializer {

  constructor(
    private readonly platformCommonService: PlatformCommonService,
    private readonly authenticationService: AuthenticationService,
    private readonly authCacheService: AuthCacheService,
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
  ) {
    super();
    this.initUserChangeCheck();
  }

  public get sentryEnabled(): boolean {
    return this.platformCommonService.isBrowser && !PlatformCommonService.isDevMode && !StringUtils.isBlank(environment.SENTRY_DSN);
  }

  public init(): void {
    if (!this.sentryEnabled) {
      return;
    }

    init({
      dsn: environment.SENTRY_DSN,
      release: BuildInfoService.version,
      environment: environment.ENVIRONMENT,
      attachStacktrace: true,
      normalizeDepth: 0,
      allowUrls: [
        /.+\.aukro\.cloud/,
        /aukro\.cz/,
      ],
      tracesSampleRate: PlatformCommonService.isProductionMode ? 0.01 : 1,
      beforeSend(event, hint) {
        // allow send if there's no exception
        if (!hint?.originalException) {
          return event;
        }

        if (ErrorUtil.canLogError(hint.originalException, false)) {
          return null;
        }

        return event;
      },
      integrations: (integrations: Integration[]) => {
        integrations = integrations
          .filter(integration => integration.name !== 'TryCatch' );

        integrations.push(browserTracingIntegration({
          enableInp: true,
        }));

        return integrations;
      },
    });

    // Make Sentry logging globally accessible to be used in GTM
    this.window.captureExceptionToSentry = captureException;
  }

  private initUserChangeCheck(): void {
    this.authenticationService.getLoginStatusChangeWithStartValue()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        setUser(this.getCurrentUser());
      });
  }

  private getCurrentUser(): SentryUserModel {
    return {
      username: this.authenticationService.isLoggedIn() ? this.authCacheService.decodedToken?.sub : null,
      id: this.authCacheService.aukroToken,
    };
  }

}
