import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseActionViewComponent } from '../base-action-view/base-action-view.component';

@Component({
  selector: 'auk-watch-email-verified-no-basic-data-view',
  templateUrl: './watch-email-verified-no-basic-data-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatchEmailVerifiedNoBasicDataViewComponent extends BaseActionViewComponent<'WATCH_EMAIL_VERIFIED_NO_BASIC_DATA'> {

  public onFillBasicData(): void {
    this.closeDialog('PERSONAL_DATA');
  }

}
