import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';
import { PickupPointDto } from '@api/aukro-api/model/pickup-point-dto';

export interface ZasilkovnaSelectedBranch {
  closePacketaWidget?: boolean;
  // packetaBranchCreditCardPayment?: boolean;
  packetaBranchId?: number;
  packetaBranchName?: string;
  // packetaBranchOpeningHours?: string;
  packetaBranchPlace?: string;
  // packetaBranchUrl?: string;
  // packetaBranchZip?: string;
  /**  additional information about selected branch   */
  packetaSelectedData?: ZasilkovnaSelectedBranchData;
  // packetaSelectedId?: number;
}

export interface ZasilkovnaSelectedBranchData {
  branchCode: string;
  // city: string;
  // claimAssistant: boolean;
  country: string;
  // creditCardPayment: boolean;
  // currency: string;
  // directions: string;
  // directionsCar: string;
  // directionsPublic: string;
  // dressingRoom: boolean;
  // holidayEnd: any;
  // holidayStart: any;
  // id: number;
  // latitude: number;
  // longitude: number;
  // maxWeight: number;
  // name: string;
  nameStreet: string;
  // openingHours: any;
  // packetConsignment: boolean;
  // photos: any;
  // place: string;
  // special: string;
  // status: any;
  // street: string;
  // url: string;
  // wheelchairAccessible: string;
  // zip: string;
}

export interface ZasilkovnaConfig {
  /** current page url */
  webUrl: string;
  apiKey: string;
  primaryButtonColor: string;
  backgroundColor: string;
  fontColor: string;
  fontFamily: string;
  cssUrl: string;
  country?: ZasilkovnaCountries;
  widgetLanguage: string;
}

export type ZasilkovnaCountries = 'cz' | 'sk' | Nil;

export interface ZasilkovnaWidgetDialogData {
  country: ZasilkovnaCountries;
}

// TODO(PDEV-20297) - remove this const and refactor to shipping_method.pickupPointSelection
export const ZASILKOVNA_SHIPPING_CODES = [
  'ZASILKOVNA_CASH_ON_DELIVERY',
  'ZASILKOVNA',
  'ZASILKOVNA_SK',
  'ZASILKOVNA_CASH_ON_DELIVERY_SK',
  'AUKRO_ZASILKOVNA',
  'AUKRO_ZASILKOVNA_CZ_SK',
  'AUKRO_ZASILKOVNA_SK_CZ',
  'AUKRO_ZASILKOVNA_SK_SK',
];
// TODO(PDEV-11487) - refactor shipping ID to CODE
export const ZASILKOVNA_PICKUP_POINT_SHIPPING_IDS = [17, 18, 23, 26, 32, 31, 33, 20];

// mapping main zasilkovna method to children by main zasilkovna id and surcharge ID
// TODO: shouldnt be used anymore
export const ZASILKOVNA_IDS_SURCHARGES_MAP = {
  17: 19, // ZASILKOVNA -> ZASILKOVNA_ADDRESS
  18: 22, // ZASILKOVNA_CASH_ON_DELIVERY -> ZASILKOVNA_CASH_ON_DELIVERY_ADDRESS
  20: 21, // ZASILKOVNA_SK -> ZASILKOVNA_ADDRESS_SK
  23: 24, // ZASILKOVNA_CASH_ON_DELIVERY_SK -> ZASILKOVNA_CASH_ON_DELIVERY_ADDRESS_SK
  26: 25, // AUKRO_ZASILKOVNA -> AUKRO_ZASILKOVNA_ADDRESS
};

// TODO(PDEV-11487) - refactor shipping ID to CODE - get enum from api BE
export enum ZasilkovnaShippingCodeEnum {
  ZASILKOVNA = 'ZASILKOVNA',
  ZASILKOVNA_CASH_ON_DELIVERY = 'ZASILKOVNA_CASH_ON_DELIVERY',
  ZASILKOVNA_SK = 'ZASILKOVNA_SK',
  ZASILKOVNA_CASH_ON_DELIVERY_SK = 'ZASILKOVNA_CASH_ON_DELIVERY_SK',
  AUKRO_ZASILKOVNA = 'AUKRO_ZASILKOVNA',
  AUKRO_ZASILKOVNA_ADDRESS = 'AUKRO_ZASILKOVNA_ADDRESS',
}

export class ZasilkovnaWidgetHelper {

  public static isZasilkovnaAndHasEmptyPickupPoint(shippingOptionId: number, pickupPoint: PickupPointDto): boolean {
    return (
      ZASILKOVNA_PICKUP_POINT_SHIPPING_IDS.includes(shippingOptionId) && (
        isNil(pickupPoint)
        || isNil(pickupPoint.code)
        || isNil(pickupPoint.name)
        || isNil(pickupPoint.url)
      )
    );
  }

}
