import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SuggestAllResponseContentElementItem } from '@api/generated/defs/SuggestAllResponseContentElementItem';

@Component({
  selector: 'auk-suggestion-item',
  templateUrl: './suggestion-item.component.html',
  styleUrls: [
    './suggestion-item.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionItemComponent {

  @Input() public item: SuggestAllResponseContentElementItem;
  @HostBinding('class.focus') @Input() public focus: boolean = false;

}
