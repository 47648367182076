<div class="tw-flex tw-w-full tw-pb-4" [ngClass]="containerDesignClass">
  <div class="tw-flex tw-flex-row tw-w-full tw-items-center tw-h-full">

    <ng-content select="[slot=before-title]"></ng-content>

    <!--TITLE-->
    <h2 class="tw-text-silverstein-700 tw-m-0"
        [ngClass]="{
          'tw-truncate': isHeaderOnOneRow,
          'tw-text-md tw-font-medium': fontSize === 'MEDIUM',
          'tw-text-xl tw-font-bold': fontSize === 'LARGE',
        }"
      >
      @if (dialogTitle) {
        <span [aukInnerHtml]="dialogTitle"></span>
      } @else {
        <ng-content select="[slot=title]"></ng-content>
      }


    </h2>
    <!--./TITLE-->

    <div class="tw-flex tw-items-center tw-ml-auto tw-gap-4"
      [ngClass]="isFloatingCloseButton ? 'tw-absolute tw-right-5 tw-rounded-lg tw-bg-contrast tw-p-2 tw-z-50' : ''">
      <ng-content select="[slot=after-title]"></ng-content>

      <!--CLOSE BUTTON-->
      @if (showCloseButton) {
        <auk-icon
          class="tw-cursor-pointer"
          [id]="closeButtonId"
          iconType="MATERIAL"
          source="close"
          size="LG"
          colorCombination="SILVERSTEIN_700_CONTRAST"
          aukAnalyticsTracking
          (click)="onCloseClick()"
        ></auk-icon>
      }
      <!--./CLOSE BUTTON-->

      <!--DONE BUTTON-->
      @if (showDoneButton) {
        <auk-button
          colorCombination="PRIMARY"
          [chin]="true"
          size="MD-NORMAL"
          class="tw-w-24 tw-h-10"
          (click)="onDoneClick()"
          >
          {{ { key: 'SIMPLE_EXPOSE_DONE_DIALOG_BUTTON' } | translateSource }}
        </auk-button>
      }
      <!--DONE BUTTON-->

    </div>
  </div>
</div>
