import { Inject, Injectable } from '@angular/core';
import { Nil } from '@util/helper-types/nil';
import { WINDOW_OBJECT } from '@util/const/window-object';

/**
 * Service for work with browser's session storage.
 *
 * Try/catch in set and get methods is needed in situations,
 * when user has disallowed their storages or storages are unavailable.
 */
@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {

  constructor(@Inject(WINDOW_OBJECT) private readonly window: Window) {
  }

  /**
   * Get value from local storage. Return `null` in case of missing saved key or invalid value.
   * @param key Local storage key
   */
  public getItem<T>(key: string): T | Nil {
    try {
      return JSON.parse(this.window.sessionStorage.getItem(key)) as T;
    } catch (e) {
      return null;
    }
  }

  /**
   * Set value to local storage.
   * @param key Local storage key
   * @param data Any primitive value, array or object
   */
  public setItem(key: string, data: any): void {
    try {
      this.window.sessionStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      // do nothing
    }
  }

  /**
   * Remove value from local storage.
   * @param key Local storage key
   */
  public removeItem(key: string): void {
    try {
      this.window.sessionStorage.removeItem(key);
    } catch (e) {
      // do nothing
    }
  }

}
