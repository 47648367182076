<auk-dialog-wrapper-with-content class="tw-break-words" [dialogTitle]="data.title" (closeDialogClick)="onCloseClick()">
  <ng-container content>
    <p class="tw-text-center">{{ data.subtitle | translateSource }}</p>
  </ng-container>

  <ng-container actions>
    <auk-button colorCombination="PRIMARY" [chin]="true" class="tw-w-full tw-mb-2" (buttonClick)="onClickButton1()">
      {{ data.button1 | translateSource }}
    </auk-button>
    <auk-button class="tw-w-full tw-mb-2" (buttonClick)="onClickButton2()">
      {{ data.button2 | translateSource }}
    </auk-button>
  </ng-container>
</auk-dialog-wrapper-with-content>
