import { Inject, Injectable } from '@angular/core';
import { AsyncSubject, merge, Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { take, takeUntil } from 'rxjs/operators';
import { StringUtils } from '@util/util/string.utils';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { DateUtils } from '@util/util/date.utils';
import { PasswordRecoveryOptionDto } from '@api/aukro-api/model/password-recovery-option-dto';
import { VersionDto } from '@api/aukro-api/model/version-dto';

@Injectable({
  providedIn: 'root',
})
export class ShareDataService extends NgUnsubscribe {

  public successMessageText: string = '';
  public successMessageClass: string = 'success';
  public preserveOnNavigation: boolean = false;
  public successMessageHandle = new Subject<{ text: string; className: string }>();
  private passwordRecoveryInfo: PasswordRecoveryOptionDto;
  private userItemNumber: { [key: string]: number };
  private destroyMessageTimeout$: Subject<void> = new Subject<void>();
  private version = new AsyncSubject<VersionDto>();

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly translateService: TranslateService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public showSuccessMessage(text: string = '', className: string = 'success', preserveOnNavigation: boolean = false): void {
    this.destroyMessageTimeout$.next();

    this.successMessageText = text;
    this.successMessageClass = className;
    this.preserveOnNavigation = preserveOnNavigation;
    this.successMessageHandle.next({
      text: this.successMessageText,
      className,
    });

    if (text) {
      this.ngZoneUtilService.simpleTimerOut$(
        () => this.clearMessage(),
        merge(this.ngUnsubscribe, this.destroyMessageTimeout$),
        DateUtils.convertSecondsToMilliseconds(8),
      );
    }
  }

  public showSuccessMessageTranslated(messageKey: string = '', className: string = 'success', preserveOnNavigation: boolean = false): void {
    if (!StringUtils.isEmpty(messageKey)) {
      this.translateService.get(messageKey)
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(text => {
          this.showSuccessMessage(text, className, preserveOnNavigation);
        });
    }
  }

  public clearMessage(): void {
    this.destroyMessageTimeout$.next();
    this.successMessageHandle.next({ text: '', className: 'success' });
    this.successMessageText = '';
    this.successMessageClass = 'success';
    this.preserveOnNavigation = false;
  }

  public setPasswordRecoveryInfo(content: PasswordRecoveryOptionDto): void {
    this.passwordRecoveryInfo = content;
  }

  public getPasswordRecoveryInfo(): PasswordRecoveryOptionDto {
    return this.passwordRecoveryInfo;
  }

  public setItemNumber(content: { [key: string]: number }): void {
    this.userItemNumber = content;
  }

  public getItemNumber(): { [key: string]: number } {
    return this.userItemNumber;
  }

  public setVersion(data: VersionDto): void {
    this.version.next(data);
    this.version.complete();
  }

  public getVersion(): Observable<VersionDto> {
    return this.version.asObservable();
  }

}
