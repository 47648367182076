import { BaseViewResolver } from './base-view-resolver';
import { UserActionType } from '@shared/user-action/model/user-action.type';
import { Injectable } from '@angular/core';
import { UserTaskType } from '@shared/user-task/base/model/user-task.type';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';
import { take } from 'rxjs/operators';
import isNil from 'lodash-es/isNil';
import { ActionViewAnalyticsDataModel } from '../model/action-view-analytics-data.model';
import { PopupFlowGaService } from '@shared/popup-flow/service/popup-flow-ga.service';

@Injectable()
export class ExecuteTaskViewResolverService extends BaseViewResolver<UserTaskType> {

  constructor(
    private readonly userTaskService: UserTaskService,
    private readonly popupFlowGaService: PopupFlowGaService,
  ) {
    super();
  }

  public resolve(
    action: UserActionType,
    analyticsData: ActionViewAnalyticsDataModel,
    viewResult?: UserTaskType,
  ): void {
    // it means dialog was closed by close button
    if (isNil(viewResult)) {
      void this.popupFlowGaService.trackPopupFlowStepInterruptedView(
        action,
        analyticsData.viewName,
      );
      return;
    }

    this.userTaskService.executeTaskWithCommonPayload$(viewResult, { action }, action)
      .pipe(
        take(1),
      )
      .subscribe();
  }

}
