import { Injectable } from '@angular/core';
import { AsyncDataUpdateService } from '@shared/async-data-update/async-data-update.service';
import { AsyncUpdateConfigModel } from '@shared/async-data-update/model/async-update-config.model';
import { Observable } from 'rxjs';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { CookieService } from '@common/cookie/service/cookie.service';
import { UserMessagesWsService } from '@shared/websocket/service/user-messages.ws.service';
import { MessageWebsocketDto } from '@shared/model/message-websocket-dto.model';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { WsConnectionState } from '@shared/websocket/model/ws-connection-state.model';
import { map } from 'rxjs/operators';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Injectable({ providedIn: 'root' })
export class UserMessageAsyncDataUpdateService extends AsyncDataUpdateService<MessageWebsocketDto> {

  protected readonly defaultConfig: AsyncUpdateConfigModel = {
    mode: 'WS_ONLY',
    websocket: {
      connectTimeout: 8_000,
    },
  };

  protected wsState$: Observable<WsConnectionState> = this.userMessagesWsService.wsConnectionState$;

  constructor(
    configuratorCacheService: ConfiguratorCacheService,
    cookieService: CookieService,
    platformCommonService: PlatformCommonService,
    ngZoneUtilService: NgZoneUtilService,
    protected readonly userMessagesWsService: UserMessagesWsService,
  ) {
    super(configuratorCacheService, cookieService, platformCommonService, ngZoneUtilService, userMessagesWsService);
  }

  protected override websocketDataProvider$(): Observable<MessageWebsocketDto> {
    return this.userMessagesWsService.wsMessage$()
      .pipe(
        map(({ data }) => data),
      );
  }

}
