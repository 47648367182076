import isNil from 'lodash-es/isNil';
import { isObservable, Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { CacheAware } from '../model/cache-aware';
import { CacheSetOperation } from '../model/cache-set-operation';
import { CacheSimpleOperation } from '../model/cache-simple-operation';
import { CacheableConfig } from './cacheable-config';
import { ArrayUtils } from '@util/util/array.utils';
import { StringUtils } from '@util/util/string.utils';
import isObject from 'lodash-es/isObject';

type CacheableProperty<T, A extends unknown[]> = TypedPropertyDescriptor<(...a: A) => Observable<T>>;

const noKeyResolverForObjectParametersError: Error =
  new Error('Bad cacheable annotation usage. Please specify keyResolver when decorated method have object typed parameters.');

const emptyKeyError: Error =
  new Error('Bad cacheable annotation usage. Key must be non empty.');

/**
 *
 * @param config
 */
export function Cacheable<A extends unknown[]>(config: CacheableConfig<A> | string) {
  return <T>(
    target: CacheAware,
    methodName: string,
    descriptor: CacheableProperty<T, A>,
  ): CacheableProperty<T, A> => {

    const originalMethod: (...a: A) => Observable<T> = descriptor.value;

    const processedConfig: CacheableConfig<A> = typeof config === 'string'
      // set config key, if cacheable config is not set
      ? {
        key: config,
      }
      // use cacheable config
      : config;

    let cacheBusterRegistered = false;

    descriptor.value = function(this: CacheAware, ...args: A): Observable<T> {

      let cacheKey: string = !isNil(processedConfig.keyResolver) ?
        processedConfig.keyResolver.apply(this, args) :
        createCacheKey(checkCacheKey(processedConfig.key), args);

      if (processedConfig.localeDependent) {
        cacheKey = this.cacheService.addSsrLocaleSuffix(cacheKey);
      }

      if (!cacheBusterRegistered) {
        processedConfig?.cacheBuster?.subscribe(() => this.cacheService.delete(generateCacheSimpleOperation(cacheKey, processedConfig)));
        cacheBusterRegistered = true;
      }

      const cachedValue: T | Observable<T> =
        this.cacheService.get(generateCacheSimpleOperation(cacheKey, processedConfig)) as T | Observable<T>;

      if (isNil(cachedValue)) {
        const newValue: Observable<T> = originalMethod.apply(this, args)
          .pipe(
            tap(value => this.cacheService.set(generateCacheSetOperation(cacheKey, processedConfig, value))),
            shareReplay(1),
          );

        this.cacheService.set(generateCacheSetOperation(cacheKey, processedConfig, newValue));

        return newValue;
      }

      if (isObservable(cachedValue)) {
        return cachedValue;
      }

      return of(cachedValue);
    };

    return descriptor;
  };
}

/**
 *
 * @param cacheKey
 */
function checkCacheKey(cacheKey: string): string {
  if (StringUtils.isEmpty(cacheKey)) {
    throw emptyKeyError;
  }
  return cacheKey;
}

/**
 *
 * @param keyPrefix
 * @param args
 */
function createCacheKey<A extends unknown[]>(keyPrefix: string, args: A): string {
  if (ArrayUtils.isEmpty(args)) {
    return keyPrefix;
  }

  const objectTypeArgs = args.filter((e) => isObject(e));

  if (objectTypeArgs.length > 0) {
    throw noKeyResolverForObjectParametersError;
  }

  let paramsPart: string = '#';
  for (let i = 0; i < args.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    paramsPart = paramsPart.concat(`${ i }-${ args[i] }`);
    if (i !== args.length - 1) {
      paramsPart = paramsPart.concat('_');
    }
  }

  return keyPrefix.concat(paramsPart);
}

/**
 *
 * @param cacheKey
 * @param config
 */
function generateCacheSimpleOperation<M extends unknown[]>(cacheKey: string, config: CacheableConfig<M>): CacheSimpleOperation<string> {
  return {
    key: cacheKey,
    cacheScope: config?.cacheScope,
  };
}

/**
 *
 * @param cacheKey
 * @param config
 * @param value
 */
function generateCacheSetOperation<M extends unknown[]>(cacheKey: string, config: CacheableConfig<M>, value: unknown):
  CacheSetOperation<string, unknown> {
  return {
    ...generateCacheSimpleOperation(cacheKey, config),
    value,
    timeToLiveClient: config?.timeToLiveClient,
    timeToLiveServer: config?.timeToLiveServer,
  };
}

