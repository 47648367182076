<auk-authenticate-wrapper>
  <auk-dialog-content
    [dialogTitle]="payload.title"
    [dialogSubtitle]="payload.subtitle"
    [dialogAlerts]="dialogAlerts"
    [isActionsShown]="false"
    >
    <ng-container content>
      <auk-user-task-login-step-not-you
        [email]="payload.email"
        [isNotThatYou]="payload.isNotThatYou"
        (isNotThatYouClick)="onBackClick()"
      ></auk-user-task-login-step-not-you>

      @if (payload.allowedLoginMethods?.includes('EMAIL')) {
        <div class="tw-flex tw-flex-col tw-text-center tw-mt-4">
          <auk-login-with-password-form [inputPlaceholder]="payload.inputPlaceholder"
            [loginButton]="payload.loginButton"
            [crossDomainButton]="payload.crossDomainButton"
            [registerButton]="payload.registerButton"
            [regDomainCodeCrossDomain]="regDomainCrossDomainTranslationSource"
            [loginOrEmail]="payload.email"
            [hiddenPassword]="payload.hiddenPassword"
            [submitDisabled]="isSubmitting"
            (passwordSubmit)="onSubmit($event)"
            (redirectSubmit)="onSubmitRedirect($event)"
            (passwordTyping)="onPasswordTyping()"
          ></auk-login-with-password-form>
          <p class="tw-text-right tw-mt-2">
            <a aukPreventDefaultLink (click)="onForgotPasswordClick()">
              {{ payload.didYouForgetThePassword | translateSource }}
            </a>
          </p>
        </div>
      }

      @if (!isNativeIosApp) {
        @if (showDivider) {
          <div
            class="tw-w-full tw-h-[7px] tw-border-b tw-border-solid tw-border-b-tertiary-200 tw-text-center tw-my-4">
            <span class="tw-text-[13px] tw-px-4 tw-leading-none tw-bg-contrast tw-text-tertiary-400">
              {{ payload.orDividerText | translateSource }}
            </span>
          </div>
        }
        @if (showThirdPartyLoginButtons) {
          @if (payload.allowedLoginMethods?.includes('FACEBOOK')) {
            <auk-facebook-button
              [buttonText]="payload.continueViaFacebook"
              (facebookButtonClick)="loginViaFB()"
              class="tw-block tw-mt-8"
            ></auk-facebook-button>
          }
        }
      }

      <p class="tw-text-center tw-text-base tw-leading-sm tw-mt-8"
      [aukInnerHtml]="payload.terms | aukTranslateSourceTerms"></p>

    </ng-container>
  </auk-dialog-content>
</auk-authenticate-wrapper>
