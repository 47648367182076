<auk-two-factor-verification
    [requestCallback]="verifyPhoneCallback"
    [showSkipButton]="!payload.enforceVerifyPhone"
    (onSubmit)="sendTrackEvent(gaRegisterFormSectionType.REGISTER_PHONE_NUMBER_VERIFICATION)"
    (skipClicked)="onTfvSkip()"
>
  <auk-dialog-content
      [dialogTitle]="payload.title"
      [dialogSubtitle]="payload.subtitle"
      [dialogAlerts]="dialogAlerts"
      [isActionsShown]="false"
      [isOverflowAuto]="false"
  >
    <ng-container content>
      <auk-basic-registration-form
          [onlyPhoneEnabled]="basicDataInit"
          [isPhoneRequired]="payload.enforceVerifyPhone"
          [isSubmitting]="isSubmitting"
          (formSubmit)="onSubmit($event)"
      ></auk-basic-registration-form>

      <auk-button colorCombination="SECONDARY"
                  class="tw-w-full tw-mt-2"
                  (buttonClick)="onClose()"
      >
        {{ payload.closeButtonText | translateSource }}
      </auk-button>
    </ng-container>

  </auk-dialog-content>
</auk-two-factor-verification>
