import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserTaskLoginStepComponent } from './user-task-login-step.component';
import { RegistrationService } from '@api/generated/api/Registration';

@Component({
  selector: 'auk-user-task-login-step-set-password',
  templateUrl: 'user-task-login-step-set-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RegistrationService],
})
export class UserTaskLoginStepSetPasswordComponent extends UserTaskLoginStepComponent<'SET_PASSWORD'> {

  public onBackClick(): void {
    this.resolve.emit({
      type: 'RESOLUTION_LOGIN_EMAIL',
      payload: {
        sourceAction: this.payload.sourceAction,
      },
    });
  }

  public setPassword(): void {
    this.resolve.emit({
      type: 'RESOLUTION_SET_PASSWORD',
      payload: {
        email: this.payload.email,
      },
    });
  }

}
