import { StringUtils } from '@util/util/string.utils';
import { MemoryCache } from '../domain/memory-cache';
import { PROCESS_SCOPED_MEMORY_CACHE_TRANSFER_STATE_KEY } from '../transfer-state/process-scoped-memory-cache-transfer-state.key';
import { Nil } from '@util/helper-types/nil';
import { TransferState } from '@angular/core';

/**
 * Creates a memory cache for the browser from a provided transfer state.
 *
 * This function retrieves a serialized cache from the provided transfer state
 * using a specific key, then deserializes this cache to create a memory cache instance.
 * @param transferState
 */
export function ProcessScopedMemoryCacheBrowserFactory(transferState: TransferState): MemoryCache<string> {
  return MemoryCache.fromObject(deserializeCache(transferState.get(PROCESS_SCOPED_MEMORY_CACHE_TRANSFER_STATE_KEY, null)));
}

/**
 *
 * @param serializedCache
 */
function deserializeCache(serializedCache: string): Record<string, string> | Nil {
  if (StringUtils.isBlank(serializedCache)) {
    return null;
  }

  try {
    return JSON.parse(serializedCache) as Record<string, string>;
  } catch(e) {
    return null;
  }
}
