import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ShareDataService } from '@shared/services/share-data/share-data.service';
import { UserService } from '@shared/user/service/user.service';
import { IkemApiService } from '@api/aukro-api/api/ikem-api.service';

export const allowedToEditOfferGuard: CanActivateFn =
  (route): boolean | Observable<boolean> => {
    const router = inject(Router);
    const shareDataService = inject(ShareDataService);
    const userService = inject(UserService);
    const ikemApiService = inject(IkemApiService);

    const offerId = route.queryParams?.itemId as number;
    if (!offerId) {
      void router.navigate(['/']);
      return false;
    }
    let loggedUserId: number;

    return userService.getActualStatistics()
      .pipe(
        mergeMap((userStats) => {
          loggedUserId = userStats.userId;
          return ikemApiService.getQuestionForm$({ objectId: offerId, type: 'ITEM', questionTo: 'SELLER' });
        }),
        map((offerDetail) => {
          const offerAuthorId = offerDetail.receiver ? offerDetail.receiver.userId : null;

          if (loggedUserId !== offerAuthorId) {
            const editOfferAccessError = 'ERROR_MESSAGE_NOT_SELLER_IN_OFFER';
            shareDataService.showSuccessMessageTranslated(editOfferAccessError, 'error');
            void router.navigate(['/']);
            return false;
          }

          return true;
        }),
      );
  };
