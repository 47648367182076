import { PreloadingLcpCandidatePriority } from '@shared/preloading/model/preloading-lcp-candidate-priority';
import { BannerLcpConfModel } from '@shared/banner/model/banner-lcp-conf.model';

export const BANNERS_TO_REGISTER_AS_LCP: BannerLcpConfModel[] = [
  {
    page: 'CATEGORY_LISTING',
    placement: 'CATEGORY_BANNER_DESKTOP',
    priority: PreloadingLcpCandidatePriority.MEDIUM,
  },
  {
    page: 'CATEGORY_LISTING',
    placement: 'CATEGORY_BANNER_MOBILE',
    priority: PreloadingLcpCandidatePriority.MEDIUM,
  },
  {
    page: 'CATEGORY_LISTING',
    placement: 'CATEGORY_BANNER_POPUP',
    priority: PreloadingLcpCandidatePriority.MEDIUM_HIGH,
  },
];
