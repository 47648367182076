import isNumber from 'lodash-es/isNumber';
import isNil from 'lodash-es/isNil';
import moment, { Moment } from 'moment-mini-ts';
import { Nil } from '@util/helper-types/nil';

export interface TimeRange {
  from: moment.Moment;
  to: moment.Moment;
}

export class DateUtils {

  public static yesterday: TimeRange = {
    from: moment().subtract(1, 'days').startOf('day'),
    to: moment().subtract(1, 'days').endOf('day'),
  };

  /**
   * @param days the number of days we want to convert to milliseconds
   * @returns number of milliseconds in the provided amount of days
   */
  public static convertDaysToMilliseconds(days: number): number {
    if (!isNumber(days)) {
      return null;
    }

    return this.convertMinutesToMilliseconds(days * 24 * 60);
  }

  /**
   * @param minutes the number of minutes we want to convert to milliseconds
   * @returns number of milliseconds in the provided amount of minutes
   */
  public static convertMinutesToMilliseconds(minutes: number): number {
    if (!isNumber(minutes)) {
      return null;
    }

    return this.convertMinutesToSeconds(minutes) * 1000;
  }

  /**
   * @param seconds - the number of minutes we want to convert to milliseconds
   * @returns number of milliseconds in the provided amount of minutes
   */
  public static convertSecondsToMilliseconds(seconds: number): number | Nil {
    if (!isNumber(seconds)) {
      return null;
    }

    return seconds * 1000;
  }

  /**
   * @param minutes the number of minutes we want to convert to seconds
   * @returns number of seconds in the provided amount of minutes
   */
  public static convertMinutesToSeconds(minutes: number): number {
    if (!isNumber(minutes)) {
      return null;
    }

    return minutes * 60;
  }

  /**
   * Returns true if provided time is after now()
   * @param dateTime
   */
  public static isAfterNow(dateTime: string): boolean {
    return moment().isAfter(dateTime);
  }

  /**
   * Returns true if date is valid
   * @param date
   */
  public static isValid(date: string | Date): boolean {
    return !isNil(date) && moment(date).isValid();
  }

  /**
   * Check if input date is weekend
   * @param date
   */
  public static isWeekend(date: Moment): boolean {
    const dayOfWeek = date.day();
    return dayOfWeek === 0 || dayOfWeek === 6;
  }

  public static getNextNonWeekendDate(startDate: Moment): Moment {
    const date: Moment = startDate.clone();

    while (this.isWeekend(date)) {
      date.add(1, 'day');
    }

    return date;
  }

}
