import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Nil } from '@util/helper-types/nil';
import { BrowserService } from '@shared/platform/browser.service';

@Injectable({
  providedIn: 'root',
})
export class RoutesService extends NgUnsubscribe {

  public currentRoute$: BehaviorSubject<string | Nil> = new BehaviorSubject<string | Nil>(null);

  public isMyAukroPage$: Observable<boolean>;
  public isSimpleExposePage$: Observable<boolean>;
  public isHomepagePage$: Observable<boolean>;

  private _routeChange$: Subject<void> = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly browserService: BrowserService,
  ) {
    super();

    this.isMyAukroPage$ = this.getRouteCheckChange$('/moje-aukro/');
    this.isSimpleExposePage$ = this.getRouteCheckChange$('/jednoduche-vystaveni/');
    this.isHomepagePage$ = this.getRouteCheckChange$('/', true);

    this.initRouteChangeListener();

    this._routeChange$.asObservable()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.currentRoute$.next(this.router.url));
  }

  /**
   * Emits event everytime url changes
   */
  public get routeChange$(): Observable<void> {
    return this._routeChange$.asObservable();
  }

  private getRouteCheckChange$(routeName: string, exactMatch: boolean = false): Observable<boolean> {
    const currentRoute = `${ this.browserService.getPathname() }/`;

    return this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd),
        map((event: NavigationEnd | NavigationStart) => {
          const url = event instanceof NavigationEnd ? event.urlAfterRedirects : event.url;

          if (url === '/') {
            return url;
          }
          return `${ url }/`;
        }),
        startWith(currentRoute),
        map((currentUrl: string) => exactMatch ? currentUrl === routeName : currentUrl.startsWith(routeName)),
        distinctUntilChanged(),
      );
  }

  private initRouteChangeListener(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this._routeChange$.next();
      });
  }

}
