import { UserTaskStepResolutionHandlerService } from '../../base/service/user-task-step-resolution-handler.service';
import { UserTaskStepType } from '../../base/model/user-task-step.type';
import { Router } from '@angular/router';

export abstract class UserTaskPersonalDataStepResolutionHandlerService<STEP_TYPE extends UserTaskStepType<'PERSONAL_DATA'>>
  extends UserTaskStepResolutionHandlerService<'PERSONAL_DATA', STEP_TYPE> {

  protected getSetPasswordNavigationParams(): Parameters<Router['navigate']> {
    return [['/moje-aukro/muj-ucet/moje-udaje'], { queryParams: { editRow: 'PASSWORD' } }];
  }

}
