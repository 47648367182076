import { Component, HostBinding, Input } from '@angular/core';

/**
 * @deprecated
 * use {@link SpinnerComponent} from 'ui-kit/spinner' folder
 */
@Component({
  selector: 'auk-spinner-legacy',
  styleUrls: ['spinner.component.scss'],
  templateUrl: './spinner.component.html',
})
export class SpinnerComponent {

  @HostBinding('class.fullPage') @Input() public isFullPage: boolean = false;
  @Input() public message: string;

}
