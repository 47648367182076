<div class="value-plus">
  +
</div>
<div class="value-prefix">
  <input
    aukInputTrim
    class="form-ctrl"
    type="tel"
    [(ngModel)]="prefix"
    (ngModelChange)="processValue()"
    maxlength="4"
    [disabled]="control.disabled"
    (blur)="onBlur()"
    matInput
    >
</div>
<div class="value-main"
  [class.has-text]="main">
  <input
    #phoneInputElm
    aukInputTrim
    class="form-ctrl !tw-px-4"
    [ngClass]="{'inside-label': isLabelShown, 'has-error': hasError}"
    type="tel"
    (keypress)="keyPress($event)"
    [(ngModel)]="main"
    (ngModelChange)="processValue()"
    [disabled]="isPrefixEmpty || control.disabled"
    (blur)="onBlur()"
    (paste)="processValue()"
    (focus)="onFocus()"
    id="phone"
    maxlength="15"
    matInput
    >
  @if (isLabelShown) {
    <label for="phone" class="tw-text-tertiary-600">{{ 'PHONE_FIELD_COMPONENT_HTML_1' | translate }} <span>*</span></label>
    <span class="active-placeholder">{{ 'PHONE_FIELD_COMPONENT_HTML_1' | translate }}</span>
  }
</div>
