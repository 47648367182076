<auk-dialog-content
    [dialogTitle]="payload.title"
    [dialogSubtitle]="payload.subtitle"
    [dialogAlerts]="[{ type: 'WARNING', text: { key: 'SET_PASSWORD_TO_PROCEED' } }]"
    [isActionsShown]="false"
>

  <ng-container content>
    <auk-user-task-login-step-not-you
        [email]="payload.email"
        [isNotThatYou]="payload.isNotThatYou"
        (isNotThatYouClick)="onBackClick()"
    ></auk-user-task-login-step-not-you>

    <auk-button colorCombination="PRIMARY"
                class="tw-w-full"
                [chin]="true"
                (buttonClick)="setPassword()"
    >
      {{ payload.setPasswordButtonText | translateSource }}
    </auk-button>
  </ng-container>
</auk-dialog-content>
