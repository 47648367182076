import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AccountBlockTypeUserProfileDtoEnum } from '@api/generated/defs/UserProfileDto';
import { AvatarService } from './avatar.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { BadgeIcon, BadgeIconType } from '@shared/user-chip/components/user-account-type-badge/modul/user-account-type-badge-data.modul';
import { userAccountTypeBadgeContainer } from '@shared/user-chip/components/user-account-type-badge/constant/user-account-type-badge-data.constant';
import { Nil } from '@util/helper-types/nil';
import { AvatarSizeType } from '@shared/avatar/model/avatar-size.type';

@Component({
  selector: 'auk-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent extends NgUnsubscribe implements OnChanges, OnDestroy {

  @Input() public url: string;
  @Input() public showAccountState: boolean = false;
  @Input() public accountActivated: boolean = false;
  @Input() public showUploadAnchor: boolean = false;
  @Input() public accountBlockType: AccountBlockTypeUserProfileDtoEnum;
  @Input() public accountBusiness: boolean = false;
  @Input() public isAukroUser: boolean = false;
  @Input() public avatarSize: AvatarSizeType;

  @Output() public avatarDialogClose: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class.is-readonly') @Input() public isReadOnly: boolean = true;
  @HostBinding('class.is-big') @Input() public isBig: boolean = false;
  @HostBinding('class.is-max-big') @Input() public isMaxBig: boolean = false;

  protected badgeIcon: BadgeIcon | Nil = null;
  protected avatarImgLoadError: boolean = false;

  constructor(
    private readonly avatarService: AvatarService,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<AvatarComponent>): void {
    if (changes.url) {
      this.avatarImgLoadError = false;
    }

    if (
      changes.showAccountState
      || changes.accountActivated
      || changes.accountBlockType
      || changes.accountBusiness
    ) {
      if(this.accountBlockType === 'BLOCK') {
        this.setCorrectBadge('blocked_user');
      } else {
        if (!this.isAukroUser) {
          this.checkUserVerification();
        }
      }
    }
  }

  protected onAvatarClick(): void {
    if (this.isReadOnly) {
      return;
    }
    this.avatarService.openEditAvatarDialogIfCanEdit$()
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.avatarDialogClose.emit());
  }

  protected onAvatarImgLoadError(): void {
    this.avatarImgLoadError = true;
  }

  private setCorrectBadge(icon: BadgeIconType): void {
    this.badgeIcon = userAccountTypeBadgeContainer.find(badge => badge.icon === icon);
  }

  private checkUserVerification(): void {
    if(this.accountActivated) {
      this.setUserVerifiedBadge();
    } else {
      this.setCorrectBadge('unverified_user');
    }
  }

  private setUserVerifiedBadge(): void {
    this.setCorrectBadge(this.accountBusiness ? 'business_user' : 'verified_user');
  }

}
