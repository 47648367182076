import { YuspRecommendationResult } from './model/yusp-recommendation-result.model';

export interface YuspEventTrackingParams {
  type: YuspType;
  buyType?: YuspBuyType;
  eventType?: YuspEventType;
  itemId?: string;
  splitGroup?: YuspSplitGroupType;
  biddingPrice?: string;
  userMaximumBid?: string;
  quantity?: string;
  sellerId?: string;
  unitPrice?: string;
  scenarioId?: YuspRecommendationScenarioType;
  userId?: string;
  recId?: string;
  itemIds?: string[];
}

export type YuspViewEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  itemId: string;
} & YuspEventTrackingParams;

export type YuspBidEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  itemId: string;
  biddingPrice: string;
  userMaximumBid: string;
  sellerId: string;
} & YuspEventTrackingParams;

export type YuspBuyEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  buyType: YuspBuyType;
  quantity: string;
  unitPrice: string;
  sellerId: string;
  itemId: string;
} & YuspEventTrackingParams;

export type YuspLetterSendEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  itemId: string;
  sellerId: string;
} & YuspEventTrackingParams;

export type YuspFavoritesEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  sellerId: string;
  itemId: string;
} & YuspEventTrackingParams;

export type YuspRatingEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  sellerId: string;
} & YuspEventTrackingParams;

export type YuspAddToWatchListEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  itemId: string;
} & YuspEventTrackingParams;

export type YuspAddToCartEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  itemId: string;
} & YuspEventTrackingParams;

export type YuspShareEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  itemId: string;
} & YuspEventTrackingParams;

export type YuspSearchEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  query: string;
} & YuspEventTrackingParams;

/**
 * The user clicked on a recommended item.
 */
export type YuspRecClickEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  recId: string;
  itemId: string;
  scenarioId: YuspRecommendationScenarioType;
  mainCategoryIdentifier?: string; // only for W_MAIN_CATBOX scenario and SHOW_RECOMMENDATION, REC_CLICK and CLICK events needed!!
} & YuspEventTrackingParams;

export type YuspShowRecommendationParams = {
  type: YuspType;
  eventType: YuspEventType;
  recId?: string;
  scenarioId: YuspRecommendationScenarioType;
  itemIds: string[];
  mainCategoryIdentifier?: string; // only for W_MAIN_CATBOX scenario and SHOW_RECOMMENDATION, REC_CLICK and CLICK events needed!!
} & YuspEventTrackingParams;

/**
 * Click on Aukro recommendation
 */
export type YuspClickEventParams = {
  type: YuspType;
  eventType: YuspEventType;
  itemId: string;
  scenarioId: YuspRecommendationScenarioType;
  mainCategoryIdentifier?: string; // only for W_MAIN_CATBOX scenario and SHOW_RECOMMENDATION, REC_CLICK and CLICK events needed!!
} & YuspEventTrackingParams;

export interface YuspRecommendationParams {
  type: YuspType;
  callback?: (result: YuspRecommendationResult) => any;
  numberLimit: number;
  scenarioId: YuspRecommendationScenarioType;
  groupId?: string;
  groupSize?: number;
  groupSeq?: number;
  resultNames?: string[];
  mainCategory?: string;
  categoryIds?: string[];
  sellerId?: string;
  itemId?: string;
  query?: string;
  purchasedItemIds?: string[];
  currentItemId?: string;
  cartItemIds?: string[];
}

export type YuspType = 'event' | 'set' | 'recommendation';

export type YuspEventType =
    'BUY' | // The user bought an item.
    'VIEW' | // The user viewed the info page of an item.
    'BID' | // The user increased a bid in auction.
    'REC_CLICK' | // The user clicked on a recommended item.
    'CLICK' | // Click on Aukro recommendation
    'SHOW_RECOMMENDATION'  | //  A recommendation box (Yusp or Aukro) is shown to the user.
    'LETTER_SEND' | // Send a message to seller
    'ADD_TO_FAVORITES' | // The user added seller to favourites.
    'REMOVE_FROM_FAVORITES' | // The user removed seller from favourites.
    'RATING' | // The user rated a seller after purchase
    'ADD_TO_WATCH_LIST' | // The user clicked on “Sledovat” button.
    'ADD_TO_CART' | // The user added an item to the shopping cart.
    'SHARE' | // The user shared an item on social media.
    'SEARCH'; // The user searched for items using search fields.

export type YuspRecommendationScenarioType =
    'W_MAIN_SBID' | // Main Page - Selected bids
    'W_MAIN_CATBOX' | // Main page - Category boxes (new placements in the future Aukro home page)
    'W_CATEG_RECO' | // Category pages (except “Speciání nabídky” - “Special offers”)
    'W_ITEM_SELLER' | // Item Page (We recommend from this seller) - new placement
    'W_ITEM_YMAL' | // Item Page (You may also like) - new placement
    'W_ITEM_ENDED' | // Item Page - only for ended items
    'W_CART' | // Cart page - new placement
    'W_NOHIT' | // No result page - new placement
    'W_404' | // 404 page - new placement
    'W_AFTER_PURCHASE' | // After purchase page - new placement
    'W_SELLER'; // Personal page of a seller (seller page) - new placement

/**
 * FE enum for measurement object codes from table mo_code type 1
 * TODO(PDEV-14976) MeasurementObjectCode from DB - V2
 */
export type MeasurementObjectCode =
  'W_MAIN_SBID' |
  'W_MAIN_BEST' |
  'W_CATEG_RECO' |
  'W_ITEM_MY_LAST_VIEWED' |
  'W_ITEM_SELLER' |
  'W_ITEM_SELLER_ENDED' |
  'W_ITEM_SELLER_MORE_FROM_CATEGORY' |
  'W_ITEM_MORE_FROM_CATEGORY' |
  'W_ITEM_YMAL' |
  'W_ITEM_YMAL_ENDED' |
  'W_CART' |
  'W_NOHIT' |
  'W_404' |
  'W_AFTER_PURCHASE' |
  'W_SELLER' |
  'W_MAIN_CATBOX_DUM_A_ZAHRADA' |
  'W_MAIN_CATBOX_ELEKTRONIKA' |
  'W_MAIN_CATBOX_SBERATELSTVI_A_UMENI' |
  'W_MAIN_CATBOX_MODA_A_KRASA' |
  'W_MAIN_CATBOX_AUTO_MOTO' |
  'W_MAIN_CATBOX_SPORT' |
  'W_MAIN_CATBOX_PRO_DETI' |
  'W_MAIN_CATBOX_POCITACE_A_HRY' |
  'W_MAIN_CATBOX_KOSMETIKA_PARFEMY' |
  'W_MAIN_CRAZY_PRICES_ITEMS' |
  'W_MAIN_HOT_AUCTIONS' |
  'W_MAIN_ENDING_HOT_ITEMS' |
  'W_MAIN_MY_LAST_VIEWED' |
  'W_MAIN_CATBOX_HUDBA_A_KNIHY' |
  'W_MAIN_CATBOX_ZIMNI_SPORTY' |
  'W_MAIN_CATBOX_NABYTEK' |
  'W_MAIN_CATBOX_OBLECENI_A_DOPLNKY' |
  'W_MAIN_CATBOX_MY_AUKRO_PURCHASED' |
  'W_MAIN_CATBOX_MY_AUKRO_NOTWON' |
  'W_MAIN_CATBOX_MY_AUKRO_WATCH_ACTIVE' |
  'W_MAIN_CATBOX_MY_AUKRO_WATCH_ENDED' |
  'W_MAIN_CATBOX_MY_AUKRO_FAVOURITE_SEARCH' |
  'W_MAIN_CATBOX_MY_AUKRO_FAVOURITE_SELLER' |
  'W_MAIN_CATBOX_MY_AUKRO_SELL' |
  'W_MAIN_CATBOX_MY_AUKRO_SOLD';

/**
 * if user buy an item in auction, buyType should be set as auction and unitPrice should be the price of the item sold in auction.
 * If user buy an item as buynow, buyType should be set as buynow and unitPrice should be equal to buyNowPrice of an item.
 */
export type YuspBuyType = 'auction' | 'buynow';

export type YuspSplitGroupType = 'aukro' | 'yusp';

export type EndedItemSplitGroupType = 'A' | 'B';

export type YuspItemType = 'BIDDING' | 'BUYNOW' | 'BIDDING_BUYNOW';

export const YuspRecommendationResultNames: string[] = [
  'link',
  'itemId',
  'title',
  'type',
  'categoryIdPath',
  'biddingPrice',
  'buyNowPrice',
  'priceWithShipping',
  'freeShipping',
  'sellerAukroPlus',
  'imageUrlMedium',
  'paidPromoPriorityList',
  'paidPromoCategoryPage',
  'paidPromoMainPage',
  'paidPromoBoldTitle',
  'paidPromoHighlight',
  'endingTime',
];
