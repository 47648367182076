import { UserTaskLoginStepResolutionHandlerService } from './user-task-login-step-resolution-handler.service';
import { UserTaskStepResolutionUnionModel } from '../../base/model/user-task-step-resolution-union.model';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserTaskLoginStepLoginEmailResolutionHandlerService extends UserTaskLoginStepResolutionHandlerService<'LOGIN_EMAIL'> {

  public handle(
    resolution: UserTaskStepResolutionUnionModel<'LOGIN', 'LOGIN_EMAIL'>,
    taskPayload: UserTaskPayloadModel<'LOGIN'>,
  ): UserTaskStepUnionModel<'LOGIN'> {
    if (resolution.type === 'RESOLUTION_SET_PASSWORD') {
      return {
        type: 'SET_PASSWORD',
        payload: {
          ...taskPayload?.stepsText?.SET_PASSWORD,
          email: resolution.payload.email,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_PASSWORD_OR_THIRD_PARTY') {
      return {
        type: 'PASSWORD_OR_THIRD_PARTY',
        payload: {
          sourceAction: taskPayload?.sourceAction,
          title: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.title,
          subtitle: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.subtitle,
          inputPlaceholder: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.inputPlaceholder,
          isNotThatYou: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.isNotThatYou,
          didYouForgetThePassword: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.didYouForgetThePassword,
          loginButton: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.loginButton,
          orDividerText: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.orDividerText,
          continueViaFacebook: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.continueViaFacebook,
          terms: taskPayload?.stepsText?.PASSWORD_OR_THIRD_PARTY?.terms,
          email: resolution.payload.email,
          allowedLoginMethods: resolution.payload.registrationChannels,
          hiddenPassword: resolution.payload.hiddenPassword,
          regDomainCode: resolution.payload.regDomainCode,
          dialogAlerts: resolution.payload.dialogAlerts,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_EMAIL_CONFIRMATION_NON_REGISTERED_VIA_EMAIL') {
      return {
        type: 'EMAIL_CONFIRMATION_NON_REGISTERED_VIA_EMAIL',
        payload: {
          ...taskPayload?.stepsText?.EMAIL_CONFIRMATION_NON_REGISTERED_VIA_EMAIL,
          sourceAction: taskPayload?.sourceAction,
          email: resolution.payload.email,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_EMAIL_CONFIRMATION_REGISTERED_VIA_FB') {
      return {
        type: 'EMAIL_CONFIRMATION_REGISTERED_VIA_FB',
        payload: {
          ...taskPayload?.stepsText?.EMAIL_CONFIRMATION_REGISTERED_VIA_FB,
          sourceAction: taskPayload?.sourceAction,
          email: resolution.payload.email,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_FB_LOAD') {
      return {
        type: 'FB_LOAD',
        payload: {
          sourceAction: taskPayload?.sourceAction,
          ...taskPayload?.stepsText?.FB_LOAD,
        },
      };
    }
  }

}
