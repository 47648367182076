<div class="tw-flex tw-flex-col tw-items-start">
  @if (tooltipModel?.buttons) {
    <div class="tw-flex tw-rounded-lg tw-gap-2 tw-items-center">
      @for (button of tooltipModel.buttons; track button) {
        <auk-button
          colorCombination="SILVERSTEIN_600_500"
          class="tw-flex tw-h-10 text-base"
          size="MD-NORMAL"
          (buttonClick)="handleButtonClick(button.action)"
          >
          <div class="tw-flex tw-items-center">
            @if (button.text) {
              {{ button.text | translateSource }}
            }
            @if (button.icon) {
              <auk-icon iconType="MATERIAL" source="close" size="LG"></auk-icon>
            }
          </div>
        </auk-button>
      }
    </div>
  }
</div>
