@if (connectionStatus) {
  <!-- ONLINE STATE -->
  <div class="connection-info-panel tw-bg-surface-blue-0 tw-text-matter-neutral-primary"
    [ngClass]="{'tw-invisible tw-w-0 tw-h-0 !tw-p-0': connectionStatus.connectionStatusType !== 'ONLINE'}"
    data-testid="online-panel"
    (click)="onOnlineReloadPageClick()">
    <span>
      <span class="tw-font-bold">{{
        {
        key: 'CONNECTION_INFO_PANEL_LABEL_BOLD_ONLINE',
        defaultValue: "Vaše internetové připojení je zpět."
        } | translateSource
      }}</span>
      {{
      {
      key: 'CONNECTION_INFO_PANEL_LABEL_ONLINE',
      defaultValue: "Aby vše fungovalo správně, klikněte pro přenačtení stránky."
      } | translateSource
      }}
    </span>
    <ng-container [ngTemplateOutlet]="refreshOrSpinnerTmpl"
      [ngTemplateOutletContext]="{colorCombination: 'SILVERSTEIN_900_CONTRAST'}"
    ></ng-container>
  </div>
  <!-- ./ONLINE STATE -->
  <!-- OFFLINE STATE -->
  <div class="connection-info-panel tw-bg-surface-neutral-1-invert tw-text-matter-neutral-primary-invert"
    [ngClass]="{'tw-invisible tw-w-0 tw-h-0 !tw-p-0': connectionStatus.connectionStatusType !== 'OFFLINE'}"
    data-testid="offline-panel"
    (click)="onOfflineReloadPageClick()">
    <auk-icon source="aukro-offline"
      size="XMD"
      iconType="SVG"
      colorCombination="PRIMARY">
    </auk-icon>
    <span>
      <span class="tw-font-bold">{{
        {
        key: 'CONNECTION_INFO_PANEL_LABEL_BOLD_OFFLINE',
        defaultValue: "Nejste připojeni k internetu."
        } | translateSource
      }}</span>
      {{
      {
      key: 'CONNECTION_INFO_PANEL_LABEL_OFFLINE',
      defaultValue: "Zkontrolujte své připojení a zkuste to prosím znovu."
      } | translateSource
      }}
    </span>
    <ng-container [ngTemplateOutlet]="refreshOrSpinnerTmpl"
      [ngTemplateOutletContext]="{colorCombination: 'PRIMARY'}"
    ></ng-container>
  </div>
  <!-- ./OFFLINE STATE -->
}

<!-- REFRESH OR SPINNER -->
<ng-template let-colorCombination="colorCombination" #refreshOrSpinnerTmpl>
  @if (connectionCheckSpinner) {
    <auk-spinner
      size="SM"
      [colorCombination]="colorCombination"
      class="!tw-w-auto !tw-h-auto">
    </auk-spinner>
  } @else {
    <auk-icon source="aukro-refresh"
      size="XMD"
      iconType="SVG"
      [colorCombination]="colorCombination">
    </auk-icon>
  }
</ng-template>
<!-- ./REFRESH OR SPINNER -->
