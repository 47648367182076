import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FavouriteSearchesDto } from '@api/aukro-api/model/favourite-searches-dto';
import { take, takeUntil } from 'rxjs/operators';
import { delayIndicator } from '@util/rxjs-operators/delay-indicator';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { trackByIndexFn } from '@util/helper-functions/track-by/track-by-index.fn';
import { FavouriteEmptyModel } from '@shared/favourite/model/favourite-empty.model';
import { FavouriteClickModel } from '@shared/favourite/model/favourite-click.model';
import { Router } from '@angular/router';
import { FavouritePopoverDataProviderService } from '@shared/favourite/service/favourite-popover-data-provider.service';
import { FavouriteSearchDto } from '@api/aukro-api/model/favourite-search-dto';
import { ListingRoutingService } from '@shared/listing/service/listing-routing.service';
import { UrlInfoModel } from '@common/routing/model/url-info.model';

@Component({
  selector: 'auk-favourite-search-popover',
  templateUrl: './favourite-search-popover.component.html',
  styleUrls: ['./favourite-search-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouritePopoverSearchComponent extends NgUnsubscribe implements OnInit {

  @Output() public measuredClick: EventEmitter<FavouriteClickModel> = new EventEmitter<FavouriteClickModel>();

  protected readonly trackByIndexFn = trackByIndexFn;

  protected favouriteSearch: FavouriteSearchesDto;
  protected dataLoading: boolean = true;
  protected emptyPopoverContent: FavouriteEmptyModel = {
    title: { key: 'FAVOURITES_POPOVER_SEARCH_NO_DATA_TITLE' },
    label: { key: 'FAVOURITES_POPOVER_SEARCH_NO_DATA_LABEL' },
  };

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly favouritePopoverDataProviderService: FavouritePopoverDataProviderService,
    private readonly listingRoutingService: ListingRoutingService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.favouritePopoverDataProviderService.getFavouriteSearchPreview$()
      .pipe(
        take(1),
        delayIndicator(
          this.ngZoneUtilService,
          () => {
            this.changeDetectorRef.detectChanges();
          },
          () => {
            this.dataLoading = false;
            this.changeDetectorRef.detectChanges();
          },
          500,
        ),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(
        data => {
          this.dataLoading = false;
          this.favouriteSearch = data;
          this.changeDetectorRef.detectChanges();
        },
      );
  }

  protected onSearchNewItemsClick(event: MouseEvent): void {
    event.stopPropagation();
    this.measuredClick.emit({ properties: { action: 'showSearchNewItems' }, tab: 'SEARCH' });
  }

  protected getFavouriteSearchUrlInfo(
    favouriteSearch: FavouriteSearchDto,
  ): UrlInfoModel {
    return this.listingRoutingService.getUrlInfoForFavouriteSearch(
      favouriteSearch.hash,
      !!favouriteSearch.newItemsCount,
    );
  }

}
