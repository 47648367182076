import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[aukPopoverToggle]',
})
export class PopoverToggleDirective {

  @Output() public hover: EventEmitter<boolean> = new EventEmitter<boolean>();

  private state: boolean = false;
  private isTouched: boolean = false;

  @HostListener('focusin')
  @HostListener('mouseenter')
  public onMouseEnter(): void {
    if (this.isTouched) {
      return;
    }
    this.state = true;
    this.toggleState();
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.isTouched = false;
    this.state = false;
    this.toggleState();
  }

  @HostListener('touchend')
  public onTouch(): void {
    this.isTouched = true;
    this.state = !this.state;
    this.toggleState();
  }

  private toggleState(): void {
    this.hover.next(this.state);
  }

}
