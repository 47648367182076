import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';
// eslint-disable-next-line import/no-restricted-paths
import { CurrencyPipeModule } from '@shared/currency/pipe/currency-pipe.module';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { IconSize } from '@common/ui-kit/component/icon/component/model/icon-size';
import { BasicItemCardPriceModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-price.model';
import { BasicItemCardTextSizeType } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-text-size.type';

@Component({
  selector: 'auk-item-card-price',
  standalone: true,
  imports: [
    CurrencyPipeModule,
    IconComponent,
    TranslateModule,
    MatTooltipModule,
    NgClass,
  ],
  templateUrl: './item-card-price.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCardPriceComponent {

  @Input() public priceModel: BasicItemCardPriceModel;
  @Input() public priceSize: BasicItemCardTextSizeType;
  @Input() public itemHasEnded: boolean = false;

  private readonly LONG_PRICE_THRESHOLD: number = 11;
  private readonly VERY_LONG_PRICE_THRESHOLD: number = 13;
  private readonly ULTRA_LONG_PRICE_THRESHOLD: number = 15;

  protected getPriceSizeClass(price: string): string {
    if (this.priceSize === 'EXTRA_SMALL') {
      return 'tw-text-sm !tw-leading-md';
    }

    if (this.isUltraLongPrice(price)) {
      return 'tw-text-md';
    }

    if (this.isVeryLongPrice(price)) {
      if (this.priceSize === 'SMALL') {
        return 'tw-text-md';
      }

      return 'tw-text-lg';
    }

    if (this.priceSize === 'SMALL' || this.isLongPrice(price) || this.priceSize === 'LARGE_COMPACT') {
      return 'tw-text-xl';
    }

    if (this.priceSize === 'LARGE') {
      return 'tw-text-2xl';
    }

    return 'tw-text-xxl';
  }

  private isUltraLongPrice(price: string): boolean {
    return price.length > this.ULTRA_LONG_PRICE_THRESHOLD;
  }

  private isVeryLongPrice(price: string): boolean {
    return price.length > this.VERY_LONG_PRICE_THRESHOLD;
  }

  private isLongPrice(price: string): boolean {
    return price.length > this.LONG_PRICE_THRESHOLD;
  }

  protected get getPriceIconSize(): IconSize {
    if (this.priceSize === 'EXTRA_SMALL') {
      return 'XSM';
    } else if (this.priceSize === 'SMALL') {
      return 'MD';
    } else if (this.priceSize === 'LARGE_COMPACT') {
      return 'XMD';
    } else {
      return 'LG';
    }
  }

}
