import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { NewUserProfileBaseDto } from '@api/generated/defs/NewUserProfileBaseDto';
import { UserService } from '@shared/user/service/user.service';
import { StringUtils } from '@util/util/string.utils';

/**
 * At least one of these params must be in search URL
 */
const MINIMAL_QUERY_PARAMS: string[] = ['text', 'sellerId', 'sellersNames'];

export const searchParamsGuard: CanActivateFn =
  (route): boolean | Observable<boolean> => {
    const router = inject(Router);
    const userService = inject(UserService);

    // temp seo redirect, this /vysledky-vyhledavani?sellerId&categoryId url should be never called in a future
    if (route.queryParams.sellerId && route.queryParams.categoryId) {
      return userService.getUserProfileMinimalById(route.queryParams.sellerId)
        .pipe(
          take(1),
          tap({
            next: (data: NewUserProfileBaseDto) => {
              void router.navigate(['/uzivatel', data.userName, 'nabidky'], {
                queryParams: {
                  categoryId: route.queryParams.categoryId,
                },
                replaceUrl: true,
              });
            },
            error: () => {
              void router.navigate(['/404'], {
                replaceUrl: true,
              });
            },
          }),
          map(() => false),
        );
    }

    const hasMinimalParams: boolean = MINIMAL_QUERY_PARAMS.some((key: string) => !StringUtils.isEmpty(route.queryParamMap.get(key)));
    if (!hasMinimalParams) {
      void router.navigate(['/']);
      return of(false);
    }

    return of(true);
  };
