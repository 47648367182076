export enum UrlTrackingParamsEnum {
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_CONTENT = 'utm_content',
  UTM_TERM = 'utm_term',
  GCLID = 'gclid',
  DCLID = 'dclid',
  FBCLID = 'fbclid',
  CJEVENT = 'cjevent',
}
