export const COLOR_NAMES = [
  'primary',
  'primary-persistent',
  'primary-50',
  'primary-400',
  'primary-700',
  'primus-300',
  'secondary',
  'tertiary',
  'tertiary-50',
  'tertiary-100',
  'tertiary-200',
  'tertiary-300',
  'tertiary-400',
  'tertiary-500',
  'tertiary-600',
  'tertiary-700',
  'tertiary-800',
  'tertiary-900',
  'vip',
  'info',
  'success-50',
  'success-100',
  'success-200',
  'success-300',
  'success-400',
  'success',
  'success-600',
  'success-700',
  'warning',
  'warning-50',
  'danger',
  'danger-50',
  'danger-100',
  'danger-300',
  'danger-600',
  'contrast',
  'contrast-opposite',
  'silverstein-50',
  'silverstein-100',
  'silverstein-200',
  'silverstein-300',
  'silverstein-400',
  'silverstein-500',
  'silverstein-600',
  'silverstein-700',
  'silverstein-800',
  'silverstein-900',
  'jungle-green-50',
  'jungle-green-500',
  'jungle-green-600',
  'royal-blue-500',
  'antique',
  'antique-light',
  'antique-400',
  'bazaar',
  'bazaar-light',
  'transparent',
  'surface-5',
  'surface-4',
  'surface-3',
  'surface-neutral-2',
] as const;
