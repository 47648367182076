import {
  SEOSortParam_LEVNE,
  SEOSortParam_NEJLEPSI,
  SEOSortParams,
  SortCriteria,
} from '../../../typings/original/deprecated';

export const predefinedCriteria: SortCriteria[] = [
  {
    title: 'MY-AUKRO-SORTING-PRICE',
    name: 'price',
    options: [
      { title: 'MY-AUKRO-SORTING-THE-CHEAPEST',
        value: 'ASC',
        seoParameters: { url: SEOSortParam_LEVNE, title: SEOSortParams[SEOSortParam_LEVNE],
          description: '<categoryname> levně' } },
      { title: 'MY-AUKRO-SORTING-THE-MOST-EXPENSIVE',
        value: 'DESC',
        seoParameters: { url: SEOSortParam_NEJLEPSI, title: SEOSortParams[SEOSortParam_NEJLEPSI],
          description: 'Nejlepší <categoryname>' } },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-PRICE-INCLUDING-SHIPPING',
    name: 'priceWithShipping',
    options: [
      { title: 'MY-AUKRO-SORTING-THE-CHEAPEST', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-THE-MOST-EXPENSIVE', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-NUMBER-OF-BUYERS',
    name: 'bidderCount',
    options: [
      { title: 'MY-AUKRO-SORTING-LEAST', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-THE-MOST', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-TIME-UNTIL-END',
    name: 'date',
    options: [
      { title: 'MY-AUKRO-SORTING-THE-MOST', value: 'DESC' },
      { title: 'MY-AUKRO-SORTING-LEAST', value: 'ASC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-AMOUNT',
    name: 'amount',
    options: [
      { title: 'MY-AUKRO-SORTING-THE-LOWEST', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-THE-HIGHEST', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-STATE',
    name: 'status',
    options: [
      { title: 'MY-AUKRO-SORTING-LEAST', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-THE-MOST', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-DATE',
    name: 'eventDate',
    options: [
      { title: 'MY-AUKRO-SORTING-THE-YOUNGEST', value: 'DESC' },
      { title: 'MY-AUKRO-SORTING-THE-OLDEST', value: 'ASC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-DATE',
    name: 'endTime',
    options: [
      { title: 'MY-AUKRO-SORTING-THE-LATES', value: 'DESC' },
      { title: 'MY-AUKRO-SORTING-THE-OLDEST', value: 'ASC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-TIME',
    name: 'endingTime',
    options: [
      { title: 'MY-AUKRO-SORTING-JUST-ENDING', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-THE-LATES', name: 'startingTime', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-BEGINNING',
    name: 'startingTime',
    options: [
      { title: 'MY-AUKRO-SORTING-COMING', value: 'ASC' },
      { title: 'MY_AUKRO_SORTING_THE_MOST_DISTANT', name: 'startingTime', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-RELEVANCY',
    name: 'relevance',
    options: [
      { title: 'MY-AUKRO-SORTING-BEST-MATCH', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-NAME',
    name: 'name',
    options: [
      { title: 'MY-AUKRO-SORTING-FROM-A-TO-Z', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-FROM-Z-TO-A', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-SELLER',
    name: 'seller',
    options: [
      { title: 'MY-AUKRO-SORTING-FROM-A-TO-Z', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-FROM-Z-TO-A', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-SELLER-LOGIN',
    name: 'seller.showName',
    options: [
      { title: 'MY-AUKRO-SORTING-FROM-A-TO-Z', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-FROM-Z-TO-A', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-NUMBER-OF-NEW-OFFERS',
    name: 'itemsCount',
    options: [
      { title: 'MY-AUKRO-SORTING-FROM-THE-LOWEST', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-FROM-THE-HIGHEST', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-BUYER',
    name: 'buyerLogin',
    options: [
      { title: 'MY-AUKRO-SORTING-FROM-A-TO-Z', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-FROM-Z-TO-A', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-AMOUNT',
    name: 'amount',
    options: [
      { title: 'MY-AUKRO-SORTING-LEAST', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-THE-MOST', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-SELECTION-STATUS',
    name: 'status',
    options: [
      { title: 'MY-AUKRO-SORTING-LEAST', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-THE-MOST', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-SELECTION-NUMBER',
    name: 'payoutId',
    options: [
      { title: 'MY-AUKRO-SORTING-LEAST', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-THE-MOST', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-DATE',
    name: 'lastPurchaseTime',
    options: [
      { title: 'MY-AUKRO-SORTING-FIRST', value: 'DESC' },
      { title: 'MY-AUKRO-SORTING-AT-THE-LATEST', value: 'ASC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-DATE',
    name: 'dealTime',
    options: [
      { title: 'MY-AUKRO-SORTING-FIRST', value: 'DESC' },
      { title: 'MY-AUKRO-SORTING-AT-THE-LATEST', value: 'ASC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-PURCHASE-DATE',
    name: 'purchaseDate',
    options: [
      { title: 'MY-AUKRO-SORTING-FIRST', value: 'DESC' },
      { title: 'MY-AUKRO-SORTING-AT-THE-LATEST', value: 'ASC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-DATE-ADDED',
    name: 'blockTime',
    options: [
      { title: 'MY-AUKRO-SORTING-FIRST', value: 'DESC' },
      { title: 'MY-AUKRO-SORTING-AT-THE-LATEST', value: 'ASC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-IT-WILL-BE-EXPOSED',
    name: 'scheduledTime',
    options: [
      { title: 'MY-AUKRO-SORTING-FIRST', value: 'ASC' },
      { title: 'MY-AUKRO-SORTING-AT-THE-LATEST', value: 'DESC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-NUMBER-OF-SUBJECTS',
    name: 'quantity',
    options: [
      { title: 'MY-AUKRO-SORTING-THE-MOST', value: 'DESC' },
      { title: 'MY-AUKRO-SORTING-LEAST', value: 'ASC' },
    ],
  },
  {
    title: 'MY-AUKRO-SORTING-DATE',
    name: 'createdTime',
    options: [
      { title: 'MY-AUKRO-SORTING-FROM-THE-LATEST', value: 'DESC' },
      { title: 'MY-AUKRO-SORTING-FROM-THE-OLDEST', value: 'ASC' },
    ],
  },
];
