import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import isInteger from 'lodash-es/isInteger';

import { StringUtils } from '@util/util/string.utils';

@Component({
  selector: 'auk-verification-code-input',
  templateUrl: './verification-code-input.component.html',
  styleUrls: ['./verification-code-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationCodeInputComponent implements AfterViewInit {

  /**
   * Requested code length
   */
  @Input()
  public set length(length: number) {
    this.inputIndexes = Array.from(Array(length).keys());
  }

  /**
   * Whether only digits are accepted (default true)
   */
  @Input()
  public onlyDigits: boolean = true;

  /**
   * Emits on each user's code input (even if code is not completed yet)
   */
  @Output()
  public codeInput: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Whether input should be shown in compact inline layout
   */
  @Input()
  public inline: boolean = false;

  /**
   * Array of input elements
   * Each digit/char has individual input field
   */
  @ViewChildren('inputElement')
  public inputs: QueryList<ElementRef<HTMLInputElement>>;

  /**
   * Array of input indexes, e.g. [0, 1, 2, 3]
   */
  public inputIndexes: number[] = [];

  public ngAfterViewInit(): void {
    // Autofocus the first input
    this.focusInputElement(0);
  }

  /**
   * Sets value to specified input
   * If value contains more digits/chars sets also values to next inputs (i.g. if user paste the code)
   * @param event - input event with value
   * @param index - index of input
   */
  public onInput(event: Event, index: number): void {
    const eventTarget: any = event.target;
    const value: string = (eventTarget.data || eventTarget.value).toString();

    // Set each digit/char to proper input
    value.split('')
      .forEach((x: string, i: number) => this.setValue(i + index, x));

    this.emitCurrentValue();
  }

  /**
   * Clears value on the given index
   * If input is already empty, clears and focuses previous input
   * @param index - index of input
   */
  public onBackspace(index: number): void {
    const hadValue: boolean = !!this.inputs.get(index).nativeElement.value;

    if (hadValue) {
      this.clearInputElement(index);
    } else {
      const prevIndex: number = index - 1;
      this.focusInputElement(prevIndex);
    }

    this.emitCurrentValue();
  }

  /**
   * Clears value of the focused input
   * @param index
   */
  public onInputFocused(index: number): void {
    this.clearInputElement(index);
    this.emitCurrentValue();
  }

  private setValue(index: number, value: string): void {
    if (!this.indexExists(index)) {
      return;
    }

    // Clear input if empty value
    if (StringUtils.isBlank(value)) {
      this.clearInputElement(index);
      return;
    }

    // Clear input if value is not digit (if required)
    if (this.onlyDigits && !isInteger(Number(value))) {
      this.clearInputElement(index);
      return;
    }

    // Set value
    this.inputs.get(index).nativeElement.value = value;

    // Focus and clear next input
    const nextIndex: number = index + 1;
    this.focusInputElement(nextIndex);
  }

  private clearInputElement(index: number): void {
    if (!this.indexExists(index)) {
      return;
    }

    this.inputs.get(index).nativeElement.value = '';
  }

  private focusInputElement(index: number): void {
    if (!this.indexExists(index)) {
      return;
    }

    this.inputs.get(index).nativeElement.focus();
  }

  private emitCurrentValue(): void {
    const currentValue: string = this.inputs
      .map(inputEl => inputEl.nativeElement.value)
      .join('');

    this.codeInput.emit(currentValue);
  }

  private indexExists(index: number): boolean {
    return index >= 0 && index <= this.inputs.length - 1;
  }

}
