import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';

/**
 * Returns false if value is Nil, otherwise true
 * @param value which should be checked
 */
export function isNotNil<T>(value: T | Nil): value is T {
  return !isNil(value);
}
