import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Translate2Module } from '@common/translations/translate2.module';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { Tooltip2InformativeModel } from '../../model/tooltip2-informative.model';

@Component({
  selector: 'auk-tooltip2-informative',
  templateUrl: './tooltip2-informative.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    Translate2Module,
    IconComponent,
    NgTemplateOutlet,
    NgClass,
  ],
})

export class Tooltip2InformativeComponent {

  @Input() public tooltipModel: Tooltip2InformativeModel;

}
