
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { NewUserProfileAllDto } from '@api/generated/defs/NewUserProfileAllDto';
import { OfferDetailSellerDto } from '@api/generated/defs/OfferDetailSellerDto';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { userAccountTypeBadgeContainer } from './constant/user-account-type-badge-data.constant';
import { BadgeIcon, BadgeIconType } from '@shared/user-chip/components/user-account-type-badge/modul/user-account-type-badge-data.modul';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { Nil } from '@util/helper-types/nil';

@Component({
  selector: 'auk-user-account-type-badge',
  templateUrl: './user-account-type-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    MatTooltipModule,
    IconComponent,
    SvgIconComponent,
  ],
})
export class UserAccountTypeBadgeComponent implements OnChanges{

  @Input() public userInfo: NewUserProfileAllDto | OfferDetailSellerDto;
  /** Badge object with information */
  public badgeIcon: BadgeIcon | Nil = null;

  public ngOnChanges(changes: AukSimpleChanges<UserAccountTypeBadgeComponent>): void {
    if(changes.userInfo) {

      if(this.userInfo?.accountBlockType === 'BLOCK') {
        this.setCorrectBadge('blocked_user');
      } else {
        this.checkUserVerification();
      }

    }
  }

  private setCorrectBadge(icon: BadgeIconType): void {
    this.badgeIcon = userAccountTypeBadgeContainer.find(badge => badge.icon === icon);
  }

  private checkUserVerification(): void {
    if(this.userInfo?.accountActivated) {
      this.setUserVerifiedBadge();
    } else {
      this.setCorrectBadge('unverified_user');
    }
  }

  private setUserVerifiedBadge(): void {
    this.setCorrectBadge(this.userInfo?.companyAccount ? 'business_user' : 'verified_user');
  }

}
