import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserService } from '@shared/user/service/user.service';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { Privilege } from '@shared/user/model/privilege.enum';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionsService {

  constructor(
    private readonly userService: UserService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
  ) {
  }

  public userPrivileges$(): Observable<string[]> {
    return this.userService.getActualStatistics()
      .pipe(
        map((userInfo) => userInfo?.privileges),
        take(1),
      );
  }

  public hasPrivilege(userInfo: UserInterestStatisticsDto, privilege: Privilege): boolean {
    return userInfo?.privileges?.includes(privilege);
  }

  public hasPrivilege$(privilege: Privilege): Observable<boolean> {
    return this.userService.getActualStatistics()
      .pipe(
        map((userInfo) => this.hasPrivilege(userInfo, privilege)),
        take(1),
      );
  }

  public hasPaymentByPva$(): Observable<boolean> {
    return combineLatest([
      this.userService.getActualStatistics(),
      this.configuratorCacheService.getFeSystemParam<boolean>('COMPULSORY_PVA_ENABLED', 'BOOLEAN'),
    ])
      .pipe(
        map(([actualStatistics, compulsoryPvaEnabled]: [UserInterestStatisticsDto, boolean]) => {
          if (compulsoryPvaEnabled) {
            return actualStatistics?.paymentOptions?.includes('PAYMENT_BY_PVA');
          } else {
            return actualStatistics?.paymentViaAukroEnabled;
          }
        }),
      );
  }

  public hasPaymentByBankTransfer$(): Observable<boolean> {
    return combineLatest([
      this.userService.getActualStatistics(),
      this.configuratorCacheService.getFeSystemParam<boolean>('COMPULSORY_PVA_ENABLED', 'BOOLEAN'),
    ])
      .pipe(
        map(([actualStatistics, compulsoryPvaEnabled]: [UserInterestStatisticsDto, boolean]) => {
          if (compulsoryPvaEnabled) {
            return actualStatistics?.paymentOptions?.includes('PAYMENT_BY_BANK_TRANSFER');
          } else {
            return this.hasPrivilege(actualStatistics, Privilege.PAYMENT_BY_BANK_TRANSFER);
          }
        }),
      );
  }

  public hasPaymentOnDelivery$(): Observable<boolean> {
    return combineLatest([
      this.userService.getActualStatistics(),
      this.configuratorCacheService.getFeSystemParam<boolean>('COMPULSORY_PVA_ENABLED', 'BOOLEAN'),
    ])
      .pipe(
        map(([actualStatistics, compulsoryPvaEnabled]: [UserInterestStatisticsDto, boolean]) => {
          if (compulsoryPvaEnabled) {
            return actualStatistics?.paymentOptions?.includes('PAYMENT_ON_DELIVERY');
          } else {
            return this.hasPrivilege(actualStatistics, Privilege.PAYMENT_ON_DELIVERY);
          }
        }),
      );
  }

}
