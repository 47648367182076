import { GaCurrencyCodeType } from '@shared/google-analytics/model/ga-currency-code.type';

import { GaActionTrackEventsMapModel } from '@shared/google-analytics/model/ga-action-track-events-map.model';

export class GoogleAnalyticsConstants {

  /**
   * Default currency used in Google Analytics. Price amount must be in this currency before sent to GTM.
   */
  public static GTM_CURRENCY: GaCurrencyCodeType = 'CZK';

  public static GA_CLIENT_INFORMATION_SESSION_KEY: string = 'gaClientInformation';

  public static GA_IMPRESSION_PAIR_CART_SESSION_KEY: string = 'gaImpressionPairCartParam';

  public static ACTION_TRACK_EVENTS: GaActionTrackEventsMapModel = {
    click: {
      eventCategory: 'Click',

      productWatchdog: {
        eventAction: 'Product Watchdog',

        activation: { eventLabel: 'Activation' },
        deactivation: { eventLabel: 'DeActivation' },
      },

      outboundLinks: {
        eventAction: 'Odchozí odkaz - patička',
        eventValue: undefined,

        news: { eventLabel: 'Novinky' },
        aukroPartners: { eventLabel: 'Aukro partneři' },
        salesGuide: { eventLabel: 'Průvodce prodeje' },
        salesManager: { eventLabel: 'Manažer prodeje' },
        priceList: { eventLabel: 'Ceník' },
        buyersProtection: { eventLabel: 'Ochrana kupujících' },
        vacancies: { eventLabel: 'Volná místa' },
      },

      outboundLinksProduct: {
        eventAction: 'Odchozí odkaz - produkt',
        eventValue: undefined,
        buyersProtection: { eventLabel: 'Ochrana kupujících' },
      },
    },
    core: {
      eventCategory: 'Core',

      emailAcquired: {
        eventAction: 'Email acquired',
        eventValue: 0,
        emailCollector: { eventLabel: 'Email collector' },
        emailCollectorFacebook: { eventLabel: 'Email collector Facebook' },
        nonRegisteredBuy: { eventLabel: 'Non-registered buy' },
      },
    },
    ux: {
      eventCategory: 'Ux',

      emailCollectorDisplayed: {
        eventAction: 'Email collector popup displayed',
        desktop: { eventLabel: 'desktop' },
        mobile: { eventLabel: 'mobile' },
      },
      emailCollectorClosed: {
        eventAction: 'Email collector popup closed',
        desktop: { eventLabel: 'desktop' },
      },
    },
  };

}
