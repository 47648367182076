import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from '@app-constants';
import { CookieService } from '@common/cookie/service/cookie.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { ClickEventDto } from '@api/generated/defs/ClickEventDto';
import { ShowEventDto } from '@api/generated/defs/ShowEventDto';
import { ShowListingEventResponseDto } from '@api/generated/defs/ShowListingEventResponseDto';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PersonalizationMeasurementService } from '@shared/services/personalization/personalization-measurement.service';
import { EMPTY, Observable } from 'rxjs';
import { Nil } from '@util/helper-types/nil';

@Injectable({
  providedIn: 'root',
})
export class SuggestPersonalizationMeasurementService extends NgUnsubscribe {

  private readonly SUGGEST_LANDING_PAGES_MEASUREMENT_OBJECT_CODE: string = 'SUGGEST_LANDING_PAGES';
  private readonly SUGGEST_CATEGORIES_MEASUREMENT_OBJECT_CODE: string = 'SUGGEST_CATEGORIES';
  private readonly SUGGEST_ITEM_MEASUREMENT_OBJECT_CODE: string = 'SUGGEST_ITEMS';

  constructor(
    private readonly cookieService: CookieService,
    private readonly personalizationMeasurementService: PersonalizationMeasurementService,
  ) {
    super();
  }

  public clickOnSuggestedItem(clickEventDto: ClickEventDto): void {
    if (this.getSuggestMeasurementEnabled()) {
      this.personalizationMeasurementService.clickMeasurement$({
        showId: clickEventDto.showId,
        itemId: clickEventDto.itemId,
        elementRank: clickEventDto.elementRank,
        measurementObjectCode: this.SUGGEST_ITEM_MEASUREMENT_OBJECT_CODE,
      })
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe();
    }
  }

  public showSuggestedItems$(showEventDto: ShowEventDto): Observable<number | Nil> {
    if (this.getSuggestMeasurementEnabled()) {
      return this.personalizationMeasurementService.showMeasurement$({
        searchTerm: showEventDto.searchTerm,
        measurementObjectCode: this.SUGGEST_ITEM_MEASUREMENT_OBJECT_CODE,
      })
        .pipe(
          map((response: ShowListingEventResponseDto) => response?.showId),
        );
    }

    return EMPTY;
  }

  public clickOnSuggestedLandingPage(clickEventDto: ClickEventDto): void {
    if (this.getSuggestMeasurementEnabled()) {
      this.personalizationMeasurementService.clickMeasurement$({
        showId: clickEventDto.showId,
        landingPageId: clickEventDto.landingPageId,
        elementRank: clickEventDto.elementRank,
        measurementObjectCode: this.SUGGEST_LANDING_PAGES_MEASUREMENT_OBJECT_CODE,
      })
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe();
    }
  }

  public showSuggestedLandingPages$(showEventDto: ShowEventDto): Observable<number | Nil> {
    if (this.getSuggestMeasurementEnabled()) {
      return this.personalizationMeasurementService.showMeasurement$({
        searchTerm: showEventDto.searchTerm,
        measurementObjectCode: this.SUGGEST_LANDING_PAGES_MEASUREMENT_OBJECT_CODE,
      })
        .pipe(
          map((response: ShowListingEventResponseDto) => response?.showId),
        );
    }

    return EMPTY;
  }

  public clickOnSuggestedCategory(clickEventDto: ClickEventDto): void {
    if (this.getSuggestMeasurementEnabled()) {
      this.personalizationMeasurementService.clickMeasurement$({
        showId: clickEventDto.showId,
        categoryId: clickEventDto.categoryId,
        elementRank: clickEventDto.elementRank,
        measurementObjectCode: this.SUGGEST_CATEGORIES_MEASUREMENT_OBJECT_CODE,
      })
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe();
    }
  }

  public showSuggestedCategories$(showEventDto: ShowEventDto): Observable<number | Nil> {
    if (this.getSuggestMeasurementEnabled()) {
      return this.personalizationMeasurementService.showMeasurement$({
        searchTerm: showEventDto.searchTerm,
        measurementObjectCode: this.SUGGEST_CATEGORIES_MEASUREMENT_OBJECT_CODE,
      })
        .pipe(
          map((response: ShowListingEventResponseDto) => response?.showId),
        );
    }

    return EMPTY;
  }

  /** Returns true if click and show measurement for search suggestions is enabled. */
  public getSuggestMeasurementEnabled(): boolean {
    return this.cookieService.get(APP_CONSTANTS.SUGGEST_MEASUREMENT_ENABLED_COOKIE_NAME) === '1';
  }

}
