import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AdElementIdType } from '@shared/google-analytics/model/ad-element-id.type';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ThirdPartyBannerService } from '@shared/banner/service/third-party-banner.service';
import { Nil } from '@util/helper-types/nil';

@Component({
  selector: 'auk-third-party-banner',
  templateUrl: './third-party-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ThirdPartyBannerComponent extends NgUnsubscribe implements AfterViewInit {

  /**
   * We don't expect this to change in runtime, so nothing will happen if it will be changed in runtime
   */
  @Input() public adElementId: AdElementIdType | Nil;

  constructor(
    private readonly thirdPartyBannerService: ThirdPartyBannerService,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.thirdPartyBannerService.adContainerReady(this.adElementId);
  }

}
