import { Injectable } from '@angular/core';
import { Nil } from '@util/helper-types/nil';
import { APP_CONSTANTS } from '@app-constants';
import { CookieService } from '@common/cookie/service/cookie.service';
import { captureException } from '@sentry/browser';
import { RoivenueIdsModel } from '@shared/roivenue/model/roivenue-ids.model';
import { StringUtils } from '@util/util/string.utils';

@Injectable({
  providedIn: 'root',
})
export class RoivenueService {

  constructor(
    private readonly cookieService: CookieService,
  ) {
  }

  public getRoivenueIds(): RoivenueIdsModel | Nil {
    const roiVenueIds: string = this.cookieService.get(APP_CONSTANTS.ROIVENUE_IDS_COOKIE_NAME);
    if (!StringUtils.isBlank(roiVenueIds)) {
      try {
        return JSON.parse(roiVenueIds) as RoivenueIdsModel;
      } catch (e) {
        const message: string = 'RoivenueService :: Cannot resolve Roivenue id';
        captureException(message, {
          extra: { error: e },
        });
        console.warn(message, e);
        return null;
      }
    }
    return null;
  }

}
