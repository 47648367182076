@if (unresolvedStances.length > 0 || !!currentStance) {
  @if (!currentStance) {
    <div class="content step-1" mat-dialog-content>
      <auk-svg-icon-legacy id="logo"></auk-svg-icon-legacy>
      <a (click)="logout()" class="log-out">{{ 'CONSENT_TERMS_COMPONENT_HTML_1' | translate }}</a>
      <div class="inner-block" [aukInnerHtml]="{key: 'CONSENT_STANCE_DEFAULT'}">
      </div>
    </div>
    <div mat-dialog-actions class="controls">
      <a (click)="skip()" class="btn-primary" [class.disabled]="!canSkip">{{ 'CONSENT_TERMS_COMPONENT_HTML_2' | translate }}</a>
    </div>
  } @else {
    <div>
      <div class="content" mat-dialog-content>
        <h1 class="!tw-text-matter-accent-primary">{{currentStance.subject}}</h1>
        <div class="stance-content" [aukInnerHtml]="currentStance.content"></div>
      </div>
      <div class="controls" mat-dialog-actions>
        @if (isOnlyView) {
          <a (click)="closeModal()" class="link">{{ 'CONSENT_TERMS_COMPONENT_HTML_3' | translate }}</a>
        } @else {
          @if (['EVERY_LOGIN', 'FIRST_LOGIN'].indexOf(currentStance.consentType.stanceUrgencyLevel) !== -1) {
            <a (click)="skip()" class="link"
            >{{ 'CONSENT_TERMS_COMPONENT_HTML_4' | translate }}</a>
          }
          <div class="container">
            @if (currentStance.consentType.consentStanceType === 'AGREE_DISAGREE') {
              <button (click)="save(false)" [disabled]="saveStanceEndpointCalled" class="btn-secondary fluid-max-tablet"
              >{{ 'CONSENT_TERMS_COMPONENT_HTML_5' | translate }}</button>
            }
            @if (currentStance.consentType.consentStanceType === 'AGREE_DISAGREE') {
              <button (click)="save(true)" [disabled]="saveStanceEndpointCalled" class="btn-primary fluid-max-tablet"
              >{{ 'CONSENT_TERMS_COMPONENT_HTML_6' | translate }}</button>
            }
            @if (currentStance.consentType.consentStanceType === 'TAKE_INTO_ACCOUNT') {
              <button (click)="save(true)" [disabled]="saveStanceEndpointCalled" class="btn-primary fluid-max-tablet"
              >{{ 'CONSENT_TERMS_COMPONENT_HTML_7' | translate }}</button>
            }
          </div>
        }
      </div>
    </div>
  }
}

