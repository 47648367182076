import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface OnesignalParams {
  /** OneSignal player id */
  playerId: string;
  /** platformTypeEnum */
  platformTypeEnum?: PlatformTypeEnumOnesignalParams;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type PlatformTypeEnumOnesignalParams =
  'ANDROID' |
  'IOS' |
  'NATIVE' |
  'IOS_PWA';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface OnesignalTEMPParams {
  /** oneSignalId */
  playerId: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root'
})
export class PushNotificationService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public onesignal(params: OnesignalParams, headers: { [key: string]: string } = {}): Observable<void> {
    let queryParams = {
      playerId: params.playerId,
      platformTypeEnum: params.platformTypeEnum,
    };
    let bodyParams;
    let formDataParams;
    return this.postGenerated(`/notification/onesignal`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public onesignalTEMP(params: OnesignalTEMPParams): Observable<void> {
    let queryParams = {
      playerId: params.playerId,
    };
    return this.deleteGenerated(`/notification/onesignalTEMP`, queryParams);
  }
}
