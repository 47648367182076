import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { DateUtils } from '@util/util/date.utils';
import { filter, mergeMap, take, takeUntil } from 'rxjs/operators';
import isMatch from 'lodash-es/isMatch';
import { captureException } from '@sentry/browser';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { Inject, Injectable } from '@angular/core';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { AukWindow } from '@shared/model/auk-window.interface';
import { Observable } from 'rxjs';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import type { Extras } from '@sentry/types/build/types/extra';

@Injectable({
  providedIn: 'root',
})
export class DataLayerCheckingService extends BaseDestroy {

  private readonly CHECK_DELAY_MS: number = DateUtils.convertSecondsToMilliseconds(5);

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
  ) {
    super();
  }

  /**
   * Checks whether the given data is still present and contains the same properties and values in Data Layer (added properties are not
   * considered as wrong state). If event is missing or changed, error is logged, as this can impact GA4 measurement accuracy.
   * Checking could be disabled by cookie value.
   * @param eventToCheck event to be checked for deletion or modification
   */
  public checkEventInDataLayer(eventToCheck: object): void {
    this.isDataLayerCheckingEnabled()
      .pipe(
        filter((enabled: boolean) => enabled),
        mergeMap(() => this.ngZoneUtilService.timerOut$(this.CHECK_DELAY_MS)),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const eventFound: boolean = this.window.dataLayer?.some((event: object) => isMatch(event, eventToCheck));
        if (!eventFound) {
          const errorMessage: string = 'Event removed or modified in Data Layer.';
          const extra: Extras = { eventToCheck, last15DataLayerEvents: this.window.dataLayer.slice(-15) };
          console.error(errorMessage, extra);
          captureException(errorMessage, { extra, fingerprint: ['DATA_LAYER_MODIFIED'] });
        }
      });
  }

  private isDataLayerCheckingEnabled(): Observable<boolean> {
    return this.configuratorCacheService.getFeSystemParam('DATA_LAYER_CHECKING_ENABLED', 'BOOLEAN');
  }

}
