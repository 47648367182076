import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * @deprecated
 * use {@link Tooltip2Directive} from 'common/tooltip2' folder
 */
@Component({
  selector: 'auk-aukro-info-hover',
  templateUrl: './aukro-info-hover.component.html',
  styleUrls: ['./aukro-info-hover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AukroInfoHoverComponent {

  @Input()
  public messageKey: string;

  @Input()
  public iconSize: 'SMALL' | 'BIG' = 'BIG';

  public isInfoShow: boolean = false;

  public get iconSizeClass(): string {
    if (this.iconSize === 'BIG') {
      return 'big';
    }
    return null;
  }

  public showInfo(): void {
    this.isInfoShow = true;
  }

  public hideInfo(): void {
    this.isInfoShow = false;
  }

}
