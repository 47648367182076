import { CrossTabMessageUnionModel } from '../model/cross-tab-message-union.model';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ICrossTabMessagingService } from './i-cross-tab-messaging.service';
import isNil from 'lodash-es/isNil';
import { CrossTabMessageType } from '../model/cross-tab-message.type';
import { filter, map } from 'rxjs/operators';
import { CrossTabMessagePayloadModel } from '../model/cross-tab-message-payload.model';

@Injectable({
  providedIn: 'root',
})
export class CrossTabMessagingService implements ICrossTabMessagingService {

  private broadcastChannel: BroadcastChannel;
  private _onMessage$: Subject<CrossTabMessageUnionModel> = new Subject<CrossTabMessageUnionModel>();

  constructor() {
    this.initializeBroadcastChannel();
  }

  public get message$(): Observable<CrossTabMessageUnionModel> {
    return this._onMessage$.asObservable();
  }

  public listenToMessageType$<S extends CrossTabMessageType>(key: S): Observable<CrossTabMessagePayloadModel<S>> {
    return this.message$
      .pipe(
        filter((message) => message.type === key),
        map((val) => val.payload as CrossTabMessagePayloadModel<S>),
      );
  }

  public postMessage(message: CrossTabMessageUnionModel): void {
    if (isNil(this.broadcastChannel)) {
      console.warn('Broadcast channel not initialized');
      return;
    }

    this.broadcastChannel.postMessage(message);
  }

  private initializeBroadcastChannel(): void {
    if (typeof BroadcastChannel === 'undefined') {
      console.warn('Broadcast channel not supported. Aborting initialization');
      return;
    }

    this.broadcastChannel = new BroadcastChannel('AUK_GENERIC');
    this.broadcastChannel.onmessage = (m: MessageEvent): void => this._onMessage$.next(m?.data);
  }

}
