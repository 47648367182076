import { ItemSearchFilterExtended } from '../../../../typings/original/internal';

import { ListingPageParamsModel } from '../model/listing-page-params.model';

export class ListingConstants {

  /**
   * This mock object is used to parse query params to appropriate types
   * and to allow only certain query params
   */
  public static readonly ALLOWED_SEARCH_PARAMS: ItemSearchFilterExtended = {
    auction: true,
    aukroPlus: true,
    buyNowActive: true,
    categoryId: 1,
    categorySeoUrl: 'seo-url',
    countryId: 1,
    destinationCountries: [],
    endingAfter: '2017-07-21T09:14:58.891Z',
    endingBefore: '2017-07-21T09:14:58.891Z',
    endingBeforeRel: '3h',
    filter: [{ attributeId: 1, value: ['1', '2'] }],
    finished: true,
    freeShipping: true,
    postCode: '76502',
    distance: 40,
    paymentOnline: true,
    paymentViaAukro: true,
    cashOnDelivery: true,
    personalPickup: true,
    ppCategoryPage: true,
    ppMainPage: true,
    priceMax: 100,
    priceMin: 1,
    searchAll: false,
    sellerId: 1,
    specialFlagBasicStartingPrice: true,
    specialFlagEndingSoon: true,
    specialFlagThreeHundred: true,
    specialFlagNewOffers: true,
    specialFlagPopularOffers: true,
    specialFlagPriceHits: true,
    specialFlagPremiumOffers: true,
    specialFlagCharityOffers: true,
    hotAuction: true,
    specialFlagNew: true,
    startingAfter: '2017-06-21T09:14:58.891Z',
    originalStartingAfter: '2017-06-21T09:14:58.891Z',
    originalExposeAfter: '2017-06-21T09:14:58.891Z',
    startingAfterRel: '3h',
    startingBefore: '2017-06-21T09:14:58.891Z',
    originalStartingBefore: '2017-06-21T09:14:58.891Z',
    originalExposeBefore: '2017-06-21T09:14:58.891Z',
    sellersNames: [],
    text: 'text',
    searchRedirectDisabled: false,
    priceDeliveryIncluded: false,
  };

  public static readonly SEARCH_FILTER_TAG_DESCRIPTION_MAP: object[] = [
    { aukroPlus: 'TOP Seller' },
    { endingBeforeRel: 'Končící za méně než' },
    { freeShipping: 'Přeprava zdarma' },
    { postCode: 'PSČ: ' },
    { distance: 'Vzdálenost:', suffix: 'km' },
    { paymentOnline: 'Platby přes PayU' },
    { personalPickup: 'Osobní převzetí' },
    // TODO: change to actual currency
    { priceMin: 'Cena od:', suffix: 'Kč' },
    { priceMax: 'Cena do:', suffix: 'Kč' },
    { startingAfterRel: 'Vystavené před' },
    { paymentViaAukro: 'Aukro Ochrana kupujících' },
    { cashOnDelivery: 'Přeprava s dobírkou' },
    { destinationCountries: '' },
  ];

  public static readonly DEFAULT_PAGE_PARAMS: ListingPageParamsModel = {
    page: 0,
    size: 60,
    sort: [],
  };

  public static readonly LANDING_PAGE_URL_PREFIX: string = 'lp/';

  public static readonly QUERY_PARAM_NAME_ORIGINAL_STARTING_AFTER: string = 'originalStartingAfter';

  public static readonly QUERY_PARAM_NAME_ORIGINAL_STARTING_BEFORE: string = 'originalStartingBefore';

  public static readonly QUERY_PARAM_NAME_ORIGINAL_EXPOSE_AFTER: string = 'originalExposeAfter';

  public static readonly QUERY_PARAM_NAME_ORIGINAL_EXPOSE_BEFORE: string = 'originalExposeBefore';

  public static readonly QUERY_PARAM_NAME_SEARCH_REDIRECT_DISABLED: string = 'searchRedirectDisabled';

  public static readonly QUERY_PARAM_NAME_FAVOURITE_SEARCH: string = 'favouriteSearch';

}
