import { Pipe, PipeTransform } from '@angular/core';
import isNil from 'lodash-es/isNil';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {

  public transform(value: string): string {
    if (!isNil(value)) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }

}
