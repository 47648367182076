import { DOMPurifyConfigModel } from '@common/html/model/dom-purify-config.model';

export class SanitizeHtmlConstants {

  public static getDOMPurifyConfig = (): DOMPurifyConfigModel => ({
    FORCE_BODY: true, // Allow <style> in document.body
    ADD_TAGS: ['style'], // Allow style tags
    ADD_ATTR: ['style', 'target'], // Allow style attributes
  });

}
