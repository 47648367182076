import { AbstractControl } from '@angular/forms';

import { getZipMaskByCountryId } from '@shared/util/method/get-zip-mask-by-county-id.util';
import { CountryCodes } from '../../../app.constants';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { Observable } from 'rxjs';
import { TextMaskModel } from '@common/text-mask/model/text-mask.model';
import { conformToMask } from 'text-mask-core';

/**
 * Gets zipCodeTextMask for countryId, conforms current FormControl value to this mask, and returns this mask.
 * @returns zip mask for country Id or false if zipMask is not needed.
 */
export function updateZipCodeMask(
  countryId: number | CountryCodes,
  control: AbstractControl,
  ngZoneUtilService: NgZoneUtilService,
  timeoutDestroy$: Observable<void>,
): TextMaskModel {
  const zipCodeTextMask = getZipMaskByCountryId(countryId);

  const oldVal: string = control.value;
  if (oldVal) {
    if (zipCodeTextMask) {
      const newVal = conformToMask(oldVal, zipCodeTextMask, { guide: false }).conformedValue;
      control.setValue(newVal);
    } else {
      // update value after mask updating
      ngZoneUtilService.simpleTimerOut$(
        () => {
          const newVal = oldVal.replace(/_/g, '').trim(); // remove mask manually
          control.setValue(newVal);
        },
        timeoutDestroy$,
      );
    }
  }
  return zipCodeTextMask;
}
