import { UserTaskType } from '@shared/user-task/base/model/user-task.type';
import { UserTaskPayloadModel } from '@shared/user-task/base/model/user-task-payload.model';
import { Observable } from 'rxjs';
import { UserActionType } from '../../model/user-action.type';

export abstract class UserActionPrerequisiteTaskFulfillmentPayloadResolverService<TASK_TYPE extends UserTaskType> {

  private type: TASK_TYPE;

  public abstract resolve$(actionType?: UserActionType): Observable<UserTaskPayloadModel<TASK_TYPE>>;

}
