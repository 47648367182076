import { Injectable } from '@angular/core';
import { CacheAware } from '@common/cache/model/cache-aware';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { actualStatisticsCacheBuster$ } from '../constant/cache-busters';
import { Observable, of } from 'rxjs';
import { UserInterestStatisticsDto } from '@api/generated/defs/UserInterestStatisticsDto';
import { UserApiService } from '@api/generated/api/User';
import { CacheService } from '@common/cache/service/cache.service';
import { AuthCacheService } from '@shared/authentication/service/auth-cache.service';

@Injectable({
  providedIn: 'root',
})
export class UserActualStatisticsService implements CacheAware {

  constructor(
    private readonly userApiService: UserApiService,
    public readonly cacheService: CacheService,
    private readonly authCacheService: AuthCacheService,
  ) {
  }

  public loadActualStatistics(): Observable<UserInterestStatisticsDto> {
    if (!this.authCacheService.isLoggedIn()) {
      return of(null);
    }
    return this.freshOrCachedActualStatistics();
  }

  public loadActualStatisticsIgnoringCache(): Observable<UserInterestStatisticsDto> {
    actualStatisticsCacheBuster$.next();
    return this.loadActualStatistics();
  }

  @Cacheable({
    cacheBuster: actualStatisticsCacheBuster$,
    key: 'UserService#loadActualStatistics',
  })
  private freshOrCachedActualStatistics(): Observable<UserInterestStatisticsDto> {
    return this.userApiService.actualStatistics();
  }

}
