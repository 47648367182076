import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationSource } from '@common/translations/model/translation-source';

@Component({
  selector: 'auk-dialog-wrapper-with-content',
  templateUrl: './dialog-wrapper-with-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWrapperWithContentComponent {

  @Input()
  public showCloseInHeading: boolean = true;

  @Input()
  public dialogTitle: TranslationSource = null;

  @Input()
  public subtitle: TranslationSource = null;

  @Output()
  public closeDialogClick: EventEmitter<void> = new EventEmitter<void>();

  public onCloseDialogClick(): void {
    this.closeDialogClick.emit();
  }

}
