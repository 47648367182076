import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FavouriteSellersModel } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-sellers.model';
import { FavouriteSellerModel } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-seller.model';
import { FavouriteEmptyModel } from '@shared/favourite/model/favourite-empty.model';
import { FavouriteClickModel } from '@shared/favourite/model/favourite-click.model';
import { Router } from '@angular/router';

@Component({
  selector: 'auk-favourite-sellers-popover',
  templateUrl: './favourite-sellers-popover.component.html',
  styleUrls: ['./favourite-sellers-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouritePopoverSellersComponent {

  @Input() public favouriteSellers: FavouriteSellersModel;

  @Output() public measuredClick: EventEmitter<FavouriteClickModel> = new EventEmitter<FavouriteClickModel>();

  protected emptyPopoverContent: FavouriteEmptyModel = {
    title: { key: 'FAVOURITES_POPOVER_SELLERS_NO_DATA_TITLE' },
    label: { key: 'FAVOURITES_POPOVER_SELLERS_NO_DATA_LABEL' },
  };

  constructor(private readonly router: Router) {
  }

  protected trackByFavouriteSellerId(index: number, favouriteSeller: FavouriteSellerModel): number {
    return favouriteSeller.sellerId;
  }

  protected onSellerClick(event: MouseEvent, url: string): void {
    // Prevents emitting two click events if a user clicks on seller in popover
    // Prevent default as this navigation would otherwise cancel measured click processing
    event.stopPropagation();
    event.preventDefault();

    this.measuredClick.emit({ url, properties: { action: 'showSellerAllItems' }, tab: 'SELLERS' });

    void this.router.navigate(this.getRouterLink(url));
  }

  protected onSellerItemsClick(url: string): void {
    this.measuredClick.emit({ url, properties: { action: 'showSellerNewItems' }, tab: 'SELLERS' });
  }

  protected getRouterLink(login: string): string[] {
    return ['/uzivatel/' + login];
  }

  protected getRouterLinkSellerItems(login: string): string[] {
    return ['/uzivatel/' + login + '/nabidky'];
  }

}
