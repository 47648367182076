<auk-icon
  class="tw-cursor-pointer"
  [colorCombination]="iconColorCombination"
  [tooltipModel]="$any({
    type: 'INFORMATIVE',
    text: hintTooltip,
    size: 'SM',
  })"
  [delayOnClose]="0"
  [placement]="tooltipPosition"
  [isDisabled]="isTooltipDisabled"
  aukToolTip2
  iconType="SVG"
  size="MD"
  source="info_outline"
/>
