import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthCacheService } from '@shared/authentication/service/auth-cache.service';
import { TranslationSource } from '@common/translations/model/translation-source';

@Component({
  selector: 'auk-personal-data-step-close-or-set-password-button',
  templateUrl: './user-task-personal-data-step-close-or-set-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskPersonalDataStepCloseOrSetPasswordComponent {

  @Input()
  public closeButtonText: TranslationSource;

  @Input()
  public notNowButtonText: TranslationSource;

  @Input()
  public setPasswordButtonText: TranslationSource;

  @Input()
  public setPasswordInfoText: TranslationSource;

  @Output()
  public closeClick: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public setPasswordClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly authCacheService: AuthCacheService,
  ) {
  }

  public get mode(): 'CLOSE' | 'SET_PASSWORD' {
    const hasPassword = this.authCacheService.decodedToken?.hasPassword ?? true;

    return hasPassword ? 'CLOSE' : 'SET_PASSWORD';
  }

  public onSetPasswordClick(): void {
    this.setPasswordClick.emit();
  }

  public onCloseClick(): void {
    this.closeClick.emit();
  }

}
