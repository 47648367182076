import { Injectable } from '@angular/core';
import moment, { Moment } from 'moment-mini-ts';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { ArrayUtils } from '@util/util/array.utils';

import { MeasurementDisabledData } from './measurement-enabled.helper';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Injectable({
  providedIn: 'root',
})
export class MeasurementEnabledService extends NgUnsubscribe {

  private globalMeasurementEnabled: boolean = true;

  private readonly MEASUREMENT_DISABLED_DATA_SYSTEM_PARAMETER: string = 'MEASUREMENT_DISABLED_DATA';

  constructor(
    private readonly platformCommonService: PlatformCommonService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
  ) {
    super();

    this.saveMeasurementEnabled();
  }

  public get measurementEnabled(): boolean {
    return this.globalMeasurementEnabled;
  }

  /**
   * Gets measurement enabled data from configurator and saves if measurement is currently enabled or not
   */
  private saveMeasurementEnabled(): void {
    // measurement is disabled on server
    if (this.platformCommonService.isServer) {
      this.globalMeasurementEnabled = false;
      return;
    }

    this.configuratorCacheService.getFeSystemParam<MeasurementDisabledData>(this.MEASUREMENT_DISABLED_DATA_SYSTEM_PARAMETER, 'JSON')
      .pipe(
        take(1),
        filter((data: MeasurementDisabledData) => ArrayUtils.isNotEmpty(data?.day)),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: MeasurementDisabledData) => {
        const now: Moment = moment();
        const day: number = now.day();
        const timeFrom: Moment = moment(data.timeFrom, 'hh:mm');
        const timeTo: Moment = moment(data.timeTo, 'hh:mm');

        if (data.day.includes(day) && (timeFrom.isValid() && timeTo.isValid()) && now.isBetween(timeFrom, timeTo)) {
          this.globalMeasurementEnabled = false;
        } else {
          this.globalMeasurementEnabled = true;
        }
      });
  }

}
