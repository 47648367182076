import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipeService } from '@shared/currency/service/currency-pipe.service';
import { mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import isNil from 'lodash-es/isNil';
import { BasePipe } from '@util/base-class/base-pipe.class';
// TODO: [PDEV-18307] - fix dependency
import { CurrencyCode } from '@shared/currency/model/currency-code.model';
import { MoneyUtils } from '@shared/currency/util/money.utils';
import { DEFAULT_CURRENCY } from '@shared/currency/const/currency.constants';
import { MoneyDto } from '@api/aukro-api/model/money-dto';

/**
 * Format given value to money format. Can perform currency exchange if needed. Works with currency user specified currency.
 * Usage:
 *
 * Value is formatted as is with input money dto currency. Money exchange is not performed.
 *
 * {{ item.totalPrice | currency }}
 *
 *
 *
 * Value is formatted with currency specified by pipe parameter.
 * If parameter currency differs from input money dto currency, money exchange is performed with warning.
 *
 * {{ item.totalPrice | currency: 'CZK' }}
 *
 *
 *
 * The same as above without warning.
 *
 * {{ item.totalPrice | currency: 'CZK':false }}
 *
 */
@Pipe({
  name: 'currency',
  pure: false,
  standalone: true,
})
export class CurrencyPipe extends BasePipe implements PipeTransform {

  private currentValue: MoneyDto;
  private currentTargetCode: CurrencyCode;
  private approximationWarn: boolean = false;

  private transformedValue: string;

  constructor(
    private readonly currencyPipeService: CurrencyPipeService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();

    // Listen on currency/preferences/etc. changes and transform if differs
    this.currencyPipeService.currencySourceChanged()
      .pipe(
        mergeMap(() => this.transformValues$()),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public transform(
    value: MoneyDto,
    targetCurrencyCode?: CurrencyCode,
    approximationWarn: boolean = true,
  ): string {

    if (isNil(value)) {
      return null;
    }

    // fallback to CZK
    if (typeof value === 'number') {
      console.info('Number used in currency pipe, mapping to default currency');
      value = { amount: value, currency: DEFAULT_CURRENCY.code };
    }

    if (!MoneyUtils.moneyEquals(this.currentValue, value) || this.currentTargetCode !== targetCurrencyCode) {

      this.currentValue = value;
      this.currentTargetCode = targetCurrencyCode;
      this.approximationWarn = approximationWarn;

      this.transformValues$()
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe();
    }

    return this.transformedValue;
  }

  private transformValues$(): Observable<string> {
    return this.currencyPipeService.transform(this.currentValue, this.currentTargetCode, this.approximationWarn)
      .pipe(
        tap((transformedValue) => {
          this.transformedValue = transformedValue;
          this.changeDetectorRef.markForCheck();
        }),
        take(1),
      );
  }

}
