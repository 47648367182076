import removeAccents from 'remove-accents';

/**
 *
 * @param text
 * @param replaceSpaceCharBy
 * @param removeSpecialChars
 */
export function removeAccentsDiacritics(text: string, replaceSpaceCharBy: string = '', removeSpecialChars: boolean = false): string {
  let sanitizedStr: string = removeAccents(text ?? '');
  if (removeSpecialChars) {
    sanitizedStr = sanitizedStr.replace(/[^a-zA-Z0-9]/g, '');
  }
  if (replaceSpaceCharBy) {
    sanitizedStr = sanitizedStr.replace(/\s/g, replaceSpaceCharBy);
  }
  return sanitizedStr;
}
