import { Injectable, Injector, Type } from '@angular/core';
import { SuggestedViewDto } from '../model/suggested-view-dto';
import { ActionViewType } from '../model/action-view-type';
import {
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { BaseActionViewComponent } from '../component/base-action-view/base-action-view.component';
import isNil from 'lodash-es/isNil';
import {
  WatchEmailVerifiedBasicDataViewComponent,
} from '../component/watch-email-verified-basic-data-view/watch-email-verified-basic-data-view.component';
import {
  WatchNoEmailVerifiedNoBasicDataViewComponent,
} from '../component/watch-no-email-verified-no-basic-data-view/watch-no-email-verified-no-basic-data-view.component';
import { ComponentType } from '@angular/cdk/overlay';
import {
  WatchEmailVerifiedNoBasicDataViewComponent,
} from '../component/watch-email-verified-no-basic-data-view/watch-email-verified-no-basic-data-view.component';
import {
  WatchNoEmailVerifiedBasicDataViewComponent,
} from '../component/watch-no-email-verified-basic-data-view/watch-no-email-verified-basic-data-view.component';
import { ActionViewData } from '../model/action-view-data';
import { filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, switchMap } from 'rxjs';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { UserActionType } from '@shared/user-action/model/user-action.type';
import { UserTaskType } from '@shared/user-task/base/model/user-task.type';
import { BaseViewResolver } from '../view-resolver/base-view-resolver';
import { ActionViewResult } from '../model/action-view-result';
import { NoopViewResolver } from '../view-resolver/noop-view.resolver';
import { ExecuteTaskViewResolverService } from '../view-resolver/execute-task-view-resolver.service';
import { DisabledHint } from '@shared/hint-disabler/model/disabled-hint';
import { HintDisablerService } from '@shared/hint-disabler/service/hint-disabler.service';
import { UserActionPopupFlowConfigService } from '@shared/user-action/service/user-action-popup-flow-config.service';
import { ModalViewsConfigService } from '@shared/user-task/base/service/modal-views-config.service';
import {
  recursiveStringsObjToTranslationSources,
} from '@util/helper-functions/recursive-strings-obj-to-translation.sources';
import { PopupFlowGaService } from '@shared/popup-flow/service/popup-flow-ga.service';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';
import { DialogUtil } from '@common/dialog/utils/dialog.util';

type ViewToComponentAndResolverMap = {
  [K in ActionViewType]: {
    component: ComponentType<BaseActionViewComponent<K>>;
    viewResolver: Type<BaseViewResolver<ActionViewResult<K>>>;
    disabledHintKey?: DisabledHint;
  };
};

@Injectable({
  providedIn: 'root',
})
export class ActionViewService extends NgUnsubscribe {

  /**
   * It is mandatory to define page and resolver for each view type
   */
  private readonly VIEW_TO_COMPONENT_AND_RESOLVER_MAP: ViewToComponentAndResolverMap = {
    WATCH_EMAIL_VERIFIED_BASIC_DATA: {
      component: WatchEmailVerifiedBasicDataViewComponent,
      viewResolver: NoopViewResolver,
      disabledHintKey: 'HINT_ADD_WATCH',
    },
    WATCH_NO_EMAIL_VERIFIED_NO_BASIC_DATA: {
      component: WatchNoEmailVerifiedNoBasicDataViewComponent,
      viewResolver: ExecuteTaskViewResolverService,
    },
    WATCH_EMAIL_VERIFIED_NO_BASIC_DATA: {
      component: WatchEmailVerifiedNoBasicDataViewComponent,
      viewResolver: ExecuteTaskViewResolverService,
    },
    WATCH_NO_EMAIL_VERIFIED_BASIC_DATA: {
      component: WatchNoEmailVerifiedBasicDataViewComponent,
      viewResolver: NoopViewResolver,
    },
  };

  constructor(
    private readonly userActionPopupFlowConfigService: UserActionPopupFlowConfigService,
    private readonly injector: Injector,
    private readonly aukMatDialogService: AukMatDialogService,
    private readonly hintDisablerService: HintDisablerService,
    private readonly modalViewsConfigService: ModalViewsConfigService,
    private readonly popupFlowGaService: PopupFlowGaService,
  ) {
    super();
  }

  /**
   * Opens a dialog with a view
   * @param suggestedViewDto - data from HTTP header
   * @param userAction - in context of which task should be dialog opened (affects text content in a dialog)
   */
  public openView<T extends ActionViewType>(suggestedViewDto: SuggestedViewDto<T>, userAction: UserActionType): void {
    if (isNil(suggestedViewDto?.code)) {
      return;
    }

    const component: ComponentType<BaseActionViewComponent<T>>
      = this.VIEW_TO_COMPONENT_AND_RESOLVER_MAP[suggestedViewDto.code].component;

    const disabledHintKey: DisabledHint
      = this.VIEW_TO_COMPONENT_AND_RESOLVER_MAP[suggestedViewDto.code].disabledHintKey;

    this.hintDisablerService.hasKey$(disabledHintKey)
      .pipe(
        filter((hasDisabledHintKey) => !hasDisabledHintKey),
        mergeMap(() => this.getTextContent$(suggestedViewDto.code, userAction)),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((textContent: ActionViewData<T>['textContent']) => {
        const config: MatDialogConfig<ActionViewData<T>> = {
          data: {
            additionalData: suggestedViewDto.additionalData,
            textContent,
          },
          ...this.modalViewsConfigService.getModalConfig(),
        };

        this.aukMatDialogService.open$<BaseActionViewComponent<T>, ActionViewData<T>, UserTaskType>(
          component,
          DialogUtil.of('POPUP_FLOW'),
          config)
          .pipe(
            switchMap((dialogRef) => dialogRef.afterClosed()),
            tap(() => void this.popupFlowGaService.trackPopupFlowStepDisplayedView(userAction, suggestedViewDto.code)),
            take(1),
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe((result: ActionViewResult<T>) => {
            const resolver: BaseViewResolver<ActionViewResult<T>>
              = this.injector.get(this.VIEW_TO_COMPONENT_AND_RESOLVER_MAP[suggestedViewDto.code].viewResolver);

            resolver.resolve(
              userAction,
              {
                viewName: suggestedViewDto.code,
              },
              result,
            );
          });
      });
  }

  private getTextContent$<T extends ActionViewType>(code: T, action: UserActionType): Observable<ActionViewData<T>['textContent']> {
    return this.userActionPopupFlowConfigService.getViewsConfiguration$(code, action)
      .pipe(
        map((textContent) => recursiveStringsObjToTranslationSources(textContent)),
      );
  }

}
