import { UserTaskExecutorService } from '../../base/service/user-task-executor.service';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';
import { UserTaskStepDefinitionModel } from '../../base/model/user-task-step-definition.model';
import { UserTaskStepType } from '../../base/model/user-task-step.type';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Injectable, Injector } from '@angular/core';
import { ModalViewsConfigService } from '../../base/service/modal-views-config.service';
import { Observable, of } from 'rxjs';
import { UserTaskVerifiedBankAccountStepNoAccountVerifiedResolutionHandlerService } from './user-task-verified-bank-account-step-no-account-verified-resolution-handler.service';
import { PopupFlowGaService } from '@shared/popup-flow/service/popup-flow-ga.service';
import { UserTaskVerifiedBankAccountStepNoAccountVerifiedComponent } from '../component/user-task-verified-bank-account-step-no-account-verified.component';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';

@Injectable({
  providedIn: 'root',
})
export class UserTaskVerifiedBankAccountExecutorService extends UserTaskExecutorService<'VERIFIED_BANK_ACCOUNT'> {

  protected override matDialogConfigOverride: MatDialogConfig = {
    width: '800px',
    maxWidth: '800px',
    panelClass: ['custom-material-modal'],
  };

  protected taskType: 'VERIFIED_BANK_ACCOUNT' = 'VERIFIED_BANK_ACCOUNT' as const;

  protected steps: {
    [STEP_TYPE in UserTaskStepType<'VERIFIED_BANK_ACCOUNT'>]: UserTaskStepDefinitionModel<'VERIFIED_BANK_ACCOUNT', STEP_TYPE>
  } = {
      NO_ACCOUNT_VERIFIED: {
        component: UserTaskVerifiedBankAccountStepNoAccountVerifiedComponent,
        resolutionHandler: UserTaskVerifiedBankAccountStepNoAccountVerifiedResolutionHandlerService,
      },
    };

  constructor(
    injector: Injector,
    modalViewsConfigService: ModalViewsConfigService,
    popupFlowGaService: PopupFlowGaService,
    aukMatDialogService: AukMatDialogService,
  ) {
    super(injector, modalViewsConfigService, popupFlowGaService, aukMatDialogService);
  }

  protected getFirstStep$(payload: UserTaskPayloadModel<'VERIFIED_BANK_ACCOUNT'>):
    Observable<UserTaskStepUnionModel<'VERIFIED_BANK_ACCOUNT'>> {

    return of({
      type: 'NO_ACCOUNT_VERIFIED',
      payload: {
        ...payload?.stepsText?.NO_ACCOUNT_VERIFIED,
        source: payload.source,
        bankAccountNumberToVerify: payload.bankAccountNumberToVerify,
      },
    });
  }

}
