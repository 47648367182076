<div class="tw-overflow-hidden tw-w-full tw-relative tw-p-0.5 tw--m-0.5">
  <div #scrollElm class="scroll-items">
    <ng-content/>
  </div>

  <!-- PREV BUTTON -->
  @if (isPrevAllowed) {
    <div class="arrow-container left">
      <auk-navigation-icon-button
          (buttonClick)="moveLeft()"
          class="tw-pointer-events-auto"
      />
    </div>
  }
  <!-- ./PREV BUTTON -->

  <!-- NEXT BUTTON -->
  @if (isNextBtnContainerVisible) {
    <div class="arrow-container right">
      @if (isNextAllowed) {
        <auk-navigation-icon-button
            direction="RIGHT"
            (buttonClick)="moveRight()"
            class="tw-pointer-events-auto"
        />
      }
    </div>
  }
  <!-- NEXT BUTTON -->

</div>
