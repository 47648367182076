import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { Category } from '@api/generated/defs/Category';
import { ItemAttributeFilter } from '@api/generated/defs/ItemAttributeFilter';
import { ItemCategoryDto } from '@api/generated/defs/ItemCategoryDto';
import { ItemSearchAggregationBucket } from '@api/generated/defs/ItemSearchAggregationBucket';
import { ItemSearchItem } from '@api/generated/defs/ItemSearchItem';
import { ItemAttributeValueDto, ItemSearchAggregation, ItemSearchFilter, SuggestAllResponse } from '@api/generated/model';
import { SortCriteria, SortOption } from '../../../../../../typings/original/deprecated';
import { ItemSearchCategoryPath, ItemSearchCurrentCategory } from '../../../../../../typings/original/internal';
import { AppHeaderSearchService } from '@shared/app-header/module/app-header-search/service/app-header-search.service';
// TODO: [PDEV-18307] - fix dependency
// eslint-disable-next-line import/no-restricted-paths
import { predefinedCriteria as predefinedSortCriteria } from '@shared/sorting-dropdown/predefined-criterias';
import { BrowserService } from '@shared/platform/browser.service';

import {
  BRAND_ATTRIBUTE_IDS,
  JsonLdAvailability,
  JsonLdBreadcrumbs,
  JsonLdBreadcrumbsListItem,
  JsonLdImage,
  JsonLdInstrument,
  JsonLdItemCondition,
  JsonLdListing,
  JsonLdListItem,
  JsonLdOfferDetail,
  JsonLdOfferDetailBrand,
  JsonLdOrganization,
  JsonLdSale,
  JsonLdScriptEntry,
  JsonLdStructure,
  JsonLdType,
  JsonLdWebPage,
  STATE_OF_GOODS_NEW,
  STATE_OF_GOODS_USED,
} from './json-ld.helpers';
import { BreadcrumbsDto } from '../../../../breadcrumbs/breadcrumbs.component';
import { ProductProfileHelper } from '@shared/util/product/product-profile.helper';
import isNil from 'lodash-es/isNil';
import { AukroHeadquartersDto } from '@shared/services/configurator-cache/model/aukro-headquarters.dto';
import { ProductUtil } from '@shared/util/product/product.util';
import { SearchCategoryModel } from '@shared/app-header/module/app-header-search/model/search-category.model';
import { SuggestAllResponseContent } from '@api/generated/defs/SuggestAllResponseContent';
import { SuggestAllResponseContentElementCategory } from '@api/generated/defs/SuggestAllResponseContentElementCategory';
import { SuggestAllResponseContentElementItem } from '@api/generated/defs/SuggestAllResponseContentElementItem';
import { SuggestAllResponseContentElementLandingPage } from '@api/generated/defs/SuggestAllResponseContentElementLandingPage';
import { STATE_OF_GOODS_ATTRIBUTE_ID } from '@shared/item-card/service/item-card-mapper.service';
import { ObjectUtils } from '@util/util/object.utils';
// eslint-disable-next-line auk-rules/no-mixed-api-files
import { OfferDetailDto } from '@api/aukro-api/model/offer-detail-dto';

/**
 * Build and serve JSON-LD data for pages (listing, offer, homepage, ...)
 */
@Injectable({
  providedIn: 'root',
})
export class JsonLdService {

  public redrawTrigger = new Subject<JsonLdType>();
  private dataStorage: Record<JsonLdType, JsonLdStructure> = {} as Record<JsonLdType, JsonLdStructure>;
  private readonly filterNames: { [key: string]: string } = {
    auction: 'Aukce',
    aukroPlus: 'TOP Seller',
    buyNowActive: 'Kup teď',
    categoryId: 'Kategorie id',
    countryId: 'Země id',
    distance: 'Vzdálenost',
    endingAfter: 'Končící po',
    endingBefore: 'Končící před',
    endingBeforeRel: 'Končící nabídky za',
    finished: 'Skončené',
    freeShipping: 'Přeprava zdarma',
    location: 'Lokalita',
    paymentOnline: 'Platby přes PayU',
    personalPickup: 'Osobní převzetí',
    postCode: 'PSČ',
    ppCategoryPage: 'Propagace - kategorie',
    ppMainPage: 'Propagace - hlavní strana',
    priceMax: 'Cena max',
    priceMin: 'Cena min',
    searchAll: 'Hledat v popisku',
    sellerId: 'Uživatel id',
    specialFlagBasicStartingPrice: 'Od 1 Kč',
    specialFlagEndingSoon: 'Končící',
    specialFlagNew: 'Nové',
    startingAfter: 'Vystavené od',
    startingAfterRel: 'Vystavené nabídky před',
    startingBefore: 'Vystavené do',
    splitGroupValue: 'Uživatelská skupina',
    hotAuction: 'Žhavé aukce',
  };

  constructor(
    private readonly browserService: BrowserService,
    private readonly headerSearchService: AppHeaderSearchService,
    private readonly router: Router,
  ) {
  }

  public configureListing(
    algorithmByKey: Record<string, string>,
    query: string,
    items: ItemSearchItem[],
    sort: string,
    currentCategory: ItemSearchCurrentCategory,
    appliedFilters: ItemSearchFilter,
    parameters: ItemSearchAggregation[],
    startPosition, /* 1st page from 1, 2nd from 61, ...*/
  ): void {

    // results list
    const offers: JsonLdListItem[] = [];
    let i = startPosition;
    items.forEach((item: ItemSearchItem) => {
      const categoriesPath = item.categoryPath.map((categoryItem: Category) => categoryItem.name).join('/');
      offers.push({
        '@context': 'http://schema.org',
        '@type': ['ListItem', 'Product'],
        position: i,
        name: item.itemName,
        url: this.browserService.baseUrl + ProductUtil.getOfferUrl(item)[1],
        image: this.buildImageObjectFromSearchItem(item, item.sellerLogin),
        sku: item.itemId.toString(),
        offers: {
          '@type': 'Offer',
          priceCurrency: item.price.currency,
          price: item.price.amount,
          category: categoriesPath,
        },
      });
      i++;
    });

    const instrument: JsonLdInstrument[] = [];
    instrument.push({
      '@type': 'ChooseAction',
      name: 'Search Variant',
      actionOption: this.getVersion(algorithmByKey),
    });

    // sorting
    if (sort.length) {
      const parsedSort = sort.split(':');
      const sortCriteria = predefinedSortCriteria.find((item: SortCriteria) => {
        let opt: SortOption;
        // first check by name
        if (item.name === parsedSort[0]) {
          return true;
        } else {
          // second check by option.name
          opt = item.options.find((option: SortOption) => option.name === parsedSort[0]);
          if (opt) {
            return true;
          }
        }
        return false;
      });
      if (!isNil(sortCriteria)) {
        const sortOption = sortCriteria.options.find((item: SortOption) => item.value === parsedSort[1]);
        instrument.push({
          '@type': 'ChooseAction',
          name: 'Řazení',
          actionOption: `${ sortCriteria.title }, ${ sortOption.title }`,
        });
      }
    }

    // filters
    Object.entries(appliedFilters).forEach(([key, value]) => {
      if (key === 'filter') {
        value.forEach((item: ItemAttributeFilter) => {
          const attribute = parameters.find((parameter: ItemSearchAggregation) =>
            parameter.type === 'attribute' && parameter.attributeId === item.attributeId);

          if (attribute) {
            instrument.push({
              '@type': 'ChooseAction',
              name: attribute.message,
              actionOption: item.value.map((option: string) =>
                attribute.buckets.find((bucket: ItemSearchAggregationBucket) => bucket.value === Number(option))?.message),
            });
          }
        });
      } else {
        if (this.filterNames[key] === undefined || value === undefined) {
          return;
        }
        instrument.push({
          '@type': 'ChooseAction',
          name: this.filterNames[key],
          actionOption: this.getFilterValue(value),
        });
        // add category name
        if (key === 'categoryId' && currentCategory) {
          instrument.push({
            '@type': 'ChooseAction',
            name: 'Název kategorie',
            actionOption: currentCategory.name,
          });
        }
      }
    });

    const output: JsonLdListing = {
      '@context': 'http://schema.org',
      '@type': 'SearchAction',
      query,
      result: {
        '@type': 'ItemList',
        name: 'Search Results',
        itemListElement: offers,
      },
      instrument,
    };

    this.save('LISTING', output);
  }

  public configureCategory(items: ItemSearchItem[],
    listingName: string,
    currentUrl: string): void {

    // results list
    const offers: JsonLdOfferDetail[] = [];
    let categoriesPath;
    items.forEach((item: ItemSearchItem) => {
      categoriesPath = item.categoryPath.map((category: Category) => category.name).join('/');
      offers.push({
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: item.itemName,
        url: this.browserService.baseUrl + ProductUtil.getOfferUrl(item)[1],
        image: this.buildImageObjectFromSearchItem(item, item.sellerLogin),
        sku: item.itemId.toString(),
        offers: {
          '@type': 'Offer',
          priceCurrency: item.price.currency,
          price: item.price.amount,
          category: categoriesPath,
        },
      });
    });

    const output: JsonLdWebPage = {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      name: listingName,
      url: this.browserService.baseUrl + currentUrl.slice(1),
      mainEntity: {
        '@type': 'WebPageElement',
        offers: {
          '@type': 'Offer',
          itemOffered: offers,
        },
      },
    };

    this.save('LISTING', output);
  }

  public configureSuggestions(suggestionResource: SuggestAllResponse,
    appliedFilters: ItemSearchFilter,
    searchCategories: SearchCategoryModel[],
    specialFlagSelected: boolean): void {

    // results list
    const offersAndCategories: JsonLdListItem[] = [];

    const categories: SuggestAllResponseContentElementCategory[] | SuggestAllResponseContentElementLandingPage[] =
      suggestionResource.content
        .filter((content: SuggestAllResponseContent) => content.type === 'CATEGORY' || content.type === 'LANDING_PAGE')
        .flatMap((content: SuggestAllResponseContent) =>
          content.elements as SuggestAllResponseContentElementCategory[] | SuggestAllResponseContentElementLandingPage[],
        );
    categories.forEach((category: SuggestAllResponseContentElementCategory | SuggestAllResponseContentElementLandingPage, key: number) => {
      const categoryUrl: string = this.router.serializeUrl(
        this.headerSearchService.createSuggestedCategoryItemUrlTree({
          itemSearchFilter: appliedFilters,
          categorySeoUrl: category?.seoUrl,
          specialFlag: specialFlagSelected,
        }),
      );

      offersAndCategories.push({
        '@context': 'http://schema.org',
        '@type': ['ListItem', 'CategorySearch'],
        position: key + 1,
        name: appliedFilters.text + ' v kategorii ' + category.name,
        url: this.browserService.baseUrl + categoryUrl.substr(1), // remove slash from categoryUrl
      });
    });

    const categoriesCount: number = categories.length;

    suggestionResource.content
      .filter((content: SuggestAllResponseContent) => content.type === 'ITEM')
      .flatMap((content: SuggestAllResponseContent) => content.elements)
      .forEach((item: SuggestAllResponseContentElementItem, key: number) => {
        offersAndCategories.push({
          '@context': 'http://schema.org',
          '@type': ['ListItem', 'Product'],
          position: categoriesCount + key + 1,
          name: item.name.replace(/<\/?[^>]+(>|$)/g, ''), // remove html tags
          url: this.browserService.baseUrl + ProductUtil.getOfferUrl(item)[1],
          offers: {
            '@type': 'Offer',
            price: item.price?.amount,
            priceCurrency: item.price?.currency,
          },
        });
      });

    const instrument: JsonLdInstrument[] = [];
    instrument.push({
      '@type': 'ChooseAction',
      name: 'Search Variant',
      actionOption: suggestionResource.resultReport.version,
    });

    // filters
    Object.entries(appliedFilters).forEach(([key, value]) => {
      if (this.filterNames[key] === undefined || !value) {
        return;
      }
      instrument.push({
        '@type': 'ChooseAction',
        name: this.filterNames[key],
        actionOption: this.getFilterValue(value),
      });
      // add category name
      if (key === 'categoryId') {
        instrument.push({
          '@type': 'ChooseAction',
          name: 'Název kategorie',
          actionOption: searchCategories?.find((searchCategory: SearchCategoryModel) => searchCategory.categoryId === value)?.htmlName?.key,
        });
      }
    });

    const output: JsonLdListing = {
      '@context': 'http://schema.org',
      '@type': 'SearchAction',
      query: appliedFilters.text,
      result: {
        '@type': 'ItemList',
        name: 'Autocomplete',
        itemListElement: offersAndCategories,
      },
      instrument,
    };

    this.save('SUGGESTIONS', output);
  }

  public configureOffer(offer: OfferDetailDto): void {
    if (isNil(offer)) {
      return;
    }

    const categoriesModel = ProductProfileHelper.parseCategoriesModel(offer.category);

    // build category path string
    const categoryPath = categoriesModel.parentCategories.map((item: ItemCategoryDto) => item.name).reverse();
    categoryPath.push(categoriesModel.leafCategory.name);
    const category = categoryPath.join('/');

    // assign condition
    const stateOfGoods = offer.attributes.find((item: ItemAttributeValueDto) => item.attributeId === STATE_OF_GOODS_ATTRIBUTE_ID);
    let condition: JsonLdItemCondition;
    if (stateOfGoods) {
      if (stateOfGoods.attributeKey === STATE_OF_GOODS_NEW) {
        condition = JsonLdItemCondition.NEW_CONDITION;
      } else if (stateOfGoods.attributeKey === STATE_OF_GOODS_USED) {
        condition = JsonLdItemCondition.USED_CONDITION;
      }
    }

    // build brand object
    const brandAttribute = offer.attributes.find((item: ItemAttributeValueDto) => BRAND_ATTRIBUTE_IDS.includes(item.attributeId));
    const brand: JsonLdOfferDetailBrand = brandAttribute ? {
      '@type': 'Brand',
      name: brandAttribute.attributeValue,
    } : undefined;

    // assign availability
    let availability: JsonLdAvailability = JsonLdAvailability.IN_STOCK;
    if (offer.state === 'ENDED') {
      availability = offer.soldQuantity ? JsonLdAvailability.SOLD_OUT : JsonLdAvailability.DISCONTINUED;
    }

    const output: JsonLdOfferDetail = {
      '@context': 'http://schema.org',
      '@type': 'Product',
      sku: offer.itemId.toString(),
      name: offer.name,
      description: offer.shortDescription,
      image: this.buildImageObjectFromOfferDetail(offer, offer.seller.showName),
      category,
      brand,
      itemCondition: condition,
      offers: {
        '@type': 'Offer',
        availability,
        price: offer.price?.amount,
        priceCurrency: offer.price?.currency,
        url: this.browserService.baseUrl + ProductUtil.getOfferUrl(offer)[1],
      },
    };

    this.save('PRODUCT', output);
  }

  public configureSale(offer: OfferDetailDto): void {
    const output: JsonLdSale = {
      '@context': 'http://schema.org',
      '@type': 'SaleEvent',
      name: offer.name,
      description: offer.shortDescription,
      image: this.buildImageObjectFromOfferDetail(offer, offer.seller.showName),
      startDate: offer.startingTime,
      endDate: offer.endingTime,
      url: this.browserService.baseUrl + ProductUtil.getOfferUrl(offer)[1],
      location: {
        '@type': 'Place',
        name: offer.seller?.showName,
        address: {
          '@type': 'PostalAddress',
          addressCountry: 'CZ',
          addressLocality: offer.itemLocation,
          postalCode: offer.postCode?.replace(' ', ''),
        },
      },
    };

    this.save('SALE', output);
  }

  public configureBreadcrumbs(list: BreadcrumbsDto[]): void {
    const output: JsonLdBreadcrumbs = {
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: list.map((item: ItemSearchCategoryPath, index: number): JsonLdBreadcrumbsListItem => ({
        '@type': 'ListItem',
        position: index + 1,
        name: item.shortName,
        item: this.browserService.baseUrl + (item.seoUrl || this.router.url.slice(1)),
      })),
    };

    this.save('BREADCRUMBS', output);
  }

  public configureOrganization(description: string, aukroHeadquarters: AukroHeadquartersDto): void {
    const output: JsonLdOrganization = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: aukroHeadquarters.name,
      url: 'https://aukro.cz/',
      sameAs: [
        'https://www.facebook.com/Aukrocz/',
        'https://www.youtube.com/user/aukrocz',
        'https://twitter.com/Aukro',
        'https://www.instagram.com/aukrocz/',
        'https://cs.wikipedia.org/wiki/Aukro',
      ],
      logo: 'https://f.aukro.cz/static-images/google/aukro_google_logo.jpg',
      image: 'https://f.aukro.cz/static-images/google/aukro_google_photo.jpeg',
      description,
      address: {
        '@type': 'PostalAddress',
        streetAddress: aukroHeadquarters.address,
        addressLocality: aukroHeadquarters.city,
        addressRegion: aukroHeadquarters.region,
        postalCode: aukroHeadquarters.postcode,
        addressCountry: 'CZ',
      },
      areaServed: {
        '@type': 'Place',
        geo: {
          '@type': 'GeoCoordinates',
          latitude: String(aukroHeadquarters.latitude),
          longitude: String(aukroHeadquarters.longitude),
        },
        hasMap: 'https://goo.gl/maps/srfwUWeobfD2',
        openingHoursSpecification: [
          {
            '@type': 'OpeningHoursSpecification',
            closes: '16:00:00',
            dayOfWeek: 'http://schema.org/Monday',
            opens: '09:00:00',
          },
          {
            '@type': 'OpeningHoursSpecification',
            closes: '16:00:00',
            dayOfWeek: 'http://schema.org/Tuesday',
            opens: '09:00:00',
          },
          {
            '@type': 'OpeningHoursSpecification',
            closes: '16:00:00',
            dayOfWeek: 'http://schema.org/Wednesday',
            opens: '09:00:00',
          },
          {
            '@type': 'OpeningHoursSpecification',
            closes: '16:00:00',
            dayOfWeek: 'http://schema.org/Thursday',
            opens: '09:00:00',
          },
          {
            '@type': 'OpeningHoursSpecification',
            closes: '16:00:00',
            dayOfWeek: 'http://schema.org/Friday',
            opens: '09:00:00',
          },
        ],
      },
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: '+420 577 775 999',
        contactType: 'customer service',
      },
    };

    this.save('ORGANIZATION', output);
  }

  public remove(key: JsonLdType): void {
    delete this.dataStorage[key];
    this.redrawTrigger.next(key);
  }

  public getScriptTags(): JsonLdScriptEntry[] {
    return Object.entries(this.dataStorage)
      .map(([key, value]) => ({ id: `jsonld-${ key }`, value: JSON.stringify(value) }));
  }

  private save(key: JsonLdType, data: JsonLdStructure): void {
    this.dataStorage[key] = data;
    this.redrawTrigger.next(key);
  }

  private getFilterValue(value: boolean | string | number | undefined | any[]): string | number | undefined | any[] {
    if (Array.isArray(value)) {
      return value.map((item) => this.getFilterValue(item));
    } else if (value === true) {
      return 'Ano';
    } else if (value === false) {
      return 'Ne';
    } else {
      return value;
    }
  }

  private buildImageObjectFromSearchItem(offer: ItemSearchItem, sellerLogin: string): JsonLdImage {
    const imageUrl = offer.images && offer.images.lists && offer.images.lists.
      original && offer.images.lists.original[0] ? offer.images.lists.original[0].url : null;
    return this.buildImageObject(offer.itemName, offer.startingTime, imageUrl, sellerLogin);
  }

  private buildImageObjectFromOfferDetail(offer: OfferDetailDto, sellerLogin: string): JsonLdImage {
    const imageUrl = offer?.itemImages?.find(i => i.position == 0)?.sizes.ORIGINAL?.url;
    return this.buildImageObject(offer.name, offer.startingTime, imageUrl, sellerLogin);
  }

  private buildImageObject(name: string, startingTime: string, imageUrl: string, sellerLogin: string): JsonLdImage {
    return imageUrl ? {
      '@type': 'ImageObject',
      contentUrl: imageUrl,
      caption: name,
      representativeOfPage: true,
      uploadDate: startingTime,
      author: {
        '@type': 'Person',
        alternateName: sellerLogin,
      },
    } : undefined;
  }

  public getVersion(algorithmByKey: Record<string, string>): string {
    const ALGORITHM_NOT_EXIST: string = 'algorithm not found';

    if (ObjectUtils.isObjectEmpty(algorithmByKey)) {
      return ALGORITHM_NOT_EXIST;
    }

    return Object.values(algorithmByKey)?.[0] ?? ALGORITHM_NOT_EXIST;
  }

}
