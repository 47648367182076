import { map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { DateUtils } from '@util/util/date.utils';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

/**
 * @returns given observable, but emits after observable completes and no earlier than x sec
 * @param source$ - Observable, which should be completed no earlier than x sec
 * @param seconds - Time (in seconds), which is the minimum time the Observable shut emit value
 * @param ngZoneUtilService - Service used to create timer
 */
export function atLeastXSec<T>(
  source$: Observable<T>,
  seconds: number = 1,
  ngZoneUtilService: NgZoneUtilService,
): Observable<T> {
  return forkJoin([
    source$,
    ngZoneUtilService.timerOut$(DateUtils.convertSecondsToMilliseconds(seconds)),
  ])
    .pipe(
      map(([sourceResult]) => sourceResult),
    );

}
