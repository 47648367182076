import { Inject, Injectable, InjectionToken } from '@angular/core';
import { CookieDict, CookieOptions, CookieService as NgxCookieService } from 'ngx-cookie';
import { CapacitorCookies } from '@capacitor/core';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NATIVE_APP_ORIGIN } from '@common/routing/service/routing.service';
import moment from 'moment-mini-ts';
import { Nil } from '@util/helper-types/nil';
import { captureMessage } from '@sentry/browser';

export const NGX_COOKIE_SERVICE = new InjectionToken('ngxCookieService');

@Injectable({
  providedIn: 'root',
})
export class CookieService {

  constructor(
    @Inject(NGX_COOKIE_SERVICE) private ngxCookieService: NgxCookieService,
  ) {
  }

  public put(key: string, value: string | undefined, options?: CookieOptions): void {
    this.ngxCookieService.put(key, value, options);
    if (PlatformCommonService.isNativeApp) {
      const expireDate = moment().add(365, 'days').toDate().toUTCString();
      void CapacitorCookies.setCookie({
        url: NATIVE_APP_ORIGIN,
        key,
        value,
        expires: expireDate,
      });
    }
  }

  public hasKey(key: string): boolean {
    return this.ngxCookieService.hasKey(key);
  }

  public get(key: string): string {
    return this.ngxCookieService.get(key);
  }

  public getAll(): CookieDict {
    return this.ngxCookieService.getAll();
  }

  public getObject<T extends object>(key: string): T | Nil {
    return this.ngxCookieService.getObject(key) as T;
  }

  public putObject(key: string, value: object, options?: CookieOptions): void {
    this.ngxCookieService.putObject(key, value, options);
    if (PlatformCommonService.isNativeApp) {
      let dataJSON = null;

      try {
        dataJSON = JSON.stringify(value);
      } catch {
        captureMessage('Error while converting JSON from Object in Native App', { level: 'info', extra: { value, key } });
      }

      if (!dataJSON) {
        return;
      }

      const expireDate = moment().add(365, 'days').toDate().toUTCString();
      void CapacitorCookies.setCookie({
        url: NATIVE_APP_ORIGIN,
        key,
        value: JSON.stringify(value),
        expires: expireDate,
      });
    }
  }

  public remove(key: string, options?: CookieOptions): void {
    this.ngxCookieService.remove(key, { ...options, expires: new Date(0) });
    if (PlatformCommonService.isNativeApp) {
      const expireDate = moment().toDate().toUTCString();
      void CapacitorCookies.setCookie({
        url: NATIVE_APP_ORIGIN,
        key,
        value: '',
        expires: expireDate,
      });
      void CapacitorCookies.deleteCookie({ key, url: NATIVE_APP_ORIGIN });
    }
  }

}
