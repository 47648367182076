@if (loginForm) {
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit($event)">
    <mat-form-field class="tw-w-full" appearance="outline">
      <mat-label>
        {{ inputPlaceholder | translateSource }}
      </mat-label>
      <input autocomplete="username" class="tw-hidden" [value]="loginOrEmail">
      <input [type]="isPasswordShown ? 'text' : 'password'"
        [placeholder]="inputPlaceholder | translateSource"
        formControlName="password"
        matInput
        aukInputTrim
        autocomplete="current-password"
        aukDelayedAutofocus
        [ignoreOnIos]="true"
        >
      <auk-icon iconType="MATERIAL"
        colorCombination="TERTIARY_CONTRAST"
        size="LG"
        class="cursor-pointer"
        [source]="isPasswordShown ? 'visibility_off' : 'visibility'"
        (click)="toggleShowPassword()"
        matSuffix
      ></auk-icon>
    </mat-form-field>
    @if (!regDomainCodeCrossDomain) {
      <auk-button type="submit"
        colorCombination="PRIMARY"
        [chin]="true"
        class="tw-w-full"
        [isDisabled]="submitDisabled || loginForm.invalid"
        [isLoading]="submitDisabled"
        >
        {{ loginButton | translateSource }}
      </auk-button>
    } @else {
      <auk-button colorCombination="PRIMARY"
        [chin]="true"
        class="tw-w-full tw-mb-3"
        [isDisabled]="submitDisabled || loginForm.invalid"
        [isLoading]="submitDisabled"
        (click)="emitCrossDomainValidation(false)"
        >
        {{ regDomainCodeCrossDomain | translateSource }}
      </auk-button>
      <auk-button colorCombination="SECONDARY"
        class="tw-w-full"
        [isDisabled]="submitDisabled || loginForm.invalid"
        [isLoading]="submitDisabled"
        (click)="emitCrossDomainValidation(true)"
        >
        {{ { key: 'REGISTER_NEW_ACCOUNT' } | translateSource }}
      </auk-button>
    }
  </form>
}
