import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { TokenMonitoringService } from '@shared/services/token-monitoring/token-monitoring.service';
import { take } from 'rxjs/operators';
import { TOKEN_GENERATION_DISABLED } from '@shared/rest/model/token-generation-disabled-http-context.token';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private readonly tokenMonitoringService: TokenMonitoringService,
  ) {
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(TOKEN_GENERATION_DISABLED)) {
      return next.handle(req);
    }

    return this.tokenMonitoringService.checkAndGenerateToken()
      .pipe(
        take(1),
        mergeMap(() => next.handle(req)),
      );
  }

}
