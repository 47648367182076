<a
  [routerLink]="item | offerUrl"
  [class.focus]="focus"
  class="tw-items-center !tw-no-underline tw-flex tw-justify-between tw-transition-[0.3s] tw-px-4 tw-py-2"
  data-gtm="go-to-suggested-item"
>
  <p
    [aukInnerHtml]="item.name"
    class="tw-text-contrast-opposite tw-text-md tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-w-full"
  ></p>
  <div class="tw-text-matter-accent-primary tw-flex tw-justify-end tw-items-center tw-shrink-0 tw-font-bold tw-relative tw-text-right tw-text-md">
    <auk-icon iconType="SVG" source="hand" size="MD" class="tw-mr-2" colorCombination="SILVERSTEIN_200_CONTRAST"></auk-icon>
    {{ item.price | currency }}
  </div>
</a>
