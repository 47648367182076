import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import { RegistryCountryItemDto } from '@api/generated/defs/RegistryCountryItemDto';

/**
 * @deprecated
 * Do not add new things into this class, always create more specific class related to your use case
 */
export class Constants {

  public static readonly CONTACT_FORM_MEASUREMENT_PAGE_LEAVE_EVENT_URL:
    string = RestHttpClientService.API_URL + '/measurement/contactFormEvent';

  public static readonly ZASILKOVNA_BRANCH_URL: string = 'https://www.zasilkovna.cz/pobocky/';

  public static readonly ITEM_REPORT_MAX_COMMENT_LENGTH: number = 160;

  public static readonly NEW_LINE: string = '\r\n';

  public static readonly REG_NUMBER_MIN_LENGTH: number = 8;

  public static readonly COMPANY_NAME_MIN_LENGTH: number = 2;

  public static readonly MINIMUM_REGISTERED_USER_AGE: number = 18;
  public static readonly MAXIMUM_REGISTERED_USER_AGE: number = 100;

  public static readonly DEFAULT_COUNTRY_CZ: RegistryCountryItemDto = {
    id: 56,
    code: 'CZ',
    name: 'Česká republika',
    postcodeRegexp: '^\\d{3}[ ]?\\d{2}$',
  };

  public static readonly DEFAULT_COUNTRY_SK: RegistryCountryItemDto = {
    id: 181,
    code: 'SK',
    name: 'Slovensko',
    postcodeRegexp: '^\\d{3}[ ]?\\d{2}$',
  };

  public static readonly DEFAULT_COUNTRY: RegistryCountryItemDto = Constants.DEFAULT_COUNTRY_CZ;

  public static readonly DEFAULT_COUNTRY_LIST: RegistryCountryItemDto[] = [Constants.DEFAULT_COUNTRY_CZ, Constants.DEFAULT_COUNTRY_SK];

}
