import { coerceCssPixelValue } from '@angular/cdk/coercion';
import { ConnectedPosition, FlexibleConnectedPositionStrategy, FlexibleConnectedPositionStrategyOrigin, OverlayContainer, ViewportRuler } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ViewportScrollPosition } from '@angular/cdk/scrolling';

interface Point {
    x: number;
    y: number;
}

// ts-ignore needed as `_getExactOverlayY` is private property
// @ts-ignore
export class CustomPositionStrategy extends FlexibleConnectedPositionStrategy {

  constructor(
    connectedTo: FlexibleConnectedPositionStrategyOrigin,
    _viewportRuler: ViewportRuler,
    _document: Document,
    _platform: Platform,
    _overlayContainer: OverlayContainer,
  ) {
    super(connectedTo, _viewportRuler, _document, _platform, _overlayContainer);
  }

  /**
   * Overrides the private _getExactOverlayY method of the FlexibleConnectedPositionStrategy class
   *
   * When overlayY is 'bottom', the 'bottom' style is calculated using the document's clientHeight, overlayPoint.y,
   * and the overlay's height, effectively positioning the overlay from the bottom of the document.
   * @param position
   * @param originPoint
   * @param scrollPosition
   */
  private override _getExactOverlayY(
    position: ConnectedPosition, originPoint: Point, scrollPosition: ViewportScrollPosition,
  ): CSSStyleDeclaration {
    const styles = { top: '', bottom: '' } as CSSStyleDeclaration;
    // ts-ignore needed as `_getOverlayPoint` is private and only accessable within FlexibleConnectedPositionStrategy
    // @ts-ignore
    const overlayPoint = this._getOverlayPoint(originPoint, this._overlayRect, position);

    if (position.overlayY === 'bottom') {
      // ts-ignore needed as `_document` is private and only accessable within FlexibleConnectedPositionStrategy
      // @ts-ignore
      // eslint-disable-next-line
      const documentHeight = this._document.documentElement.clientHeight;
      // ts-ignore needed as `_overlayRect` is private and only accessable within FlexibleConnectedPositionStrategy
      // @ts-ignore
      styles.bottom = `${ documentHeight - (overlayPoint.y + this._overlayRect.height) }px`;
    } else {
      styles.top = coerceCssPixelValue(overlayPoint.y);
    }

    return styles;
  }

}
