import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { BehaviorSubject, combineLatestWith, Observable, of, switchMap } from 'rxjs';
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { Inject, Injectable } from '@angular/core';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { ArrayUtils } from '@util/util/array.utils';
import { DialogModel } from '@common/dialog/model/dialog.model';
import { DialogConfigModel } from '@common/dialog/model/dialog-config.model';
import { DIALOG_CONFIG } from '@common/dialog/di/dialog-config.injection-token';
import isEqual from 'lodash-es/isEqual';
import { BaseDialogType } from '@common/dialog/model/base-dialog.type';

/**
 * Service ensures that multiple dialogs are not opened at the same time. It stores dialogs in priority queue.
 */
@Injectable({
  providedIn: 'root',
})
export class DialogService<DIALOG_TYPE extends string> extends NgUnsubscribe {

  /**
   * Priority queue with fixed first element. First dialog in queue is the one that is currently opened, so its stays first even when dialog
   * with higher priority comes. Otherwise, elements are enqueued, sorted by priority and are processed using FIFO.
   */
  private _queue: BehaviorSubject<DialogModel<BaseDialogType | DIALOG_TYPE>[]>
    = new BehaviorSubject<DialogModel<BaseDialogType | DIALOG_TYPE>[]>([]);

  constructor(@Inject(DIALOG_CONFIG) protected config: DialogConfigModel<BaseDialogType | DIALOG_TYPE>) {
    super();
  }

  public isOpened$(dialog: DialogModel<BaseDialogType | DIALOG_TYPE>): Observable<boolean> {
    return this.opened$
      .pipe(
        map((opened) => isEqual(opened, dialog)),
        distinctUntilChanged(),
      );
  }

  /**
   * Add dialog to queue if not already in queue.
   * Emits true (dialog is allowed to open) once it's the dialog turn. Instantly emits false otherwise - when dialog can't be opened.
   */
  public open$(dialog: DialogModel<BaseDialogType | DIALOG_TYPE>): Observable<boolean> {
    if (isNil(dialog)) {
      return of(false);
    }

    // Don't open nor enqueue dialog if its opened or in queue
    if (this.isOpened(dialog) || this.isInQueue(dialog)) {
      return of(false);
    }

    const waitForOpen$ = this.opened$
      .pipe(
        filter(m => isEqual(m, dialog)),
        map(() => true),
        distinctUntilChanged(),
      );

    return of(dialog)
      .pipe(
        combineLatestWith(this.restrictedTypes$()),
        switchMap(([modal, arr]) => {
          // Modal can't be opened under current conditions
          if (this.isCurrentlyRestricted(modal, arr)) {
            return of(false);
          }

          this.enqueue(dialog);

          return waitForOpen$;
        }),
      );
  }

  protected restrictedTypes$(): Observable<(BaseDialogType | DIALOG_TYPE)[]> {
    return of<(BaseDialogType | DIALOG_TYPE)[]>([]);
  }

  private isCurrentlyRestricted(
    dialog: DialogModel<BaseDialogType | DIALOG_TYPE>,
    restricted: (BaseDialogType | DIALOG_TYPE)[]): boolean {
    return restricted?.includes(dialog.type);
  }

  /**
   * Closes opened dialog or removes dialog from queue if not opened.
   * This method must be called after closing dialog to queue to work properly!
   */
  public close(dialog: DialogModel<BaseDialogType | DIALOG_TYPE>): void {
    // Remove opened
    if (this.isOpened(dialog)) {
      this.dequeue();
      return;
    }

    // Remove enqueued
    if (this.isInQueue(dialog)) {
      this.removeFromQueue(dialog);
      return;
    }

    return;
  }

  private get opened$(): Observable<DialogModel<BaseDialogType | DIALOG_TYPE> | Nil> {
    return this._queue.asObservable()
      .pipe(map((queue) => queue[0]));
  }

  private enqueue(dialog: DialogModel<BaseDialogType | DIALOG_TYPE>): void {
    const queue = this._queue.value;

    if (ArrayUtils.isEmpty(queue)) {
      this._queue.next([dialog]);
      return;
    }

    this._queue.next([
      // Keep first as it is already open
      queue[0],
      // Enqueue the new one, sort by priority
      ...[...queue.slice(1, queue.length + 1), dialog]
        .sort((a, b) => this.config?.priorityMap?.[a.type] - this.config?.priorityMap?.[b.type])],
    );
  }

  private dequeue(): DialogModel<BaseDialogType | DIALOG_TYPE> | Nil {
    const q = this._queue.value;
    const nextDialog = q?.shift();

    this._queue.next(q);
    return nextDialog;
  }

  private removeFromQueue(dialog: DialogModel<BaseDialogType | DIALOG_TYPE>): void {
    this._queue.next(this._queue.value.filter(m => !isEqual(m, dialog)));
  }

  private isInQueue(dialog: DialogModel<BaseDialogType | DIALOG_TYPE>): boolean {
    if (isNil(dialog.id)) {
      return isNotNil(this._queue.value.find(m => m.type === dialog.type));
    }

    return isNotNil(this._queue.value.find(m => m.type === dialog.type && m.id == dialog.id));
  }

  private isOpened(dialog: DialogModel<BaseDialogType | DIALOG_TYPE>): boolean {
    return isEqual(this._queue.value[0], dialog);
  }

}
