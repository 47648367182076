import { CustomEventListener } from '@util/util/model/custom-event-listener';
import { ActivatedRoute } from '@angular/router';

export class BrowserUtils {

  public static createDocumentVisibilityHiddenListener(cb: (e?: Event) => void): CustomEventListener {
    const eventHandler = (e: Event): void => {
      if (document.visibilityState === 'hidden') {
        cb(e);
      }
    };

    document.addEventListener('visibilitychange', eventHandler);

    return {
      removeListener: (): void => {
        document.removeEventListener('visibilitychange', eventHandler);
      },
    };
  }

  public static supportsNavigator(): boolean {
    return this.isFieldOfWindow('navigator');
  }

  private static isFieldOfWindow(field: string): boolean {
    return (typeof window === 'object') && (field in window);
  }

  public static getActiveRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

}
