import { Component, ElementRef } from '@angular/core';
import { ColorCombinationId } from '../../model/color-combination-id';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ColorUtil } from '../../util/color.util';
import { COLOR_COMBINATIONS } from '../../constant/color-combinations';

@Component({
  template: '',
})
export abstract class ColorCombinationComponent extends NgUnsubscribe {

  constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
  ) {
    super();
  }

  protected setColorCombination(colorCombination: ColorCombinationId): void {
    ColorUtil.setAbstractCssVarsOnElement(COLOR_COMBINATIONS[colorCombination], this.elementRef.nativeElement);
  }

}
