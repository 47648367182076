import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nil } from '@util/helper-types/nil';

@Component({
  selector: 'auk-badge',
  templateUrl: './badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})

export class BadgeComponent {

  @Input() public badgeText: string | number | Nil;

}
