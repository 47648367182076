import { PickupPointDto } from '@api/aukro-api/model/pickup-point-dto';
import { isNil } from 'ngx-cookie';

const PPL_PICKUP_POINT_SHIPPING_IDS = [43, 44];

export class PplWidgetHelper {

  public static isPplAndHasEmptyPickupPoint(shippingOptionId: number, pickupPoint: PickupPointDto): boolean {
    return (
      PPL_PICKUP_POINT_SHIPPING_IDS.includes(shippingOptionId) && (
        isNil(pickupPoint)
        || isNil(pickupPoint.code)
        || isNil(pickupPoint.name)
        || isNil(pickupPoint.url)
      )
    );
  }

}
