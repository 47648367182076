import isNil from 'lodash-es/isNil';

export class MapUtils {

  public static isEmpty(map: Map<any, any>): boolean {
    return (isNil(map) || map.size === 0);
  }

  public static isNotEmpty(data: Map<any, any>): boolean {
    return !this.isEmpty(data);
  }

}
