import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestCloneConfig } from '../rest/model/http-request-clone-config';
import isNil from 'lodash-es/isNil';
import { DomainService } from '@shared/platform/domain.service';
import { LocaleConstants } from '@shared/platform/locale.constants';
import { WITH_CREDENTIALS } from '@shared/rest/token/with-credentials-http-context.token';

/**
 * Adds 'X-Accept-Language' with corresponding language value resolved by domain.
 * Does not work on SSR. See {@link SsrDomainInterceptor}
 */
@Injectable()
export class LanguageInterceptorService implements HttpInterceptor {

  constructor(private readonly domainService: DomainService) {
  }

  public intercept(request: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const language = this.domainService.lang;

    if (isNil(language)) {
      return next.handle(request);
    }

    const requestCloneConfig: HttpRequestCloneConfig = {
      withCredentials: request.context.has(WITH_CREDENTIALS) ? request.context.get(WITH_CREDENTIALS) : true,
    };

    requestCloneConfig.headers = request.headers.set(LocaleConstants.LANGUAGE_HEADER_NAME, language);

    return next.handle(request.clone(requestCloneConfig));
  }

}
