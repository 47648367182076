import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { Translate2Module } from '@common/translations/translate2.module';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { AccordionItemContentDirective } from '@common/ui-kit/component/accordion/directive/accordion-item-content.directive';

import { AccordionComponent } from '@common/ui-kit/component/accordion/component/accordion.component';
import { AccordionItemComponent } from '@common/ui-kit/component/accordion/accordion-item/accordion-item.component';

@NgModule({
  imports: [
    CommonModule,
    IconComponent,
    MatExpansionModule,
    Translate2Module,
  ],
  declarations: [
    AccordionItemContentDirective,
    AccordionComponent,
    AccordionItemComponent,
  ],
  exports: [
    AccordionItemContentDirective,
    AccordionComponent,
    AccordionItemComponent,
  ],
})
export class AccordionModule {
}
