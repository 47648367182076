import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { merge, Subject } from 'rxjs';
import { debounceTime, finalize, take, takeUntil } from 'rxjs/operators';
import { PlatformService } from '@shared/platform/service/platform.service';
import { UserService } from '@shared/user/service/user.service';
import { validateAcceptableFileSize } from '@util/util/method/validate-acceptable-file-size.util';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { HttpError } from '@shared/rest/model/http-error';
import { FileFormatUtil } from '@util/util/file-format/domain/file-format.util';
import { FILE_TYPES } from '@util/util/file-format/constant/file-types.constant';
import { FileType } from '@util/util/file-format/model/file-type.model';
import { ImagesApiService, UploadAvatarRequestParams } from '@api/aukro-api/api/images-api.service';
import { TranslationSource } from '@common/translations/model/translation-source';
import { Nil } from '@util/helper-types/nil';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CameraService } from '@common/camera/service/camera.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

interface CroppieData {
  orientation: number;
  points: string[];
  zoom: number;
}

interface CroppieData {
  orientation: number;
  points: string[];
  zoom: number;
}

@Component({
  selector: 'auk-dialog-change-avatar',
  templateUrl: 'dialog-change-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogChangeAvatarComponent extends NgUnsubscribe implements OnInit, AfterViewInit, OnDestroy {

  public uploadInProgress: boolean = false;
  public isDropAreaActive: boolean = false;
  public isEditableImage: boolean = true;
  public image: HTMLImageElement; // image storage
  public errorMessage: TranslationSource | Nil = null;
  @ViewChild('fileInput', { static: false }) public fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('croppieArea', { static: false }) public croppieArea: ElementRef<HTMLElement>;
  public readonly MAX_IMAGE_SIZE_MB: number = 3;
  public readonly MIN_IMAGE_WIDTH: number = 65;
  public readonly MIN_IMAGE_HEIGHT: number = 65;
  private isUserRefreshNeeded: boolean = false;
  private uploadParams: UploadAvatarRequestParams = {
    width: 1,
    height: 1,
    offsetX: 0,
    offsetY: 0,
  };
  private fileToUpload: File;
  private croppie: any;
  private readonly ALLOWED_FILE_TYPE: FileType = 'photos';
  protected allowedFileFormats: string = FileFormatUtil.getFormatsForAcceptAttribute([this.ALLOWED_FILE_TYPE]);
  private croppieTerminator = new Subject<void>();

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly platformService: PlatformService,
    private readonly imagesApiService: ImagesApiService,
    private readonly dialogRef: MatDialogRef<DialogChangeAvatarComponent>,
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly cameraService: CameraService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.userService.getActualStatistics()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: UserInterestStatisticsDto) => {
        if (data.avatarUrlLarge) {
          this.isEditableImage = false;
          this.image = new Image();
          this.image.src = data.avatarUrlLarge;
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  public ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
  }

  public override ngOnDestroy(): void {
    this.croppieTerminator.next();
    this.croppieTerminator.complete();
    super.ngOnDestroy();
  }

  @HostListener('document:dragover', ['$event'])
  public onDragoverGlobally(event: DragEvent): void {
    // needed, otherwise open dropped file in new tab
    event.preventDefault();
    event.stopPropagation();

    this.isDropAreaActive = true;
    this.changeDetectorRef.detectChanges();
  }

  // cannot use dragend
  @HostListener('document:dragleave', ['$event'])
  public onDragleaveGlobally(event: DragEvent): void {
    // needed, otherwise open dropped file in new tab
    event.preventDefault();
    event.stopPropagation();

    if (
      event.clientX <= 0 ||
      event.clientX >= this.window.innerWidth ||
      event.clientY <= 0 ||
      event.clientY >= this.window.innerHeight
    ) {
      this.isDropAreaActive = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  @HostListener('document:drop', ['$event'])
  public onDropGlobally(event: DragEvent): void {
    // needed, otherwise open dropped file in new tab
    event.preventDefault();
    event.stopPropagation();

    this.isDropAreaActive = false;
    this.changeDetectorRef.detectChanges();
  }

  public onDropFile(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.isDropAreaActive = false;

    this.processSelectedImage(event.dataTransfer.files[0]);
  }

  private async uploadNativeFile(): Promise<void> {
    if (PlatformCommonService.isNativeApp) {
      //Quality of image is just 40% as it is just Avatar and we need to get into 3mb limit
      const images = await this.cameraService.getCameraImages(1, 40);
      if (images.length > 0) {
        this.processSelectedImage(images[0]);
      }
    }

    return Promise.resolve();
  }

  public onFileSelected(event: Event): void {
    this.processSelectedImage((event.target as HTMLInputElement).files[0]);
  }

  public openFileInput(): void {
    this.errorMessage = null;
    if (PlatformCommonService.isNativeApp) {
      void this.uploadNativeFile();
    } else {
      this.fileInput.nativeElement.click();
    }
  }

  public close(): void {
    if (this.isUserRefreshNeeded) {
      this.userService.resetCurrentUser();
    }
    this.dialogRef.close();
  }

  public save(): void {
    this.uploadInProgress = true;
    this.errorMessage = null;

    const uploadParams: UploadAvatarRequestParams & { file1?: File } = {
      ...this.uploadParams,
      file1: this.fileToUpload,
    };

    this.imagesApiService.uploadAvatar$(uploadParams)
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: () => {
          this.isUserRefreshNeeded = true;
          this.close();
        },
        error: (error: HttpError) => {
          this.uploadInProgress = false;
          this.setErrorMessage({ key: 'SET_AVATAR_ERROR_UPLOAD_FAILED' });
          throw error;
        },
      });
  }

  public remove(redraw: boolean = false): void {
    this.image = null;
    if (!this.isEditableImage) {
      this.isUserRefreshNeeded = true;
      this.imagesApiService.deleteAvatar$()
        .pipe(
          take(1),
          finalize(() => this.changeDetectorRef.detectChanges()),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe();
    } else if (redraw) {
      this.changeDetectorRef.detectChanges();
    }
  }

  private processSelectedImage(file: File): void {
    this.errorMessage = null;
    if (!file) {
      return;
    }
    if (!FileFormatUtil.validateAcceptableFileFormat(file, [this.ALLOWED_FILE_TYPE])) {
      const formats: string = FILE_TYPES[this.ALLOWED_FILE_TYPE].map((item: string) => item.toUpperCase()).join(', ');
      this.setErrorMessage({
        key: 'SET_AVATAR_ERROR_WRONG_FILE_FORMAT',
        params: { formats },
      });
      return;
    } else if (!validateAcceptableFileSize(file, this.MAX_IMAGE_SIZE_MB * 1024 * 1024)) {
      this.setErrorMessage({
        key: 'SET_AVATAR_ERROR_WRONG_FILE_SIZE',
        params: { maxImageSizeMb: this.MAX_IMAGE_SIZE_MB },
      });
      return;
    }

    this.remove();

    const onImageError = (): void => {
      this.setErrorMessage({
        key: 'SET_AVATAR_ERROR_CORRUPTED_FILE',
      });
      this.changeDetectorRef.detectChanges();
    };

    const reader = new FileReader();
    reader.onload = (): void => {
      this.image = new Image();
      this.image.onload = (): void => {
        if (this.image.naturalWidth < this.MIN_IMAGE_WIDTH || this.image.naturalHeight < this.MIN_IMAGE_HEIGHT) {
          this.image = null;
          this.setErrorMessage({
            key: 'SET_AVATAR_ERROR_TOO_SMALL_IMAGE',
            params: {
              minImageWidth: this.MIN_IMAGE_WIDTH,
              minImageHeight: this.MIN_IMAGE_HEIGHT,
            },
          });
        } else {
          this.isEditableImage = true;
          this.fileToUpload = file;
          this.changeDetectorRef.detectChanges();
          this.initCroppie(this.image.src);
        }
      };
      this.image.onerror = (): () => void => onImageError;
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      this.image.src = reader.result.toString();
    };
    reader.onerror = (): () => void => onImageError;
    reader.readAsDataURL(file);
  }

  private initCroppie(url: string): void {
    if (!this.platformService.isBrowser) {
      return;
    }

    if (this.croppie) {
      this.croppieTerminator.next();
      (this.croppie).destroy();
    }

    const Croppie = require('croppie');
    this.croppie = new Croppie(this.croppieArea.nativeElement, {
      mouseWheelZoom: false,
      viewport: {
        width: 150,
        height: 150,
        type: 'circle',
      },
    });

    this.croppie.bind({
      url,
      zoom: 0,
    });

    this.ngZoneUtilService.fromEventOut$(this.croppieArea.nativeElement, 'update')
      .pipe(
        debounceTime(200),
        takeUntil(
          merge(this.croppieTerminator, this.ngUnsubscribe),
        ),
      )
      .subscribe((event: CustomEvent<CroppieData>) => {
        const [offsetX, offsetY, width, height] = event.detail.points.map((item: string) => parseInt(item, 10));
        const calculatedWidth = width - offsetX;
        const calculatedHeight = height - offsetY;

        this.uploadParams = {
          width: this.getRatio(calculatedWidth, this.image.naturalWidth),
          height: this.getRatio(calculatedHeight, this.image.naturalHeight),
          offsetX: this.getRatio(offsetX, this.image.naturalWidth),
          offsetY: this.getRatio(offsetY, this.image.naturalHeight),
        };
      });
  }

  private setErrorMessage(value: TranslationSource): void {
    this.errorMessage = value;
    this.changeDetectorRef.detectChanges();
  }

  private getRatio(value: number, origin: number): number {
    return parseFloat((value / origin).toFixed(4));
  }

}
