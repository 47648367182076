import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestCloneConfig } from './model/http-request-clone-config';
import { CurrencyCode } from '@shared/currency/model/currency-code.model';
import { DomainService } from '@shared/platform/domain.service';
import { CurrencyUtil } from '@shared/currency/util/currency.util';
import { WITH_CREDENTIALS } from '@shared/rest/token/with-credentials-http-context.token';

@Injectable()
export class CurrencyPreferenceInterceptor implements HttpInterceptor {

  private readonly CURRENCY_HEADER_NAME: string = 'X-Accept-Currency';

  constructor(private readonly domainService: DomainService) {
  }

  public intercept(request: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.interceptWithCurrency(
      request,
      next,
      CurrencyUtil.getDefaultCurrencyByLocale(this.domainService.locale),
    );
  }

  private interceptWithCurrency(request: HttpRequest<string>, next: HttpHandler, currency: CurrencyCode): Observable<HttpEvent<unknown>> {

    const requestCloneConfig: HttpRequestCloneConfig = {
      withCredentials: request.context.has(WITH_CREDENTIALS) ? request.context.get(WITH_CREDENTIALS) : true,
    };

    requestCloneConfig.headers = request.headers.set(
      this.CURRENCY_HEADER_NAME, currency,
    );

    return next.handle(request.clone(requestCloneConfig));
  }

}
