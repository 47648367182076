import { Component, ElementRef, HostBinding, Input, OnChanges } from '@angular/core';
import { ColorCombinationId } from '../../model/color-combination-id';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ColorUtil } from '../../util/color.util';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { COLOR_COMBINATIONS } from '../../constant/color-combinations';
import isNil from 'lodash-es/isNil';

@Component({
  template: '',
})
export abstract class ColorCombinationInputComponent extends NgUnsubscribe implements OnChanges {

  @Input() public colorCombination: ColorCombinationId;

  /**
   * In some cases color combination is not set, and we need to use inherit color combination
   */
  @HostBinding('class.has-color-combination')
  public get hasColorCombination(): boolean {
    return !isNil(this.colorCombination);
  }

  constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<ColorCombinationInputComponent>): void {
    if (changes.colorCombination) {
      ColorUtil.setAbstractCssVarsOnElement(COLOR_COMBINATIONS[this.colorCombination], this.elementRef.nativeElement);
    }
  }

}
