import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'auk-mobile-filters-show-more',
  templateUrl: './mobile-filters-show-more.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileFiltersShowMoreComponent {

  /**
   * Count of all items in page
   */
  @Input() public itemsCount: number = 0;

  /**
   * How many objects are shown initially
   */
  @Input() public countVisible: number = 2;

  @Input() public isToggled: boolean = false;

  /**
   * Output toggle event
   */
  @Output() public toggleChange = new EventEmitter<boolean>();

  public onToggle(): void {
    this.isToggled = !this.isToggled;
    this.toggleChange.emit(this.isToggled);
  }

}
