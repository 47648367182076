import { ObjectWithItemId } from '@util/helper-types/object-with-item-id';
import { TrackByFunction } from '@angular/core';

/**
 * Default trackByFn to use in ng-for trackBy field for items with `itemId` field (if `itemId` is not set, index of the item is used)
 *
 * Generic type U - type of attributeId (number or string)
 * Generic type T - type of item
 * @param index
 * @param item
 */
export const trackByItemIdFn:
  TrackByFunction<ObjectWithItemId<string | number>> = <U extends string | number = string | number, T extends ObjectWithItemId<U> =
  ObjectWithItemId<U>>(index: number, item: T) => item?.itemId ?? index;
