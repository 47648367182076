import { Injectable } from '@angular/core';
import { CookieService } from '@common/cookie/service/cookie.service';
import moment from 'moment-mini-ts';
import { BannerPlacementDto } from '@api/generated/defs/BannerPlacementDto';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { take, takeUntil } from 'rxjs/operators';
import { PlatformService } from '@shared/platform/service/platform.service';
import isNil from 'lodash-es/isNil';
import { BannerConstants } from '@shared/banner/domain/banner.constants';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Injectable({
  providedIn: 'root',
})
export class BannerCookieService extends NgUnsubscribe {

  constructor(
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly platformService: PlatformService,
    private readonly cookieService: CookieService,
  ) {
    super();
  }

  /**
   * Manage cookies related to banners.
   * When a banner is shown, that particular banner shouldn't show up again within certain time interval (e.g. one year)
   * and banner of the same category (e.g. popup banner or top-line banner) shouldn't show up within other time interval (e.g. 24 hours).
   * @param suppressionIntervalCEKey Key of CE determining how long should the banners of certain type be suppressed
   * @param suppressedCookieKey      Cookie key for global suppression of banners of given type (e.g. popup banners or top-line banners)
   * @param shownIdsCookieKey        Cookie key for particular banners
   * @param bannerPlacement          Banner placement from which the deployment ID to be suppressed is taken
   */
  public initBannerCookies(
    suppressionIntervalCEKey: string,
    suppressedCookieKey: string,
    shownIdsCookieKey: string,
    bannerPlacement: BannerPlacementDto,
  ): void {
    if (!this.platformService.isBrowser) {
      return;
    }

    this.configuratorCacheService.getFeSystemParam<number>(suppressionIntervalCEKey, 'NUMBER')
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (value) => {
          this.suppressBanners(suppressedCookieKey, value || BannerConstants.BANNER_DEFAULT_SUPPRESS_TIME);
          this.updateBannerDeploymentIdsInCookie(shownIdsCookieKey, bannerPlacement);
        },
      });
  }

  public updateBannerDeploymentIdsInCookie(shownIdsCookieKey: string, bannerPlacement: BannerPlacementDto): void {
    const currentlyShownDeploymentId = bannerPlacement?.bannerPositions?.[0]?.bannerDeployments?.[0]?.deploymentId;

    this.addCurrentBannerDeploymentIdToCookie(currentlyShownDeploymentId, shownIdsCookieKey);
    this.cleanOldBannerDeploymentIdsFromCookie(shownIdsCookieKey);
  }

  /**
   * Supress banners marked with given cookie key for given time period. For that time period no banner of given cookie key will show up.
   * @param cookieKey    Cookie key determining the type of banner, e.g. web-popup banner or top-line banner
   * @param suppressTime Time period in hours for how long banners shouldn't show up
   */
  private suppressBanners(cookieKey: string, suppressTime: number): void {
    this.cookieService.put(cookieKey, 'true', { expires: moment().add(suppressTime, 'hours').toDate() });
  }

  /**
   * Save currently shown banner (its deployment ID) to cookie, so it's not shown again to the same user.
   * @param currentlyShownDeploymentId Banner deplyment ID to be added to cookies and suppressed
   * @param cookieKey                  Cookie key determining the type of banner, e.g. web-popup banner or top-line banner
   */
  private addCurrentBannerDeploymentIdToCookie(currentlyShownDeploymentId: number, cookieKey: string): void {
    if (isNil(currentlyShownDeploymentId)) {
      return;
    }

    let shownBannerDeploymentIds = this.cookieService.get(cookieKey);
    if (shownBannerDeploymentIds) {
      shownBannerDeploymentIds += ',' + currentlyShownDeploymentId.toString();
    } else {
      shownBannerDeploymentIds = currentlyShownDeploymentId.toString();
    }

    this.cookieService.put(cookieKey, shownBannerDeploymentIds, { expires: moment().add(365, 'days').toDate() });
  }

  /**
   * Remove banners (their deployment IDs) from cookie if they're older than one year or if there's more than 50 of them in the cookie.
   * @param cookieKey Cookie key determining the type of banner, e.g. web-popup banner or top-line banner
   */
  private cleanOldBannerDeploymentIdsFromCookie(cookieKey: string): void {
    const shownBannerDeploymentIdsCookie = this.cookieService.get(cookieKey);
    if (isNil(shownBannerDeploymentIdsCookie)) {
      return;
    }

    const shownBannerDeploymentIds = shownBannerDeploymentIdsCookie.split(',');
    if (shownBannerDeploymentIds?.length > BannerConstants.MAX_AMOUNT_OF_SHOWN_BANNER_DEPLOYMENT_IDS) {
      shownBannerDeploymentIds.splice(0, shownBannerDeploymentIds.length - BannerConstants.MAX_AMOUNT_OF_SHOWN_BANNER_DEPLOYMENT_IDS);
    }

    this.cookieService.put(cookieKey, shownBannerDeploymentIds.join(','), { expires: moment().add(365, 'days').toDate() });
  }

  /**
   * Parse banner deployment IDs (stored as a string with comma separated values) from cookie to array of numbers.
   * @param cookieKey Cookie key determining the type of banner, e.g. web-popup banner or top-line banner
   */
  public parseShownBannerDeploymentIds(cookieKey: string): number[] {
    return this.cookieService.get(cookieKey)?.split(',').map(id => Number(id)) ?? [];
  }

}
