@if (bannerPlacement) {
  <auk-top-line-banner
    class="tw-relative tw-overflow-hidden"
    [bannerPlacement]="bannerPlacement"
    [class.tw-p-0]="isLgAndLower"
  />
}

<!-- HEADER LOGO AND BUTTONS  -->
@if (!isMdAndLower) {
  <auk-app-header-menu [dummySearchInput]="dummySearchInput"/>
}
<!-- ./HEADER LOGO AND BUTTONS  -->

<!-- GO BACK BUTTON -->
<auk-go-back-button class="tw-fixed tw-z-20 tw-block tw-overflow-visible"
                    [class.tw-invisible]="!showGoBackButton"
                    [isGlobalButton]="true"
/>
<!-- ./GO BACK BUTTON -->

<!-- HEADER NAVBAR V2 -->
@if (showSearch) {
  <div class="tw-max-h-28 tw-max-w-full tw-overflow-y-hidden tw-bg-contrast tw-relative">
    @if (isMdAndLower) {
      <auk-app-header-search-input
        type="dummy"
        (inputClick)="openDropdown()"
        (searchRef)="setDummySearchInput($event)"
      />
    }
    <auk-horizontal-menu-navbar
      [itemData]="horizontalMenuSubbrand"
    />
  </div>
  <div class="tw-border-b-silverstein-200 tw-border-b tw-border-solid"></div>
}
<!-- ./HEADER NAVBAR V2 -->

<!-- Hidden prerendered hamburger dialog for faster opening up -->
<auk-category-dialog-modal [ngClass]="{'tw-invisible' : (categoryMenuOpened$() | async) === false}"/>