import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList,
} from '@angular/core';
import { AccordionItemComponent } from '../accordion-item/accordion-item.component';
import { takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { getTrackByTranslationSourceFn } from '@util/helper-functions/get-track-by-translation-source-fn';

@Component({
  selector: 'auk-accordion-new',
  templateUrl: './accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent extends NgUnsubscribe implements AfterContentInit, AfterContentChecked {

  public trackByTitleFn = getTrackByTranslationSourceFn('titleText');

  constructor(private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  @ContentChildren(AccordionItemComponent)
  public accordionItems: QueryList<AccordionItemComponent>;

  @Output()
  public accordionItemSelect: EventEmitter<AccordionItemComponent> = new EventEmitter<AccordionItemComponent>();

  public selectTab(accordionItem: AccordionItemComponent): void {
    this.accordionItems.toArray().forEach(tabComponent => (tabComponent.isActive = false));
    if (accordionItem) {
      accordionItem.isActive = true;
      this.accordionItemSelect.emit( accordionItem );
    }
    this.changeDetectorRef.detectChanges();
  }

  public ngAfterContentInit(): void {
    // here because of OnPush strategy and use of ContentChildren
    // QueryList notification on changes fired if, and only if a query list size (length) changes
    this.accordionItems.changes.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.changeDetectorRef.detectChanges();
      });
  }

  // detects changes in ContentChildren properties change
  public ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

}
