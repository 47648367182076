import isNil from 'lodash-es/isNil';
import { SubbrandType, SubbrandTypeArr } from '@shared/subbrand/model/subbrand.type';
import { WithSubbrandData } from '@shared/subbrand/util/with-subbrand.data';
import { TranslationKey } from '@common/translations/model/translation-key';
import { Nil } from '@util/helper-types/nil';
import { DomainCode } from '@shared/platform/domain.model';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { captureException } from '@sentry/browser';

export class SubbrandUtil {

  private static SUBBRAND_TO_SUBBRAND_TITLE_TRANSLATION_KEY: Record<SubbrandType, TranslationKey> = {
    BAZAAR: 'SUBBRAND_BAZAAR',
    ANTIQUE: 'SUBBRAND_ANTIQUE',
    NOT_SPECIFIED: 'SUBBRAND_NOT_SPECIFIED',
  };

  private static SUBBRAND_SWITCH_SVG_ICON: Record<SubbrandType, string> = {
    BAZAAR: 'antique-bazaar-link',
    ANTIQUE: 'color-logo',
    NOT_SPECIFIED: 'color-logo',
  };

  private static SUBBRAND_LOGO_SVG_ICON: Record<SubbrandType, Record<DomainCode, string>> = {
    // TODO: [PDEV-25853] - check/refactor
    // @ts-ignore
    BAZAAR: { CZ: 'logo', SK: 'logo' },
    // TODO: [PDEV-25853] - check/refactor
    // @ts-ignore
    ANTIQUE: { CZ: 'logo-antique', SK: 'logo-antique-sk' },
    // TODO: [PDEV-25853] - check/refactor
    // @ts-ignore
    NOT_SPECIFIED: { CZ: 'logo', SK: 'logo' },
  };

  /**
   * Returns variant of data for given subbrand, returns base variant for given subbrand is not present.
   * @param subbrandData Data with base and subbrand variant
   * @param subbrand Subbrand for which we want variant of data
   */
  public static getBySubbrand<T>(subbrandData: WithSubbrandData<T>, subbrand: SubbrandType): T | null {

    const logException = (msg: string): void => {
      console.error(msg);
      captureException(new Error(msg));
    };

    if (isNil(subbrandData)) {
      logException('Cannot get data for subbrand as data is not defined');
      return null;
    }

    if (isNil(subbrandData.dataBySubbrand)) {
      logException(`Cannot get data for subbrand as no subbrand data exists`);
      return null;
    }

    const notSpecifiedData = subbrandData.dataBySubbrand['NOT_SPECIFIED'];

    if (isNil(subbrand) && isNil(notSpecifiedData)) {
      logException(`Cannot get data for null subbrand and NOT_SPECIFIED variant do not exists`);
      return null;
    }

    if (isNil(subbrand) && isNotNil(notSpecifiedData)) {
      return notSpecifiedData;
    }

    const subbrandVariantData = subbrandData.dataBySubbrand[subbrand];

    if (isNil(subbrandVariantData) && isNil(notSpecifiedData)) {
      logException(`Cannot get data for subbrand as no data exists for '${ subbrand }' and NOT_SPECIFIED variant do not exists'`);
      return null;
    }

    if (isNil(subbrandVariantData) && isNotNil(notSpecifiedData)) {
      logException(`Cannot get data for subbrand as no data exists for '${ subbrand }', returning NOT_SPECIFIED variant'`);
      return notSpecifiedData;
    }

    return subbrandVariantData;
  }

  public static getTitleTranslationKeyBy(subbrand: SubbrandType): TranslationKey {
    return this.SUBBRAND_TO_SUBBRAND_TITLE_TRANSLATION_KEY[subbrand] || 'SUBBRAND_NOT_SPECIFIED';
  }

  public static getSubbrandsExceptNotSpecified(): SubbrandType[] {
    return SubbrandTypeArr.filter(s => s !== 'NOT_SPECIFIED');
  }

  public static getSwitchIcon(subbrand: SubbrandType | Nil): string {
    if (isNil(subbrand)) {
      return 'color-logo';
    }
    return this.SUBBRAND_SWITCH_SVG_ICON[subbrand] || 'color-logo';
  }

  public static getLogoIcon(subbrand: SubbrandType | Nil, domain: DomainCode = 'CZ'): string {
    if (isNil(subbrand)) {
      return 'logo';
    }

    return this.SUBBRAND_LOGO_SVG_ICON[subbrand][domain] || 'logo';
  }

}
