import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslateCompiler, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { TranslateSourcePipeService } from '@common/translations/service/translate-source-pipe.service';
import { TranslationSource } from '@common/translations/model/translation-source';
import { Nil } from '@util/helper-types/nil';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { TermsInfoModel } from '../model/terms-info.model';
import { BasePipe } from '@util/base-class/base-pipe.class';
import { takeUntil, tap } from 'rxjs/operators';
import isNil from 'lodash-es/isNil';

@Pipe({
  name: 'aukTranslateSourceTerms',
  pure: false,
})
export class TranslateSourceTermsPipe extends BasePipe implements PipeTransform {

  private readonly ngxTranslatePipe: TranslatePipe;

  private transformedValue: string | Nil = '';
  private translationSourceValue: TranslationSource;
  private termsInfo: TermsInfoModel | Nil;

  constructor(
    private readonly translateService: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translateCompiler: TranslateCompiler,
    private readonly translateSourcePipeService: TranslateSourcePipeService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
  ) {
    super();
    // create ngx translate pipe
    this.ngxTranslatePipe = new TranslatePipe(this.translateService, this.changeDetectorRef);

    this.configuratorCacheService.getFeSystemParam<TermsInfoModel>('TERMS_INFO', 'JSON')
      .pipe(
        tap((termsInfo) => {
          this.termsInfo = termsInfo;

          this.transformValues();
          this.changeDetectorRef.markForCheck();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public transform(value: TranslationSource): string | Nil {
    if (isNil(value)) {
      return '';
    }

    if (this.transformedValue !== value) {
      this.translationSourceValue = value;

      this.transformValues();
    }

    return this.transformedValue;
  }

  private transformValues(): void {
    if (isNil(this.translationSourceValue)) {
      this.transformedValue = '';
      return;
    }

    this.transformedValue = this.translateSourcePipeService.transform(
      {
        ...this.translationSourceValue,
        params: {
          ...this.translationSourceValue.params,
          termsLinkPdf: this.termsInfo?.termsLinkPdf,
          termsLink: this.termsInfo?.termsLink,
          termsLastChangedDate: this.termsInfo?.termsLastChangedDate,
        },
      },
      this.translateService,
      this.translateCompiler,
      this.ngxTranslatePipe,
    );
  }

}
