<div class="tw-flex tw-h-12 tw-items-center tw-gap-4 tw-px-4 tw-py-2">
  <auk-icon iconType="SVG" source="bazaar-placeholder" size="MD" class="tw-mr-2" colorCombination="PRIMARY_CONTRAST"></auk-icon>
  <div class="tw-text-contrast-opposite tw-text-md tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-w-full">
    {{ landingPage.name }}
  </div>
  <div
    class="tw-flex tw-h-5 tw-min-w-[25px] tw-justify-center tw-items-center tw-text-silverstein-400 text-ns tw-font-medium tw-px-1.5 tw-py-0 tw-rounded tw-bg-surface-neutral-2"
  >
    {{ landingPage.count }}
  </div>
</div>
