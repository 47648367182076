import { Injectable } from '@angular/core';

import { StringUtils } from '@util/util/string.utils';
import { LoginVM } from '@api/aukro-api/model/login-vm';
import { PersonDto } from '@api/aukro-api/model/person-dto';
import { Nil } from '@util/helper-types/nil';
import { SocialLogin } from '@capgo/capacitor-social-login';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

const facebookKeys = {
  production: {
    appId: '1943181299261447',
    clientToken: '4426de22d356d3169143f9c9848f2251',
  },
  test: {
    appId: '815644725251536',
    clientToken: 'ff2c03b035eef85360155d2301f19c91',
  },
};

@Injectable({
  providedIn: 'root',
})
export class FacebookNativeSDKService {

  public async loginViaFBNative(): Promise<{ loginVM: LoginVM; registrationPersonDto: PersonDto } | Nil> {
    await SocialLogin.initialize({
      facebook: PlatformCommonService.isProductionMode ? facebookKeys.production : facebookKeys.test,
    });

    const response = await SocialLogin.login({
      provider: 'facebook',
      options: {
        limitedLogin: true,
        permissions: ['email', 'public_profile'],
      },
    });

    if (response.result.accessToken.userId) {
      const responseData = response.result;

      if (StringUtils.isBlank(responseData.profile.email)) {
        return null;
      }

      const fullName = responseData.profile.name || ''; // Default to an empty string to avoid errors
      const [firstName, ...lastNameParts] = fullName.split(' '); // Split by space
      const lastName = lastNameParts.join(' ');

      const loginVM: LoginVM = {
        username: responseData.profile.email,
        extUserId: responseData.accessToken.userId as any, // facebook user id could be bigger than Number.MAX_VALUE
        registrationChannelEnum: 'FACEBOOK',
        authToken: responseData.accessToken.token,
      };

      const registrationPersonDto: PersonDto = {
        name: firstName,
        surname: lastName,
      };

      return { loginVM, registrationPersonDto };
    } else {
      return null;
    }
  }

}
