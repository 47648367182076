<div class="tw-flex tw-gap-4">

  @if (tooltipModel.icon) {
    <auk-icon
      [iconType]="tooltipModel.icon.type"
      [source]="tooltipModel.icon.source"
      size="LG"
      [colorCombination]="tooltipModel.icon.colorCombination"
      class="tw-pt-0.5"
      />
  }

  <div class="tw-flex tw-flex-col tw-items-start tw-gap-2">

    @if (tooltipModel.heading) {
      <div class="tw-font-medium">
        {{ tooltipModel.heading | translateSource }}
      </div>
    }

    <div [ngClass]="tooltipModel.size === 'SM' ? 'tw-text-xs' : 'tw-text-sm'">
      {{ tooltipModel.text | translateSource }}
    </div>

    @if (tooltipModel.contentTemplate) {
      <div class="tw-flex tw-gap-2 tw-mt-2">
        <ng-template [ngTemplateOutlet]="tooltipModel.contentTemplate" />
      </div>
    }

  </div>
</div>
