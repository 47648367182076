import { Pipe, PipeTransform } from '@angular/core';
import { Nil } from '@util/helper-types/nil';

@Pipe({
  name: 'nilToEmptyString',
  standalone: true,
})
export class NilToEmptyStringPipe implements PipeTransform {

  public transform(value: string | Nil, fallback?: string): string {
    return value ?? fallback ?? '';
  }

}
