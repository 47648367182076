import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import isUndefined from 'lodash-es/isUndefined';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { DropdownContainerDirective } from './dropdown-container.directive';
import { DropdownFilterButtonDirective } from './dropdown-filter-button.directive';
import { WINDOW_OBJECT } from '@util/const/window-object';
import isNil from 'lodash-es/isNil';
import { LayoutService } from '@common/layout/layout.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Directive({
  selector: '[aukDropdown]',
  exportAs: 'dropdownExport',
})
export class DropdownDirective extends NgUnsubscribe implements AfterViewInit, OnDestroy {

  @Input() public autoClose: boolean = true;
  @Input() public closeFromOutside: boolean = true;
  @Input() public closeOnTouchOutside: boolean = false;
  @Input() public disableClickToggle: boolean = false;
  @Input() public openWidth: number;
  @Input() public disableBackgroundScroll: boolean = false;

  @Output() public closeDropdown: EventEmitter<void> = new EventEmitter<void>();
  @Output() public toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.dropdown-active') @Input() public isOpen: boolean = false;

  public container: DropdownContainerDirective;
  public filterButton: DropdownFilterButtonDirective;
  public dropdownElement: HTMLElement;
  public toggleElement: HTMLElement;
  public containerElement: HTMLElement;
  private isFocused: boolean = false;

  constructor(
    @Inject(WINDOW_OBJECT) protected readonly window: Window,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly layoutService: LayoutService,
    protected readonly elementRef: ElementRef<HTMLElement>,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    this.dropdownElement = this.elementRef.nativeElement;

    if (!isUndefined(this.openWidth)) {
      // toggle dropdown depending on window width & 'openWidth' property
      this.ngZoneUtilService.fromEventOut$(this.window, 'resize')
        .pipe(
          map(() => this.window.innerWidth >= this.openWidth),
          distinctUntilChanged(),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((state: boolean) => {
          this.toggle(state);
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  public override ngOnDestroy(): void {
    // Enable scrolling again only if dropdown is opened (to prevent enabling scrolling if disabled from some other place)
    if (this.disableBackgroundScroll && this.isOpen) {
      this.layoutService.updateIsAppScrollable(true, true);
    }
    super.ngOnDestroy();
  }

  public toggle(isOpen: boolean = !this.isOpen): void {
    // Don't toggle to the same state
    if (this.isOpen === isOpen) {
      return;
    }

    this.isOpen = isOpen;
    this.container.display = this.isOpen ?
      'block' :
      'none';

    if (!this.isOpen) {
      this.closeDropdown.emit();
      if (this.disableBackgroundScroll) {
        this.layoutService.updateIsAppScrollable(true, true);
      }
    }

    if (this.isOpen && this.disableBackgroundScroll) {
      this.layoutService.updateIsAppScrollable(false, true);
    }

    this.changeDetectorRef.detectChanges();

    this.toggleChange.emit(this.isOpen);
  }

  public open(): void {
    this.toggle(true);
  }

  public close(): void {
    this.toggle(false);
  }

  /**
   * In case there's no separate DropdownToggle,
   * DropdownDirective should behave like DropdownToggle
   * and handle user clicks
   * @param e
   */
  @HostListener('click', ['$event'])
  public onclick(e: MouseEvent): void {
    if (this.disableClickToggle) {
      return;
    }

    if (this.toggleElement || this.isEventFromElement(this.containerElement, e) || this.isFocused) {
      this.isFocused = false;
      return;
    }

    e.stopPropagation();
    if (!this.isOpen || (this.isOpen && this.autoClose)) {
      this.toggle();
    }
  }

  /**
   * Close dropdown on click form outside of DropdownDirective
   * @param e
   */
  @HostListener('document:click.silent', ['$event'])
  public onDocumentClick(e: MouseEvent): void {
    if (this.closeFromOutside && !this.isEventFromElement(this.dropdownElement, e)) {
      this.close();
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Closes dropdown on outside element touch (e.g. on swipe start)
   * @param touchEvent DOM event
   */
  @HostListener('window:touchstart.silent', ['$event'])
  public onTouchStart(touchEvent: TouchEvent): void {
    if (this.closeOnTouchOutside && !this.isEventFromElement(this.dropdownElement, touchEvent)) {
      this.close();
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Open dropdown on focus event
   */
  @HostListener('focus', ['$event'])
  public onFocus(): void {
    this.isFocused = true;
    this.open();
  }

  /**
   * Close dropdown on blur event
   */
  @HostListener('blur', ['$event'])
  public onBlur(): void {
    this.isFocused = false;
    this.close();
  }

  private isEventFromElement(el: HTMLElement, e: Event): boolean {
    if (isNil(el)) {
      return false;
    }
    return el.contains(e.target as HTMLElement);
  }

}
