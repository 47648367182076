import { Injectable } from '@angular/core';
import { AuthCacheService } from '@shared/authentication/service/auth-cache.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseWebsocketTopicService } from '@shared/websocket/service/base-websocket-topic.service';
import { WsTopicPropertiesModel } from '@shared/websocket/model/ws-topic-properties.model';
import { WebsocketsService } from '@shared/websocket/service/websockets.service';
import { WebSocketItemDetailDto } from '@api/generated/defs/WebSocketItemDetailDto';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { FavouritePopoverAsyncUpdateHelperService } from '@shared/favourite/service/favourite-popover-async-update-helper.service';
import { WS_TOPIC_PREFIX_AUCTION } from '@shared/item/const/ws-topic-prefix-auction.const';
import { Nil } from '@util/helper-types/nil';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { WsDestinationModel } from '@shared/websocket/model/ws-destination.model';
import { FavouritePopoverWsAdditionalDataModel } from '@shared/favourite/model/favourite-popover-ws-additional-data.model';

@Injectable({
  providedIn: 'root',
})
export class FavouritePopoverWsService extends BaseWebsocketTopicService<WebSocketItemDetailDto[], FavouritePopoverWsAdditionalDataModel> {

  protected readonly wsProperties: WsTopicPropertiesModel = {
    authorized: false,
  };

  protected override readonly isMultiple: boolean = true;

  constructor(
    authCacheService: AuthCacheService,
    websocketsService: WebsocketsService<WebSocketItemDetailDto[]>,
    platformCommonService: PlatformCommonService,
    private readonly favouritePopoverAsyncUpdateHelperService: FavouritePopoverAsyncUpdateHelperService,
  ) {
    super(authCacheService, websocketsService, platformCommonService);
  }

  protected wsDestinations$(): Observable<WsDestinationModel<FavouritePopoverWsAdditionalDataModel>[] | Nil> {
    return this.favouritePopoverAsyncUpdateHelperService.itemsIdsReadyForAsyncUpdate$
      .pipe(
        map((itemIds) => itemIds?.filter((itemId) => isNotNil(itemId))),
        map((itemIds) =>
          itemIds?.map((itemId) => ({
            destination: `${ WS_TOPIC_PREFIX_AUCTION }.${ itemId }`,
            additionalData: {
              itemId,
            },
          })),
        ),
      );
  }

}
