import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpError } from './model/http-error';
import { HttpErrorMessage } from './model/http-error-message';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(req)
      .pipe(
        catchError((err: HttpErrorResponse) => throwError(() => this.transformError(req, err)),
        ),
      );
  }

  private transformError(req: HttpRequest<unknown>, err: HttpErrorResponse): HttpError {
    const body: HttpErrorMessage = typeof err.error === 'object' ? err.error : {
      message: err.error || err.message,
    };

    body?.errors?.map((obj) => {
      obj.defaultMessage = obj.code;
      obj.codes = [obj.code];
    });

    return {
      url: err.url,
      code: err.status,
      body,
      req,
      prerequisites: body?.prerequisites,
    };
  }

}
