import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserTaskVerifiedBankAccountStepComponent } from './user-task-verified-bank-account-step.component';
import { finalize, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { captureException, withScope } from '@sentry/browser';
import { UserService } from '@shared/user/service/user.service';
import { HttpError } from '@shared/rest/model/http-error';
import { HttpContext } from '@angular/common/http';
import { DomainService } from '@shared/platform/domain.service';
import { ICULocale } from '@shared/platform/icu-locale.model';
import { LocaleConstants } from '@shared/platform/locale.constants';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';
import { FileFormatUtil } from '@util/util/file-format/domain/file-format.util';
import { StringUtils } from '@util/util/string.utils';
import { ImagesApiService } from '@api/aukro-api/api/images-api.service';
import { MoneyDto } from '@api/aukro-api/model/money-dto';
import { UserInterestStatisticsDto, UserInterestStatisticsDtoAccountTypeEnumEnum } from '@api/aukro-api/model/user-interest-statistics-dto';
import { AccountDetailDto } from '@api/aukro-api/model/account-detail-dto';
import { VerificationPaymentDataForBankTransferDto } from '@api/aukro-api/model/verification-payment-data-for-bank-transfer-dto';
import { PaymentApiService } from '@api/aukro-api/api/payment-api.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';

@Component({
  selector: 'auk-user-task-verified-bank-account-step-no-account-verified',
  templateUrl: 'user-task-verified-bank-account-step-no-account-verified.component.html',
  styleUrls: ['./user-task-verified-bank-account-step-no-account-verified.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskVerifiedBankAccountStepNoAccountVerifiedComponent
  extends UserTaskVerifiedBankAccountStepComponent<'NO_ACCOUNT_VERIFIED'> implements OnInit {

  private readonly MINIMAL_BANK_TRANSFER_AMOUNT_DOMAIN_MAP: Map<ICULocale, MoneyDto> = new Map([
    [LocaleConstants.CZ_LOCALE, { amount: 3, currency: 'CZK' }],
    [LocaleConstants.SK_LOCALE, { amount: 0.1, currency: 'EUR' }],
  ]);
  public MINIMAL_BANK_TRANSFER_AMOUNT: MoneyDto = this.MINIMAL_BANK_TRANSFER_AMOUNT_DOMAIN_MAP.get(LocaleConstants.CZ_LOCALE);

  public actualStatistics: UserInterestStatisticsDto;
  public userDetail: AccountDetailDto;
  public bankTransferData: VerificationPaymentDataForBankTransferDto;
  public loading: boolean = false;
  public qrPaymentData: string;
  public isDesktop: boolean;

  constructor(
    private readonly userService: UserService,
    private readonly paymentApiService: PaymentApiService,
    private readonly imagesApiService: ImagesApiService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly domainService: DomainService,
    private readonly responsivenessService: ResponsivenessService,
  ) {
    super();
    this.MINIMAL_BANK_TRANSFER_AMOUNT = this.MINIMAL_BANK_TRANSFER_AMOUNT_DOMAIN_MAP.get(this.domainService.locale);
  }

  public ngOnInit(): void {
    this.loadData();
    this.initPlatformListener();
  }

  protected get showMaPaymentVerificationPrelinkInfo(): boolean {
    return !StringUtils.isEmpty(this.payload.maPaymentVerificationPrelinkInfo.defaultValue);
  }

  protected get isUserAccountType(): boolean {
    return this.accountType === 'USER' && !this.isUserChangingToCompany;
  }

  protected get isCompanyAccountType(): boolean {
    return this.accountType === 'COMPANY' || this.isUserChangingToCompany;
  }

  private get isUserChangingToCompany(): boolean {
    return this.userDetail?.stateDto?.changingToCompany;
  }

  private get accountType(): UserInterestStatisticsDtoAccountTypeEnumEnum {
    return this.actualStatistics?.accountTypeEnum;
  }

  private initPlatformListener(): void {
    this.responsivenessService.isMdLgCombination$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([isMdAndLower, isLgAndLower]) => {
        this.isDesktop = !isMdAndLower && !isLgAndLower;
        this.changeDetectorRef.markForCheck();
      });
  }

  private loadData(): void {
    this.loading = true;

    this.userService.getActualStatistics()
      .pipe(
        tap((actualStatistics: UserInterestStatisticsDto) => this.actualStatistics = actualStatistics),
        mergeMap(() => this.userService.detail(this.actualStatistics?.userId)),
        tap((userDetail: AccountDetailDto) => this.userDetail = userDetail),
        tap(() => captureException(
          'opening BAVD for user ' + String(this.actualStatistics.userId) +
          ' (roles: ' + String(this.actualStatistics.roles) + ') from ' + this.payload.source)),
        mergeMap(() => this.paymentApiService.newBankTransferVerificationPayment$(
          { paysBillingPaymentDto: { amount: this.MINIMAL_BANK_TRANSFER_AMOUNT } },
          {},
          new HttpContext().set(USER_ACTION_TYPE, 'VERIFY_THROUGH_PAYMENT'),
        )),
        mergeMap(() => this.paymentApiService.verificationPaymentDataForBankTransfer$()),
        tap((bankTransferData: VerificationPaymentDataForBankTransferDto) => this.bankTransferData = bankTransferData),
        mergeMap((bankTransferData: VerificationPaymentDataForBankTransferDto) => this.imagesApiService.generateQRCode$({
          generateQRCodeDto: {
            amount: this.MINIMAL_BANK_TRANSFER_AMOUNT,
            bankAccountNumber: bankTransferData.bankAccount,
            variableSymbol: bankTransferData.variableSymbol,
            dimension: 200,
            // TODO: [PDEV-25853] - check/refactor
            // @ts-ignore
            transactionDomain: this.domainService.domain,
          },
        })),
        take(1),
        finalize(() => {
          this.loading = false;
          this.changeDetectorRef.detectChanges();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (qrCodeData: string) => {
          this.qrPaymentData = FileFormatUtil.withPngBase64Prefix(qrCodeData);
        },
        error: (error: HttpError) => {
          withScope((scope) => {
            scope.setExtra('extra', error);
            captureException(
              new Error(
                'Error ' + error?.code?.toString() + ' - loading data for bank account verification failed'));
          });
        },
      });
  }

}
