<!-- AUKRO BANNER -->
@if (group === 'AUKRO' && aukroBanner) {
  @if (title) {
    <span class="page-component-title tw-text-2xl tw-font-medium"
          [ngClass]="{
            'tw-text-sm md:tw-text-md': titleSize === 'SMALL',
            'tw-text-2xl': titleSize === 'DEFAULT'
          }"
    >
      <span [aukInnerHtml]="title"></span>
    </span>
  }

  <div class="banner-slider"
       (mouseenter)="autoMoveAllowed(false)"
       (mouseleave)="autoMoveAllowed(true)"
       (touchstart)="swipe($event, 'start')"
       (touchend)="swipe($event, 'end')"
       [style.transform]="transformStyle"
  >
    @for (bannerPosition of aukroBanner.bannerPositions; let index = $index; track index) {
      @if (!onlyFirstImage || index < 1) {
        <a [aukLinkFromHref]="$any(bannerPosition).bannerDeployments[0].destinationUrl"
           [openInNewTab]="$any(bannerPosition).bannerDeployments[0].openInNewTab"
           (click)="onBannerClick(bannerPosition, $any(bannerPosition).bannerDeployments[0])"
           [class.img-prev]="index < selectedIndex"
           [class.img-next]="index > selectedIndex"
        >
          <img [aukReplaceByPlaceholderOnError]="$any(bannerPosition).bannerDeployments[0].imgUrl"
               [attr.loading]="lazyLoadImages ? 'lazy' : 'eager'"
               [attr.height]="aukroBanner.height"
               [attr.width]="aukroBanner.width"
               [dummyImagePlaceholder]="getDummyImagePlaceholder(bannerPosition, aukroBanner.width, aukroBanner.height)"
               [attr.alt]="$any(bannerPosition).bannerDeployments[0].promoName"
          >
        </a>
      }
    }
  </div>

  @if (isPrevAllowed) {
    <div class="nav-prev tw-left-4">
      <auk-navigation-icon-button (buttonClick)="move('prev', true)"/>
    </div>
  }

  @if (isNextAllowed) {
    <div class="nav-next tw-right-4">
      <auk-navigation-icon-button (buttonClick)="move('next', true)" direction="RIGHT"/>
    </div>
  }
}
<!-- ./AUKRO BANNER -->

<!-- THIRD PARTY BANNER -->
@if (group === 'THIRD_PARTY') {
  <auk-third-party-banner [adElementId]="adElementId"/>
}
<!-- ./THIRD PARTY BANNER -->
