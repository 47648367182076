import { Injectable } from '@angular/core';
import find from 'lodash-es/find';
import reject from 'lodash-es/reject';
import { CookieService } from '@common/cookie/service/cookie.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ItemSearchPagedResources } from '@api/generated/defs/ItemSearchPagedResources';
import { ItemAttributeFilter, ItemSearchAggregation, ItemSearchFilter, MoneyDto } from '@api/generated/model';
import { SortDirection, SortSearchParams } from '../../../../typings/original/deprecated';
import { City, ItemSearchFilterExtended, ItemSearchFilterTag } from '../../../../typings/original/internal';
import { ListingUserRelatedDataModel } from '../model/listing-user-related-data.model';

import { ListingPageParamsModel } from '../model/listing-page-params.model';
import { ListingTypeService } from './listing-type.service';
import { ListingConstants } from '../const/listing.constants';
import { SavedFiltersService } from '@shared/listing/service/savedFilters.service';
import { TranslateService } from '@ngx-translate/core';
import { DestinationCountryUtils } from '@shared/util/destination-country-utils';
import { UserCurrencyPreferenceService } from '@shared/currency/service/user-currency-preference.service';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';

@Injectable({
  providedIn: 'root',
})
export class ListingService {

  /**
   * Use for fire search endpoint call, because mobile filters change does not fire endpoint call
   */
  public submitMobileFilters$: Subject<void> = new Subject<void>();

  public resetMobileFilters$: Subject<void> = new Subject<void>();

  public searchDirectUrl$: Subject<void> = new Subject<void>();

  private searchResponse$: BehaviorSubject<ItemSearchPagedResources> = new BehaviorSubject<ItemSearchPagedResources>(null);

  private _userRelatedData$: BehaviorSubject<ListingUserRelatedDataModel> = new BehaviorSubject<ListingUserRelatedDataModel>(null);

  private searchFilter$: BehaviorSubject<ItemSearchFilterExtended> = new BehaviorSubject<ItemSearchFilterExtended>(null);

  private searchErrors$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

  private pageParams$: BehaviorSubject<ListingPageParamsModel> = new BehaviorSubject<ListingPageParamsModel>(null);

  private activeSort$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  private searchParamsTags$: BehaviorSubject<ItemSearchAggregation[]> = new BehaviorSubject<ItemSearchAggregation[]>(null);

  private mobileFiltersOpened$: Subject<boolean> = new Subject<boolean>();

  private searchAll$: Subject<boolean> = new Subject<boolean>();

  private isAttributeFilterSetFromNiceUrl$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly listingTypeService: ListingTypeService,
    private readonly cookieService: CookieService,
    private readonly savedFiltersService: SavedFiltersService,
    private readonly userCurrencyPreferenceService: UserCurrencyPreferenceService,
    private readonly translateService: TranslateService,
    private readonly configuratorCacheService: ConfiguratorCacheService) {
  }

  public setMobileFiltersOpened(value: boolean): void {
    this.mobileFiltersOpened$.next(value);
  }

  public getMobileFiltersOpened$(): Observable<boolean> {
    return this.mobileFiltersOpened$.asObservable();
  }

  public setSearchResponse(searchResponse: ItemSearchPagedResources): void {
    this.searchResponse$.next(searchResponse);
  }

  public setUserRelatedData(userRelatedData: ListingUserRelatedDataModel): void {
    this._userRelatedData$.next(userRelatedData);
  }

  public getSearchResponse$(): Observable<ItemSearchPagedResources> {
    return this.searchResponse$;
  }

  public get userRelatedData$(): Observable<ListingUserRelatedDataModel> {
    return this._userRelatedData$.asObservable();
  }

  public setPageParams(pageParams: ListingPageParamsModel): void {
    this.pageParams$.next(pageParams);
  }

  public setActiveSort(sort: string[]): void {
    this.activeSort$.next(sort);
  }

  public getActiveSortValue(): string[] {
    return this.activeSort$.getValue();
  }

  public getPageParamsValue(): ListingPageParamsModel {
    return this.pageParams$.getValue();
  }

  public setSearchParamsTags(tags: ItemSearchAggregation[]): void {
    this.searchParamsTags$.next(tags);
  }

  public getSearchParamsTags$(): Observable<ItemSearchAggregation[]> {
    return this.searchParamsTags$.asObservable();
  }

  public setSearchFilter(searchParams: ItemSearchFilterExtended): void {
    this.searchFilter$.next(searchParams);
  }

  public getSearchFilter$(): Observable<ItemSearchFilterExtended> {
    return this.searchFilter$.asObservable();
  }

  public getSearchFilterValue(): ItemSearchFilterExtended {
    return this.searchFilter$.getValue();
  }

  public setSearchErrors(data: string[]): void {
    this.searchErrors$.next(data);
  }

  public getSearchErrors$(): Observable<string[]> {
    return this.searchErrors$.asObservable();
  }

  public setSearchAll(value: boolean): void {
    this.searchAll$.next(value);
  }

  public getSearchAll$(): Observable<boolean> {
    return this.searchAll$.asObservable();
  }

  public get isAttributeFilterSetFromNiceUrl(): boolean {
    return this.isAttributeFilterSetFromNiceUrl$.getValue();
  }

  public set isAttributeFilterSetFromNiceUrl(value: boolean) {
    this.isAttributeFilterSetFromNiceUrl$.next(value);
  }

  public parseAttributeSeoFilter(
    attributeSeoUrl: string,
    existingFilter: ItemAttributeFilter[],
    removeDuplicityFromSeoUrl: boolean = false,
  ): ItemAttributeFilter[] {
    const filterFromSeo = attributeSeoUrl.split('-').reverse();
    let attributeId: number;
    const attributeValue: string = filterFromSeo[1];
    let attributeValueNum: number;
    // retype to useful vars
    try {
      attributeId = parseInt(filterFromSeo[0], 10);
      attributeValueNum = parseInt(attributeValue, 10);
    } catch (e) {
      throw new Error(`Error parsing attributeSeoUrl: ${ e.content }`);
    }
    if (isNaN(attributeId) || isNaN(attributeValueNum)) {
      throw new Error('Error parsing attribute from seoUrl - id or value is not valid');
    }
    // removing duplicity attributes according to attributeSeoUrl
    if (removeDuplicityFromSeoUrl) {
      const filterFound: ItemAttributeFilter = find(existingFilter, (attr: ItemAttributeFilter) => attr.attributeId === attributeId);
      if (filterFound) {
        // remove filter if contains only one value
        if (filterFound.value.length === 1) {
          existingFilter = reject(existingFilter, { attributeId });
        } else if (filterFound.value.length > 1) {
          // remove redudant value which is in attributeSeoUrl
          existingFilter = existingFilter.map((attr: ItemAttributeFilter) => {
            if (attr.attributeId === attributeId) {
              const values = attr.value.filter((val: any) => val !== attributeValueNum);
              attr.value = values;
              return attr;
            }
            return attr;
          });
        }
      }
    } else {
      // append value into existing filter from attributeSeoUrl
      existingFilter.map((item: ItemAttributeFilter) => {
        if (item.attributeId === attributeId) {
          item.value.push(this.tryParse(attributeValue));
        }
      });
      // append filter from attributeSeourl - !! value will be string by DTO definition!!
      if (!find(existingFilter, (attr: ItemAttributeFilter) => attr.attributeId === attributeId)) {
        existingFilter.push({ attributeId, value: [this.tryParse(attributeValue)] });
      }
    }
    this.isAttributeFilterSetFromNiceUrl = true; // attribute filter is set based on nice url, e.g. /vysavace/roboticke-32-10347
    return existingFilter;
  }

  public removeFilterIncludedInSeoUrl(attributeSeoUrl: string, filters: ItemAttributeFilter[]): ItemAttributeFilter[] {
    return this.parseAttributeSeoFilter(attributeSeoUrl, filters, true);
  }

  public tryParse<R>(value: string): string | R {
    try {
      return JSON.parse(value) as R;
    } catch (e) {
      return value;
    }
  }

  public resetParams(): ItemSearchFilterExtended {
    this.savedFiltersService.clearSavedFilters();
    return {
      auction: null,
      buyNowActive: null,
      priceMin: null,
      priceMax: null,
      priceDeliveryIncluded: null,
      aukroPlus: null,
      freeShipping: null,
      personalPickup: null,
      paymentOnline: null,
      paymentViaAukro: null,
      cashOnDelivery: null,
      startingAfterRel: null,
      endingBeforeRel: null,
      filter: null,
      location: null,
      postCode: null,
      distance: null,
      sellersNames: null,
      destinationCountries: null,
    };
  }

  public prepareSearchFilterTags(appliedFilters: ItemSearchFilter): ItemSearchFilterTag[] {
    let output: ItemSearchFilterTag[] = [];

    Object.entries(appliedFilters).sort().reverse().forEach((entry) => {
      const filterName = entry[0];
      const filterValue = entry[1];
      ListingConstants.SEARCH_FILTER_TAG_DESCRIPTION_MAP.forEach((descriptionMap: any) => {
        if (descriptionMap[filterName] !== undefined) {
          if (filterName === 'destinationCountries') {
            const destinationCountriesFilterTags = this.getDestinationCountriesFilterTags(filterName, filterValue);
            output = output.concat(destinationCountriesFilterTags);
          } else if (filterName === 'priceMin' || filterName === 'priceMax') {
            output.push(
              {
                key: filterName,
                value: { amount: filterValue, currency: this.userCurrencyPreferenceService.preferredCurrencyCodeValue } as MoneyDto,
                suffix: null,
                description: descriptionMap[filterName],
                shouldValueBeVisible: true,
              },
            );

          } else if (filterName !== 'distance') {
            output.push(
              {
                key: filterName,
                value: typeof filterValue !== 'boolean' ? filterValue : null, // boolean cause "true" label in template
                description: descriptionMap[filterName],
                suffix: descriptionMap.suffix || null,
                shouldValueBeVisible: true,
                shouldValueBeEmitted: false,
              });
          }
        }
      });
    });

    return output;
  }

  /**
   * Get sort params from sort string like price:ASC
   * @param searchSort
   * @returns SortSearchParams
   */
  public getParsedSortSearchParams(searchSort: string): SortSearchParams {
    const sortParts: string[] = searchSort.split(':');
    const sort: string = sortParts[0];
    const direction: SortDirection = sortParts[1] as SortDirection;
    return { sort, direction };
  }

  public getCountySeats(): Observable<City[]> {
    return this.configuratorCacheService.getFeSystemParam<City[]>('COUNTY_SEATS_LOCATION_INFO', 'JSON');
  }

  private getDestinationCountriesFilterTags(
    filterName: string,
    destinationCountries: string[],
  ): ItemSearchFilterTag[] {
    const destinationCountriesTags: ItemSearchFilterTag[] = [];

    destinationCountries.forEach((destCountry: string) => {
      destinationCountriesTags.push(
        {
          key: filterName,
          value: DestinationCountryUtils.getSelectedDestinationCountries(destinationCountries, destCountry),
          description: DestinationCountryUtils.isAbroad(destCountry) ?
            this.translateService.instant('FILTER_ABROAD_LABEL') as string : destCountry.toUpperCase(),
          suffix: null,
          shouldValueBeVisible: false,
          shouldValueBeEmitted: true,
        });
    });

    return destinationCountriesTags;
  }

}
