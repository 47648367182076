import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AlertBannerModule } from '@shared/legacy/component/alert-banner/alert-banner.module';

import { SuccessMessageComponent } from './success-message.component';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    AlertBannerModule,
    InnerHtmlDirective,
  ],
  declarations: [
    SuccessMessageComponent,
  ],
  exports: [
    SuccessMessageComponent,
  ],
})
export class SuccessMessageModule {
}
