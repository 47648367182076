import { RegistryCountryItemDto } from '@api/generated/defs/RegistryCountryItemDto';
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';
import { ZasilkovnaCountries } from '@shared/pickup-point-widget/zasilkovna-widget/zasilkovna-widget.helper';

/**
 *
 * @param idOrCode
 * @param countries
 */
export function getCountryByIdOrCode(
  idOrCode: number | string,
  countries: RegistryCountryItemDto[],
): RegistryCountryItemDto | Nil {
  if (isNil(idOrCode) || isNil(countries)) {
    return null;
  }

  return countries.find((country) => [country.code, country.id].includes(idOrCode));
}

// Temporary solution, will be replaced by PDEV-20308
/**
 *
 * @param countryToId
 */
export function getZasilkovnaWidgetCountry(countryToId: number): ZasilkovnaCountries[] {
  if (!countryToId) {
    return ['sk'];
  } else {
    return 181 === countryToId ? ['sk'] : ['cz'];
  }
}
