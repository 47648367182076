import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { UserTaskLoginStepComponent } from './user-task-login-step.component';
import { RegisterUserParams, RegistrationService } from '@api/generated/api/Registration';
import { environment } from '@environment';
import { finalize } from 'rxjs/operators';
import { CaptchaService } from '@shared/services/captcha/captcha.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { DialogAlert } from '@common/dialog-wrapper/dialog-alert';
import { HttpError } from '@shared/rest/model/http-error';
import { AUTO_STANCES } from '@shared/registration/const/auto-stances';
import { HttpContext } from '@angular/common/http';
import { RECAPTCHA_COMPONENT } from '@shared/captcha/token/recaptcha-component.token';
import { GaRegisterFormSectionEnum } from '@shared/google-analytics/model/ga-register-form-section.enum';

@Component({
  selector: 'auk-user-task-login-step-email-confirmation-non-registered-via-email',
  templateUrl: 'user-task-login-step-email-confirmation-non-registered-via-email.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RegistrationService, CaptchaService],
})
export class UserTaskLoginStepEmailConfirmationNonRegisteredViaEmailComponent extends
  UserTaskLoginStepComponent<'EMAIL_CONFIRMATION_NON_REGISTERED_VIA_EMAIL'> {

  public readonly CAPTCHA_SITE_KEY: string = environment.CAPTCHA_SITE_KEY;

  public isSubmitting: boolean = false;
  public dialogAlerts: DialogAlert[] = [];

  @ViewChild(RecaptchaComponent, { static: false })
  private recaptchaComponent: RecaptchaComponent;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public onPrimaryButtonClick(): void {
    this.isSubmitting = true;
    this.dialogAlerts = [];

    const registerUserParams: RegisterUserParams = {
      user: {
        email: this.payload.email,
        termsConfirmed: true,
        registrationChannelEnum: 'EMAIL',
        consentStances: AUTO_STANCES,
        businessStanceConfirm: true,
        sourceAction: this.payload.sourceAction,
      },
    };

    const httpContext: HttpContext = new HttpContext().set(RECAPTCHA_COMPONENT, this.recaptchaComponent);

    this.registrationService.registerUser(registerUserParams, {}, httpContext)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe({
        next: () => {
          void this.googleAnalyticsTrackingService.trackRegisterFormParams(GaRegisterFormSectionEnum.REGISTER);
          this.resolve.emit({
            type: 'RESOLUTION_SUCCESS',
            payload: {},
          });
        },
        error: (httpError: HttpError) => {
          console.error(httpError);
          this.dialogAlerts = [{ type: 'DANGER', text: { key: 'REGISTRATION_STEP_1_OVERALL_ERROR' } }];
        },
      });
  }

  public onBackClick(): void {
    this.resolve.emit({
      type: 'RESOLUTION_LOGIN_EMAIL',
      payload: {
        sourceAction: this.payload.sourceAction,
      },
    });
  }

}
