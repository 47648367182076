import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthenticateWrapperComponent } from './component/authenticate-wrapper.component';
import { TwoFactorVerificationModule } from '@shared/two-factor-verification/two-factor-verification.module';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  imports: [
    CommonModule,
    TwoFactorVerificationModule,
    RecaptchaModule,
  ],
  declarations: [
    AuthenticateWrapperComponent,
  ],
  exports: [
    AuthenticateWrapperComponent,
  ],
})
export class AuthenticateWrapperModule {
}
