import isNil from 'lodash-es/isNil';
import { MapUtils } from '@util/util/map.utils';

/**
 * Key - upper limit for time left [seconds]
 * Value - polling interval [seconds]
 *
 * E.g.
 * [120, 20] - poll every 20 seconds if time left above 120 seconds
 * [20, 2] - poll every 2 seconds if time left above 20 seconds
 */
export type PollingBracketsSeconds = Map<number, number>;

export class PollingUtils {

  /**
   * Computes timeout to next poll.
   * @param timeLeftSeconds Seconds left to end of polling
   * @param bracketsMap Map of polling rates with upper limit brackets
   * @param defaultRateSeconds Default polling rate used if no brackets me conditions
   */
  public static resolveTimeout(timeLeftSeconds: number = 0, bracketsMap: Map<number, number>, defaultRateSeconds: number = 1): number {

    if (MapUtils.isEmpty(bracketsMap)) {
      return defaultRateSeconds;
    }

    this.validateBracketMap(bracketsMap);

    const lowerThanTimeLeftBrackets: number[] = Array.from(bracketsMap.keys())
      .filter(bracketLimit => bracketLimit < timeLeftSeconds)
      .sort((a, b) => b - a);

    const currBracket = lowerThanTimeLeftBrackets[0];

    if (isNil(currBracket)) {
      return defaultRateSeconds;
    }

    const currBracketTimeout: number = bracketsMap.get(currBracket);

    if (timeLeftSeconds - currBracketTimeout >= currBracket) {
      return currBracketTimeout;
    }

    const lowerBracket = lowerThanTimeLeftBrackets[1];
    const lowerBracketTimeout = bracketsMap.get(lowerBracket) ?? defaultRateSeconds;

    return timeLeftSeconds - currBracket + lowerBracketTimeout;
  }

  private static validateBracketMap(bracketsMap: Map<number, number>): void {
    const containsInvalidBracket = Array.from(bracketsMap.keys())
      .some((key) => isNil(key) || isNil(bracketsMap.get(key)));

    if (containsInvalidBracket) {
      throw new Error('Brackets map is invalid');
    }
  }

}
