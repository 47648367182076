import { CanActivateFn } from '@angular/router';
import { asyncRenderGuard } from '@common/async-render/guard/async-render.guard';

export const getLeafCanActivateGuards: (...additionalGuards: CanActivateFn[]) => {
  canActivate: CanActivateFn[];
} = (...additionalGuards: CanActivateFn[]) => ({
  canActivate: [
    ...additionalGuards,
    asyncRenderGuard,
  ],
});
