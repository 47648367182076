@if (isUserLinkVisible) {
  <a
    [aukLinkFromHref]="userDetailAdminLink"
    [openInNewTab]="true"
    class="tw-inline-block !tw-text-matter-accent-primary tw-text-lg tw-no-underline hover:tw-underline tw-font-bold tw-cursor-pointer"
    >
    {{ 'USER_PROFILE_ADMIN_ACTION_USER' | translate }}
  </a>
}

@if (isClipboardIconVisible) {
  <auk-icon
    colorCombination="TERTIARY_600_CONTRAST"
    source="pets"
    size="MD"
    iconType="MATERIAL"
    class="tw-ml-4 tw-cursor-pointer"
    [title]="{ key: 'INSERT_TO_ADMIN_CLIPBOARD_DIALOG_HEADER' } | translateSource"
    (click)="openClipboardDialog()"
  ></auk-icon>
}
