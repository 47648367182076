<re-captcha size="invisible"
            errorMode="handled"
            [siteKey]="CAPTCHA_SITE_KEY"
></re-captcha>

<auk-two-factor-verification [requestCallback]="authenticateCallback"
                         (goBackClicked)="onTfwGoBackClicked()"
>
    <ng-content></ng-content>
</auk-two-factor-verification>
