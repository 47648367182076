import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { AccordionItemContentDirective } from '@common/ui-kit/component/accordion/directive/accordion-item-content.directive';
import { TranslationSource } from '@common/translations/model/translation-source';

@Component({
  selector: 'auk-accordion-item-new',
  templateUrl: './accordion-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionItemComponent {

  @Input() public titleText: TranslationSource;
  @Input() public titleTpl: TemplateRef<unknown>;
  @Input() public isActive: boolean = false;
  @ContentChild(AccordionItemContentDirective)
  public accordionItemContent: AccordionItemContentDirective;

}
