
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TileContainerComponent } from '@common/ui-kit/component/tile/component/tile-container/tile-container.component';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Nil } from '@util/helper-types/nil';
import { trackByIdFn } from '@util/helper-functions/track-by/track-by-id-fn';
import { TabMenuContainerComponent } from '@common/ui-kit/component/tab-menu-container/component/tab-menu-container.component';
import { TabMenuItemModel } from '@common/ui-kit/component/tab-menu-item/model/tab-menu-item.model';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TabMenuOverlayComponent } from '@common/ui-kit/component/tab-menu-overlay/component/tab-menu-overlay.component';
import { HeaderNavbarContainerModel } from '../model/header-navbar-container.model';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { combineLatest, merge, Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { HorizontalMenuTabOperationData } from '@shared/app-header/component/horizontal-header-navbar/model/horizontal-menu-tab-operation-data.model';
import isNil from 'lodash-es/isNil';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { ListingService } from '@shared/listing/service/listing.service';
import { UrlUtils } from '@util/util/url.utils';
import { PersonalizationMeasurementService } from '@shared/services/personalization/personalization-measurement.service';
import { CanvasMenuItemModel } from '@common/ui-kit/component/tab-menu-overlay/model/canvas-menu-item.model';
import { AppHeaderHamburgerService } from '@shared/app-header/service/app-header-hamburger.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

const MENU_HOVER_DELAY = 300;

@Component({
  selector: 'auk-horizontal-menu-navbar',
  templateUrl: './horizontal-menu-navbar.component.html',
  styleUrls: ['./horizontal-menu-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TileContainerComponent,
    TabMenuContainerComponent,
  ],
})
export class HorizontalMenuNavbarComponent extends NgUnsubscribe implements OnChanges {

  @Input() public itemData: HeaderNavbarContainerModel | Nil;

  @ViewChild('horizontalHeaderNavBar') protected readonly horizontalHeaderNavBar: ElementRef<HTMLDivElement>;

  protected trackByIdFn = trackByIdFn;
  protected combinedTabsForSmAndMd: TabMenuItemModel<HorizontalMenuTabOperationData>[] | Nil = [];
  protected isMdAndLower: boolean = false;
  protected isLgAndLower: boolean = false;
  private isHoveringOverlay: boolean = false;
  private isHoveringNavBar: boolean = false;
  private hoverDelayDestroy$: Subject<void> = new Subject<void>();
  private overlayRef: OverlayRef | Nil;

  constructor(
    private readonly overlay: Overlay,
    private readonly responsivenessService: ResponsivenessService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly router: Router,
    private readonly subbrandService: SubbrandService,
    private readonly appHeaderService: AppHeaderHamburgerService,
    private readonly listingService: ListingService,
    private readonly personalizationMeasurementService: PersonalizationMeasurementService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();

    combineLatest([
      this.responsivenessService.isMdAndLower$,
      this.responsivenessService.isLgAndLower$,
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([isMdAndLower, isLgAndLower]: [boolean, boolean]) => {
        this.isMdAndLower = isMdAndLower;
        this.isLgAndLower = isLgAndLower;
        this.changeDetectorRef.markForCheck();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.itemData && this.itemData) {
      const tabGroupLeft = this.itemData.tabGroupLeft || [];
      const tabGroupRight = this.itemData.tabGroupRight || [];

      // Take the first 6 items from tabGroupLeft - Categories
      const first6Items = tabGroupLeft.slice(0, 6);

      // Take the last item from tabGroupLeft - "ALL" Button
      const lastItem = tabGroupLeft[tabGroupLeft.length - 1];

      this.combinedTabsForSmAndMd = [...first6Items, lastItem, ...tabGroupRight];
    } else {
      this.combinedTabsForSmAndMd = null;
    }
  }

  protected onTabClick(data: HorizontalMenuTabOperationData): void {
    this.closeOverlay();

    if (isNotNil(this.horizontalHeaderNavBar?.nativeElement)) {
      this.horizontalHeaderNavBar.nativeElement.scrollLeft = 0;
    }

    if (isNil(data)) {
      return;
    }

    this.personalizationMeasurementService.clickMeasurementWithCode(data.moCode);

    if (data.operation === 'openUrl' && data.link) {
      void this.router.navigate([data.link]);
      return;
    }

    if (data.operation === 'switchSubbrand') {
      this.subbrandService.setSessionSubbrandWithHomepageRedirect(data.subbrand);
      return;
    }

    if (data.operation === 'openCategoryMenu') {
      this.appHeaderService.openCategoryMenu();
    }
  }

  protected onTabHover(data: TabMenuItemModel<HorizontalMenuTabOperationData>): void {
    if (!data.canvasMenu?.items || PlatformCommonService.isNativeApp) {
      return;
    }
    this.isHoveringNavBar = true;

    this.ngZoneUtilService.simpleTimerOut$(
      () => {
        if (!this.isHoveringOverlay && this.isHoveringNavBar) {
          this.displayOverlay(data);
        }
      },
      merge(this.ngUnsubscribe, this.hoverDelayDestroy$),
      MENU_HOVER_DELAY,
    );
  }

  // Check if the mouse is not hovering over the button or the overlay
  protected onTabHoverEnd(): void {
    this.hoverDelayDestroy$.next();

    if (!isNil(this.overlayRef)) {
      // Timeout is added to give user some time to move from button to canvas
      this.ngZoneUtilService.simpleTimerOut$(
        () => {
          if (!this.isHoveringOverlay && !this.isHoveringNavBar) {
            this.closeOverlay();
          }
        },
        this.ngUnsubscribe,
        100,
      );
    }

    this.isHoveringNavBar = false;
  }

  private closeOverlay(): void {
    if (!isNil(this.overlayRef)) {
      this.overlayRef.dispose();
      this.isHoveringOverlay = false;
    }
  }

  // Display overlay - Category menu connected to navbar
  private displayOverlay(data: TabMenuItemModel<HorizontalMenuTabOperationData>): void {
    if (this.isMdAndLower) {
      return;
    }

    this.closeOverlay();

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.horizontalHeaderNavBar)
      .setOrigin(this.horizontalHeaderNavBar)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ])
      .withPush(false);

    this.overlayRef = this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      positionStrategy,
      maxWidth: 1400,
      width: '100%',
    });

    const componentRef = this.overlayRef.attach(new ComponentPortal(TabMenuOverlayComponent));
    componentRef.instance.canvasMenu = data?.canvasMenu;
    componentRef.instance.itemClick
      .pipe(
        takeUntil(
          merge(componentRef.instance.ngUnsubscribe, this.ngUnsubscribe),
        ),
      )
      .subscribe((clickedModel: CanvasMenuItemModel) => {
        if (clickedModel?.url) {
          this.personalizationMeasurementService.clickMeasurementWithCode(clickedModel?.moCode);
          this.listingService.searchDirectUrl$.next();
          void this.router.navigateByUrl(UrlUtils.getRelativeUrl(clickedModel.url));
        }

        this.closeOverlay();
      });

    // Function to handle mouse enter on the button or overlay
    this.ngZoneUtilService.fromEventOut$(this.overlayRef.hostElement, 'mouseenter')
      .pipe(
        takeUntil(
          merge(
            componentRef.instance.ngUnsubscribe,
            this.ngUnsubscribe,
          ),
        ),
      )
      .subscribe(() => {
        this.isHoveringOverlay = true;
      });

    // Function to handle mouse leave on the button or overlay
    this.ngZoneUtilService.fromEventOut$(this.overlayRef.hostElement, 'mouseleave')
      .pipe(
        takeUntil(
          merge(
            componentRef.instance.ngUnsubscribe,
            this.ngUnsubscribe,
          ),
        ),
      )
      .subscribe(() => {
        this.isHoveringOverlay = false;
        this.onTabHoverEnd();
      });
  }

}
