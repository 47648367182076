import { Injectable } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { ListingTypeConfigModel } from '../model/listing-type-config.model';
import { ListingType } from '../model/listing-type.enum';
import { LISTING_TYPES_CONFIG } from '../const/listing-types-config.const';

@Injectable({
  providedIn: 'root',
})
export class ListingTypeService {

  private _activeListingType: ListingType = null;

  public getActiveListingType(): ListingType {
    return this._activeListingType;
  }

  public setActiveListingType(listingType: ListingType): void {
    this._activeListingType = listingType;
  }

  public resetActiveListingType(): void {
    this._activeListingType = null;
  }

  /**
   * Whether some listing page is opened
   */
  public someListingOpened(): boolean {
    return !isNil(this.getActiveListingType());
  }

  public getActiveListingTypeConfig(): ListingTypeConfigModel {
    return LISTING_TYPES_CONFIG.get(this.getActiveListingType());
  }

  public isActive(listingType: ListingType): boolean {
    return this.getActiveListingType() === listingType;
  }

}
