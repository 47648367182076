import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { ItemViewDto } from '../model/item-view-dto';
import { PopularSearchesResponse } from '../model/popular-searches-response';
import { SuggestAllRequest } from '../model/suggest-all-request';
import { SuggestAllResponse } from '../model/suggest-all-response';
import { YuspEventsLogDto } from '../model/yusp-events-log-dto';
import { BASE_PATH } from '../variables';

export interface Get404SuggestionRequestParams {
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface GetCartOffersRequestParams {
    itemId: number;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface GetCategorySuggestionRequestParams {
    categoryId: number;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface GetUserSuggestionRequestParams {
    /** Exclude item from suggestion */
    excludedItemId?: number;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    userId: number;
}

export interface SaveYuspEventLogRequestParams {
    yuspEventsLogDto: YuspEventsLogDto;
}

export interface SuggestAllRequestParams {
    subbrand: 'BAZAAR' | 'ANTIQUE' | 'NOT_SPECIFIED';
    suggestAllRequest: SuggestAllRequest;
}

export interface SuggestRandomItemsRequestParams {
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface SuggestSimilarItemNamesRequestParams {
    text: string;
}

@Injectable({
  providedIn: 'root',
})
export class SuggestApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Return random items for 404 page
   */
  public get404Suggestion$(
    requestParameters: Get404SuggestionRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemViewDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<ItemViewDto>'),
      headers,
    };
    return this.httpClient.request<ItemViewDto[]>(
      'get',
      `${ BASE_PATH }/suggest/notFound`,
      options,
    );
  }

  /**
   * Return similar offers (only buy now) to specified offer on cart page.
   */
  public getCartOffers$(
    requestParameters: GetCartOffersRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemViewDto[]> {
    const itemId: number = requestParameters.itemId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<ItemViewDto>'),
      headers,
    };
    return this.httpClient.request<ItemViewDto[]>(
      'get',
      `${ BASE_PATH }/suggest/cart/${ this.configuration.encodeParam({ name: 'itemId', value: itemId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }`,
      options,
    );
  }

  /**
   * Return random items in category
   */
  public getCategorySuggestion$(
    requestParameters: GetCategorySuggestionRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemViewDto[]> {
    const categoryId: number = requestParameters.categoryId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<ItemViewDto>'),
      headers,
    };
    return this.httpClient.request<ItemViewDto[]>(
      'get',
      `${ BASE_PATH }/suggest/category/${ this.configuration.encodeParam({ name: 'categoryId', value: categoryId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }`,
      options,
    );
  }

  /**
   * Get popular searches
   */
  public getPopularSearches$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PopularSearchesResponse> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'PopularSearchesResponse'),
      headers,
    };
    return this.httpClient.request<PopularSearchesResponse>(
      'get',
      `${ BASE_PATH }/suggest/popular-searches`,
      options,
    );
  }

  /**
   * Return random items from seller
   */
  public getUserSuggestion$(
    requestParameters: GetUserSuggestionRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemViewDto[]> {
    const userId: number = requestParameters.userId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      excludedItemId: requestParameters.excludedItemId,
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<ItemViewDto>'),
      headers,
    };
    return this.httpClient.request<ItemViewDto[]>(
      'get',
      `${ BASE_PATH }/suggest/user/${ this.configuration.encodeParam({ name: 'userId', value: userId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }`,
      options,
    );
  }

  /**
   * Saves log of sent event to yusp
   */
  public saveYuspEventLog$(
    requestParameters: SaveYuspEventLogRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.yuspEventsLogDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/suggest/yuspEventLog`,
      options,
    );
  }

  /**
   * Suggestions for various resources
   */
  public suggestAll$(
    requestParameters: SuggestAllRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<SuggestAllResponse> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      subbrand: requestParameters.subbrand,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.suggestAllRequest,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'SuggestAllResponse'),
      headers,
    };
    return this.httpClient.request<SuggestAllResponse>(
      'post',
      `${ BASE_PATH }/suggest/all`,
      options,
    );
  }

  /**
   * Return random items
   */
  public suggestRandomItems$(
    requestParameters: SuggestRandomItemsRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ItemViewDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<ItemViewDto>'),
      headers,
    };
    return this.httpClient.request<ItemViewDto[]>(
      'get',
      `${ BASE_PATH }/suggest/random`,
      options,
    );
  }

  /**
   * Suggest offer name based on seller\&#39;s previous names.
   */
  public suggestSimilarItemNames$(
    requestParameters: SuggestSimilarItemNamesRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<string[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      text: requestParameters.text,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<string>'),
      headers,
    };
    return this.httpClient.request<string[]>(
      'get',
      `${ BASE_PATH }/suggest/similarOfferNames`,
      options,
    );
  }

}
