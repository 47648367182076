import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';
import { HttpContext } from '@angular/common/http';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ActivatePayoutsParams {
  /** PayU payouts settings */
  payUSettingsFormDto: model.PayUSettingsFormDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface FileDisputeParams {
  /** requestDto */
  requestDto: model.FileDisputeRequestDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface BankAccountParams {
  /** bankAccount */
  bankAccount: string;
  /**
   * id
   * format: int64
   */
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface PaymentMethodsParams {
  /** payuPos */
  payuPos?: PayuPosPaymentMethodsParamsEnum;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type PayuPosPaymentMethodsParamsEnum =
  'BILLING' |
  'PAYMENT_FOR_SALE' |
  'VERIFICATION';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface PaysBillingParams {
  /** Payment data */
  paysPaymentDto: model.PaysBillingPaymentDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface RefundParams {
  /** Refund data */
  payURefundFormDto: model.PayURefundFormDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SendBillingFeePaymentParams {
  /** payUFeeDto */
  payUFeeDto: model.PayUBillingFeeDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SendPayUVerificationPaymentParams {
  /** continuePathUrl */
  continuePathUrl: string;
  /** Selected payU method name */
  payUPaymentMethodName?: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SendSaleVerificationPaymentParams {
  /** continuePathUrl */
  continuePathUrl: string;
  /** payUPaymentMethodName */
  payUPaymentMethodName?: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface UploadDocsToAMLVerifyParams {
  /** verifyAmlDocsDto */
  verifyAmlDocsDto: model.VerifyAmlDocsDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root'
})
export class PayUPaymentService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public activatePayouts(params: ActivatePayoutsParams, headers: { [key: string]: string } = {}): Observable<model.PayUSettingsFormDto> {
    let bodyParams = params.payUSettingsFormDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/payment/activatePayouts`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public fileDispute(params: FileDisputeParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.requestDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/payment/aukro/buyer/fileDispute`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public bankAccount(params: BankAccountParams, headers: { [key: string]: string } = {}): Observable<void> {
    let queryParams = {
      bankAccount: params.bankAccount,
    };
    let pathParams = {
      id: params.id,
    };
    let bodyParams;
    return this.putGenerated(`/payment/aukro/${pathParams.id}/admin/bankAccount`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getPayoutsSettings(): Observable<model.PayUSettingsFormDto> {
    return this.getGenerated(`/payment/getPayoutsSettings`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public paymentMethods(params: PaymentMethodsParams): Observable<model.PaymentMethodsDto> {
    let queryParams = {
      payuPos: params.payuPos,
    };
    return this.getGenerated(`/payment/paymentMethods`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public paysBilling(params: PaysBillingParams, headers: { [key: string]: string } = {}): Observable<model.PaysPaymentResponseDto> {
    let bodyParams = params.paysPaymentDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/payment/paysBilling`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public refund(params: RefundParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.payURefundFormDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/payment/refund`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public sendBillingFeePayment(params: SendBillingFeePaymentParams, headers: { [key: string]: string } = {}): Observable<model.PayUPaymentResponseDto> {
    let bodyParams = params.payUFeeDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/payment/sendBillingFeePayment`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public sendPayUVerificationPayment(params: SendPayUVerificationPaymentParams, headers: { [key: string]: string } = {}): Observable<model.PayUPaymentResponseDto> {
    let queryParams = {
      continuePathUrl: params.continuePathUrl,
      payUPaymentMethodName: params.payUPaymentMethodName,
    };
    let bodyParams;
    let formDataParams;
    return this.postGenerated(`/payment/sendPayUVerificationPayment`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public sendSaleVerificationPayment(params: SendSaleVerificationPaymentParams, headers: { [key: string]: string } = {}): Observable<model.PayUPaymentResponseDto> {
    let queryParams = {
      continuePathUrl: params.continuePathUrl,
      payUPaymentMethodName: params.payUPaymentMethodName,
    };
    let bodyParams;
    let formDataParams;
    return this.postGenerated(`/payment/sendSaleVerificationPayment`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public uploadDocsToAMLVerify(params: UploadDocsToAMLVerifyParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.verifyAmlDocsDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/payment/uploadDocsToAMLVerify`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public checkPrerequisitesForNewVerificationUsingPOST(httpContext: HttpContext): Observable<void> {
    let queryParams;
    return this.postGenerated(`/payment/verification/check-prerequisites`, undefined, queryParams, undefined, undefined, undefined, httpContext);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public walletBalance(): Observable<model.PayUWalletBalanceRestDto> {
    return this.getGenerated(`/payment/walletBalance`);
  }
}
