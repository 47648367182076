/**
 *
 * @param oldPath
 * @param path
 */
export function isChangeOnlyQuery(oldPath: string, path: string): boolean {
  const [pathWithoutQuery, pathQuery] = path.split('?');
  const [oldPathWithoutQuery, oldPathQuery] = oldPath ? oldPath.split('?') : new Array(2);

  return oldPathWithoutQuery === pathWithoutQuery && pathQuery !== oldPathQuery;
}
