import { HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  makeStateKey,
  TransferState,
  ViewEncapsulation,
} from '@angular/core';
import { PlatformService } from '@shared/platform/service/platform.service';
import { TransferStateUtils } from '@util/util/transfer-state.utils';

/**
 * @deprecated
 * use {@link SvgIconComponent} from 'ui-kit/svg-icon' folder
 * is still used in standalone config_elements and when would be removed, it must be removed from the CE
 */
@Component({
  selector: 'auk-svg-icon-legacy',
  templateUrl: './svg-icon.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {

  @HostBinding('id')
  public iconId: string;

  constructor(private platformService: PlatformService,
              private transferState: TransferState) {
  }

  /** Icon identifier. */
  @Input()
  public set id(value: string) {
    this.iconId = value;
  }

  /** Relative path to SVG source file. */
  public get path(): string {
    return `/assets/icon/${ this.iconId }.svg`;
  }

  /**
   * Invoke after SVG was inlined.
   * @param svgEl
   */
  public onSvgInserted(svgEl: SVGElement): void {
    if (this.platformService.isBrowser) {
      return;
    }

    this.saveSvgToTransferState(svgEl);
  }

  /**
   * The svg is cached on the server, so there might not be a http request to be saved into transfer state.
   * This method saves a fake request, that is used during the rehydration on the client.
   * @param svgEl svg element to be saved
   */
  private saveSvgToTransferState(svgEl: SVGElement): void {
    const key = makeStateKey<string>(TransferStateUtils.generateKeyForRequest({
      url: this.path,
      method: 'GET',
      params: new HttpParams(),
      body: null,
    }));

    this.transferState.set(key, svgEl.outerHTML);
  }

}
