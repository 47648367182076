import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { StarIconSize } from '@common/ui-kit/component/star-icon/model/star-icon-size';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { StarIcon, StarIconType } from '@common/ui-kit/component/star-icon/model/star-icon.model';
import { starIconContainer } from './constant/star-icon-data.constant';

@Component({
  selector: 'auk-star-icon',
  templateUrl: './star-icon.component.html',
  styleUrls: ['./star-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
  ],
})
export class StarIconComponent implements OnChanges{

  @Input() public starType: StarIconType;
  @Input() public size: StarIconSize = 'MD';
  public iconSizeClass: string;
  public starInformation: StarIcon;

  public ngOnChanges(changes: AukSimpleChanges<StarIconComponent>): void {

    if (changes.size) {
      this.iconSizeClass = StarIconComponent.getIconSizeClass(this.size ?? 'MD');
    }
    if(changes.starType){
      this.starInformation = starIconContainer.find(star => star.type === this.starType);
    }
  }

  private static getIconSizeClass(size: StarIconSize): string {
    return `size-${ size.toLowerCase() }`;
  }

}
