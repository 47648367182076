import { DialogModel } from '@common/dialog/model/dialog.model';

export class DialogUtil {

  public static of<T>(modalType: T, id?: string): DialogModel<T> {
    return {
      type: modalType,
      id,
    };
  }

}
