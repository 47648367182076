import { Subscription } from 'rxjs';
import { NgZone } from '@angular/core';
import { ZoneScheduler } from '@util/zone/domain/zone-scheduler';
import { WorkModel } from '@util/zone/model/work.model';
import { DelayModel } from '@util/zone/model/delay.model';

export class EnterZoneScheduler extends ZoneScheduler {

  public override schedule<T>(...args: [WorkModel<T>, DelayModel, T]): Subscription {
    return NgZone.isInAngularZone()
      ? this.schedulerLike.schedule(...args)
      : this.ngZone.run(() => this.schedulerLike.schedule(...args));
  }

}
