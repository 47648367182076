@if (showSearch) {
  <auk-icon
    (click)="buttonClicked('SEARCH')"
    class="tw-cursor-pointer tw-z-[3] tw-px-[2vw] tw-py-3 !tw-flex tw-items-center"
    iconType="SVG"
    source="search-new"
    size="XMD"
    aukTestIdentification="search-icon"
    aukAccessibilityId="ACS_HEADER_SEARCH_ICON"
    colorCombination="CONTRAST_OPPOSITE"
  />
}

<auk-account-control
  aukTestIdentification="account-control"
  aukAccessibilityId="ACS_HEADER_ACCOUNT_CONTROL"
  class="nav tw-flex tw-items-center tw-min-w-[38px]"
  [user]="user"
  (click)="onUserInfoClick()"
/>

@if (user) {
  <auk-announcements-control
    class="nav tw-flex tw-items-center header-icon lg:tw-mx-0.5"
    [user]="user"
    aukTestIdentification="announcements-control"
    aukAccessibilityId="ACS_NNOUNCEMENTS_CONTROL"
  />
  <auk-inbox-control
    class="nav tw-flex tw-items-center header-icon lg:tw-mx-0.5"
    aukTestIdentification="inbox-control"
    aukAccessibilityId="ACS_INBOX_CONTROL"
  />
  <auk-favourites-control
    aukPopoverToggle
    aukTestIdentification="favourites-control"
    aukAccessibilityId="ACS_FAVOURITE_CONTROL"
    class="nav tw-flex tw-items-center header-icon lg:tw-mx-0.5"
    [user]="user"
  />
}

@if (showCart) {
  <auk-basket-control
    aukTestIdentification="basket-control"
    aukAccessibilityId="ACS_BASKET_CONTROL"
    class="nav tw-flex tw-items-center header-icon lg:tw-mx-0.5"
  />
}

<div
  class="nav last tw-flex tw-items-center hidden-tablet-and-more-bigger"
  aukTestIdentification="menu-button"
  aukAccessibilityId="ACS_MENU_BUTTON"
  (click)="toggleNavigationMenu()"
>
  <span class="material-icons big pointer-events-none">menu</span>
</div>

<auk-cart-notification/>
