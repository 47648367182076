import { Injectable, OnDestroy } from '@angular/core';
import { APP_CONSTANTS } from '@app-constants';
import { AsyncSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { AppHeaderService } from '@shared/app-header/service/app-header.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { BannerPlacementDto } from '@api/aukro-api/model/banner-placement-dto';
import { RequestBannerDto, RequestBannerDtoPageTypeEnumEnum, RequestBannerDtoPlacementsEnum } from '@api/aukro-api/model/request-banner-dto';
import { BannerApiService } from '@api/aukro-api/api/banner-api.service';
import { BannerGroupType } from '@shared/banner/model/banner-group.type';

interface BannerCache {
  [key: string]: BannerCachePlacement;
}

interface BannerCachePlacement {
  [key: string]: AsyncSubject<BannerPlacementDto>;
}

interface BannerPlacementCache {
  [key: string]: RequestBannerDtoPlacementsEnum[];
}

@Injectable({
  providedIn: 'root',
})
export class BannersService extends NgUnsubscribe implements OnDestroy {

  public refreshTrigger = new Subject<void>();
  private bannerPlacementsCache: BannerPlacementCache = {};
  private bannerCache: BannerCache = {};

  constructor(
    private readonly bannerApiService: BannerApiService,
    private readonly localStorageService: LocalStorageService,
    private readonly appHeaderService: AppHeaderService,
  ) {
    super();
  }

  public prepareBanners(
    requestData: RequestBannerDto,
    onlyAukroPlacements: RequestBannerDtoPlacementsEnum[] = ['CATEGORY_BANNER_DESKTOP', 'CATEGORY_BANNER_MOBILE',
      'CATEGORY_TOP_LINE_BANNER']): void {
    this.bannerPlacementsCache[requestData.pageTypeEnum] = requestData.placements;
    this.bannerCache[requestData.pageTypeEnum] = {};
    requestData.placements = requestData.placements.filter((placement: RequestBannerDtoPlacementsEnum) =>
      onlyAukroPlacements.includes(placement) || this.group === 'AUKRO');

    requestData.placements.forEach((item: RequestBannerDtoPlacementsEnum) =>
      this.bannerCache[requestData.pageTypeEnum][item] = new AsyncSubject());
    this.refreshTrigger.next();

    // In case we do not need any banner from BE
    if (!requestData.placements.length) {
      return;
    }

    this.bannerApiService.getBanners$({ requestBannerDto: requestData })
      .pipe(take(1))
      .subscribe((data: BannerPlacementDto[]) => {
        requestData.placements.forEach((placement: RequestBannerDtoPlacementsEnum) => {
          const responseBanner: BannerPlacementDto = data.find((item: BannerPlacementDto) => item.placementCode === placement);
          const subject: AsyncSubject<BannerPlacementDto> = this.bannerCache[requestData.pageTypeEnum][placement];
          subject.next(responseBanner ? responseBanner : null);
          subject.complete();

          this.appHeaderService.setTopLineBanner(responseBanner?.type === 'TEXT' ? responseBanner : null);
        });
      });
  }

  public isPlacementExisting(page: RequestBannerDtoPageTypeEnumEnum, placement: RequestBannerDtoPlacementsEnum): boolean {
    return this.bannerPlacementsCache[page] && this.bannerPlacementsCache[page].includes(placement);
  }

  public isAukroBanner(page: RequestBannerDtoPageTypeEnumEnum, placement: RequestBannerDtoPlacementsEnum): boolean {
    return this.bannerCache[page] && !!this.bannerCache[page][placement];
  }

  public waitForBanner(page: RequestBannerDtoPageTypeEnumEnum, placement: RequestBannerDtoPlacementsEnum): Observable<BannerPlacementDto> {
    return this.bannerCache[page][placement].asObservable();
  }

  /**
   * Gets category placements
   * @param currentPageItemsCount - count of items on the current page
   * @param isCategory - if it is category listing (if so, we want to ask for current category related banner)
   * @returns - placements
   */
  public getListingBannerPlacements(currentPageItemsCount: number, isCategory: boolean): RequestBannerDtoPlacementsEnum[] {
    const placements: RequestBannerDtoPlacementsEnum[] = ['LIST_SKY'];

    if (currentPageItemsCount > 0) {
      placements.push('LIST_UPPER');
    }

    if (currentPageItemsCount >= 43) { // 45 - 1 upper banner - 1 current position
      placements.push('LIST_LOWER');
    }

    if (isCategory) {
      placements.push('CATEGORY_BANNER_DESKTOP', 'CATEGORY_BANNER_MOBILE', 'CATEGORY_TOP_LINE_BANNER');
    }

    return placements;
  }

  private get group(): BannerGroupType {
    // Value in LS means Aukro banners percentage (range 0–100).
    const aukroBannersPercentage: number =
      this.localStorageService.getItem(APP_CONSTANTS.AUKRO_BANNERS_CHANCE_LOCALSTORAGE_NAME) || 0;
    if (aukroBannersPercentage === 0) {
      return 'THIRD_PARTY';
    }
    return Math.random() <= (aukroBannersPercentage / 100) ? 'AUKRO' : 'THIRD_PARTY';
  }

}
