import { ChangeDetectorRef, Component, HostBinding, HostListener } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

@Component({
  template: '',
})
export abstract class UserHeaderControlComponent extends NgUnsubscribe {

  @HostBinding('class.force-closed') public forceClosed: boolean = false;
  @HostBinding('class.touch-opened') public touchOpened: boolean = false;

  protected constructor(
    protected readonly window: Window,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly responsivenessService: ResponsivenessService,
    protected readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  /**
   * Search for toggle-on-touch class on clicked element and toggle dropdown (only on touch devices).
   */
  @HostListener('touchstart', ['$event'])
  public touch(event: TouchEvent): void {
    let target = event.target as Node;
    while (target) {
      const element: HTMLElement = target as HTMLElement;
      if (element.classList && element.classList.contains('toggle-on-touch')) {
        this.touchOpened = !this.touchOpened;
        target = null;
      } else {
        target = target.parentNode;
      }
    }
  }

  /** Close on click outside on touch devices – on hover lost, close dropdown. */
  @HostListener('mouseleave')
  public hoverLost(): void {
    this.touchOpened = false;
  }

  /** Close dropdown by add and remove closing class. */
  protected closeDropdown(): void {
    this.forceClosed = true;
    this.ngZoneUtilService.simpleTimerOut$(
      () => {
        this.forceClosed = false;
        this.changeDetectorRef.markForCheck();
      },
      this.ngUnsubscribe,
    );
    this.touchOpened = false;
  }

  /**
   * Measure show event when the popover is shown (on desktop when user hovers the icon with cursor).
   */
  protected onPopoverInViewport(isInViewport: boolean): void {
    if (isInViewport) {
      this.measurePopoverShow();
    }
  }

  protected abstract measurePopoverShow(): void;

}
