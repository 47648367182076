import { ElementRef, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import isNil from 'lodash-es/isNil';
import { ScrollDurationType } from '@common/scroll/model/scroll-duration.type';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import includes from 'lodash-es/includes';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

@Injectable({
  providedIn: 'root',
})
export class ScrollService extends NgUnsubscribe {

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    private readonly platformCommonService: PlatformCommonService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public scrollToElement(
    element: HTMLElement,
    additionalOffset: number = 0,
    duration: ScrollDurationType = 2000,
  ): void {
    if (isNil(element) || this.platformCommonService.isServer) {
      return;
    }

    const offset = this.getElementOffsetY(element);

    this.smoothScroll(offset + additionalOffset, duration);
  }

  public scrollToSelector(
    querySelector: string,
    additionalOffset: number = 0,
    duration: ScrollDurationType = 2000,
  ): void {
    const scrollToElement = this.document.querySelector<HTMLElement>(querySelector);

    this.scrollToElement(scrollToElement, additionalOffset, duration);
  }

  /**
   * Scrolls element into view
   * @param selector CSS selector matching element to be scrolled to
   * @param options scroll behaviour options
   * @returns true if element found, and false otherwise
   */
  public scrollIntoViewSelector(selector: string, options?: ScrollIntoViewOptions): boolean {
    if (isNil(options)) {
      options = {
        block: 'start',
        inline: 'nearest',
      };
    }

    const element = this.document.querySelector<HTMLElement>(selector);

    if (isNil(element)) {
      return false;
    }

    element.scrollIntoView(options);
    return true;
  }

  /**
   * Scrolls the parent element horizontally to ensure that the selected element is in view.
   *
   * This method calculates the horizontal scroll position needed to align the selected element
   * within the parent element's visible area. It adjusts the `scrollLeft` property of the
   * parent element to bring the selected element into view.
   * @param {ElementRef<HTMLElement>} parentElement - The reference to the parent element that contains the scrollable content.
   * @param {ElementRef<HTMLElement>} selectedElement - The reference to the element that needs to be scrolled into view.
   */
  public scrollHorizontallyToSelected(parentElement: ElementRef<HTMLElement>, selectedElement: ElementRef<HTMLElement>): void {
    if (this.platformCommonService.isServer || !parentElement?.nativeElement || !selectedElement?.nativeElement) {
      return;
    }
    const parentGetBoundingClientRect = parentElement.nativeElement.getBoundingClientRect();
    const selectedGetBoundingClientRect = selectedElement.nativeElement.getBoundingClientRect();

    parentElement.nativeElement.scrollLeft += selectedGetBoundingClientRect.left - parentGetBoundingClientRect.left;
  }

  private smoothScroll(scrollToYPosition: number, duration: number = 2000): void {
    /**
     * Emit a value when smoothScroll:
     * - was interrupted by user scroll
     * - completed
     */
    const interruption$: Subject<void> = new Subject<void>();
    /**
     * Navigation keys, which should stop scrolling
     */
    const navigationKeys = [
      'ArrowDown',
      'ArrowUp',
      'End',
      'Home',
      'PageDown',
      'PageUp',
    ];
    const start = this.scrollTopValue;
    const distance = scrollToYPosition - start;
    /**
     * Holds a reference to a scheduled animationFrame
     */
    let animationFrame: number;
    let nextScrollTo: number;
    let timeStart: number;
    let timeElapsed: number;

    /**
     * Basic easing function
     * https://github.com/jaxgeller/ez.js
     */
    const easeInOutQuadFn = (t: number, b: number, c: number, d: number): number => {
      t /= d / 2;
      if (t < 1) {
        return c / 2 * t * t + b;
      }
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    };

    /**
     * Dispose scrolling
     */
    const disposeFn = (): void => {
      interruption$.next();
      interruption$.complete();
    };

    /**
     * Perform the final scroll & complete
     */
    const endFn = (): void => {
      disposeFn();
      this.window.scrollTo(0, scrollToYPosition);
    };

    /**
     * Recursive partial scroll
     */
    const scrollFn = (timeCurrent: number): void => {
      // Set scrolling start time
      if (!timeStart) {
        timeStart = timeCurrent;
      }

      timeElapsed = timeCurrent - timeStart;
      nextScrollTo = easeInOutQuadFn(timeElapsed, start, distance, duration);

      this.window.scrollTo(0, nextScrollTo);

      if (timeElapsed < duration) {
        animationFrame = this.window.requestAnimationFrame(scrollFn);
      } else {
        endFn();
      }
    };

    const wheel$ = this.ngZoneUtilService.fromEventOut$(this.window, 'wheel');
    const navigationKeys$ = this.ngZoneUtilService.fromEventOut$(this.window, 'keydown')
      .pipe(
        filter((e) => includes(navigationKeys, e.key)),
      );

    // Handle any possible interruption from the user
    merge(
      wheel$,
      navigationKeys$,
    )
      .pipe(
        takeUntil(
          merge(
            interruption$,
            this.ngUnsubscribe,
          ),
        ),
      )
      .subscribe(() => {
        this.window.cancelAnimationFrame(animationFrame);
        disposeFn();
      });

    // Start the scroll
    animationFrame = this.window.requestAnimationFrame(scrollFn);
  }

  private get scrollTopValue(): number {
    return this.document.documentElement.scrollTop;
  }

  private get scrollLeftValue(): number {
    return this.document.documentElement.scrollLeft;
  }

  /**
   * Gets element coordinates relative to document
   */
  private getElementOffsetY(element: Element): number {
    return element.getBoundingClientRect().top + this.scrollTopValue;
  }

}
