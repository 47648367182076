import { environment } from '@environment';
import { CurrencyCode } from '@shared/currency/model/currency-code.model';
import { LocaleConstants } from '@shared/platform/locale.constants';
// eslint-disable-next-line import/no-restricted-paths
import { currencyByRegistrationDomain } from '@section/my-aukro/module/my-account/bill/bill.const';
import { DEFAULT_CURRENCY } from '@shared/currency/const/currency.constants';
import { UserInterestStatisticsDto } from '@api/generated/defs/UserInterestStatisticsDto';
import isNil from 'lodash-es/isNil';
import { DomainCode } from '@shared/platform/domain.model';
import { ICULocale } from '@shared/platform/icu-locale.model';
import { HttpError } from '@shared/rest/model/http-error';
import { Nil } from '@util/helper-types/nil';
import { RegistryCountryItemDto } from '@api/generated/defs/RegistryCountryItemDto';
import { Constants } from '@shared/constants';
import { Lang } from './lang.const';

// TODO: [PDEV-25853] - check/refactor
// @ts-ignore
const domainEnvironmentMap: Record<DomainCode, string> = {
  CZ: environment.APP_HOST,
  SK: environment.HOST_FRONTEND_SK,
};

// TODO(PDEV-20494)
// TODO: [PDEV-25853] - more domains
export class DomainUtils {

  public static resolveLocale(val: string): ICULocale {
    return !isNil(val) && (environment.HOST_FRONTEND_SK === val || environment.HOST_INT_FRONTEND_SK === val) ?
      LocaleConstants.SK_LOCALE :
      LocaleConstants.CZ_LOCALE;
  }

  public static getLocaleByCountry(domain: DomainCode): ICULocale | null {
    return LocaleConstants.DOMAIN_LIST.find((locale) => locale?.country === domain);
  }

  public static userIsNotCZ(actualStatistics: UserInterestStatisticsDto): boolean {
    return actualStatistics?.registrationDomainCode !== 'CZ';
  }

  public static userIsCZ(actualStatistics: UserInterestStatisticsDto): boolean {
    return actualStatistics?.registrationDomainCode === 'CZ';
  }

  public static getRegistrationDomainId(actualStatistics: UserInterestStatisticsDto): CurrencyCode {
    return actualStatistics ? currencyByRegistrationDomain[actualStatistics.registrationDomainCode].id : DEFAULT_CURRENCY.code;
  }

  public static getRegistrationDomainCurrencySuffix(actualStatistics: UserInterestStatisticsDto): string {
    return actualStatistics ? currencyByRegistrationDomain[actualStatistics.registrationDomainCode].value : DEFAULT_CURRENCY.suffix;
  }

  public static getRegistrationDomainMinPayment(actualStatistics: UserInterestStatisticsDto): number {
    return actualStatistics ? currencyByRegistrationDomain[actualStatistics.registrationDomainCode].minOnlinePayment : 3;
  }

  private static getCountryByDomainCode(domainCode: DomainCode): RegistryCountryItemDto {
    if (isNil(domainCode)) {
      return Constants.DEFAULT_COUNTRY;
    }
    return Constants.DEFAULT_COUNTRY_LIST.find(country => country.code === domainCode) ?? Constants.DEFAULT_COUNTRY;
  }

  public static getCountryIdByDomainCode(domainCode: DomainCode): number {
    return this.getCountryByDomainCode(domainCode).id;
  }

  /**
   * In case of cross domain error we need to extract users reg domain code from arguments
   * @param httpError
   * @private
   */
  public static resolveDomainCodeFromError(httpError: HttpError): DomainCode | Nil {
    return httpError?.body?.errors[0]?.arguments[0] as DomainCode;
  }

  public static getHostnameDomain(hostname: string): Lang {
    if (hostname.includes(domainEnvironmentMap['CZ'])) {
      return 'cs';
    } else if (hostname.includes(domainEnvironmentMap['SK'])) {
      return 'sk';
    }
    return 'cs';
  }

}
