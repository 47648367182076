import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationSource } from '@common/translations/model/translation-source';

@Component({
  selector: 'auk-facebook-button',
  templateUrl: 'facebook-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacebookButtonComponent {

  @Input()
  public buttonText: TranslationSource;

  @Input()
  public buttonDisabled: boolean = false;

  @Output()
  public facebookButtonClick: EventEmitter<void> = new EventEmitter();

  public onFacebookButtonClick(): void {
    this.facebookButtonClick.emit();
  }

}
