import isNil from 'lodash-es/isNil';
import isString from 'lodash-es/isString';
import round from 'lodash-es/round';
import { Nil } from '@util/helper-types/nil';
import { StringUtils } from '@util/util/string.utils';

export class NumberUtils {

  private static FLOAT_REGEX: RegExp = /^\d*[.,]?\d*$/;
  private static DIGITS_REGEX: RegExp = /^\d+$/;
  private static FLOAT_WITH_MAX_2_DECIMALS: RegExp = /^\d*[.,]?\d{0,2}$/;

  private static DEFAULT_ROUND_UP_PRECISISON = 2;
  private static DEFAULT_ROUND_DOWN_PRECISISON = 2;

  public static containsOnlyDigits(value: number | string): boolean {
    return this.DIGITS_REGEX.test(value.toString());
  }

  public static isFloat(value: number | string): boolean {
    return this.FLOAT_REGEX.test(value.toString());
  }

  public static isFloatWithMax2Decimals(value: number | string): boolean {
    return this.FLOAT_WITH_MAX_2_DECIMALS.test(value.toString());
  }

  /**
   * Parses string to an integer. If the input string is not a strictly valid integer, returns null
   * @param value input string
   * @returns - parsed number
   * @example
   * // returns 1
   * NumberUtils.parseIntegerStrictly('1')
   * NumberUtils.parseIntegerStrictly(' 1 ')
   * NumberUtils.parseIntegerStrictly(1)
   * @example
   * // returns null
   * NumberUtils.parseIntegerStrictly('1.1')
   * NumberUtils.parseIntegerStrictly(1.1)
   * NumberUtils.parseIntegerStrictly(null)
   * NumberUtils.parseIntegerStrictly('')
   */
  public static parseIntegerStrictly(value: string | number | Nil): number | Nil {
    if (this.isInteger(value)) {
      return value;
    }

    if (typeof value !== 'string' || StringUtils.isBlank(value)) {
      return null;
    }

    const result: number = Number(value);

    return this.isInteger(result) ? result : null;
  }

  /**
   * Parses string to a number. If the input string is not a valid number, returns default.
   * @param s input string
   * @param def default number
   */
  public static parseNumberOrDefault(s: string, def: number | null): number | Nil {
    if (!isString(s)) {
      return def;
    }

    const num = Number(s);

    if (isNaN(num)) {
      return def;
    }

    return num;
  }

  public static parseStringOrNumberOrFallback(value: string | number, fallbackValue: number | null = null): number | Nil {
    if (typeof value === 'number') {
      if (isNaN(value)) {
        return fallbackValue;
      }

      return value;
    }

    if (!isString(value) || StringUtils.isBlank(value)) {
      return fallbackValue;
    }

    const num = Number(value);

    if (isNaN(num)) {
      return fallbackValue;
    }

    return num;
  }

  /**
   * Get random number in specified range
   * @param min
   * @param max
   */
  public static getRandomNumber(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public static roundWithPrecision(num: number, precision: number = 2): number {
    if (isNil(num)) {
      return null;
    }

    return round(num, precision);
  }

  /**
   * Rounds number up
   * Examples:
   * 123.123456 -> 123.13
   * 123.0000000001 -> 123.01
   * @param num
   * @param precision
   */
  public static roundUp(num: number, precision: number = NumberUtils.DEFAULT_ROUND_UP_PRECISISON): number {
    if (isNil(num)) {
      return null;
    }

    const fixedMultiplication = this.roundingPrecisionLogic(num, precision);

    return Math.ceil(fixedMultiplication.value) / fixedMultiplication.precision;
  }

  /**
   * Rounds number down (mostly used for negative numbers)
   * Examples:
   * -123.123456 -> -123.13
   * -123.0000000001 -> -123.01
   * @param num
   * @param precision
   */
  public static roundDown(num: number, precision: number = NumberUtils.DEFAULT_ROUND_DOWN_PRECISISON): number {
    if (isNil(num)) {
      return null;
    }

    const fixedMultiplication = this.roundingPrecisionLogic(num, precision);

    return Math.floor(fixedMultiplication.value) / fixedMultiplication.precision;
  }

  private static roundingPrecisionLogic(num: number, precision: number): { value: number; precision: number } {
    precision = Math.pow(10, precision);

    // Mathematically round to fix multiplication error e.g. 18.26 * 100 = 1826.0000000000002
    return { value: round(num * precision, 7), precision };
  }

  public static countDecimals(value: number): number {
    if (isNil(value)) {
      return 0;
    }

    if ((value % 1) !== 0) {
      return value.toString().split('.')[1].length;
    }

    return 0;
  }

  /**
   * Return boolean whether given value is number && float
   * @param value - value to check
   */
  public static isIntegerOrFloat(value: unknown): value is number {
    if (typeof value !== 'number') {
      return false;
    }

    return this.isInteger(value) || !!(value % 1);
  }

  /**
   * Return boolean whether given value is integer
   * @param value - value to check
   */
  public static isInteger(value: unknown): value is number {
    if (typeof value !== 'number') {
      return false;
    }

    return Number.isInteger(value);
  }

  /**
   * Return boolean whether given number is float and not integer
   * @param value - value to check
   */
  public static isFloatAndNotInteger(value: unknown): value is number {
    if (typeof value !== 'number') {
      return false;
    }

    return value % 1 === 0;
  }

  /**
   * Removes last x digits from number
   * @param value - value from which the digits will be removed
   * @param digitsToRemove - digits to remove (default 1)
   */
  public static removeDigitsFromEnd(value: number, digitsToRemove: number = 1): number {
    return Number(value.toString().slice(0, -digitsToRemove));
  }

  /**
   * @param value
   * @returns decimal digits count
   */
  public static decimalDigitsCount(value: string | number): number {
    value = value.toString();

    if (!this.isFloat(value)) {
      return 0;
    }

    return value.toString().split('.')[1].length;
  }

  /**
   *
   * @param value
   * @returns separator symbol used, or Nil if there's no decimal separator
   */
  public static decimalSeparatorSymbol(value: string): '.' | ',' | Nil {
    if (value.includes('.')) {
      return '.';
    }

    if (value.includes(',')) {
      return ',';
    }

    return null;
  }

  /**
   *
   * 1. returned element is number representation of the given value
   * 2. returned element is the string representation of given value
   * @param integerValue
   * @param decimalValue
   * @param decimalSeparatorSymbol
   */
  public static createNumberFromIntegerAndDecimalPart(
    integerValue: string | number,
    decimalValue?: number | string,
    decimalSeparatorSymbol: ',' | '.' = ',',
  ): [string | number | Nil, string | Nil] {
    if (isNil(decimalValue)) {
      return [
        integerValue,
        `${ integerValue }${ decimalSeparatorSymbol }`,
      ];
    }

    return [
      this.parseStringOrNumberOrFallback(`${ integerValue }.${ decimalValue }`),
      `${ integerValue }${ decimalSeparatorSymbol }${ decimalValue }`,
    ];
  }

  /**
   * @returns whether numberToCheck is in given range
   */
  public static isNumberInRange(
    numberToCheck: number,
    minRange: number,
    maxRange: number,
    isInclusive: boolean = true,
  ): boolean {
    if (isInclusive) {
      return numberToCheck >= minRange && numberToCheck <= maxRange;
    } else {
      // exclusive
      return numberToCheck > minRange && numberToCheck < maxRange;
    }
  }

}
