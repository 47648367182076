import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserTaskLoginStepComponent } from './user-task-login-step.component';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Component({
  selector: 'auk-user-task-login-step-email-confirmation-registered-via-fb',
  templateUrl: 'user-task-login-step-email-confirmation-registered-via-fb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskLoginStepEmailConfirmationRegisteredViaFbComponent
  extends UserTaskLoginStepComponent<'EMAIL_CONFIRMATION_REGISTERED_VIA_FB'> {

  public isNativeIosApp: boolean = PlatformCommonService.isNativeIosApp;

  public loginViaFB(): void {
    this.resolve.emit({
      type: 'RESOLUTION_FB_LOAD',
      payload: {},
    });
  }

  public onBackClick(): void {
    this.resolve.emit({
      type: 'RESOLUTION_LOGIN_EMAIL',
      payload: {
        sourceAction: this.payload.sourceAction,
      },
    });
  }

}
