import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { UserAnnouncementStatusDto } from '@api/generated/defs/UserAnnouncementStatusDto';
import { UserInterestStatisticsDto } from '@api/generated/defs/UserInterestStatisticsDto';
import { UserTopAnnouncementsDto } from '@api/generated/defs/UserTopAnnouncementsDto';
import { AnnouncementCustomService } from '@shared/services/annoucement/announcement-custom.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { AppHeaderService } from '../../../../service/app-header.service';
import { UserHeaderControlComponent } from '../user-header-control/user-header-control.component';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { ColorCombinationId } from '@common/colors/model/color-combination-id';
import { HeaderItemModel } from '@shared/app-header/module/app-header-user-controls/component/header-item/model/header-item.model';

@Component({
  selector: 'auk-announcements-control',
  templateUrl: './announcements-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnnouncementsControlComponent extends UserHeaderControlComponent implements OnInit, OnDestroy {

  @HostBinding('class.hidden') public isControlHidden: boolean = true;
  @Input() public user: UserInterestStatisticsDto;
  public announcements: UserTopAnnouncementsDto;
  public displayedCount: string;

  protected isDropdownOpened: boolean = false;

  constructor(
    private announcementService: AnnouncementCustomService,
    private router: Router,
    private platformService: PlatformService,
    @Inject(WINDOW_OBJECT) window: Window,
    changeDetectorRef: ChangeDetectorRef,
    responsivenessService: ResponsivenessService,
    ngZoneUtilService: NgZoneUtilService,
  ) {
    super(window, changeDetectorRef, responsivenessService, ngZoneUtilService);
  }

  public ngOnInit(): void {
    if (this.platformService.isServerAndMobileAndNotBot) {
      return;
    }

    this.announcementService.getAnnouncementsPreview()
      .pipe(
        filter((data) => !!data),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: UserTopAnnouncementsDto) => {
        this.announcements = data;
        this.isControlHidden = !data.unreadCount;
        this.displayedCount = AppHeaderService.getBadgeCount(data.unreadCount);
        this.changeDetectorRef.markForCheck();
      });
  }

  public onAnnouncementsClick(): void {
    void this.router.navigate(['/moje-aukro/muj-ucet/sdeleni']);
  }

  public showAnnouncementPopup(announcement: UserAnnouncementStatusDto): void {
    this.closeDropdown();
    this.announcementService.openAnnouncementPopup(announcement);
  }

  public trackByAnnouncementId(index: number, announcement: UserAnnouncementStatusDto): number {
    return announcement.id || 0;
  }

  protected override measurePopoverShow(): void {
    // mo tracking not implemented
  }

  protected setDropdownOpenState(isOpen: boolean): void {
    this.isDropdownOpened = isOpen;
  }

  protected get headerItemData(): HeaderItemModel {
    return {
      badgeText: this.displayedCount,
      icon: {
        source: 'mail',
        type: 'MATERIAL',
        colorCombination: this.getColorCombination,
      },
    };
  }

  protected get getColorCombination(): ColorCombinationId {
    return this.isDropdownOpened ? 'SILVERSTEIN_200_900' : 'SILVERSTEIN_CONTRAST_200_600';
  }

}
