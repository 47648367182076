import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Translate2Module } from '@common/translations/translate2.module';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { RouterModule } from '@angular/router';
import { TabMenuItemModel } from '../model/tab-menu-item.model';
import { DynamicColorDirective } from '@common/dynamic-color/dynamic-color.directive';

import { TranslationSource } from '@common/translations/model/translation-source';
import isNil from 'lodash-es/isNil';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { MouseUtils } from '@util/util/mouse.utils';
import { LinkFromHrefDirective } from '@common/routing/directive/link-from-href.directive';

@Component({
  selector: 'auk-tab-menu-item',
  templateUrl: './tab-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    RouterModule,
    Translate2Module,
    IconComponent,
    DynamicColorDirective,
    MatTooltipModule,
    LinkFromHrefDirective,
  ],
})
export class TabMenuItemComponent<T> {

  @Input() public tab: TabMenuItemModel<T>;
  @Input() public showTooltip: boolean = true;

  @Output() public tabClick: EventEmitter<T> = new EventEmitter<T>();
  @Output() public tabHover: EventEmitter<TabMenuItemModel<T>> = new EventEmitter<TabMenuItemModel<T>>();
  @Output() public tabHoverEnd: EventEmitter<TabMenuItemModel<T>> = new EventEmitter<TabMenuItemModel<T>>();

  public onTabClick(event: MouseEvent): void {
    // if ctrl or meta key is pressed and link is not nil, do nothing (so the link will be opened in the new tab)
    // and nothing more happens
    if (!MouseUtils.canDoAppNavigation(event) && isNotNil(this.tab.url)) {
      return;
    }

    event.preventDefault();

    this.tabClick.emit(this.tab?.data);
  }

  public onTabHover(): void {
    this.tabHover.emit(this.tab);
  }

  public get tooltip(): TranslationSource {
    return this.tab?.tooltip;
  }

  public get tooltipDisabled(): boolean {
    return isNil(this.tooltip?.defaultValue) || this.showTooltip === false;
  }

  public onTabHoverEnd(): void {
    this.tabHoverEnd.emit(this.tab);
  }

  public get doesLabelExist(): boolean {
    if (this.tab?.label) {
      return !!(this.tab.label?.defaultValue || this.tab.label?.key);
    }
  }

}
