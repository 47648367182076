import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { startWith, takeUntil } from 'rxjs/operators';

import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

import { AppInitializer } from '@util/helper-types/initializer.interface';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

@Injectable()
export class AppSetCorrectCssValueInitializer extends NgUnsubscribe implements AppInitializer {

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly platformCommonService: PlatformCommonService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public init(): void {
    this.setCorrectCssVhValue();
  }

  /**
   * Sets correct vh value initially and on resize
   * Fixes 100vh problem in iOS and other mobile devices which  counts bottom bar on mobile devices to viewport height
   */
  private setCorrectCssVhValue(): void {
    if (!this.platformCommonService.isBrowser) {
      return;
    }

    this.ngZoneUtilService.fromEventOut$(this.window, 'resize')
      .pipe(
        startWith(null),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        const vh: number = this.window.innerHeight / 100;
        this.document.documentElement.style.setProperty('--vh', `${ vh }px`);
      });
  }

}
