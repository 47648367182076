import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';

export class TileConstant {

  public static readonly ARROW_ICON: IconModel = {
    source: 'right-arrow',
    type: 'SVG',
    size: 'LG',
    colorCombination: 'PRIMARY_CONTRAST',
  };

  public static readonly CATEGORY_FILE_ICON: IconModel = {
    source: 'chevron-box',
    type: 'SVG',
    size: 'LG',
    colorCombination: 'PRIMARY_CONTRAST',
  };

  public static readonly DEFAULT_COLUMNS_ON_MOBILE: number = 2;
  public static readonly DEFAULT_MIN_TILE_WIDTH: string = '11.5rem';

}
