import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';

const DISABLED: boolean = false;
const ITEMS_ARE_OBJECTS: boolean = false;

type OptionType = (SelectOption | string | number);

@Component({
  selector: 'auk-aukro-select',
  templateUrl: './select.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent implements OnChanges {

  @Input() public arrayOfObjects: boolean = ITEMS_ARE_OBJECTS;
  @Input() public disabled: boolean = DISABLED;
  @Input() public optionName: string = 'name';
  @Input() public options: OptionType[] = [];
  @Input() public placeholder: string = null;
  @Input() public selected: OptionType;
  @Output() public optionSelected = new EventEmitter<OptionType>();
  @Output() public reset = new EventEmitter<boolean>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.setSelected(changes.selected.currentValue);
    }
  }

  public setSelected(selected: SelectOption): void {
    if (this.arrayOfObjects) {
      this.options?.forEach((option: SelectOption) => option.selected = false);
      if (selected) {
        selected.selected = true;
      }
    }
    this.selected = selected;
  }

  public onOptionSelect(option: SelectOption): void {
    this.setSelected(option);
    this.optionSelected.emit(option);
  }

  public onSelectChange(option: string): void {
    if (option === '?') {
      this.onOptionSelect(null);
    } else {
      this.onOptionSelect(this.options[option]);
    }
  }

  public showOptionName<R = string | number | Date | SelectOption>(option: OptionType): R {
    return (this.arrayOfObjects ? option[this.optionName] : option) as R;
  }

  public selectedOption(option: OptionType): boolean {
    return this.arrayOfObjects
      ? !!((option as SelectOption).defaultValue
        || (option as SelectOption).selected
        || (this.selected && option[this.optionName] === this.selected[this.optionName]))
      : option === this.selected;
  }

}

export interface SelectOption {
  code?: string;
  defaultValue?: boolean;
  from?: string | Date;
  key?: string | boolean;
  name?: string;
  selected?: boolean;
  to?: string | Date;
  value?: string | number | Date;
  disabled?: boolean;
}
