import { Directive, ElementRef, HostBinding, Input, OnChanges } from '@angular/core';
import { Nil } from '@util/helper-types/nil';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { RoutingService } from '@common/routing/service/routing.service';
import { merge, Subject } from 'rxjs';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { TargetAttrType } from '@common/routing/model/target-attr.type';

/**
 * Use if you have only href url, and you want to do "app navigation" (without reload) if the hostname of the given href url equals
 * current hostname, otherwise do normal web navigation with href
 */
@Directive({
  selector: '[aukLinkFromHref]a',
  standalone: true,
})
export class LinkFromHrefDirective extends NgUnsubscribe implements OnChanges {

  /**
   * Link, which will be applied to href attribute
   */
  @Input({ required: true }) public aukLinkFromHref: string | Nil;
  /**
   * Whether, should link open in new tab
   */
  @Input() public openInNewTab: boolean = false;
  /**
   * If set to false, it won't initialize click listener for in app navigation
   * e.g. for cases, where we handle it differently
   */
  @Input() public enableInAppNavigation: boolean = true;

  @HostBinding('attr.target') protected targetAttr: TargetAttrType = '_self';
  @HostBinding('attr.href') protected hrefAttr: string | Nil;

  private readonly linkFromHrefChange$: Subject<void> = new Subject<void>();

  constructor(
    private readonly routingService: RoutingService,
    private readonly elementRef: ElementRef<HTMLAnchorElement>,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (changes.aukLinkFromHref || changes.openInNewTab) {
      this.linkFromHrefChange$.next();
      this.processHrefLink();
    }
  }

  private processHrefLink(): void {
    const routingInfo = this.routingService.getHrefRoutingResult(
      this.aukLinkFromHref,
      this.openInNewTab,
    );

    // set attributes
    this.hrefAttr = routingInfo?.href;
    this.targetAttr = routingInfo?.target;

    if (this.enableInAppNavigation) {
      // create click listener for in-app navigation
      this.routingService.createAnchorClickListener(
        routingInfo,
        this.elementRef.nativeElement,
        merge(
          this.linkFromHrefChange$,
          this.ngUnsubscribe,
        ),
      );
    }
  }

}
