import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  WatchEmailVerifiedBasicDataViewComponent,
} from './component/watch-email-verified-basic-data-view/watch-email-verified-basic-data-view.component';
import {
  WatchEmailVerifiedNoBasicDataViewComponent,
} from './component/watch-email-verified-no-basic-data-view/watch-email-verified-no-basic-data-view.component';
import {
  WatchNoEmailVerifiedNoBasicDataViewComponent,
} from './component/watch-no-email-verified-no-basic-data-view/watch-no-email-verified-no-basic-data-view.component';
import {
  WatchNoEmailVerifiedBasicDataViewComponent,
} from './component/watch-no-email-verified-basic-data-view/watch-no-email-verified-basic-data-view.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CommonPipesModule } from '@shared/legacy/pipe/common-pipes.module';
import { DialogWrapperModule } from '@common/dialog-wrapper/dialog-wrapper.module';
import { HintDisablerModule } from '@shared/hint-disabler/hint-disabler.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    ButtonComponent,
    DialogWrapperModule,
    CommonPipesModule,
    HintDisablerModule,
    Translate2Module,
    InnerHtmlDirective,
  ],
  declarations: [
    WatchEmailVerifiedBasicDataViewComponent,
    WatchEmailVerifiedNoBasicDataViewComponent,
    WatchNoEmailVerifiedNoBasicDataViewComponent,
    WatchNoEmailVerifiedBasicDataViewComponent,
  ],
})
export class UserActionViewModule {
}
