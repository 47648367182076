import { ItemSearchOrderEnumClickListingEventDto, SortOrderClickListingEventDtoEnum } from '@api/generated/defs/ClickListingEventDto';
import { ItemAttributeFilterDto } from '@api/generated/defs/ItemAttributeFilterDto';
import {
  ItemSearchOrderEnumShowListingEventDto,
  ItemSearchProviderEnumShowListingEventDto,
  SortOrderShowListingEventDtoEnum,
} from '@api/generated/defs/ShowListingEventDto';
import { ListingLayoutType } from '@shared/listing/model/listing-layout.type';
import { MeasurementAukroSplitGroupValueModel } from '@shared/services/personalization/model/measurement-aukro-split-group-value.type';
import { ClickEventPropertiesDto } from '@api/generated/defs/ClickEventPropertiesDto';
import { PersonalizationConstants } from '@shared/services/personalization/const/personalization.constants';

// default value of cookie for development environments (TST,DEV,localhost...)
export const CATEGORY_RECOMMENDER_COOKIE_DEV = 'A1';

export const CATEGORY_RECOMMENDER_MO_CODE: MeasurementCategoryRecommenderType = 'CATEGORY_RECOMMENDER';

export const CATEGORY_RECOMMENDER_UPDATE_MO_CODE: MeasurementCategoryRecommenderType = 'CATEGORY_RECOMMENDER_UPDATE';

export type MeasurementCategoryRecommenderType = 'CATEGORY_RECOMMENDER' | 'CATEGORY_RECOMMENDER_UPDATE';

export const MEASUREMENT_AUKRO_SPLIT_GROUP_VALUES: MeasurementAukroSplitGroupValueModel = {
  YUSP_NO_RESULT: 'aukro-yusp_no_result',
  YUSP_NO_RESULT_TOO_LONG_PROCESSING: 'aukro-yusp_no_result-too_long_processing',
  YUSP_NOT_CALLED: 'aukro-yusp_not_called',
  YUSP_NOT_CALLED_CONSENT_NOT_DECIDED: 'aukro-yusp_not_called-consent_not_decided',
  YUSP_NOT_CALLED_CONSENT_REJECTED: 'aukro-yusp_not_called-consent_rejected',
  YUSP_NOT_CALLED_IS_SERVER: 'aukro-yusp_not_called-is_server',
  YUSP_NOT_CALLED_MISSING_GRAVITY_SDK: 'aukro-yusp_not_called-missing_gravity_sdk',
  YUSP_NOT_CALLED_NOT_CZ_LOCALE: 'aukro-yusp_not_called-not_cz_locale',
  AUKRO_MISSING_RECO1_COOKIE: 'aukro-missing_reco1_cookie',
  YUSP_NOT_CALLED_SPLIT_GROUP_AUKRO: PersonalizationConstants.MEASUREMENT_SPLIT_GROUP_VALUE_AUKRO,
};

export const MEASUREMENT_LISTING_SPLIT_GROUP_KEY = 'listing';
export const MEASUREMENT_WSRV1_SPLIT_GROUP_KEY = 'wsrv-1';
export const MEASUREMENT_WSRV2_SPLIT_GROUP_KEY = 'wsrv-2';
export const MEASUREMENT_WSRV3_SPLIT_GROUP_KEY = 'wsrv-3';
export const MEASUREMENT_WSRV4_SPLIT_GROUP_KEY = 'wsrv-4';
export const MEASUREMENT_WSRV5_SPLIT_GROUP_KEY = 'wsrv-5';
export const MEASUREMENT_WSRV6_SPLIT_GROUP_KEY = 'wsrv-6';
export const MEASUREMENT_WSRV7_SPLIT_GROUP_KEY = 'wsrv-7';
export const MEASUREMENT_WSRV8_SPLIT_GROUP_KEY = 'wsrv-8';
export const MEASUREMENT_WSRV9_SPLIT_GROUP_KEY = 'wsrv-9';
export const MEASUREMENT_WSRV10_SPLIT_GROUP_KEY = 'wsrv-10';
export const MEASUREMENT_WSRV11_SPLIT_GROUP_KEY = 'wsrv-11';
export const MEASUREMENT_WSRV12_SPLIT_GROUP_KEY = 'wsrv-12';
export const MEASUREMENT_SEARCH_SPLIT_GROUP_KEY = 'search';
export const MEASUREMENT_SUGGEST_SPLIT_GROUP_KEY = 'suggest';

export type ListingCode =
  'L_CATEGORY' |
  'L_SELLER_ITEMS' |
  'L_SELLER_SEARCH' |
  'L_SEARCH' |
  'L_FAVOURITE_SEARCH' |
  'L_FROM_1CZK' |
  'L_ENDING_SOON' |
  'L_HOT_AUCTIONS' |
  'L_SPECIAL_300CZK' |
  'L_LANDING_PAGE' |
  'L_ITEMS_COLLECTION_DETAIL' |
  'L_SPECIAL_NEW_ITEMS' |
  'L_SPECIAL_POPULAR_ITEMS' |
  'L_SPECIAL_RETAIL_PRICE' |
  'L_SPECIAL_PREMIUM' |
  'L_SPECIAL_CHARITY';

export interface MeasurementListingClick {
  itemId: number;
  categoryId?: number;
  elementRank: number;
  measurementObjectCode: ListingCode;
  boldTitle: boolean;
  featured: boolean;
  highlight: boolean;
  itemSearchOrderEnum: ItemSearchOrderEnumClickListingEventDto;
  sortOrder: SortOrderClickListingEventDtoEnum;
  page: number;
  price: number;
  watchingCount: number;
  bidderBuyerCount: number;
  searchTerm?: string;
  properties?: ClickEventPropertiesDto;
}

export interface MeasurementListingShow {
  measurementObjectCode: ListingCode;
  categoryId: number;
  featuredCount: number;
  itemSearchOrderEnum: ItemSearchOrderEnumShowListingEventDto;
  listingLayout: ListingLayoutType;
  sellerId: number;
  sortOrder: SortOrderShowListingEventDtoEnum;
  unfeaturedCount: number;
  page: number;
  searchTerm: string;
  items: number[];
  searchAll: boolean;
  buyNowActive: boolean;
  auction: boolean;
  finished: boolean;
  priceMin: number;
  priceMax: number;
  postcode: string;
  distance: number;
  freeShipping: boolean;
  personalPickup: boolean;
  startingAfterRel: string;
  endingBeforeRel: string;
  originalStartingAfter: string;
  originalStartingBefore: string;
  originalExposeAfter: string;
  originalExposeBefore: string;
  itemAttributeFilterDtoList: ItemAttributeFilterDto[];
  totalCount: number;
  paymentViaAukro: boolean;
  cashOnDelivery: boolean;
  provider: ItemSearchProviderEnumShowListingEventDto;
  listingUrl: string;
}
