<div class="header">
  @if (!announcement.announcementDto.important) {
    <i class="material-icons text-blue m-r-3">
      info
    </i>
  }
  @if (announcement.announcementDto.important) {
    <i class="material-icons text-red m-r-3">
      warning
    </i>
  }
  <div>
    <span class="heading">
      {{announcement.announcementDto.subject}}
    </span>
    <span class="date m-t-2">
      {{announcement.announcementDto.publishDate|date:'d. MMMM y'}}
    </span>
  </div>
</div>
<div class="content" [aukInnerHtml]="announcement.announcementDto.body"></div>
<div class="controls">
  <a (click)="removeAnnouncement()"  class="btn-secondary fluid-max-tablet">{{ 'ANNOUNCEMENT_POPUP_COMPONENT_HTML_1' | translate }}</a>
  @if (announcement.read) {
    <a (click)="changeReadStatus(false)" class="btn-secondary fluid-max-tablet">{{ 'ANNOUNCEMENT_POPUP_COMPONENT_HTML_2' | translate }}</a>
  }
  @if (!announcement.read) {
    <a (click)="closeWithoutChanges()" class="btn-secondary fluid-max-tablet">{{ 'ANNOUNCEMENT_POPUP_COMPONENT_HTML_3' | translate }}</a>
  }
  <a (click)="changeReadStatus(true)" class="btn-primary fluid-max-tablet">{{ 'ANNOUNCEMENT_POPUP_COMPONENT_HTML_4' | translate }}</a>
</div>
