@if (!isNativeApp) {
  @if ((newVersionInfo$ | async); as newVersionInfo) {
    <div
      class="new-version-info-panel"
      [style.background-color]="newVersionInfo.panelBgColor"
      [style.color]="newVersionInfo.panelTextColor"
      >
      <span [aukInnerHtml]="{key: 'NEW_VERSION_INFO_TEXT'}"
      ></span>
      <span class="tw-underline tw-cursor-pointer"
        [aukInnerHtml]="{key: 'NEW_VERSION_INFO_UPDATE_ACTION_TEXT'}"
        (click)="onUpdateToNewVersionClick()"
      ></span>
    </div>
  }
}
