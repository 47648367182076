import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';

// TODO(PDEV-15881) - rework this to be more configurable. Might be better as a directive.
@Component({
  selector: 'auk-skeleton-loading',
  template: '',
  styleUrls: [ 'skeleton-loading.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SkeletonLoadingComponent extends ColorCombinationInputComponent {

  @Input()
  @HostBinding('style.height')
  public height: string;

}
