import { Directive, Input, Renderer2, ElementRef, OnChanges } from '@angular/core';
import { BaseDestroy } from '@util/base-class/base-destroy.class';
import { AccessibilityService } from '../service/accessibility.service';
import { Subscription, takeUntil } from 'rxjs';
import { AccessiblityTranslationKey } from '../model/accessibility-translation-key';
import { Nil } from '@util/helper-types/nil';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import isNil from 'lodash-es/isNil';
import { StringUtils } from '@util/util/string.utils';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Directive({
  selector: '[aukAccessibilityId]',
  standalone: true,
})
export class AccessibilityDirective extends BaseDestroy implements OnChanges {

  @Input({ required: true }) public aukAccessibilityId: AccessiblityTranslationKey;

  private subscription: Subscription | Nil = null;

  constructor(
    private readonly renderer2: Renderer2,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly accessibilityService: AccessibilityService,
  ) {
    super();
  }

  public ngOnChanges(simpleChanges: AukSimpleChanges<typeof this>): void {
    if (PlatformCommonService.isNativeApp && !PlatformCommonService.isProductionMode) {
      return;
    }

    if (simpleChanges.aukAccessibilityId) {
      this.subscription?.unsubscribe();

      if (isNil(this.aukAccessibilityId)) {
        this.setAttr(null);
      } else {
        this.subscription = this.accessibilityService.getTranslated$(this.aukAccessibilityId)
          .pipe(
            takeUntil(this.destroy$),
          )
          .subscribe((translatedText) => {
            this.setAttr(translatedText);
          });
      }
    }
  }

  private setAttr(attrValue: string | Nil): void {
    if (StringUtils.isBlank(attrValue)) {
      this.renderer2.removeAttribute(this.elementRef.nativeElement, 'aria-label');
    } else {
      this.renderer2.setAttribute(this.elementRef.nativeElement, 'aria-label', attrValue);
    }
  }

}

