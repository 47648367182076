import { Injectable } from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CurrencyCode } from '../model/currency-code.model';
import { Observable, of } from 'rxjs';
import { UserCurrencyPreferenceService } from './user-currency-preference.service';
import isNil from 'lodash-es/isNil';
import { DomainService } from '@shared/platform/domain.service';
import { CurrencyUtil } from '@shared/currency/util/currency.util';

@Injectable({
  providedIn: 'root',
})
export class CurrencyResolverService extends NgUnsubscribe {

  constructor(private readonly userCurrencyPreferenceService: UserCurrencyPreferenceService,
              private readonly domainService: DomainService,
  ) {
    super();
  }

  public resolvePreferredCurrency(preferredCurrency?: CurrencyCode): Observable<CurrencyCode> {

    if (isNil(preferredCurrency)) {
      preferredCurrency = CurrencyUtil.getDefaultCurrencyByLocale(this.domainService.locale);
    }

    this.userCurrencyPreferenceService.preferredCurrencyCode = preferredCurrency;
    return of(this.userCurrencyPreferenceService.preferredCurrencyCode);

  }

}

