import { Injectable } from '@angular/core';
import filter from 'lodash-es/filter';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { PayUPaymentService } from '@api/generated/api/PayUPayment';
import { PayByLinkDto, PaymentMethodsDto } from '@api/generated/model';
import { PvaState, PvaStateGlobal, ViewerType } from '../../../../typings/original/internal';
import { CacheAware } from '@common/cache/model/cache-aware';
import { CacheService } from '@common/cache/service/cache.service';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { CacheScope } from '@common/cache/model/cache-scope';
import { DateUtils } from '@util/util/date.utils';

@Injectable({
  providedIn: 'root',
})
export class PaymentService implements CacheAware {

  constructor(
    public readonly cacheService: CacheService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly payUPaymentService: PayUPaymentService,
  ) {
  }

  public getPayUPaymentMethods(): Observable<PayByLinkDto[]> {
    return this.payUPaymentService.paymentMethods({}).pipe(
      map((result: PaymentMethodsDto) => result.payByLinks),
      map((payByLinks: PayByLinkDto[]) => filter(payByLinks, { status: 'ENABLED' })));
  }

  /**
   * @param payByLinks
   * @returns only enabled payment methods
   */
  public getEnabledBankTransferPaymentMethods(payByLinks: PayByLinkDto[]): PayByLinkDto[] {
    return payByLinks.filter((payByLink: PayByLinkDto) => payByLink.status === 'ENABLED');
  }

  /**
   * @returns PVA states information stored in system parameter config element.
   * @param pvaStateViewer - type of viewer (buyer or seller)
   * @param withGrouped - whether to show only grouped states or all states separately (and not grouped)
   */
  @Cacheable({
    cacheScope: CacheScope.PROCESS,
    timeToLiveServer: DateUtils.convertMinutesToMilliseconds(10),
    timeToLiveClient: DateUtils.convertMinutesToMilliseconds(10),
    keyResolver: (viewerType, withGrouped) => `PaymentService#getPvaStates#pvaStateViewer-${ viewerType }#withGrouped-${ withGrouped }`,
  })
  public getPvaStates(pvaStateViewer: ViewerType, withGrouped: boolean = true): Observable<PvaState[]> {
    return this.configuratorCacheService.enumsContent<PvaStateGlobal>('PVA_STATES2')
      .pipe(
        take(1),
        // Retrieve only active states
        map((pvaStatesGlobal: PvaStateGlobal[]) => pvaStatesGlobal.filter(state => state.active)),
        // Postprocessing configuration object into objects used in FE app
        map((pvaStatesGlobal: PvaStateGlobal[]) => {
          let pvaStates: PvaState[] = [];

          pvaStates = pvaStatesGlobal.reduce((acc, pvaStateGlobal) => {
            // filter out grouped states, if we don't want them
            if (!withGrouped && pvaStateGlobal.groupedByWithState) {
              return acc;
            }

            const filterStates: string[] = [];
            if (withGrouped) {

              // In case that this state is grouped by with some previous one we do not want to filter by this state,
              // we want to use parent state to filter this state too
              if (pvaStateGlobal.groupedByWithState) {

                const parentState: PvaState = acc
                  .find((pvaState: PvaStateGlobal) => pvaState.code === pvaStateGlobal.groupedByWithState);

                if (parentState) {
                  parentState.filterStates.push(pvaStateGlobal.code);
                }
              } else {
                // if there is grouping, state should filter itself (and later one there can be added another states)
                filterStates.push(pvaStateGlobal.code);
              }
            }

            let name: string;
            let tooltip: string;
            switch (pvaStateViewer) {
              case ViewerType.BUYER:
                name = pvaStateGlobal.nameForBuyer;
                tooltip = pvaStateGlobal.tooltipForBuyer;
                break;
              case ViewerType.SELLER:
                name = pvaStateGlobal.nameForSeller;
                tooltip = pvaStateGlobal.tooltipForSeller;
                break;
              default:
                name = pvaStateGlobal.name;
            }

            return [
              ...acc,
              {
                id: pvaStateGlobal.id,
                code: pvaStateGlobal.code,
                name,
                tooltip,
                filterStates,
              },
            ];
          }, [] as PvaState[]);

          return pvaStates;
        }),
      );
  }

}
