<div class="text-right">
  <a aukPreventDefaultLink [mat-dialog-close]="null">
    <i class="material-icons big">close</i>
  </a>
</div>

<auk-success-message></auk-success-message>

<auk-two-factor-verification [requestCallback]="verifyPhoneCallback" (goBackClicked)="onGoBackClicked()">
  <div class="text-center">
    <h1 class="line-height m-b-3">
      @switch (dialogData?.context) {
        @case ('BID') {
          <span
            [aukInnerHtml]="{key:'PHONE_NUMBER_VERIFICATION_ENFORCED_DIALOG_BID_HEADING'}"
          ></span>
        }
        @case ('ADD_TO_CART') {
          <span
            [aukInnerHtml]="{key:'PHONE_NUMBER_VERIFICATION_ENFORCED_DIALOG_ADD_TO_CART_HEADING'}"
          ></span>
        }
        @case ('EXPOSE') {
          <span
            [aukInnerHtml]="{key:'PHONE_NUMBER_VERIFICATION_ENFORCED_DIALOG_EXPOSE_HEADING'}"
          ></span>
        }
        @case ('REGISTER_TO_ITEMS_COLLECTION') {
          <span
            [aukInnerHtml]="{key:'PHONE_NUMBER_VERIFICATION_ENFORCED_DIALOG_REGISTER_TO_ITEMS_COLLECTION_HEADING'}"
          ></span>
        }
      }
    </h1>

    <i class="material-icons !tw-text-matter-accent-primary huge m-b-3">
      security
    </i>

    <div class="line-height m-b-4">
      <div [aukInnerHtml]="{key:'PHONE_NUMBER_VERIFICATION_ENFORCED_DIALOG_COMMON_INFO_TEXT'}"
      ></div>
      @if (dialogData?.enforced) {
        <div
          [aukInnerHtml]="{key:'PHONE_NUMBER_VERIFICATION_ENFORCED_DIALOG_ENFORCED_INFO_TEXT'}"
        ></div>
      }
    </div>

    @if (form) {
      <form
        [formGroup]="form"
        >
        <div>
          <auk-phone-field class="m-x-auto w-56-min-tablet fluid-max-tablet m-b-1"
            [control]="phoneControl"
            [aukLimitMaxLength]="15"
            aukOnlyNumberInput
          ></auk-phone-field>
          <mat-error class="tw-block tw-h-3">
            {{ phoneErrorMessage | translateSource }}
          </mat-error>
        </div>
        <button type="submit"
          class="btn-primary w-56-min-tablet fluid-max-tablet m-t-2"
          [disabled]="!form.valid || isSubmitting"
          [class.loading]="isSubmitting"
          [aukInnerHtml]="{key:'PHONE_NUMBER_VERIFICATION_ENFORCED_DIALOG_SEND_BUTTON'}"
          (click)="onSubmit()"
        ></button>
      </form>
    }

    <p class="line-height m-t-3"
      [aukInnerHtml]="{key:'PHONE_NUMBER_VERIFICATION_ENFORCED_DIALOG_CONTACT_TEXT'}"
    ></p>
  </div>
</auk-two-factor-verification>
