import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopPanelComponent } from './component/top-panel.component';
import { CommonDirectivesModule } from '@shared/legacy/directive/common-directives.module';
import { AlertBannerModule } from '@shared/legacy/component/alert-banner/alert-banner.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonPipesModule } from '@shared/legacy/pipe/common-pipes.module';
import { DisclaimersModule } from '@shared/disclaimers/disclaimers.module';
import { HideOnMobileSsrDirectiveModule } from '@shared/legacy/directive/hide-on-mobile-ssr/hide-on-mobile-ssr.directive.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    CommonDirectivesModule,
    AlertBannerModule,
    TranslateModule,
    CommonPipesModule,
    DisclaimersModule,
    HideOnMobileSsrDirectiveModule,
    Translate2Module,
    InnerHtmlDirective,
  ],
  declarations: [
    TopPanelComponent,
  ],
  exports: [
    TopPanelComponent,
  ],
})
export class TopPanelModule {
}
