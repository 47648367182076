import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { IconButtonComponent } from '@common/ui-kit/component/icon-button/component/icon-button.component';
import { ColorCombinationId } from '@common/colors/model/color-combination-id';
import { IconSize } from '@common/ui-kit/component/icon/component/model/icon-size';

@Component({
  selector: 'auk-navigation-icon-button',
  standalone: true,
  templateUrl: './navigation-icon-button.component.html',
  imports: [
    IconButtonComponent,
    NgClass,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationIconButtonComponent {

  @Input() public direction: 'LEFT' | 'RIGHT' = 'LEFT';

  @Input() public type: 'PRIMARY' | 'SECONDARY' = 'PRIMARY';

  @Input() public iconSize: IconSize = 'MD';

  @Output() public buttonClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  protected onButtonClick(event: MouseEvent): void {
    this.buttonClick.emit(event);
  }

  protected get colorCombinationByType(): ColorCombinationId {
    switch (this.type) {
      case 'PRIMARY':
        return 'PRIMARY_CONTRAST_OPPOSITE';
      case 'SECONDARY':
      default:
        return 'SECONDARY_BUTTON';
    }
  }

  protected get iconColorCombinationByType(): ColorCombinationId {
    switch (this.type) {
      case 'PRIMARY':
        return 'PRIMARY';
      case 'SECONDARY':
      default:
        return 'SECONDARY_BUTTON';
    }
  }

}
