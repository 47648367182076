import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[aukUserTaskDialogStepHost]',
})
export class UserTaskDialogStepHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
