import { MoneyDto } from '@api/generated/defs/MoneyDto';
import { CurrencyCode } from '@shared/currency/model/currency-code.model';
import { ArrayUtils } from '@util/util/array.utils';
import { DEFAULT_CURRENCY } from '@shared/currency/const/currency.constants';

export class WebsocketPricesUtil {

  public static findProperPriceInCurrencyOrDefault(moneyDtos: MoneyDto[], currencyCode: CurrencyCode): MoneyDto | null {
    if (ArrayUtils.isEmpty(moneyDtos) || currencyCode == null) {
      return null;
    }

    const valueInTargetCurrency = moneyDtos.find(value => value.currency === currencyCode);
    return valueInTargetCurrency == null ?
      moneyDtos.find(value => value.currency === DEFAULT_CURRENCY.code) : valueInTargetCurrency;
  }

}
