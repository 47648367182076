import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DomainService } from '@shared/platform/domain.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { WINDOW_OBJECT } from '@util/const/window-object';

/**
 * This redirect logic is used just for Android to fix barion gateway payment redirect back to app, I advise not to temper with it
 * as DeepLinking works in very "special" way and can be easily broken.
 * @param route
 * @returns boolean | UrlTree | Observable<boolean>
 */
export const openAndroidGuard: CanActivateFn =
  (route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean> => {
    const router = inject(Router);
    const domainService = inject(DomainService);
    const windowObject = inject(WINDOW_OBJECT);

    if (PlatformCommonService.isNativeApp) {
      return router.createUrlTree(['/']);
    }

    if (!route.params.url) {
      windowObject.open('https://' + domainService.hostname, '_self');
    }

    if (route.queryParams.paymentId) {
      windowObject.open(
        'https://' + domainService.hostname + '/' + route.params.url + '?paymentId=' + route.queryParams.paymentId, '_self');
    } else {
      windowObject.open('https://' + domainService.hostname + '/' + route.params.url, '_self');
    }

    return false;
  };
