import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { ActivatedRoute, Event as RouterEvent, NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { SEOSortParam_LEVNE, SEOSortParam_NEJLEPSI } from '../../../../typings/original/deprecated';
import {
  OpenGraphSettings,
  SEO_CITY_NAME,
  SeoConfigureDelayedComponentType,
  SeoSettings,
  SeoTitleParams,
} from '../../../../typings/original/internal';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { BrowserService } from '@shared/platform/browser.service';
import { DomainService } from '@shared/platform/domain.service';
import { LinkDefinition, LinkService } from './link.service';
import { HreflangService } from './hreflang.service';
import { TranslateSourcePipe } from '@common/translations/pipe/translate-source.pipe';
import { StringUtils } from '@util/util/string.utils';
import { TranslateService } from '@ngx-translate/core';
import { TranslationSource } from '@common/translations/model/translation-source';
import { SeoConstants } from './seo.constants';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { BrowserUtils } from '@util/util/browser.utils';
import { GaTrackPageViewParamsModel } from '@shared/google-analytics/model/ga-track-page-view-params.model';

@Injectable({
  providedIn: 'root',
})
export class SeoService extends NgUnsubscribe {

  /** Query params allowed in URL when page can be indexed by bots. */
  private readonly BOT_INDEX_ALLOWED_QUERY_PARAMS: string[] = [
    SEOSortParam_LEVNE,
    SEOSortParam_NEJLEPSI,
    SEO_CITY_NAME,
  ];

  private activeRoute: ActivatedRoute;
  private componentConfigured: boolean = false;
  private restrictedCanonicalParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    'sort',
    'size',
    'type',
    'auction',
    'aukroPlus',
    'buyNow',
    'category',
    'endingBefore',
    'filter',
    'freeShipping',
    'location',
    'paymentOnline',
    'personalPickup',
    'paymentViaAukro',
    'cashOnDelivery',
    'ppCategoryPage',
    'ppMainPage',
    'priceMax',
    'priceMin',
    'specialFlagBasicStartingPrice',
    'specialFlagEndingSoon',
    'hotAuction',
    'specialFlagNew',
    'startingAfter',
    'specialFlagThreeHundred',
    'specialFlagNewOffers',
    'specialFlagPopularOffers',
    'specialFlagPriceHits',
    'specialFlagPremiumOffers',
    'specialFlagCharityOffers',
  ];
  private restrictedCanonicalParamsStartsWith = [
    'attr_',
  ];
  private seoSettings: SeoSettings = {};

  constructor(
    private readonly browserService: BrowserService,
    private readonly router: Router,
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
    private readonly titleService: Title,
    private readonly metaService: Meta,
    private readonly linkService: LinkService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly platformService: PlatformService,
    private readonly translateService: TranslateService,
    private readonly hreflangService: HreflangService,
    private readonly domainService: DomainService,
    private readonly translateSourcePipe: TranslateSourcePipe,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public set metaDescription(description: string) {
    const tag: MetaDefinition = {
      name: 'description',
      content: description || '',
    };
    this.addOrUpdateMetaTag(tag);
  }

  public set metaApplicationName(applicationName: string) {
    const tag: MetaDefinition = {
      name: 'application-name',
      content: applicationName || '',
    };
    this.addOrUpdateMetaTag(tag);
  }

  public set metaRobots(robots: string) {
    const tag: MetaDefinition = {
      name: 'robots',
      content: robots || '',
    };
    this.addOrUpdateMetaTag(tag);
  }

  public set metaViewport(viewport: string) {
    const tag: MetaDefinition = {
      name: 'viewport',
      content: viewport || '',
    };
    this.addOrUpdateMetaTag(tag);
  }

  public set title(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public init(): void {
    this.configureSeoOnNavigation();
  }

  public configureSeoOnNavigation(): void {
    this.router.events.pipe(
      filter((e: RouterEvent) => e instanceof NavigationEnd),
      map(() => BrowserUtils.getActiveRoute(this.activatedRoute)),
      map((route: ActivatedRoute) => {
        this.activeRoute = route;
        while (!route.snapshot.data.seo && route.parent) {
          route = route.parent;
        }
        return route.snapshot.data.seo as SeoSettings;
      }))
      .subscribe((seoSettings: SeoSettings) => {
        const component = this.activeRoute.component as SeoConfigureDelayedComponentType;
        if (component) {
          if (!component.seoConfigureDelayed && (seoSettings || !this.componentConfigured)) {
            this.configure(seoSettings || {});
          } else {
            this.componentConfigured = false;
          }
        }
      });
  }

  public updatePaginationLinks(page: number, totalPages: number): void {
    this.updatePaginationLink('prev', page, totalPages);
    this.updatePaginationLink('next', page, totalPages);
  }

  /**
   * It's necessary to call this method always in combination with <code>public static seoConfigureDelayed: boolean = true;</code>
   * defined on given page.
   * @param seoSettings
   * @param trackParams
   */
  public configure(seoSettings: SeoSettings = {}, trackParams?: GaTrackPageViewParamsModel): void {
    if (this.platformService.isBrowser) {
      this.componentConfigured = true;
      this.ngZoneUtilService.simpleTimerOut$(
        () => this.componentConfigured = false,
        this.ngUnsubscribe,
      );
      void this.googleAnalyticsTrackingService.trackPageView(trackParams);
    }
    this.seoSettings = seoSettings;
    this.getTitleOrDefault(seoSettings?.title, seoSettings?.titleParams)
      .pipe(take(1))
      .subscribe((translatedTitle) => this.title = translatedTitle || seoSettings?.title);

    if (seoSettings?.description?.key) {
      this.translateService.get(seoSettings.description.key)
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((translatedValue: string) => {
          this.metaDescription = this.getSeoDescriptionOrDefault(translatedValue);
        });
    } else if (seoSettings?.description?.defaultValue) {
      this.metaDescription = this.getSeoDescriptionOrDefault(seoSettings.description.defaultValue);
    }

    this.metaApplicationName = this.getSeoApplicationNameOrDefault(seoSettings?.applicationName);
    const botIndexAllowed: boolean = this.robotsIndexAllowed();
    this.metaRobots = this.allowMetaRobots(seoSettings, botIndexAllowed);
    this.handleCanonicalTag(botIndexAllowed);
    this.configureOpenGraphTags(seoSettings.openGraph);
    if (seoSettings.openGraph?.type === 'product' || seoSettings.openGraph?.type === 'product:category') {
      this.hreflangService.removeHrefLangList();
      this.hreflangService.appendHrefLangList(seoSettings.hreflangs);
    }
  }

  // Determines the meta robots tag value based on the provided SEO settings, SK settings and bot index allowance.
  private allowMetaRobots(seoSettings: SeoSettings, botIndexAllowed: boolean): string {
    const pathName = this.getItemUrl(seoSettings.openGraph?.url);

    if (pathName?.includes('-bazar')) {
      return SeoConstants.robotIndexFollow; // bazar is always indexed
    }

    if (!botIndexAllowed) {
      return SeoConstants.robotNoIndexFollow;
    }

    if (this.domainService.isCzLocale()) {
      return SeoConstants.robotIndexFollow;
    }

    if (pathName === '' ||
      pathName.includes('stranka/') ||
      pathName.includes('kontakt/') ||
      pathName === 'kontakt') {
      return SeoConstants.robotNoIndexFollow;
    }

    return seoSettings.openGraph?.type === 'product' && !seoSettings.hreflangs?.['sk']
      ? SeoConstants.robotNoIndexFollow
      : SeoConstants.robotIndexFollow;
  }

  public updatePaginationLink(rel: 'prev' | 'next', page: number, totalPages: number): void {
    if (!(page < 1 || (page === 1 && rel === 'prev') || (totalPages <= page && rel === 'next'))) {
      page = rel === 'prev' ? page - 1 : page + 1;
      const href = this.getPaginationHref(page);
      if (href != null) {
        const linkTag: LinkDefinition = {
          href,
          rel,
        };
        return this.linkService.addTag(linkTag, true);
      }
    }
    this.linkService.removeTag(`rel=${ rel }`);
    return null;
  }

  // TODO(PDEV-19157) We need to replace all hardcoded titles
  private isTitleTranslationKey(possiblyKey: string): boolean {
    return possiblyKey.includes(SeoConstants.titlesPrefix);
  }

  public getPaginationHref(page: number): string {
    const href = this.browserService.href;
    if (page === 1) {
      return href.replace(/&?page=(\d+)/gim, '')
        .replace(/\?$/gim, '')
        .replace(/\?&/gim, '?');
    }
    if (!this.pageInQueryParams(href)) {
      return `${ href }${ href.includes('?') ? '&' : '?' }page=${ page }`;
    }
    return href.replace(/page=(\d+)/gim, `page=${ page }`);
  }

  private getItemUrl(url: string): string {
    if (StringUtils.isEmpty(url)) {
      return '';
    }

    const localUrl = new URL(url);
    return localUrl.pathname;
  }

  /**
   * @returns whether bot indexing is allowed on current page
   */
  public robotsIndexAllowed(): boolean {
    // Check 1: SEO configuration of the current page.
    if (!this.seoSettings.index) {
      return false;
    }

    const queryParamKeys: string[] = this.router.parseUrl(this.router.url).queryParamMap.keys;

    // Check 2: No query params allowed on non-listing page.
    if (!this.seoSettings.isListing && queryParamKeys.length > 0) {
      return false;
    }

    // Check 3: Not allowed params on listing page. This filters out also search and pagination, because it always has not allowed param.
    const hasNotAllowedParam: boolean = queryParamKeys.some((key: string) => !this.BOT_INDEX_ALLOWED_QUERY_PARAMS.includes(key));
    if (hasNotAllowedParam) {
      return false;
    }

    // Check 4: Disabled query param combination.
    if (queryParamKeys.includes(SEOSortParam_LEVNE) && queryParamKeys.includes(SEOSortParam_NEJLEPSI)) {
      return false;
    }

    return true;
  }

  private pageInQueryParams(href: string = this.browserService.href): boolean {
    return this.getPageFromQueryParams(href) != null;
  }

  private getPageFromQueryParams(href: string = this.browserService.href): number {
    const execRes = (/\?(.*?)page=(\d+)/gim).exec(href);
    return execRes && +execRes[2];
  }

  private handleCanonicalTag(botIndexAllowed: boolean): void {
    const parsed = this.router.parseUrl(this.router.url);
    Object.keys(parsed.queryParams).forEach((key: string) => {
      if (
        this.restrictedCanonicalParams.includes(key)
        || this.restrictedCanonicalParamsStartsWith.find((arrValue: string) => key.startsWith(arrValue))
      ) {
        delete parsed.queryParams[key];
      }
    });

    const cleaned = `${ this.browserService.baseUrl }${ parsed.toString().substr(1) }`;

    if (botIndexAllowed) {
      this.linkService.addTag({
        rel: 'canonical',
        href: cleaned,
      }, true);
    } else {
      this.linkService.removeTag('rel=canonical');
    }
  }

  // useful only for SSR
  private configureOpenGraphTags(og: OpenGraphSettings): void {
    this.getTitleOrDefault(og?.title, og?.titleParams)
      .pipe(take(1))
      .subscribe((translatedTitle) => {
        const tags: MetaDefinition[] = [
          {
            name: 'og:type',
            content: (og && og.type) || 'website',
          },
          {
            name: 'og:url',
            content: (og && og.url) || this.browserService.href,
          },
          {
            name: 'og:title',
            content: translatedTitle || og?.title,
          },
          {
            name: 'og:image',
            content: (og && og.image && og.image.url) || 'https://f.aukro.cz/static-images/google/aukro_google_logo.jpg',
          },
          {
            name: 'og:image:width',
            content: ((og && og.image && og.image.width) || 500).toString(),
          },
          {
            name: 'og:image:height',
            content: ((og && og.image && og.image.height) || 500).toString(),
          },
          {
            name: 'og:description',
            content: this.getSeoDescriptionOrDefault(og?.description),
          },
          {
            name: 'og:applcation-name',
            content: this.getSeoApplicationNameOrDefault(og?.applicationName),
          },
        ];
        tags.forEach((tag: MetaDefinition) => this.addOrUpdateMetaTag(tag));
      });
  }

  private addOrUpdateMetaTag(tag: MetaDefinition): HTMLMetaElement {
    const attrSelector: string = `name='${ tag.name }'`;
    const oldTag: HTMLMetaElement = this.metaService.getTag(attrSelector);
    return oldTag == null ? this.metaService.addTag(tag) : this.metaService.updateTag(tag);
  }

  private getSeoDescriptionOrDefault(description: string): string {
    // In case there is no description, we choose default
    if (StringUtils.isBlank(description)) {
      return this.translateSourcePipe.transform({ key: 'DEFAULT_SEO_DESCRIPTION' });
    }

    return description;
  }

  private getSeoApplicationNameOrDefault(applicationName: string): string {
    // In case there is no description, we choose default
    if (StringUtils.isBlank(applicationName)) {
      return this.translateSourcePipe.transform({ key: 'DEFAULT_SEO_APPLICATION_NAME' });
    }

    return applicationName;
  }

  private getTitleOrDefault(titleString: string, titleParams: SeoTitleParams): Observable<string> {
    // In case there is no title, we choose default
    if (StringUtils.isEmpty(titleString)) {
      return of(this.translateSourcePipe.transform({ key: 'DEFAULT_SEO_TITLE' }));
    }

    const title: TranslationSource = this.isTitleTranslationKey(titleString) ?
      { key: titleString, params: { ...titleParams } } :
      { defaultValue: titleString };

    if (StringUtils.isEmpty(title.key)) {
      return of(title.defaultValue);
    }

    return this.translateService.get(title.key, title.params) as Observable<string>;
  }

}
