<select (change)="onSelectChange($any($event.target).value)" [disabled]="disabled">
  @if (placeholder !== null) {
    <option value="?">{{ placeholder }}</option>
  }

  @for (option of options; track option; let index = $index) {
    <option
      [value]="index"
      [disabled]="$any(option).disabled"
      [selected]="selectedOption(option)"
      >
      {{ { key: showOptionName(option), defaultValue: showOptionName(option) } | translateSource }}
    </option>
  }
</select>
