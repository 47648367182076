import isNil from 'lodash-es/isNil';
import { StringUtils } from '@util/util/string.utils';
import { TfvErrorCodeEnum } from './tvf-error-code.enum';
import { HttpError } from '@shared/rest/model/http-error';

export class TwoFactorVerificationHelper {

  public static readonly TFV_HEADER: string = 'X-Aukro-TFV-Code';

  public static readonly TFV_MESSAGE_CODES: Map<TfvErrorCodeEnum, string> = new Map<TfvErrorCodeEnum, string>([
    [TfvErrorCodeEnum.ENFORCED, 'rest.tfv.sms.enforced'],
    [TfvErrorCodeEnum.SEND, 'rest.tfv.sms.sent'],
    [TfvErrorCodeEnum.NOT_FOUND, 'rest.tfv.sms.notFound'],
    [TfvErrorCodeEnum.EXPIRED, 'rest.tfv.sms.expired'],
    [TfvErrorCodeEnum.INVALID, 'rest.tfv.sms.invalid'],
    [TfvErrorCodeEnum.FAILED, 'rest.tfv.sms.failCoolDown'],
  ]);

  private static readonly TFV_HTTP_ERROR_CODE: number = 417;

  public static isTwoFactorVerificationError(httpError: HttpError): boolean {
    return httpError.code === TwoFactorVerificationHelper.TFV_HTTP_ERROR_CODE
      && !isNil(TwoFactorVerificationHelper.getTfvErrorCode(httpError));
  }

  public static getTfvErrorCode(httpError: HttpError): TfvErrorCodeEnum {
    const errorMessage: string = (httpError.body as any)?.errorMessage;

    if (StringUtils.isEmpty(errorMessage)) {
      return null;
    }

    for (const [error, codeMessage] of TwoFactorVerificationHelper.TFV_MESSAGE_CODES.entries()) {
      if (codeMessage === errorMessage) {
        return error;
      }
    }

    return null;
  }

}
