import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisclaimersComponent } from './component/disclaimers.component';
import { CookieDisclaimerComponent } from './component/cookie-disclaimer/cookie-disclaimer.component';
import { CommonDirectivesModule } from '@shared/legacy/directive/common-directives.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    CommonDirectivesModule,
    TranslateModule,
  ],
  declarations: [
    DisclaimersComponent,
    CookieDisclaimerComponent,
  ],
  exports: [
    DisclaimersComponent,
  ],
})
export class DisclaimersModule {
}
