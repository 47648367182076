<auk-icon-button
    class="tw-block"
    [ngClass]="direction === 'LEFT' ? 'tw-rotate-180' : ''"
    [colorCombination]="colorCombinationByType"
    [isCircle]="false"
    [hasShadow]="false"
    [iconModel]="{ type: 'SVG', size: iconSize, colorCombination: iconColorCombinationByType, source: 'arrow-gray-sm' }"
    paddingClass="tw-p-2.5"
    (click)="onButtonClick($event)"
/>
