import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import isNil from 'lodash-es/isNil';
import { HeaderCategoryUtil } from '@shared/app-header/component/app-header/util/header-category.util';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CategoryDialogModel } from '@shared/app-header/component/category-dialog/model/category-dialog-model';
import { DialogUtil } from '@common/dialog/utils/dialog.util';
import { DialogModel } from '@common/dialog/model/dialog.model';
import { AukDialogType } from '@shared/dialog/model/auk-dialog.type';
import { AukDialogService } from '@shared/dialog/service/auk-dialog-service';
import { DomainService } from '@shared/platform/domain.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { ConfigElementValueDto } from '@api/aukro-api/model/config-element-value-dto';

const HAMBURGER_MENU_MODAL: DialogModel<AukDialogType> = DialogUtil.of('NAVIGATION', 'hamburger');

@Injectable({ providedIn: 'root' })
export class AppHeaderHamburgerService extends NgUnsubscribe {

  // Keep track of first opening of hamburger menu as it should be pre-rendered after first opening.
  private hasBeenOpened$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly aukModalService: AukDialogService,
    private readonly domainService: DomainService,
  ) {
    super();
  }

  public hamburgerHasBeenOpened$(): Observable<boolean> {
    return this.hasBeenOpened$.asObservable();
  }

  public hamburgerData$(): Observable<CategoryDialogModel> {
    return combineLatest([
      this.hasBeenOpened$,
      this.configuratorCacheService.menuLayouts(['HAMBURGER_MAIN_MENU_LAYOUT'])
        .pipe(
          distinctUntilChanged(),
        ),
      this.responsivenessService.isMdAndLower$
        .pipe(
          distinctUntilChanged(),
        ),
      this.responsivenessService.isLgAndLower$
        .pipe(
          distinctUntilChanged(),
        ),
    ])
      .pipe(
        map(([hasBeenOpened, data, isMdAndLower, isLgAndLower]: [boolean, ConfigElementValueDto[], boolean, boolean]) => {
          if (!hasBeenOpened) {
            return null;
          }

          const hamburgerMenuJSON = data[0].value;

          if (!isNil(hamburgerMenuJSON)) {
            const { categoryTabList, tileListContainer } =
              HeaderCategoryUtil.processHamburgerMenuData(
                hamburgerMenuJSON,
                this.domainService.locale,
                PlatformCommonService.isNativeApp,
                this.responsivenessService,
              );

            return {
              categoryTabList,
              tileListContainer,
            };
          }
        }),
        distinctUntilChanged(),
      );
  }

  public openCategoryMenu(): void {
    this.aukModalService.open$(HAMBURGER_MENU_MODAL)
      .pipe(
        take(1),
        filter((isOpened) => isOpened === true),
        tap(() => {
          if (this.hasBeenOpened$.value == false) {
            this.hasBeenOpened$.next(true);
          }
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public closeCategoryMenu(): void {
    this.aukModalService.close(HAMBURGER_MENU_MODAL);
  }

  public categoryMenuOpened$(): Observable<boolean> {
    return this.aukModalService.isOpened$(HAMBURGER_MENU_MODAL);
  }

}
