// TODO: [PDEV-18307] - fix dependency
// eslint-disable-next-line import/no-restricted-paths
import { TranslationSource } from '@common/translations/model/translation-source';

/**
 * Converts string into {@link TranslationSource}
 * @param value - string, which will be converted to {@link TranslationSource}
 */
export const stringToTranslationSource = (value: string): TranslationSource => ({
  defaultValue: value,
});
