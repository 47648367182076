import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { PlatformService } from '@shared/platform/service/platform.service';
import { UserHeaderControlComponent } from '../user-header-control/user-header-control.component';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { HeaderUserPersonalizationMeasurementService } from '@shared/services/personalization/header-user-personalization-measurement.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { FavouritePopoverService } from '@shared/favourite/service/favourite-popover.service';
import { FavouritePopoverDataService } from '@shared/favourite/service/favourite-popover-data.service';
import { UserFavouritesPreviewDto } from '@api/aukro-api/model/user-favourites-preview-dto';
import { FavouriteTabModel } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-tab.model';
import { FavouritePopoverDataModel } from './model/favourite-popover-data.model';
import { FavouriteSellerModel } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-seller.model';
import { UserChip } from '@shared/user-chip/model/user-chip';
import { FavouriteItemModel } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-item.model';
import isNil from 'lodash-es/isNil';
import { CartItemModel } from '@shared/cart/model/cart-item.model';
import { AddToCartService } from '@shared/cart/service/add-to-cart.service';
import { UserInterestStatisticsDto } from '@api/aukro-api/model/user-interest-statistics-dto';
import { FavouriteSellerDto } from '@api/aukro-api/model/favourite-seller-dto';
import { Tooltip2Directive } from '@common/tooltip2/directive/tooltip2.directive';
import { AppHeaderService } from '@shared/app-header/service/app-header.service';
import { Nil } from '@util/helper-types/nil';
import { HeaderItemModel } from '@shared/app-header/module/app-header-user-controls/component/header-item/model/header-item.model';
import { ColorCombinationId } from '@common/colors/model/color-combination-id';
import { WatchingItemPreviewDto } from '@api/aukro-api/model/watching-item-preview-dto';
import { FavouriteClickModel } from '@shared/favourite/model/favourite-click.model';
import { FavouriteTabModelNameEnum } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-tab-name.type';
import { combineLatest, Subject } from 'rxjs';
import { FavouriteItemUserSpecificBiddingStateType } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-item-user-specific-bidding-state.type';
import { ProductStateUtils } from '@shared/item-detail/service/mapper/base-item/utils/product-state.utils';
import { FavouriteItemStateInfoModel } from '@shared/app-header/module/app-header-user-controls/component/favourites-control/model/favourite-item-state-info.model';

@Component({
  selector: 'auk-favourites-control',
  templateUrl: './favourites-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouritesControlComponent extends UserHeaderControlComponent implements OnInit, OnDestroy {

  @Input() public user: UserInterestStatisticsDto;

  @ViewChild(Tooltip2Directive) private readonly tooltipDirective: Tooltip2Directive;

  @HostBinding('class.force-closed') public override forceClosed: boolean = false;
  @HostBinding('class.touch-opened') public override touchOpened: boolean = false;

  protected list: FavouriteSellerDto[] = [];
  protected displayedCount: string;

  protected favouriteSellersUrl: string = '/moje-aukro/moje-nakupy/oblibeni-prodejci';
  protected favouriteItemsUrl: string = '//moje-aukro/moje-nakupy/sledovani';
  protected isPopoverOpened: boolean = false;

  protected favourites: FavouritePopoverDataModel;
  protected firstToEndItem: WatchingItemPreviewDto;

  protected tabs: FavouriteTabModel[] = [
    { name: 'ITEMS', url: this.favouriteItemsUrl },
    { name: 'SELLERS', url: this.favouriteSellersUrl },
    { name: 'SEARCH', url: '/moje-aukro/moje-nakupy/oblibene-vyhledavani' },
  ];

  private readonly navigationListenerDestroy$: Subject<void> = new Subject<void>();

  private lastPopoverShowId: number | Nil;
  private showCountdownTimer: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly platformService: PlatformService,
    private readonly headerUserPersonalizationMeasurementService: HeaderUserPersonalizationMeasurementService,
    private readonly favouritePopoverService: FavouritePopoverService,
    private readonly favouritePopoverDataService: FavouritePopoverDataService,
    private readonly addToCartService: AddToCartService,
    @Inject(WINDOW_OBJECT) window: Window,
    changeDetectorRef: ChangeDetectorRef,
    responsivenessService: ResponsivenessService,
    ngZoneUtilService: NgZoneUtilService,
  ) {
    super(window, changeDetectorRef, responsivenessService, ngZoneUtilService);
  }

  public ngOnInit(): void {
    if (this.platformService.isServerAndMobileAndNotBot) {
      return;
    }

    this.favouritePopoverService.init();

    this.favouritePopoverDataService.popoverData$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(data => {
        if (!isNil((data?.favouriteItemsPreviewDto || data?.favouriteSellersPreviewDto))) {
          this.transformAndSetFavouritesPopover(data);
          this.displayedCount = AppHeaderService.getBadgeCount(this.favourites.favouriteItemsPreviewData.countOfAllFavouriteItems);
        } else {
          // Set all values to null if there are no data given
          this.transformAndSetFavouritesPopover(null);
          this.displayedCount = null;
        }
        this.changeDetectorRef.markForCheck();
      });

    combineLatest([
      this.favouritePopoverDataService.firstToEndPopoverItem$(),
      this.favouritePopoverDataService.firstToEndPopoverUrgentItem$(),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(([firstToEndItem, firstToEndUrgentItem]) => {
        this.firstToEndItem = firstToEndItem;
        this.showCountdownTimer = !!firstToEndUrgentItem;

        this.changeDetectorRef.markForCheck();
      });

    this.favouritePopoverDataService.popoverOpened$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(isPopoverOpened => {
        this.isPopoverOpened = isPopoverOpened;
        if (isPopoverOpened) {
          this.initNavigationListener();
        } else {
          this.navigationListenerDestroy$.next();
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.favouritePopoverService.destroy();

    this.navigationListenerDestroy$.next();
    this.navigationListenerDestroy$.complete();
  }

  public transformAndSetFavouritesPopover(data: UserFavouritesPreviewDto | Nil): void {
    if (data?.favouriteItemsPreviewDto && data.favouriteSellersPreviewDto) {
      // Data are available
      const transformedItems: FavouriteItemModel[] = this.transformItems(data.favouriteItemsPreviewDto?.favouriteItemsPreview);
      const transformedSellers: FavouriteSellerModel[] = this.transformSellers(data.favouriteSellersPreviewDto?.favouriteSellersPreview);
      const transformedTabs: FavouriteTabModel[] = this.transformTabs(this.tabs, data);

      this.favourites = {
        favouriteItemsPreviewData: {
          favouriteItemsPreview: transformedItems || null,
          countOfAllFavouriteItems: data.favouriteItemsPreviewDto?.countOfAllFavouriteItems || null,
        },
        favouriteSellersPreviewData: {
          favouriteSellersPreview: transformedSellers || null,
          countOfAllFavouriteSellers: data.favouriteSellersPreviewDto?.countOfAllFavouriteSellers || null,
        },
        favouriteTabs: transformedTabs || null,
        countOfAllFavouriteSearchTerms: data.countOfAllFavouriteSearchTerms || null,
      };
    } else {
      // Data are unavailable
      this.favourites = {
        favouriteItemsPreviewData: {
          favouriteItemsPreview: null,
          countOfAllFavouriteItems: null,
        },
        favouriteSellersPreviewData: {
          favouriteSellersPreview: null,
          countOfAllFavouriteSellers: null,
        },
        favouriteTabs: null,
        countOfAllFavouriteSearchTerms: null,
      };
    }

    this.changeDetectorRef.markForCheck();
  }

  private transformItems(items: WatchingItemPreviewDto[]): FavouriteItemModel[] {
    return items.map(item => ({
      ...item,
      imageUrl: item.image?.url,
      stateInfo: this.getItemStateInfo(item),
    }));
  }

  private transformSellers(sellers: FavouriteSellerDto[]): FavouriteSellerModel[] {
    return sellers.map(seller => ({
      login: seller.login,
      userChip: this.transformUserChip(seller),
      newItemsCount: seller.newItemsCount,
      sellerId: seller.userRating.userId,
    }));
  }

  private transformUserChip(seller: FavouriteSellerDto): UserChip {
    return {
      showName: seller.login,
      avatarUrl: seller.avatarUrlMedium,
    };
  }

  private transformTabs(tabs: FavouriteTabModel[], data: UserFavouritesPreviewDto): FavouriteTabModel[] {
    return tabs.map(tab => {
      switch (tab.name) {
        case 'ITEMS':
          return {
            ...tab,
            count: data.favouriteItemsPreviewDto?.countOfAllFavouriteItems,
            label: { key: 'FAVOURITES_POPOVER_ITEMS_TAB_LABEL' },
          };
        case 'SELLERS':
          return {
            ...tab,
            count: data.favouriteSellersPreviewDto.countOfAllFavouriteSellers,
            label: { key: 'FAVOURITES_POPOVER_SELLERS_TAB_LABEL' },
          };
        case 'SEARCH':
          return {
            ...tab,
            count: data.countOfAllFavouriteSearchTerms,
            label: { key: 'FAVOURITES_POPOVER_SEARCH_TAB_LABEL' },
          };
        default:
          return tab;
      }
    });
  }

  public onBuyButtonClick(item: FavouriteItemModel): void {

    const cartItem: CartItemModel = {
      itemId: item.itemId,
      itemName: item.itemName,
      buyNowPrice: item.price,
      buyNowActive: item.buyNowActive,
      seller: {
        userId: item.sellerId,
        showName: item.itemName,
      },
    };
    this.addToCartService.addToBasket(cartItem, 1)
      .pipe(
        take(1),
        finalize(() => {
          this.changeDetectorRef.markForCheck();
        }),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  private closePopover(): void {
    this.tooltipDirective.closeToolTip();
  }

  public onFavouritePopoverClick(): void {
    const lgAndHigher = this.responsivenessService.isLgAndHigher;
    this.measureClick(this.lastPopoverShowId, this.favouriteItemsUrl, lgAndHigher, !lgAndHigher, { tab: 'ITEMS' });

    if (lgAndHigher) {
      void this.router.navigate([this.favouriteItemsUrl]);
    }
  }

  protected onPopoverMeasuredClick(click: FavouriteClickModel): void {
    this.measureClick(this.lastPopoverShowId, click.url, false, false, click);

    if (this.responsivenessService.isMdAndLower || click.canDoAppNavigation) {
      this.closePopover();
    }
  }

  protected measureClick(
    showId: number,
    targetUrl: string,
    desktopIconClick: boolean,
    mobileIconClick: boolean,
    click: FavouriteClickModel,
  ): void {
    this.headerUserPersonalizationMeasurementService.clickFavouritesPopover(
      {
        showId,
        itemId: click.itemId,
        properties: {
          targetUrl,
          desktopIconClick,
          mobileIconClick,
          action: click?.properties?.action,
          urgencyItemId: this.showCountdownTimer ? this.firstToEndItem?.itemId : null,
        },
      },
      click?.tab,
    );
  }

  protected override measurePopoverShow(): void {
    this.headerUserPersonalizationMeasurementService.showFavouritesPopover$('ITEMS')
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((showId) => {
        if (isNil(showId)) {
          return;
        }
        this.lastPopoverShowId = showId;
      });
  }

  protected setPopoverOpenState(isOpen: boolean): void {
    this.favouritePopoverDataService.setPopoverOpened(isOpen);
  }

  protected get headerItemData(): HeaderItemModel {
    return {
      badgeText: this.displayedCount,
      countdown: this.showCountdownTimer ? this.firstToEndItem?.endTime : null,
      icon: {
        type: 'SVG',
        source: 'heart-filled',
        colorCombination: this.favouriteColorCombination,
      },
    };
  }

  protected get favouriteColorCombination(): ColorCombinationId {
    if (!this.showCountdownTimer || isNil(this.firstToEndItem?.endTime)) {
      return this.isPopoverOpened ? 'SILVERSTEIN_200_900' : 'SILVERSTEIN_CONTRAST_200_600';
    }

    switch (this.firstToEndItem.stateOfBidding) {
      case 'WINNING':
        return 'SUCCESS_100_600';
      case 'LOOSING':
        return 'DANGER_100_600';
      default:
        return 'SILVERSTEIN_200_900';
    }
  }

  protected onPopoverTabClick(tabName: FavouriteTabModelNameEnum): void {
    this.headerUserPersonalizationMeasurementService.showFavouritesPopover$(tabName)
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((showId) => {
        if (isNil(showId)) {
          return;
        }
        this.lastPopoverShowId = showId;
      });
  }

  private initNavigationListener(): void {
    this.navigationListenerDestroy$.next();
    this.router.events
      .pipe(
        takeUntil(this.navigationListenerDestroy$),
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.closePopover();
        }
      });
  }

  private getUserSpecificBiddingState(
    {
      state,
      stateOfBidding,
      itemTypeEnum,
    }: WatchingItemPreviewDto,
  ): FavouriteItemUserSpecificBiddingStateType | Nil {
    if (!ProductStateUtils.isAuction(itemTypeEnum)) {
      return null;
    }

    // check if user is winning
    if (
      !ProductStateUtils.isEnded({ state })
      && ProductStateUtils.isUserWinningBasedOnState(stateOfBidding)
    ) {
      return 'WINNING';
    }
    // check if user has won
    if (
      ProductStateUtils.isEnded({ state })
      && ProductStateUtils.isUserWinningBasedOnState(stateOfBidding)
    ) {
      return 'WON';
    }
    // check if user is losing
    if (
      !ProductStateUtils.isEnded({ state })
      && ProductStateUtils.isUserLosingBasedOnState(stateOfBidding)
    ) {
      return 'LOSING';
    }
    // check if user has lost
    if (
      ProductStateUtils.isEnded({ state })
      && ProductStateUtils.isUserLosingBasedOnState(stateOfBidding)
    ) {
      return 'LOST';
    }

    return null;
  }

  private getItemStateInfo(item: WatchingItemPreviewDto): FavouriteItemStateInfoModel | Nil {

    if (item.itemTypeEnum === 'BUYNOW') {
      return {
        label: {
          key: 'ITEM_TIMER_LABEL_ONLY_BUY_NOW',
        },
      };
    }

    const userSpecificBiddingState = this.getUserSpecificBiddingState(item);

    switch (userSpecificBiddingState) {
      case 'LOSING':
        return {
          label: {
            key: 'ITEM_TIMER_LABEL_LOSING',
          },
          classes: ['tw-text-matter-red-primary'],
        };
      case 'LOST':
        return {
          label: {
            key: 'ITEM_TIMER_LABEL_LOST',
          },
          classes: ['tw-text-matter-red-primary'],
        };
      case 'WINNING':
        return {
          label: {
            key: 'ITEM_TIMER_LABEL_WINNING',
          },
          classes: ['tw-text-matter-green-primary'],
        };
      case 'WON':
        return {
          label: {
            key: 'ITEM_TIMER_LABEL_WON',
          },
          classes: ['tw-text-matter-green-primary'],
        };
      default:
        // as default, we know that it is auction
        return {
          label: {
            key: 'ITEM_TIMER_LABEL_AUCTION',
          },
        };
    }
  }

}
