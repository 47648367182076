import { combineLatest, Observable, ObservableInputTuple } from 'rxjs';
import { skip, startWith } from 'rxjs/operators';

/**
 * Works like combineLatest, but it emits even if only single value emits (other values will have nil value in that case)
 */
export const combineLatestWithStart$ = <A extends readonly unknown[]>(
  sources: readonly [...ObservableInputTuple<A>],
): Observable<A> => (combineLatest(
    (sources as Observable<A>[]).map((source$) =>
      source$
        .pipe(
          startWith(null),
        ),
    ),
  )
    .pipe(
      skip(1),
    ) as Observable<A>);
