import { NgModule } from '@angular/core';
import { UserTaskDialogComponent } from './component/user-task-dialog.component';
import { UserTaskDialogStepHostDirective } from './directive/user-task-dialog-step-host.directive';
import { MatIconModule } from '@angular/material/icon';
import { DialogWrapperModule } from '@common/dialog-wrapper/dialog-wrapper.module';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';

@NgModule({
  declarations: [
    UserTaskDialogComponent,
    UserTaskDialogStepHostDirective,
  ],
  imports: [
    MatIconModule,
    IconComponent,
    DialogWrapperModule,
  ],
})
export class UserTaskBaseModule {

}
