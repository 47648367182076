import { Injectable, Injector, Type } from '@angular/core';
import { UserActionType } from '../model/user-action.type';
import { UserTaskType } from '@shared/user-task/base/model/user-task.type';
import { Observable } from 'rxjs';
import { UserTaskPayloadModel } from '@shared/user-task/base/model/user-task-payload.model';
import { UserActionPrerequisiteTaskFulfillmentPayloadResolverService } from './task-fulfillment/user-action-prerequisite-task-fulfillment-payload-resolver.service';
import { UserActionPrerequisiteTaskLoginFulfillmentPayloadResolverService } from './task-fulfillment/user-action-prerequisite-task-login-fulfillment-payload-resolver.service';
import { UserActionPrerequisiteTaskPersonalDataFulfillmentPayloadResolverService } from './task-fulfillment/user-action-prerequisite-task-personal-data-fulfillment-payload-resolver.service';
import { UserActionPrerequisiteTaskVerifiedEmailFulfillmentPayloadResolverService } from './task-fulfillment/user-action-prerequisite-task-verified-email-fulfillment-payload-resolver.service';
import { UserActionPrerequisiteTaskVerifiedPhoneFulfillmentPayloadResolverService } from './task-fulfillment/user-action-prerequisite-task-verified-phone-fulfillment-payload-resolver.service';
import { UserActionPrerequisiteTaskVerifiedBankAccountFulfillmentPayloadResolverService } from './task-fulfillment/user-action-prerequisite-task-verified-bank-account-fulfillment-payload-resolver.service';

type TaskToResolverMap = {
  [K in UserTaskType]: Type<UserActionPrerequisiteTaskFulfillmentPayloadResolverService<K>>;
};

@Injectable({
  providedIn: 'root',
})
export class UserActionTaskPayloadService {

  private readonly TASK_TO_RESOLVER_MAP: TaskToResolverMap = {
    LOGIN: UserActionPrerequisiteTaskLoginFulfillmentPayloadResolverService,
    PERSONAL_DATA: UserActionPrerequisiteTaskPersonalDataFulfillmentPayloadResolverService,
    VERIFIED_EMAIL: UserActionPrerequisiteTaskVerifiedEmailFulfillmentPayloadResolverService,
    VERIFIED_PHONE: UserActionPrerequisiteTaskVerifiedPhoneFulfillmentPayloadResolverService,
    VERIFIED_BANK_ACCOUNT: UserActionPrerequisiteTaskVerifiedBankAccountFulfillmentPayloadResolverService,
  };

  constructor(
    private readonly injector: Injector,
  ) {
  }

  public resolvePayload$<T extends UserTaskType>(taskType: T, actionType?: UserActionType): Observable<UserTaskPayloadModel<T>> {
    const resolver: Type<UserActionPrerequisiteTaskFulfillmentPayloadResolverService<T>> = this.TASK_TO_RESOLVER_MAP[taskType];
    return this.injector.get(resolver).resolve$(actionType);
  }

}
