import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { HelperService } from '@shared/services/helper/helper.service';
import { CartItemOverviewDto } from '@api/generated/model';
import { ProductUtil } from '@shared/util/product/product.util';

@Component({
  selector: 'auk-basket-item',
  templateUrl: './basket-item.component.html',
  styleUrls: ['./basket-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasketItemComponent {

  @Input() public item: CartItemOverviewDto;

  @Output() public popoverLinkClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() public remove = new EventEmitter<void>();

  constructor(
    private readonly helperService: HelperService,
  ) {
  }

  public onClick(): void {
    this.popoverLinkClick.emit(ProductUtil.getOfferUrl(this.item).join(''));
  }

  protected get itemTitleImage(): string {
    return this.helperService.getTitleImage(this.item.titleImage, 'small');
  }

}
