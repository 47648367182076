import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';
import { PlatformService } from '@shared/platform/service/platform.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

@Directive({
  selector: 'input[aukDelayedAutofocus], textarea[aukDelayedAutofocus]',
})
export class DelayedAutofocusDirective extends NgUnsubscribe implements AfterViewInit {

  @Input() public ignoreOnIos: boolean = false;

  constructor(
    private readonly elementRef: ElementRef<HTMLInputElement>,
    private readonly platformService: PlatformService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    if (this.ignoreOnIos && this.platformService.isIos) {
      return;
    }

    this.ngZoneUtilService.simpleTimerOut$(
      () => this.elementRef.nativeElement.focus(),
      this.ngUnsubscribe,
    );
  }

}
