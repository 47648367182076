<a [routerLink]="item | offerUrl"
   (click)="onClick()"
>
  <div class="item-photo">
    <img
      [aukReplaceByPlaceholderOnError]="itemTitleImage"
      placeholderSize="64x48"
      [alt]="item.itemName"
    >
  </div>
  <div class="item-name">
    {{item.itemName}}
  </div>
  <div class="item-price text-bold">
    {{ item.totalPrice | currency }}
  </div>
</a>

<i class="material-icons remove-item" (click)="remove.emit()">
  cancel
</i>

<div class="tw-flex tw-justify-end tw-relative tw-items-end tw-w-4/5 tw-h-0 tw--top-3">
  <auk-currency-approximation class="text-small" [price]="item.totalPrice"></auk-currency-approximation>
</div>
