import { ToastConfig } from '../model/toast-config';
import { BaseToastComponent } from '../component/base-toast/base-toast.component';
import { ToastRef } from '../model/toast-ref';
import { ToastContent } from '../model/toast-content';
import { ToastStaticConfig } from '../model/toast-static-config';
import { TOAST_TYPES_MAP } from '../constant/toast-types-map.constant';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

export abstract class BaseToastService extends NgUnsubscribe {

  /**
   * Shows info type toast
   * @param content - either {@link TranslationSource} which will be used as shortcut to apply only message
   *  or {@link ToastContentExtended} to set both message & title
   * @param config - config for the toast {@link ToastConfig} to set for example actions, custom timeout,...
   */
  public showInfo<C extends BaseToastComponent = BaseToastComponent>(
    content: ToastContent,
    config?: ToastConfig,
  ): ToastRef<C> {
    return this.showToast(
      content,
      TOAST_TYPES_MAP['info'],
      config,
    );
  }

  /**
   * Shows danger type toast
   * @param content - either {@link TranslationSource} which will be used as shortcut to apply only message
   *  or {@link ToastContentExtended} to set both message & title
   * @param config - config for the toast {@link ToastConfig} to set for example actions, custom timeout,...
   */
  public showDanger<C extends BaseToastComponent = BaseToastComponent>(
    content: ToastContent,
    config?: ToastConfig,
  ): ToastRef<C> {
    return this.showToast(
      content,
      TOAST_TYPES_MAP['danger'],
      config,
    );
  }

  /**
   * Shows warning type toast
   * @param content - either {@link TranslationSource} which will be used as shortcut to apply only message
   *  or {@link ToastContentExtended} to set both message & title
   * @param config - config for the toast {@link ToastConfig} to set for example actions, custom timeout,...
   */
  public showWarning<C extends BaseToastComponent = BaseToastComponent>(
    content: ToastContent,
    config?: ToastConfig,
  ): ToastRef<C> {
    return this.showToast(
      content,
      TOAST_TYPES_MAP['warning'],
      config,
    );
  }

  /**
   * Shows success type toast
   * @param content - either {@link TranslationSource} which will be used as shortcut to apply only message
   *  or {@link ToastContentExtended} to set both message & title
   * @param config - config for the toast {@link ToastConfig} to set for example actions, custom timeout,...
   */
  public showSuccess<C extends BaseToastComponent = BaseToastComponent>(
    content: ToastContent,
    config?: ToastConfig,
  ): ToastRef<C> {
    return this.showToast(
      content,
      TOAST_TYPES_MAP['success'],
      config,
    );
  }

  /**
   * Opens toast
   * @returns Toast reference, so it can be dynamically updated
   * @param content - either {@link TranslationSource} which will be used as shortcut to apply only message
   *  or {@link ToastContentExtended} to set both message & title
   * @param staticConfig - config, which is used to differentiate the toast types {@link ToastStaticConfig}
   * @param config - config for the toast {@link ToastConfig} to set for example actions, custom timeout,...
   */
  protected abstract showToast<C extends BaseToastComponent = BaseToastComponent>(
    content: ToastContent,
    staticConfig: ToastStaticConfig,
    config?: ToastConfig,
  ): ToastRef<C>;

  /**
   * Closes all currently shown toasts
   */
  public abstract closeAll(): void;

  /**
   * Remove specific toast by ID
   * @param toastId - ID of the toast to remove
   */
  public abstract close(toastId: number): void;

}
