// set stances automatically when user is created
import { RegistrationConsentStanceDto } from '@api/generated/defs/RegistrationConsentStanceDto';

export const AUTO_STANCES: RegistrationConsentStanceDto[] = [
  {
    consentTypeId: 1,
    stance: true,
  },
  {
    consentTypeId: 2,
    stance: true,
  },
];
