import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { AppHeaderService } from '@shared/app-header/service/app-header.service';
import { DynamicTemplateModel } from '@shared/app-header/model/dynamic-template.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'auk-app-header-sticky-dynamic-container',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    AsyncPipe,
  ],
  templateUrl: './app-header-sticky-dynamic-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderStickyDynamicContainerComponent {

  constructor(private readonly appHeaderService: AppHeaderService) {
  }

  protected get dynamicTemplate$(): Observable<DynamicTemplateModel> {
    return this.appHeaderService.dynamicTemplate$;
  }

}
