import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SuggestAllResponseContentElementCategory } from '@api/generated/defs/SuggestAllResponseContentElementCategory';

@Component({
  selector: 'auk-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: [
    './category-item.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryItemComponent {

  @Input() public category: SuggestAllResponseContentElementCategory;
  @HostBinding('class.focus') @Input() public focus: boolean = false;

}
