import { ColorType } from '../model/color-type';
import { COLOR_TYPES } from '../constant/color-types';
import { ColorName } from '../model/color-name';
import { AbstractColorName } from '../model/abstract-color-name';
import { CSS_COLOR_PREFIX } from '../constant/css-color-prefix';
import isNil from 'lodash-es/isNil';
import camelCase from 'lodash-es/camelCase';
import { ColorCombination } from '../class/color-combination.class';
import { ABSTRACT_COLOR_NAMES } from '../constant/abstract-color-names';

export class ColorUtil {

  // Set of color names that should not have the CSS_COLOR_PREFIX
  private static colorsWithoutPrefix = new Set(['primus-300']);

  public static getColorCssVar(cssVarName: string): string {
    if (ColorUtil.colorsWithoutPrefix.has(cssVarName)) {
      return `var(--auk-${ cssVarName })`;
    }

    // TODO: PDEV-22799 - THIS IS DIRTY HACK THAT SHOULD BE REMOVED AS SOON AS POSSIBLE
    if (cssVarName === 'primary') {
      return 'var(--auk-primus-500)';
    }
    if (cssVarName === 'primary-hover') {
      return 'var(--auk-primus-600)';
    }
    if (cssVarName === 'primary-active') {
      return 'var(--auk-primus-700)';
    }
    if (cssVarName === 'primary-disabled') {
      return 'var(--auk-primus-100)';
    }
    // TODO: PDEV-22799 - REMOVE UNTIL HERE
    return `var(${ CSS_COLOR_PREFIX }${ cssVarName })`;
  }

  public static getCssColorPrefixVar(cssVarName: string): string {
    return `${ CSS_COLOR_PREFIX }${ cssVarName }`;
  }

  public static getColorName(colorName: ColorName | AbstractColorName, colorType: ColorType = 'base'): string {
    return `${ colorName }${ colorType === 'base' ? '' : `-${ colorType }` }`;
  }

  public static getColorTypeCssVars(colorName: ColorName | AbstractColorName): Record<ColorType, string> {
    return COLOR_TYPES.reduce((accColorTypes, colorType) => ({
      ...accColorTypes,
      [colorType]: ColorUtil.getColorCssVar(ColorUtil.getColorName(colorName, colorType)),
    }), {} as Record<ColorType, string>);
  }

  public static setAbstractCssVarsOnElement(colorCombination: ColorCombination | undefined, htmlElement: HTMLElement): void {
    if (isNil(htmlElement)) {
      console.error(`Html Element doesn't exist`);
      return;
    }

    // color combination can be undefined, in this case don't set any color
    if (isNil(colorCombination)) {
      return;
    }

    // We cannot use htmlElement.setProperty(), because it is not supported in SSR (https://github.com/angular/angular/issues/42170)
    // Instead we create css style string and set it directly on the element
    const cssStyle = ABSTRACT_COLOR_NAMES
      .reduce((styleStrAbstractColors, abstractColorName) => {
        const cssStyleColorTypes = COLOR_TYPES
          .reduce((styleStrColorTypes, colorType) => {
            const cssVarDef = ColorUtil.getCssVarDeclaration(abstractColorName, colorType, colorCombination);

            return styleStrColorTypes + cssVarDef;
          }, '');

        return styleStrAbstractColors + cssStyleColorTypes;
      }, '');

    // ignore needed, because in the type defs `style` property is marked as readonly
    // @ts-ignore
    (htmlElement.style as string) = cssStyle;
  }

  /**
   * Returns css var style declaration
   * @param abstractColorName
   * @param colorType
   * @param colorCombination
   * @example returned value -> --auk-clr-primary-active: #fff
   */
  private static getCssVarDeclaration(
    abstractColorName: AbstractColorName,
    colorType: ColorType,
    colorCombination: ColorCombination,
  ): string {
    return `${ CSS_COLOR_PREFIX }${ ColorUtil.getColorName(abstractColorName, colorType) }:`
      + `${ colorCombination[camelCase(abstractColorName)][camelCase(colorType)] }; `;
  }

}
