import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { AddressDto } from '../model/address-dto';
import { ChangeEmailDto } from '../model/change-email-dto';
import { ChangeLoginDto } from '../model/change-login-dto';
import { ChangeNameRequestDataDto } from '../model/change-name-request-data-dto';
import { CompanyAccountDetailDto } from '../model/company-account-detail-dto';
import { FeedbackFilter } from '../model/feedback-filter';
import { GetUserAccountDetail200Response } from '../model/get-user-account-detail200-response';
import { InitBasicDataFormDto } from '../model/init-basic-data-form-dto';
import { JWTInfo } from '../model/jwt-info';
import { NewUserProfileAllDto } from '../model/new-user-profile-all-dto';
import { NewUserProfileBaseDto } from '../model/new-user-profile-base-dto';
import { PagedModelFeedbackDto } from '../model/paged-model-feedback-dto';
import { SellerInfoDto } from '../model/seller-info-dto';
import { TokenDto } from '../model/token-dto';
import { TokenPairedToNewsletterDto } from '../model/token-paired-to-newsletter-dto';
import { UpdateCompanyAccountDetailDto } from '../model/update-company-account-detail-dto';
import { UpdateUserPreferenceSubbrandDto } from '../model/update-user-preference-subbrand-dto';
import { UserAbsenceDto } from '../model/user-absence-dto';
import { UserAccountDetailDto } from '../model/user-account-detail-dto';
import { UserInterestStatisticsDto } from '../model/user-interest-statistics-dto';
import { UserNotificationDto } from '../model/user-notification-dto';
import { UserNotificationSettingsDto } from '../model/user-notification-settings-dto';
import { UserProfileDto } from '../model/user-profile-dto';
import { UserProfileStatisticsDto } from '../model/user-profile-statistics-dto';
import { UserShowNameDto } from '../model/user-show-name-dto';
import { VerifyCompanyRequestDataDto } from '../model/verify-company-request-data-dto';
import { BASE_PATH } from '../variables';

export interface ChangePrivateAccountToCompanyRequestParams {
    id: number;
}

export interface CreateMessageForVerifyCompanyRequestParams {
    userId: number;
    verifyCompanyRequestDataDto: VerifyCompanyRequestDataDto;
}

export interface EnableUserSubbrandFeatureFlagRequestParams {
    subbrandsEnabled: boolean;
}

export interface EncloseDocumentsToVerificationRequestRequestParams {
    /** Attachment ids (enclosed documents) */
    attachmentIds: number[];
    /** Verification request token */
    token: string;
}

export interface FindTokenRequestParams {
    xAukroToken?: string;
}

export interface FindUserProfileRequestParams {
    /** Username of user to show profile of */
    username: string;
}

export interface FindUserProfileByUsernameMinimalRequestParams {
    /** Username of user to show profile of */
    username: string;
}

export interface GenerateTokenRequestParams {
    platformType?: 'WEB' | 'OLD_MOBILE_APP' | 'NATIVE_APP_ANDROID' | 'NATIVE_APP_IOS';
    userAgent?: string;
}

export interface GetSellerInfoRequestParams {
    userId: number;
}

export interface GetUserAbsenceStatusRequestParams {
    userId: number;
}

export interface GetUserAccountDetailRequestParams {
    /** Id of user to show account details */
    id: number;
}

export interface GetUserAddressRequestParams {
    id: number;
}

export interface GetUserEmailInfoRequestParams {
    id: number;
}

export interface GetUserLoginInfoRequestParams {
    id: number;
}

export interface GetUserProfileRequestParams {
    /** Id of user to show profile of */
    id: number;
}

export interface GetUserProfileAllReceivedFeedbackRequestParams {
    feedbackFilter?: FeedbackFilter;
    /** Id of user to show profile of */
    id: number;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface GetUserProfileAllReceivedFeedbackFromBuyersRequestParams {
    feedbackFilter?: FeedbackFilter;
    /** Id of user to show profile of */
    id: number;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface GetUserProfileAllReceivedFeedbackFromSellersRequestParams {
    feedbackFilter?: FeedbackFilter;
    /** Id of user to show profile of */
    id: number;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface GetUserProfileAllSendFeedbackRequestParams {
    feedbackFilter?: FeedbackFilter;
    /** Id of user to show profile of */
    id: number;
    /** Zero-based page index (0..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
}

export interface GetUserProfileByIdAllRequestParams {
    /** Id of user to show profile of */
    id: number;
}

export interface GetUserProfileByIdMinimalRequestParams {
    /** Id of user to show profile of */
    id: number;
}

export interface GetUserProfileByUsernameAllRequestParams {
    /** Username of user to show profile of */
    username: string;
}

export interface InitialBasicDataInsertRequestParams {
    id: number;
    initBasicDataFormDto: InitBasicDataFormDto;
}

export interface RefreshTokenDataRequestParams {
    platformType?: 'WEB' | 'OLD_MOBILE_APP' | 'NATIVE_APP_ANDROID' | 'NATIVE_APP_IOS';
    userAgent?: string;
}

export interface ResendActivationEmailRequestParams {
    /** User ID */
    id: number;
}

export interface SaveUserAbsenceRequestParams {
    userAbsenceDto: UserAbsenceDto;
}

export interface SetNotificationSettingsRequestParams {
    userNotificationDto: UserNotificationDto[];
}

export interface SetOnlyVerifiedBuyersRequestParams {
    enableOnlyVerifiedBuyers: boolean;
}

export interface SuggestShowNameRequestParams {
    /** Suggest text */
    text: string;
}

export interface UpdateCompanyAccountDetailRequestParams {
    id: number;
    updateCompanyAccountDetailDto: UpdateCompanyAccountDetailDto;
}

export interface UpdateSellerInfoRequestParams {
    sellerInfoDto: SellerInfoDto;
}

export interface UpdateUserAccountDetailRequestParams {
    id: number;
    userAccountDetailDto: UserAccountDetailDto;
}

export interface UpdateUserAccountNameRequestParams {
    changeNameRequestDataDto: ChangeNameRequestDataDto;
    id: number;
}

export interface UpdateUserLoginInfoRequestParams {
    changeLoginDto: ChangeLoginDto;
    id: number;
}

export interface UpdateUserPreferenceSubbrandRequestParams {
    updateUserPreferenceSubbrandDto: UpdateUserPreferenceSubbrandDto;
}

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * Cancels user absence
   */
  public cancelUserAbsence$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserAbsenceDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'UserAbsenceDto'),
      headers,
    };
    return this.httpClient.request<UserAbsenceDto>(
      'post',
      `${ BASE_PATH }/users/absence/cancel`,
      options,
    );
  }

  /**
   * [UCU600.6] Změnit účet na podnikatelský
   * @deprecated
   */
  public changePrivateAccountToCompany$(
    requestParameters: ChangePrivateAccountToCompanyRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<CompanyAccountDetailDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'CompanyAccountDetailDto'),
      headers,
    };
    return this.httpClient.request<CompanyAccountDetailDto>(
      'put',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/account/changeType/private`,
      options,
    );
  }

  /**
   * Starts changing to company and creates a ticket request for change to company by admin
   */
  public createMessageForVerifyCompany$(
    requestParameters: CreateMessageForVerifyCompanyRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const userId: number = requestParameters.userId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.verifyCompanyRequestDataDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'userId', value: userId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/createRequestForChangeToCompany`,
      options,
    );
  }

  /**
   * Enables/disables user preference subbrand
   */
  public enableUserSubbrandFeatureFlag$(
    requestParameters: EnableUserSubbrandFeatureFlagRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      subbrandsEnabled: requestParameters.subbrandsEnabled,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/users/subbrand-enable`,
      options,
    );
  }

  /**
   * [UCS625.1] Enclose documents to user request
   */
  public encloseDocumentsToVerificationRequest$(
    requestParameters: EncloseDocumentsToVerificationRequestRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const token: string = requestParameters.token;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      attachmentIds: requestParameters.attachmentIds,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/users/verificationRequest/${ this.configuration.encodeParam({ name: 'token', value: token, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined }) }/encloseDocuments`,
      options,
    );
  }

  /**
   * Fetch token detail from token sent in the header
   */
  public findToken$(
    requestParameters: FindTokenRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<TokenDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'TokenDto'),
      headers,
    };
    return this.httpClient.request<TokenDto>(
      'get',
      `${ BASE_PATH }/token`,
      options,
    );
  }

  /**
   * [UCU025.5] Find user by username
   * @deprecated
   */
  public findUserProfile$(
    requestParameters: FindUserProfileRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserProfileDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      username: requestParameters.username,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'UserProfileDto'),
      headers,
    };
    return this.httpClient.request<UserProfileDto>(
      'post',
      `${ BASE_PATH }/users/profile`,
      options,
    );
  }

  /**
   * Find user profile by username
   */
  public findUserProfileByUsernameMinimal$(
    requestParameters: FindUserProfileByUsernameMinimalRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<NewUserProfileBaseDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      username: requestParameters.username,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'NewUserProfileBaseDto'),
      headers,
    };
    return this.httpClient.request<NewUserProfileBaseDto>(
      'get',
      `${ BASE_PATH }/users/profile/minimal`,
      options,
    );
  }

  /**
   * Generates token
   */
  public generateToken$(
    requestParameters: GenerateTokenRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<TokenDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      platformType: requestParameters.platformType,
      userAgent: requestParameters.userAgent,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'TokenDto'),
      headers,
    };
    return this.httpClient.request<TokenDto>(
      'get',
      `${ BASE_PATH }/token/generate`,
      options,
    );
  }

  /**
   * [UCU614.1] Get actual notification settings
   */
  public getNotificationSettings$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserNotificationSettingsDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'UserNotificationSettingsDto'),
      headers,
    };
    return this.httpClient.request<UserNotificationSettingsDto>(
      'get',
      `${ BASE_PATH }/users/notification/settings`,
      options,
    );
  }

  /**
   * Get seller info
   */
  public getSellerInfo$(
    requestParameters: GetSellerInfoRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<SellerInfoDto> {
    const userId: number = requestParameters.userId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'SellerInfoDto'),
      headers,
    };
    return this.httpClient.request<SellerInfoDto>(
      'get',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'userId', value: userId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/sellerInfo`,
      options,
    );
  }

  /**
   * Gets user\&#39;s absence history
   */
  public getUserAbsenceHistory$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserAbsenceDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'Array<UserAbsenceDto>'),
      headers,
    };
    return this.httpClient.request<UserAbsenceDto[]>(
      'get',
      `${ BASE_PATH }/users/absence/history`,
      options,
    );
  }

  /**
   * Gets user\&#39;s absence
   */
  public getUserAbsenceStatus$(
    requestParameters: GetUserAbsenceStatusRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserAbsenceDto> {
    const userId: number = requestParameters.userId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'UserAbsenceDto'),
      headers,
    };
    return this.httpClient.request<UserAbsenceDto>(
      'get',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'userId', value: userId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/absence`,
      options,
    );
  }

  /**
   * [UCU600.1][UCU600.3] Show formal details - private or company account
   */
  public getUserAccountDetail$(
    requestParameters: GetUserAccountDetailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<GetUserAccountDetail200Response> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'GetUserAccountDetail200Response'),
      headers,
    };
    return this.httpClient.request<GetUserAccountDetail200Response>(
      'get',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/account/detail`,
      options,
    );
  }

  /**
   * Ziskat standardni adresu uzivatele
   */
  public getUserAddress$(
    requestParameters: GetUserAddressRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<AddressDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'AddressDto'),
      headers,
    };
    return this.httpClient.request<AddressDto>(
      'get',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/account/detail/address`,
      options,
    );
  }

  /**
   * [UCU600.11] Změnit email
   */
  public getUserEmailInfo$(
    requestParameters: GetUserEmailInfoRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ChangeEmailDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'ChangeEmailDto'),
      headers,
    };
    return this.httpClient.request<ChangeEmailDto>(
      'get',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/account/detail/getEmailInfo`,
      options,
    );
  }

  /**
   * Get logged user interest statistics
   */
  public getUserInterestStatistics$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserInterestStatisticsDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'UserInterestStatisticsDto'),
      headers,
    };
    return this.httpClient.request<UserInterestStatisticsDto>(
      'get',
      `${ BASE_PATH }/users/actualStatistics`,
      options,
    );
  }

  /**
   * [UCU600.9] Změnit uživatelské jméno (login)
   */
  public getUserLoginInfo$(
    requestParameters: GetUserLoginInfoRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ChangeLoginDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'ChangeLoginDto'),
      headers,
    };
    return this.httpClient.request<ChangeLoginDto>(
      'get',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/account/detail/getLoginInfo`,
      options,
    );
  }

  /**
   * [UCU025.1] Show user profile
   * @deprecated
   */
  public getUserProfile$(
    requestParameters: GetUserProfileRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserProfileDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'UserProfileDto'),
      headers,
    };
    return this.httpClient.request<UserProfileDto>(
      'get',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/profile`,
      options,
    );
  }

  /**
   * [UCU025.2] Show user profile feedbacks - all received
   */
  public getUserProfileAllReceivedFeedback$(
    requestParameters: GetUserProfileAllReceivedFeedbackRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PagedModelFeedbackDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.feedbackFilter,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PagedModelFeedbackDto'),
      headers,
    };
    return this.httpClient.request<PagedModelFeedbackDto>(
      'post',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/profile/feedback/allReceived`,
      options,
    );
  }

  /**
   * [UCU025.2] Show user profile feedbacks - all received from buyers
   */
  public getUserProfileAllReceivedFeedbackFromBuyers$(
    requestParameters: GetUserProfileAllReceivedFeedbackFromBuyersRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PagedModelFeedbackDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.feedbackFilter,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PagedModelFeedbackDto'),
      headers,
    };
    return this.httpClient.request<PagedModelFeedbackDto>(
      'post',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/profile/feedback/allReceivedFromBuyers`,
      options,
    );
  }

  /**
   * [UCU025.2] Show user profile feedbacks - all received from sellers
   */
  public getUserProfileAllReceivedFeedbackFromSellers$(
    requestParameters: GetUserProfileAllReceivedFeedbackFromSellersRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PagedModelFeedbackDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.feedbackFilter,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PagedModelFeedbackDto'),
      headers,
    };
    return this.httpClient.request<PagedModelFeedbackDto>(
      'post',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/profile/feedback/allReceivedFromSellers`,
      options,
    );
  }

  /**
   * [UCU025.2] Show user profile feedbacks - all send
   */
  public getUserProfileAllSendFeedback$(
    requestParameters: GetUserProfileAllSendFeedbackRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PagedModelFeedbackDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      page: requestParameters.page,
      size: requestParameters.size,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.feedbackFilter,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PagedModelFeedbackDto'),
      headers,
    };
    return this.httpClient.request<PagedModelFeedbackDto>(
      'post',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/profile/feedback/allSend`,
      options,
    );
  }

  /**
   * Find user profile by id. Extended version
   */
  public getUserProfileByIdAll$(
    requestParameters: GetUserProfileByIdAllRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<NewUserProfileAllDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'NewUserProfileAllDto'),
      headers,
    };
    return this.httpClient.request<NewUserProfileAllDto>(
      'get',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/profile/all`,
      options,
    );
  }

  /**
   * Find user profile by id
   */
  public getUserProfileByIdMinimal$(
    requestParameters: GetUserProfileByIdMinimalRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<NewUserProfileBaseDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'NewUserProfileBaseDto'),
      headers,
    };
    return this.httpClient.request<NewUserProfileBaseDto>(
      'get',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/profile/minimal`,
      options,
    );
  }

  /**
   * Find user profile by username. Extended version
   */
  public getUserProfileByUsernameAll$(
    requestParameters: GetUserProfileByUsernameAllRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<NewUserProfileAllDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      username: requestParameters.username,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'NewUserProfileAllDto'),
      headers,
    };
    return this.httpClient.request<NewUserProfileAllDto>(
      'get',
      `${ BASE_PATH }/users/profile/all`,
      options,
    );
  }

  /**
   * Get user profile counters
   */
  public getUserProfileStatistics$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserProfileStatisticsDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'UserProfileStatisticsDto'),
      headers,
    };
    return this.httpClient.request<UserProfileStatisticsDto>(
      'get',
      `${ BASE_PATH }/users/userProfileStatistics`,
      options,
    );
  }

  /**
   * Initial insert of basic data (in registration)
   */
  public initialBasicDataInsert$(
    requestParameters: InitialBasicDataInsertRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<JWTInfo> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.initBasicDataFormDto,
      responseType: this.configuration.getResponseType(false, 'JWTInfo'),
      headers,
    };
    return this.httpClient.request<JWTInfo>(
      'put',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/account/detail/initBasicData`,
      options,
    );
  }

  /**
   * Check if token belongs to any newsletter subscription
   */
  public isPairedToNewsletterSubscribedUser$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<TokenPairedToNewsletterDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'TokenPairedToNewsletterDto'),
      headers,
    };
    return this.httpClient.request<TokenPairedToNewsletterDto>(
      'get',
      `${ BASE_PATH }/token/isPairedToSubscribedUser`,
      options,
    );
  }

  /**
   * Store device info to token
   */
  public refreshTokenData$(
    requestParameters: RefreshTokenDataRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      platformType: requestParameters.platformType,
      userAgent: requestParameters.userAgent,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'get',
      `${ BASE_PATH }/token/refresh-data`,
      options,
    );
  }

  /**
   * [UCU010.5] Resend user\&#39;s activation email
   */
  public resendActivationEmail$(
    requestParameters: ResendActivationEmailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<number> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'number'),
      headers,
    };
    return this.httpClient.request<number>(
      'post',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/account/resendActivationEmail`,
      options,
    );
  }

  /**
   * Creates or updates user\&#39;s absence
   */
  public saveUserAbsence$(
    requestParameters: SaveUserAbsenceRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserAbsenceDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.userAbsenceDto,
      responseType: this.configuration.getResponseType(false, 'UserAbsenceDto'),
      headers,
    };
    return this.httpClient.request<UserAbsenceDto>(
      'post',
      `${ BASE_PATH }/users/absence`,
      options,
    );
  }

  /**
   * [UCU614.1] Set notification settings
   */
  public setNotificationSettings$(
    requestParameters: SetNotificationSettingsRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.userNotificationDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/users/notification/settings`,
      options,
    );
  }

  /**
   * Set user preference - only verified buyers enabled
   */
  public setOnlyVerifiedBuyers$(
    requestParameters: SetOnlyVerifiedBuyersRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<boolean> {
    const enableOnlyVerifiedBuyers: boolean = requestParameters.enableOnlyVerifiedBuyers;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'boolean'),
      headers,
    };
    return this.httpClient.request<boolean>(
      'post',
      `${ BASE_PATH }/users/setOnlyVerifiedBuyers/${ this.configuration.encodeParam({ name: 'enableOnlyVerifiedBuyers', value: enableOnlyVerifiedBuyers, in: 'path', style: 'simple', explode: false, dataType: 'boolean', dataFormat: undefined }) }`,
      options,
    );
  }

  /**
   * Suggest user\&#39;s \&#39;show name\&#39;
   */
  public suggestShowName$(
    requestParameters: SuggestShowNameRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<UserShowNameDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      text: requestParameters.text,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<UserShowNameDto>'),
      headers,
    };
    return this.httpClient.request<UserShowNameDto[]>(
      'get',
      `${ BASE_PATH }/users/suggest/showName`,
      options,
    );
  }

  /**
   * Unsubsribe user from getting similar items in emails
   */
  public unsubscribeItemsinTransactionalEmails$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/users/email/similarItems/unsubscribe`,
      options,
    );
  }

  /**
   * [UCU600.4] Change formal details - company account
   */
  public updateCompanyAccountDetail$(
    requestParameters: UpdateCompanyAccountDetailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.updateCompanyAccountDetailDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/account/detail/updateCompanyDetail`,
      options,
    );
  }

  /**
   * [UCU615.1] Update seller info
   */
  public updateSellerInfo$(
    requestParameters: UpdateSellerInfoRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<SellerInfoDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.sellerInfoDto,
      responseType: this.configuration.getResponseType(false, 'SellerInfoDto'),
      headers,
    };
    return this.httpClient.request<SellerInfoDto>(
      'post',
      `${ BASE_PATH }/users/sellerInfo`,
      options,
    );
  }

  /**
   * [UCU600.2] Change formal details - private account
   */
  public updateUserAccountDetail$(
    requestParameters: UpdateUserAccountDetailRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.userAccountDetailDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'put',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/account/detail/updateUserDetail`,
      options,
    );
  }

  /**
   * [UCU600.5] Change name and surname (private account)
   */
  public updateUserAccountName$(
    requestParameters: UpdateUserAccountNameRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<object> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.changeNameRequestDataDto,
      responseType: this.configuration.getResponseType(false, 'object'),
      headers,
    };
    return this.httpClient.request<object>(
      'post',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/account/detail/updateName`,
      options,
    );
  }

  /**
   * [UCU600.9] Změnit uživatelské jméno (login)
   */
  public updateUserLoginInfo$(
    requestParameters: UpdateUserLoginInfoRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<ChangeLoginDto> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.changeLoginDto,
      responseType: this.configuration.getResponseType(false, 'ChangeLoginDto'),
      headers,
    };
    return this.httpClient.request<ChangeLoginDto>(
      'put',
      `${ BASE_PATH }/users/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/account/detail/updateLoginInfo`,
      options,
    );
  }

  /**
   * Update user preference subbrand
   */
  public updateUserPreferenceSubbrand$(
    requestParameters: UpdateUserPreferenceSubbrandRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.updateUserPreferenceSubbrandDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/users/subbrand`,
      options,
    );
  }

}
