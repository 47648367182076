import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';
import { HttpContext } from '@angular/common/http';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface RegisterCompanyParams {
  /** Company account details */
  companyRegistrationDto: model.CompanyRegistrationDto;
  country?: string;
  displayCountry?: string;
  displayLanguage?: string;
  displayName?: string;
  displayScript?: string;
  displayVariant?: string;
  extensionKeys?: string[];
  ISO3Country?: string;
  ISO3Language?: string;
  language?: string;
  script?: string;
  unicodeLocaleAttributes?: string[];
  unicodeLocaleKeys?: string[];
  variant?: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface RegisterUserParams {
  country?: string;
  displayCountry?: string;
  displayLanguage?: string;
  displayName?: string;
  displayScript?: string;
  displayVariant?: string;
  extensionKeys?: string[];
  ISO3Country?: string;
  ISO3Language?: string;
  language?: string;
  script?: string;
  unicodeLocaleAttributes?: string[];
  unicodeLocaleKeys?: string[];
  /** User details */
  user: model.UserRegistrationDto;
  variant?: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SimilarLoginsParams {
  /** login */
  login: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ValidateEmailParams {
  /** E-mail */
  emailDto: model.EmailDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ValidateLoginParams {
  /** Login */
  login: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface RegistrationValidationParams {
  registrationValidationDto: model.RegistrationValidationDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root',
})
export class RegistrationService extends RestHttpClientService {
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public registerCompany(params: RegisterCompanyParams, headers: { [key: string]: string } = {}, context?: HttpContext): Observable<Object> {
    let bodyParams = params.companyRegistrationDto;
    let queryParams = {
      country: params.country,
      displayCountry: params.displayCountry,
      displayLanguage: params.displayLanguage,
      displayName: params.displayName,
      displayScript: params.displayScript,
      displayVariant: params.displayVariant,
      extensionKeys: params.extensionKeys,
      ISO3Country: params.ISO3Country,
      ISO3Language: params.ISO3Language,
      language: params.language,
      script: params.script,
      unicodeLocaleAttributes: params.unicodeLocaleAttributes,
      unicodeLocaleKeys: params.unicodeLocaleKeys,
      variant: params.variant,
    };
    let formDataParams;
    return this.postGenerated(`/registration/registerCompany`, bodyParams, queryParams, formDataParams, headers, null, context);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public registerUser(params: RegisterUserParams, headers: { [key: string]: string } = {}, context?: HttpContext): Observable<Object | model.UserLoginDto> {
    let queryParams = {
      country: params.country,
      displayCountry: params.displayCountry,
      displayLanguage: params.displayLanguage,
      displayName: params.displayName,
      displayScript: params.displayScript,
      displayVariant: params.displayVariant,
      extensionKeys: params.extensionKeys,
      ISO3Country: params.ISO3Country,
      ISO3Language: params.ISO3Language,
      language: params.language,
      script: params.script,
      unicodeLocaleAttributes: params.unicodeLocaleAttributes,
      unicodeLocaleKeys: params.unicodeLocaleKeys,
      variant: params.variant,
    };
    let bodyParams = params.user;
    let formDataParams;
    return this.postGenerated(`/registration/registerUser`, bodyParams, queryParams, formDataParams, headers, null, context);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public similarLogins(params: SimilarLoginsParams): Observable<string[]> {
    let pathParams = {
      login: params.login,
    };
    let queryParams;
    return this.getGenerated(`/registration/similarLogins/${ pathParams.login }`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public validateEmail(params: ValidateEmailParams, headers: { [key: string]: string } = {}): Observable<boolean> {
    let bodyParams = params.emailDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/registration/validateEmail`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public validateLogin(params: ValidateLoginParams): Observable<boolean> {
    let pathParams = {
      login: params.login,
    };
    let queryParams;
    return this.getGenerated(`/registration/validateLogin/${ pathParams.login }`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public userAccountState(params: RegistrationValidationParams, headers: { [key: string]: string } = {}): Observable<model.UserAccountStateDto> {
    let bodyParams = params.registrationValidationDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/registration/accountState`, bodyParams, queryParams, formDataParams, headers);
  }
}
