import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KeyboardUtil } from '@util/util/keyboard.util';
import { PhoneNumberService } from '@shared/services/phone-number/phone-number.service';
import { take, takeUntil } from 'rxjs/operators';
import { PhoneNumberValidationDto } from '@api/generated/defs/PhoneNumberValidationDto';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { StringUtils } from '@util/util/string.utils';
import { DomainService } from '@shared/platform/domain.service';
import { LocaleConstants } from '@shared/platform/locale.constants';
import { ICULocale } from '@shared/platform/icu-locale.model';

const DEFAULT_PREFIX: Map<ICULocale, string> = new Map([
  [LocaleConstants.CZ_LOCALE, '420'],
  [LocaleConstants.SK_LOCALE, '421'],
]);

@Component({
  selector: 'auk-phone-field',
  templateUrl: 'phone-field.component.html',
  styleUrls: ['phone-field.component.scss'],
})
export class PhoneFieldComponent extends NgUnsubscribe {

  public prefix: string = '';         // just the numeric part without '+'
  public main: string = '';           // phone number to be shown in the input (formatted if the number is valid, unformatted otherwise)
  private mainFormatted: string = ''; // with spaces, e.g. 774 123 456, used to show the formatted number when user isn't editing it
  private mainClean: string = '';     // without spaces, e.g. 774123456, used to show the clean number without spaces when user's editing it
  private _control: FormControl;

  @ViewChild('phoneInputElm', { static: false }) public phoneInputElm: ElementRef<HTMLInputElement>;

  @Input() public isLabelShown: boolean = false;

  constructor(
    private readonly phoneNumberService: PhoneNumberService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly domainService: DomainService,
  ) {
    super();
  }

  @Input()
  public set control(control: FormControl) {
    this._control = control;
    this.formatPhoneNumber(DEFAULT_PREFIX.get(this.domainService.locale), '', true);
  }

  public get control(): FormControl {
    return this._control;
  }

  private get cleanedValue(): string | null {
    if (!this.isPrefixEmpty && this.main) {
      return StringUtils.stripWhitespace(`+${ this.prefix }${ this.main }`);
    }
    return null;
  }

  /**
   * Set new value to form control only if changed. Setting the same value again triggers async validators in parent FormControl
   * which is not necessary.
   */
  public processValue(): void {
    const currentValue: string = StringUtils.stripWhitespace(this.control.value);
    const cleanedValue: string = this.cleanedValue;

    if (cleanedValue !== currentValue) {
      this.control.setValue(cleanedValue);
      this.control.markAsTouched();
    }
  }

  public onFocus(): void {
    this.main = this.mainClean;
  }

  public onBlur(): void {
    this.processValue();
    this.formatPhoneNumber(this.prefix, this.main, false);
  }

  public keyPress(event): void {
    if(!KeyboardUtil.isNumberPressed(event)) {
      event.preventDefault();
    }
    this.control.markAsTouched();
  }

  public get isPrefixEmpty(): boolean {
    return !this.prefix;
  }

  public get hasError(): boolean {
    return !!(this.control.touched && this.control.errors);
  }

  public focus(): void {
    const focusElement = this.phoneInputElm?.nativeElement;
    focusElement?.focus();
  }

  /**
   * Calls a BE endpoint to format the phone number stored in the <code>control.value</code> which is basically input from the user,
   * so it can contain phone number with or without prefix, valid or invalid.
   * <li>if the phone number is <b>valid</b>, the response is parsed and the single parts are saved to <code>prefix</code>
   * and <code>main</code> correspondingly and the clean format (without spaces) of the whole phone number is stored
   * in <code>control.value</code> (if it is desired for triggering the async validations in parent components)
   * <li>if the phone number is <b>invalid</b>, <code>prefix</code> and <code>main</code> have specific handling depending on situation
   * @param prefixForInvalid prefix of the phone number which should be shown in case the number is invalid
   * @param mainFormattedForInvalid main part of the phone number which should be shown in case the number is invalid
   * @param triggerAsyncValidatorsInParent whether to trigger async validators in the parent components, not needed e.g. when
   * <code>onBlur()</code> because it's done already in <code>processValue()</code> which is called before
   */
  private formatPhoneNumber(prefixForInvalid: string, mainFormattedForInvalid: string, triggerAsyncValidatorsInParent: boolean): void {
    if (StringUtils.isBlank(this.control.value)) {
      // handle the phone number as invalid straight away if no value is provided (it doesn't make sense to call BE)
      this.handlePhoneNumberFormatting(null, prefixForInvalid, mainFormattedForInvalid, triggerAsyncValidatorsInParent);
      return;
    }

    // if the value is provided, call BE in order to format the phone number (if the BE call fails, handle the failure as an invalid number)
    this.phoneNumberService.validatePhoneNumber(this.control.value)
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: (phoneNumber: PhoneNumberValidationDto) => {
          this.handlePhoneNumberFormatting(phoneNumber, prefixForInvalid, mainFormattedForInvalid, triggerAsyncValidatorsInParent);
        },
        error: () => {
          this.handlePhoneNumberFormatting(null, prefixForInvalid, mainFormattedForInvalid, triggerAsyncValidatorsInParent);
        },
      });
  }

  private handlePhoneNumberFormatting(
    phoneNumber: PhoneNumberValidationDto,
    prefixForInvalid: string,
    mainFormattedForInvalid: string,
    triggerAsyncValidatorsInParent: boolean,
  ): void {
    if (phoneNumber?.valid) {
      this.prefix = phoneNumber.countryCode;
      this.mainFormatted = phoneNumber.phoneNumber;
    } else {
      this.prefix = prefixForInvalid;
      this.mainFormatted = mainFormattedForInvalid;
    }
    this.mainClean = StringUtils.stripWhitespace(this.mainFormatted);
    this.main = this.mainFormatted;

    if (triggerAsyncValidatorsInParent) {
      this.control.setValue(this.cleanedValue);
    }

    this.changeDetectorRef.markForCheck();
  }

}
