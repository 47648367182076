import { ColorVariant } from '../class/color-variant.class';
import { COLORS } from './colors';
import { explicitObjectLiteralValueType } from '@util/helper-functions/explicit-object-value-type';

export const COLOR_VARIANTS = explicitObjectLiteralValueType({
  PRIMARY: new ColorVariant(
    COLORS.primary.base,
    COLORS.primary.hover,
    COLORS.primary.active,
    COLORS.primary.disabled,
  ),
  PRIMARY_PERSISTENT: new ColorVariant(
    COLORS['primary-persistent'].base,
    COLORS['primary-persistent'].hover,
    COLORS['primary-persistent'].active,
    COLORS['primary-persistent'].disabled,
  ),
  PRIMARY_50: new ColorVariant(
    COLORS['primary-50'].base,
  ),
  PRIMARY_400: new ColorVariant(
    COLORS['primary-400'].base,
  ),
  PRIMARY_700: new ColorVariant(
    COLORS['primary-700'].base,
  ),
  PRIMARY_CONTRAST: new ColorVariant(
    COLORS.contrast.base,
    COLORS.primary.base,
    COLORS.primary.active,
  ),
  PRIMARY_OPPOSITE: new ColorVariant(
    COLORS.primary.base,
    COLORS.contrast.base,
    COLORS.contrast.base,
  ),
  // used as a dynamic color that has a value according to the subbrand
  PRIMUS_300: new ColorVariant(
    COLORS['primus-300'].base,
  ),
  SECONDARY: new ColorVariant(
    COLORS.secondary.base,
    COLORS.secondary.hover,
    COLORS.secondary.active,
    COLORS.secondary.disabled,
  ),
  VIP: new ColorVariant(
    COLORS.vip.base,
    COLORS.vip.hover,
    COLORS.vip.active,
    COLORS.vip.disabled,
  ),
  INFO: new ColorVariant(
    COLORS.info.base,
    COLORS.info.hover,
    COLORS.info.active,
    COLORS.info.disabled,
  ),
  SUCCESS: new ColorVariant(
    COLORS.success.base,
    COLORS.success.hover,
    COLORS.success.active,
    COLORS.success.disabled,
  ),
  SUCCESS_50: new ColorVariant(
    COLORS['success-50'].base,
  ),
  SUCCESS_100: new ColorVariant(
    COLORS['success-100'].base,
  ),
  SUCCESS_200: new ColorVariant(
    COLORS['success-200'].base,
  ),
  SUCCESS_300: new ColorVariant(
    COLORS['success-300'].base,
  ),
  SUCCESS_400: new ColorVariant(
    COLORS['success-400'].base,
  ),
  SUCCESS_600: new ColorVariant(
    COLORS['success-600'].base,
  ),
  SUCCESS_700: new ColorVariant(
    COLORS['success-700'].base,
  ),
  WARNING: new ColorVariant(
    COLORS.warning.base,
    COLORS.warning.hover,
    COLORS.warning.active,
    COLORS.warning.disabled,
  ),
  WARNING_50: new ColorVariant(
    COLORS['warning-50'].base,
    COLORS['warning-50'].hover,
    COLORS['warning-50'].active,
    COLORS['warning-50'].disabled,
  ),
  DANGER: new ColorVariant(
    COLORS.danger.base,
    COLORS.danger.hover,
    COLORS.danger.active,
    COLORS.danger.disabled,
  ),
  DANGER_50: new ColorVariant(
    COLORS['danger-50'].base,
  ),
  DANGER_100: new ColorVariant(
    COLORS['danger-100'].base,
  ),
  DANGER_300: new ColorVariant(
    COLORS['danger-300'].base,
  ),
  DANGER_600: new ColorVariant(
    COLORS['danger-600'].base,
  ),
  CONTRAST: new ColorVariant(
    COLORS.contrast.base,
    COLORS.contrast.hover,
    COLORS.contrast.active,
    COLORS.contrast.disabled,
  ),
  CONTRAST_SILVERSTEIN_200: new ColorVariant(
    COLORS.contrast.base,
    COLORS['silverstein-200'].hover,
    COLORS['silverstein-200'].active,
    COLORS.contrast.disabled,
  ),
  TERTIARY: new ColorVariant(
    COLORS.tertiary.base,
    COLORS.tertiary.hover,
    COLORS.tertiary.active,
    COLORS.tertiary.disabled,
  ),
  TERTIARY_400: new ColorVariant(
    COLORS['tertiary-400'].base,
  ),
  TERTIARY_300: new ColorVariant(
    COLORS['tertiary-300'].base,
  ),
  TERTIARY_500: new ColorVariant(
    COLORS['tertiary-500'].base,
  ),
  TERTIARY_600: new ColorVariant(
    COLORS['tertiary-600'].base,
  ),
  TERTIARY_700: new ColorVariant(
    COLORS['tertiary-700'].base,
  ),
  CONTRAST_OPPOSITE: new ColorVariant(
    COLORS['contrast-opposite'].base,
  ),
  TERTIARY_50: new ColorVariant(
    COLORS['tertiary-50'].base,
  ),
  TERTIARY_200: new ColorVariant(
    COLORS['tertiary-200'].base,
  ),
  SILVERSTEIN_50: new ColorVariant(
    COLORS['silverstein-50'].base,
  ),
  SILVERSTEIN_50_200: new ColorVariant(
    COLORS['silverstein-50'].base,
    COLORS['silverstein-200'].base,
  ),
  SILVERSTEIN_100: new ColorVariant(
    COLORS['silverstein-100'].base,
  ),
  SILVERSTEIN_200: new ColorVariant(
    COLORS['silverstein-200'].base,
  ),
  SILVERSTEIN_300: new ColorVariant(
    COLORS['silverstein-300'].base,
  ),
  SILVERSTEIN_400: new ColorVariant(
    COLORS['silverstein-400'].base,
  ),
  SILVERSTEIN_500: new ColorVariant(
    COLORS['silverstein-500'].base,
  ),
  SILVERSTEIN_600: new ColorVariant(
    COLORS['silverstein-600'].base,
  ),
  SILVERSTEIN_600_500: new ColorVariant(
    COLORS['silverstein-600'].base,
    COLORS['silverstein-500'].base,
    COLORS['silverstein-500'].base,
    COLORS['silverstein-600'].base,
  ),
  SILVERSTEIN_700: new ColorVariant(
    COLORS['silverstein-700'].base,
  ),
  SILVERSTEIN_800: new ColorVariant(
    COLORS['silverstein-800'].base,
  ),
  SILVERSTEIN_900: new ColorVariant(
    COLORS['silverstein-900'].base,
  ),
  SILVERSTEIN_900_CONTRAST: new ColorVariant(
    COLORS['silverstein-900'].base,
    COLORS.contrast.base,
  ),
  SILVERSTEIN_100_PRIMARY: new ColorVariant(
    COLORS['silverstein-100'].base,
    COLORS.primary.base,
  ),
  // Mainly used as SECONDARY variant for `auk-button`
  SILVERSTEIN_200_300_400_100: new ColorVariant(
    COLORS['surface-3'].base,
    COLORS['surface-4'].base,
    COLORS['silverstein-400'].base,
    COLORS['silverstein-100'].base,
  ),
  // Mainly used as DARK SECONDARY variant for `auk-button`
  SILVERSTEIN_600_500_400_800: new ColorVariant(
    COLORS['silverstein-600'].base,
    COLORS['silverstein-500'].base,
    COLORS['silverstein-400'].base,
    COLORS['silverstein-800'].base,
  ),
  JUNGLE_GREEN_500: new ColorVariant(
    COLORS.contrast.base,
    COLORS['jungle-green-500'].base,
  ),
  JUNGLE_GREEN_500_OPPOSITE: new ColorVariant(
    COLORS['jungle-green-500'].base,
    COLORS.contrast.base,
  ),
  JUNGLE_GREEN_600: new ColorVariant(
    COLORS['jungle-green-600'].base,
  ),
  ROYAL_BLUE_500: new ColorVariant(
    COLORS['royal-blue-500'].base,
  ),
  PRIMARY_CONTRAST_OPPOSITE: new ColorVariant(
    COLORS['tertiary-900'].base,
    COLORS.primary.base,
    COLORS.primary.active,
    COLORS.primary.disabled,
  ),
  ANTIQUE: new ColorVariant(
    COLORS['antique'].base,
  ),
  BAZAAR: new ColorVariant(
    COLORS['bazaar'].base,
  ),
  ANTIQUE_LIGHT: new ColorVariant(
    COLORS['antique-light'].base,
  ),
  ANTIQUE_400: new ColorVariant(
    COLORS['antique-400'].base,
  ),
  BAZAAR_LIGHT: new ColorVariant(
    COLORS['bazaar-light'].base,
  ),
  SURFACE_4: new ColorVariant(
    COLORS['surface-4'].base,
  ),
  SURFACE_3: new ColorVariant(
    COLORS['surface-3'].base,
  ),
  SURFACE_NEUTRAL_2: new ColorVariant(
    COLORS['surface-neutral-2'].base,
  ),
  TRANSPARENT: new ColorVariant(
    COLORS['transparent'].base,
    COLORS.contrast.base,
    COLORS['silverstein-100'].base,
    COLORS['transparent'].base,
  ),
  TRANSPARENT_PRIMARY: new ColorVariant(
    COLORS['transparent'].base,
    COLORS.primary.base,
  ),
}, ColorVariant);
