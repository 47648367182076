import { ListingTypeConfigModel } from '../model/listing-type-config.model';
import { ListingType } from '../model/listing-type.enum';

export const LISTING_TYPES_CONFIG: Map<ListingType, ListingTypeConfigModel> = new Map<ListingType, ListingTypeConfigModel>([
  [ListingType.APV_INTERNAL, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: false,
    listingDescriptionKey: null,
    pageTitleKey: 'APV_INTERNAL_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'APV_INTERNAL_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'APV_INTERNAL_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SEARCH',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      internalListingType: 'APV',
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.BLACK_FRIDAY, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'SPECIAL_BLACK_FRIDAY_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SPECIAL_BLACK_FRIDAY_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'SPECIAL_BLACK_FRIDAY_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SPECIAL_RETAIL_PRICE',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: false,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      specialFlagPriceHits: true,
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.CATEGORY_OFFERS, {
    isCategoryListingType: true,
    bannerPageType: 'CATEGORY_LISTING',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: null,
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: null,
    searchPlaceholderKey: null,
    clickMeasurementPageType: 'CATEGORY_LISTING',
    measurementObjectCode: 'L_CATEGORY',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {},
    noPriority: false,
    showAttributeTiles: true,
  }],
  [ListingType.CRAZY_PRICES_INTERNAL, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: false,
    listingDescriptionKey: null,
    pageTitleKey: 'CRAZY_PRICES_INTERNAL_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'CRAZY_PRICES_INTERNAL_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'CRAZY_PRICES_INTERNAL_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SEARCH',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      internalListingType: 'CRAZY_PRICE',
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.HOT_AUCTIONS, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: 'chilli',
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'HOT_AUCTIONS_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'HOT_AUCTIONS_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'HOT_AUCTIONS_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_HOT_AUCTIONS',
    showHeading: true,
    itemTypeControlDisabled: true,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      hotAuction: true,
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.INTERESTING_OFFERS, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'INTERESTING_OFFERS_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'INTERESTING_OFFERS_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: null,
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SEARCH',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: false,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      ppMainPage: true,
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.LANDING_PAGE, {
    isCategoryListingType: true,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: null,
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'LANDING_PAGE_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: null,
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_LANDING_PAGE',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      landingPage: true,
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.SEARCH, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: null,
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SEARCH_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: null,
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SEARCH',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {},
    noPriority: false,
    showAttributeTiles: true,
  }],
  [ListingType.INDEXED_SEARCH, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: null,
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SEARCH_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: null,
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SEARCH',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {},
    noPriority: false,
    showAttributeTiles: true,
  }],
  [ListingType.FAVOURITE_SEARCH, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: null,
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SEARCH_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: null,
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_FAVOURITE_SEARCH',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      favouriteSearch: true,
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.SMALL_NEW_SELLERS_INTERNAL, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: false,
    listingDescriptionKey: null,
    pageTitleKey: 'SMALL_NEW_SELLERS_INTERNAL_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SMALL_NEW_SELLERS_INTERNAL_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'SMALL_NEW_SELLERS_INTERNAL_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SEARCH',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      internalListingType: 'SMALL_NEW_SELLERS',
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.SPECIAL_300_CZK, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'SPECIAL_300_CZK_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SPECIAL_300_CZK_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'SPECIAL_300_CZK_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SPECIAL_300CZK',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      specialFlagThreeHundred: true,
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.SPECIAL_ENDING, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'SPECIAL_ENDING_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SPECIAL_ENDING_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'SPECIAL_ENDING_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_ENDING_SOON',
    showHeading: true,
    itemTypeControlDisabled: true,
    showFavouriteControl: false,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      specialFlagEndingSoon: true,
    },
    noPriority: true,
    showAttributeTiles: false,
  }],
  [ListingType.SPECIAL_FROM_1, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'SPECIAL_FROM_1_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SPECIAL_FROM_1_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'SPECIAL_FROM_1_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_FROM_1CZK',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      specialFlagBasicStartingPrice: true,
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.USER_OFFERS, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: true,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'USER_OFFERS_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'USER_OFFERS_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'USER_OFFERS_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'PUBLIC_USER_PROFILE',
    measurementObjectCode: 'L_SELLER_ITEMS',
    showHeading: false,
    itemTypeControlDisabled: false,
    showFavouriteControl: false,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: false,
    specificFilters: {},
    noPriority: false,
    showAttributeTiles: true,
  }],
  [ListingType.USER_OFFERS_SEARCH, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: true,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'USER_OFFERS_SEARCH_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'USER_OFFERS_SEARCH_EXTRA_ITEM',
    searchPlaceholderKey: null,
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SELLER_SEARCH',
    showHeading: false,
    itemTypeControlDisabled: false,
    showFavouriteControl: false,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: false,
    specificFilters: {
      searchRedirectDisabled: true,
    },
    noPriority: false,
    showAttributeTiles: true,
  }],
  [ListingType.MULTI_USER_OFFERS, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: true,
    seoIndex: false,
    listingDescriptionKey: null,
    pageTitleKey: null,
    pageSubtitleKey: 'MULTI_USER_OFFERS_SUBTITLE',
    breadcrumbsExtraItemKey: 'MULTI_USER_OFFERS_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'MULTI_USER_OFFERS_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SEARCH',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: false,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {},
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.ITEMS_COLLECTION, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: false,
    listingDescriptionKey: null,
    pageTitleKey: null,
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: null,
    searchPlaceholderKey: null,
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_ITEMS_COLLECTION_DETAIL',
    showHeading: true,
    itemTypeControlDisabled: true,
    showFavouriteControl: false,
    showSettingsControl: false,
    showParamFilter: false,
    showRecommendedCategories: false,
    specificFilters: {},
    noPriority: true,
    showAttributeTiles: false,
  }],
  [ListingType.NEW_OFFERS, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'SPECIAL_NEW_OFFERS_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SPECIAL_NEW_OFFERS_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'SPECIAL_NEW_OFFERS_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SPECIAL_NEW_ITEMS',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      specialFlagNewOffers: true,
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.POPULAR_OFFERS, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'SPECIAL_POPULAR_OFFERS_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SPECIAL_POPULAR_OFFERS_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'SPECIAL_POPULAR_OFFERS_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SPECIAL_POPULAR_ITEMS',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      specialFlagPopularOffers: true,
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.PRICE_HITS, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'SPECIAL_PRICE_HITS_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SPECIAL_PRICE_HITS_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'SPECIAL_PRICE_HITS_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SPECIAL_RETAIL_PRICE',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      specialFlagPriceHits: true,
    },
    noPriority: false,
    showAttributeTiles: false,
  }],
  [ListingType.PREMIUM_OFFERS, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'SPECIAL_PREMIUM_OFFERS_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SPECIAL_PREMIUM_OFFERS_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'SPECIAL_PREMIUM_OFFERS_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SPECIAL_PREMIUM',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      specialFlagPremiumOffers: true,
    },
    noPriority: true,
    showAttributeTiles: false,
  }],
  [ListingType.CHARITY_OFFERS, {
    isCategoryListingType: false,
    bannerPageType: 'SEARCH',
    headingSvgIconName: null,
    hideGoBackButton: false,
    seoIndex: true,
    listingDescriptionKey: null,
    pageTitleKey: 'SPECIAL_CHARITY_OFFERS_TITLE',
    pageSubtitleKey: null,
    breadcrumbsExtraItemKey: 'SPECIAL_CHARITY_OFFERS_BREADCRUMBS_EXTRA_ITEM',
    searchPlaceholderKey: 'SPECIAL_CHARITY_OFFERS_SEARCH_PLACEHOLDER',
    clickMeasurementPageType: 'SEARCH',
    measurementObjectCode: 'L_SPECIAL_CHARITY',
    showHeading: true,
    itemTypeControlDisabled: false,
    showFavouriteControl: true,
    showSettingsControl: true,
    showParamFilter: true,
    showRecommendedCategories: true,
    specificFilters: {
      specialFlagCharityOffers: true,
    },
    noPriority: true,
    showAttributeTiles: false,
  }],
]);
