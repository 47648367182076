import { Inject, Injectable, Optional } from '@angular/core';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { AukWindow } from '@shared/model/auk-window.interface';
import { REQUEST } from '../../../server/const/request';
import { ExpressRequest } from '@common/platform/model/request';
import { Nil } from '@util/helper-types/nil';

@Injectable({
  providedIn: 'root',
})
export class UserAgentService {

  private readonly USER_AGENT_HEADER_NAME = 'user-agent';

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
    @Optional() @Inject(REQUEST) private readonly request: ExpressRequest,
    private readonly platformCommonService: PlatformCommonService,
  ) {
  }

  public get userAgent(): string | Nil {
    return this.platformCommonService.isBrowser ? this.userAgentBrowser : this.userAgentServer;
  }

  private get userAgentBrowser(): string | Nil {
    return this.window?.navigator?.userAgent;
  }

  private get userAgentServer(): string | Nil {
    return this.request?.headers?.[this.USER_AGENT_HEADER_NAME];
  }

}
