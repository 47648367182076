import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FavouritePopoverDataService } from '@shared/favourite/service/favourite-popover-data.service';
import { TimeService } from '@common/time/service/time.service';
import { DateUtils } from '@util/util/date.utils';
import { debounce, map, pairwise, shareReplay, switchMap } from 'rxjs/operators';
import { Duration } from 'moment-mini-ts';
import { ArrayUtils } from '@util/util/array.utils';
import { distinctUntilChangedDeep } from '@util/rxjs-operators/distinct-until-changed-deep';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { WatchingItemPreviewDto } from '@api/aukro-api/model/watching-item-preview-dto';
import { ProductStateUtils } from '@shared/item-detail/service/mapper/base-item/utils/product-state.utils';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { FavouritePopoverDataProviderService } from '@shared/favourite/service/favourite-popover-data-provider.service';
import { combineLatestWithStart$ } from '@util/rxjs-operators/combine-latest-with-start';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { Nil } from '@util/helper-types/nil';

@Injectable({
  providedIn: 'root',
})
export class FavouritePopoverAsyncUpdateHelperService {

  /**
   * @returns items, which should be asynchronously updated (by WS or backup polling)
   */
  public readonly itemsReadyForAsyncUpdate$: Observable<WatchingItemPreviewDto[] | Nil>;

  /**
   * @returns same like {@link itemsReadyForAsyncUpdate$} but returns only item ids
   */
  public readonly itemsIdsReadyForAsyncUpdate$: Observable<number[] | Nil>;

  /**
   * Add debounce time for open -> close state change for popover, so we don't "spam" registration/deregistration of WS topics
   */
  private readonly debounceTimeForPopoverClosedEvent: number = DateUtils.convertSecondsToMilliseconds(30);

  constructor(
    private readonly favouritePopoverDataProviderService: FavouritePopoverDataProviderService,
    private readonly favouritePopoverDataService: FavouritePopoverDataService,
    private readonly timeService: TimeService,
    private readonly authenticationService: AuthenticationService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    this.itemsReadyForAsyncUpdate$ = this.getItemsReadyForAsyncUpdate$();
    this.itemsIdsReadyForAsyncUpdate$ = this.getItemIdsReadyForAsyncUpdate$();
  }

  /**
   * This method emits items which meets all conditions for initiating async update
   *
   * - User must be logged in
   * - Every item must meet all conditions @see {@link canDoAsyncUpdate}
   * - If popover is closed, we only need to init async update for first urgent item
   * - If popover is opened, we need to init async update for all items
   */
  private getItemsReadyForAsyncUpdate$(): Observable<WatchingItemPreviewDto[] | Nil> {
    // listen to login/logout
    return this.authenticationService.getLoginStatusChangeWithStartValueDistinct$()
      .pipe(
        switchMap((isLoggedIn) => {
          if (!isLoggedIn) {
            return of(null);
          }

          return combineLatestWithStart$([
            // listen to popover data
            this.favouritePopoverDataService.popoverData$,
            // listen to popover opened change
            this.favouritePopoverDataService.popoverOpened$
              .pipe(
                /**
                 * @see {@link debounceTimeForPopoverOpenedEvent}
                 */
                pairwise(),
                debounce(([prev, current]) => {
                  // debounce only open -> close case (in other cases we want this to emit asap)
                  if (prev && !current) {
                    return this.ngZoneUtilService.timerOut$(this.debounceTimeForPopoverClosedEvent);
                  }
                  return of(null);
                }),
                // map back to current value
                map(([_, curr]) => curr),
              ),
            // get remaining threshold duration, when can we start ASYNC update
            this.favouritePopoverDataProviderService.asyncUpdateThresholdDuration$,
            this.favouritePopoverDataService.firstToEndPopoverItem$(),
          ])
            .pipe(
              switchMap((
                [
                  popoverData,
                  isPopoverOpened,
                  asyncThresholdDuration,
                  firstToEndPopoverItem,
                ],
              ) => {

                // get items for which we can register async update
                const itemsReadyToAsync = this.getItemsAvailableForAsyncUpdate(
                  popoverData?.favouriteItemsPreviewDto?.favouriteItemsPreview,
                  asyncThresholdDuration,
                );

                // handle empty array
                if (ArrayUtils.isEmpty(itemsReadyToAsync)) {
                  return of(null);
                }

                // additionally filter, based on popover state
                const itemsReadyForAsyncBasedOnPopoverState: WatchingItemPreviewDto[] = [];
                // if popover is closed, init async only for first urgent item
                if (!isPopoverOpened) {
                  if (
                    // push only if it is not nil
                    isNotNil(firstToEndPopoverItem)
                    // the item must be also ready for async
                    && itemsReadyToAsync.some((item) => item.itemId === firstToEndPopoverItem.itemId)
                  ) {
                    itemsReadyForAsyncBasedOnPopoverState.push(firstToEndPopoverItem);
                  }
                } else {
                  // if opened, init async for all items
                  itemsReadyForAsyncBasedOnPopoverState.push(...itemsReadyToAsync);
                }

                return of(itemsReadyForAsyncBasedOnPopoverState);
              }),
            );
        }),
        // share this value, as it is not simple logic for calculation
        shareReplay(1),
      );
  }

  /**
   * same like {@link getItemsReadyForAsyncUpdate$} but returns only list of item ids
   */
  private getItemIdsReadyForAsyncUpdate$(): Observable<number[] | Nil> {
    return this.itemsReadyForAsyncUpdate$
      .pipe(
        // map to item ids
        map((items) => (
          items?.map((item) => item.itemId)),
        ),
        // distinct, so we don't spam the async channel, if itemIds are same
        distinctUntilChangedDeep(),
        // share this value, as it is not simple logic for calculation
        shareReplay(1),
      );
  }

  private getItemsAvailableForAsyncUpdate(
    favouriteItemsPreview: WatchingItemPreviewDto[],
    asyncThresholdDuration: Duration,
  ): WatchingItemPreviewDto[] {
    return favouriteItemsPreview?.filter((item) => this.canDoAsyncUpdate(item, asyncThresholdDuration)) ?? [];
  }

  /**
   * Tells whether we can register async update for given item (trough WS/polling)
   */
  private canDoAsyncUpdate(
    item: WatchingItemPreviewDto,
    asyncThresholdDuration: Duration,
  ): boolean {
    // item must be auction
    return ProductStateUtils.isBiddingOffer({ itemType: item.itemTypeEnum })
      // is not scheduled
      && !ProductStateUtils.isScheduled(item)
      // is not ended
      && !ProductStateUtils.isEnded(item)
      // has ending time
      && ProductStateUtils.hasEndingTime({ endingTime: item?.endTime })
      // remaining time must be lower than given threshold
      && this.timeService.isRemainingTimeLowerThanThreshold(item?.endTime, asyncThresholdDuration);
  }

}
