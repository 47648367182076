import { CategoryMenuItem } from '@shared/app-header/model/category-menu-item.model';
import { TileModel } from '@common/ui-kit/component/tile/model/tile.model';
import { CategoryTileData } from '@shared/app-header/component/category-dialog/model/category-tile-data.model';
import { Nil } from '@util/helper-types/nil';
import { CategoryMenu } from '@shared/app-header/model/category-menu.model';
import { CategoryTileListContainerModel } from '../../category-dialog/model/category-tile-list-container.model';
import { TabControlModel } from '@common/ui-kit/component/tab-control/model/tab-control';
import { ICULocale } from '@shared/platform/icu-locale.model';
import { CategoryTileListModel } from '@shared/app-header/component/category-dialog/model/category-tile-list.model';
import { TileModelWithCustomColumns } from '@common/ui-kit/component/tile/model/tile-model-with-custom-columns.model';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';

export class HeaderCategoryUtil {

  public static convertToTilesCompatible(
    item: CategoryMenuItem,
    tabId?: string | Nil,
    columnsOnMobile?: number,
    isMdAndLower?: boolean,
  ):
    TileModelWithCustomColumns<CategoryTileData> {
    return {
      label: { defaultValue: item?.label },
      defaultIcon: {
        colorCombination: item.background,
        source: item?.image,
        type: 'SVG',
        size: '2XMD',
      },
      colorCombination: item.background,
      link: item.url,
      type: 'IMAGE_TEXT',
      columnsOnMobile,
      suffixIcon: isMdAndLower && item.onMobileOperation
        ? {
          colorCombination: item.background,
          source: 'chevron_right',
          type: 'MATERIAL',
          size: 'XL',
        }
        : null,
      data: {
        onDesktopOperation: item.onDesktopOperation,
        onMobileOperation: item.onMobileOperation,
        categoryId: item.categoryId,
        link: item.url,
        label: item.label,
        subbrand: item.subbrand,
        type: tabId,
        moCode: item.moCode,
      },
    };
  }

  public static processHamburgerMenuData(
    data: string,
    currentLocale: ICULocale,
    isNativeApp: boolean,
    responsivenessService: ResponsivenessService,
  ): {
    categoryTabList: TabControlModel[];
    tileListContainer: CategoryTileListContainerModel;
  } {
    const hamburgerData = JSON.parse(data) as CategoryMenu;
    const isMdAndLower = responsivenessService.isMdAndLower;

    if (!hamburgerData) {
      return { categoryTabList: [], tileListContainer: { sections: { CONTENT: null, HEADER: null } } };
    }

    const tabsMenu = hamburgerData.items.find((item) => item?.type === 'tabsMenu');
    const tilesContainers = hamburgerData.items;

    const categoryTabList = tabsMenu?.tabs?.map((item) => ({
      label: { defaultValue: item?.label },
      id: item?.id,
      backgroundColor: item?.background,
    })) as TabControlModel[];

    const tilesContainersBody: CategoryTileListModel[] = [];
    const tilesContainersHeader: CategoryTileListModel[] = [];
    tilesContainers.forEach((tiles) => {
      if (!responsivenessService.isActiveWidthType(tiles.visible)) {
        return;
      }
      if (tiles?.type === 'tabsMenu') {
        tiles?.tabs.forEach((tab) => {
          tab?.items.forEach((itemsContainer) => {
            tilesContainersBody.push(
              itemsContainer.items.map((item) =>
                HeaderCategoryUtil.convertToTilesCompatible(
                  item,
                  tab?.id,
                  itemsContainer.columnsOnMobile,
                  isMdAndLower,
                ),
              ),
            );
          });
        });
      } else {
        if (tiles.type === 'tilesMenu') {
          tilesContainersBody.push(
            tiles.items.map((item) =>
              HeaderCategoryUtil.convertToTilesCompatible(item, null, tiles.columnsOnMobile, isMdAndLower),
            ),
          );
        }

        if (tiles.type === 'globalHamburgerMenuItems') {
          tilesContainersHeader.push(
            tiles.items.map((item) => HeaderCategoryUtil.convertToTilesCompatible(
              item,
              null,
              item.columnsOnMobile,
              isMdAndLower,
            )),
          );
        }
      }
    });

    // Add domain picker on pwa app
    if (isNativeApp) {
      tilesContainersBody.push([{
        label: { key: 'DOMAIN_PICKER_SELECT' },
        type: 'IMAGE_TEXT',
        data: {
          onDesktopOperation: 'pickDomain',
        },
        defaultIcon: {
          type: 'SVG',
          source: currentLocale?.flag,
          size: 'XMD',
        },
      } as TileModel<CategoryTileData>]);
    }

    return {
      categoryTabList, tileListContainer: {
        sections: {
          HEADER: { items: tilesContainersHeader },
          CONTENT: { items: tilesContainersBody },
        },
      },
    };
  }

}
