import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { ColorCombinationId } from '../../model/color-combination-id';
import { ColorUtil } from '../../util/color.util';
import { AukBaseLocalStateComponent } from '@common/base-class/base-local-state.component';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { COLOR_COMBINATIONS } from '../../constant/color-combinations';

@Component({
  template: '',
})
export abstract class ColorCombinationStateComponent<TState>
  extends AukBaseLocalStateComponent<TState> implements OnChanges {

  @Input() public colorCombinationId: ColorCombinationId;

  protected constructor(
    protected readonly elementRef: ElementRef<HTMLElement>,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<ColorCombinationStateComponent<TState>>): void {
    if (changes.colorCombinationId) {
      ColorUtil.setAbstractCssVarsOnElement(COLOR_COMBINATIONS[this.colorCombinationId], this.elementRef.nativeElement);
    }
  }

}
