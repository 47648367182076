import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
// eslint-disable-next-line import/no-restricted-paths
import { CurrencyPipeModule } from '@shared/currency/pipe/currency-pipe.module';
import { CommonModule } from '@angular/common';
import { Nil } from '@util/helper-types/nil';
import { RetailPricePercentageModel } from '../model/retail-price-percentage.model';
import { Translate2Module } from '@common/translations/translate2.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'auk-retail-price',
  templateUrl: './retail-price.component.html',
  styleUrls: ['./retail-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CurrencyPipeModule,
    Translate2Module,
    MatTooltipModule,
  ],
})
export class RetailPriceComponent {

  /**
   * Retail price data
   */
  @Input() public retailPrice: RetailPricePercentageModel | Nil;
  /**
   * Whether prefix should be shown
   */
  @Input() public showRetailPricePrefix: boolean = false;
  /**
   * Css class to be applied for price and prefix elements
   */
  @Input() public priceAndPrefixClass: string;

}
