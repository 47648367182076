import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Nil } from '@util/helper-types/nil';
import { NgClass } from '@angular/common';
import { NativeDragScrollChildDirective } from '@common/ui-kit/component/native-drag-scroll/directive/native-drag-scroll-child.directive';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { NavigationIconButtonComponent } from '@common/ui-kit/component/navigation-icon-button/navigation-icon-button.component';

const SCROLL_END_DEBOUNCE_TIME = 200;

/**
 * You can use native drag scroll in classic or virtual scroll mode.
 * If you use virtual scroll, use virtualScrollRef and staticChildWidth input.
 * As we need to get the reference for the move logic, width and other properties.
 */
@Component({
  selector: 'auk-native-drag-scroll',
  templateUrl: './native-drag-scroll.component.html',
  styleUrls: ['./native-drag-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NavigationIconButtonComponent,
    NgClass,
  ],
})
export class NativeDragScrollComponent extends NgUnsubscribe implements OnInit, AfterContentChecked, OnChanges {

  @Input() public dragDistance: number = 2;
  @Input() public ignoreLastItemWidth: boolean = false;

  /**
   * Only when using Virtual list, we need to input staticChildWidth for scroll calculations
   */
  @Input() public staticChildWidth: number = 0;

  /**
   * Reference used for getting scroll positions, moving with arrows and etc. Only for virtual list.
   */
  @Input() public virtualScrollRef: ElementRef<HTMLDivElement> | Nil = null;

  @ContentChildren(NativeDragScrollChildDirective) public scrollChildren: QueryList<NativeDragScrollChildDirective> | Nil;

  @ViewChild('scrollElm', { static: true }) private scrollElementRef: ElementRef<HTMLElement> | Nil;

  protected isNextAllowed: boolean = false;
  protected isPrevAllowed: boolean = false;
  protected displayArrows: boolean = false;
  protected isNextBtnContainerVisible: boolean = true;

  private isScrollingListenerEnabled: boolean = true;
  private childWidth: number | Nil;
  private scrollSubscription: Subscription;
  /**
   * Is used for storing current items scroll width, so we can later filter out cases where the width didn't change
   */
  private itemsScrollWidth: number | Nil;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly platformCommonService: PlatformCommonService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.platformCommonService.isBrowser) {
      this.setupScrollListener();
    }

    this.responsivenessService.isActiveBreakpointInRange$({ min: 'SM' })
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isSmBreakpointOrHigher) => {
        this.displayArrows = isSmBreakpointOrHigher;
        this.evaluateNextAndPrevAllowed();
        this.changeDetectorRef.markForCheck();
      });
  }

  public ngOnChanges(changes: AukSimpleChanges<NativeDragScrollComponent>): void {
    if (changes.virtualScrollRef) {
      this.setupScrollListener();
    }
    if (changes.staticChildWidth) {
      this.updateChildWidth();
      this.evaluateNextAndPrevAllowed();
    }
  }

  /**
   * has to be ngAfterContentChecked hook, because we need to detect width of <ng-content></ng-content> parent scroll element
   * we check scroll element width, so we can filter out cases where the width didn't change
   */
  public ngAfterContentChecked(): void {
    if (this.hasItemsScrollWidthChanged()) {
      this.updateChildWidth();
      this.evaluateNextAndPrevAllowed();
    }
  }

  protected getMode(): 'VIRTUAL' | 'NORMAL' {
    if (isNotNil(this.virtualScrollRef)) {
      return 'VIRTUAL';
    } else {
      return 'NORMAL';
    }
  }

  /**
   * Setup scroll - sometimes virtualScrollRef comes after init, so we need to clean old subscription a create new for virtualScrollRef
   */
  private setupScrollListener(): void {
    this.ngZoneUtilService.runOut(() => {
      this.cleanupScrollListener();

      const scrollObservable$ = this.ngZoneUtilService.fromEventOut$(this.scrollElement, 'scroll');

      this.scrollSubscription = scrollObservable$
        .pipe(
          tap(() => this.onScrolling()),
          this.ngZoneUtilService.debounceTimeOut(SCROLL_END_DEBOUNCE_TIME),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => this.onScrollEnd());
    });
  }

  private cleanupScrollListener(): void {
    this.scrollSubscription?.unsubscribe();
    this.scrollSubscription = null;
  }

  private get scrollElement(): HTMLElement | Nil {
    if (this.getMode() === 'VIRTUAL') {
      return this.virtualScrollRef?.nativeElement;
    }
    return this.scrollElementRef?.nativeElement;
  }

  private onScrolling(): void {
    if (this.isScrollingListenerEnabled) {
      this.evaluateNextAndPrevAllowed();
    }
  }

  private onScrollEnd(): void {
    this.evaluateNextAndPrevAllowed();
    this.setScrollingListenerEnabled(true);
  }

  private evaluateNextAndPrevAllowed(): void {
    /**
     * for mobile devices (screens below SM breakpoint), where arrows are hidden, we don't evaluate (prev/next) arrow visibility
     */
    if (!this.displayArrows) {
      return;
    }
    const el = this.scrollElement;

    const isPrevAllowed = el?.scrollLeft > 0;
    this.setPrevAllowed(isPrevAllowed);

    let scrollWidth = el?.scrollWidth;
    if (this.ignoreLastItemWidth && scrollWidth && this.childWidth) {
      scrollWidth -= this.childWidth;
    }
    const isNextAllowed = Math.ceil((el?.scrollLeft + el?.offsetWidth)) < scrollWidth;
    this.setNextAllowed(isNextAllowed);
  }

  private setScrollingListenerEnabled(isEnabled: boolean): void {
    this.isScrollingListenerEnabled = isEnabled;
  }

  private setPrevAllowed(isAllowed: boolean): void {
    if (this.isPrevAllowed !== isAllowed) {
      this.isPrevAllowed = isAllowed;
      this.changeDetectorRef.detectChanges();
    }
  }

  private setNextAllowed(isAllowed: boolean): void {
    if (this.isNextAllowed !== isAllowed) {
      this.isNextAllowed = isAllowed;
      this.isNextBtnContainerVisible = isAllowed;
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Potentially performance-intensive operation
   * instead of reading child element width many times, we refresh local variable only when necessary
   */
  private updateChildWidth(): void {
    if (this.getMode() === 'VIRTUAL') {
      this.childWidth = this.staticChildWidth ?? 0;
    } else {
      this.childWidth = this.scrollChildren?.get(0)?.elementRef?.nativeElement?.offsetWidth ?? 0;
    }
  }

  private hasItemsScrollWidthChanged(): boolean {
    if (this.scrollElement?.scrollWidth === this.itemsScrollWidth) {
      return false;
    }

    this.itemsScrollWidth = this.scrollElement.scrollWidth;
    return true;
  }

  protected moveLeft(): void {
    this.updateChildWidth();
    const el = this.scrollElement;
    let nextLeft = el?.scrollLeft - (this.childWidth * this.dragDistance);
    // if next scroll distance will be smaller than width of one child, scroll to start and disable left arrow immediately
    if (nextLeft < this.childWidth) {
      nextLeft = 0;
      this.setScrollingListenerEnabled(false);
      this.setPrevAllowed(false);
    }
    const options: ScrollToOptions = {
      left: nextLeft,
      behavior: 'smooth',
    };
    el?.scrollTo(options);
  }

  protected moveRight(): void {
    this.updateChildWidth();
    const el = this.scrollElement;
    let nextLeft = el?.scrollLeft + (this.childWidth * this.dragDistance);
    // if next scroll distance will be smaller than width of one child, scroll to end and disable right arrow immediately
    if (el?.scrollWidth - (nextLeft + el?.offsetWidth) < this.childWidth) {
      nextLeft = el?.scrollWidth - el?.offsetWidth;
      this.setScrollingListenerEnabled(false);
      this.setNextAllowed(false);
    }
    const options: ScrollToOptions = {
      left: nextLeft,
      behavior: 'smooth',
    };
    el?.scrollTo(options);
  }

}
