import { CountryCodes } from '../../../app.constants';
import {
  ZIP_CODE_TEXT_MASK_CZ_LIKE,
  ZIP_CODE_TEXT_MASK_PL_LIKE,
  ZIP_CODE_TEXT_MASK_XD,
} from '@shared/validators/patterns';

const maskByCountryId: Record<CountryCodes, (string | RegExp)[] | boolean> = {
  [CountryCodes.POLAND]: ZIP_CODE_TEXT_MASK_PL_LIKE,
  [CountryCodes.AUSTRIA]: ZIP_CODE_TEXT_MASK_XD(4),
  [CountryCodes.CZECH_REPUBLIC]: ZIP_CODE_TEXT_MASK_CZ_LIKE,
  [CountryCodes.GERMANY]: ZIP_CODE_TEXT_MASK_XD(5),
  [CountryCodes.HUNGARY]: ZIP_CODE_TEXT_MASK_XD(4),
  [CountryCodes.SLOVAKIA]: ZIP_CODE_TEXT_MASK_CZ_LIKE,
};

/**
 * Returns zip mask for country Id or false if zipMask is not needed.
 * @param countryId
 */
export function getZipMaskByCountryId(countryId: number | CountryCodes): (string | RegExp)[] | false {
  return maskByCountryId[countryId] as (string | RegExp)[] ?? false;
}
