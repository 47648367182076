<auk-dialog-content
    [dialogTitle]="payload.title"
>
  <div content [aukInnerHtml]="bodyTranslationSource"></div>

  <ng-container actions>
    <auk-button colorCombination="PRIMARY"
                class="tw-w-full"
                [chin]="true"
                (buttonClick)="closeDialog()"
    >
      {{ payload.closeButtonText | translateSource }}
    </auk-button>
  </ng-container>
</auk-dialog-content>
