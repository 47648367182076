import { ExchangedPriceService } from '../services/exchanged-price.service';
import { ExchangeRatesService } from '../services/exchange-rates.service';
import { CurrencyService } from './currency.service';
import { UserCurrencyPreferenceService } from './user-currency-preference.service';
import { combineLatest, mergeMap, Observable, of } from 'rxjs';
import { CurrencyConfigElementEnumModel } from '../model/currency-config-element-enum.model';
import { ExchangeRatesDto } from '@api/generated/defs/ExchangeRatesDto';
// TODO: [PDEV-18307] - fix dependency
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { MoneyDto } from '@api/generated/defs/MoneyDto';
import { CurrencyCode } from '../model/currency-code.model';
import { NumberUtils } from '@util/util/number.utils';
import { MoneyUtils } from '@shared/currency/util/money.utils';
import { CurrencyModelMap } from '@shared/currency/model/currency-model-map.type';
import { CurrencyUtil } from '@shared/currency/util/currency.util';
import { DEFAULT_CURRENCY } from '@shared/currency/const/currency.constants';

@Injectable({
  providedIn: 'root',
})
export class CurrencyPipeService {

  constructor(private readonly exchangePriceService: ExchangedPriceService,
              private readonly exchangeRatesService: ExchangeRatesService,
              private readonly currencyService: CurrencyService,
              private readonly userCurrencyPreferenceService: UserCurrencyPreferenceService) {

  }

  private get userPreferredCurrency$(): Observable<CurrencyConfigElementEnumModel> {
    return this.userCurrencyPreferenceService.preferredCurrencyModel$;
  }

  private get availableCurrencies$(): Observable<CurrencyConfigElementEnumModel[]> {
    return this.currencyService.getCurrencies$();
  }

  private get availableCurrenciesMap$(): Observable<CurrencyModelMap> {
    return this.currencyService.getCurrencies$()
      .pipe(map((currenciesArr) => CurrencyUtil.currenciesModelArrToMap(currenciesArr)));
  }

  private get exchangeRates$(): Observable<ExchangeRatesDto> {
    return this.exchangeRatesService.exchangeRates(DEFAULT_CURRENCY.code);
  }

  /**
   * Exchange and format value to currency specified in user preferences.
   * @param money Price value to transform
   * @param targetCurrencyCode To override user preferred currency
   * @param approximationWarn If warning should be raised while performing money e
   */
  public transform(money: MoneyDto, targetCurrencyCode?: CurrencyCode, approximationWarn: boolean = true): Observable<string> {
    if (isNil(money)) {
      return of(null);
    }

    // If no currency specified or the same is requested, return directly the one from the value
    targetCurrencyCode = isNil(targetCurrencyCode) ? money?.currency : targetCurrencyCode;

    if (money?.currency === targetCurrencyCode) {
      return this.currencySources()
        .pipe(
          map(([userPreferredCurrency, availableCurrencies]) => this.getTargetCurrency(
            userPreferredCurrency,
            targetCurrencyCode,
            availableCurrencies)),
          mergeMap((targetCurrency) => this.formatWithCurrencyModel(MoneyUtils.of(money?.amount, targetCurrency.code), targetCurrency)),
          take(1),
        );
    }

    // Different currency in money dto than target currency -> perform money exchange (approximation)
    return this.currencySources()
      .pipe(
        tap(() => {
          if (approximationWarn === true) {
            console.warn(
              `Currency pipe service performs suspicious exchange ${ money.currency }->${ targetCurrencyCode } amount ${ money.amount }`,
            );
          }
        }),
        map(([userPreferredCurrency, availableCurrencies]) => this.getTargetCurrency(
          userPreferredCurrency,
          targetCurrencyCode,
          availableCurrencies)),
        mergeMap((targetCurrency: CurrencyConfigElementEnumModel) => this.exchangePriceService.price(
          money?.amount,
          money?.currency,
          targetCurrency.code)
          .pipe(mergeMap((price) => this.formatWithCurrencyModel(MoneyUtils.of(price, targetCurrency.code), targetCurrency)))),
        take(1),
      );
  }

  /**
   * Observable of currency/exchange rates source data
   */
  private currencySources(): Observable<[CurrencyConfigElementEnumModel, CurrencyConfigElementEnumModel[], ExchangeRatesDto]> {
    return combineLatest([
      this.userPreferredCurrency$,
      this.availableCurrencies$,
      this.exchangeRates$,
    ]);
  }

  public currencySourceChanged(): Observable<void> {
    return this.currencySources()
      .pipe(
        distinctUntilChanged(),
        mergeMap(() => of(void 0)),
      );
  }

  public formatWithCurrencyModel(money: MoneyDto, currencyModel: CurrencyConfigElementEnumModel): Observable<string> {
    return of(this.instantFormat(money, currencyModel));
  }

  public format(money: MoneyDto): Observable<string> {
    return this.availableCurrenciesMap$
      .pipe(
        map((currenciesMap) => currenciesMap[money.currency]),
        mergeMap((currencyModel: CurrencyConfigElementEnumModel) => this.formatWithCurrencyModel(money, currencyModel)),
      );
  }

  /**
   * Formats given value to given currency. Does not react on user currency preference change.
   *
   * Use {@link transform method } if possible!
   * @param money Amount
   * @param currencyModel
   */
  public instantFormat(money: MoneyDto, currencyModel: CurrencyConfigElementEnumModel): string {
    if (isNil(money) || isNil(money.amount)) {
      return '';
    }

    if (isNil(money.currency)) {
      console.info(`Can't format ${ JSON.stringify(money) } without currency`);
      return '';
    }

    if (isNil(currencyModel)) {
      console.info(`Can't format ${ JSON.stringify(money) } without currency model`);
      return '';
    }

    if (money.currency !== currencyModel.code) {
      console.info(`Can't format ${ JSON.stringify(money) }, different currency model provided`);
      return '';
    }

    let stringPrice = money.amount > 0 ? NumberUtils.roundUp(money.amount, 2).toFixed(2) :
      NumberUtils.roundDown(money.amount, 2).toFixed(2);

    /* remove .00 case */
    stringPrice = stringPrice.replace(/\.00$/, '');

    /* insert space between thousands */
    let formatPrice = stringPrice.replace(/./g, (match, offset, str) => offset && match !== '.' &&
    ((String(str).length - offset) % 3 === 0) ? ' ' + match : match);

    /* remove space after - */
    formatPrice = formatPrice.replace(/^- /, '-');

    /* replace decimal point with decimal comma */
    formatPrice = formatPrice.replace(/\./, ',');
    /* replace dash with &minus; symbol for negative numbers */
    formatPrice = formatPrice.replace(/-/, '−'); // here are two different characters

    /* add currency text */
    return [formatPrice, currencyModel?.suffix ?? ''].join(' ');
  }

  private getTargetCurrency(
    userPreferredCurrency: CurrencyConfigElementEnumModel,
    overrideCurrencyCode: CurrencyCode,
    availableCurrencies: CurrencyConfigElementEnumModel[]): CurrencyConfigElementEnumModel {
    if (isNil(overrideCurrencyCode)) {
      return userPreferredCurrency;
    }

    return availableCurrencies?.find(curr => curr.code === overrideCurrencyCode) ?? DEFAULT_CURRENCY;
  }

}
