import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { UserShowNameDto } from '@api/generated/defs/UserShowNameDto';

@Component({
  selector: 'auk-suggestion-user',
  templateUrl: './suggestion-user.component.html',
  styleUrls: [
    './suggestion-user.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuggestionUserComponent {

  @Input() public item: UserShowNameDto;
  @HostBinding('class.focus') @Input() public focus: boolean = false;

}
