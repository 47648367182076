@if (itemHasEnded) {
  {{ 'ITEM_CARD_TIMER_ENDED_LABEL' | translate }}
} @else {
  @if (timeToEnd) {
    @if (isMoreThenOneDay && humanReadableTime) {
      {{ humanReadableTime }}
    }
    @if (!isMoreThenOneDay) {
      <!--
      The <auk-countdown-panel> is hidden with CSS ('tw-hidden') instead of being removed from the DOM.
      This allows event handlers, like countdownEnd, to remain active even when the countdown is under one minute.
      -->
      <auk-countdown-panel
        [ngClass]="{'tw-hidden': lessThanMinute}"
        [endingTime]="timeToEnd"
        (lessThanOneMinute)="onLessThanOneMinute($event)"
        (countdownEnd)="onCountdownEnd()"
      />
      @if (lessThanMinute) {
        {{ 'ITEM_CARD_TIMER_LESS_THAN_MINUTE' | translate }}
      }

    }
  }
}

