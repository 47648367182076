import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';

export class MouseUtils {

  public static isScrollUp(e: WheelEvent): boolean {
    const delta = e.deltaY ? e.deltaY : -1 * e.deltaY;
    return delta > 0;
  }

  public static isScrollDown(e: WheelEvent): boolean {
    const delta = e.deltaY ? e.deltaY : -1 * e.deltaY;
    return delta < 0;
  }

  /**
   * - returns whether we can do app navigation
   * - basically checks if action key is not pressed, because in that case we want it to open in new tab)
   * - inspired by Angular RouterLinkDirective
   */
  public static canDoAppNavigation(mouseEvent: MouseEvent | Nil): boolean {
    if (isNil(mouseEvent)) {
      return false;
    }

    return !(
      mouseEvent.button !== 0
      || mouseEvent.ctrlKey
      || mouseEvent.shiftKey
      || mouseEvent.altKey
      || mouseEvent.metaKey
    );
  }

}
