@if ((state$ | async); as state) {
  <a
    class="tw-flex tw-items-center tw-h-full"
    aukMouseOver
    [matTooltip]="'AUKRO_UNREAD_MESSAGES_TOOLTIP' | translate:{ count: state.unreadMessagesCount }"
    [matTooltipDisabled]="state.unreadMessagesCount === 0"
    [matTooltipShowDelay]="tooltipShowDelayMs"
    [routerLink]="['/moje-aukro/moje-zpravy']"
    (isMouseOver)="setIconHoverState($event)"
    >
    <auk-header-item
      [headerItemModel]="headerItemData"
      [isActive]="isIconHovered"
      colorCombination="SILVERSTEIN_CONTRAST_200_600"
      />
  </a>
}
