import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { TranslationSource } from '@common/translations/model/translation-source';

@Component({
  selector: 'auk-login-with-password-form',
  templateUrl: 'login-with-password-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginWithPasswordFormComponent extends NgUnsubscribe implements OnInit {

  @Input()
  public inputPlaceholder: TranslationSource;

  @Input()
  public loginButton: TranslationSource;

  @Input()
  public crossDomainButton: TranslationSource;

  @Input()
  public registerButton: TranslationSource;

  @Input()
  public regDomainCodeCrossDomain: TranslationSource;

  @Input()
  public submitDisabled: boolean = false;

  @Input()
  public loginOrEmail: string;

  @Input()
  public hiddenPassword: string;

  @Output()
  public passwordSubmit: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public redirectSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public passwordTyping: EventEmitter<void> = new EventEmitter<void>();

  public loginForm: FormGroup = null;

  public isPasswordShown: boolean = false;

  private readonly PASSWORD_INPUT_NAME = 'password';

  constructor(
    private readonly formBuilder: FormBuilder,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initLoginForm();
    this.initTypingEmitting();
  }

  private initLoginForm(): void {
    this.loginForm = this.formBuilder.group({
      [this.PASSWORD_INPUT_NAME]: [this.hiddenPassword ?? null, [Validators.required]],
    });
  }

  private initTypingEmitting(): void {
    this.loginForm.get(this.PASSWORD_INPUT_NAME).valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.passwordTyping.emit());
  }

  public onSubmit(submitEvent: SubmitEvent): void {
    submitEvent.preventDefault();

    if (!this.loginForm.valid || this.submitDisabled) {
      return;
    }

    const password: string = this.loginForm.get(this.PASSWORD_INPUT_NAME).value;
    this.passwordSubmit.emit(password);
  }

  public toggleShowPassword(): void {
    this.isPasswordShown = !this.isPasswordShown;
  }

  /**
   * Emit event with cross domain validation problem
   * Possible solution is emitted by parameter
   */
  public emitCrossDomainValidation(registration: boolean): void {
    this.redirectSubmit.emit(registration);
  }

}
