import isNil from 'lodash-es/isNil';
import last from 'lodash-es/last';
import isEmpty from 'lodash-es/isEmpty';
import { OfferDetailItemCategoryDto } from '@api/generated/defs/OfferDetailItemCategoryDto';

export interface ProductCategoriesModel {
  leafCategory: OfferDetailItemCategoryDto;
  rootCategory: OfferDetailItemCategoryDto;
  parentCategories: OfferDetailItemCategoryDto[];
}

export class ProductProfileHelper {

  public static parseCategoriesModel(categoryArr: OfferDetailItemCategoryDto[]): ProductCategoriesModel {
    if (isNil(categoryArr) || isEmpty(categoryArr)) {
      return {
        leafCategory: null,
        rootCategory: null,
        parentCategories: [],
      };
    }

    const parentCategories = categoryArr.slice(0, categoryArr.length - 1);

    return {
      leafCategory: last(categoryArr),
      rootCategory: last(parentCategories),
      parentCategories: parentCategories.reverse(),
    };
  }

}
