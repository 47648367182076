import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { MeasurementObjectCode, YuspRecommendationScenarioType } from '@shared/services/personalization/yusp-personalization.helper';
import { YuspPersonalizationService } from '@shared/services/personalization/yusp-personalization.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { YuspRecommendationResultInternal } from '@shared/services/personalization/model/yusp-recommendation-result-internal.model';
import { YuspItemResultInternal } from '@shared/services/personalization/model/yusp-item-result-internal.model';
import { UserCurrencyPreferenceService } from '@shared/currency/service/user-currency-preference.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import isNil from 'lodash-es/isNil';
import { ItemScrollSliderComponent } from '@shared/item-scroll-slider/component/item-scroll-slider.component';

import { ItemScrollSliderItemMapperDirective } from '@shared/item-scroll-slider/directive/item-scroll-slider-item-mapper.directive';
import { ItemScrollSliderTrackItemModel } from '@shared/item-scroll-slider/model/item-scroll-slider-track-item.model';
import { ItemScrollSliderGaDirective } from '@shared/item-scroll-slider/directive/item-scroll-slider-ga.directive';
import { RecommendationPersonalizationMeasurementService } from '@shared/services/personalization/recommendation-personalization-measurement.service';

@Component({
  selector: 'auk-reco-box',
  templateUrl: './reco-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ItemScrollSliderComponent,
    ItemScrollSliderItemMapperDirective,
    ItemScrollSliderGaDirective,
  ],
})
export class RecoBoxComponent extends NgUnsubscribe implements OnInit {

  @Input() public measurementObjectCode: MeasurementObjectCode;
  @Input() public yuspScenarioType: YuspRecommendationScenarioType;
  @Input() public limit: number = 20;
  public yuspResult: YuspRecommendationResultInternal;
  private recoBoxCategories: string[];
  private lastRecommendationShowId: number;

  constructor(
    private readonly yuspPersonalizationService: YuspPersonalizationService,
    private readonly cd: ChangeDetectorRef,
    private readonly platformService: PlatformService,
    private readonly recommendationPersonalizationMeasurementService: RecommendationPersonalizationMeasurementService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly userCurrencyPreferenceService: UserCurrencyPreferenceService,
  ) {
    super();

    this.configuratorCacheService.systemParametersFE(['RECO_BOX_CATEGORIES_INPUT'])
      .pipe(take(1))
      .subscribe(configElements => {
        this.recoBoxCategories = (JSON.parse(configElements[0]?.value) as { categoryIds: string[] })?.categoryIds;
        this.loadYuspItems();
      });
  }

  public ngOnInit(): void {
    this.reloadOnCurrencyPreferenceChange();
  }

  private reloadOnCurrencyPreferenceChange(): void {
    this.userCurrencyPreferenceService.preferredCurrencyCodeChanged$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.loadYuspItems());
  }

  private loadYuspItems(): void {
    if (this.platformService.isServer || !this.recoBoxCategories) {
      return;
    }

    // TODO PDEV-15821 move the fallback logic in a new component with desired algorithm as an input and emitting show/click events
    this.yuspPersonalizationService.recommendation_W_MAIN_CATBOX(this.limit, '', this.recoBoxCategories)
      .pipe(
        finalize(() => this.cd.markForCheck()),
        take(1),
      )
      .subscribe((data: YuspRecommendationResultInternal) => {
        if (isNil(data.fallbackReason)) {
          this.yuspResult = data;
        }
      });
  }

  public onYuspRecommendationBoxShow(): void {
    if (this.yuspResult.items) {
      const itemIds = this.yuspResult.items.map((yuspRecommended: YuspItemResultInternal) => yuspRecommended.itemId);
      this.recommendationPersonalizationMeasurementService.showRecommendationItem$(this.measurementObjectCode, itemIds)
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((showId) => this.lastRecommendationShowId = showId);
      this.yuspPersonalizationService.trackShowYuspRecommendation(this.yuspResult, this.yuspScenarioType);
    }
  }

  public onYuspProductClicked(item: ItemScrollSliderTrackItemModel): void {
    this.recommendationPersonalizationMeasurementService.clickOnRecommendationItem(
      item.id,
      item.position,
      this.lastRecommendationShowId,
      this.measurementObjectCode,
    );
    this.yuspPersonalizationService.trackRecClickEvent(item.id.toString(), this.yuspResult.recommendationId, this.yuspScenarioType);
  }

}
