import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';

import { AukFormControl } from '@common/auk-forms/auk-form-control/domain/auk-form-control';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { TranslationSource } from '@common/translations/model/translation-source';
import { Translate2Module } from '@common/translations/translate2.module';
import { Nil } from '@util/helper-types/nil';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { merge, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { AukFormValidatorsModel } from '@common/auk-forms/auk-validator/model/auk-form-validators.model';
import { AukValidatorResultModel } from '@common/auk-forms/auk-validator/model/auk-validator-result.model';
import isNil from 'lodash-es/isNil';
import { ObjectUtils } from '@util/util/object.utils';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@Component({
  selector: 'auk-validation-message',
  templateUrl: './auk-validation-message.component.html',
  styleUrls: ['./auk-validation-message.component.scss'],
  standalone: true,
  imports: [
    Translate2Module,
    InnerHtmlDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AukValidationMessageComponent<TValue> extends NgUnsubscribe implements OnChanges {

  @Input() public aukFormControl: AukFormControl<TValue, Nil>;

  protected isValidationMessageVisible: boolean = false;
  protected validationMessage: TranslationSource | Nil;

  private readonly aukFormControlChange$: Subject<void> = new Subject<void>();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<AukValidationMessageComponent<TValue>>): void {
    if (changes.aukFormControl) {
      this.aukFormControlChange$.next();
      this.listenToFormControlChanges();
    }
  }

  private listenToFormControlChanges(): void {
    merge(
      this.aukFormControl.statusChanges,
      this.aukFormControl.touchedStateChange$,
    )
      .pipe(
        startWith(null),
        takeUntil(
          merge(
            this.aukFormControlChange$,
            this.ngUnsubscribe,
          ),
        ),
      )
      .subscribe(() => {
        // check if validation message should be visible
        this.isValidationMessageVisible = this.aukFormControl.shouldBeErrorStateVisible;

        // get validation message value
        this.validationMessage = this.isValidationMessageVisible
          ? this.getValidationMessage(this.aukFormControl.pendingStateErrors)
          : null;

        this.changeDetectorRef.markForCheck();
      });
  }

  private getValidationMessage<
    VALIDATORS extends AukFormValidatorsModel<TValue>,
  >(errors: AukValidatorResultModel<VALIDATORS>): TranslationSource {
    let correctValidationMessage: TranslationSource | Nil;
    let currentPossibleValidatorResultContext: object | AukValidatorResultModel<VALIDATORS> = errors;

    // find the first most leaf validation message
    while (isNil(correctValidationMessage)) {
      // if current context is ValidatorResultContext
      if (this.isAukValidatorResultModel(currentPossibleValidatorResultContext)) {
        // get the validator result from the first validator
        const firstValidatorResult = ObjectUtils.getFirstObjectValue(currentPossibleValidatorResultContext);

        // if value of the validatorResult is Nil, it means it is the leaf validator, so we get the validation message
        if (isNil(firstValidatorResult.validatorResult.value)) {
          correctValidationMessage = firstValidatorResult.validationMessage;
        } else {
          // otherwise we switch the context into the validator result and repeat it
          currentPossibleValidatorResultContext = firstValidatorResult.validatorResult.value;
        }
      } else {
        // if current context is not ValidatorResultContext, switch context to first object value
        currentPossibleValidatorResultContext = ObjectUtils.getFirstObjectValue(currentPossibleValidatorResultContext);
      }
    }

    return correctValidationMessage;
  }

  private isAukValidatorResultModel<VALIDATORS extends AukFormValidatorsModel<TValue>>(
    value: unknown,
  ): value is AukValidatorResultModel<VALIDATORS> {
    if (!ObjectUtils.isObject(value)) {
      return false;
    }

    const objectFirstKey = ObjectUtils.getFirstObjectKey<AukValidatorResultModel<VALIDATORS>>(value);

    // check if first object value has fields validationMessage & validatorResult
    return !isNil((value as AukValidatorResultModel<VALIDATORS>)[objectFirstKey].validationMessage)
      && !isNil((value as AukValidatorResultModel<VALIDATORS>)[objectFirstKey].validatorResult);
  }

}
