import { Injectable } from '@angular/core';
import {
  UserTaskVerifiedPhoneStepResolutionHandlerService,
} from './user-task-verified-phone-step-resolution-handler.service';
import { UserTaskStepResolutionUnionModel } from '../../base/model/user-task-step-resolution-union.model';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';

@Injectable({
  providedIn: 'root',
})
export class UserTaskVerifiedPhoneStepNoPhoneVerifiedResolutionHandlerService
  extends UserTaskVerifiedPhoneStepResolutionHandlerService<'NO_PHONE_VERIFIED'> {

  public handle(
    resolution: UserTaskStepResolutionUnionModel<'VERIFIED_PHONE', 'NO_PHONE_VERIFIED'>,
    taskPayload: UserTaskPayloadModel<'VERIFIED_PHONE'>,
  ): UserTaskStepUnionModel<'VERIFIED_PHONE'> {

    if (resolution.type === 'RESOLUTION_SUCCESS') {
      return null;
    }

    throw new Error('User failed to verify phone');

  }

}
