import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AlertBannerComponent } from './alert-banner.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';

/**
 * @deprecated
 * use {@link AlertBannerComponent} from 'ui-kit/alert-banner' folder
 */
@NgModule({
  imports: [
    CommonModule,
    IconComponent,
  ],
  declarations: [
    AlertBannerComponent,
  ],
  exports: [
    AlertBannerComponent,
  ],
})
export class AlertBannerModule {
}
