import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';

@Component({
  selector: 'auk-item-card-corner-mark',
  templateUrl: 'item-card-corner-mark.component.html',
  standalone: true,
  imports: [
    IconComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ItemCardCornerMarkComponent extends ColorCombinationInputComponent {

  @Input() public cornerMarkIcon: IconModel;

}
