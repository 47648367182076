import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { Translate2Module } from '@common/translations/translate2.module';

import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { Tooltip2ButtonsModel } from '../../model/tooltip2-buttons.model';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';

@Component({
  selector: 'auk-tooltip2-buttons',
  templateUrl: './tooltip2-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    Translate2Module,
    IconComponent,
    ButtonComponent,
  ],
})

export class Tooltip2ButtonsComponent {

  @Input() public tooltipModel: Tooltip2ButtonsModel;
  @Output() public clickAction: EventEmitter<string> = new EventEmitter<string>();

  public handleButtonClick(action: string): void {
    this.clickAction.emit(action);
  }

}
