import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from '@app-constants';
import { CookieService } from '@common/cookie/service/cookie.service';
import { EMPTY, Observable } from 'rxjs';
import { map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { MeasurementService } from '@api/generated/api/Measurement';
import { ClickEventDto } from '@api/generated/defs/ClickEventDto';
import { ClickEventResponseDto } from '@api/generated/defs/ClickEventResponseDto';
import { ContactFormEventDto } from '@api/generated/defs/ContactFormEventDto';
import { ShowEventDto } from '@api/generated/defs/ShowEventDto';
import { ShowListingEventResponseDto } from '@api/generated/defs/ShowListingEventResponseDto';
import {
  CATEGORY_RECOMMENDER_COOKIE_DEV,
  MEASUREMENT_LISTING_SPLIT_GROUP_KEY,
  MEASUREMENT_SEARCH_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV10_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV11_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV12_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV1_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV2_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV3_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV4_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV5_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV6_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV7_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV8_SPLIT_GROUP_KEY,
  MEASUREMENT_WSRV9_SPLIT_GROUP_KEY,
} from './personalization-measurement.helper';
import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';
import unionBy from 'lodash-es/unionBy';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { MeasurementEnabledService } from '@shared/services/personalization/measurement-enabled.service';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { SplitGroupKeyMapping } from '@shared/services/personalization/model/split-group-key-mapping.model';
import { ArrayUtils } from '@util/util/array.utils';
import { SplitGroupParams } from '@shared/services/personalization/model/split-group-params.model';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { StringUtils } from '@util/util/string.utils';
import { BuildInfoService } from '@shared/version/service/build-info.service';
import { captureException, Scope, withScope } from '@sentry/browser';

@Injectable({
  providedIn: 'root',
})
export class PersonalizationMeasurementService extends NgUnsubscribe {

  constructor(
    private readonly cookieService: CookieService,
    private readonly measurementService: MeasurementService,
    private readonly measurementEnabledService: MeasurementEnabledService,
    private readonly configuratorCacheService: ConfiguratorCacheService,
  ) {
    super();

    this.setCategoryRecommenderDevCookie();
  }

  /**
   * Cookie sets only on DEV environments - PRODUCTION
   */
  public setCategoryRecommenderDevCookie(): void {
    if (!PlatformCommonService.isProductionMode) {
      const categoryRecommender: string = this.cookieService.get(APP_CONSTANTS.CATEGORY_RECOMMENDER_COOKIE_NAME);
      if (!categoryRecommender) {
        this.cookieService.put(APP_CONSTANTS.CATEGORY_RECOMMENDER_COOKIE_NAME, CATEGORY_RECOMMENDER_COOKIE_DEV);
      }
    }
  }

  public getCategoryRecommenderCookie(): string | null {
    return this.cookieService.get(APP_CONSTANTS.CATEGORY_RECOMMENDER_COOKIE_NAME) || null;
  }

  public getMeasurementSplitValues(isSearching: boolean): Record<string, string> {
    if (isSearching) {
      return {
        [MEASUREMENT_SEARCH_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.SEARCH_COOKIE_NAME),
      };
    }

    return {
      [MEASUREMENT_LISTING_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.LISTING_COOKIE_NAME),
      [MEASUREMENT_WSRV1_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV1_COOKIE_NAME),
      [MEASUREMENT_WSRV2_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV2_COOKIE_NAME),
      [MEASUREMENT_WSRV3_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV3_COOKIE_NAME),
      [MEASUREMENT_WSRV4_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV4_COOKIE_NAME),
      [MEASUREMENT_WSRV5_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV5_COOKIE_NAME),
      [MEASUREMENT_WSRV6_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV6_COOKIE_NAME),
      [MEASUREMENT_WSRV7_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV7_COOKIE_NAME),
      [MEASUREMENT_WSRV8_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV8_COOKIE_NAME),
      [MEASUREMENT_WSRV9_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV9_COOKIE_NAME),
      [MEASUREMENT_WSRV10_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV10_COOKIE_NAME),
      [MEASUREMENT_WSRV11_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV11_COOKIE_NAME),
      [MEASUREMENT_WSRV12_SPLIT_GROUP_KEY]: this.getGroup(APP_CONSTANTS.WSRV12_COOKIE_NAME),
    };
  }

  public getMeasurementSplitGroups$(moCode: string): Observable<Record<string, string>> {
    return this.configuratorCacheService.getFeSystemParam<SplitGroupKeyMapping[]>('MO_SPLIT_GROUP_KEY_MAPPING', 'JSON')
      .pipe(
        map((splitGroupKeyMappings: SplitGroupKeyMapping[]) => this.findSplitGroupParams(splitGroupKeyMappings, moCode)),
        map((moCodeSplitGroupParamsList: SplitGroupParams[]) => {
          const measurementSplitGroups: Record<string, string> = {};

          if (ArrayUtils.isEmpty(moCodeSplitGroupParamsList)) {
            return measurementSplitGroups;
          }

          moCodeSplitGroupParamsList.forEach((moCodeSplitGroupParams) => {
            if (isNil(moCodeSplitGroupParams)) {
              return;
            }

            const group = this.getGroup(moCodeSplitGroupParams.splitGroupCookieName);
            if (!isNil(group)) {
              measurementSplitGroups[moCodeSplitGroupParams.splitGroupKey] = group;
            }
          });
          return measurementSplitGroups;
        }),
      );
  }

  private findSplitGroupParams(splitGroupKeyMappings: SplitGroupKeyMapping[], moCode: string): SplitGroupParams[] {
    if (ArrayUtils.isEmpty(splitGroupKeyMappings)) {
      return [];
    }

    const splitGroupParamsExact = splitGroupKeyMappings
      .filter((splitGroupKeyMapping) => splitGroupKeyMapping.type === 'EXACT' && splitGroupKeyMapping.moCode === moCode)
      .flatMap((splitGroupKeyMapping) => splitGroupKeyMapping.splitGroups);

    const splitGroupParamsRegex = splitGroupKeyMappings
      .filter((splitGroupKeyMapping) =>
        splitGroupKeyMapping.type === 'REGEX' && new RegExp(splitGroupKeyMapping.moCodePattern).test(moCode))
      .flatMap((splitGroupKeyMapping) => splitGroupKeyMapping.splitGroups);

    return unionBy(splitGroupParamsExact, splitGroupParamsRegex, 'splitGroupKey');
  }

  public sendContactFormEvent(contactFormEventDto: ContactFormEventDto): Observable<void> {
    return this.measurementService.contactFormEvent({ contactFormEventDto });
  }

  public clickMeasurementWithCode(moCode: string): void {
    if (StringUtils.isBlank(moCode)) {
      return;
    }
    this.clickMeasurement({ measurementObjectCode: moCode });
  }

  public clickMeasurement(clickEventDto: ClickEventDto): void {
    this.clickMeasurement$(clickEventDto)
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

  public clickMeasurement$(clickEventDto: ClickEventDto): Observable<ClickEventResponseDto | Nil> {
    if (!this.measurementEnabledService.measurementEnabled) {
      return EMPTY;
    }

    if (isNil(clickEventDto.measurementObjectCode)) {
      withScope((scope: Scope) => {
        scope.setExtra('clickEventDto', clickEventDto);
        captureException(new Error('Unable to send measurement click event data because measurementObjectCode is nil.'));
      });

      return EMPTY;
    }

    return this.getMeasurementSplitGroups$(clickEventDto.measurementObjectCode)
      .pipe(
        take(1),
        mergeMap((measurementSplitValues) => {
          clickEventDto.splitGroups = {
            ...clickEventDto.splitGroups,
            ...measurementSplitValues,
          };
          clickEventDto.versionFe = BuildInfoService.version;
          return this.measurementService.click({ clickEventDto });
        }),
      );
  }

  public showMeasurement$(showEventDto: ShowEventDto): Observable<ShowListingEventResponseDto | Nil> {
    if (!this.measurementEnabledService.measurementEnabled) {
      return EMPTY;
    }

    return this.getMeasurementSplitGroups$(showEventDto.measurementObjectCode)
      .pipe(
        take(1),
        mergeMap((measurementSplitValues) => {
          showEventDto.splitGroups = {
            ...showEventDto.splitGroups,
            ...measurementSplitValues,
          };
          showEventDto.versionFe = BuildInfoService.version;
          return this.measurementService.show({ showEventDto });
        }),
      );
  }

  public getGroup(groupName: string, withoutVersion?: boolean): string | Nil {
    const cookieValue: string = this.cookieService.get(groupName);
    if (isNil(cookieValue)) {
      return null;
    }
    return withoutVersion ? new RegExp('\\D+').exec(cookieValue)?.[0] : cookieValue;
  }

}
