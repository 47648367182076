import { APP_INITIALIZER, FactoryProvider, Injector, Type } from '@angular/core';
import { AppInitializer } from '@util/helper-types/initializer.interface';
import { DomainService } from '../domain.service';
import { Observable, from, of, switchMap, take } from 'rxjs';
import isNil from 'lodash-es/isNil';

export function createDomainDependentInitializer(
  service: Type<AppInitializer>,
): FactoryProvider {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (domainService: DomainService, injector: Injector) =>
      (): Observable<unknown> => domainService.domainInitialized$
        .pipe(
          take(1),
          switchMap(() => {
            const initService = injector.get(service).init();
            if (isNil(initService)) {
              return of(null);
            }
            return from(injector.get(service).init() as Observable<unknown> | Promise<unknown>);
          }),
        ),
    deps: [DomainService, Injector],
  };
}
