import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ConnectionInfoPanelComponent } from '@shared/connection/component/connection-info-panel/connection-info-panel.component';
import { NewVersionInfoPanelComponent } from '@shared/version/component/new-version-info-panel/new-version-info-panel.component';
import { AppHeaderStickyDynamicContainerComponent } from '@shared/app-header/component/app-header-sticky-panel/app-header-sticky-dynamic-container/app-header-sticky-dynamic-container.component';

@Component({
  selector: 'auk-app-header-sticky-panel',
  standalone: true,
  imports: [
    ConnectionInfoPanelComponent,
    NewVersionInfoPanelComponent,
    AppHeaderStickyDynamicContainerComponent,
  ],
  templateUrl: './app-header-sticky-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderStickyPanelComponent {

}
