@if (favouriteSellers?.countOfAllFavouriteSellers > 0) {
  <div>
    @for (favouriteSeller of favouriteSellers.favouriteSellersPreview; track trackByFavouriteSellerId($index, favouriteSeller)) {
      <a
        class="!tw-no-underline !tw-text-matter-neutral-primary"
        [routerLink]="getRouterLinkSellerItems(favouriteSeller.login)"
        (click)="onSellerItemsClick(favouriteSeller.login)">
        <div class="tw-flex tw-flex-col">
          <div class="content">
            <!-- USER CHIP SECTION -->
            <auk-user-chip class="tw-flex tw-flex-1"
              chipType="SMALL-SIMPLE-INACTIVE"
              [userInfo]="favouriteSeller.userChip"
              (click)="onSellerClick($event, favouriteSeller.login)"
              />
            <!--./USER CHIP SECTION-->
            <!-- OFFERS SECTION -->
            <div class="tw-flex tw-flex-col tw-items-end tw-gap-1 tw-content-center"
              [ngClass]="favouriteSeller.newItemsCount > 0 ? '' : 'tw-text-matter-neutral-secondary'">
              <span class="tw-text-sm tw-font-bold">
                {{ favouriteSeller.newItemsCount }}
                <span class="tw-font-normal">
                  {{ {key: 'FAVOURITES_POPOVER_COMMON_ITEMS_PLACED'} | translateSource }}
                </span>
              </span>
            </div>
            <!--./OFFERS SECTION -->
          </div>
        </div>
      </a>
    }
  </div>
} @else {
  <auk-favourite-popover-empty-tab-content
    [emptyPopoverContent]="emptyPopoverContent"
    />
}

