import { Inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Nil } from '@util/helper-types/nil';
import { WINDOW_OBJECT } from '@util/const/window-object';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  constructor(private readonly router: Router,
              @Inject(WINDOW_OBJECT) private readonly window: Window) {
  }

  /**
   * Remove specific param from actual url and return url without specific parameter
   * @param queryParamName
   * @returns
   */
  public removeQueryParam(queryParamName: string): string {
    const queryParams = this.router.parseUrl(this.router.url);
    delete queryParams.queryParams[queryParamName];
    return queryParams.toString();
  }

  public removeEmptyProps(params: Params): Params {
    return Object.keys(params).reduce((result: Params, key: string) => {
      const value = params[key];
      if (value !== null && value !== undefined && value !== false && value !== '') {
        result[key] = value;
      }
      return result;
    }, {});
  }

  /**
   * Retrieves query parameter in synchronous manner.
   * Window object is used, because retrieving query parameter is problematic via Route during app initialization as
   * Route or ActivatedRoute is not available yet.
   *
   * Do not use this method outside of APP_INITIALIZER scope.
   * @param key query parameter name
   */
  public getAppQueryParamForInitializer(key: string): string | Nil {
    return new URLSearchParams(this.window.location.search)?.get(key);
  }

}
