import { UserTaskPersonalDataStepComponent } from './user-task-personal-data-step.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { UserService } from '@shared/user/service/user.service';
import { TranslationSource } from '@common/translations/model/translation-source';
import { environment } from '@environment';
import { ChangeEmailDto } from '@api/generated/defs/ChangeEmailDto';

@Component({
  selector: 'auk-user-task-personal-data-step-phone-verified-no-email-verified',
  templateUrl: 'user-task-personal-data-step-phone-verified-no-email-verified.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskPersonalDataStepPhoneVerifiedNoEmailVerifiedComponent extends
  UserTaskPersonalDataStepComponent<'PHONE_VERIFIED_NO_EMAIL_VERIFIED'> implements OnInit {

  public email: string;

  constructor(
    private readonly userService: UserService,
    private readonly cd: ChangeDetectorRef,
  ) {
    super();
  }

  public get bodyTranslationSource(): TranslationSource {
    return {
      ...this.payload?.body,
      params: {
        ...this.payload?.body?.params,
        email: this.email,
        app_host: environment.APP_HOST,
      },
    };
  }

  public ngOnInit(): void {
    this.userService.getCurrentUserEmailInfo()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((data: ChangeEmailDto) => {
        this.email = data?.email;
        this.cd.markForCheck();
      });
  }

  public closeDialog(): void {
    this.resolve.emit({
      type: 'RESOLUTION_CLOSE',
      payload: {},
    });
  }

}
