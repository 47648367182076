<auk-authenticate-wrapper>
  <auk-dialog-content
    [dialogTitle]="payload.title"
    [dialogSubtitle]="payload.subtitle"
    [dialogAlerts]="dialogAlerts"
    [isOverflowAuto]="false"
    [isActionsShown]="false"
    aukTestIdentification="user-login"
    >
    <ng-container content>
      <form [formGroup]="loginForm"
        (ngSubmit)="onSubmit()"
        >
        <mat-form-field class="tw-w-full tw-mb-4" appearance="outline">

          <mat-label>{{ payload.inputPlaceholder | translateSource }}</mat-label>

          <input [ignoreOnIos]="true"
            aukInputTrim
            matInput
            formControlName="loginOrEmail"
            autocomplete="username"
            aukTestIdentification="username-input"
            id="username"
            name="username"
            type="text"
            aukDelayedAutofocus
            (keyup)="resetHiddenPassword()"
            />

          <!-- this hidden input type="password" forces browser to show stored login/password combinations -->
          <!-- Safari refuses to display "password manager" if input type="password" is not in viewport can't use neither display: none; nor top: -10000px;  -->
          @if (isPasswordInputVisible) {
            <input
              class="tw-w-0 tw-h-0 tw-opacity-0 tw-absolute"
              autocomplete="current-password"
              type="password"
              aukTestIdentification="password-input"
              name="password"
              id="password"
              formControlName="hiddenPassword"
              />
          }

          <auk-aukro-info-hover [messageKey]="'REGISTRATION_PAGE_INFO_HOVER'"
            matSuffix>
          </auk-aukro-info-hover>

          @if (loginOrEmailControl.invalid) {
            <mat-error>
              <span>{{ loginOrEmailErrorText | translateSource }}</span>
            </mat-error>
          }

        </mat-form-field>

        <auk-button class="tw-w-full tw-mb-7"
          [isDisabled]="isSubmitting || loginForm.invalid"
          [isLoading]="isSubmitting"
          type="submit"
          [chin]="true"
          colorCombination="PRIMARY"
          >
          {{ payload.continueButtonText | translateSource }}
        </auk-button>
      </form>



      @if (!isNativeIosApp) {
        <div
          class="tw-w-full tw-h-[7px] tw-border-b tw-border-solid tw-border-b-tertiary-200 tw-text-center tw-my-4">
          <span class="tw-text-[13px] tw-px-4 tw-leading-none tw-bg-contrast tw-text-tertiary-400">
            {{ payload.orDividerText | translateSource }}
          </span>
        </div>
      }

      @if (!isNativeIosApp) {
        <auk-facebook-button
          class="tw-block tw-mt-8"
          [buttonText]="payload.loginViaFacebookButtonText"
          (facebookButtonClick)="loginViaFB()"
        ></auk-facebook-button>
      }
    </ng-container>
  </auk-dialog-content>
</auk-authenticate-wrapper>
