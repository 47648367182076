import { Subscription } from 'rxjs';
import { DelayModel } from '@util/zone/model/delay.model';
import { ZoneScheduler } from '@util/zone/domain/zone-scheduler';
import { WorkModel } from '@util/zone/model/work.model';

export class LeaveZoneScheduler extends ZoneScheduler {

  public override schedule<T>(...args: [WorkModel<T>, DelayModel, T]): Subscription {
    return this.ngZone.runOutsideAngular(() => this.schedulerLike.schedule(...args));
  }

}
