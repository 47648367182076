import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Credentials, NativeBiometric } from '@capgo/capacitor-native-biometric';
import { Dialog } from '@capacitor/dialog';
import { lastValueFrom } from 'rxjs';

const CREDENTIALS_SERVER_HOST = 'aukro';

@Injectable({
  providedIn: 'root',
})
export class BiometryService {

  constructor(
    private readonly translateService: TranslateService,
  ) { }

  private async performBiometricVerification(): Promise<boolean> {
    const result = await NativeBiometric.isAvailable();

    if (!result.isAvailable) {
      return Promise.resolve(false);
    }

    const translations = await lastValueFrom(
      this.translateService.get(['BIOMETRY_REASON', 'BIOMETRY_LOGIN_IN', 'BIOMETRY_ALLOW', 'BIOMETRY_ALLOW_DESC']),
    );

    const dialogResponse = await Dialog.confirm({
      title: translations.BIOMETRY_ALLOW,
      message: translations.BIOMETRY_ALLOW_DESC,
    });

    if (!dialogResponse.value) {
      return Promise.resolve(false);
    }

    const verified = await NativeBiometric.verifyIdentity({
      reason: translations.BIOMETRY_REASON,
      title: translations.BIOMETRY_LOGIN_IN,
    })
      .then(() => true)
      .catch(() => false);

    return verified;
  }

  public async getCredentials(): Promise<Credentials> {
    const credentials = await this.getLocalCredentials();

    if (credentials) {
      const verified = await this.performBiometricVerification();

      if (!verified) {
        return Promise.reject();
      }
    }

    return credentials;
  }

  public async saveCreditials(username: string, password: string): Promise<void> {
    const credentials = await this.getLocalCredentials();

    if (credentials?.username === username && credentials?.password === password) {
      return Promise.resolve();
    }

    const verified = await this.performBiometricVerification();

    if (!verified) {
      return Promise.resolve();
    }

    await NativeBiometric.deleteCredentials({
      server: CREDENTIALS_SERVER_HOST,
    });

    await NativeBiometric.setCredentials({
      username,
      password,
      server: CREDENTIALS_SERVER_HOST,
    });
  }

  public async removeCreditials(): Promise<void> {
    const verified = await this.performBiometricVerification();

    if (!verified) {
      return Promise.resolve();
    }

    void NativeBiometric.deleteCredentials({
      server: CREDENTIALS_SERVER_HOST,
    });
  }

  private async getLocalCredentials(): Promise<Credentials | null> {
    try {
      const credentials = await NativeBiometric.getCredentials({
        server: CREDENTIALS_SERVER_HOST,
      });
      return credentials;
    } catch {
      return null;
    }
  }

}
