import { Injectable } from '@angular/core';
import { PlatformService } from '@shared/platform/service/platform.service';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root',
})
export class ModalViewsConfigService {

  constructor(private readonly platformService: PlatformService) {
  }

  /**
   * United configuration of user modals (Ux redesign - tasks, actions, prerequisites)
   */
  public getModalConfig(): MatDialogConfig {
    const config: MatDialogConfig = {
      autoFocus: true,
      restoreFocus: true,
      disableClose: true,
      closeOnNavigation: true,
      panelClass: ['user-modal-dialog', 'dialog-no-scroll-x-y'],
    };

    // disable autofocus feature, for ios, because of specific scrolling "feature"
    if (this.platformService.isIos) {
      config.autoFocus = false;
    }

    if (this.platformService.isMobile) {
      config.panelClass = [...config.panelClass, 'slide-in'];
    }

    return config;
  }

}
