import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PlatformService } from '@shared/platform/service/platform.service';

@Directive({
  selector: '[aukHideOnMobileSsr]',
})
export class HideOnMobileSsrDirective implements OnInit {

  constructor(
    private readonly platformService: PlatformService,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
  }

  public ngOnInit(): void {
    // check if is server & mobile & not bot, if yes then show the template, otherwise hide
    if (this.platformService.isServerAndMobileAndNotBot) {
      // hide template
      this.viewContainerRef.clear();
    } else {
      // show template
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

}
