import { Component, ViewChild } from '@angular/core';
import { UserTaskDialogStepHostDirective } from '../directive/user-task-dialog-step-host.directive';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  templateUrl: 'user-task-dialog.component.html',
})
export class UserTaskDialogComponent {

  @ViewChild(UserTaskDialogStepHostDirective, { static: true })
  public stepHost: UserTaskDialogStepHostDirective;

  constructor(private dialogRef: MatDialogRef<UserTaskDialogComponent>) { }

  public onClose(): void {
    this.dialogRef.close();
  }

}

