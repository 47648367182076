@if (!v2) {
  <span class="spinner" [ngClass]="spinnerSizeClass"></span>
}
@if (v2) {
  <auk-icon
    iconType="SVG"
    [source]="'spinner2'"
    [size]="$any(size)"
    class="tw-animate-spin tw-m-auto"
  ></auk-icon>
}
