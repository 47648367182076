import { Inject, Injectable } from '@angular/core';
import { defer, Observable, of, takeLast } from 'rxjs';
import { CaptchaUtils } from './captcha.utils';
import { repeat, takeWhile } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { AukWindow } from '@shared/model/auk-window.interface';
import { WINDOW_OBJECT } from '@util/const/window-object';

@Injectable({
  providedIn: 'root',
})
export class CaptchaWindowService {

  private readonly CAPTCHA_ATTEMPTS_INTERVAL_IN_MILLIS = 1000;
  private readonly CAPTCHA_NUMBER_OF_ATTEMPTS = 12;

  constructor(@Inject(DOCUMENT) private readonly document: Document,
              @Inject(WINDOW_OBJECT) private readonly window: AukWindow) {
  }

  public isCaptchaWindowReady(): Observable<boolean> {
    return defer(() => of(CaptchaUtils.isCaptchaReady(this.window)))
      .pipe(
        repeat({
          count: this.CAPTCHA_NUMBER_OF_ATTEMPTS,
          delay: this.CAPTCHA_ATTEMPTS_INTERVAL_IN_MILLIS ,
        }),
        takeWhile((ready: boolean) => !ready, true),
        takeLast(1),
      );
  }

  public isCaptchaIframeReady(): Observable<boolean> {
    return defer(() => of(CaptchaUtils.isCaptchaIframeReady(this.document)))
      .pipe(
        repeat({
          count: this.CAPTCHA_NUMBER_OF_ATTEMPTS,
          delay: this.CAPTCHA_ATTEMPTS_INTERVAL_IN_MILLIS ,
        }),
        takeWhile((ready: boolean) => !ready, true),
        takeLast(1),
      );
  }

}
