import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { IconWithTextComponent } from '@common/ui-kit/component/icon-with-text/component/icon-with-text.component';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';
import { Translate2Module } from '@common/translations/translate2.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ChipModel } from '@common/ui-kit/component/chip/model/chip.model';
import { ChipTypeTextWithCountModel } from '@common/ui-kit/component/chip/model/chip-type-text-with-count.model';
import { ColorUtil } from '@common/colors/util/color.util';
import isNil from 'lodash-es/isNil';

@Component({
  selector: 'auk-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    IconComponent,
    IconWithTextComponent,
    Translate2Module,
    MatTooltipModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent extends ColorCombinationInputComponent {

  @Input() public chipInfo: ChipModel;

  public get getChipTextWithCount(): ChipTypeTextWithCountModel | false {
    return this.chipInfo.type === 'TEXT_WITH_COUNT' && this.chipInfo;
  }

  // PDEV-22807 Will be removed after update all background color of user chip into color combination id
  protected get backgroundColor(): string {
    return isNil(this.chipInfo.colorCombination) ? this.chipInfo.backgroundColor : 'auto';
  }

  // PDEV-22807 Will be removed after update all background color of user chip into color combination id
  protected get fontColor(): string {
    return isNil(this.chipInfo.colorCombination) ? this.getContrastColorByColor(this.chipInfo.backgroundColor) : 'auto';
  }

  // PDEV-22807 Will be removed after update all background color of user chip into color combination id
  protected getContrastColorByColor(color: string): string {
    const contrastClr = ColorUtil.getColorCssVar(ColorUtil.getColorName('contrast'));
    const contrastOppositeClr = ColorUtil.getColorCssVar(ColorUtil.getColorName('contrast-opposite'));

    if (isNil(color) || !this.isHex(color)) {
      return contrastOppositeClr;
    }

    const red = parseInt(color.substring(1, 3), 16);
    const green = parseInt(color.substring(3, 5), 16);
    const blue = parseInt(color.substring(5, 7), 16);

    return (((red * 0.299) + (green * 0.587) + (blue * 0.114)) > 186) ?
      contrastOppositeClr :
      contrastClr;
  }

  // PDEV-22807 Will be removed after update all background color of user chip into color combination id
  private isHex(hex: string): boolean {
    const onlyHexValue: string = hex.substring(1);
    const onlyHexIntValue = parseInt(onlyHexValue, 16);
    return (onlyHexIntValue.toString(16) === onlyHexValue.toLowerCase());
  }

}
