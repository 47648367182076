import { HttpError } from './model/http-error';

export abstract class BaseErrorInterceptor {

  protected abstract readonly matcher: RegExp;

  protected shouldIntercept(err: HttpError): boolean {
    return this.matcher.test(String(err.code));
  }

}
