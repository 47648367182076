<div class="no-data-or-loading">
  <!--LOADING -->
  @if (dataLoading) {
    <auk-spinner
      size="XL"
      />
  } @else {
    <auk-icon source="heart"
      size="XL"
      iconType="SVG"
      />
    <span class="tw-text-md tw-font-medium">
      {{ emptyPopoverContent.title | translateSource }}
    </span>
    <span class="tw-text-sm tw-text-center">
      {{ emptyPopoverContent.label | translateSource }}
    </span>
  }
  <!--./LOADING -->

</div>
