import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AppInitializer } from '@util/helper-types/initializer.interface';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { takeUntil } from 'rxjs/operators';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { DateUtils } from '@util/util/date.utils';

@Injectable({
  providedIn: 'root',
})
export class AppTranslationsLoadInitializer extends NgUnsubscribe implements AppInitializer {

  private readonly TRANSLATION_INTERVAL_MS: number = DateUtils.convertMinutesToMilliseconds(30);

  constructor(
    private readonly platformCommonService: PlatformCommonService,
    private readonly translationService: TranslateService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public init(): void {
    this.translationService.use('');

    if (this.platformCommonService.isBrowser) {
      this.ngZoneUtilService.intervalOut$(this.TRANSLATION_INTERVAL_MS)
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => {
          this.translationService.reloadLang('');
        });
    }
  }

}
