import { Injectable, ElementRef } from '@angular/core';
import { ConnectionPositionPair, VerticalConnectionPos } from '@angular/cdk/overlay';
import { PLACEMENT_POSITION } from './tooltip2-position.constants.service';
import { Nil } from '@util/helper-types/nil';
import { ObjectUtils } from '@util/util/object.utils';
import { Tooltip2Position } from '../tooltip2-position.type';
import { Tooltip2ArrowPlacementType } from '@common/tooltip2/model/tooltip2-arrow-placement.type';

const ARROW_MARGIN: number = 10;

@Injectable({
  providedIn: 'root',
})
export class TooltipPositionService {

  private readonly placementPosition: {[key in Tooltip2Position]: ConnectionPositionPair} = PLACEMENT_POSITION;

  public getPosition(name: Tooltip2Position): ConnectionPositionPair {
    return this.placementPosition[name];
  }

  public getOppositePosition(name: Tooltip2Position): ConnectionPositionPair {
    const position = this.getPosition(name);

    // Swap 'top' with 'bottom'
    const oppositeOriginY: VerticalConnectionPos = position.originY === 'top' ? 'bottom' : 'top';
    const oppositeOverlayY: VerticalConnectionPos = position.overlayY === 'top' ? 'bottom' : 'top';

    return new ConnectionPositionPair(
      { originX: position.originX, originY: oppositeOriginY },
      { overlayX: position.overlayX, overlayY: oppositeOverlayY },
    );
  }

  // Get Tooltip2Position string based on ConnectionPositionPair
  public getTooltip2Position(position: ConnectionPositionPair): Tooltip2Position | Nil {
    const entry = ObjectUtils.entries(this.placementPosition)
      .find(([_, value]) => this.checkPositionsEqual(value, position));
    return entry ? entry[0] : null;
  }

  private checkPositionsEqual(a: ConnectionPositionPair, b: ConnectionPositionPair): boolean {
    return a.originX === b.originX && a.originY === b.originY && a.overlayX === b.overlayX && a.overlayY === b.overlayY;
  }

  public getArrowPosition(
    hostElement: ElementRef<HTMLElement>,
    tooltipElement: ElementRef<HTMLElement>,
    arrowElement: ElementRef<HTMLElement>,
    position: Tooltip2ArrowPlacementType = 'auto',
  ): number {
    /** Get the host element values */
    const hostElementWidth = hostElement.nativeElement.getBoundingClientRect().width;
    const hostOffsetLeft = hostElement.nativeElement.getBoundingClientRect().left;

    /** Get the tooltip and arrow values */
    const tooltipOffsetLeft = tooltipElement.nativeElement.getBoundingClientRect().left;
    const tooltipWidth = tooltipElement.nativeElement.getBoundingClientRect().width;
    const arrowWidth = arrowElement.nativeElement.getBoundingClientRect().width;

    /** Calculate the left position for the arrow so that it appears in the center of the target element */
    const hostElementCenter = hostElementWidth / 2;

    /** Calculate the difference between the center of the host element and the left edge of the tooltip */
    let arrowPosition = hostElementCenter - (tooltipOffsetLeft - hostOffsetLeft) - arrowWidth / 2;

    if(position === 'center'){
      arrowPosition = tooltipWidth / 2 - arrowWidth / 2;
    }

    /** Ensure that the arrow's position is within the tooltip's width */
    if (arrowPosition < ARROW_MARGIN) {
      arrowPosition = ARROW_MARGIN;
    } else if (arrowPosition > tooltipWidth - arrowWidth - ARROW_MARGIN) {
      arrowPosition = tooltipWidth - arrowWidth - ARROW_MARGIN;
    }

    return arrowPosition;
  }

}
