<span
  [hidden]="!_message"
  [ngClass]="_message?.className"
  >
  <span class="message-container">
    @if (!hasContent && _message?.text) {
      <span class="text-container m-l-2">
        {{ _message.text | translateSource }}
      </span>
    }
    <span [hidden]="!hasContent || !_message" class="content-container" #content>
      <ng-content></ng-content>
    </span>
  </span>
</span>
