import { CurrencyConfigElementEnumModel } from '@shared/currency/model/currency-config-element-enum.model';

// TODO(PDEV-20494)
export const CZK_CURRENCY: CurrencyConfigElementEnumModel = {
  code: 'CZK',
  active: true,
  name: 'koruna',
  suffix: 'Kč',
  inputFractionDigits: 0,
  inputStep: 1,
};

// TODO(PDEV-20494)
export const DEFAULT_CURRENCY: CurrencyConfigElementEnumModel = CZK_CURRENCY;
