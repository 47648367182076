import { Pipe, PipeTransform } from '@angular/core';
import { ProductUtil } from '@shared/util/product/product.util';
import { OfferUrlObject } from '@shared/util/product/model/offer-url-object';

@Pipe({
  name: 'offerUrl',
  standalone: true,
})
export class OfferUrlPipe implements PipeTransform {

  public transform(urlObject: OfferUrlObject, idProp?: string): string[] {
    return ProductUtil.getOfferUrl(urlObject, idProp);
  }

}
