import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { defer, mergeMap, Observable, of, retry, throwError } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { RECAPTCHA_COMPONENT } from '@shared/captcha/token/recaptcha-component.token';
import { CaptchaService } from '@shared/services/captcha/captcha.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import isNil from 'lodash-es/isNil';

@Injectable()
export class CaptchaInterceptor implements HttpInterceptor {

  private readonly CAPTCHA_ERROR_CODES: string[] = [
    'captcha.required',
    'captcha.invalid',
  ];

  private readonly CAPTCHA_TOKEN_HEADER: string = 'X-Captcha-Token';

  constructor(
    private readonly captchaService: CaptchaService,
  ) {
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let modifiedRequest: Readonly<HttpRequest<unknown>> = req;

    return of(req)
      .pipe(
        mergeMap(() => defer(() => next.handle(modifiedRequest))
          .pipe(
            filter(e => e.type === HttpEventType.Response),
            retry({
              delay: (error: HttpErrorResponse) => this.handleCaptcha(req, error)
                .pipe(tap((r: HttpRequest<unknown>) => modifiedRequest = r)),
            }),
          ),
        ),
      );
  }

  private handleCaptcha(req: HttpRequest<unknown>, err: HttpErrorResponse): Observable<HttpRequest<unknown>> {
    const recaptchaComponent: RecaptchaComponent = req.context.get(RECAPTCHA_COMPONENT);

    if (!isNil(recaptchaComponent) && err?.status === 417 && this.CAPTCHA_ERROR_CODES.includes(err.error.errorMessage)) {
      return this.captchaService.executeCaptcha(recaptchaComponent)
        .pipe(
          map((captchaToken: string) => req.clone({ setHeaders: { [this.CAPTCHA_TOKEN_HEADER]: captchaToken } })),
        );
    }

    return throwError(() => err);
  }

}
