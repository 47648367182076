import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface SetUrlAnnouncementsReadParams {
  id: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type TypeExportParamsEnum =
  'CSV' |
  'XLSX';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface StatusParams {
  /** announcementState */
  announcementState: AnnouncementStateStatusParamsEnum;
  /** format: int32 */
  page?: number;
  /** format: int32 */
  size?: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type AnnouncementStateStatusParamsEnum =
  'ALL' |
  'READ' |
  'UNREAD';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface DisplayedParams {
  /** userAnnouncementStatusIds */
  userAnnouncementStatusIds: number[];
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ReadParams {
  /** changeReadStatusAnnouncementDto */
  changeReadStatusAnnouncementDto: model.ChangeReadStatusAnnouncementDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface TopParams {
  /**
   * count
   * format: int32
   */
  count: number;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface StatusTEMPParams {
  /** userAnnouncementStatusIds */
  userAnnouncementStatusIds: number[];
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root',
})
export class AnnouncementApiService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public status(params: StatusParams): Observable<model.UserAnnouncementStatusDtoPage> {
    let queryParams = {
      announcementState: params.announcementState,
      page: params.page,
      size: params.size,
    };
    return this.getGenerated(`/announcement/status`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getUrlAnnouncementsForUser(): Observable<model.UrlAnnouncementDto[]> {
    let queryParams = {};
    return this.getGenerated(`/announcement/urlAnnouncements`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public setUrlAnnouncementsRead(params: SetUrlAnnouncementsReadParams): Observable<void> {
    let bodyParams = params.id;
    let queryParams = {};
    let formDataParams;
    return this.postGenerated(`/announcement/urlAnnouncements/read`, bodyParams, queryParams, formDataParams);
  }

  /** Set displayed date of announcements */
  public displayed(params: DisplayedParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.userAnnouncementStatusIds;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/announcement/status/displayed`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public read(params: ReadParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.changeReadStatusAnnouncementDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/announcement/status/read`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public top(params: TopParams): Observable<model.UserTopAnnouncementsDto> {
    let queryParams = {
      count: params.count,
    };
    return this.getGenerated(`/announcement/status/top`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public statusTEMP(params: StatusTEMPParams): Observable<void> {
    let queryParams = {
      userAnnouncementStatusIds: params.userAnnouncementStatusIds,
    };
    return this.deleteGenerated(`/announcement/statusTEMP`, queryParams);
  }
}
