import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, from } from 'rxjs';
import { take, map } from 'rxjs/operators';
import isNil from 'lodash-es/isNil';
import { DomainService } from '@shared/platform/domain.service';
import isEmpty from 'lodash-es/isEmpty';
import findKey from 'lodash-es/findKey';
import { LocaleConstants } from '@shared/platform/locale.constants';
import { AukroMobileTab } from '@shared/legacy/component/aukro-tabs/aukro-tabs.interface';
import { Lang } from './lang.const';

const routesSuffix: string = '-routes.json';
@Injectable({
  providedIn: 'root',
})
export class UrlService {

  public routesTranslationList: Observable<Record<string, string>>;
  public routesTranslationListCS: Observable<Record<string, string>>;
  public routesTranslationListSK: Observable<Record<string, string>>;

  constructor(
    private readonly domainService: DomainService) {
    this.routesTranslationListCS =
      from(import(`../../../assets/i18n/${ 'cs' }${ routesSuffix }`) as Promise<Record<string, string>>);
    this.routesTranslationListSK =
      from(import(`../../../assets/i18n/${ 'sk' }${ routesSuffix }`) as Promise<Record<string, string>>);

    if (this.domainService.lang === 'cs') {
      this.routesTranslationList = this.routesTranslationListCS;
    } else if (this.domainService.lang === 'sk') {
      this.routesTranslationList = this.routesTranslationListSK;
    }
  }

  /**
   * Returns List of Translated URLs as Observable
   * @param tabsLocal
   */
  public translateUrlTabs(tabsLocal: AukroMobileTab[]): Observable<AukroMobileTab[]> {
    const localURLs = tabsLocal.map(tab => tab.url);
    const translateUrlList = localURLs.map((localURL) => this.translateUrl(localURL));
    return forkJoin(translateUrlList)
      .pipe(
        take(1),
        map((translatedURLs) => tabsLocal.map((tab, index) => ({ ...tab, translatedUrl: translatedURLs[index] }))),
      );
  }

  /**
   * Returns prepared data for translating or reversed translating url
   * @param localURL
   */
  public prepareUrlData(localURL: string): { subPaths: string[]; queryParams: string } {
    // Split URL to path and query param
    const urlSplit = localURL.split('?').filter(subPath => subPath);
    const justPaths = urlSplit[0];

    // If available, prepare queryParams for alter append
    const queryParams = urlSplit[1] ? '?' + urlSplit[1] : '';

    // Split URL to parts so we can translate just parts
    const subPaths = justPaths.split('/').filter(subPath => subPath);

    return { subPaths, queryParams };
  }

  /**
   * Returns Translated URL as Observable every part of URL is localized separately
   * @param localURL
   */
  public translateUrl(localURL: string): Observable<string> {
    const { subPaths, queryParams } = this.prepareUrlData(localURL);

    if (isEmpty(subPaths)) {
      return of(localURL);
    } else {
      return this.routesTranslationList
        .pipe(
          take(1),
          map((routesTranslationList: Record<string, string>) => {
            //Translate every part of URL one by one from routesTranslationList (JSON file with translation data)
            const translatedSubPaths = subPaths.map((subPath) =>
              routesTranslationList[`${ LocaleConstants.ROUTES_PREFFIX }${ subPath.replace(/-/g, '_').toUpperCase() }`] || subPath);
            return `/${ translatedSubPaths.join('/') }${ queryParams }`;
          }),
        );
    }
  }

  /**
   * Returns Reversed Translated URL as Observable every part of URL is taken from key separately
   * @param localURL
   */
  public reverseTranslateUrl(localURL: string, explicitDomain?: Lang): Observable<string> {
    const { subPaths, queryParams } = this.prepareUrlData(localURL);
    let localRoutesTranslationList = this.routesTranslationList;

    if (explicitDomain === 'cs') {
      localRoutesTranslationList = this.routesTranslationListCS;
    } else if (explicitDomain === 'sk') {
      localRoutesTranslationList = this.routesTranslationListSK;
    }

    return localRoutesTranslationList
      .pipe(
        take(1),
        map((routesTranslationList: Record<string, string>) => {
          //Find keys of translated part of URL so we can reverse translate back to CS (cs translation is key)
          const reversedSubPathsUrl = subPaths.map((subPath) => {
            const translationKey = findKey(routesTranslationList, (translatedTitle) => subPath === translatedTitle);
            if (!isNil(translationKey)) {
              return translationKey.replace(LocaleConstants.ROUTES_PREFFIX, '').replace(/_/g, '-').toLowerCase();
            } else {
              return subPath;
            }
          });
          return `/${ reversedSubPathsUrl.join('/') }${ queryParams }`;
        }),
      );

  }

}
