import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Component({
  template: '',
})
export abstract class AukBaseLocalStateComponent<TState> extends NgUnsubscribe {

  public readonly state$: BehaviorSubject<TState | undefined>;

  constructor() {
    super();
    this.state$ = new BehaviorSubject<TState | undefined>(undefined);
  }

  public get state(): TState {
    return this.state$.getValue();
  }

  protected updateState(state: Partial<TState>): TState {
    const newState: TState = {
      ...this.state,
      ...state,
    };

    this.state$.next(newState);

    return newState;
  }

}
