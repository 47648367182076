import { NgModule } from '@angular/core';
import { CommonModule, NgForOf, NgIf } from '@angular/common';
import { SuggestionUserComponent } from '@shared/app-header/module/app-header-search/component/suggestion-user/suggestion-user.component';
import { SuggestionItemComponent } from '@shared/app-header/module/app-header-search/component/suggestion-item/suggestion-item.component';
import { CategoryItemComponent } from '@shared/app-header/module/app-header-search/component/category-item/category-item.component';
import { AppHeaderSearchComponent } from '@shared/app-header/module/app-header-search/component/app-header-search/app-header-search.component';
import { PopularSearchComponent } from '@shared/app-header/module/app-header-search/component/popular-search/popular-search.component';
import { SearchCategoriesListComponent } from '@shared/app-header/module/app-header-search/component/search-categories-list/search-categories-list.component';
import { DropdownModule } from '@shared/legacy/directive/dropdown/dropdown.module';
import { Translate2Module } from '@common/translations/translate2.module';
import { FormsModule } from '@angular/forms';
import { StopPropagationOnClickDirective } from '@shared/legacy/directive/stop-propagation/stop-propagation-on-click.directive';
import { OfferUrlPipe } from '@shared/pipes/offer-url/offer-url.pipe';
import { RouterModule } from '@angular/router';
import { SvgIconModule } from '@shared/legacy/component/svg-icon/svg-icon.module';
import { CurrencyPipeModule } from '@shared/currency/pipe/currency-pipe.module';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { SearchCompactComponent } from './component/search-compact/search-compact.component';
import { AppHeaderSearchInputComponent } from './component/app-header-search-input/app-header-search-input.component';
import { LandingPageItemComponent } from '@shared/app-header/module/app-header-search/component/landing-page-item/landing-page-item.component';
import { DialogDropdownDirective } from '@common/dialog/directive/dialog-dropdown.directive';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';

@NgModule({
  imports: [
    CommonModule,
    DropdownModule,
    Translate2Module,
    FormsModule,
    StopPropagationOnClickDirective,
    OfferUrlPipe,
    RouterModule,
    SvgIconModule,
    CurrencyPipeModule,
    IconComponent,
    AppHeaderSearchInputComponent,
    SearchCategoriesListComponent,
    DialogDropdownDirective,
    InnerHtmlDirective,
    FormsModule,
    NgForOf,
    NgIf,
    FormsModule,
    NgForOf,
    NgIf,
    TestIdentificationDirective,
  ],
  declarations: [
    AppHeaderSearchComponent,
    SuggestionUserComponent,
    SuggestionItemComponent,
    LandingPageItemComponent,
    CategoryItemComponent,
    PopularSearchComponent,
    SearchCompactComponent,
  ],
  exports: [
    AppHeaderSearchComponent,
  ],
})
export class AppHeaderSearchModule {
}
