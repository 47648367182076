import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogContentComponent } from './dialog-content.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DialogWrapperComponent } from './dialog-wrapper.component';
import { DialogWrapperWithContentComponent } from './dialog-wrapper-with-content.component';
import { Translate2Module } from '@common/translations/translate2.module';
import { AlertBannerComponent } from '@common/ui-kit/component/alert-banner/component/alert-banner.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    IconComponent,
    Translate2Module,
    AlertBannerComponent,
    InnerHtmlDirective,
  ],
  declarations: [
    DialogContentComponent,
    DialogWrapperComponent,
    DialogWrapperWithContentComponent,
  ],
  exports: [
    DialogContentComponent,
    DialogWrapperComponent,
    DialogWrapperWithContentComponent,
  ],
})
export class DialogWrapperModule {
}
