<button
  #buttonElm
  class="auk-button tw-transition"
  [name]="nameAttr"
  [disabled]="isDisabled"
  [class.is-loading]="isLoadingShown"
  [class.tw-duration-100]="transitionDurationMs === 100"
  [class.tw-duration-200]="transitionDurationMs === 200"
  [ngClass]="buttonClasses"
  [type]="type"
  [attr.data-gtm]="gtmAttr"
  (click)="onButtonClick($event)"
  >
  <div class="auk-button-content">
    @if (isLoadingShown) {
      <auk-spinner
        [size]="spinnerSize"
        [colorCombination]="colorCombination"
      ></auk-spinner>
    }

    <div class="tw-contents" [class.tw-hidden]="isLoadingShown">
      <ng-content></ng-content>
    </div>
  </div>
</button>
