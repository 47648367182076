import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { LocaleConstants } from '@shared/platform/locale.constants';
import { DomainService } from '@shared/platform/domain.service';
import { Lang } from '@shared/platform/lang.const';
import { ArrayUtils } from '@util/util/array.utils';
import isNil from 'lodash-es/isNil';
import { StringUtils } from '@util/util/string.utils';

@Injectable({
  providedIn: 'root',
})
export class HreflangService {

  private renderer: Renderer2;

  constructor(private readonly rendererFactory: RendererFactory2,
              private readonly domainService: DomainService,
              @Inject(DOCUMENT) private document: Document) {
    this.renderer = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {},
    });
  }

  /**
   * Uses href lang list to call each one for append except active domain
   * @param hreflangs
   */
  public appendHrefLangList(hreflangs?: Record<Lang, string>): void {
    if (isNil(hreflangs) || ArrayUtils.isEmpty(Object.keys(hreflangs))) {
      return;
    }

    const currentLocale = this.domainService.locale;
    LocaleConstants.HREF_LANG_MAP.forEach((domainName, locale) => {
      if (locale != currentLocale && !StringUtils.isEmpty(hreflangs[locale.language])) {
        this.appendHrefLang(locale.hrefLangLocale, domainName, hreflangs[locale.language]);
      }
    });
  }

  /** Uses href lang list to call each one for removal */
  public removeHrefLangList(): void {
    LocaleConstants.HREF_LANG_MAP.forEach((domain, locale) => {
      if (this.domainService.lang !== locale.language) {
        this.removeHrefLang(locale.hrefLangLocale);
      }
    });
  }

  /**
   * Will remove hrefLang
   * @param lang
   */
  private removeHrefLang(lang: string): void {
    const elements = this.document.head.querySelector('link[hreflang="' + lang + '"]');
    if (elements) {
      this.renderer.removeChild(this.document.head, elements);
    }
  }

  /**
   * Will append hrefLang to link to head for alternate language pages
   * @param lang
   * @param domainName
   * @param seoUrl
   */
  private appendHrefLang(lang: string, domainName: string, seoUrl: string): void {
    const linkElt = this.renderer.createElement('link');
    this.renderer.setAttribute(linkElt, 'rel', 'alternate');
    this.renderer.setAttribute(linkElt, 'hreflang', lang);
    this.renderer.setAttribute(linkElt, 'href', `https://${ domainName }${ seoUrl }`);
    this.renderer.appendChild(this.document.head, linkElt);
  }

}
