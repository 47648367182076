import { UserTaskLoginStepResolutionHandlerService } from './user-task-login-step-resolution-handler.service';
import { UserTaskStepResolutionUnionModel } from '../../base/model/user-task-step-resolution-union.model';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserTaskLoginStepSetPasswordResolutionHandlerService extends UserTaskLoginStepResolutionHandlerService<'SET_PASSWORD'> {

  constructor(
    private readonly router: Router,
  ) {
    super();
  }

  public handle(resolution: UserTaskStepResolutionUnionModel<'LOGIN', 'SET_PASSWORD'>,
    taskPayload: UserTaskPayloadModel<'LOGIN'>): UserTaskStepUnionModel<'LOGIN'> {
    if (resolution.type === 'RESOLUTION_LOGIN_EMAIL') {
      return {
        type: 'LOGIN_EMAIL',
        payload: {
          sourceAction: taskPayload?.sourceAction,
          ...taskPayload?.stepsText?.LOGIN_EMAIL,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_SET_PASSWORD') {
      void this.router.navigate(['/zapomenute-heslo'], { queryParams: { email: resolution.payload.email } });
      throw new Error('User failed to login');
    }
  }

}
