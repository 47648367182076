import { ViewportWidthType } from '../model/viewport-width.type';
import { MediaBreakpointsWithMinType } from '@common/responsiveness/type/media-breakpoints-with-min.type';

export class ViewportWidthTypeUtil {

  public static getViewportWidthType(breakpoint: MediaBreakpointsWithMinType): ViewportWidthType {
    if (breakpoint === 'MIN' || breakpoint === 'SM') {
      return 'mobile';
    }

    if (breakpoint === 'MD') {
      return 'tablet';
    }

    return 'desktop';
  }

}
