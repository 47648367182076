import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { I18nPluralPipe, NgClass } from '@angular/common';
import { CountdownPanelComponent } from '@common/ui-kit/component/countdown-panel/component/countdown-panel.component';
import { Translate2Module } from '@common/translations/translate2.module';
import { StringDate } from '@util/helper-types/string-date';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
// eslint-disable-next-line import/no-restricted-paths
import { DomainService } from '@shared/platform/domain.service';
import { TimeService } from '@common/time/service/time.service';
import moment from 'moment-mini-ts';
import { TimeUtil } from '@common/ui-kit/common/time/class/time.util';

@Component({
  selector: 'auk-item-card-timer',
  templateUrl: './item-card-timer.component.html',
  standalone: true,
  imports: [
    CountdownPanelComponent,
    Translate2Module,
    NgClass,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCardTimerComponent implements OnChanges {

  @Input() public timeToEnd: StringDate;

  @Input() public itemHasEnded: boolean = false;

  @Output() public countdownEnd: EventEmitter<void> = new EventEmitter<void>();

  // This will be used to select between simple time until render (more than one day) or full live render (less than one day).
  protected isMoreThenOneDay: boolean = false;
  protected humanReadableTime: string = '';
  protected lessThanMinute: boolean = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly domainService: DomainService,
    private readonly timeService: TimeService,
    private readonly i18nPluralPipe: I18nPluralPipe,
  ) {
  }

  public ngOnChanges(changes: AukSimpleChanges<ItemCardTimerComponent>): void {
    if (changes.timeToEnd) {
      this.calculateHowManyDaysUntil();
    }
  }

  protected onLessThanOneMinute(isLessThanMinute: boolean): void {
    this.lessThanMinute = isLessThanMinute;
    this.changeDetectorRef.detectChanges();
  }

  protected onCountdownEnd(): void {
    this.countdownEnd.emit();
  }

  /**
   * Will check if auction is ending in more then a day or not.
   * This will be used to select between simple time until render or full live render.
   */
  private calculateHowManyDaysUntil(): void {
    if (moment().diff(this.timeToEnd, 'days') < 0) {
      this.isMoreThenOneDay = true;
      const endingTimeParsed = moment(this.timeToEnd);
      const remainingTime = this.timeService.getRemainingTime(endingTimeParsed);
      const remainingTimeWithoutMilliseconds = TimeUtil.getTimeWithoutMilliseconds(remainingTime);
      this.humanReadableTime =
        TimeUtil.getHumanReadableRemainingTime(remainingTimeWithoutMilliseconds, this.i18nPluralPipe, this.domainService.lang);
    }
  }

}
