@if (yuspResult?.items?.length) {
  <div class="container p-b-3">
    <auk-item-scroll-slider
      [sliderMapperItems]="yuspResult.items"
      aukItemSliderItemMapper
      [description]="{ key: 'RECO_BOX_OFFERS_FOR_YOU_TEXT'}"
      dtoType="YuspItemResultInternal"
      aukItemScrollSliderGa
      (isInViewPort)="onYuspRecommendationBoxShow()"
      (itemClick)="onYuspProductClicked($event)"
    ></auk-item-scroll-slider>
  </div>
}

