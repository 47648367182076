import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import moment, { Duration } from 'moment-mini-ts';
import { map } from 'rxjs/operators';
import { GetFavouriteItemsPreviewRequestParams, UsersMeFavouritesApiService } from '@api/aukro-api/api/users-me-favourites-api.service';
import { UserFavouritesPreviewDto } from '@api/aukro-api/model/user-favourites-preview-dto';
import { FavouritePopoverConfigModel } from '@shared/favourite/model/favourite-popover-config.model';
import { FavouritesApiService, GetFavouriteSearchRequestParams } from '@api/aukro-api/api/favourites-api.service';
import { FavouriteSearchesDto } from '@api/aukro-api/model/favourite-searches-dto';

@Injectable({
  providedIn: 'root',
})
export class FavouritePopoverDataProviderService {

  private readonly defaultAsyncUpdateThresholdDuration: number = 1;
  private readonly defaultUrgentUpdateThresholdDuration: number = 12;

  constructor(
    private readonly configuratorCacheService: ConfiguratorCacheService,
    private readonly usersMeFavouritesApiService: UsersMeFavouritesApiService,
    private readonly favouritesApiService: FavouritesApiService,
  ) {
  }

  private get favouritePopoverConfig$(): Observable<FavouritePopoverConfigModel> {
    return this.configuratorCacheService.getFeSystemParam<FavouritePopoverConfigModel>(
      'FAVOURITE_POPOVER_CONFIG',
      'JSON',
    );
  }

  /**
   * Returns whether async update for favourite items in popover is enabled
   */
  public get isAsyncUpdateEnabled$(): Observable<boolean> {
    return this.favouritePopoverConfig$
      .pipe(
        map((config) => config?.asyncUpdateEnabled),
      );
  }

  /**
   * Returns threshold duration of remaining time, when we can show the remaining time in popover icon in header
   */
  public get urgentCountdownThresholdDuration$(): Observable<Duration> {
    return this.favouritePopoverConfig$
      .pipe(
        map((config) => config?.urgentCountdownMinutesThreshold),
        // map to moment duration
        map((urgentCountdownThresholdDurationHrs) =>
          moment.duration(urgentCountdownThresholdDurationHrs ?? this.defaultUrgentUpdateThresholdDuration, 'minutes')),
      );
  }

  /**
   * Returns threshold duration of remaining time, when we can initialize async update for favourite items
   */
  public get asyncUpdateThresholdDuration$(): Observable<Duration> {
    return this.favouritePopoverConfig$
      .pipe(
        map((config) => config?.asyncUpdateHoursThreshold),
        // map to moment duration
        map((asyncUpdateThresholdDurationHrs) =>
          moment.duration(asyncUpdateThresholdDurationHrs ?? this.defaultAsyncUpdateThresholdDuration, 'hours')),
      );
  }

  /**
   * Fetches data for favourite popover
   */
  public getFavouritePopoverData$(itemsCount?: number): Observable<UserFavouritesPreviewDto> {
    const requestParams: GetFavouriteItemsPreviewRequestParams = {
      itemsCount,
    };

    return this.usersMeFavouritesApiService.getFavouriteItemsPreview$(requestParams);
  }

  /**
   * Fetches data for favourite popover search preview
   */
  public getFavouriteSearchPreview$(
    params: GetFavouriteSearchRequestParams = { size: 5, sort: 'newItemsCount:DESC', page: 0 },
  ): Observable<FavouriteSearchesDto> {
    return this.favouritesApiService.getFavouriteSearch$(params);
  }

}
