<auk-dialog-content
  [dialogTitle]="payload.title"
  [dialogSubtitle]="payload.subtitle"
  [dialogAlerts]="payload.dialogAlerts ? payload.dialogAlerts : [{ type: 'WARNING', text: payload.alertBannerText }]"
  >

  <ng-container actions>
    @if (!payload.regDomainCode) {
      <auk-button colorCombination="PRIMARY"
        [chin]="true"
        class="tw-w-full tw-mb-2"
        (buttonClick)="onBackClick()"
        >
        {{ payload.retryButtonText | translateSource }}
      </auk-button>
      <auk-button colorCombination="SECONDARY"
        class="tw-w-full"
        (buttonClick)="onCancelClick()"
        >
        {{ payload.cancelButtonText | translateSource }}
      </auk-button>
    } @else {
      <auk-button colorCombination="PRIMARY"
        [chin]="true"
        class="tw-w-full tw-mb-3"
        (click)="crossDomainRedirect(false)"
        >
        {{ payload.crossDomainLogin | translateSource }}
      </auk-button>
      <auk-button colorCombination="SECONDARY"
        class="tw-w-full"
        (click)="crossDomainRedirect(true)"
        >
        {{ { key: 'REGISTER_NEW_ACCOUNT' } | translateSource }}
      </auk-button>
    }


  </ng-container>

</auk-dialog-content>
