<div class="cart-update-notification" *ngIf="isCartUpdateNotificationShown" [ngClass]="{ '!tw-top-[65px]': isIosApp }">
  <div class="cart-update-notification__header" [class.is-failed]="insertionFailed">
    <p *ngIf="data.successful; else failed" [aukInnerHtml]="data.message"></p>
    <ng-template #failed>
      <p class="failed tw-w-[90%]">
        <ng-container *ngIf="data.messageTrans; else defaultError">
          {{ data.messageTrans | translateSource }}
        </ng-container>
        <ng-template #defaultError>
          <b [aukInnerHtml]="{ key: 'ADD_TO_CART_POPUP_TEXT_FAILED' }"></b>
          <ng-container *ngIf="data.message">
            <br />
            {{ data.message }}
          </ng-container>
        </ng-template>
      </p>
    </ng-template>
    <i class="material-icons text-white remove text-bold tw-cursor-pointer" (click)="onClose()"> close </i>
  </div>
  <div class="cart-update-notification__body">
    <p class="name">{{ data.itemName }}</p>
    <p class="price">{{ 'CART_NOTIFICATION_COMPONENT_HTML_1' | translate }} {{ data.totalPrice | currency }}</p>
  </div>
  <div *ngIf="data.successful" class="tw-pt-3 tw-pb-3 tw-ml-3 tw-mr-3 tw-flex tw-border-t tw-border-solid tw-border-t-tertiary-200">
    <button class="btn-primary m-l-auto" routerLink="/kosik" [aukInnerHtml]="{ key: 'GO_TO_CART_BUTTON_TEXT' }"></button>
  </div>
</div>
