import { UserTaskLoginStepResolutionHandlerService } from './user-task-login-step-resolution-handler.service';
import { Injectable } from '@angular/core';
import { UserTaskStepResolutionUnionModel } from '../../base/model/user-task-step-resolution-union.model';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';

@Injectable({
  providedIn: 'root',
})
export class UserTaskLoginStepEmailConfirmationRegisteredViaFbResolutionHandlerService extends
  UserTaskLoginStepResolutionHandlerService<'EMAIL_CONFIRMATION_REGISTERED_VIA_FB'> {

  public handle(resolution: UserTaskStepResolutionUnionModel<'LOGIN', 'EMAIL_CONFIRMATION_REGISTERED_VIA_FB'>,
    taskPayload: UserTaskPayloadModel<'LOGIN'>): UserTaskStepUnionModel<'LOGIN'> {
    if (resolution.type === 'RESOLUTION_FB_LOAD') {
      return {
        type: 'FB_LOAD',
        payload: {
          sourceAction: taskPayload?.sourceAction,
          ...taskPayload?.stepsText?.FB_LOAD,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_LOGIN_EMAIL') {
      return {
        type: 'LOGIN_EMAIL',
        payload: {
          sourceAction: taskPayload?.sourceAction,
          ...taskPayload?.stepsText?.LOGIN_EMAIL,
        },
      };
    }
  }

}
