import { ToastType } from '../model/toast-type';
import { ToastStaticConfig } from '../model/toast-static-config';

export const TOAST_TYPES_MAP: Record<ToastType, ToastStaticConfig> = {
  success: {
    iconName: 'done',
    iconType: 'MATERIAL',
    colorCombination: 'SUCCESS',
  },
  info: {
    iconName: 'info',
    iconType: 'MATERIAL',
    colorCombination: 'INFO',
  },
  warning: {
    iconName: 'warning',
    iconType: 'MATERIAL',
    colorCombination: 'WARNING',
  },
  danger: {
    iconName: 'error',
    iconType: 'MATERIAL',
    colorCombination: 'DANGER',
  },
};
