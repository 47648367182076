import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationSource } from '@common/translations/model/translation-source';

@Component({
  selector: 'auk-user-task-login-step-not-you',
  templateUrl: 'user-task-login-step-not-you.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskLoginStepNotYouComponent {

  @Input()
  public email: string;

  @Input()
  public isNotThatYou: TranslationSource;

  @Output()
  public isNotThatYouClick: EventEmitter<void> = new EventEmitter<void>();

  public onIsNotThatYouClick(): void {
    this.isNotThatYouClick.emit();
  }

}
