import { IdAware } from '@util/helper-types/id-aware';

/**
 * Default trackByFn to use in ng-for trackBy field for items with `id` field (if `id` is not set, index of the item is used)
 *
 * Generic type U - type of id (number or string)
 * Generic type T - type of item
 */
export const trackByIdFn = <U extends string | number = string | number, T extends IdAware<U> = IdAware<U>>(
  index: number,
  item: T,
): U | number => item?.id && index;
