import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { HttpClient , HttpContext  } from '@angular/common/http';
import { FileDisputeRequestDto } from '../model/file-dispute-request-dto';
import { PaymentAukroActionDto } from '../model/payment-aukro-action-dto';
import { PaymentButtonBanksBulkDto } from '../model/payment-button-banks-bulk-dto';
import { PaymentButtonBanksDto } from '../model/payment-button-banks-dto';
import { PaymentMethodsDto } from '../model/payment-methods-dto';
import { PaymentSplitDto } from '../model/payment-split-dto';
import { PaymentViaAukroDisputeDto } from '../model/payment-via-aukro-dispute-dto';
import { PaymentViaAukroDisputeResolutionDto } from '../model/payment-via-aukro-dispute-resolution-dto';
import { PaysBillingPaymentDto } from '../model/pays-billing-payment-dto';
import { PaysPaymentDto } from '../model/pays-payment-dto';
import { PaysPaymentResponseDto } from '../model/pays-payment-response-dto';
import { VerificationPaymentDataForBankTransferDto } from '../model/verification-payment-data-for-bank-transfer-dto';
import { BASE_PATH } from '../variables';

export interface CartBankPaymentButtonsRequestParams {
    /** Deal transaction id */
    dealTransactionId: number;
}

export interface CartBankPaymentButtonsBulkRequestParams {
    /** Deal transaction ids */
    dealTransactionIds: number[];
}

export interface ConfirmPaymentViaAukroRequestParams {
    /** Deal transaction ID */
    dealTransactionId: number;
}

export interface DisputeResolutionRequestParams {
    paymentViaAukroDisputeResolutionDto: PaymentViaAukroDisputeResolutionDto;
    /** Payment via Aukro ID */
    paymentViaAukroId: number;
}

export interface FileDisputeRequestParams {
    fileDisputeRequestDto: FileDisputeRequestDto;
}

export interface GetDisputeRequestParams {
    /** Payment via Aukro ID */
    paymentViaAukroId: number;
}

export interface GetPaymentMethods1RequestParams {
    payuPos?: 'VERIFICATION' | 'BILLING' | 'PAYMENT_FOR_SALE';
}

export interface NewBankTransferVerificationPaymentRequestParams {
    paysBillingPaymentDto: PaysBillingPaymentDto;
}

export interface NewPaymentViaAukroRequestParams {
    paysPaymentDto: PaysPaymentDto;
}

export interface NewPaysBillingPaymentRequestParams {
    paysBillingPaymentDto: PaysBillingPaymentDto;
}

export interface PaymentDisputeWithdrawRequestParams {
    /** Deal transaction id */
    dealTransactionId: number;
}

export interface PaymentSplitRequestParams {
    paymentSplitDto: PaymentSplitDto;
}

export interface PaysBillingPaymentConfirmRequestParams {
    amount: number;
    cardBrand?: string;
    cardLevel?: string;
    cardType?: string;
    currencyBaseUnits: number;
    currencyID: string;
    hash: string;
    merchantOrderNumber: number;
    paymentOrderID: string;
    paymentOrderStatusDescription: string;
    paymentOrderStatusID: number;
}

export interface PaysBillingPaymentConfirmSkRequestParams {
    amount: number;
    cardBrand?: string;
    cardLevel?: string;
    cardType?: string;
    currencyBaseUnits: number;
    currencyID: string;
    hash: string;
    merchantOrderNumber: number;
    paymentOrderID: string;
    paymentOrderStatusDescription: string;
    paymentOrderStatusID: number;
}

export interface PaysConfirmVerificationPaymentRequestParams {
    amount: number;
    cardBrand?: string;
    cardLevel?: string;
    cardType?: string;
    currencyBaseUnits: number;
    currencyID: string;
    hash: string;
    merchantOrderNumber: number;
    paymentOrderID: string;
    paymentOrderStatusDescription: string;
    paymentOrderStatusID: number;
}

export interface PaysConfirmVerificationPaymentSkRequestParams {
    amount: number;
    cardBrand?: string;
    cardLevel?: string;
    cardType?: string;
    currencyBaseUnits: number;
    currencyID: string;
    hash: string;
    merchantOrderNumber: number;
    paymentOrderID: string;
    paymentOrderStatusDescription: string;
    paymentOrderStatusID: number;
}

export interface PaysPaymentConfirmRequestParams {
    amount: number;
    cardBrand?: string;
    cardLevel?: string;
    cardType?: string;
    currencyBaseUnits: number;
    currencyID: string;
    hash: string;
    merchantOrderNumber: number;
    paymentOrderID: string;
    paymentOrderStatusDescription: string;
    paymentOrderStatusID: number;
}

export interface PaysPaymentConfirmSkRequestParams {
    amount: number;
    cardBrand?: string;
    cardLevel?: string;
    cardType?: string;
    currencyBaseUnits: number;
    currencyID: string;
    hash: string;
    merchantOrderNumber: number;
    paymentOrderID: string;
    paymentOrderStatusDescription: string;
    paymentOrderStatusID: number;
}

export interface SendPaymentViaAukroBackToBuyerRequestParams {
    paymentAukroActionDto: PaymentAukroActionDto;
    /** Payment via Aukro ID */
    paymentViaAukroId: number;
}

export interface SendPaymentViaAukroBackToBuyerCreatePaymentAndFinishRequestParams {
    paymentAukroActionDto: PaymentAukroActionDto;
    /** Payment via Aukro ID */
    paymentViaAukroId: number;
}

export interface SendPaymentViaAukroBackToBuyerFinishRequestParams {
    paymentAukroActionDto: PaymentAukroActionDto;
    /** Payment via Aukro ID */
    paymentViaAukroId: number;
}

export interface SendPaymentViaAukroToSellerRequestParams {
    paymentAukroActionDto: PaymentAukroActionDto;
    /** Payment via Aukro ID */
    paymentViaAukroId: number;
}

export interface StopPaymentViaAukroRequestParams {
    /** Deal transaction ID */
    dealTransactionId: number;
}

export interface UpdatePaymentViaAukroBankAccountRequestParams {
    bankAccount: string;
    id: number;
}

export interface UpdatePaymentViaAukroStatusManuallyRequestParams {
    /** Payment via Aukro Id */
    paymentViaAukroId: number;
    /** New status */
    paymentViaAukroStatus: 'WAITING_FOR_BUYER_PAYMENT' | 'PAID_WAITING_FOR_BUYER_CONFIRMATION' | 'SHIPPED_WAITING_FOR_BUYER_CONFIRMATION' | 'STOPPED_BY_BUYER' | 'PAYMENT_TO_SELLER_IN_PROGRESS' | 'PAYMENT_TO_SELLER_PENDING' | 'RETURN_TO_BUYER_IN_PROGRESS' | 'RETURN_TO_BUYER_PENDING' | 'PAID_TO_SELLER' | 'RETURNED_TO_BUYER' | 'NOT_PAID_BY_BUYER' | 'PAYMENT_SPLIT' | 'RETURN_TO_BUYER_PENDING_WITH_BP' | 'AUKRO_REMAINED';
}

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {

  private readonly configuration: Configuration = new Configuration();

  constructor(
    private readonly httpClient: HttpClient,
  ) {
  }

  /**
   * List of bank payment buttons for deal
   */
  public cartBankPaymentButtons$(
    requestParameters: CartBankPaymentButtonsRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PaymentButtonBanksDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      dealTransactionId: requestParameters.dealTransactionId,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PaymentButtonBanksDto'),
      headers,
    };
    return this.httpClient.request<PaymentButtonBanksDto>(
      'get',
      `${ BASE_PATH }/payment/cart-bank-payment-buttons`,
      options,
    );
  }

  /**
   * List of bank payment buttons for deals
   */
  public cartBankPaymentButtonsBulk$(
    requestParameters: CartBankPaymentButtonsBulkRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PaymentButtonBanksBulkDto[]> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      dealTransactionIds: requestParameters.dealTransactionIds,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'Array<PaymentButtonBanksBulkDto>'),
      headers,
    };
    return this.httpClient.request<PaymentButtonBanksBulkDto[]>(
      'get',
      `${ BASE_PATH }/payment/cart-bank-payment-buttons-bulk`,
      options,
    );
  }

  /**
   * Check prerequisites for verification payment
   */
  public checkPrerequisitesForNewVerification$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/payment/verification/check-prerequisites`,
      options,
    );
  }

  /**
   * Confirm Payment via Aukro delivery by buyer
   */
  public confirmPaymentViaAukro$(
    requestParameters: ConfirmPaymentViaAukroRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      dealTransactionId: requestParameters.dealTransactionId,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/payment/aukro/buyer/confirm`,
      options,
    );
  }

  /**
   * Resolve Payment via Aukro Dispute
   */
  public disputeResolution$(
    requestParameters: DisputeResolutionRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentViaAukroId: requestParameters.paymentViaAukroId,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.paymentViaAukroDisputeResolutionDto,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/payment/aukro/admin/disputeResolution`,
      options,
    );
  }

  /**
   * File payment via Aukro dispute by buyer
   * @deprecated
   */
  public fileDispute$(
    requestParameters: FileDisputeRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.fileDisputeRequestDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/payment/aukro/buyer/fileDispute`,
      options,
    );
  }

  /**
   * Gets allowed combination of manual payment via Aukro status changes.
   */
  public getAllowedPvaStatusChanges$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<{ [key: string]: string[] }> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, '{ [key: string]: Array<string>; }'),
      headers,
    };
    return this.httpClient.request<{ [key: string]: string[] }>(
      'get',
      `${ BASE_PATH }/payment/aukro/admin/allowedStatusChanges`,
      options,
    );
  }

  /**
   * File payment via Aukro dispute by buyer
   * @deprecated
   */
  public getDispute$(
    requestParameters: GetDisputeRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PaymentViaAukroDisputeDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentViaAukroId: requestParameters.paymentViaAukroId,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PaymentViaAukroDisputeDto'),
      headers,
    };
    return this.httpClient.request<PaymentViaAukroDisputeDto>(
      'get',
      `${ BASE_PATH }/payment/aukro/admin/dispute`,
      options,
    );
  }

  /**
   * Get all aukro payment methods
   */
  public getPaymentMethods1$(
    requestParameters: GetPaymentMethods1RequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PaymentMethodsDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      payuPos: requestParameters.payuPos,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, 'PaymentMethodsDto'),
      headers,
    };
    return this.httpClient.request<PaymentMethodsDto>(
      'get',
      `${ BASE_PATH }/payment/paymentMethods`,
      options,
    );
  }

  /**
   * Creates new payment of VERIFICATION via Bank transfer
   */
  public newBankTransferVerificationPayment$(
    requestParameters: NewBankTransferVerificationPaymentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<number> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.paysBillingPaymentDto,
      responseType: this.configuration.getResponseType(false, 'number'),
      headers,
    };
    return this.httpClient.request<number>(
      'post',
      `${ BASE_PATH }/payment/verificationBankTransfer`,
      options,
    );
  }

  /**
   * Creates new payment via Pays
   */
  public newPaymentViaAukro$(
    requestParameters: NewPaymentViaAukroRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PaysPaymentResponseDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.paysPaymentDto,
      responseType: this.configuration.getResponseType(false, 'PaysPaymentResponseDto'),
      headers,
    };
    return this.httpClient.request<PaysPaymentResponseDto>(
      'post',
      `${ BASE_PATH }/payment/pays`,
      options,
    );
  }

  /**
   * Creates new payment of BILLING via Pays
   */
  public newPaysBillingPayment$(
    requestParameters: NewPaysBillingPaymentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<PaysPaymentResponseDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.paysBillingPaymentDto,
      responseType: this.configuration.getResponseType(false, 'PaysPaymentResponseDto'),
      headers,
    };
    return this.httpClient.request<PaysPaymentResponseDto>(
      'post',
      `${ BASE_PATH }/payment/paysBilling`,
      options,
    );
  }

  /**
   * Withdraw buyer dispute to seller
   * @deprecated
   */
  public paymentDisputeWithdraw$(
    requestParameters: PaymentDisputeWithdrawRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const dealTransactionId: number = requestParameters.dealTransactionId;
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/payment/disputeWithdraw/${ this.configuration.encodeParam({ name: 'dealTransactionId', value: dealTransactionId, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }`,
      options,
    );
  }

  /**
   * Split payment between buyer and seller
   */
  public paymentSplit$(
    requestParameters: PaymentSplitRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      body: requestParameters.paymentSplitDto,
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/payment/paymentSplit`,
      options,
    );
  }

  /**
   * Confirm Pays payment
   */
  public paysBillingPaymentConfirm$(
    requestParameters: PaysBillingPaymentConfirmRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentOrderID: requestParameters.paymentOrderID,
      merchantOrderNumber: requestParameters.merchantOrderNumber,
      paymentOrderStatusID: requestParameters.paymentOrderStatusID,
      currencyID: requestParameters.currencyID,
      amount: requestParameters.amount,
      currencyBaseUnits: requestParameters.currencyBaseUnits,
      paymentOrderStatusDescription: requestParameters.paymentOrderStatusDescription,
      hash: requestParameters.hash,
      cardBrand: requestParameters.cardBrand,
      cardType: requestParameters.cardType,
      cardLevel: requestParameters.cardLevel,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'get',
      `${ BASE_PATH }/payment/pays/confirmBillingPayment`,
      options,
    );
  }

  /**
   * Confirm Pays payment for aukro.sk
   */
  public paysBillingPaymentConfirmSk$(
    requestParameters: PaysBillingPaymentConfirmSkRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentOrderID: requestParameters.paymentOrderID,
      merchantOrderNumber: requestParameters.merchantOrderNumber,
      paymentOrderStatusID: requestParameters.paymentOrderStatusID,
      currencyID: requestParameters.currencyID,
      amount: requestParameters.amount,
      currencyBaseUnits: requestParameters.currencyBaseUnits,
      paymentOrderStatusDescription: requestParameters.paymentOrderStatusDescription,
      hash: requestParameters.hash,
      cardBrand: requestParameters.cardBrand,
      cardType: requestParameters.cardType,
      cardLevel: requestParameters.cardLevel,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'get',
      `${ BASE_PATH }/payment/pays/confirmBillingPayment-sk`,
      options,
    );
  }

  /**
   * Confirm Pays verification for sale
   */
  public paysConfirmVerificationPayment$(
    requestParameters: PaysConfirmVerificationPaymentRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentOrderID: requestParameters.paymentOrderID,
      merchantOrderNumber: requestParameters.merchantOrderNumber,
      paymentOrderStatusID: requestParameters.paymentOrderStatusID,
      currencyID: requestParameters.currencyID,
      amount: requestParameters.amount,
      currencyBaseUnits: requestParameters.currencyBaseUnits,
      paymentOrderStatusDescription: requestParameters.paymentOrderStatusDescription,
      hash: requestParameters.hash,
      cardBrand: requestParameters.cardBrand,
      cardType: requestParameters.cardType,
      cardLevel: requestParameters.cardLevel,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'get',
      `${ BASE_PATH }/payment/pays/confirmVerificationPayment`,
      options,
    );
  }

  /**
   * Confirm Pays verification for sale for aukro.sk
   */
  public paysConfirmVerificationPaymentSk$(
    requestParameters: PaysConfirmVerificationPaymentSkRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentOrderID: requestParameters.paymentOrderID,
      merchantOrderNumber: requestParameters.merchantOrderNumber,
      paymentOrderStatusID: requestParameters.paymentOrderStatusID,
      currencyID: requestParameters.currencyID,
      amount: requestParameters.amount,
      currencyBaseUnits: requestParameters.currencyBaseUnits,
      paymentOrderStatusDescription: requestParameters.paymentOrderStatusDescription,
      hash: requestParameters.hash,
      cardBrand: requestParameters.cardBrand,
      cardType: requestParameters.cardType,
      cardLevel: requestParameters.cardLevel,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'get',
      `${ BASE_PATH }/payment/pays/confirmVerificationPayment-sk`,
      options,
    );
  }

  /**
   * Confirm Pays payment
   */
  public paysPaymentConfirm$(
    requestParameters: PaysPaymentConfirmRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentOrderID: requestParameters.paymentOrderID,
      merchantOrderNumber: requestParameters.merchantOrderNumber,
      paymentOrderStatusID: requestParameters.paymentOrderStatusID,
      currencyID: requestParameters.currencyID,
      amount: requestParameters.amount,
      currencyBaseUnits: requestParameters.currencyBaseUnits,
      paymentOrderStatusDescription: requestParameters.paymentOrderStatusDescription,
      hash: requestParameters.hash,
      cardBrand: requestParameters.cardBrand,
      cardType: requestParameters.cardType,
      cardLevel: requestParameters.cardLevel,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'get',
      `${ BASE_PATH }/payment/pays/confirm`,
      options,
    );
  }

  /**
   * Confirm Pays payment for sk payments
   */
  public paysPaymentConfirmSk$(
    requestParameters: PaysPaymentConfirmSkRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentOrderID: requestParameters.paymentOrderID,
      merchantOrderNumber: requestParameters.merchantOrderNumber,
      paymentOrderStatusID: requestParameters.paymentOrderStatusID,
      currencyID: requestParameters.currencyID,
      amount: requestParameters.amount,
      currencyBaseUnits: requestParameters.currencyBaseUnits,
      paymentOrderStatusDescription: requestParameters.paymentOrderStatusDescription,
      hash: requestParameters.hash,
      cardBrand: requestParameters.cardBrand,
      cardType: requestParameters.cardType,
      cardLevel: requestParameters.cardLevel,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'get',
      `${ BASE_PATH }/payment/pays/confirm-sk`,
      options,
    );
  }

  /**
   * Send Payment via Aukro back to buyer manually by admin user
   */
  public sendPaymentViaAukroBackToBuyer$(
    requestParameters: SendPaymentViaAukroBackToBuyerRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentViaAukroId: requestParameters.paymentViaAukroId,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.paymentAukroActionDto,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/payment/aukro/admin/sendBackToBuyer`,
      options,
    );
  }

  /**
   * Creates returning payment and sets finished status of sending Payment via Aukro back to buyer.
   */
  public sendPaymentViaAukroBackToBuyerCreatePaymentAndFinish$(
    requestParameters: SendPaymentViaAukroBackToBuyerCreatePaymentAndFinishRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentViaAukroId: requestParameters.paymentViaAukroId,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.paymentAukroActionDto,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/payment/aukro/admin/sendBackToBuyer/createPaymentAndFinish`,
      options,
    );
  }

  /**
   * Just sets finished status of sending Payment via Aukro back to buyer.
   */
  public sendPaymentViaAukroBackToBuyerFinish$(
    requestParameters: SendPaymentViaAukroBackToBuyerFinishRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentViaAukroId: requestParameters.paymentViaAukroId,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.paymentAukroActionDto,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/payment/aukro/admin/sendBackToBuyer/finish`,
      options,
    );
  }

  /**
   * Send Payment via Aukro to seller manually by admin user
   */
  public sendPaymentViaAukroToSeller$(
    requestParameters: SendPaymentViaAukroToSellerRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentViaAukroId: requestParameters.paymentViaAukroId,
    };
    const options: object = {
      context: httpContext,
      body: requestParameters.paymentAukroActionDto,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/payment/aukro/admin/sendToSeller`,
      options,
    );
  }

  /**
   * Stop Payment via Aukro by buyer
   */
  public stopPaymentViaAukro$(
    requestParameters: StopPaymentViaAukroRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      dealTransactionId: requestParameters.dealTransactionId,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'post',
      `${ BASE_PATH }/payment/aukro/buyer/stop`,
      options,
    );
  }

  /**
   * Updates Payment via Aukro bank account by admin user
   */
  public updatePaymentViaAukroBankAccount$(
    requestParameters: UpdatePaymentViaAukroBankAccountRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const id: number = requestParameters.id;
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      bankAccount: requestParameters.bankAccount,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'put',
      `${ BASE_PATH }/payment/aukro/${ this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'number', dataFormat: 'int64' }) }/admin/bankAccount`,
      options,
    );
  }

  /**
   * Updates status of the given payment via Aukro.
   */
  public updatePaymentViaAukroStatusManually$(
    requestParameters: UpdatePaymentViaAukroStatusManuallyRequestParams,
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<void> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const queryParams = {
      paymentViaAukroId: requestParameters.paymentViaAukroId,
      paymentViaAukroStatus: requestParameters.paymentViaAukroStatus,
    };
    const options: object = {
      context: httpContext,
      params: this.configuration.toHttpParams(queryParams),
      responseType: this.configuration.getResponseType(false, ''),
      headers,
    };
    return this.httpClient.request<void>(
      'put',
      `${ BASE_PATH }/payment/aukro/admin/updateStatus`,
      options,
    );
  }

  /**
   * Returns dto holding information needed to perform verification bank transfer payment
   */
  public verificationPaymentDataForBankTransfer$(
    headers: Record<string, string> = {},
    context?: HttpContext,
  ): Observable<VerificationPaymentDataForBankTransferDto> {
    const httpContext: HttpContext = context ?? new HttpContext();
    const options: object = {
      context: httpContext,
      responseType: this.configuration.getResponseType(false, 'VerificationPaymentDataForBankTransferDto'),
      headers,
    };
    return this.httpClient.request<VerificationPaymentDataForBankTransferDto>(
      'get',
      `${ BASE_PATH }/payment/verificationPaymentDataForBankTransfer`,
      options,
    );
  }

}
