import { Injectable } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { NumberUtils } from '@util/util/number.utils';
import { SavingRateModel } from '../model/saving-rate.model';
import { RetailPricePercentageModel } from '@common/ui-kit/component/retail-price/model/retail-price-percentage.model';
import { Observable, of } from 'rxjs';
import { Nil } from '@util/helper-types/nil';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { RetailPriceItemDataModel } from '@shared/retail-price/model/retail-price-item-data.model';
import { map, take } from 'rxjs/operators';
import { RetailPriceCategoryModel } from '@shared/retail-price/model/retail-price-category.model';

/**
 * Saving percentage, which we show in UI, can be max 99,
 */
const MAX_SAVING_PERCENTAGE: number = 99;

@Injectable({
  providedIn: 'root',
})
export class RetailPriceService {

  constructor(
    private readonly configuratorCacheService: ConfiguratorCacheService,
  ) {
  }

  /**
   * Determines if the retail price should be shown and calculates the saving percentage.
   *
   * A retail price should be displayed if:
   * - It exists and is not null
   * - Amount of retail price is not zero
   * - The current price is less than or equal to the retail price multiplied by the (1 - applicable rate)
   * If the product is a BIDDING offer, it returns the retail price but not the saving percentage
   * (it can be overridden by alwaysShowSavingPercentage parameters).
   */
  public getRetailPrice(
    itemData: RetailPriceItemDataModel,
    savingRates: SavingRateModel,
    alwaysShowSavingPercentage: boolean = false,
  ): RetailPricePercentageModel | Nil {
    if (isNil(itemData) || isNil(itemData.retailPrice) || itemData.retailPrice.amount === 0 || isNil(savingRates)) {
      return null;
    }

    const category: RetailPriceCategoryModel = this.getItemCategory(itemData.categoryOrPath);

    // find specific rate for item category
    const specificRate = savingRates.specificRates?.find(rate => rate.categorySeoUrl === category.seoUrl);

    // use specific rate if available, otherwise use default rate
    const applicableRate = isNil(specificRate) ? savingRates?.defaultRate : specificRate.rate;

    // calculate value of retail target price and round it up to 2 decimals
    const retailTargetPrice = NumberUtils.roundUp(itemData.retailPrice.amount * (1 - applicableRate), 2);

    // get correct price based on item type
    const correctCurrentItemPrice = itemData.itemType === 'BUY_NOW' ? itemData.buyNowPrice : itemData.price;

    // if current item price is higher than retail target price, return Nil
    if (correctCurrentItemPrice.amount > retailTargetPrice) {
      return null;
    }

    const savedAmount = itemData.retailPrice.amount - correctCurrentItemPrice.amount;
    // Calculate saved percentage based on retail price and round it up
    // Saving percentage can be max 99 (so if rounded calculated percentage is 100, we set it to 99)
    const savingPercentage = Math.min(Math.round((savedAmount / itemData.retailPrice.amount) * 100), MAX_SAVING_PERCENTAGE);

    return {
      retailPrice: itemData.retailPrice,
      savingPercentage: alwaysShowSavingPercentage || itemData.itemType === 'BUY_NOW' ? savingPercentage : null,
    };
  }

  /**
   * Same like {@link getRetailPrice}, but doesn't need saving rates, it gets them automatically
   */
  public getRetailPrice$(
    itemData: RetailPriceItemDataModel,
    alwaysShowSavingPercentage: boolean = false,
  ): Observable<RetailPricePercentageModel | Nil> {
    // if there's no retail price or is zero, return Nil
    if (isNil(itemData) || isNil(itemData.retailPrice) || itemData.retailPrice.amount === 0) {
      return of(null);
    }

    return this.configuratorCacheService.getFeSystemParam<SavingRateModel>('MINIMUM_SAVINGS_RATE', 'JSON')
      .pipe(
        take(1),
        map((savingRates) => this.getRetailPrice(itemData, savingRates, alwaysShowSavingPercentage)),
      );
  }

  /**
   * @returns item category (if category path is provided, it returns the last category from the path)
   * @private
   */
  private getItemCategory(
    categoryOrPath: RetailPriceCategoryModel | RetailPriceCategoryModel[],
  ): RetailPriceCategoryModel | Nil {
    if (isNil(categoryOrPath)) {
      return null;
    }

    if (Array.isArray(categoryOrPath)) {
      return categoryOrPath[categoryOrPath.length - 1];
    }

    return categoryOrPath;
  }

}
