<div
  class="auk-icon transition-base"
  [ngClass]="iconSizeClass">
  @switch (iconType) {
    <!-- MATERIAL ICON -->
    @case ('MATERIAL') {
      <mat-icon>
        {{ source }}
      </mat-icon>
    }
    <!-- ./MATERIAL ICON -->
    <!-- SVG ICON -->
    @case ('SVG') {
      <auk-svg-icon [iconId]="source"></auk-svg-icon>
    }
    <!-- ./SVG ICON -->
  }
</div>
