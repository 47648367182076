import { Injectable } from '@angular/core';
import { LocalStorageService } from '@common/services/storage/local-storage.service';
import { DISABLED_HINTS, DisabledHint } from '../model/disabled-hint';
import { StringUtils } from '@util/util/string.utils';
import { ArrayUtils } from '@util/util/array.utils';
import { UserService } from '@shared/user/service/user.service';
import { map, switchMap } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Observable, of } from 'rxjs';
import isNil from 'lodash-es/isNil';

export const HINTS_DISABLED = 'hints_disabled';

@Injectable({
  providedIn: 'root',
})
export class HintDisablerService extends NgUnsubscribe {

  constructor(
    private localStorageService: LocalStorageService,
    private userService: UserService,
  ) {
    super();
  }

  private getUserId$(): Observable<number> {
    return this.userService.getActualStatistics()
      .pipe(
        map((data) => data.userId ?? null),
      );
  }

  private isOfTypeDisabledHint(hintKey: DisabledHint): hintKey is DisabledHint {
    return DISABLED_HINTS.includes(hintKey);
  }

  private getAllHintsFromStorage$(): Observable<Record<number, DisabledHint[]>> {
    const storageObject = this.localStorageService.getItem<Record<number, DisabledHint[]>>(HINTS_DISABLED);

    return of(storageObject || {});
  }

  private getUserHints$(): Observable<DisabledHint[]> {
    return this.getUserId$()
      .pipe(
        switchMap(userId => this.getAllHintsFromStorage$()
          .pipe(
            map((allHints) => {
              if (isNil(userId)) {
                return [];
              }
              return allHints?.[userId] ?? [];
            }),
          )),
      );
  }

  private setUserHints$(hints: DisabledHint[]): Observable<void> {
    return this.getUserId$()
      .pipe(
        switchMap(userId => this.getAllHintsFromStorage$()
          .pipe(
            map((allHints) => {
              if (isNil(userId)) {
                return;
              }
              if (ArrayUtils.isEmpty(hints) && allHints[userId]) {
                delete allHints[userId];
              } else {
                allHints[userId] = hints;
              }
              if (Object.keys(allHints).length === 0) {
                this.localStorageService.removeItem(HINTS_DISABLED);
              } else {
                this.localStorageService.setItem(HINTS_DISABLED, allHints);
              }
            }),
          )),
      );
  }

  public hasKey$(hintKey: DisabledHint): Observable<boolean> {
    if (StringUtils.isEmpty(hintKey)) {
      return of(false);
    }
    return this.getUserHints$()
      .pipe(
        map(userHints => userHints.includes(hintKey)),
      );
  }

  public removeKey$(hintKey: DisabledHint): Observable<void> {
    if (StringUtils.isEmpty(hintKey) || !this.isOfTypeDisabledHint(hintKey)) {
      return of(void 0);
    }
    return this.getUserHints$()
      .pipe(
        switchMap((hints) => {
          if (ArrayUtils.isNotEmpty(hints) && hints.includes(hintKey)) {
            hints.splice(hints.indexOf(hintKey), 1);
            return this.setUserHints$(hints);
          } else {
            return of(void 0);
          }
        }),
      );
  }

  public addKey$(hintKey: DisabledHint): Observable<void> {
    if (StringUtils.isEmpty(hintKey) || !this.isOfTypeDisabledHint(hintKey)) {
      return of(void 0);
    }
    return this.getUserHints$()
      .pipe(
        switchMap((hints) => {
          if (ArrayUtils.isEmpty(hints)) {
            return this.setUserHints$([hintKey]);
          } else if (!hints.includes(hintKey)) {
            hints.push(hintKey);
            return this.setUserHints$(hints);
          } else {
            return of(void 0);
          }
        }),
      );
  }

}
