// TODO: [PDEV-18307] - fix dependency
// eslint-disable-next-line import/no-restricted-paths
import { TranslationSource } from '@common/translations/model/translation-source';
import { stringToTranslationSource } from './string-to-translation-source';
import isObject from 'lodash-es/isObject';
import isNil from 'lodash-es/isNil';

interface RecursiveTranslationSource {
  [key: string]: RecursiveTranslationSource | TranslationSource;
}

interface RecursiveStringObj {
  [key: string]: string | RecursiveStringObj;
}

/**
 * Replaces every string with {@link TranslationSource} in given object
 * @param stringsObj - source object, where every string will be replaced with {@link TranslationSource}
 */
export const recursiveStringsObjToTranslationSources = (stringsObj: RecursiveStringObj): RecursiveTranslationSource => {
  if (isNil(stringsObj)) {
    return null;
  }

  return Object.entries(stringsObj)
    .reduce((acc, [key, value]: [string, RecursiveStringObj]) => ({
      ...acc,
      [key]: isObject(value)
        ? recursiveStringsObjToTranslationSources(value)
        : stringToTranslationSource(value),
    }), {});
};

