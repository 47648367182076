import { ChangeDetectorRef, Directive, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Nil } from '@util/helper-types/nil';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { takeUntil } from 'rxjs/operators';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { RangeBreakpointConfigModel } from '@common/responsiveness/model/range-breakpoint-config.model';

@Directive({
  selector: '[aukIfIsActiveBreakpoint]',
  standalone: true,
})
export class IfIsActiveBreakpointDirective extends NgUnsubscribe implements OnInit, OnChanges {

  @Input('aukIfIsActiveBreakpoint') public config: RangeBreakpointConfigModel | Nil;

  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly responsivenessService: ResponsivenessService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.responsivenessService.activeBreakpoint$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.evaluateShowOrHide();
        this.changeDetectorRef.markForCheck();
      });
  }

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (changes.config) {
      this.evaluateShowOrHide();
    }
  }

  private evaluateShowOrHide(): void {
    if (this.responsivenessService.isActiveBreakpointInRange(this.config)) {
      this.showTemplate();
    } else {
      this.hideTemplate();
    }
  }

  private showTemplate(): void {
    if (this.viewContainerRef.length) {
      return;
    }

    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }

  private hideTemplate(): void {
    if (!this.viewContainerRef.length) {
      return;
    }

    this.viewContainerRef.clear();
  }

}
