import { Inject, Injectable } from '@angular/core';
import { forkJoin, mergeMap, Observable, of, switchMap } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ActivatedRouteSnapshot, CanActivateFn, mapToCanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '../../user/service/user.service';
import { DomainService } from '@shared/platform/domain.service';
import { AukWindow } from '@shared/model/auk-window.interface';
import { HttpContext } from '@angular/common/http';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';
import { HttpError } from '@shared/rest/model/http-error';
import { ErrorsService } from '@shared/services/errors/errors.service';
import { UrlService } from '@shared/platform/url.service';
import { ToastService } from '@common/toast/service/toast.service';
import { PlatformService } from '@shared/platform/service/platform.service';
import { UserMeService } from '@api/generated/api/UserMe';
import { UserInterestStatisticsDto } from '@api/generated/defs/UserInterestStatisticsDto';
import { DomainCode } from '@shared/platform/domain.model';
import { MyAukroUserDataDto } from '@api/generated/defs/MyAukroUserDataDto';
import { AukTwoButtonDialogComponent } from '@common/two-button-dialog/auk-two-button-dialog.component';
// eslint-disable-next-line import/no-restricted-paths
import { MyDataUtils } from '../../../section/my-aukro/module/my-account/my-data/my-data.utils';
// eslint-disable-next-line import/no-restricted-paths
import { ChangeAddressDialogComponent } from '../../../section/my-aukro/module/my-account/my-data/change-address/change-address-modal/change-address-modal.component';
import { BaseGuardService } from '@shared/routing/base-guard.service';
import { Result } from '@shared/routing/base-guard.model';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';
// eslint-disable-next-line auk-rules/no-mixed-api-files
import { OffersApiService } from '@api/aukro-api/api/offers-api.service';

/**
 * TODO: [PDEV-20803] - rewrite to functional guard
 * @deprecated use {@link allowedToSaleGuard}
 */
@Injectable({
  providedIn: 'root',
})
export class AllowedToSaleGuardService extends BaseGuardService {

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
    router: Router,
    private readonly userService: UserService,
    private readonly offerApiService: OffersApiService,
    private readonly errorsService: ErrorsService,
    private readonly toastService: ToastService,
    private readonly domainService: DomainService,
    private readonly platformService: PlatformService,
    private readonly aukMatDialogService: AukMatDialogService,
    private readonly urlService: UrlService,
    private readonly userMeService: UserMeService,
  ) {
    super(router);
  }

  public canActivateImpl(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Result {
    return this.checkPrerequisitesForExposeOffer(route.queryParams.type === 'edit')
      .pipe(
        catchError((httpError: HttpError): Observable<boolean | UrlTree> => {
          // refresh account blocked banner
          this.userService.getActualStatistics(true).subscribe();

          if (this.errorsService.isBlockSellRestrictionError(httpError)
            || this.errorsService.isBalanceLimitExceededRestrictionError(httpError)) {

            this.toastService.showWarning({ key: 'EXPOSE_FORM_NO_EXPOSE_BALANCE' });
            void this.router.navigate(['/moje-aukro/muj-ucet/aktualni-zustatek']);

          } else if (this.errorsService.isSellerOutsideEuRestrictionError(httpError)) {

            this.toastService.showWarning({ key: 'EXPOSE_FORM_NO_EXPOSE_VERIFY' });
            void this.router.navigate(['/moje-aukro/muj-ucet/overeni'], {
              queryParamsHandling: 'merge',
            });

          } else if (!ErrorsService.isNotFulfilledPrerequisitesError(httpError)) {
            this.toastService.showDanger({ key: 'EXPOSE_FORM_NO_EXPOSE' });
          }

          return of(false);
        }),
        /** fixes click on <a href="/vystavit"> in POPUP_FLOW CE. Instead of blank page redirects user to homepage */
        tap((canActivate) => {
          if (canActivate || this.router.routerState.snapshot.url !== '') {
            return;
          }
          void this.router.navigate(['/']);
        }),
        mergeMap((canActivate: boolean) => !canActivate ?
          // can activate false - no need for checking redirects etc.
          of(false) :
          // can activate true - has permission according backend, go further, resolve redirects
          of(true)
            .pipe(
              mergeMap(() => forkJoin([this.userService.getActualStatistics(), this.userMeService.myData()])),
              mergeMap(([actualStatistics, userData]: [UserInterestStatisticsDto, MyAukroUserDataDto]) =>
                this.getSaleRedirect(actualStatistics, state, userData)),
              map((allowed: boolean) => allowed),
            ),
        ),
      );
  }

  private getSaleRedirect(
    actualStatistics: UserInterestStatisticsDto,
    state: RouterStateSnapshot,
    userData: MyAukroUserDataDto,
  ): Observable<boolean> {
    const domain: DomainCode = this.domainService.domain;
    const registrationDomain: DomainCode = actualStatistics?.registrationDomainCode;
    const accountAddressCountryCode = MyDataUtils.getAccountAddress(userData)?.country?.code;

    // If the user is not on their registration domain, prompt them to be moved to it (while keep path and params)
    if (registrationDomain !== domain) {
      return this.openRouteDialog()
        .pipe(
          switchMap((dialogRef) => dialogRef.afterClosed()),
          take(1),
          mergeMap((redirectConfirmed: boolean) => {
            if (!redirectConfirmed) {
              return of(false);
            }
            return this.urlService.reverseTranslateUrl(state.url)
              .pipe(
                take(1),
                tap((translatedUrl: string) => {
                  const path: string = this.router.createUrlTree([translatedUrl],
                    { queryParamsHandling: 'merge' }).toString();
                  this.window.location.href =
                    this.platformService.getDomainUrl(registrationDomain)
                    + decodeURIComponent(path);
                }),
                map(() => false),
              );
          }),
        );
    }

    // SK user with CZ adress is trying to acess expose, show modal for address change
    if (registrationDomain === 'SK' && accountAddressCountryCode !== 'SK') {
      return this.redirectAfterWrongAddress();
    }

    // Current form allowed
    return of(true);
  }

  private redirectAfterWrongAddress(): Observable<boolean> {
    return this.openWrongAdressDialog()
      .pipe(
        switchMap((dialogRef) => dialogRef.afterClosed()),
        take(1),
        switchMap((redirectConfirmed: boolean) => {
          if (redirectConfirmed) {
            return this.openChangeAddressModal()
              .pipe(
                switchMap((dialogRef) => dialogRef.afterClosed()),
                take(1),
                tap((returnToHome: boolean) => {
                  if (returnToHome) {
                    void this.router.navigate(['/']);
                    return of(false);
                  }
                  return of(true);
                }),
              );
          } else {
            void this.router.navigate(['/stranka/zaregistruj-sa-a-vyhraj']);
            return of(false);
          }
        }),
      );
  }

  private openRouteDialog(): Observable<MatDialogRef<AukTwoButtonDialogComponent>> {
    return this.aukMatDialogService.openSimple$(AukTwoButtonDialogComponent, {
      maxWidth: '400px',
      data: {
        title: { key: 'EXPOSE_REDIRECT_TITLE' },
        subtitle: { key: 'EXPOSE_REDIRECT_SUBTITLE' },
        button1: { key: 'EXPOSE_REDIRECT_BUTTON_REDIRECT' },
        button2: { key: 'EXPOSE_REDIRECT_BUTTON_STAY' },
      },
      panelClass: ['user-modal-dialog'],
    });
  }

  private openWrongAdressDialog(): Observable<MatDialogRef<AukTwoButtonDialogComponent, any>> {
    return this.aukMatDialogService.openSimple$(AukTwoButtonDialogComponent, {
      maxWidth: '400px',
      data: {
        title: { key: 'EXPOSE_WRONG_ADRESS_MODAL_TITLE' },
        subtitle: { key: 'EXPOSE_WRONG_ADRESS_MODAL_SUBTITLE' },
        button1: { key: 'EXPOSE_WRONG_ADRESS_MODAL_BUTTON_REDIRECT' },
        button2: { key: 'EXPOSE_WRONG_ADRESS_MODAL_BUTTON_STAY' },
      },
      panelClass: ['user-modal-dialog'],
    });
  }

  private openChangeAddressModal(): Observable<MatDialogRef<ChangeAddressDialogComponent, any>> {
    return this.aukMatDialogService.openSimple$(ChangeAddressDialogComponent, {
      maxWidth: '400px',
      panelClass: ['user-modal-dialog'],
    });
  }

  private checkPrerequisitesForExposeOffer(editParam: boolean): Observable<boolean> {
    return this.offerApiService.checkPrerequisitesForCreateNewOffer$(
      { edit: editParam },
      {},
      new HttpContext().set(USER_ACTION_TYPE, 'EXPOSE_OFFER'),
    )
      .pipe(
        map(() => true),
      );
  }

}

export const allowedToSaleGuard: CanActivateFn = mapToCanActivate([AllowedToSaleGuardService])[0];
