import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { environment } from '@environment';
import { RequestWithHeadersCallback, TwoFactorVerificationComponent } from '@shared/two-factor-verification/two-factor-verification.component';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Observable, Subject, throwError } from 'rxjs';
import { tokenCacheBuster$ } from '@shared/user/constant/cache-busters';
import isNil from 'lodash-es/isNil';
import { TokenMonitoringService } from '@shared/services/token-monitoring/token-monitoring.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PushNotificationsService } from '@shared/services/push-notifications/push-notifications.service';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { ErrorsService } from '@shared/services/errors/errors.service';
import { ShareDataService } from '@shared/services/share-data/share-data.service';
import { HttpContext } from '@angular/common/http';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';
import { HttpError } from '@shared/rest/model/http-error';
import { RECAPTCHA_COMPONENT } from '@shared/captcha/token/recaptcha-component.token';
import { AccountsApiService } from '@api/aukro-api/api/accounts-api.service';
import { PersonDto } from '@api/aukro-api/model/person-dto';
import { JWTInfo } from '@api/aukro-api/model/jwt-info';
import { LoginVM } from '@api/aukro-api/model/login-vm';
import { PasswordRecoveryOptionDto } from '@api/aukro-api/model/password-recovery-option-dto';

interface DoAuthenticateConfig {
  registrationPersonDto?: PersonDto;
  onSuccessNavigateUrl?: string;
}

@Component({
  selector: 'auk-authenticate-wrapper',
  templateUrl: './authenticate-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthenticateWrapperComponent {

  protected readonly CAPTCHA_SITE_KEY: string = environment.CAPTCHA_SITE_KEY;

  @ViewChild(RecaptchaComponent, { static: false })
  public recaptchaComponent: RecaptchaComponent;

  @ViewChild(TwoFactorVerificationComponent, { static: false })
  public twoFactorVerificationComponent: TwoFactorVerificationComponent<JWTInfo, LoginVM>;

  private tfwGoBackClicked: Subject<void> = new Subject<void>();

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly tokenMonitoringService: TokenMonitoringService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly pushNotificationsService: PushNotificationsService,
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
    private readonly accountsApiService: AccountsApiService,
    private readonly errorService: ErrorsService,
    private readonly shareDataService: ShareDataService,
  ) {
  }

  public authenticateCallback: RequestWithHeadersCallback<JWTInfo, LoginVM> = (params: LoginVM, headers: { [key: string]: string }) => {
    if (isNil(params)) {
      return;
    }

    const httpContext: HttpContext = new HttpContext()
      .set(USER_ACTION_TYPE, 'LOGIN')
      .set(RECAPTCHA_COMPONENT, this.recaptchaComponent);

    return this.authenticationService.authenticate(params, headers, httpContext);
  };

  public onTfwGoBackClicked(): void {
    this.tfwGoBackClicked.next();
  }

  public doAuthenticateWithoutCatchError(loginVM: LoginVM, config?: DoAuthenticateConfig): Observable<any> {
    this.tokenMonitoringService.checkToken();

    // Call authentication service
    // Possible TFV error will be handled by TFV page
    return this.twoFactorVerificationComponent.sendTfvWrappedRequest(loginVM)
      .pipe(
        tap(() => {
          tokenCacheBuster$.next();
          this.pushNotificationsService.assignPlayerIdToUser();
          this.tokenMonitoringService.setToken();
          void this.googleAnalyticsTrackingService.trackLoginFormParams();

          const { returnUrl } = this.route.snapshot.queryParams;
          if (returnUrl != null) {
            void this.router.navigateByUrl(returnUrl);
          } else if (config?.onSuccessNavigateUrl) {
            void this.router.navigate([config.onSuccessNavigateUrl]);
          }
        }),
        takeUntil(this.tfwGoBackClicked),
      );
  }

  public doAuthenticateViaFbWithoutCatchError(loginVM: LoginVM, config?: DoAuthenticateConfig): Observable<any> {
    this.tokenMonitoringService.checkToken();

    // Call authentication service
    // Possible TFV error will be handled by TFV page
    return this.twoFactorVerificationComponent.sendTfvWrappedRequest(loginVM)
      .pipe(
        tap(() => {
          tokenCacheBuster$.next();
          this.pushNotificationsService.assignPlayerIdToUser();
          this.tokenMonitoringService.setToken();
          void this.googleAnalyticsTrackingService.trackLoginFormParams();
        }),
        takeUntil(this.tfwGoBackClicked),
      );
  }

  public doAuthenticate(loginVM: LoginVM, config?: DoAuthenticateConfig): Observable<any> {
    return this.doAuthenticateWithoutCatchError(loginVM, config)
      .pipe(
        catchError((httpError: HttpError) => {
          // TODO(PDEV-15882) - redirect implementation for task modals
          if (this.errorService.isPasswordChangeRequired(httpError)) {
            return this.accountsApiService.getPasswordRecoveryInfo$({
              loginOrEmail: loginVM.username,
            })
              .pipe(
                tap((resp: PasswordRecoveryOptionDto) => this.shareDataService.setPasswordRecoveryInfo(resp)),
                mergeMap(() => {
                  void this.router.navigate(['/zapomenute-heslo/zpusob-overeni']);
                  return throwError(() => httpError);
                }),
              );
          }

          console.error(httpError);

          throw httpError;
        }),
        takeUntil(this.tfwGoBackClicked),
      );
  }

}
