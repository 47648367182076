import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Translate2Module } from '@common/translations/translate2.module';

import { WINDOW_OBJECT } from '@util/const/window-object';
import { VersionService } from '@shared/version/service/version.service';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { filter, first, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

interface NewVersionInfo {
  panelBgColor: string;
  panelTextColor: string;
}

@Component({
  selector: 'auk-new-version-info-panel',
  standalone: true,
  templateUrl: './new-version-info-panel.component.html',
  styleUrls: ['./new-version-info-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    Translate2Module,
    AsyncPipe,
    InnerHtmlDirective,
  ],
})
export class NewVersionInfoPanelComponent {

  protected readonly newVersionInfo$: Observable<NewVersionInfo>;

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: Window,
    protected readonly versionService: VersionService,
    protected readonly configuratorCacheService: ConfiguratorCacheService,
  ) {
    this.newVersionInfo$ = this.getNewVersionInfo$();
  }

  protected onUpdateToNewVersionClick(): void {
    this.window.location.reload();
  }

  protected get isNativeApp(): boolean {
    return PlatformCommonService.isNativeApp;
  }

  private getNewVersionInfo$(): Observable<NewVersionInfo> {
    return this.versionService.isNewVersionAvailable$
      .pipe(
        filter((isAvailable) => isAvailable),
        first(),
        mergeMap(() => this.configuratorCacheService.getFeSystemParam<NewVersionInfo>('NEW_VERSION_INFO_PANEL_CONFIG', 'JSON')),
        first(),
      );
  }

}
