import { NgModule } from '@angular/core';
import { UserTaskLoginModule } from './login/user-task-login.module';
import { UserTaskPersonalDataModule } from './personal-data/user-task-personal-data.module';
import { UserTaskVerifiedEmailModule } from './verified-email/user-task-verified-email.module';
import { UserTaskVerifiedPhoneModule } from './verified-phone/user-task-verified-phone.module';
import { UserTaskVerifiedBankAccountModule } from './verified-bank-account/user-task-verified-bank-account.module';

@NgModule({
  imports: [
    UserTaskLoginModule,
    UserTaskPersonalDataModule,
    UserTaskVerifiedEmailModule,
    UserTaskVerifiedPhoneModule,
    UserTaskVerifiedBankAccountModule,
  ],
})
// TODO(PDEV-15442) - consider not importing this entire module into the app module and importing
//  the specific task modules only where they might be needed.
export class UserTaskModule {}
