import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { captureException, Scope, withScope } from '@sentry/browser';
import isNil from 'lodash-es/isNil';
// eslint-disable-next-line import/no-restricted-paths
import { LocaleConstants } from '@shared/platform/locale.constants';
// eslint-disable-next-line import/no-restricted-paths
import { TranslationCacheService } from '@shared/services/translation-cache/translation-cache.service';

/**
 * Handling missing translation in specific key - return empty string into target element
 */
export class MissingTranslationsHandler implements MissingTranslationHandler {

  constructor(
    private readonly translationCacheService: TranslationCacheService,
  ) {
  }

  public handle(params: MissingTranslationHandlerParams): string {
    let defaultValue: string = '';
    if (!isNil(params.interpolateParams)) {
      defaultValue = params.interpolateParams['defaultValue'] as string;
    }
    if (params.key.startsWith(LocaleConstants.ROUTES_PREFFIX)) {
      defaultValue = params.key;
    }

    if (!defaultValue && this.translationCacheService.translationsLoaded && !this.translationCacheService.hasTranslation(params.key)) {
      withScope((scope: Scope) => {
        const title = 'Configurator: Missing translation - ' + params.key;
        scope.setExtra('extra', {
          countOfTranslations: this.translationCacheService.translationsCount(),
          translations: this.translationCacheService.translationsContent(),
        });
        scope.setTag('group', 'configurator-translation-missing');
        scope.setFingerprint([title]);
        captureException(new Error(title));
      });
    }
    return defaultValue ? defaultValue : '';
  }

}
