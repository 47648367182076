import { Injectable } from '@angular/core';
import { FavouriteOffersTimeRange } from '@shared/model/favourite-offers-time-range';
import { DateUtils } from '@util/util/date.utils';
import { UrlInfoModel } from '@common/routing/model/url-info.model';

@Injectable({
  providedIn: 'root',
})
export class ListingRoutingService {

  public getUrlInfoForFavouriteSearch(
    /**
     * Favourite search hash
     */
    searchHash: string,
    /**
     * Whether it should include filters for items which were exposed yesterday
     */
    withYesterdayFilter: boolean = false,
  ): UrlInfoModel<FavouriteOffersTimeRange> {

    return {
      routerLink: ['/oblibene-vyhledavani', searchHash],
      queryParams: withYesterdayFilter
        ? this.getListingItemsExposedYesterdayFilter()
        : null,
    };
  }

  private getListingItemsExposedYesterdayFilter(): FavouriteOffersTimeRange {
    return {
      originalExposeAfter: DateUtils.yesterday.from.format(),
      originalExposeBefore: DateUtils.yesterday.to.format(),
    };
  }

}
