export const PASSWORD_PATTERN: RegExp = /^[^ ][a-zA-Z0-9-!$%^&*@#()_+|~=`{}\\[\]:";'<>?,./]*$/;
export const EMAIL_PATTERN: RegExp = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
export const REGISTRATION_LOGIN_PATTERN: RegExp = /^(?![wW]{3})[a-zA-Z0-9_-]+(?![-_][cC][zZ]|[-_][cC][oO][mM])$/;
export const EMAIL_OR_LOGIN_PATTERN: RegExp =
  /^\s*(([a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})|(?![wW]{3})[a-zA-Z0-9-_:]+(?![-_][cC][zZ]|[-_][cC][oO][mM]))\s*$/;
export const ONLY_NUMBER_PATTERN: string = '([0-9])+$';
export const HOUSE_NUMBER_PATTERN: RegExp = /^[a-zA-ZÁáÄäČčDdĎďÉéĚěÆæÍíŇňÓóÖöQqŘřŠšŤťÚúŮůÜüÝýŽžĽľŔŕôĺĹ0-9/.´]{1,10}$/;
export const ITEM_NUMBERS_PATTERN: RegExp = /^(\d{1,})(,\d{1,})*$/;
export const PHONE_PATTERN: RegExp = /^\+?[\d ]+$/;
export const CITY_PATTERN = /^([a-zA-ZÀ-ž\s'\-\u2013])+([-\d])*$/;
export const NAME_PATTERN = /^[a-zA-ZÀ-ž'´,.\-_ ]+$/;
export const STREET_PATTERN = /^[a-zA-Z0-9ÁáÄäČčDdĎďÉéĚěÆæÍíŇňÓóÖöQqŘřŠšŤťÚúŮůÜüÝýŽžĽľŔŕôĺĹ\s/'.\-\u2013]*$/;
export const PICKUP_POINT_STREET_PATTERN = /^[a-zA-Z0-9ÁáÄäČčDdĎďÉéĚěÆæÍíŇňÓóÖöQqŘřŠšŤťÚúŮůÜüÝýŽžĽľŔŕôĺĹ\s/'.\-\u2013]*\s\d+.*$/;
export const CZ_VAT_PATTERN = /^((CZ\d{8,10}))$/;
export const SK_VAT_PATTERN = /^((\d{8,10}))$/;
export const VAT_FOREIGN_PATTERN = /^(([A-Z\d-]{5,20}))$/;
export const ZIP_CODE_TEXT_MASK_CZ_LIKE: (string | RegExp)[] = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/]; // CZ like zip code mask (CZ, SK)
export const ZIP_CODE_TEXT_MASK_PL_LIKE: (string | RegExp)[] = [/\d/, /\d/, '-', /\d/, /\d/, /\d/]; // PL like zip code mask (PL)
/**
 *
 * @param count
 */
export function ZIP_CODE_TEXT_MASK_XD(count: number): (string | RegExp)[] { // n digit zip code mask (4: AT, HU, 5: DE)
  return Array(count).fill(/\d/) as RegExp[];
}
export const ZIP_FOREIGN_PATTERN = /^[a-zA-Z\d ./-]*$/;
export const BANK_ACCOUNT_PATTERN: RegExp = /^([0-9]{1,6}-|)[0-9]{1,10}\/[0-9]{4}$/;
export const IBAN_BANK_ACCOUNT_PATTERN: RegExp = /^([A-Z]{2}[0-9]{2}[0-9A-Z]{2,30}|([0-9]{1,6}-|)[0-9]{1,10}\/[0-9]{4})$/;
export const IBAN_NUMBER_PATTERN: RegExp = /^([A-Z]{2}[0-9]{2}[0-9A-Z]{2,30})$/;
export const CZECH_IBAN_NUMBER_PATTERN: RegExp = /^(CZ[0-9]{2}[0-9A-Z]{2,30})$/;
export const WEBHOOK_URL_PATTERN: RegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
export const WHOLE_NUMBER_PATTERN: RegExp = /^[0-9]*$/;
export const NO_WHITESPACE_PATTERN: RegExp = /^\S*$/;
