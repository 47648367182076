import { parse } from 'url';
import isString from 'lodash-es/isString';
import isEmpty from 'lodash-es/isEmpty';
import { StringUtils } from '@util/util/string.utils';
import URI from 'urijs';
import { ParamMap, Params } from '@angular/router';
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';

export class UrlUtils {

  /**
   * @param url source url
   * @example
   * // returns '/path?a=b&b=c#d'
   * getRelativeUrl('https://fe.aukro.cz/path?a=b&b=c#d')
   * @example
   * // returns '/path?a=b&b=c#d'
   * getRelativeUrl('/path?a=b&b=c#d')
   * @returns the source url without the domain part
   */
  public static getRelativeUrl(url: string): string {
    if (!isString(url)) {
      return '';
    }

    return url.replace(/^.*\/\/[^/]+/, '');
  }

  /**
   * @static
   * @param url source url
   * @returns pathname of the url
   * @example
   * // returns '/path'
   * getPathnameFromUrl('https://fe.aukro.cz/path?a=b&b=c#d')
   * @example
   * // returns '/path/foo'
   * getPathnameFromUrl('/path/foo?a=b&b=c#d')
   * @memberof UrlUtils
   */
  public static getPathnameFromUrl(url: string): string {
    return parse(url).pathname;
  }

  /**
   * Keep only such query parameters in the url which are not supposed to be excluded.
   * @static
   * @param url            original URL
   * @param excludedParams list of query parameters (the keys) to be excluded from the original URL
   * @returns The URL including the path and query parameters which should be kept in the URL.
   */
  public static excludeQueryParams(url: string, excludedParams: string[]): string {
    if (StringUtils.isEmpty(url) || isEmpty(excludedParams)) {
      // URL is not set or there are no params to be excluded - return the provided URL straightaway
      return url;
    }

    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    return URI(url)
      .removeQuery(excludedParams)
      .toString();
  }

  /**
   * Return query parameters from url.
   * @static
   * @param url            original URL
   * @returns query parameters included in url.
   * @example
   * // returns '{"freeShipping":"true", "attr_48":"val_3"}'
   * getPathnameFromUrl('/path/foo?freeShipping=true&attr_48=val_3')
   * @memberof UrlUtils
   */
  public static getQueryParamsFromUrl(url: string): Params {
    if (StringUtils.isEmpty(url) || isNil(url)) {
      return null;
    }

    if (!url.includes('?')) {
      return {};
    }

    const paramArr = url.slice(url.indexOf('?') + 1).split('&');

    return paramArr.reduce((acc, param) => {
      const [key, val] = param.split('=');
      return { ...acc, [key]: val };
    }, {});
  }

  /**
   * Return flag whether query param map contains given key regardless of the value. Matching is case-insensitive.<br>
   * Return null, if the provided key is not specified - it's not possible to determine whether a not-specified object
   * is contained in the query param map.
   * @static
   */
  public static hasQueryParamKey(queryParamMap: ParamMap, key: string): boolean | Nil {
    if (isEmpty(key)) {
      return null;
    }

    if (isEmpty(queryParamMap?.keys)) {
      return false;
    }

    const queryParamKeysLowerCase: string[] = queryParamMap.keys.map(queryParam => queryParam.toLowerCase());
    const keyLowerCase: string = key.toLowerCase();

    return queryParamKeysLowerCase.some((queryParamLowerCase) => queryParamLowerCase === keyLowerCase);
  }

  /**
   * Return flag whether query param map contains given key with given value. Matching is case-insensitive.<br>
   * Return null, if the provided key or value is not specified - it's not possible to determine whether a not-specified object
   * is contained in the query param map.
   * @static
   */
  public static hasQueryParamKeyValue(queryParamMap: ParamMap, key: string, value: string): boolean | Nil {
    if (isEmpty(key) || isEmpty(value)) {
      return null;
    }

    if (isEmpty(queryParamMap?.keys)) {
      return false;
    }

    const keyLowerCase: string = key.toLowerCase();
    const valueLowerCase: string = value.toLowerCase();

    return queryParamMap.keys.some((queryParamKey) => {
      const queryParamKeyLowerCase: string = queryParamKey.toLowerCase();
      if (queryParamKeyLowerCase === keyLowerCase) {
        const queryParamValueLowerCase: string = queryParamMap.get(queryParamKey)?.toLowerCase();
        return queryParamValueLowerCase === valueLowerCase;
      }
      return false;
    });
  }

}
