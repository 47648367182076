import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogWrapperModule } from '@common/dialog-wrapper/dialog-wrapper.module';
import { SpinnerModule } from '@shared/legacy/component/spinner/spinner.module';
import { RouterLink } from '@angular/router';
import { Translate2Module } from '@common/translations/translate2.module';
import { UserTaskBaseModule } from '@shared/user-task/base/user-task-base.module';

import { CurrencyPipeModule } from '@shared/currency/pipe/currency-pipe.module';
import { UserTaskVerifiedBankAccountStepNoAccountVerifiedComponent } from './component/user-task-verified-bank-account-step-no-account-verified.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { PreventDefaultLinkDirective } from '@common/routing/directive/prevent-default-link.directive';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    UserTaskBaseModule,
    DialogWrapperModule,
    Translate2Module,

    ButtonComponent,
    IconComponent,
    SpinnerModule,
    PreventDefaultLinkDirective,
    RouterLink,
    CurrencyPipeModule,
    InnerHtmlDirective,
  ],
  declarations: [
    UserTaskVerifiedBankAccountStepNoAccountVerifiedComponent,
  ],
})
export class UserTaskVerifiedBankAccountModule {}
