import { Injectable, Inject, Renderer2, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class CustomOverlayContainer extends OverlayContainer implements OnDestroy {

  protected destroy$ = new Subject<void>();
  protected isMdAndLower: boolean = false;
  protected isDynamic: boolean = false;

  constructor(
    _platform: Platform,
    @Inject(DOCUMENT) _document: Document,
    private readonly renderer: Renderer2,
    private readonly responsivenessService: ResponsivenessService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super(_document, _platform);

    this.responsivenessService.isMdAndLower$
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((isMdAndLower) => {
        this.isMdAndLower = isMdAndLower;
        this.updateContainerZIndex();
        this.changeDetectorRef.markForCheck();
      });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public updateZIndex(isDynamic: boolean): void {
    this.isDynamic = isDynamic;
    this.updateContainerZIndex();
  }

  protected updateContainerZIndex(): void {
    if (!this._containerElement) {
      return;
    }

    const zIndex = this.isDynamic
      ? this.isMdAndLower
        ? '90'
        : '110'
      : '2000';

    this.renderer.setStyle(this._containerElement, 'z-index', zIndex);
  }

  protected override _createContainer(): void {
    super._createContainer();
    this.updateContainerZIndex();
  }

}
