import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserTaskBaseModule } from '../base/user-task-base.module';
import { DialogWrapperModule } from '@common/dialog-wrapper/dialog-wrapper.module';
import { Translate2Module } from '@common/translations/translate2.module';

import {
  UserTaskVerifiedEmailStepNoEmailVerifiedComponent,
} from './component/user-task-verified-email-step-no-email-verified.component';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    UserTaskBaseModule,
    DialogWrapperModule,
    Translate2Module,

    ButtonComponent,
    InnerHtmlDirective,
  ],
  declarations: [
    UserTaskVerifiedEmailStepNoEmailVerifiedComponent,
  ],
})
export class UserTaskVerifiedEmailModule {}
