import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import isNil from 'lodash-es/isNil';
import { TwoFactorVerificationService } from '@shared/two-factor-verification/two-factor-verification.service';
import { ToastService } from '@common/toast/service/toast.service';
import { BankAccountVerificationService } from '@shared/bank-account/module/user-bank-account/service/bank-account-verification.service';
import { ArrayUtils } from '@util/util/array.utils';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { HttpError } from './model/http-error';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationFailedHandlerService extends NgUnsubscribe {

  constructor(
    private readonly router: Router,
    private readonly toastService: ToastService,
    private readonly bankAccountVerificationService: BankAccountVerificationService,
    private readonly twoFactorVerificationService: TwoFactorVerificationService,
  ) {
    super();
  }

  public handleAuthorizationFailed(err: HttpError): void {
    const errorMessageCodes: string[] = this.extractErrorMessageCodes(err);
    if (errorMessageCodes.includes('validation.make.bid.blacklisted')
      || errorMessageCodes.includes('BLOCKED_BY_SELLER')) {
      this.toastService.showDanger({ key: 'ALERT_BID_STATUS_BLOCKED_BY_SELLER' });

    } else if (errorMessageCodes.includes('validation.make.bid.not.verified')
      || errorMessageCodes.includes('USER_NOT_VERIFIED_FOR_BUY')) {
      this.toastService.showWarning(
        { key: 'ALERT_BID_STATUS_USER_NOT_VERIFIED_FOR_BUY' },
        {
          timeout: null,
          closeOnUrlChange: true,
          actions: this.bankAccountVerificationService.getUserNotVerifiedForBuyToastAction('authorization-failed-handler-service'),
        },
      );

    } else if (errorMessageCodes.includes('validation.make.bid.allowed.to.buy')
      || errorMessageCodes.includes('USER_NOT_ALLOWED_TO_BUY')) {
      this.toastService.showDanger({ key: 'ALERT_BID_STATUS_NOT_ALLOWED_TO_BUY' });

    } else if (errorMessageCodes.includes('validation.make.bid.unverified.company')
      || errorMessageCodes.includes('NOT_VERIFIED_COMPANY')) {
      void this.router.navigate(['/moje-aukro/muj-ucet/moje-udaje']);

    } else if (errorMessageCodes.includes('BID_ON_ITEM')) {
      this.twoFactorVerificationService.openTfvEnforcedDialog({
        context: 'BID',
        enforced: false,
      });
    }
  }

  private extractErrorMessageCodes(err: HttpError): string[] {
    if (!ArrayUtils.isEmpty(err.body.errors)) {
      return err.body.errors.flatMap((error) => {
        if (this.isDefaultMessageSourceResolvable(error)) {
          const defaultMessageSourceResolvable = (error);
          return !isNil(defaultMessageSourceResolvable.codes) ? defaultMessageSourceResolvable.codes : [];
        }
        return [];
      });
    }
    return [];
  }

  private isDefaultMessageSourceResolvable(object: unknown): boolean {
    return (Object.prototype.hasOwnProperty.call(object, 'codes')
      || Object.prototype.hasOwnProperty.call(object, 'arguments')
      && Object.prototype.hasOwnProperty.call(object, 'defaultMessage')) as boolean;
  }

}
