import { Injectable } from '@angular/core';
import { ItemSearchFilter } from '@api/generated/defs/ItemSearchFilter';
import { TypeSearchType } from '@shared/app-header/model/type-search.type';
import isNil from 'lodash-es/isNil';
import { AppHeaderSearchButtonType } from '@shared/app-header/model/app-header-search-button.type';
import { MeasurementAppHeaderShow } from '@shared/services/personalization/model/measurement-search-show.model';
import { take, takeUntil } from 'rxjs/operators';
import { PersonalizationMeasurementService } from '@shared/services/personalization/personalization-measurement.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ItemSearchFilterExtended } from '../../../../typings/original/internal';

@Injectable()
export class AppHeaderMeasurementServiceService extends NgUnsubscribe {

  private readonly HEADER_SEARCH__MEASUREMENT_OBJECT_CODE: string = 'HS_RESULT';

  constructor(private readonly personalizationMeasurementService: PersonalizationMeasurementService) {
    super();
  }

  private resolveSearchTypeForItemCategory(itemSearchFilter: ItemSearchFilterExtended): TypeSearchType {
    if (!isNil(itemSearchFilter.categoryId)) {
      return 'category';
    }
    if (itemSearchFilter.finished) {
      return 'ended_item';
    }

    if (itemSearchFilter.ignoreSubbrand) {
      return 'all_categories';
    }

    return 'within_subbrand';

  }

  public clickOnSearchItemCategory(itemSearchFilter: ItemSearchFilter, hitSearchButtonType: AppHeaderSearchButtonType): void {
    if (isNil(itemSearchFilter)) {
      return;
    }

    this.clickOnSearch({
      searchTerm: itemSearchFilter.text,
      searchDescription: itemSearchFilter.searchAll,
      searchType: this.resolveSearchTypeForItemCategory(itemSearchFilter),
      hitSearchButton: hitSearchButtonType,
    });
  }

  public clickOnSearchUser(searchTerm: string): void {
    this.clickOnSearch({
      searchTerm,
      searchDescription: false,
      searchType: 'user',
      hitSearchButton: 'magnifier',
    });
  }

  private clickOnSearch(data: MeasurementAppHeaderShow): void {
    if (isNil(data)) {
      return;
    }

    this.personalizationMeasurementService.showMeasurement$({
      measurementObjectCode: this.HEADER_SEARCH__MEASUREMENT_OBJECT_CODE,
      searchTerm: data.searchTerm,
      properties: {
        searchType: data.searchType,
        hitSearchButton: data.hitSearchButton,
        searchDescription: data.searchDescription,
      },
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

}
