@if (mode === 'SET_PASSWORD') {
  <div class="tw-w-full"
    [aukInnerHtml]="setPasswordInfoText"
  ></div>
  <auk-button
    class="tw-w-full tw-mt-2"
    colorCombination="PRIMARY"
    [chin]="true"
    (buttonClick)="onSetPasswordClick()"
    >
    {{ setPasswordButtonText | translateSource }}
  </auk-button>
  <auk-button
    class="tw-w-full tw-mt-2"
    colorCombination="SECONDARY"
    (buttonClick)="onCloseClick()"
    >
    {{ notNowButtonText | translateSource }}
  </auk-button>
} @else {
  <auk-button
    class="tw-w-full"
    colorCombination="PRIMARY"
    [chin]="true"
    (buttonClick)="onCloseClick()"
    >
    {{ closeButtonText | translateSource }}
  </auk-button>
}

