import { UserTaskPersonalDataStepResolutionHandlerService } from './user-task-personal-data-step-resolution-handler.service';
import { UserTaskStepResolutionUnionModel } from '../../base/model/user-task-step-resolution-union.model';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserTaskPersonalDataStepUserBasicDataResolutionHandlerService extends
  UserTaskPersonalDataStepResolutionHandlerService<'USER_BASIC_DATA'> {

  public handle(
    resolution: UserTaskStepResolutionUnionModel<'PERSONAL_DATA', 'USER_BASIC_DATA'>,
    taskPayload: UserTaskPayloadModel<'PERSONAL_DATA'>,
  ): UserTaskStepUnionModel<'PERSONAL_DATA'> {

    if (resolution.type === 'RESOLUTION_CLOSE') {
      throw new Error('User declined to fill personal data');
    }

    if (resolution.type === 'RESOLUTION_PHONE_VERIFIED_NO_EMAIL_VERIFIED') {
      return {
        type: 'PHONE_VERIFIED_NO_EMAIL_VERIFIED',
        payload: {
          ...taskPayload?.stepsText?.PHONE_VERIFIED_NO_EMAIL_VERIFIED,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_NO_PHONE_VERIFIED_NO_EMAIL_VERIFIED') {
      return {
        type: 'NO_PHONE_VERIFIED_NO_EMAIL_VERIFIED',
        payload: {
          ...taskPayload?.stepsText?.NO_PHONE_VERIFIED_NO_EMAIL_VERIFIED,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_PHONE_VERIFIED_EMAIL_VERIFIED') {
      return {
        type: 'PHONE_VERIFIED_EMAIL_VERIFIED',
        payload: {
          ...taskPayload?.stepsText?.PHONE_VERIFIED_EMAIL_VERIFIED,
        },
      };
    }

    if (resolution.type === 'RESOLUTION_NO_PHONE_VERIFIED_EMAIL_VERIFIED') {
      return {
        type: 'NO_PHONE_VERIFIED_EMAIL_VERIFIED',
        payload: {
          ...taskPayload?.stepsText?.NO_PHONE_VERIFIED_EMAIL_VERIFIED,
        },
      };
    }

  }

}
