import { DatePipe, I18nPluralPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountTypePipe } from './account-type.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { CharactersLeftPipe } from './characters-left.pipe';
import { CountPluralizePipe } from './count-pluralize.pipe';
import { HumanizedTimePipe } from './humanized-time.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import { SortObjectPipe } from './sort-objects.pipe';
import { UnitPluralizeModule } from '@common/ui-kit/common/unit-pluralize/unit-pluralize.module';
import { CurrencyPipeModule } from '@shared/currency/pipe/currency-pipe.module';
import { MediumDateTimePipe } from '@common/date-time/pipe/medium-date-time.pipe';
import { SafeUrl } from '@common/routing/pipe/safe-url.pipe';
import { OfferUrlPipe } from '@shared/pipes/offer-url/offer-url.pipe';

/**
 * @deprecated
 * Do not use, if you need to use some pipe from this module, create module only for that pipe and use that
 * (don't forget to remove the pipe from declarations in this module, and put the module into exports only there
 */
@NgModule({
  imports: [
    UnitPluralizeModule,
    CurrencyPipeModule,
    OfferUrlPipe,
    MediumDateTimePipe,
    SafeUrl,
    RelativeTimePipe,
  ],
  providers: [
    I18nPluralPipe,
    CharactersLeftPipe,
    DatePipe,
    CapitalizePipe,
  ],
  declarations: [
    CapitalizePipe,
    CountPluralizePipe,
    SortObjectPipe,
    CharactersLeftPipe,
    AccountTypePipe,
    HumanizedTimePipe,
  ],
  exports: [
    CapitalizePipe,
    CountPluralizePipe,
    CurrencyPipeModule,
    SafeUrl,
    SortObjectPipe,
    UnitPluralizeModule,
    CharactersLeftPipe,
    RelativeTimePipe,
    AccountTypePipe,
    HumanizedTimePipe,
    OfferUrlPipe,
    MediumDateTimePipe,
    SafeUrl,
  ],
})
export class CommonPipesModule {
}
