import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AukValidationMessageComponent } from '@common/auk-forms/auk-validation-message/component/auk-validation-message/auk-validation-message.component';

@NgModule({
  imports: [
    AukValidationMessageComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    AukValidationMessageComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class AukFormsModule {
}
