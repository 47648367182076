<mat-dialog-content class="mat-typography">
  <div class="background"></div>
  <h2>
    {{ 'DISCLAIMER_COMPONENT_HTML_1' | translate }}
  </h2>
  <div class="checkbox-section">
    <input type="checkbox" id="disclaimer-checkbox" [(ngModel)]="isRememberActive">
    <label for="disclaimer-checkbox"></label>
    <p class="label">{{ 'DISCLAIMER_COMPONENT_HTML_2' | translate }}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="buttons">
  <button class="allow" [disabled]="areButtonsDisabled" (click)="onAllow()">{{ 'DISCLAIMER_COMPONENT_HTML_3' | translate }}</button>
  <button class="deny" [disabled]="areButtonsDisabled" (click)="onDeny()">{{ 'DISCLAIMER_COMPONENT_HTML_4' | translate }}</button>
</mat-dialog-actions>
