import moment, { Moment } from 'moment-mini-ts';
import { StringDate } from '@util/helper-types/string-date';

export interface TimeToEnd {
  remainingTime: 'days' | 'hours' | 'minutes';
  label: string;
  past: boolean;
}

/**
 *
 * @param endingTime
 */
export function timeToEnd(endingTime: StringDate | Moment): TimeToEnd {
  const endMoment = moment(endingTime);
  const now = moment();
  let key: 'days' | 'hours' | 'minutes';
  let value: number;

  value = endMoment.diff(now, 'days');
  key = 'days';

  if (value === 0) {
    value = endMoment.diff(now, 'hours');
    key = 'hours';

    if (value === 0) {
      value = endMoment.diff(now, 'minutes');
      key = 'minutes';
    }
  }

  return {
    remainingTime: key,
    label: getFullTimeToEnd(value, key),
    past: value < 0,
  };
}

/**
 *
 * @param value
 * @param key
 */
function getFullTimeToEnd(value: number, key: 'days' | 'hours' | 'minutes'): string {
  let keys: Record<string, string>;
  if (value === 0 && key === 'minutes') {
    return 'méně než minuta';
  }

  switch (value) {
    case 1:
      keys = {
        days: 'den',
        hours: 'hodina',
        minutes: 'minuta',
      };
      break;
    case 2:
    case 3:
    case 4:
      keys = {
        days: 'dny',
        hours: 'hodiny',
        minutes: 'minuty',
      };
      break;
    default:
      keys = {
        days: 'dní',
        hours: 'hodin',
        minutes: 'minut',
      };
      break;
  }

  // get past time
  if (value < 0) {
    switch (value) {
      case -1: {
        keys = {
          days: 'dnem',
          hours: 'hodinou',
          minutes: 'minutou',
        };
        break;
      }
      default:
        keys = {
          days: 'dny',
          hours: 'hodinami',
          minutes: 'minutami',
        };
    }
    value = value * (-1);
  }

  return `${ value } ${ keys[key] }`;
}
