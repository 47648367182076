import { BehaviorSubject, combineLatest, Observable, of, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { distinctUntilChangedDeep } from '@util/rxjs-operators/distinct-until-changed-deep';
import { Injectable } from '@angular/core';
import { UserFavouritesPreviewDto } from '@api/aukro-api/model/user-favourites-preview-dto';
import { UserFavouriteItemsPreviewDto } from '@api/aukro-api/model/user-favourite-items-preview-dto';
import { WatchingItemPreviewDto } from '@api/aukro-api/model/watching-item-preview-dto';
import { CrossTabMessagingService } from '@shared/services/cross-tab-messaging/service/cross-tab-messaging.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';
import { combineLatestWithStart$ } from '@util/rxjs-operators/combine-latest-with-start';
import { FavouritePopoverDataProviderService } from '@shared/favourite/service/favourite-popover-data-provider.service';
import { TimeService } from '@common/time/service/time.service';
import moment from 'moment-mini-ts';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { DateUtils } from '@util/util/date.utils';

@Injectable({
  providedIn: 'root',
})
export class FavouritePopoverDataService extends NgUnsubscribe {

  private readonly _popoverData$: BehaviorSubject<UserFavouritesPreviewDto> =
    new BehaviorSubject<UserFavouritesPreviewDto>({
      favouriteItemsPreviewDto: null,
      favouriteSellersPreviewDto: null,
      countOfAllFavouriteSearchTerms: null,
    });
  private readonly _popoverOpened$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private readonly _popoverForceReload$: Subject<void> =
    new Subject<void>;
  private readonly _recalculateFirstToEndPopoverItem$: Subject<void> =
    new Subject<void>;

  constructor(
    private readonly crossTabMessagingService: CrossTabMessagingService,
    private readonly favouritePopoverDataProviderService: FavouritePopoverDataProviderService,
    private readonly timeService: TimeService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public setPopoverData(data: UserFavouritesPreviewDto, syncCrossTab: boolean = true): void {
    this._popoverData$.next(data);
    if (syncCrossTab) {
      this.crossTabMessagingService.postMessage({ type: 'RELOAD_POPOVER_DATA', payload: data });
    }
  }

  public get popoverData$(): Observable<UserFavouritesPreviewDto> {
    return this._popoverData$.asObservable();
  }

  public get popoverData(): UserFavouritesPreviewDto {
    return this._popoverData$.getValue();
  }

  public setPopoverOpened(isOpened: boolean): void {
    this._popoverOpened$.next(isOpened);
  }

  public get popoverOpened$(): Observable<boolean> {
    return this._popoverOpened$.asObservable();
  }

  public reloadPopoverData(): void {
    this._popoverForceReload$.next();
  }

  public recalculateFirstToEndPopoverItem(): void {
    this._recalculateFirstToEndPopoverItem$.next();
  }

  public get forceLoadPopoverData$(): Observable<void> {
    return this._popoverForceReload$.asObservable();
  }

  /**
   * Retrieves an observable that emits the `FavouritePopoverItemModel` with the latest ending time among all items in the popover data.
   */
  public firstToEndPopoverItem$(): Observable<WatchingItemPreviewDto | Nil> {
    return combineLatest([
      this._popoverData$.asObservable(),
      this._recalculateFirstToEndPopoverItem$.asObservable()
        .pipe(
          startWith(void 0),
        ),
    ])
      .pipe(
        map(([popoverData]) =>
          popoverData.favouriteItemsPreviewDto?.favouriteItemsPreview?.reduce(
            (prev, current) =>
              (moment(prev.endTime).isBefore(moment(current.endTime)))
                ? prev
                : current,
            popoverData.favouriteItemsPreviewDto?.favouriteItemsPreview?.[0],
          ),
        ),
      );
  }

  public firstToEndPopoverUrgentItem$(): Observable<WatchingItemPreviewDto | Nil> {
    return combineLatestWithStart$([
      this.firstToEndPopoverItem$(),
      this.favouritePopoverDataProviderService.urgentCountdownThresholdDuration$,
    ])
      .pipe(
        map(([firstToEndItem, asyncThresholdDuration]) => {
          if (isNil(firstToEndItem)) {
            return null;
          }

          return this.timeService.isRemainingTimeLowerThanThreshold(firstToEndItem?.endTime, asyncThresholdDuration)
            ? firstToEndItem
            : null;
        }),
      );
  }

  public hasFirstToEndPopoverUrgentItemEndingTimePassed$(): Observable<boolean> {
    // create refresh sub, which can be called later, to repeat some logic after calculated duration
    const refreshSub$ = new Subject<void>();

    return this.firstToEndPopoverUrgentItem$()
      .pipe(
        switchMap((data) =>
          // return refreshSub$, so when it emits value, it will repeatedly call following code
          refreshSub$.asObservable()
            .pipe(
              // always emit first value immediately
              startWith(void 0),
              map(() => data),
            ),
        ),
        switchMap((firstToEndUrgentItem) => {
          // if theres no urgent item, return default backup polling
          if (isNil(firstToEndUrgentItem)) {
            return of(false);
          }
          // otherwise return different configs based on if urgent item ending time has passed or not
          const remainingTime = this.timeService.getRemainingTime(moment(firstToEndUrgentItem.endTime));

          if (remainingTime.asMilliseconds() <= 0) {
            return of(true);
          }
          // init delay, after repeat this func
          return this.ngZoneUtilService.timerOut$(
            Math.max(remainingTime.asMilliseconds(), DateUtils.convertSecondsToMilliseconds(1)),
          )
            .pipe(
              tap(() => {
                // repeat previous func
                refreshSub$.next();
              }),
              map(() => false),
            );
        }),
        distinctUntilChanged(),
      );
  }

  /**
   * Observable to get the current favourite items preview.
   * @returns The observable of current favourite items preview.
   */
  public popoverItems$(): Observable<UserFavouriteItemsPreviewDto> {
    return this._popoverData$
      .pipe(
        map(data => data.favouriteItemsPreviewDto),
        distinctUntilChangedDeep((items) => items?.favouriteItemsPreview?.map(item => item.itemId)),
      );
  }

  /**
   * Updates a specific popover item with the new provided data.
   * @param updatedItemId The ID of the item to update.
   * @param updatedItem The new data for the item.
   */
  public updatePopoverItem(updatedItemId: number, updatedItem: WatchingItemPreviewDto): void {
    const currentData: UserFavouritesPreviewDto = this._popoverData$.getValue();
    if (currentData.favouriteItemsPreviewDto && currentData.favouriteItemsPreviewDto.favouriteItemsPreview) {
      const items = currentData.favouriteItemsPreviewDto.favouriteItemsPreview.map(item =>
        item.itemId === updatedItemId ? { ...item, ...updatedItem } : item,
      );
      this.setPopoverData({
        ...currentData,
        favouriteItemsPreviewDto: { ...currentData.favouriteItemsPreviewDto, favouriteItemsPreview: items },
      });
    }
  }

  /**
   * Updates popover items with the new provided data
   */
  public updatePopoverItems(
    itemsToUpdate: WatchingItemPreviewDto[],
    syncCrossTab: boolean = true,
  ): void {
    const newCurrentPreviewItems = [
      ...this.popoverData.favouriteItemsPreviewDto.favouriteItemsPreview,
    ];

    // update favourite popover items
    itemsToUpdate.forEach((updateItem) => {
      const itemIndex = newCurrentPreviewItems.findIndex((currentItem) =>
        currentItem.itemId === updateItem.itemId);

      newCurrentPreviewItems[itemIndex] = updateItem;
    });

    this.setPopoverData(
      {
        ...this.popoverData,
        favouriteItemsPreviewDto: {
          ...this.popoverData.favouriteItemsPreviewDto,
          favouriteItemsPreview: newCurrentPreviewItems,
        },
      },
      syncCrossTab,
    );
  }

}
