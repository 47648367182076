import { AfterContentInit, ContentChildren, Directive, ElementRef, OnDestroy, QueryList } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { mergeWith } from 'rxjs';
import { delay, filter, takeUntil } from 'rxjs/operators';

import { ValidationMessageComponent } from '@shared/legacy/component/validation-message/validation-message.component';

import { fieldsValidationMessages, FieldValidationMessage } from './fields-validation-messages';
import { ValidationMessage, validationMessages } from './validation-messages';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

const DEFAULT_VALID_MESSAGE: ValidationMessage = {
  className: 'green',
  text: { defaultValue: '' },
};

@Directive({
  selector: '[aukValidationMessages]',
  exportAs: 'validationMessagesExport',
  standalone: true,
})
export class ValidationMessagesDirective extends NgUnsubscribe implements AfterContentInit, OnDestroy {

  @ContentChildren(ValidationMessageComponent, { descendants: true })

  protected vmComponents: QueryList<ValidationMessageComponent>;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly formGroupDirective: FormGroupDirective,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public ngAfterContentInit(): void {
    // Used to reset messages on input blur event
    const formBlur$ = this.ngZoneUtilService.fromEventOut$(
      this.elementRef.nativeElement,
      'blur',
      { capture: true },
    );
    const formSubmit$ = this.formGroupDirective.ngSubmit.asObservable();

    this.formGroupDirective.statusChanges
      .pipe(
        mergeWith(formBlur$, formSubmit$),
        filter(() => !!this.vmComponents.length),
        // Synchronize hasError() with checkVMComponents()
        delay(0),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => this.checkVMComponents());
  }

  public hasError(path: string, code?: string): boolean {
    if (!this.formNeedsToBeChecked(this.formGroupDirective)) {
      return false;
    }

    if (!this.formGroupDirective.form) {
      return false;
    }
    const c = this.formGroupDirective.form.get(path);
    if (!(this.controlIsAvailable(c) && this.controlNeedsToBeChecked(c))) {
      return false;
    }

    return code ?
      c.hasError(code) :
      !c.valid;
  }

  private checkVMComponents(): void {
    this.vmComponents
      .filter((vmc) => this.controlIsAvailable(vmc.control))
      .forEach((vmc) => {
        if (vmc.valid) {
          // If vm is valid, and control has some value,
          // either show valid checkbox or just clear the error message
          return vmc.message = vmc.control.value && vmc.showValid ?
            DEFAULT_VALID_MESSAGE :
            null;
        }

        if (this.formNeedsToBeChecked(this.formGroupDirective)
          && this.controlNeedsToBeChecked(vmc.control)) {
          const errorCode = this.getErrorCode(vmc.control, vmc._path, vmc.errorCode);
          vmc.message = this.getValidationMessage(vmc, errorCode);
        }
      });
  }

  private formNeedsToBeChecked(f: FormGroupDirective | FormGroup): boolean {
    return !!f && !f.valid;
  }

  private controlIsAvailable(c: AbstractControl): boolean {
    return !!c && !c.disabled;
  }

  private controlNeedsToBeChecked(c: AbstractControl): boolean {
    return c.touched || this.formGroupDirective.submitted;
  }

  private getErrorCode(control: AbstractControl, path: string, errorCode?: string): string | null {
    if (errorCode) {
      return control.hasError(errorCode) ?
        errorCode :
        null;
    }

    // While app has async validators, check PENDING state
    if (control.status === 'PENDING') {
      return null;
    }

    try {
      // Check for the first error
      return Object.keys(control.errors)[0] || null;
    } catch (e) {
      throw new Error(`Invalid component <auk-validation-message path="${ path }" ... does not have errors`);
    }
  }

  private getValidationMessage(vmc: ValidationMessageComponent, errorCode: string): ValidationMessage {
    let defaultMsg: FieldValidationMessage | string = fieldsValidationMessages[vmc.fieldName || vmc._fieldName];
    if (typeof defaultMsg === 'string') {
      defaultMsg = fieldsValidationMessages[defaultMsg];
    }
    const msg: FieldValidationMessage = defaultMsg as FieldValidationMessage;
    const message: ValidationMessage | string = !vmc.ignoreFields && msg
      && (msg.errors && msg.errors[errorCode] || msg.defaultMessage)
      || validationMessages[errorCode];
    return typeof message === 'string' ? validationMessages[message] as ValidationMessage : message;
  }

}
