import { combineLatest, OperatorFunction } from 'rxjs';
import { finalize, map, startWith, tap } from 'rxjs/operators';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

export const DEFAULT_DELAY_INDICATOR_TIME = 150;

/**
 * Calls start fn if observable doesn't emit value within the defined time frame, and complete fn
 * when the observable emits value
 * @example Start loader only if observable doesn't emit value within the defined time frame
 * @param ngZoneUtilService - Service used to create timer
 * @param start - called if observable doesn't emit value within defined time frame
 * @param complete - called when observable emits value
 * @param delay - time (in ms), when the start fn will be called
 */
export function delayIndicator<T>(
  ngZoneUtilService: NgZoneUtilService,
  start: () => void,
  complete: () => void,
  delay: number = DEFAULT_DELAY_INDICATOR_TIME,
): OperatorFunction<T, T> {
  let completeCallbackCalled = false;

  const isDelayShown$ = ngZoneUtilService.timerOut$(delay)
    .pipe(
      tap(() => {
        start();
      }),
      map(() => true),
      startWith(false),
    );

  return (input$) =>
    combineLatest([input$, isDelayShown$])
      .pipe(
        map(([input, isDelayShown]) => {
          if (isDelayShown) {
            complete();
            completeCallbackCalled = true;
          }

          return input;
        }),
        finalize(() => {
          if (completeCallbackCalled) {
            return;
          }

          complete();
        }),
      );
}
