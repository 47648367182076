import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';
import { HttpContext } from '@angular/common/http';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface EmailParams {
  /** New email data */
  changeEmailDto: model.ChangeEmailDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface PasswordParams {
  /** New password data */
  changePasswordDto: model.ChangePasswordDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface PhoneParams {
  /** Phone verification data */
  verifyPhoneDto: model.VerifyPhoneDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface PreferenceParams {
  /** Phone verification data */
  myAukroUserPreferencesChangeDto: model.MyAukroUserPreferencesChangeDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface UserCheckRestrictionsParams {
  /** Phone verification data */
  userCheckRestrictionsDto: model.UserCheckRestrictionsDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root'
})
export class UserMeService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public email(params: EmailParams, headers: { [key: string]: string } = {}): Observable<Object> {
    let bodyParams = params.changeEmailDto;
    let queryParams;
    return this.putGenerated(`/users/me/email`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public myData(withCompanyData?: boolean): Observable<model.MyAukroUserDataDto> {
    let queryParams = {
      withCompanyData,
    };
    return this.getGenerated(`/users/me/myData`, queryParams);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public checkRestrictions(params: UserCheckRestrictionsParams, httpContext: HttpContext, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.userCheckRestrictionsDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/users/me/check-restrictions`, bodyParams, queryParams, formDataParams, headers, undefined, httpContext);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public companyChange(): Observable<model.MyAukroCompanyChangeDataDto> {
    return this.getGenerated(`/users/me/myData/companyChange`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public newEmail(): Observable<void> {
    return this.deleteGenerated(`/users/me/newEmail`);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public resend(headers: { [key: string]: string } = {}): Observable<void> {
    return this.postGenerated(`/users/me/newEmail/resend`, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public password(params: PasswordParams, headers: { [key: string]: string } = {}): Observable<Object> {
    let bodyParams = params.changePasswordDto;
    let queryParams;
    return this.putGenerated(`/users/me/password`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public phone(params: PhoneParams, headers: { [key: string]: string } = {}): Observable<void> {
    let bodyParams = params.verifyPhoneDto;
    let queryParams;
    return this.putGenerated(`/users/me/phone`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public preference(params: PreferenceParams, headers: { [key: string]: string } = {}): Observable<model.MyAukroUserPreferencesDataDto> {
    let bodyParams = params.myAukroUserPreferencesChangeDto;
    let queryParams;
    return this.putGenerated(`/users/me/preference`, bodyParams, queryParams, headers);
  }
}
