import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SuggestAllResponseContentElementLandingPage } from '@api/generated/defs/SuggestAllResponseContentElementLandingPage';

@Component({
  selector: 'auk-landing-page-item',
  templateUrl: './landing-page-item.component.html',
  styleUrls: [
    './landing-page-item.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingPageItemComponent {

  @Input() public landingPage: SuggestAllResponseContentElementLandingPage;
  @HostBinding('class.focus') @Input() public focus: boolean = false;

}
