import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import isNil from 'lodash-es/isNil';
import { takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { CurrencyCode } from '@shared/currency/model/currency-code.model';
import { UserCurrencyPreferenceService } from '@shared/currency/service/user-currency-preference.service';
import { DEFAULT_CURRENCY } from '@shared/currency/const/currency.constants';
import { MoneyDto } from '@api/aukro-api/model/money-dto';

@Component({
  selector: 'auk-currency-approximation',
  templateUrl: './currency-approximation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyApproximationComponent extends NgUnsubscribe implements OnChanges, OnInit {

  @Input() public price: MoneyDto;

  private nonEmptyAmount: boolean = false;
  private preferredCurrencyCode: CurrencyCode = DEFAULT_CURRENCY.code;

  constructor(
    private readonly userCurrencyPreferenceService: UserCurrencyPreferenceService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.userCurrencyPreferenceService.preferredCurrencyCode$
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((preferredCurrency) => {
        this.preferredCurrencyCode = preferredCurrency;
        this.changeDetectorRef.markForCheck();
      });
  }

  // TODO: [PDEV-17322] - have only MoneyDTO ( no number )
  public ngOnChanges(changes: AukSimpleChanges<CurrencyApproximationComponent>): void {
    if (changes.price) {
      if (typeof this.price === 'number') {
        console.error('Number price change detected');
        this.nonEmptyAmount = !isNil(this.price) && this.price > 0;
      } else {
        this.nonEmptyAmount = !isNil(this.price) && this.price.amount > 0;
      }
    }
  }

  protected get shouldShowApproximation(): boolean {
    return this.nonEmptyAmount &&
      this.preferredCurrencyCode !== DEFAULT_CURRENCY.code &&
      this.priceCurrencyDifferentThanUserCurrency(this.preferredCurrencyCode);
  }

  // TODO: [PDEV-17322] - have only MoneyDTO ( no number )
  private priceCurrencyDifferentThanUserCurrency(userCurrencyCode: CurrencyCode): boolean {
    if (typeof this.price === 'number') {
      return true;
    }

    return !isNil(this.price) && this.price?.currency !== userCurrencyCode;
  }

}
