import { Injectable } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PlatformService } from '@shared/platform/service/platform.service';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

@Injectable({
  providedIn: 'root',
})
export class SkeletonLoadingService extends NgUnsubscribe {

  private appViewInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private readonly platformService: PlatformService,
    private readonly responsivenessService: ResponsivenessService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  public get displaySkeletons$(): Observable<boolean> {
    if (this.platformService.isBot) {
      return of(false);
    }

    if (this.platformCommonService.isServer) {
      return of(true);
    }

    return this.appViewInitialized$
      .pipe(
        map(val => !val),
      );
  }

  public get displaySkeletonsOnMobile$(): Observable<boolean> {
    if (!this.responsivenessService.isMdAndLower) {
      return of(false);
    }

    return this.displaySkeletons$;
  }

  public get displaySkeletonsOnTablet$(): Observable<boolean> {
    if (!this.responsivenessService.isLgAndLower) {
      return of(false);
    }

    return this.displaySkeletons$;
  }

  public onAppComponentViewInit(): void {
    this.ngZoneUtilService.timerOut$(0)
      .pipe(
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(() => {
        this.appViewInitialized$.next(true);
        this.appViewInitialized$.complete();
      });
  }

}
