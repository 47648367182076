import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, throwError } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { StatusDto } from '@api/generated/defs/StatusDto';
import { PlatformService } from '@shared/platform/service/platform.service';
import { ServerService } from '@api/generated/api/Server';
import { Router } from '@angular/router';
import { BaseErrorInterceptor } from './base.error.interceptor';
import { HttpError } from './model/http-error';
import { ConnectionService } from '@shared/connection/service/connection.service';
import { ConnectionInfoModel } from '@shared/connection/model/connection-info.model';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Injectable()
export class Error5xxInterceptor extends BaseErrorInterceptor implements HttpInterceptor {

  protected readonly matcher: RegExp = /5\d\d/;

  private statusCheckPending: boolean = false;
  private connectionCheckPending: boolean = false;

  constructor(
    private readonly platformService: PlatformService,
    private readonly serverService: ServerService,
    private readonly router: Router,
    private readonly connectionService: ConnectionService,
  ) {
    super();
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req)
      .pipe(
        catchError((err: HttpError) => {
          if (err.code === 504) {
            return this.handleConnectivityCheck(err);
          }
          if (this.shouldIntercept(err)) {
            this.makeStatusCheck();
          }
          return throwError(() => err);
        }),
      );
  }

  /**
   * Handle connectivity checks when the error code is 504.
   * This method checks the current connectivity status by running a request to the server.
   * If the connectivity check is already pending or in progress, it immediately rethrows the error to prevent duplicate checks.
   * If the device is not connected, it initiates a connectivity check and rethrows the original error.
   * If the device is connected, it performs a status check (if applicable based on the error) and then rethrows the original error.
   */
  private handleConnectivityCheck(err: HttpError): Observable<HttpEvent<unknown>> {
    if (this.connectionCheckPending || this.connectionService.isConnectivityCheckRunning) {
      return throwError(() => err);
    }
    this.connectionCheckPending = true;

    return this.connectionService.checkConnectivityRequest$()
      .pipe(
        take(1),
        switchMap((connection: ConnectionInfoModel) => {
          this.connectionCheckPending = false;
          if (connection?.connectionStatusType === 'OFFLINE') {
            this.connectionService.initiateConnectivityCheck();
            return throwError(() => err);
          } else {
            if (this.shouldIntercept(err)) {
              this.makeStatusCheck();
            }
            return throwError(() => err);
          }
        }),
        catchError(() => {
          this.connectionCheckPending = false;
          return throwError(() => err);
        }),
      );
  }

  private makeStatusCheck(): void {
    if (
      this.statusCheckPending
      || this.router.url === '/zavreno'
      || this.router.url === '/zavreno-native'
      || this.platformService.isServer
    ) {
      return;
    }

    this.statusCheckPending = true;
    // SSR optimization
    this.serverService.status()
      .pipe(
        take(1),
        finalize(() => this.statusCheckPending = false),
      )
      .subscribe({
        next: (data: StatusDto) => {
          if (data.outage.running && !data.outage.showBanner) {
            if (PlatformCommonService.isNativeApp) {
              void this.router.navigate(['/zavreno-native']);
            } else {
              void this.router.navigate(['/zavreno']);
            }
          }
        },
        error: () => {
          if (PlatformCommonService.isNativeApp) {
            void this.router.navigate(['/zavreno-native']);
          } else {
            void this.router.navigate(['/zavreno']);
          }
        },
      });
  }

}
