import { SchedulerLike, Subscription } from 'rxjs';
import { NgZone } from '@angular/core';
import { DelayModel } from '@util/zone/model/delay.model';
import { WorkModel } from '@util/zone/model/work.model';

export abstract class ZoneScheduler implements SchedulerLike {

  constructor(
    protected readonly ngZone: NgZone,
    protected readonly schedulerLike: SchedulerLike,
  ) {
  }

  public abstract schedule<T>(...args: [WorkModel<T>, DelayModel, T]): Subscription;

  public now(): number {
    return this.schedulerLike.now();
  }

}
