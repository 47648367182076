import { BaseViewResolver } from './base-view-resolver';
import { EmptyObject } from '@util/helper-types/empty-object';
import { UserActionType } from '@shared/user-action/model/user-action.type';
import { Injectable } from '@angular/core';
import { ActionViewAnalyticsDataModel } from '../model/action-view-analytics-data.model';

@Injectable()
export class NoopViewResolver extends BaseViewResolver<EmptyObject> {

  public resolve(
    action: UserActionType,
    analyticsData: ActionViewAnalyticsDataModel,
    viewResult?: EmptyObject,
  ): void {
    // Intentionally blank
  }

}
