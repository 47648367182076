<auk-dialog-wrapper-with-content class="tw-break-words" [dialogTitle]="{ key: 'CHANGE_ADDRESS_MODAL_ACTION_TITLE' }" (closeDialogClick)="onCloseClick()">
  <ng-container content>
    <form *ngIf="personalDataForm" id="personal-form" [formGroup]="personalDataForm" (ngSubmit)="onPersonalDataFormSubmit()">
      <div class="flex column-max-tablet m-b-3" formGroupName="address">
        <mat-form-field class="fluid" appearance="outline">
          <mat-label>{{ "CHANGE_PERSONAL_DATA_COMPONENT_HTML_6" | translate }}</mat-label>
          <input type="text" formControlName="address" matInput />
          <mat-error [ngSwitch]="true">
            <div
              *ngSwitchCase="
                personalDataForm.get('address.address').hasError('street') || personalDataForm.get('address.address').hasError('required')
              "
            >
              {{ "CHANGE_PERSONAL_DATA_COMPONENT_HTML_7" | translate }}
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex wrap m-b-3 justify-between" formGroupName="address">
        <div class="w-33p-min-tablet full-width-max-tablet p-r-2-min-tablet m-b-3-max-tablet">
          <mat-form-field class="fluid" appearance="outline">
            <mat-label>{{ "CHANGE_PERSONAL_DATA_COMPONENT_HTML_8" | translate }}</mat-label>
            <input type="text" formControlName="zipCode" matInput />
            <mat-error [ngSwitch]="true">
              <div
                *ngSwitchCase="
                  personalDataForm.get('address.zipCode').hasError('zipCode') ||
                  personalDataForm.get('address.zipCode').hasError('required')
                "
              >
                {{ "CHANGE_PERSONAL_DATA_COMPONENT_HTML_9" | translate }}
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="tw-grow">
          <mat-form-field class="fluid" appearance="outline">
            <mat-label>{{ "CHANGE_PERSONAL_DATA_COMPONENT_HTML_10" | translate }}</mat-label>
            <input type="text" formControlName="city" matInput />
            <mat-error [ngSwitch]="true">
              <div
                *ngSwitchCase="
                  personalDataForm.get('address.city').hasError('city') || personalDataForm.get('address.city').hasError('required')
                "
              >
                {{ "CHANGE_PERSONAL_DATA_COMPONENT_HTML_11" | translate }}
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex column-max-tablet m-b-3" *ngIf="countries" formGroupName="address">
        <mat-form-field class="fluid" appearance="outline">
          <mat-label>{{ "CHANGE_PERSONAL_DATA_COMPONENT_HTML_12" | translate }}</mat-label>
          <mat-select [formControlName]="'country'">
            <mat-option *ngFor="let country of countries" [value]="country.code">
              {{ country.name }}
            </mat-option>
          </mat-select>
          <mat-error [ngSwitch]="true">
            <div *ngSwitchCase="personalDataForm.get('address.country')?.hasError('required')">
              {{ "CHANGE_PERSONAL_DATA_COMPONENT_HTML_13" | translate }}
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </ng-container>

  <ng-container actions>
    <auk-button colorCombination="PRIMARY" [chin]="true" class="tw-w-full tw-mb-2" (buttonClick)="onPersonalDataFormSubmit()">
      {{ "CHANGE_ADDRESS_MODAL_ACTION_SAVE" | translate }}
    </auk-button>
    <auk-button class="tw-w-full tw-mb-2" (buttonClick)="onCloseClick()">
      {{ "CHANGE_ADDRESS_MODAL_ACTION_CANCEL" | translate }}
    </auk-button>
  </ng-container>
</auk-dialog-wrapper-with-content>
