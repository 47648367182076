@if (retailPrice) {
  <div class="retail-price-wrapper">
    @if (showRetailPricePrefix) {
      <span
        [ngClass]="priceAndPrefixClass"
        >
        {{ 'COMMON__RETAIL_PRICE_PREFIX' | translate }}:
      </span>
    }
    <span class="tw-line-through"
      [ngClass]="priceAndPrefixClass"
      [matTooltip]="'RETAIL_PRICE_LABEL_TOOLTIP' | translate"
      matTooltipClass="tooltip-lg"
      [matTooltipShowDelay]="250"
      >
      {{ retailPrice.retailPrice | currency }}
    </span>
    @if (retailPrice.savingPercentage) {
      <div
        class="retail-price-percentage"
        [matTooltip]="'RETAIL_PRICE_PERCENTAGE_TOOLTIP' | translate"
        matTooltipClass="tooltip-lg"
        [matTooltipShowDelay]="250"
        >
        -{{ retailPrice.savingPercentage }} %
      </div>
    }
  </div>
}
