<div class="tw-flex tw-items-center tw-justify-start tw-gap-2">

  @if (iconPosition === 'START') {
    <ng-container [ngTemplateOutlet]="iconTmpl"/>
  }

  <!-- SIMPLE TEXT -->
  @if (text) {
    <div
      class="tw-flex-grow tw-text-foreground-color"
      [aukInnerHtml]="text"
      [class.tw-truncate]="truncate"
      [ngClass]="textCssClass"
    ></div>
  }
  <!-- SIMPLE TEXT -->

  <!-- DYNAMIC TEXT CONTENT -->
  <ng-content/>
  <!-- DYNAMIC TEXT CONTENT -->

  @if (iconPosition === 'END') {
    <ng-container [ngTemplateOutlet]="iconTmpl"/>
  }

</div>

<ng-template #iconTmpl>

  @if (iconsList?.length) {
    <div
      class="tw-flex tw-items-center tw-justify-start tw-gap-2">
      @for (icon of iconsList; track icon) {
        <auk-icon
          [source]="icon.source"
          [colorCombination]="icon.colorCombination"
          [iconType]="icon.type"
          [size]="icon.size"
          [ngClass]="iconCustomClass"
          [ratio]="icon.ratio"
          />
      }
    </div>
  }

</ng-template>
