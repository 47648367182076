import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Directive({
  selector: '[aukHideIfMobileApp]',
  standalone: true,
})
export class HideIfMobileAppDirective implements OnInit {

  constructor(
    private readonly templateRef: TemplateRef<unknown>,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
  }

  public ngOnInit(): void {
    if (PlatformCommonService.isNativeApp) {
      this.viewContainerRef.clear();
    } else {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

}
