import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, OnChanges, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { fromIntersectionObserver } from '../domain/from-intersection-observer';
import { IntersectionStatus } from '../model/intersection-status.type';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { Subject, merge } from 'rxjs';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { IdAware } from '@util/helper-types/id-aware';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import isEqual from 'lodash-es/isEqual';

@Directive({
  selector: '[aukIntersectionObserver]',
  standalone: true,
})
export class IntersectionObserverDirective extends NgUnsubscribe implements OnDestroy, OnChanges {

  // Default is set to viewport, you can go outside viewport to preload (aka 30% means 30% outside viewport on all sides)
  @Input() public intersectionRootMargin = '0px';
  @Input() public intersectionRoot: HTMLElement;
  // How big part of elements needs to be in viewport in order to call callback. 0.1 (10%), 1 (100%)
  @Input() public intersectionThreshold: number | number[];
  // When Element goes outside viewport, the callback with NotVisible will be called. Use when list of elements its big and complex
  @Input() public intersectionUnload: boolean = false;
  // You can disable intersection detection with this input. Its useful in SSR for example.
  @Input() public disableIntersectionDetection: boolean = false;
  /**
   * This array should represent how many direct children elements, are rendered inside this element where this directive is placed
   * So every time the ids are changed, we can initialize intersection observer listener (for skeleton loading purposes)
   */
  @Input({ required: true }) public children: IdAware[];

  @Output() public visibilityChange = new EventEmitter<{ status: IntersectionStatus; value: string }>();

  private intersectionObserverDestroy$: Subject<void> = new Subject();

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly ngZoneUtilService: NgZoneUtilService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (this.canInitIntersection(changes)) {
      this.init();
    }
  }

  private canInitIntersection(changes: AukSimpleChanges<typeof this>): boolean {
    // if something from the intersection config changes, return true
    if (
      changes.intersectionRootMargin
      || changes.intersectionRoot
      || changes.intersectionThreshold
      || changes.intersectionUnload
      || changes.disableIntersectionDetection
    ) {
      return true;
    }

    if (changes.children) {
      // only if ids of the children are changed, return true otherwise false
      const oldChildrenIds = changes.children.previousValue.map((child) => child.id);
      const newChildrenIds = changes.children.currentValue.map((child) => child.id);

      return !isEqual(oldChildrenIds, newChildrenIds);
    }

    // in all remaining cases return false
    return false;
  }

  private init(): void {
    this.cleanIntersection();

    if (this.disableIntersectionDetection || this.platformCommonService.isServer) {
      return;
    }

    this.setIntersection();
  }

  private cleanIntersection(): void {
    this.intersectionObserverDestroy$.next();
  }

  private setIntersection(): void {
    // we need to explicitly detect changes, because this method can be called inside OnChanges hook and for that we need to make sure
    // the DOM is updated, with the latest data, so we can work with directive's element children length
    this.changeDetectorRef.detectChanges();

    if (this.elementRef?.nativeElement?.children?.length < 1) {
      return;
    }

    const elements = Array.from(this.elementRef.nativeElement.children);

    const config: IntersectionObserverInit = {
      root: this.intersectionRoot,
      rootMargin: this.intersectionRootMargin,
      threshold: this.intersectionThreshold,
    };

    this.ngZoneUtilService.runOut(() => {
      fromIntersectionObserver(
        elements,
        config,
        this.intersectionUnload,
      ).pipe(
        this.ngZoneUtilService.observeOnNgZone(),
        takeUntil(
          merge(this.ngUnsubscribe, this.intersectionObserverDestroy$),
        ),
      ).subscribe(({ status, value }) => {
        this.visibilityChange.emit({ status, value });
      });
    });
  }

}
