import { ValidationMessage } from './validation-messages';

export interface FieldValidationMessage {
  errors?: {
    [errorCode: string]: ValidationMessage;
  };
  defaultMessage?: ValidationMessage;
}

export const fieldsValidationMessages: { [path: string]: FieldValidationMessage | string } = {
  address: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_ADDRESS_ERROR_REQUIRED' },
      },
    },
  },
  answer: {
    errors: {
      maxlength: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_ANSWER_ERROR_MAX_LENGTH' },
      },
    },
  },
  birthDate: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_BIRTH_DATE_ERROR_REQUIRED' },
      },
      notExist: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_BIRTH_DATE_ERROR_NOT_EXIST' },
      },
    },
  },
  city: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_CITY_ERROR_REQUIRED' },
      },
    },
  },
  zipCode: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_ZIP_CODE_ERROR_REQUIRED' },
      },
      incorrect: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_ZIP_CODE_ERROR_INCORRECT' },
      },
    },
  },
  company: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_COMPANY_ERROR_REQUIRED' },
      },
    },
  },
  companyName: 'company',
  confirmPassword: {
    errors: {
      fieldsMatch: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_CONFIRM_PASSWORD_ERROR_FIELDS_MATCH' },
      },
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_CONFIRM_PASSWORD_ERROR_REQUIRED' },
      },
    },
    defaultMessage: {
      text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_CONFIRM_PASSWORD_DEFAULT_MESSAGE' },
    },
  },
  country: {
    defaultMessage: {
      text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_COUNTRY_DEFAULT_MESSAGE' },
    },
  },
  email: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_EMAIL_ERROR_REQUIRED' },
      },
      used: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_EMAIL_ERROR_USED' },
      },
    },
  },
  firstName: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_FIRST_NAME_ERROR_REQUIRED' },
      },
    },
  },
  attachmentIds: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_ATTACHMENT_IDS_ERROR_REQUIRED' },
      },
    },
  },
  imageUploading: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_IMAGE_UPLOADING_ERROR_REQUIRED' },
      },
      fileTypeError: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_IMAGE_UPLOADING_ERROR_FILE_TYPE_ERROR' },
      },
      fileSizeError: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_IMAGE_UPLOADING_ERROR_FILE_SIZE_ERROR' },
      },
    },
  },
  itemNumbers: {
    errors: {
      maxlength: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_ITEM_NUMBERS_ERROR_MAX_LENGTH' },
      },
    },
  },
  lastName: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_LAST_NAME_ERROR_REQUIRED' },
      },
    },
  },
  login: {
    errors: {
      used: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_LOGIN_ERROR_USED' },
      },
      incorrect: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_LOGIN_ERROR_INCORRECT' },
      },
      exists: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_LOGIN_ERROR_EXISTS' },
      },
      alreadyChanged: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_LOGIN_ERROR_ALREADY_CHANGED' },
      },
    },
    defaultMessage: {
      text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_LOGIN_DEFAULT_MESSAGE' },
    },
  },
  newEmail: {
    errors: {
      exist: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_EMAIL_ERROR_EXIST' },
      },
      exists: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_EMAIL_ERROR_EXISTS' },
      },
      fieldsMatch: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_EMAIL_ERROR_FIELDS_MATCH' },
      },
      required: {
        text: { key: 'LEGACY_VALIDATION_MESSAGES_REQUIRED' },
      },
      notAllowed: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_EMAIL_ERROR_NOT_ALLOWED' },
      },
    },
    defaultMessage: {
      text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_EMAIL_DEFAULT_MESSAGE' },
    },
  },
  newPassword: {
    errors: {
      equalWithOldPassword: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_PASSWORD_ERROR_EQUAL_WITH_OLD_PASSWORD' },
      },
      pattern: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_PASSWORD_ERROR_PATTERN' },
      },
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_PASSWORD_ERROR_REQUIRED' },
      },
      containsLogin: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_PASSWORD_ERROR_CONTAINS_LOGIN' },
      },
    },
    defaultMessage: {
      text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_PASSWORD_DEFAULT_MESSAGE' },
    },
  },
  newPassword2: {
    errors: {
      fieldsMatch: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_PASSWORD2_ERROR_FIELDS_MATCH' },
      },
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_NEW_PASSWORD2_ERROR_REQUIRED' },
      },
    },
  },
  oldPassword: {
    defaultMessage: {
      text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_OLD_PASSWORD_DEFAULT_MESSAGE' },
    },
    errors: {
      incorrect: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_OLD_PASSWORD_ERROR_INCORRECT' },
      },
    },
  },
  password: {
    errors: {
      pattern: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_ERROR_PATTERN' },
      },
      fieldsMatch: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_ERROR_FIELDS_MATCH' },
      },
      incorrectPwd: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_ERROR_INCORRECT_PWD' },
      },
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_ERROR_REQUIRED' },
      },
      matchOld: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_ERROR_MATCH_OLD' },
      },
      containsLogin: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_ERROR_CONTAINS_LOGIN' },
      },
      passContainsLastName: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_ERROR_PASS_CONTAINS_LAST_NAME' },
      },
      passContainsFirstName: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_ERROR_PASS_CONTAINS_FIRST_NAME' },
      },
      passContainsLogin: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_ERROR_PASS_CONTAINS_LOGIN' },
      },
      passContainsCompany: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_ERROR_PASS_CONTAINS_COMPANY' },
      },
    },
    defaultMessage: {
      text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PASSWORD_DEFAULT_MESSAGE' },
    },
  },
  phone: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_ERROR_REQUIRED' },
      },
      phoneNumberNotExists: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_ERROR_PHONE_NUMBER_NOT_EXISTS' },
      },
      phoneNumberNotValid: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_ERROR_PHONE_NUMBER_NOT_VALID' },
      },
      empty: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_ERROR_EMPTY' },
      },
      parseError: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_ERROR_PARSE_ERROR' },
      },
      shortInput: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_ERROR_SHORT_INPUT' },
      },
    },
  },
  phoneNumber: {
    errors: {
      phoneNumberNotExists: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_NUMBER_ERROR_PHONE_NUMBER_NOT_EXISTS' },
      },
      phoneNumberNotValid: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_NUMBER_ERROR_PHONE_NUMBER_NOT_VALID' },
      },
      empty: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_NUMBER_ERROR_EMPTY' },
      },
      parseError: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_NUMBER_ERROR_PARSE_ERROR' },
      },
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_NUMBER_ERROR_REQUIRED' },
      },
      shortInput: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_PHONE_NUMBER_ERROR_SHORT_INPUT' },
      },
    },
  },
  reason: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_REASON_ERROR_REQUIRED' },
      },
    },
  },
  reasonName: {
    errors: {
      required: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_REASON_NAME_ERROR_REQUIRED' },
      },
    },
  },
  regNumber: {
    errors: {
      minlength: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_REG_NUMBER_ERROR_MIN_LENGTH' },
      },
      required: {
        text: { key: 'LEGACY_VALIDATION_MESSAGES_REQUIRED' },
      },
      pattern: {
        text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_REG_NUMBER_ERROR_PATTERN' },
      },
    },
  },
  termsConfirmed: {
    defaultMessage: {
      text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_TERMS_CONFIRMED_DEFAULT_MESSAGE' },
    },
  },
  consentTermsConfirmed: {
    defaultMessage: {
      text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_CONSENT_TERMS_CONFIRMED_DEFAULT_MESSAGE' },
    },
  },
  underAgeConfirmation: {
    defaultMessage: {
      text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_UNDER_AGE_CONFIRMATION_DEFAULT_MESSAGE' },
    },
  },
  wrongInput: {
    defaultMessage: {
      text: { key: 'LEGACY_FIELDS_VALIDATION_MESSAGES_WRONG_INPUT_DEFAULT_MESSAGE' },
    },
  },
};
