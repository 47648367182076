<a [class.button-to-header]="showButton"
   [class.hidden]="!showButton"
   [class.hidden-tablet]="isHiddenOnTablet"
   (click)="scrollToTopWindow()"
   class="flex-center"
   aukTestIdentification="button-arrow-up"
   aukAccessibilityId="ACS_BUTTON_ARROW_UP"
>
  <i class="material-icons text-white">
    keyboard_arrow_up
  </i>
</a>
