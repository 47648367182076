import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface RemoveStanceSendyParams {
  /** email */
  email: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface StanceParams {
  /** newConsentStanceDto */
  newConsentStanceDto: model.NewConsentStanceDto;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface TermsParams {
  /** consentTermsIds */
  consentTermsIds: number[];
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable()
export class ConsentService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public removeStanceSendy(params: RemoveStanceSendyParams, headers: { [key: string]: string } = {}): Observable<void> {
    let queryParams = {
      email: params.email,
    };
    let bodyParams;
    return this.putGenerated(`/consent/removeStanceSendy`, bodyParams, queryParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public stance(params: StanceParams, headers: { [key: string]: string } = {}): Observable<model.ConsentStanceBasicDto> {
    let bodyParams = params.newConsentStanceDto;
    let queryParams;
    let formDataParams;
    return this.postGenerated(`/consent/stance`, bodyParams, queryParams, formDataParams, headers);
  }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public terms(params: TermsParams): Observable<model.ConsentTermsDto[]> {
    let queryParams = {
      consentTermsIds: params.consentTermsIds,
    };
    return this.getGenerated(`/consent/terms`, queryParams);
  }
}
