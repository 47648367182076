import { Component, Inject } from '@angular/core';
import { ActionViewType } from '../../model/action-view-type';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActionViewData } from '../../model/action-view-data';
import { ActionViewResult } from '../../model/action-view-result';

@Component({
  template: '',
})
export abstract class BaseActionViewComponent<T extends ActionViewType> {

  constructor(
    private readonly matDialogRef: MatDialogRef<BaseActionViewComponent<T>, ActionViewResult<T>>,
    @Inject(MAT_DIALOG_DATA) public readonly data: ActionViewData<T>,
  ) {
  }

  public closeDialog(result?: ActionViewResult<T>): void {
    this.matDialogRef.close(result);
  }

}
