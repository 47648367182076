<div class="tw-flex tw-justify-center tw-items-center tw-cursor-pointer transition-base tw-select-none"
     [ngClass]="iconClasses"
     (click)="onButtonClick($event)"
>

  <auk-icon [colorCombination]="iconModel.colorCombination"
            [source]="iconModel.source"
            [iconType]="iconModel.type"
            [size]="iconModel.size">
  </auk-icon>

</div>
