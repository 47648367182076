import { ChangeDetectorRef, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors } from '@angular/forms';
import isEmpty from 'lodash-es/isEmpty';
import { Observable, of } from 'rxjs';
import { first, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { TranslationSource } from '@common/translations/model/translation-source';
import { PhoneNumberValidationDto } from '@api/generated/defs/PhoneNumberValidationDto';
import { PhoneNumberValidatorService } from '@api/generated/api/PhoneNumberValidator';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberService {

  constructor(
    private readonly phoneNumberValidatorService: PhoneNumberValidatorService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
  }

  public phoneNumberValidator: (changeDetectorRef: ChangeDetectorRef) => AsyncValidatorFn = (changeDetectorRef: ChangeDetectorRef) =>
    (abstractControl: AbstractControl): ReturnType<AsyncValidatorFn> => {
      // https://stackoverflow.com/questions/14894899/what-is-the-minimum-length-of-a-valid-international-phone-number
      // minimum digits = 4 (Solomon Islands)
      // +1 because of '+' at the beginning
      abstractControl.markAsTouched();
      if (abstractControl.value && abstractControl.value.length < 7) {
        return of({ shortInput: true } as ValidationErrors);
      }

      // Null values are supported, prevent them with validator.required
      if (!abstractControl.value) {
        return of(null);
      }

      return this.ngZoneUtilService.timerOut$(500)
        .pipe(
          switchMap(() => this.validatePhoneNumber(abstractControl.value)),
          map((response: PhoneNumberValidationDto) => {
            changeDetectorRef.markForCheck();
            if (!response.valid) {
              return { [response.notValidCode]: true };
            }
            return null;
          }),
          first(),
        );
    };

  public static getPhoneErrorMessageForFormControl(phoneControl: FormControl): TranslationSource {
    if (!phoneControl || isEmpty(phoneControl.errors)) {
      return null;
    }

    if (phoneControl?.hasError('required')) {
      return { key: 'PHONE_VALIDATOR_REQUIRED' };
    }

    if (phoneControl?.hasError('shortInput')) {
      return { key: 'PHONE_VALIDATOR_SHORT_INPUT' };
    }

    if (phoneControl?.hasError('maxlength')) {
      return { key: 'PHONE_VALIDATOR_LONG_INPUT' };
    }

    if (phoneControl?.hasError('parseError')) {
      return { key: 'PHONE_VALIDATOR_PARSE_ERROR' };
    }

    if (phoneControl?.hasError('phoneNumberNotValid')) {
      return { key: 'PHONE_VALIDATOR_PHONE_NUMBER_NOT_VALID' };
    }

    if (phoneControl?.hasError('phoneNumberNotExists')) {
      return { key: 'PHONE_VALIDATOR_PHONE_NUMBER_NOT_EXISTS' };
    }

    if (phoneControl?.hasError('phoneNumberBlacklisted')) {
      return { key: 'PHONE_NUMBER_BLACKLIST_TEXT' };
    }

    return { key: 'PHONE_VALIDATOR_PHONE_INVALID' };
  }

  public validatePhoneNumber(phoneNumber: string): Observable<PhoneNumberValidationDto> {
    return this.phoneNumberValidatorService.validateNumber({ phoneNumber });
  }

  /**
   * Call BE service to validate and format the anonymized phone number and return always at most one formatted phone number.
   * The input phone number doesn't have to contain country prefix, BE service fills it according to its logic.
   * @param phoneNumber the anonymized phone number to be formatted
   */
  public splitAnonymizedPhoneNumber(phoneNumber: string): Observable<string> {
    if (!phoneNumber || typeof phoneNumber !== 'string') {
      return of(null);
    }

    return this.validatePhoneNumber(phoneNumber)
      .pipe(
        take(1),
        mergeMap((validatedPhoneNumber: PhoneNumberValidationDto) => {
          const splitPhoneNumber: string[] = phoneNumber.replace('+' + validatedPhoneNumber.countryCode, '').split('***');
          return of(`+${ validatedPhoneNumber.countryCode } ${ splitPhoneNumber[0] } *** ${ splitPhoneNumber[1] }`);
        }),
      );
  }

}
