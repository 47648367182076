import {
  UserActionPrerequisiteTaskFulfillmentPayloadResolverService,
} from './user-action-prerequisite-task-fulfillment-payload-resolver.service';
import { UserTaskPayloadModel } from '@shared/user-task/base/model/user-task-payload.model';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserActionType } from '../../model/user-action.type';
import { UserActionPopupFlowConfigService } from '../user-action-popup-flow-config.service';
import { map } from 'rxjs/operators';
import { UserActionPopupFlowConfigElementModel } from '../../model/user-action-popup-flow-config-element.model';
import {
  recursiveStringsObjToTranslationSources,
} from '@util/helper-functions/recursive-strings-obj-to-translation.sources';

@Injectable({
  providedIn: 'root',
})
export class UserActionPrerequisiteTaskPersonalDataFulfillmentPayloadResolverService
  extends UserActionPrerequisiteTaskFulfillmentPayloadResolverService<'PERSONAL_DATA'> {

  constructor(
    private readonly userActionPopupFlowConfigService: UserActionPopupFlowConfigService,
  ) {
    super();
  }

  public resolve$(actionType: UserActionType): Observable<UserTaskPayloadModel<'PERSONAL_DATA'>> {
    return this.userActionPopupFlowConfigService.getTasksConfiguration$('PERSONAL_DATA', actionType)
      .pipe(
        map((taskConfig: UserActionPopupFlowConfigElementModel['tasks']['PERSONAL_DATA']) => ({
          stepsText: recursiveStringsObjToTranslationSources(taskConfig?.steps),
        })),
      );
  }

}
