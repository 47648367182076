import { DialogService } from '@common/dialog/service/dialog.service';
import { AukDialogType } from '@shared/dialog/model/auk-dialog.type';
import { Inject, Injectable } from '@angular/core';
import { DialogConfigModel } from '@common/dialog/model/dialog-config.model';
import { BaseDialogType } from '@common/dialog/model/base-dialog.type';

import { combineLatest, Observable } from 'rxjs';
import { RoutesService } from '@shared/services/app/routes.service';
import { DIALOG_CONFIG } from '@common/dialog/di/dialog-config.injection-token';
import { SubbrandService } from '@shared/subbrand/service/subbrand.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AukDialogService extends DialogService<AukDialogType> {

  constructor(@Inject(DIALOG_CONFIG) config: DialogConfigModel<BaseDialogType | AukDialogType>,
              private readonly routesService: RoutesService,
              private readonly subbrandService: SubbrandService,
  ) {
    super(config);
  }

  protected override restrictedTypes$(): Observable<AukDialogType[]> {
    return combineLatest(
      [
        this.routesService.currentRoute$,
        this.subbrandService.shouldSpecifySubbrand$,
      ],
    )
      .pipe(
        map<[string, boolean], AukDialogType[]>(([currentRoute, shouldSpecifySubbrand]) => {

          // Restrict these dialogs types on HP without specified subbrand
          if (currentRoute === '/' && shouldSpecifySubbrand) {
            return ['POPUP_FLOW', 'SIMPLE_ACTION', 'MARKETING'];
          }

          // Specify other cases when dialogs should be restricted here...

          return [];
        }),
      );
  }

}
