import { environment } from '@environment';
import { ICULocale } from '@shared/platform/icu-locale.model';

export class LocaleConstants {

  public static readonly CZ_LOCALE: ICULocale =
    { hrefLangLocale: 'cs-cz', country: 'CZ', language: 'cs', flag: 'flag-icon-czech-republic', label: 'CZ_LOCALE_LABEL' };
  public static readonly SK_LOCALE: ICULocale =
    { hrefLangLocale: 'sk-sk', country: 'SK', language: 'sk', flag: 'flag-icon-slovakia', label: 'SK_LOCALE_LABEL' };

  public static readonly LANGUAGE_HEADER_NAME: string = 'X-Accept-Language';

  public static readonly HREF_LANG_MAP: Map<ICULocale, string> = new Map([
    [this.CZ_LOCALE, environment.APP_HOST],
    [this.SK_LOCALE, environment.HOST_FRONTEND_SK],
  ]);

  public static readonly DOMAIN_LIST: ICULocale[] = [
    this.CZ_LOCALE,
    this.SK_LOCALE,
  ];

  public static readonly ROUTES_PREFFIX: string = 'ROUTES.';

  /**
   * Added via Nginx, see frontend ingress config
   */
  public static readonly NGINX_SSR_LANGUAGE_HEADER: string = 'X-Aukro-SSR-Language';

}

