import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Translate2Module } from '@common/translations/translate2.module';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { AukFormsModule } from '@common/auk-forms/auk-forms.module';
import { ReactiveFormsModule } from '@angular/forms';
import { Nil } from '@util/helper-types/nil';
import { TranslationSource } from '@common/translations/model/translation-source';
import { AnalyticsTrackingDirective } from '@common/tracking/directive/analytics-tracking.directive';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';

import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@Component({
  selector: 'auk-dialog-header',
  templateUrl: './dialog-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    Translate2Module,
    IconComponent,
    ReactiveFormsModule,
    AukFormsModule,
    AnalyticsTrackingDirective,
    ButtonComponent,
    InnerHtmlDirective,
  ],
})
export class DialogHeaderComponent implements OnChanges {

  @Input() public showCloseButton: boolean = true;
  @Input() public showDoneButton: boolean = false;
  @Input() public closeButtonId: string;
  @Input() public backgroundColor: 'SECONDARY' | 'SILVERSTEIN_100' = 'SECONDARY';
  @Input() public padding: 'MD' | 'LG' | 'NONE' = 'LG';
  @Input() public fontSize: 'LARGE' | 'MEDIUM' = 'LARGE';
  @Input() public v2: boolean;
  @Input() public dialogTitle: TranslationSource | Nil;
  @Input() public isHeaderOnOneRow: boolean = false;
  @Input() public isFloatingCloseButton: boolean = false;

  @Output() public closeClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() public confirmClick: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Combined Class from Inputs to render correct design applied with ngClass
   */
  protected containerDesignClass: string[] = [];

  public ngOnChanges(changes: AukSimpleChanges<DialogHeaderComponent>): void {
    if (changes.v2 || changes.padding || changes.backgroundColor) {
      this.generateDesignClass();
    }
  }

  private generateDesignClass(): void {
    if (this.v2) {
      this.containerDesignClass.push(...['tw-pr-4', '!tw-pb-0', 'tw-h-20', 'tw-justify-center', 'tw-items-center']);
    }
    if (this.padding === 'NONE') {
      this.containerDesignClass.push('tw-pl-0');
    }
    if (this.padding === 'LG') {
      this.containerDesignClass.push('tw-pl-8');
    }
    if (this.padding === 'MD') {
      this.containerDesignClass.push('tw-pl-4');
    }
    if (this.backgroundColor === 'SECONDARY') {
      this.containerDesignClass.push('tw-bg-secondary');
    }
    if (this.backgroundColor === 'SILVERSTEIN_100') {
      this.containerDesignClass.push('tw-bg-silverstein-100');
    }
  }

  protected onCloseClick(): void {
    this.closeClick.emit();
  }

  protected onDoneClick(): void {
    this.confirmClick.emit();
  }

}
