import isNil from 'lodash-es/isNil';
import { AukWindow } from '@shared/model/auk-window.interface';

export class CaptchaUtils {

  public static isCaptchaReady(window: AukWindow): boolean {
    return !isNil(window.grecaptcha);
  }

  public static isCaptchaIframeReady(document: Document): boolean {
    return !isNil(this.getCaptchaIframe(document));
  }

  public static getCaptchaIframe(document: Document): HTMLIFrameElement {
    return Array.from(document.getElementsByTagName('iframe'))
      .find(element => element.src.includes('google.com/recaptcha/api2/bframe'));
  }

}
