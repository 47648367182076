import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CookieService } from '@common/cookie/service/cookie.service';
import { finalize, take } from 'rxjs/operators';

import { ConsentService } from '@api/generated/api/Consent';
import { ConsentStanceBasicDto } from '@api/generated/defs/ConsentStanceBasicDto';
import { ConsentTermsDto } from '@api/generated/defs/ConsentTermsDto';
import { UserInterestStatisticsDto } from '@api/generated/defs/UserInterestStatisticsDto';
import { ConsentStanceBasicWithContent, ConsentStancesModalClose } from '../../../../../typings/original/internal';
import { AuthenticationService } from '@shared/authentication/service/authentication.service';
import { UserService } from '@shared/user/service/user.service';
import { WINDOW_OBJECT } from '@util/const/window-object';

@Component({
  selector: 'auk-consent-terms',
  templateUrl: './consent-terms.component.html',
  styleUrls: ['./consent-terms.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    ConsentService,
  ],
})
export class ConsentTermsComponent {

  public isOnlyView: boolean = false;
  public canSkip: boolean = false;
  public unresolvedStances: ConsentStanceBasicWithContent[] = [];
  public actualStatistics: UserInterestStatisticsDto = null;
  public currentStance: ConsentStanceBasicWithContent = null;
  protected saveStanceEndpointCalled: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data:
    { actualStatistics: UserInterestStatisticsDto; unresolvedStances: ConsentStanceBasicWithContent[]; isOnlyView: boolean },
    @Inject(WINDOW_OBJECT) private window: Window,
    private authService: AuthenticationService,
    private userService: UserService,
    private consentService: ConsentService,
    private cookieService: CookieService,
    private dialogRef: MatDialogRef<ConsentTermsComponent>) {

    this.actualStatistics = data.actualStatistics;
    this.unresolvedStances = data.unresolvedStances;
    this.isOnlyView = data.isOnlyView;
    if (this.isOnlyView) {
      this.currentStance = this.unresolvedStances.shift();
    } else {
      const consentTermsIds = this.unresolvedStances.map((item: ConsentStanceBasicDto) => item.consentTermsId);
      this.consentService.terms({ consentTermsIds }).pipe(
        take(1))
        .subscribe((consentTerms: ConsentTermsDto[]) => {
          consentTerms.forEach((item: ConsentTermsDto, index: number) => {
            this.unresolvedStances[index].content = item.body;
            this.unresolvedStances[index].subject = item.subject;
          });
          this.canSkip = true;
        });
    }
  }

  public skip(): void {
    if (!this.unresolvedStances.length) {
      this.closeModal();
    } else {
      this.currentStance = this.unresolvedStances.shift(); // get first stance from array
    }
  }

  public save(resolution: boolean): void {
    // prevent double call BE endpoint
    this.saveStanceEndpointCalled = true;
    this.consentService.stance({
      newConsentStanceDto: {
        consentTermsId: this.currentStance.consentTermsId,
        id: this.currentStance.id,
        stance: resolution,
        userId: this.actualStatistics.userId,
      },
    }).pipe(finalize(() => {
      this.saveStanceEndpointCalled = false;
    }))
      .subscribe(() => {
        this.skip();
      });
  }

  public logout(): void {
    this.authService.logout();
    this.closeModal();
  }

  public closeModal(): void {
    this.dialogRef.close({ closed: true, actualStatistics: this.actualStatistics } as ConsentStancesModalClose);
  }

}
