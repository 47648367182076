import { Injectable, Injector, Type } from '@angular/core';
import { UserTaskExecutorService } from './user-task-executor.service';
import { UserTaskLoginExecutorService } from '../../login/service/user-task-login-executor.service';
import { UserTaskType } from '../model/user-task.type';
import { UserTaskPayloadModel } from '../model/user-task-payload.model';
import { Observable, Subject } from 'rxjs';
import { UserTaskPersonalDataExecutorService } from '../../personal-data/service/user-task-personal-data-executor.service';
import { UserTaskAnalyticsDataModel } from '../model/user-task-analytics-data.model';
import { UserTaskVerifiedEmailExecutorService } from '../../verified-email/service/user-task-verified-email-executor.service';
import { UserTaskVerifiedPhoneExecutorService } from '../../verified-phone/service/user-task-verified-phone-executor.service';
import { UserActionType } from '@shared/user-action/model/user-action.type';
import { UserActionTaskPayloadService } from '@shared/user-action/service/user-action-task-payload.service';
import { mergeMap } from 'rxjs/operators';
import { Nil } from '@util/helper-types/nil';
import { UserTaskVerifiedBankAccountExecutorService } from '../../verified-bank-account/service/user-task-verified-bank-account-executor.service';

@Injectable({
  providedIn: 'root',
})
export class UserTaskService {

  private readonly taskTypeToExecutorMap: { [T in UserTaskType]: Type<UserTaskExecutorService<T>> } = {
    LOGIN: UserTaskLoginExecutorService,
    PERSONAL_DATA: UserTaskPersonalDataExecutorService,
    VERIFIED_EMAIL: UserTaskVerifiedEmailExecutorService,
    VERIFIED_PHONE: UserTaskVerifiedPhoneExecutorService,
    VERIFIED_BANK_ACCOUNT: UserTaskVerifiedBankAccountExecutorService,
  };

  private _taskExecutionStart$: Subject<void> = new Subject<void>();

  constructor(
    private readonly injector: Injector,
    private readonly userActionTaskPayloadService: UserActionTaskPayloadService,
  ) {
  }

  public taskExecutionStart$(): Observable<void> {
    return this._taskExecutionStart$.asObservable();
  }

  public executeTask$<TASK_TYPE extends UserTaskType>(
    type: TASK_TYPE,
    payload: UserTaskPayloadModel<TASK_TYPE>,
    analyticsData: UserTaskAnalyticsDataModel,
  ): Observable<void> {
    const executor: UserTaskExecutorService<TASK_TYPE> =
      this.injector.get<UserTaskExecutorService<TASK_TYPE>>(this.taskTypeToExecutorMap[type]);

    this._taskExecutionStart$.next();

    return executor.executeTask$(payload, analyticsData);
  }

  public executeTaskWithCommonPayload$<TASK_TYPE extends UserTaskType>(
    type: TASK_TYPE,
    analyticsData: UserTaskAnalyticsDataModel,
    actionType?: UserActionType,
  ): Observable<void> {
    return this.executeTaskWithCommonAndCustomPayload$(
      type,
      analyticsData,
      null,
      actionType,
    );
  }

  public executeTaskWithCommonAndCustomPayload$<TASK_TYPE extends UserTaskType>(
    type: TASK_TYPE,
    analyticsData: UserTaskAnalyticsDataModel,
    customPayload: Partial<UserTaskPayloadModel<TASK_TYPE>> | Nil,
    actionType?: UserActionType,
  ): Observable<void> {
    return this.userActionTaskPayloadService.resolvePayload$(type, actionType)
      .pipe(
        mergeMap((payload: UserTaskPayloadModel<TASK_TYPE>) =>
          this.executeTask$(
            type,
            {
              ...payload,
              ...customPayload,
            },
            analyticsData,
          ),
        ),
      );
  }

}
