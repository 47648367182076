import { Injectable } from '@angular/core';
import { RetailPricePercentageModel } from '@common/ui-kit/component/retail-price/model/retail-price-percentage.model';
import { ItemCardInfoRow } from '@common/ui-kit/component/item-card/component/basic-item-card/model/item-card-info-row.type';
import isNil from 'lodash-es/isNil';
import { isNotNil } from '@util/helper-functions/is-not-nil';
import { ItemCardPriceLabelModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/item-card-price-label.model';
import { TranslationSource } from '@common/translations/model/translation-source';
import { Nil } from '@util/helper-types/nil';
import { BasicItemCardWrapperPropagationInfoModel } from '@shared/basic-item-card-wrapper/model/basic-item-card-wrapper-propagation-info.model';
import { BasicItemCardItemMarkingModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-item-marking.model';
import { stringToTranslationSource } from '@util/helper-functions/string-to-translation-source';
import { ChipModel } from '@common/ui-kit/component/chip/model/chip.model';
import { ArrayUtils } from '@util/util/array.utils';
import { DELIVERY_TIME_ATTRIBUTE_ID, TAG_ATTRIBUTE_ID } from '../../../app.constants';
import { ObjectUtils } from '@util/util/object.utils';
import { BasicItemCardItemModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-item.model';
import { RetailPriceService } from '@shared/retail-price/service/retail-price.service';
import { SavingRateModel } from '@shared/retail-price/model/saving-rate.model';
import { AdultContentService } from '@shared/services/adult-content/adult-content.service';
import { BasicItemCardActionButton } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-action-button.model';
import { BasicItemCardSizeType } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-size.type';
import { ItemCardMapperItemModel } from '@shared/item-card/model/item-card-mapper-item.model';
import { BasicItemCardViewType } from '@common/ui-kit/component/item-card/component/basic-item-card/model/basic-item-card-view.type';
import { AdvancedItemCardModel } from '@common/ui-kit/component/item-card/component/advanced-item-card/model/advanced-item-card.model';
import { BasicItemCardAttributesModel } from '@common/ui-kit/component/item-card/component/advanced-item-card/model/advanced-item-card-attributes.model';
import { ItemCardConfig } from '@shared/item-card/model/item-card-config.model';
import { ItemAttributeGroupedValueDto } from '@api/aukro-api/model/item-attribute-grouped-value-dto';
import { EntityModelItemSearchItemItemStateEnum } from '@api/aukro-api/model/entity-model-item-search-item';
import moment, { Duration } from 'moment-mini-ts';
import { DatePipe, I18nPluralPipe } from '@angular/common';
import { DomainService } from '@shared/platform/domain.service';
import { TimeUtil } from '@common/ui-kit/common/time/class/time.util';
import { TimeService } from '@common/time/service/time.service';
import { ProductStateUtils } from '@shared/item-detail/service/mapper/base-item/utils/product-state.utils';
import { chipBackgroundColor } from '@common/ui-kit/component/chip/model/chip.helper';

export const STATE_OF_GOODS_ATTRIBUTE_ID = 48;
const BASIC_CARD_MAX_COUNT_OF_CHIPS_ON_VERTICAL_CARD = 1;
const BASIC_CARD_MAX_COUNT_OF_CHIPS_ON_HORIZONTAL_CARD = 2;
const ADVANCED_CARD_MAX_COUNT_OF_CHIPS = 3;

@Injectable({
  providedIn: 'root',
})
export class ItemCardMapperService {

  constructor(
    private readonly retailPriceService: RetailPriceService,
    private readonly adultContentService: AdultContentService,
    private readonly i18nPluralPipe: I18nPluralPipe,
    private readonly domainService: DomainService,
    private readonly timeService: TimeService,
    private readonly datePipe: DatePipe,
  ) {
  }

  public mapToBasicItemCardItem(
    mapperItem: ItemCardMapperItemModel,
    savingRatesModel: SavingRateModel,
    itemCardConfig: ItemCardConfig,
    itemCardSize: BasicItemCardSizeType,
    viewType: BasicItemCardViewType,
    showCharityChip: boolean,
  ): BasicItemCardItemModel {
    return {
      link: mapperItem.link,
      id: mapperItem.id,
      titleInfo: {
        title: {
          userValue: mapperItem.name,
        },
        isBold: mapperItem.propagationInfo?.hasBoldName,
        dynamicHeightOnMdAndLower: this.getDynamicTitleHeight(itemCardConfig, itemCardSize),
      },
      image: {
        itemImageUrl: mapperItem.mainImageUrl,
        name: mapperItem.name,
        isBlurred: this.adultContentService.shouldBlurItemImage(mapperItem.adultRequired),
      },
      followersInfo: {
        followersCount: mapperItem.watchingInfo?.watchersCount,
        isFollowed: mapperItem.watchingInfo?.isWatchedByCurrentUser,
        isLoading: mapperItem.watchingInfo?.isLoading,
      },
      actionButton: this.getActionButton(itemCardConfig, viewType),
      markingInfo: this.getMarkingInfo(mapperItem.propagationInfo),
      hasGoldBorder: mapperItem.propagationInfo?.hasHighlight ?? false,
      infoRowsList: this.getCardInfoRowsModel(
        mapperItem,
        this.retailPriceService.getRetailPrice(
          {
            price: mapperItem.priceInfo.price,
            itemType: mapperItem.itemType,
            buyNowActive: mapperItem.priceInfo.buyNowActive,
            buyNowPrice: mapperItem.priceInfo.buyNowPrice,
            retailPrice: mapperItem.priceInfo.retailPrice,
            categoryOrPath: mapperItem.categoryPath,
          },
          savingRatesModel,
          true,
        ),
      ),
      aboveTitleLabelsList: this.getAboveTitleLabelsList(mapperItem),
      chips: this.getChips(
        mapperItem,
        viewType === 'VERTICAL'
          ? BASIC_CARD_MAX_COUNT_OF_CHIPS_ON_VERTICAL_CARD
          : BASIC_CARD_MAX_COUNT_OF_CHIPS_ON_HORIZONTAL_CARD,
        showCharityChip,
      ),
      hasEnded: this.getItemHasEnded(mapperItem.state),
      sortScores: mapperItem.sortScores,
    };
  }

  public mapToAdvancedItemCardItem(
    mapperItem: ItemCardMapperItemModel,
    savingRatesModel: SavingRateModel,
    showCharityChip: boolean,
  ): AdvancedItemCardModel {
    return {
      id: mapperItem.id,
      link: mapperItem.link,
      actionButton: {
        label: {
          key: 'BASIC_ITEM_CARD_CTA',
        },
      },
      titleInfo: {
        title: {
          userValue: mapperItem.name,
        },
        isBold: mapperItem.propagationInfo?.hasBoldName,
      },
      image: {
        itemImageUrl: mapperItem.mainImageUrl,
        name: mapperItem.name,
        isBlurred: this.adultContentService.shouldBlurItemImage(mapperItem.adultRequired),
      },
      followersInfo: {
        followersCount: mapperItem.watchingInfo?.watchersCount,
        isFollowed: mapperItem.watchingInfo?.isWatchedByCurrentUser,
        isLoading: mapperItem.watchingInfo?.isLoading,
      },
      markingInfo: this.getMarkingInfo(mapperItem.propagationInfo),
      hasGoldBorder: mapperItem.propagationInfo?.hasHighlight ?? false,
      infoRowsList: this.getCardInfoRowsModel(
        mapperItem,
        this.retailPriceService.getRetailPrice(
          {
            price: mapperItem.priceInfo.price,
            itemType: mapperItem.itemType,
            buyNowActive: mapperItem.priceInfo.buyNowActive,
            buyNowPrice: mapperItem.priceInfo.buyNowPrice,
            retailPrice: mapperItem.priceInfo.retailPrice,
            categoryOrPath: mapperItem.categoryPath,
          },
          savingRatesModel,
          true,
        ),
      ),
      aboveTitleLabelsList: this.getAboveTitleLabelsList(mapperItem),
      chips: this.getChips(mapperItem, ADVANCED_CARD_MAX_COUNT_OF_CHIPS, showCharityChip),
      descriptionAttributeList: this.getDescriptionAttributes(mapperItem.attributes),
      sellerAttributeList: this.getSellerAttributes(mapperItem),
      sellerInfo: mapperItem.seller,
      hasEnded: this.getItemHasEnded(mapperItem.state),
    };
  }

  private getDescriptionAttributes(attributes: ItemAttributeGroupedValueDto[]): BasicItemCardAttributesModel[] | Nil {
    if (ArrayUtils.isEmpty(attributes)) {
      return null;
    }

    const excludedAttributeIds = [
      DELIVERY_TIME_ATTRIBUTE_ID,
      STATE_OF_GOODS_ATTRIBUTE_ID,
      TAG_ATTRIBUTE_ID,
    ];
    const filteredAttributes = attributes.filter(attr => !excludedAttributeIds.includes(attr.attributeId));

    if (!filteredAttributes.length) {
      return null;
    }

    const foundDescriptionAttributes: BasicItemCardAttributesModel[] = filteredAttributes
      .map((attr) => ({
        label: { defaultValue: attr.attributeName },
        value: attr.attributeValue,
      }));

    return foundDescriptionAttributes?.length > 0 ? foundDescriptionAttributes : null;
  }

  private getSellerAttributes(mapperItem: ItemCardMapperItemModel): BasicItemCardAttributesModel[] {
    const sellerAttributesList: BasicItemCardAttributesModel[] = [];

    /**
     * Add location if exists
     */
    if (mapperItem.location) {
      sellerAttributesList.push({
        label: { key: 'ADVANCED_ITEM_CARD_LOCATION_LABEL' },
        value: mapperItem.location,
        type: 'LOCATION',
      });
    }

    /**
     * Add delivery time attribute if exists
     */
    const deliveryTimeValue = this.getSellerAttributeDeliveryTime(mapperItem.attributes);
    if (deliveryTimeValue) {
      sellerAttributesList.push({
        label: { key: 'ADVANCED_ITEM_CARD_DELIVERY_TIME_LABEL' },
        value: deliveryTimeValue,
        type: 'DELIVERY_TIME',
      });
    }

    return sellerAttributesList;
  }

  private getSellerAttributeDeliveryTime(attributes: ItemAttributeGroupedValueDto[]): string | Nil {
    if (ArrayUtils.isEmpty(attributes)) {
      return null;
    }

    const sellerAttributeDeliveryTime: ItemAttributeGroupedValueDto = attributes
      .find((attr) => attr.attributeId === DELIVERY_TIME_ATTRIBUTE_ID);

    return isNil(sellerAttributeDeliveryTime)
      ? null
      : sellerAttributeDeliveryTime.attributeValue;
  }

  private getDynamicTitleHeight(itemCardConfig: ItemCardConfig, itemCardSize: BasicItemCardSizeType): boolean {
    if (isNil(itemCardConfig)) {
      return false;
    }

    return (
      (itemCardSize === 'SMALL' && itemCardConfig.isDynamicTitleHeightActiveOnSmallBasicItemCard) ||
      (itemCardSize === 'DEFAULT' && itemCardConfig.isDynamicTitleHeightActiveOnDefaultBasicItemCard)
    );
  }

  private getActionButton(
    itemCardConfig: ItemCardConfig,
    viewType: BasicItemCardViewType,
  ): BasicItemCardActionButton | Nil {
    if (isNil(itemCardConfig)) {
      return null;
    }

    if (
      (viewType === 'HORIZONTAL' && itemCardConfig.showCtaOnHorizontalView)
      || (viewType === 'VERTICAL' && itemCardConfig.showCtaOnVerticalView)
    ) {
      return {
        label: {
          key: 'BASIC_ITEM_CARD_CTA',
        },
      };
    }

    return null;
  }

  private getCardInfoRowsModel(
    mapperItem: ItemCardMapperItemModel,
    calculatedRetailPrice: RetailPricePercentageModel,
  ): ItemCardInfoRow[] {
    const infoRows: ItemCardInfoRow[] = [];
    const itemHasEnded: boolean = this.getItemHasEnded(mapperItem.state);

    infoRows.push({
      type: 'PRICE',
      label: this.getPriceLabel({
        isActionRequired: mapperItem.itemType === 'BIDDING',
        participantsCount: mapperItem.biddersCount,
        soldQuantity: mapperItem.buyersCount,
      }, itemHasEnded),
      priceItemData: {
        price: mapperItem.itemType === 'BIDDING' ? mapperItem.priceInfo?.price : mapperItem.priceInfo?.buyNowPrice,
        withBadge: mapperItem.buyersProtectionAvailable,
      },
    });

    if (!itemHasEnded && (!isNil(calculatedRetailPrice) && !ObjectUtils.isObjectEmpty(calculatedRetailPrice))) {
      infoRows.push({
        type: 'RETAIL_PRICE',
        label: {
          key: 'COMMON__RETAIL_PRICE_PREFIX',
        },
        retailPriceItemData: calculatedRetailPrice,
      });
    }

    // BUY_NOW price on BIDDING item, checks also bidders count === 0 for sure
    if (!itemHasEnded && mapperItem.itemType === 'BIDDING'
      && mapperItem.priceInfo?.buyNowActive && mapperItem?.biddersCount === 0
      && mapperItem.priceInfo?.buyNowPrice?.amount > 0) {
      infoRows.push({
        type: 'CURRENCY',
        label: {
          key: 'BASIC_ITEM_CARD_BUY_NOW',
        },
        price: mapperItem.priceInfo?.buyNowPrice,
      });
    }

    if (isNotNil(mapperItem.collectionStartingTime) && !ProductStateUtils.isEnded(mapperItem)) {
      if (moment().isAfter(mapperItem.collectionStartingTime)) {
        // In collection and bidding is ongoing right now
        infoRows.push({
          type: 'TEXT',
          value: { key: 'BASIC_ITEM_CARD_LIVE_BIDDING_LABEL' },
        });

      } else {
        const isMoreThanOneDay: boolean = moment().diff(mapperItem.collectionStartingTime, 'days') < 0;

        if (isMoreThanOneDay) {
          // In collection which starts in more than one day
          infoRows.push({
            type: 'TEXT',
            label: { key: 'BASIC_ITEM_CARD_START_AT_LABEL' },
            value: { defaultValue: TimeUtil.getFormattedTime(this.datePipe, mapperItem.collectionStartingTime, 'd. M. yyyy, HH:mm') },
          });
        } else {
          const remainingTime: Duration = this.timeService.getRemainingTime(moment(mapperItem.collectionStartingTime));
          const displayTime: string = TimeUtil.getHumanReadableRemainingTime(remainingTime, this.i18nPluralPipe, this.domainService.lang);
          // In collection which starts in less than one day
          infoRows.push({
            type: 'TEXT',
            label: { key: 'BASIC_ITEM_CARD_COUNTDOWN_TO_START_LABEL' },
            value: { defaultValue: displayTime },
          });
        }
      }

    } else if (isNotNil(mapperItem.endingTime)) {
      // Not in collection or in collection and ended
      infoRows.push({
        type: 'COUNTDOWN',
        label: itemHasEnded
          ? null
          : { key: 'BASIC_ITEM_CARD_COUNTDOWN_LABEL' },
        timeToEnd: itemHasEnded
          ? null
          : mapperItem.endingTime,
      });
    }

    return infoRows;
  }

  private getPriceLabel(priceLabel: ItemCardPriceLabelModel, hasEnded: boolean): TranslationSource | Nil {
    // AUCTION
    if (priceLabel.isActionRequired) {
      if (isNil(priceLabel.participantsCount)) {
        return {
          key: 'ITEM_INFO_TYPE_AUCTION',
        };
      }

      return priceLabel.participantsCount > 0
        ? {
          key: hasEnded ? 'AUCTION_BIDDING_ENDED_PRICE_TITLE' : 'AUCTION_BIDDING_PRICE_TITLE',
          params: { biddersCount: priceLabel.participantsCount },
        }
        : {
          key: 'ITEM_NO_BIDDERS_LABEL',
        };
    }
    // BUY_NOW
    return priceLabel.soldQuantity > 0
      ? {
        key: 'ITEM_SOLD_QUANTITY_BUY_NOW_LABEL',
        params: { soldQuantity: String(priceLabel.soldQuantity) },
      }
      : {
        key: 'ITEM_CARD_BUY_NOW',
      };
  }

  private getMarkingInfo(
    propagationInfo: BasicItemCardWrapperPropagationInfoModel | Nil,
  ): BasicItemCardItemMarkingModel | Nil {
    if (propagationInfo?.hasHighlight) {
      return {
        type: 'TOP',
        colorCombination: 'VIP',
        icon: {
          source: 'aukro-pricetag',
          type: 'SVG',
        },
      };
    }

    if (propagationInfo?.pepperLevel) {
      return {
        type: 'HOT',
        colorCombination: 'DANGER',
        icon: {
          source: 'chilli2-unicolor',
          type: 'SVG',
        },
      };
    }
  }

  private getAboveTitleLabelsList(item: ItemCardMapperItemModel): TranslationSource[] {
    const stateOfGoodsAttribute = item.attributes
      ?.find((attribute) => attribute.attributeId === STATE_OF_GOODS_ATTRIBUTE_ID);

    if (isNil(stateOfGoodsAttribute)) {
      return [];
    }

    return [stringToTranslationSource(stateOfGoodsAttribute.attributeValue)];
  }

  public getItemHasEnded(mapperItemState: EntityModelItemSearchItemItemStateEnum): boolean {
    return mapperItemState === 'ENDED';
  }

  private getChips(
    item: ItemCardMapperItemModel,
    maxChipsCount: number,
    showCharityChip: boolean,
  ): ChipModel[] {
    const chips: ChipModel[] = [];

    // Try to get first user chip from item attributes according to specific attribute id
    this.addAttributeChips(item, chips, maxChipsCount);
    this.addCharityChip(showCharityChip, chips, maxChipsCount);
    this.addTopSellerChip(item.isTopSeller, chips, maxChipsCount);

    return chips.slice(0, maxChipsCount);
  }

  private addAttributeChips(item: ItemCardMapperItemModel, chips: ChipModel[], maxChipsCount: number): void {
    if (ArrayUtils.isNotEmpty(item.attributes)) {
      const chipFromAttributesList: ItemAttributeGroupedValueDto[] = item.attributes
        .filter((attr) => {
          if (isNil(attr)) {
            return false;
          }
          return attr.attributeId === TAG_ATTRIBUTE_ID;
        });

      for (const chipFromAttribute of chipFromAttributesList) {
        if (chips.length >= maxChipsCount) {
          break;
        }

        chips.push({
          type: item.itemsCollection ? 'TEXT_WITH_COUNT' : 'TEXT', // TODO: use 'TEXT_WITH_COUNT' only for collection attribute
          label: {
            defaultValue: chipFromAttribute.attributeValue,
          },
          tooltip: {
            defaultValue: chipFromAttribute.valueTooltip,
          },
          backgroundColor: chipFromAttribute.additionalProperties.backgroundColor,
          count: item.itemsCollection?.position,
        });
      }
    }
  }

  private addCharityChip(showCharityChip: boolean, chips: ChipModel[], maxChipsCount: number): void {
    if (showCharityChip && chips.length < maxChipsCount) {
      // if chips is empty, the charity chip will be the first one, otherwise always the second one
      const indexOfCharityChip: number = ArrayUtils.isEmpty(chips) ? 0 : 1;

      chips.splice(indexOfCharityChip, 0, {
        type: 'TEXT',
        backgroundColor: chipBackgroundColor.charity,
        label: {
          key: 'CHARITY_AUCTIONS_TAG',
        },
        tooltip: {
          key: 'CHARITY_AUCTIONS_TAG_TOOLTIP',
        },
      });
    }
  }

  private addTopSellerChip(isTopSeller: boolean, chips: ChipModel[], maxChipsCount: number): void {
    if (isTopSeller && chips.length < maxChipsCount) {
      chips.push({
        type: 'TEXT',
        icon: {
          source: 'aukro-crown',
          type: 'SVG',
          colorCombination: 'PRIMARY',
        },
        colorCombination: 'PRIMARY',
        label: {
          key: 'AUKRO_TOP_SELLER',
        },
        tooltip: {
          key: 'TOP_SELLER_ICON_TOOLTIP',
        },
      });
    }
  }

}
