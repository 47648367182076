import { UserTaskExecutorService } from '../../base/service/user-task-executor.service';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';
import { UserTaskStepDefinitionModel } from '../../base/model/user-task-step-definition.model';
import { UserTaskStepType } from '../../base/model/user-task-step.type';
import { UserTaskPersonalDataStepUserBasicDataComponent } from '../component/user-task-personal-data-step-user-basic-data.component';
import { Injectable, Injector } from '@angular/core';
import { UserTaskPersonalDataStepUserBasicDataResolutionHandlerService } from './user-task-personal-data-step-user-basic-data-resolution-handler.service';
import { UserTaskPersonalDataStepPhoneVerifiedNoEmailVerifiedResolutionHandlerService } from './user-task-personal-data-step-phone-verified-no-email-verified-resolution-handler.service';
import { UserTaskPersonalDataStepPhoneVerifiedNoEmailVerifiedComponent } from '../component/user-task-personal-data-step-phone-verified-no-email-verified.component';
import { UserTaskPersonalDataStepNoPhoneVerifiedNoEmailVerifiedComponent } from '../component/user-task-personal-data-step-no-phone-verified-no-email-verified.component';
import { UserTaskPersonalDataStepNoPhoneVerifiedNoEmailVerifiedResolutionHandlerService } from './user-task-personal-data-step-no-phone-verified-no-email-verified-resolution-handler.service';
import { UserTaskPersonalDataStepPhoneVerifiedEmailVerifiedComponent } from '../component/user-task-personal-data-step-phone-verified-email-verified.component';
import { UserTaskPersonalDataStepPhoneVerifiedEmailVerifiedResolutionHandlerService } from './user-task-personal-data-step-phone-verified-email-verified-resolution-handler.service';
import { UserTaskPersonalDataStepNoPhoneVerifiedEmailVerifiedComponent } from '../component/user-task-personal-data-step-no-phone-verified-email-verified.component';
import { UserTaskPersonalDataStepNoPhoneVerifiedEmailVerifiedResolutionHandlerService } from './user-task-personal-data-step-no-phone-verified-email-verified-resolution-handler.service';
import { ModalViewsConfigService } from '../../base/service/modal-views-config.service';
import { UserService } from '@shared/user/service/user.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PopupFlowGaService } from '@shared/popup-flow/service/popup-flow-ga.service';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';

@Injectable({
  providedIn: 'root',
})
export class UserTaskPersonalDataExecutorService extends UserTaskExecutorService<'PERSONAL_DATA'> {

  protected steps: { [STEP_TYPE in UserTaskStepType<'PERSONAL_DATA'>]: UserTaskStepDefinitionModel<'PERSONAL_DATA', STEP_TYPE> } = {
    USER_BASIC_DATA: {
      component: UserTaskPersonalDataStepUserBasicDataComponent,
      resolutionHandler: UserTaskPersonalDataStepUserBasicDataResolutionHandlerService,
    },
    PHONE_VERIFIED_NO_EMAIL_VERIFIED: {
      component: UserTaskPersonalDataStepPhoneVerifiedNoEmailVerifiedComponent,
      resolutionHandler: UserTaskPersonalDataStepPhoneVerifiedNoEmailVerifiedResolutionHandlerService,
    },
    NO_PHONE_VERIFIED_NO_EMAIL_VERIFIED: {
      component: UserTaskPersonalDataStepNoPhoneVerifiedNoEmailVerifiedComponent,
      resolutionHandler: UserTaskPersonalDataStepNoPhoneVerifiedNoEmailVerifiedResolutionHandlerService,
    },
    PHONE_VERIFIED_EMAIL_VERIFIED: {
      component: UserTaskPersonalDataStepPhoneVerifiedEmailVerifiedComponent,
      resolutionHandler: UserTaskPersonalDataStepPhoneVerifiedEmailVerifiedResolutionHandlerService,
    },
    NO_PHONE_VERIFIED_EMAIL_VERIFIED: {
      component: UserTaskPersonalDataStepNoPhoneVerifiedEmailVerifiedComponent,
      resolutionHandler: UserTaskPersonalDataStepNoPhoneVerifiedEmailVerifiedResolutionHandlerService,
    },
  };

  protected taskType: 'PERSONAL_DATA' = 'PERSONAL_DATA' as const;

  constructor(
    injector: Injector,
    modalViewsConfigService: ModalViewsConfigService,
    popupFlowGaService: PopupFlowGaService,
    private readonly userService: UserService,
    aukMatDialogService: AukMatDialogService,
  ) {
    super(injector, modalViewsConfigService, popupFlowGaService, aukMatDialogService);
  }

  protected getFirstStep$(payload: UserTaskPayloadModel<'PERSONAL_DATA'>): Observable<UserTaskStepUnionModel<'PERSONAL_DATA'>> {
    if (payload.skipTo === 'CONFIRMATION') {
      return this.userService.getActualStatistics()
        .pipe(
          map(stats => this.getConfirmationStep(payload, !stats.phoneVerificationRequired, stats.emailVerified)),
        );
    }

    return of({
      type: 'USER_BASIC_DATA',
      payload: {
        ...payload?.stepsText?.USER_BASIC_DATA,
        enforceVerifyPhone: payload?.enforceVerifyPhone,
      },
    });
  }

  private getConfirmationStep(
    payload: UserTaskPayloadModel<'PERSONAL_DATA'>,
    phoneVerified: boolean,
    emailVerified: boolean,
  ): UserTaskStepUnionModel<'PERSONAL_DATA'> {
    if (!emailVerified && phoneVerified) {
      return {
        type: 'PHONE_VERIFIED_NO_EMAIL_VERIFIED',
        payload: {
          ...payload?.stepsText?.PHONE_VERIFIED_NO_EMAIL_VERIFIED,
        },
      };
    }

    if (!emailVerified && !phoneVerified) {
      return {
        type: 'NO_PHONE_VERIFIED_NO_EMAIL_VERIFIED',
        payload: {
          ...payload?.stepsText?.NO_PHONE_VERIFIED_NO_EMAIL_VERIFIED,
        },
      };
    }

    if (emailVerified && phoneVerified) {
      return {
        type: 'PHONE_VERIFIED_EMAIL_VERIFIED',
        payload: {
          ...payload?.stepsText?.PHONE_VERIFIED_EMAIL_VERIFIED,
        },
      };
    }

    if (emailVerified && !phoneVerified) {
      return {
        type: 'NO_PHONE_VERIFIED_EMAIL_VERIFIED',
        payload: {
          ...payload?.stepsText?.NO_PHONE_VERIFIED_EMAIL_VERIFIED,
        },
      };
    }
  }

}
