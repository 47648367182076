import isNil from 'lodash-es/isNil';
import isPlainObject from 'lodash-es/isPlainObject';
import { CacheEntry } from '../model/cache-entry';
import { CacheModel } from '../model/cache.model';

/**
 * Used as a general purpose cache in memory.
 */
export class MemoryCache<V> implements CacheModel<string, V> {

  private cacheMap: Map<string, CacheEntry<V>> = new Map();

  public get(key: string): V {
    const entry = this.cacheMap.get(key);

    if (!this.isEntryValid(entry)) {
      return null;
    }

    return entry.value;
  }

  public set(key: string, value: V, timeToLive: number = Infinity): void {
    const expires = new Date().getTime() + timeToLive;

    this.cacheMap.set(key, { expires, value });
  }

  public has(key: string): boolean {
    return this.cacheMap.has(key) && this.isEntryValid(this.cacheMap.get(key));
  }

  public delete(key: string): boolean {
    return this.cacheMap.delete(key);
  }

  public keys(): IterableIterator<string> {
    return this.cacheMap.keys();
  }

  private isEntryValid(entry: CacheEntry<V>): boolean {
    return !isNil(entry) && !this.isEntryExpired(entry);
  }

  private isEntryExpired(entry: CacheEntry<unknown>): boolean {
    return new Date().getTime() > entry.expires;
  }

  public static fromObject<V>(obj: Record<string, V>): MemoryCache<V> {
    const cache: MemoryCache<V> = new MemoryCache();

    if (!isPlainObject(obj)) {
      return cache;
    }

    Object.keys(obj).forEach((key) => cache.set(key, obj[key]));

    return cache;
  }

}
