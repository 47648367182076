import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import { ColorCombinationStateComponent } from '@common/colors/component/color-combination-state/color-combination-state.component';
import { ToastComponentConfig } from '../../model/toast-component.config';

@Component({
  template: '',
})
export abstract class BaseToastComponent<TState = unknown> extends ColorCombinationStateComponent<TState> {

  /**
   * Used to close & destroy toast page
   */
  @Output() public closeToast: EventEmitter<void> = new EventEmitter<void>();

  protected constructor(
    elementRef: ElementRef<HTMLElement>,
  ) {
    super(elementRef);
  }

  /**
   * Called upon toast page creation
   * Used to process config
   */
  public abstract init(toastConfig: ToastComponentConfig): void;

}
