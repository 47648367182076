import isNil from 'lodash-es/isNil';
import { ArrayUtils } from '@util/util/array.utils';
import { CurrenciesCodeArr, CurrencyCode } from '@shared/currency/model/currency-code.model';
import { StringUtils } from '@util/util/string.utils';
import { CurrencyConfigElementEnumModel } from '@shared/currency/model/currency-config-element-enum.model';
import { CurrencyModelMap } from '@shared/currency/model/currency-model-map.type';
import { LocaleConstants } from '@shared/platform/locale.constants';
import { ICULocale } from '@shared/platform/icu-locale.model';
import { NumberType } from '@common/ui-kit/component/input/component/model/number-type';

export class CurrencyUtil {

  public static isValidCurrencyCode(val: string): boolean {
    if (isNil(val)) {
      return false;
    }
    return !isNil(CurrenciesCodeArr.find((validName) => validName === val));
  }

  public static codeToSuffix(currencyCode: CurrencyCode | string): string {
    if (StringUtils.isBlank(currencyCode)) {
      return '';
    }

    if (currencyCode === 'EUR') {
      return '€';
    } else if (currencyCode === 'CZK') {
      return 'Kč';
    }

    return currencyCode;
  }

  public static currenciesModelArrToMap(currencies: CurrencyConfigElementEnumModel[]): CurrencyModelMap {
    if (ArrayUtils.isEmpty(currencies)) {
      throw new Error(`Can't create currency model map. Empty model array provided`);
    }

    const map: CurrencyModelMap = {
      CZK: undefined,
      EUR: undefined,
    };

    currencies.forEach(val => map[val.code] = val);

    for (const key in map) {
      if (isNil(key)) {
        throw new Error(`Can't create currency model map. Nil provided for currency ${ key }`);
      }
    }

    return map;
  }

  public static getDefaultCurrencyByLocale(locale: ICULocale): CurrencyCode {
    if (isNil(locale) || locale === LocaleConstants.CZ_LOCALE) {
      return 'CZK';
    }

    return 'EUR';
  }

  public static getNumberTypeForCurrency(currency: CurrencyCode): NumberType {
    if (currency === 'EUR') {
      return 'FLOAT';
    }
    return 'INTEGER';
  }

}
