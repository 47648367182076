import { Injectable } from '@angular/core';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';
import { UserActionPrerequisitePersonalDataFulfillmentService } from './user-action-prerequisite-personal-data-fulfillment.service';
import { UserTaskPayloadModel } from '@shared/user-task/base/model/user-task-payload.model';
import { UserActionType } from '../../model/user-action.type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserActionPrerequisitePersonalDataAndVerifyEmailAndOptionalVerifyPhoneFulfillmentService
  extends UserActionPrerequisitePersonalDataFulfillmentService<['FILLED_PERSONAL_DATA', 'VERIFIED_EMAIL']> {

  public readonly prerequisiteCombination: ['FILLED_PERSONAL_DATA', 'VERIFIED_EMAIL'] =
    ['FILLED_PERSONAL_DATA', 'VERIFIED_EMAIL'];

  protected readonly customTaskPayload: Partial<UserTaskPayloadModel<typeof this.taskType>> = {
    enforceVerifyPhone: false,
  };

  constructor(
    userTaskService: UserTaskService,
  ) {
    super(userTaskService);
  }

  public override fulfillPrerequisite(userAction: UserActionType): Observable<never> {
    return super.fulfillPrerequisite(userAction)
      .pipe(
        map(() => {
          throw new Error(`Can't verify email in browser (user needs to do it by himself)`);
        }),
      );
  }

}
