<div class="tw-border tw-block tw-relative">
  <select
    #categorySelect
    [ngModel]="selectedCategory"
    [style.width.px]="selectWidth"
    class="select"
    [ngClass]="{ 'tw-text-right tw-pr-8': !fitToContent, 'size-by-content': fitToContent }"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="onCategorySelected($event)"
    >
    @for (category of categories; track trackByCategoryId($index, category)) {
      <option
        [ngValue]="category"
        [selected]="selectedCategory === category"
        [disabled]="category?.disabled"
        >
        @if (category?.htmlName) {
          {{ category.htmlName | translateSource }}
        }
      </option>
    }
  </select>
</div>
