import { Injectable, Injector } from '@angular/core';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import { Observable } from 'rxjs';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root',
})
export class TranslationService extends RestHttpClientService {

  constructor(injector: Injector) {
    super(injector);
  }
/**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public getAllTranslations(): Observable<model.TranslationValueDto[]> {
    return this.get(`/translation`);
  }
}
