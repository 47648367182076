import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UserTaskLoginStepComponent } from './user-task-login-step.component';
import { LoginVM } from '@api/generated/defs/LoginVM';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { DialogAlert } from '@common/dialog-wrapper/dialog-alert';
import { ErrorsService } from '@shared/services/errors/errors.service';
import { AuthenticateWrapperComponent } from '@shared/legacy/component/authenticate-wrapper/component/authenticate-wrapper.component';
import isNil from 'lodash-es/isNil';
import { PlatformService } from '@shared/platform/service/platform.service';
import { HttpError } from '@shared/rest/model/http-error';
import { DomainUtils } from '@shared/platform/domain.utils';
import { DomainService } from '@shared/platform/domain.service';
import { Nil } from '@util/helper-types/nil';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { DomainCode } from '@shared/platform/domain.model';
import { TranslationSource } from '@common/translations/model/translation-source';
import { BiometryService } from '@common/biometry/service/biometry.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Component({
  selector: 'auk-user-task-login-step-password-or-third-party',
  templateUrl: 'user-task-login-step-password-or-third-party.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskLoginStepPasswordOrThirdPartyComponent
  extends UserTaskLoginStepComponent<'PASSWORD_OR_THIRD_PARTY'>
  implements OnInit {

  @ViewChild(forwardRef(() => AuthenticateWrapperComponent), { static: false })
  public authenticateWrapperComponent: AuthenticateWrapperComponent;

  public isSubmitting: boolean = false;

  public dialogAlerts: DialogAlert[] = [];

  public isNativeIosApp: boolean = PlatformCommonService.isNativeIosApp;

  public regDomainCode: DomainCode | Nil = null;

  constructor(
    private readonly platformService: PlatformService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly errorsService: ErrorsService,
    private readonly domainService: DomainService,
    private readonly biometryService: BiometryService,
    @Inject(WINDOW_OBJECT) public readonly window: Window,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.regDomainCode = this.payload?.regDomainCode;
    // on step init, show dialog alerts from payload
    this.dialogAlerts = this.payload?.dialogAlerts;
  }

  public onSubmit(password: string): void {
    if (isNil(password) || this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    const loginVM: LoginVM = {
      username: this.payload.email,
      password,
    };

    this.authenticateWrapperComponent.doAuthenticate(loginVM)
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
          this.changeDetectorRef.markForCheck();
        }),
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe({
        next: () => {
          if (PlatformCommonService.isNativeApp) {
            void this.biometryService.saveCreditials(this.payload.email, password);
          }
          this.resolve.emit({
            type: 'RESOLUTION_SUCCESS',
            payload: {},
          });
        },
        error: (httpError: HttpError) => {
          if (httpError.code === 401) {
            this.dialogAlerts = [{ type: 'DANGER', text: { key: 'LOGIN_PAGE_AUTH_ERR_MESSAGE' } }];
            return;
          } else if (this.errorsService.isPasswordChangeRequired(httpError)) {
            this.resolve.emit({
              type: 'RESOLUTION_PASSWORD_CHANGE_REQUIRED',
              payload: {},
            });
            return;
          } else if (this.errorsService.isCrossDomainNotAllowed(httpError)) {
            this.regDomainCode = DomainUtils.resolveDomainCodeFromError(httpError);
            this.dialogAlerts = [{
              type: 'WARNING', text: {
                key: 'CROSS_DOMAIN_SING_IN_NOT_ALLOWED_OLD_LOGIN_PAGE',
                params: {
                  regDomainUrl: this.platformService.getDomainUrl(this.regDomainCode),
                  regDomain: this.platformService.getDomainHostUpperCaseCapitalLetter(this.regDomainCode),
                  actualDomain: this.platformService.getDomainHostUpperCaseCapitalLetter(this.domainService.domain),
                },
              },
            }];
            throw httpError;
          }
          this.dialogAlerts = [{ type: 'DANGER', text: { key: 'LOGIN_PAGE_OVERALL_ERR_MESSAGE' } }];
          throw httpError;
        },
      });
  }

  /**
   * Need to close modal if user is redirected
   */
  public onSubmitRedirect(registration: boolean): void {
    this.resolve.emit({
      type: 'RESOLUTION_REDIRECT',
      payload: {
        isRegistration: registration,
        redirectDomain: this.regDomainCode,
      },
    });
  }

  public onPasswordTyping(): void {
    this.dialogAlerts = [];
  }

  public onBackClick(): void {
    this.resolve.emit({
      type: 'RESOLUTION_LOGIN_EMAIL',
      payload: {
        sourceAction: this.payload.sourceAction,
      },
    });
  }

  public onForgotPasswordClick(): void {
    this.resolve.emit({
      type: 'RESOLUTION_FORGOT_PASSWORD',
      payload: {},
    });
  }

  public loginViaFB(): void {
    this.resolve.emit({
      type: 'RESOLUTION_FB_LOAD',
      payload: {},
    });
  }

  protected get regDomainCrossDomainTranslationSource(): TranslationSource {
    if (!isNil(this.regDomainCode)) {
      return {
        key: 'LOGIN_CROSS_DOMAIN_REDIRECT', params: {
          regDomain: this.platformService.getDomainHostUpperCaseCapitalLetter(this.regDomainCode),
        },
      };
    }
  }

  public get showDivider(): boolean {
    return this.payload.allowedLoginMethods?.includes('EMAIL') && this.payload.allowedLoginMethods.length > 1;
  }

  public get showThirdPartyLoginButtons(): boolean {
    return this.payload.allowedLoginMethods?.includes('FACEBOOK')
      || this.payload.allowedLoginMethods?.includes('GOOGLE');
  }

}
