import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UserTaskLoginStepComponent } from './user-task-login-step.component';
import { PlatformService } from '@shared/platform/service/platform.service';

@Component({
  selector: 'auk-user-task-login-step-facebook-authorize-failed',
  templateUrl: 'user-task-login-step-facebook-authorize-failed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTaskLoginStepFacebookAuthorizeFailedComponent
  extends UserTaskLoginStepComponent<'FACEBOOK_AUTHORIZE_FAILED'>
  implements OnInit {

  public regDomainHost: string = null;

  constructor(private readonly platformService: PlatformService) {
    super();
  }

  public ngOnInit(): void {
    this.regDomainHost = this.platformService.getDomainHostUpperCaseCapitalLetter(this.payload?.regDomainCode);
  }

  public onBackClick(): void {
    this.resolve.emit({
      type: 'RESOLUTION_LOGIN_EMAIL',
      payload: {
        sourceAction: this.payload.sourceAction,
      },
    });
  }

  /**
   * Emit event with cross domain validation problem
   * Possible solution is emitted by parameter
   */
  public crossDomainRedirect(registration: boolean): void {
    this.resolve.emit({
      type: 'RESOLUTION_REDIRECT',
      payload: {
        isRegistration: registration,
        redirectDomain: this.payload.regDomainCode,
      },
    });
  }

  public onCancelClick(): void {
    this.resolve.emit({
      type: 'RESOLUTION_CANCEL',
      payload: {},
    });
  }

}
