import { NgModule } from '@angular/core';
import { UserTaskPersonalDataStepUserBasicDataComponent } from './component/user-task-personal-data-step-user-basic-data.component';
import { UserTaskBaseModule } from '../base/user-task-base.module';
import {
  TwoFactorVerificationModule,
} from '@shared/two-factor-verification/two-factor-verification.module';
import {
  BasicRegistrationFormModule,
} from '@shared/registration/module/basic-registration-form/basic-registration-form.module';
import {
  UserTaskPersonalDataStepPhoneVerifiedNoEmailVerifiedComponent,
} from './component/user-task-personal-data-step-phone-verified-no-email-verified.component';
import {
  UserTaskPersonalDataStepNoPhoneVerifiedNoEmailVerifiedComponent,
} from './component/user-task-personal-data-step-no-phone-verified-no-email-verified.component';
import {
  UserTaskPersonalDataStepPhoneVerifiedEmailVerifiedComponent,
} from './component/user-task-personal-data-step-phone-verified-email-verified.component';
import {
  UserTaskPersonalDataStepNoPhoneVerifiedEmailVerifiedComponent,
} from './component/user-task-personal-data-step-no-phone-verified-email-verified.component';
import { DialogWrapperModule } from '@common/dialog-wrapper/dialog-wrapper.module';
import { Translate2Module } from '@common/translations/translate2.module';

import { CommonModule } from '@angular/common';
import { UserTaskPersonalDataStepCloseOrSetPasswordComponent } from './component/user-task-personal-data-step-close-or-set-password.component';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    UserTaskBaseModule,
    TwoFactorVerificationModule,
    BasicRegistrationFormModule,
    DialogWrapperModule,
    Translate2Module,

    ButtonComponent,
    CommonModule,
    InnerHtmlDirective,
  ],
  declarations: [
    UserTaskPersonalDataStepUserBasicDataComponent,
    UserTaskPersonalDataStepPhoneVerifiedNoEmailVerifiedComponent,
    UserTaskPersonalDataStepNoPhoneVerifiedNoEmailVerifiedComponent,
    UserTaskPersonalDataStepPhoneVerifiedEmailVerifiedComponent,
    UserTaskPersonalDataStepNoPhoneVerifiedEmailVerifiedComponent,
    UserTaskPersonalDataStepCloseOrSetPasswordComponent,
  ],
})
export class UserTaskPersonalDataModule {}
