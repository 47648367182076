import { AukError } from '@common/error/domain/auk-error';
import isObject from 'lodash-es/isObject';
import { StringUtils } from '@util/util/string.utils';

const IGNORED_MESSAGES: string[] = [
  'Non-Error promise rejection captured with value: Timeout',
  'Uncaught (in promise): Timeout',
  'QuotaExceededError', // full storage
  'Permission denied to access property',  // external scripts
  'ResizeObserver loop limit exceeded', // unimportant error, no impact
  'SecurityError', // external scripts or strict browser settings
  // unnecessary error, which is telling that view transition was skipped
  'Skipping view transition',
];

export class ErrorUtil {

  public static canLogError(
    error: AukError | unknown,
    /**
     * Whether ignored errors by messages content should be allowed to log
     */
    allowIgnoredErrors: boolean,
  ): boolean {
    if (
      // don't log invalid value
      !isObject(error)
      // don't log explicitly ignored errors
      || (error instanceof AukError && !error.logError)
    ) {
      return false;
    }

    // if we are allowing ignored errors by message or if error is not instance of Error class, allow it
    if (allowIgnoredErrors || !(error instanceof Error)) {
      return true;
    }

    // allow even if error message is empty
    if (StringUtils.isBlank(error.message)) {
      return true;
    }

    // otherwise check that the message doesn't include ignored strings
    return IGNORED_MESSAGES.every((message) => !message.includes(error.message));
  }

}
