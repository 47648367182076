import { Injectable, Injector } from '@angular/core';
import { Observable, map, of, take, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { catchError, shareReplay } from 'rxjs/operators';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { StringUtils } from '@util/util/string.utils';

//This is used when unexpected behavior occurs when downloading SVG icons, this is last resort to render at least folder icon.
// eslint-disable-next-line max-len
const BACKUP_SVG = '<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.86285 16.5034H17.3566C19.0208 16.5034 20 15.5314 20 13.6692V4.69386C20 2.83166 19.0136 1.85964 17.1372 1.85964H9.01671C8.39341 1.85964 8.01518 1.71529 7.53798 1.3196L7.03704 0.914658C6.42649 0.411802 5.97512 0.25 5.06086 0.25H2.59091C0.964696 0.25 0 1.19968 0 3.03081L0 13.6692C0 15.5385 0.979183 16.5034 2.86285 16.5034ZM2.96199 14.7936C2.15054 14.7936 1.70981 14.3751 1.70981 13.5255V3.17847C1.70981 2.37731 2.14635 1.95266 2.93075 1.95266H4.61352C5.22249 1.95266 5.58886 2.10259 6.07636 2.49984L6.57573 2.91195C7.18226 3.40206 7.64794 3.57102 8.5622 3.57102H17.0308C17.8351 3.57102 18.2902 3.99673 18.2902 4.84466V13.5327C18.2902 14.3751 17.8351 14.7936 17.0308 14.7936H2.96199ZM1.06732 6.86097H18.9397V5.3531H1.06732V6.86097Z"/></svg>';

export const svgCache: Map<string, string> = new Map<string, string>();

@Injectable({
  providedIn: 'root',
})
export class SvgAutoCacheService extends NgUnsubscribe {

  private inProgressSvgCache: Map<string, Observable<string>> = new Map<string, Observable<string>>();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly platformCommonService: PlatformCommonService,
    private readonly injector: Injector,
  ) {
    super();
  }

  public getSvg(path: string): Observable<string> {
    if (!path) {
      //This is used when unexpected behavior occurs when downloading SVG icons, this is last resort to render at least folder icon.
      return of(BACKUP_SVG);
    }

    // first check if svg already exists
    const cachedSvg = svgCache.get(path);
    if (cachedSvg) {
      return of(cachedSvg);
    }

    // second check if there's already in progress request for the same svg
    const cachedInProgressSvg = this.inProgressSvgCache.get(path);
    if (cachedInProgressSvg) {
      return cachedInProgressSvg;
    }

    // create request for the svg
    const baseUrl = this.platformCommonService.isServer ? `http://localhost:${ this.injector.get('SSR_PORT') }` : '.';
    const svgReq$: Observable<string> = this.httpClient.get(`${ baseUrl }${ path }`, { observe: 'response', responseType: 'text' })
      .pipe(
        take(1),
        tap((res) => {
          this.inProgressSvgCache.delete(path);
        }),
        map((res) => {
          try {
            if (!res) {
              throw new Error(`SvgAutoCacheService :: get svg response is Nil`);
            }

            if (StringUtils.isBlank(res.body)) {
              throw new Error(`SvgAutoCacheService :: get svg response is empty`);
            }

            if (!res.body?.trim().startsWith('<svg') && !res.body?.trim().startsWith('<xml') && !res.body?.trim().startsWith('<?xml')) {
              throw new Error(`SvgAutoCacheService :: Could not find an SVG element in response. ${ res.body }`);
            }

            svgCache.set(path, res.body);

            return res.body;
          } catch (err: unknown) {
            this.inProgressSvgCache.delete(path);
            //This is used when unexpected behavior occurs when downloading SVG icons, this is last resort to render at least folder icon.
            return BACKUP_SVG;
          }
        }),
        catchError((error) => {
          this.inProgressSvgCache.delete(path);
          return of(error);
        }),
        shareReplay(1),
      );

    this.inProgressSvgCache.set(path, svgReq$);

    return svgReq$;
  }

}
