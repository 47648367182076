import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { SearchCategoryModel } from '../../model/search-category.model';
import { Translate2Module } from '@common/translations/translate2.module';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { SearchCategoriesListComponent } from '../search-categories-list/search-categories-list.component';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '@common/ui-kit/component/button/component/button.component';
import { StringUtils } from '@util/util/string.utils';
import { SvgIconComponent } from '@common/ui-kit/component/svg-icon/component/svg-icon.component';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';

@Component({
  selector: 'auk-app-header-search-input',
  templateUrl: './app-header-search-input.component.html',
  standalone: true,
  imports: [
    CommonModule,
    Translate2Module,
    IconComponent,
    SearchCategoriesListComponent,
    ButtonComponent,
    SvgIconComponent,
    TestIdentificationDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderSearchInputComponent extends NgUnsubscribe implements AfterViewInit {

  @Output() public submitSearch: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() public inputFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output() public inputClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  @Output() public inputKeydown: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();
  @Output() public inputType: EventEmitter<void> = new EventEmitter<void>();
  @Output() public clearText: EventEmitter<void> = new EventEmitter<void>();
  @Output() public categoryChange: EventEmitter<SearchCategoryModel> = new EventEmitter<SearchCategoryModel>();
  @Output() public searchRef: EventEmitter<ElementRef<HTMLInputElement>> = new EventEmitter<ElementRef<HTMLInputElement>>();

  @Input() public searchCategories: SearchCategoryModel[];
  @Input() public searchCategory: SearchCategoryModel;
  @Input() public type: 'dummy' | 'search' = 'search';

  protected isMdAndLower: boolean = false;
  protected searchInFocus: boolean = false;

  @ViewChild('searchInputElement') public readonly searchInputElm: ElementRef<HTMLInputElement>;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly responsivenessService: ResponsivenessService,
  ) {
    super();

    this.responsivenessService.isMdAndLower$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isMdAndLower) => {
        this.isMdAndLower = isMdAndLower;
        this.changeDetectorRef.markForCheck();
      });
  }

  protected searchClick(event: MouseEvent): void {
    if (this.type === 'dummy') {
      this.inputClick.emit(event);
    } else {
      this.submitSearch.emit(event);
    }
  }

  public ngAfterViewInit(): void {
    this.searchRef.emit(this.searchInputElm);
  }

  protected get showSearchButton(): boolean {
    if (this.isMdAndLower === true) {
      return false;
    }

    return this.searchInFocus || !StringUtils.isEmpty(this.searchInputElm?.nativeElement?.value);
  }

  protected inputFocused(state: boolean): void {
    this.searchInFocus = state;
    if (state) {
      this.inputFocus.emit();
    }
  }

}
