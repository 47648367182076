import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconModel } from '@common/ui-kit/component/icon/component/model/icon.model';
import { TranslationSource } from '@common/translations/model/translation-source';
import { ColorCombinationInputComponent } from '@common/colors/component/color-combination-input/color-combination-input.component';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Translate2Module } from '@common/translations/translate2.module';

import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';
import { ArrayUtils } from '@util/util/array.utils';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@Component({
  selector: 'auk-icon-with-text',
  templateUrl: './icon-with-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconComponent,
    Translate2Module,
    NgTemplateOutlet,
    NgClass,
    InnerHtmlDirective,
  ],
})
export class IconWithTextComponent extends ColorCombinationInputComponent {

  @Input() public iconModel: IconModel | IconModel[];
  @Input() public text: TranslationSource;
  @Input() public iconCustomClass: string;
  @Input() public textCssClass: string;
  /**
   * Whether icon should be shown on start or on end
   */
  @Input() public iconPosition: 'START' | 'END' = 'START';
  /**
   * Whether text should be truncated with ellipsis
   */
  @Input()
  @HostBinding('class.tw-overflow-hidden')
  public truncate: boolean = false;

  protected get iconsList(): IconModel[] | Nil {
    if (isNil(this.iconModel) || (Array.isArray(this.iconModel) && ArrayUtils.isEmpty(this.iconModel))) {
      return null;
    }

    return Array.isArray(this.iconModel)
      ? this.iconModel
      : [this.iconModel];
  }

}
