import { UserTaskPersonalDataStepResolutionHandlerService } from './user-task-personal-data-step-resolution-handler.service';
import { Injectable } from '@angular/core';
import { UserTaskStepResolutionUnionModel } from '../../base/model/user-task-step-resolution-union.model';
import { UserTaskPayloadModel } from '../../base/model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../../base/model/user-task-step-union.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserTaskPersonalDataStepPhoneVerifiedEmailVerifiedResolutionHandlerService
  extends UserTaskPersonalDataStepResolutionHandlerService<'PHONE_VERIFIED_EMAIL_VERIFIED'> {

  constructor(private readonly router: Router) {
    super();
  }

  public handle(resolution: UserTaskStepResolutionUnionModel<'PERSONAL_DATA', 'PHONE_VERIFIED_EMAIL_VERIFIED'>,
    taskPayload: UserTaskPayloadModel<'PERSONAL_DATA'>): UserTaskStepUnionModel<'PERSONAL_DATA'> {

    if (resolution.type === 'RESOLUTION_SET_PASSWORD') {
      void this.router.navigate(...this.getSetPasswordNavigationParams());
    }

    return null;
  }

}
