import { Nil } from '@util/helper-types/nil';
import isNil from 'lodash-es/isNil';

export class CookieUtil {

  public static shouldBeNumberValSanitized(cookieVal: string | Nil): boolean {
    return isNil(cookieVal) || cookieVal === 'null' || cookieVal === 'undefined';
  }

}
