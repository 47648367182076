<div *ngIf="verificationRequired; else content" [class.text-center]="!inline">
  <ng-container *ngIf="!isTotallyFailed; else failed">
    <div [class.flex]="inline" [class.wrap]="inline" [class.column-max-tablet]="inline">
      <div [class.grow-1]="inline">
        <!-- HEADING -->
        <h1 class="m-b-4"
            [aukInnerHtml]="{key: 'PHONE_NUMBER_VERIFICATION_HEADING'}"
            *ngIf="!inline"
        ></h1>

        <!-- ICON -->
        <i class="material-icons !tw-text-matter-accent-primary huge m-b-4" *ngIf="!inline">
          security
        </i>

        <!-- SUBTITLE -->
        <p class="line-height text-big m-b-2"
           [class.text-big]="!inline"
           [class.m-b-4]="!inline"
           [aukInnerHtml]="{key: 'PHONE_NUMBER_VERIFICATION_SUBTITLE'}"
        ></p>

        <!-- INPUT -->
        <auk-verification-code-input [length]="VERIFICATION_CODE_LENGTH"
                                 [inline]="inline"
                                 class="display-block m-b-2"
                                 aukTestIdentification="two-factor-input"
                                 (codeInput)="onCodeInput($event)"
                                 (keyup.enter)="submitManually()"
        ></auk-verification-code-input>

        <!-- MESSAGES -->
        <p class="line-height m-b-4"
           [ngSwitch]="tfvErrorMessageCode"
        >
          <!-- Send again -->
          <ng-container *ngSwitchDefault>
            <span class="text-gray"
                  [aukInnerHtml]="{key: 'PHONE_NUMBER_VERIFICATION_CODE_NOT_DELIVERED'}"
            ></span>
            &ngsp;
            <ng-container [ngTemplateOutlet]="resend"></ng-container>
          </ng-container>

          <!-- Invalid -->
          <ng-container *ngSwitchCase="TfvErrorMessageCodeEnum.INVALID">
            <span class="text-red"
                  [aukInnerHtml]="{key: 'PHONE_NUMBER_VERIFICATION_CODE_INVALID'}"
            ></span>

            <div class="m-t-2">
              <span class="text-gray"
                    [aukInnerHtml]="{key: 'PHONE_NUMBER_VERIFICATION_CODE_NOT_DELIVERED'}"
              ></span>
              &ngsp;
              <ng-container [ngTemplateOutlet]="resend"></ng-container>
            </div>
          </ng-container>

          <!-- Expired -->
          <ng-container *ngSwitchCase="TfvErrorMessageCodeEnum.EXPIRED">
            <span class="text-red"
                  [aukInnerHtml]="{key: 'PHONE_NUMBER_VERIFICATION_CODE_EXPIRED'}"
            ></span>
            &ngsp;
            <ng-container [ngTemplateOutlet]="resend"></ng-container>
          </ng-container>

          <!-- Reusable resend button -->
          <ng-template #resend>
            <a aukPreventDefaultLink
               role="button"
               [class.disabled]="resendDisabled"
               (click)="resendCode()"
            >{{'PHONE_NUMBER_VERIFICATION_SEND_AGAIN' | translate}}
              <span *ngIf="resendDisabled" class="text-red">{{resendCountDownText}}</span>
            </a>
          </ng-template>
        </p>
      </div>

      <!-- BUTTONS -->
      <div class="flex"
           [class.shrink-0]="inline"
           [class.m-t-2]="inline"
           [class.column-reverse]="!inline"
           [class.column-max-tablet]="inline"
      >
        <auk-button *ngIf="showSkipButton"
                    colorCombination="SECONDARY"
                    class="tw-items-start tw-mb-2"
                    [class.tw-w-full]="inline"
                    [class.md:tw-w-28]="inline"
                    [class.tw-mr-1]="inline"
                    [isDisabled]="isSubmitting"
                    (buttonClick)="onSkipClick()"
        >
          <span [aukInnerHtml]="{key: 'PHONE_NUMBER_VERIFICATION_SKIP_BUTTON'}"></span>
        </auk-button>
        <auk-button *ngIf="showGoBackButton"
                    colorCombination="SECONDARY"
                    class="tw-items-start tw-mb-2"
                    [class.tw-w-full]="inline"
                    [class.md:tw-w-28]="inline"
                    [class.tw-mr-1]="inline"
                    [isDisabled]="isSubmitting"
                    (buttonClick)="onGoBackClick()"
        >
          <span [aukInnerHtml]="{key: (inline ? 'PHONE_NUMBER_VERIFICATION_BACK_INLINE' : 'PHONE_NUMBER_VERIFICATION_BACK')}"></span>
        </auk-button>
        <auk-button type="submit"
                    colorCombination="PRIMARY"
                    [chin]="true"
                    class="tw-items-start tw-mb-2"
                    [class.tw-w-full]="inline"
                    [class.md:tw-w-28]="inline"
                    [isDisabled]="isSubmitting || !hasValidLength(currentValue) || hasErrorWithUnchangedCode"
                    [isLoading]="isSubmitting"
                    (buttonClick)="submitManually()"
        >
          <span [aukInnerHtml]="{key: 'PHONE_NUMBER_VERIFICATION_SUBMIT'}"></span>
        </auk-button>
      </div>
    </div>

    <!-- CONTACT -->
    <p *ngIf="!inline"
       class="line-height m-t-4"
       [aukInnerHtml]="{key: 'PHONE_NUMBER_VERIFICATION_CONTACT_FORM'}"
    ></p>
  </ng-container>
</div>

<ng-template #failed>
  <i *ngIf="!inline" class="material-icons text-red huge m-b-3">
    highlight_off
  </i>

  <p class="line-height" [class.text-huge]="!inline" [ngSwitch]="tfvErrorMessageCode">
    <span *ngSwitchCase="TfvErrorMessageCodeEnum.FAILED"
          [aukInnerHtml]="{key: 'TFV_GENERIC_ERROR_MODAL_COOLDOWN_ERROR'}"
    ></span>
    <span *ngSwitchDefault
          [aukInnerHtml]="{key: 'TFV_GENERIC_ERROR_MODAL_GENERIC_ERROR'}"
    ></span>
  </p>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
