import { StringUtils } from '@util/util/string.utils';
import { isNotNil } from '@util/helper-functions/is-not-nil';

export class ElementIdentifierUtil {

  /**
   * Creates single kebab-case string by combining all strings
   * @param values - strings from which should be the identifier constructed
   */
  public static createIdentifier(
    ...values: string[]
  ): string {
    // first map all strings to kebab-case
    const kebabCaseStrings = values
      .filter(isNotNil)
      .map((value) => StringUtils.toKebabCase(value));

    // then join all strings by dash, so everything will remain in kebab-case
    return StringUtils.joinStringsWithDash(...kebabCaseStrings);
  }

}
