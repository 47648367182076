import { Injectable } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { ShowListingEventResponseDto } from '@api/generated/defs/ShowListingEventResponseDto';
import { PopularSearchDto } from '@api/generated/defs/PopularSearchDto';
import { PersonalizationMeasurementService } from '@shared/services/personalization/personalization-measurement.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Injectable({
  providedIn: 'root',
})
export class PopularSearchMeasurementService extends NgUnsubscribe {

  private lastPopularSearchShowId: number;

  private readonly SUGGEST_POPULAR_SEARCH_MEASUREMENT_OBJECT_CODE: string = 'SUGGEST_POPULAR_SEARCH';

  constructor(
    private readonly personalizationMeasurementService: PersonalizationMeasurementService,
  ) {
    super();
  }

  public showPopularSearch(shownPopularSearches: PopularSearchDto[]): void {
    this.personalizationMeasurementService.showMeasurement$({
      objects: shownPopularSearches?.map(popularSearch => popularSearch.url),
      measurementObjectCode: this.SUGGEST_POPULAR_SEARCH_MEASUREMENT_OBJECT_CODE,
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((response: ShowListingEventResponseDto) => {
        this.lastPopularSearchShowId = response?.showId;
      });
  }

  public clickOnPopularSearch(index: number): void {
    this.personalizationMeasurementService.clickMeasurement$({
      elementRank: index + 1,
      measurementObjectCode: this.SUGGEST_POPULAR_SEARCH_MEASUREMENT_OBJECT_CODE,
      showId: this.lastPopularSearchShowId,
    })
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe();
  }

}
