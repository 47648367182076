import { UserRatingDto } from '@api/generated/defs/UserRatingDto';
import { RefundDto } from '@api/generated/defs/RefundDto';
import { ItemImageDto } from '@api/generated/defs/ItemImageDto';
import { FeedbackRatingSumValueDto } from '@api/generated/defs/FeedbackRatingSumValueDto';
import { FeedbackCriteriumTypeDto } from '@api/generated/defs/FeedbackCriteriumTypeDto';
import { PayUSettingsFormDto } from '@api/generated/defs/PayUSettingsFormDto';
import { ItemShippingOptionDto } from '@api/generated/defs/ItemShippingOptionDto';
import { AddressItemDtoReq } from '@api/generated/defs/AddressItemDtoReq';
import { MoneyDto } from '@api/generated/defs/MoneyDto';
import { TranslationKey } from '@common/translations/model/translation-key';
import { TranslationSource } from '@common/translations/model/translation-source';
import { ColorCombinationId } from '@common/colors/model/color-combination-id';

export interface BidTerminationDto {
  amount?: MoneyDto; // (number, optional): Bid amount
  userRatingDto?: UserRatingDto; // (UserRatingDto, optional): Anonymous username of bidder with rating
  time?: string; // (string, optional): Date and time when the bid added
}

/* Aukro.Billing */

export interface BillingRefundDto extends RefundDto {
  possibleReasonTypes?: string[]; // (string[], optional)
  quantity?: number; // (number, optional)
}

/* Aukro.Feedback */

export interface FeedbackRatingSummaryTransformed extends FeedbackRatingSumValueDto {
  feedbackRatingSumValuesTransformed?: { number?: FeedbackRatingSummaryTransformed };
  oneMonthAgo?: { criteriaId?: FeedbackRatingSumValueDto };
  twoMonthsAgo?: { criteriaId?: FeedbackRatingSumValueDto };
  threeMonthsAgo?: { criteriaId?: FeedbackRatingSumValueDto };
  feedbackReasonSumValuesTransformed?: { number?: FeedbackCriteriumTypeDto };
}

/* Aukro.Payment */

export interface PayUSettingsForm extends PayUSettingsFormDto {
  bankCountryName?: string;
}

export interface OfferPostCategoryRestrictionErrors {
  art: boolean;
}

export interface OfferPostErrors {
  itemName?: boolean;
  itemNameSize?: {
    maxSize: number
  };
  itemNameFfp3?: boolean;
  categoryId?: {
    empty?: boolean;
    restricted?: boolean;
    restrictedArt?: boolean;
  };
  itemAttributeValues?: {
    [key: string]: boolean;
  };
  itemTypeEnumId?: boolean;
  currentQuantity?: boolean;
  startPrice?: {
    empty?: boolean;
    lowerThenOne?: boolean;
    sameOrBiggerThenBuyNow?: boolean;
  };
  buyNowPrice?: boolean;
  retailPrice?: boolean;
  itemDurationEnumId?: boolean;
  itemDurationMinimum?: number;
  propagationError?: boolean;
  shipmentPaymentDescription?: boolean;
  shippingListName?: boolean;
  missedPaymentBasicMethod?: boolean;
  missedPaymentOptions?: boolean;
  missedPrepaidDeliveryShippingOptions?: boolean;
  missedPostpaidDeliveryShippingOptions?: boolean;
  missedPrepaidDeliveryShippingOptionForPaymentViaAukro?: boolean;
  selectedZasilkovnaAndAukroZasilkovna?: boolean;
  bankAccount1error?: boolean;
  bankAccount2error?: boolean;
  bankAccountPaymentViaAukroError?: boolean;
  itemShippingOptions?: boolean | {
    [key: number]: {
      [key: string]: boolean;
    }
  };
  itemDescription?: boolean;
  itemDescriptionSize?: {
    maxSize: number
  };
  itemDescriptionFfp3?: boolean;
  itemAdditionalDescriptionFfp3?: boolean;
  images?: {
    empty?: boolean;
    limitReached?: boolean;
    serverError?: boolean;
    sizeError?: boolean;
    typeError?: boolean;
  };
  itemLocation?: {
    empty?: boolean;
    countryMissed?: boolean;
    cityMissed?: boolean;
    cityIncorrect?: boolean;
    zipMissed?: boolean;
    zipIncorrect?: boolean;
  };
}

export interface OfferPostAttributes {
  attributeValue: string;
  itemAttributeId: number;
  itemAttributeListValueId?: number;
}

export type SortDirection = 'ASC' | 'DESC';

export interface SortOption {
  name?: string;
  value: SortDirection;
  title: string;
  seoParameters?: SEOSortOptionParameters;
}

export interface SEOSortOptionParameters {
  url?: string;
  title?: TranslationKey;
  description?: string;
}

// internal mask for replacement category name
export const SEOSortOptionReplaceMask: RegExp = /<categoryname>/g;
// mask used in DB for replacement category name in meta_description
export const MetaDescriptionReplaceMask: RegExp = /<category_name>/g;

// params for SEO sorting
export const SEOSortParam_LEVNE = 'levne';
export const SEOSortParam_NEJLEPSI = 'nejlepsi';

export const SEOSortParams: SeoSortParameterUrlLabelMap =
{
  [SEOSortParam_NEJLEPSI]: 'SEO_SORT_PARAMS_BEST',
  [SEOSortParam_LEVNE]: 'SEO_SORT_PARAMS_CHEAP',
};

export interface SeoSortParameterUrlLabelMap {
  [url: string]: TranslationKey;
}

export interface SortSearchParams {
  sort: string,
  direction: SortDirection
}

export interface SortCriteria {
  title: string;
  name: string;
  options: SortOption[];
}

export interface SelectedSortCriterion {
  option: SortOption,
  criteria: SortCriteria
}

export interface SimpleFilterParamItem {
  message: string;
  count?: number;
  type?: string;
  filterName?: string;
  value?: boolean;
  applied?: boolean;
  tooltip?: string;
  icon?: {
    id: string;
    iconClass: string;
    colorCombination?: ColorCombinationId;
  };
}

export interface SimpleFilterParams {
  name: string;
  items: SimpleFilterParamItem[];
}

/* Social network */

export enum SocialNetworkType {
  TWITTER,
  FACEBOOK
}

export interface SocialNetwork {
  convertOptions: (ShareOptions) => unknown;
  href: string;
  shareUrl: string;
  type: SocialNetworkType;
}

export interface ShareOptions {
  text: string;
  url: string;
}

export interface OfferDetailGroupedParameter {
  name: string;
  values: OfferDetailGroupedValue[];
  /**
   * Attribute id
   */
  attributeId: number;
  /**
   * Attribute value addtional description
   */
  attributeValueAdditionalDescription: TranslationSource;
}

export interface OfferDetailGroupedValue {
  name: string;
  url: string;
}

/* Multiselect */

export interface SelectOption {
  id?: string | number;
  key?: string | number;
  name?: string | number;
  description?: string;
  value?: string | number | Date;
}

export interface Suggestion {
  key: string | boolean;
  name: string;
  htmlText: string;
}

