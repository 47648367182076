import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export interface ValidateNumberParams {
  /** phoneNumber */
  phoneNumber: string;
}

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root',
})
export class PhoneNumberValidatorService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public validateNumber(params: ValidateNumberParams): Observable<model.PhoneNumberValidationDto> {
    let pathParams = {
      phoneNumber: params.phoneNumber,
    };
    let queryParams;
    return this.getGenerated(`/phoneNumber/validateNumber/${pathParams.phoneNumber}`, queryParams);
  }
}
