import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, makeStateKey, StateKey, TransferState } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TransferStateService } from '@shared/transfer-state/service/transfer-state.service';

@Injectable()
export class BrowserStateInterceptor implements HttpInterceptor {

  constructor(private readonly transferState: TransferState,
              private readonly transferStateService: TransferStateService) {
  }

  public intercept(req: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<any>> {
    const key: StateKey<string> = makeStateKey<string>(this.transferStateService.generateKeyForRequest(req));
    const storedResponse: string = this.transferState.get(key, null);

    if (storedResponse) {
      const response: HttpResponse<string> = new HttpResponse({ body: storedResponse, status: 200 });
      this.transferState.remove(key);
      return of(response);
    }

    return next.handle(req);
  }

}
