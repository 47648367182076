import { Injectable } from '@angular/core';
import { GoogleAnalyticsTrackingService } from '@shared/google-analytics/service/google-analytics-tracking.service';
import { PopupFlowStepType } from '../model/popup-flow-step.type';
import { PopupFlowStepDisplayedGaEventModel } from '../model/popup-flow-step-displayed-ga-event.model';
import { PopupFlowInterruptedGaEventModel } from '../model/popup-flow-interrupted-ga-event.model';
import { ActionViewType } from '@shared/user-action-view/model/action-view-type';
import { UserTaskType } from '@shared/user-task/base/model/user-task.type';
import { UserTaskStepType } from '@shared/user-task/base/model/user-task-step.type';
import { UserActionType } from '@shared/user-action/model/user-action.type';
import { PopupFlowAnalyticsActionType } from '@shared/popup-flow/model/popup-flow-analytics-action.type';

@Injectable({
  providedIn: 'root',
})
export class PopupFlowGaService {

  constructor(
    private readonly googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
  ) {
  }

  public trackPopupFlowStepDisplayedTask<STEP_TYPE extends UserTaskType>(
    actionType: UserActionType | string,
    taskType: UserTaskType,
    stepType: UserTaskStepType<STEP_TYPE>,
  ): Promise<PopupFlowStepDisplayedGaEventModel> {
    return this.trackPopupFlowStepDisplayed(
      actionType,
      `TASK_${ taskType }_${ stepType.toString() }`,
    );
  }

  public trackPopupFlowStepDisplayedView(
    actionType: PopupFlowAnalyticsActionType,
    viewType: ActionViewType,
  ): Promise<PopupFlowStepDisplayedGaEventModel> {
    return this.trackPopupFlowStepDisplayed(
      actionType,
      viewType,
    );
  }

  private trackPopupFlowStepDisplayed(
    action: UserActionType | string,
    step: PopupFlowStepType,
  ): Promise<PopupFlowStepDisplayedGaEventModel> {
    return this.googleAnalyticsTrackingService.trackEventGeneric({
      event: 'popupFlowStepDisplayed',
      action,
      step,
    });
  }

  private trackPopupFlowStepInterrupted(
    action: PopupFlowAnalyticsActionType,
    step: PopupFlowStepType,
  ): Promise<PopupFlowInterruptedGaEventModel> {
    return this.googleAnalyticsTrackingService.trackEventGeneric({
      event: 'popupFlowInterrupted',
      action,
      step,
    });
  }

  public trackPopupFlowInterruptedTask<STEP_TYPE extends UserTaskType>(
    actionType: PopupFlowAnalyticsActionType,
    taskType: UserTaskType,
    stepType: UserTaskStepType<STEP_TYPE>,
  ): Promise<PopupFlowInterruptedGaEventModel> {
    return this.trackPopupFlowStepInterrupted(
      actionType,
      `TASK_${ taskType }_${ stepType.toString() }`,
    );
  }

  public trackPopupFlowStepInterruptedView(
    actionType: PopupFlowAnalyticsActionType,
    viewType: ActionViewType,
  ): Promise<PopupFlowInterruptedGaEventModel> {
    return this.trackPopupFlowStepInterrupted(
      actionType,
      viewType,
    );
  }

}
