import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanActivateFn } from '@angular/router';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';

/**
 * Used to make the rendering process asynchronous in order to reduce the initial continuous load on the main thread by slicing
 * the rendering of the page into a separate task.
 */
export const asyncRenderGuard: CanActivateFn =
  (): true | Observable<true> => {
    const platformCommonService = inject(PlatformCommonService);
    const ngZoneUtilService = inject(NgZoneUtilService);

    if (platformCommonService.isServer) {
      return true;
    }

    return ngZoneUtilService.timerOut$()
      .pipe(
        map(() => true),
      );
  };
