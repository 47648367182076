import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BackToTopService {

  private visible: boolean = true;

  public setVisibility(visible: boolean): void {
    this.visible = visible;
  }

  public isVisible(): boolean {
    return this.visible;
  }

}
