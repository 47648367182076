import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[aukMouseOver]',
  standalone: true,
})
export class MouseOverDirective {

  @Output() public isMouseOver:EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.isMouseOver.emit(true);
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.isMouseOver.emit(false);
  }

}
