import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { NgClass } from '@angular/common';
import { Translate2Module } from '@common/translations/translate2.module';
import isNil from 'lodash-es/isNil';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { ItemCardTitleConfigModel } from '@common/ui-kit/component/item-card/component/basic-item-card/model/item-card-title-config.model';
import { ResponsivenessService } from '@common/responsiveness/service/responsiveness.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { takeUntil } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import { Nil } from '@util/helper-types/nil';
import { TestIdentificationDirective } from '@common/test-identification/directive/test-identification.directive';

@Component({
  selector: 'auk-item-card-title',
  templateUrl: './item-card-title.component.html',
  standalone: true,
  imports: [
    Translate2Module,
    NgClass,
    TestIdentificationDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCardTitleComponent extends NgUnsubscribe implements OnChanges {

  @Input() public titleModel: ItemCardTitleConfigModel;

  protected titleClasses: string[] = [];

  private _titleModelChange$: Subject<void> = new Subject<void>();

  constructor(
    private readonly responsivenessService: ResponsivenessService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (changes.titleModel) {
      this._titleModelChange$.next();

      this.titleClasses = this.getTitleClasses(
        this.titleModel,
        this.responsivenessService.isMdAndLower,
      );
      if (this.titleModel.dynamicHeightOnMdAndLower) {
        this.initMdAndLowerBrListener();
      }
    }
  }

  protected getTitleClasses(
    titleModel: ItemCardTitleConfigModel,
    isMdAndLowerBr: boolean,
  ): string[] {
    const titleClasses: string[] = [];

    titleClasses.push(titleModel.isBold ? 'tw-font-bold' : 'tw-font-normal');

    const titleHeightClass: string = this.getTitleHeightClass(titleModel, isMdAndLowerBr);
    if (!isNil(titleHeightClass)) {
      titleClasses.push(titleHeightClass);
    }

    if (isNil(titleModel?.titleSize) || titleModel.titleSize === 'DEFAULT') {
      titleClasses.push('tw-text-lg', 'tw-leading-sm');
      return titleClasses;
    }

    if (titleModel.titleSize === 'SMALL' || titleModel.titleSize === 'EXTRA_SMALL') {
      titleClasses.push('tw-leading-sm', 'tw-text-md');

      if (titleModel.isTwoLineClamp) {
        titleClasses.push('!tw-line-clamp-2');
        return titleClasses;
      }

      return titleClasses;
    }

    if (titleModel.titleSize === 'LARGE_COMPACT') {
      titleClasses.push('tw-leading-custom2', 'tw-text-llg', 'tw-line-clamp-3');

      return titleClasses;
    }

    if (titleModel.titleSize === 'LARGE') {
      titleClasses.push('tw-leading-sm', 'tw-text-xl', 'tw-line-clamp-3');

      return titleClasses;
    }
  }

  private getTitleHeightClass(
    titleModel: ItemCardTitleConfigModel,
    isMdAndLowerBr: boolean,
  ): string | Nil {
    if (titleModel.dynamicHeightOnMdAndLower && isMdAndLowerBr) {
      return null;
    }

    if (isNil(titleModel?.titleSize) || titleModel.titleSize === 'DEFAULT') {
      return 'tw-h-[4.25rem]';
    }

    if (titleModel.titleSize === 'SMALL') {
      if (titleModel.isTwoLineClamp) {
        return 'tw-h-[2.5rem]';
      }

      return 'tw-h-[3.75rem]';
    }
  }

  private initMdAndLowerBrListener(): void {
    this.responsivenessService.isMdAndLower$
      .pipe(
        takeUntil(
          merge(
            this.ngUnsubscribe,
            this._titleModelChange$.asObservable(),
          ),
        ),
      )
      .subscribe((isMdAndLower) => {
        this.titleClasses = this.getTitleClasses(this.titleModel, isMdAndLower);
        this.changeDetectorRef.markForCheck();
      });
  }

}
