import { UserTaskType } from '../model/user-task.type';
import { UserTaskStepType } from '../model/user-task-step.type';
import { UserTaskStepResolutionUnionModel } from '../model/user-task-step-resolution-union.model';
import { UserTaskPayloadModel } from '../model/user-task-payload.model';
import { UserTaskStepUnionModel } from '../model/user-task-step-union.model';

export abstract class UserTaskStepResolutionHandlerService<TASK_TYPE extends UserTaskType, STEP_TYPE extends UserTaskStepType<TASK_TYPE>> {

  private taskType: TASK_TYPE;

  private stepType: STEP_TYPE;

  public abstract handle(
    resolution: UserTaskStepResolutionUnionModel<TASK_TYPE, STEP_TYPE>,
    taskPayload: UserTaskPayloadModel<TASK_TYPE>
  ): UserTaskStepUnionModel<TASK_TYPE>;

}
