import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AukButtonDialogModel } from '../model/auk-button-dialog.model';
import { AukDialogButtonModel } from '../model/auk-dialog-button.model';
import { trackByIdFn } from '@util/helper-functions/track-by/track-by-id-fn';

@Component({
  selector: 'auk-button-dialog',
  templateUrl: './auk-button-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AukButtonDialogComponent {

  protected trackByIdFn = trackByIdFn;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: AukButtonDialogModel,
    private readonly dialogRef: MatDialogRef<AukButtonDialogComponent, string>,
  ) {
  }

  protected buttonClick(button: AukDialogButtonModel): void {
    this.dialogRef.close(button.action);
  }

  protected closeClick(): void {
    this.dialogRef.close('close');
  }

}
