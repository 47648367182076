<div class="tw-bg-contrast">
  <div class="container tw-h-auto tw-flex tw-justify-between tw-flex-wrap tw-items-center lg:tw-h-[initial] lg:tw-flex-nowrap
    lg:tw-gap-x-4 lg:tw-min-h-[4.75rem]">

    <!-- SEARCH TOGGLE BUTTON -->
    <auk-icon class="tw-rounded-md lg:!tw-hidden tw-p-1.5"
      [class.tw-bg-silverstein-500]="isSearchOpened"
      [colorCombination]="isSearchOpened ? 'CONTRAST' : 'CONTRAST_OPPOSITE'"
      size="LG"
      source="search0"
      (click)="toggleSearchInput()"
      aukStopPropagationOnClick
    ></auk-icon>
    <!-- ./SEARCH TOGGLE BUTTON -->

    <!-- MAIN LOGO -->
    <a class="main-logo" routerLink="/">
      <auk-svg-icon iconId="logo"
        class="!tw-fill-primary !tw-w-[75px] lg:!tw-w-[155px]"
      ></auk-svg-icon>
    </a>
    <!-- ./MAIN LOGO -->

    <!-- HEADER SEARCH -->
    @if (isSearchOpened) {
      <auk-app-header-search
        class="tw-flex tw-justify-center"
        [openExpanded]="isSearchExpanded"
      ></auk-app-header-search>
    }
    <!-- ./HEADER SEARCH -->

    <!-- HAMBURGER MENU -->
    <auk-icon size="LG"
      class="tw-cursor-pointer tw-rounded-lg tw-bg-contrast tw-p-2 hover:tw-bg-surface-neutral-2"
      source="menu"
      (click)="openHamburgerMenu()"
    ></auk-icon>
    <!-- ./HAMBURGER MENU -->

  </div>

</div>

<!-- Hidden prerendered hamburger dialog for faster opening up -->
<auk-category-dialog-modal [ngClass]="{'tw-invisible' : (categoryMenuOpened$() | async) === false}"></auk-category-dialog-modal>
