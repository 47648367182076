import { Inject, Injectable } from '@angular/core';
import { APP_CONSTANTS } from '@app-constants';
import { CookieService } from '@common/cookie/service/cookie.service';
import { from, mergeMap, Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { SuggestApiService } from '@api/aukro-api/api/suggest-api.service';
import { NewUserProfileBaseDto } from '@api/aukro-api/model/new-user-profile-base-dto';
import { BoughtItemExtendedDto } from '../../../../typings/original/internal';
import { PlatformService } from '@shared/platform/service/platform.service';
import { UserService } from '@shared/user/service/user.service';
import {
  EndedItemSplitGroupType,
  YuspAddToCartEventParams,
  YuspAddToWatchListEventParams,
  YuspClickEventParams,
  YuspEventTrackingParams,
  YuspEventType,
  YuspFavoritesEventParams,
  YuspLetterSendEventParams,
  YuspRatingEventParams,
  YuspRecClickEventParams,
  YuspRecommendationParams,
  YuspRecommendationResultNames,
  YuspRecommendationScenarioType,
  YuspSearchEventParams,
  YuspShowRecommendationParams,
  YuspSplitGroupType,
  YuspViewEventParams,
} from './yusp-personalization.helper';
import { OfferDetailDto } from '@api/aukro-api/model/offer-detail-dto';
import { removeAccentsDiacritics } from '@util/util/method/remove-accents-diacritics.util';
import { PersonalizationMeasurementService } from './personalization-measurement.service';
import { YuspItemResult } from './model/yusp-item-result.model';
import { YuspRecommendationResult } from './model/yusp-recommendation-result.model';
import { YuspRecommendationResultInternal } from './model/yusp-recommendation-result-internal.model';
import { YuspItemResultInternal } from './model/yusp-item-result-internal.model';
import { CurrencyCode } from '@shared/currency/model/currency-code.model';
import { NumberUtils } from '@util/util/number.utils';
import { YuspPersonalizationPriceExchangeService } from './yusp-personalization-price-exchange.service';
import isNil from 'lodash-es/isNil';
import isEmpty from 'lodash-es/isEmpty';
import { ArrayUtils } from '@util/util/array.utils';
import { WINDOW_OBJECT } from '@util/const/window-object';
import { MoneyDto } from '@api/aukro-api/model/money-dto';
import { DomainService } from '@shared/platform/domain.service';
import { FallbackReason } from '@shared/services/personalization/model/fallback-reason.type';
import { Nil } from '@util/helper-types/nil';
import { CookieConsentVendorEnum } from '@shared/cookie-consent/model/cookie-consent-vendor.enum';
import { CookieConsentService } from '@shared/cookie-consent/service/cookie-consent.service';
import { AukWindow } from '@shared/model/auk-window.interface';
import { NgZoneUtilService } from '@util/zone/service/ng-zone-util.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { DateUtils } from '@util/util/date.utils';
import { PersonalizationConstants } from '@shared/services/personalization/const/personalization.constants';

@Injectable({
  providedIn: 'root',
})
export class YuspPersonalizationService extends NgUnsubscribe {

  constructor(
    @Inject(WINDOW_OBJECT) private readonly window: AukWindow,
    private readonly platformService: PlatformService,
    private readonly platformCommonService: PlatformCommonService,
    private readonly userService: UserService,
    private readonly cookieService: CookieService,
    private readonly suggestApiService: SuggestApiService,
    private readonly personalizationMeasurementService: PersonalizationMeasurementService,
    private readonly yuspPersonalizationPriceExchangeService: YuspPersonalizationPriceExchangeService,
    private readonly domainService: DomainService,
    private readonly cookieConsentService: CookieConsentService,
    private readonly ngZoneUtilService: NgZoneUtilService,
  ) {
    super();
  }

  private get isPersonalizationAllowed(): boolean {
    if (this.platformService.isBot) {
      return false;
    }

    return !isNil(this.cookieService.get(APP_CONSTANTS.RECO1_COOKIE_SPLIT_GROUP_NAME));
  }

  /**
   * The user viewed the info page of an item.
   * @param itemId
   */
  public trackViewEvent(itemId: number): void {
    const event: YuspViewEventParams = {
      type: 'event',
      eventType: 'VIEW',
      itemId: itemId.toString(),
    };

    void this.createTrackPromise(event);
  }

  /**
   * The user increased a bid in auction.
   * @param item
   * @param currentBid
   */
  public trackBidEvent(item: OfferDetailDto, currentBid: MoneyDto): void {
    if (isNil(item) || isNil(currentBid)) {
      return;
    }

    this.yuspPersonalizationPriceExchangeService.exchangeTrackBidEvent(item, currentBid)
      .pipe(
        map(event => this.createTrackPromise(event)),
        take(1),
      ).subscribe();
  }

  /**
   * The user bought an item. There are 2 buy types as auction and buynow.
   * If user buy an item in auction, buyType should be set as auction and unitPrice should be the price of the item sold in auction.
   * If user buy an item as buynow, buyType should be set as buynow and unitPrice should be equal to buyNowPrice of an item.
   * @param boughtItems
   */
  public trackBuyEvent(boughtItems: BoughtItemExtendedDto[]): void {
    if (isNil(boughtItems) || isEmpty(boughtItems)) {
      return;
    }

    this.yuspPersonalizationPriceExchangeService.exchangeTrackBuyEvent(boughtItems)
      .pipe(
        map(events => events.map(event => this.createTrackPromise(event))),
        take(1),
      ).subscribe();
  }

  /**
   * Send a message to seller
   * @param itemId
   * @param sellerId
   */
  public trackLetterSendEvent(itemId: number, sellerId: number): void {
    const event: YuspLetterSendEventParams = {
      type: 'event',
      eventType: 'LETTER_SEND',
      itemId: itemId.toString(),
      sellerId: sellerId.toString(),
    };
    void this.createTrackPromise(event);
  }

  /**
   * The user added seller to favourites.
   * @param sellerId
   */
  public trackAddToFavoritesEvent(sellerId: number): void {
    const event: YuspFavoritesEventParams = {
      type: 'event',
      eventType: 'ADD_TO_FAVORITES',
      sellerId: sellerId.toString(),
      itemId: 'seller' + sellerId.toString(),
      splitGroup: this.getSplitGroupOrDefault(),
    };

    void this.createTrackPromise(event);
  }

  /**
   * The user removed seller from favourites.
   * @param sellerId
   */
  public trackRemoveFromFavoritesEvent(sellerId: number): void {
    const event: YuspFavoritesEventParams = {
      type: 'event',
      eventType: 'REMOVE_FROM_FAVORITES',
      sellerId: sellerId.toString(),
      itemId: 'seller' + sellerId.toString(),
    };
    void this.createTrackPromise(event);
  }

  /**
   * The user rated a seller after purchase
   * @param sellerId
   */
  public trackRatingEvent(sellerId: number): void {
    const event: YuspRatingEventParams = {
      type: 'event',
      eventType: 'RATING',
      sellerId: sellerId.toString(),
      itemId: 'seller' + sellerId.toString(),
    };
    void this.createTrackPromise(event);
  }

  /**
   * The user clicked on “Sledovat” button.
   * @param itemId
   */
  public trackAddToWatchListEvent(itemId: number): void {
    const event: YuspAddToWatchListEventParams = {
      type: 'event',
      eventType: 'ADD_TO_WATCH_LIST',
      itemId: itemId.toString(),
    };
    void this.createTrackPromise(event);
  }

  /**
   * The user added an item to the shopping cart.
   * @param itemId
   */
  public trackAddToCartEvent(itemId: number): void {
    const event: YuspAddToCartEventParams = {
      type: 'event',
      eventType: 'ADD_TO_CART',
      itemId: itemId.toString(),
    };
    void this.createTrackPromise(event);
  }

  /**
   * User searched for item using one of search fields.
   * @param searchQuery
   */
  public trackSearchEvent(searchQuery: string): void {
    const event: YuspSearchEventParams = {
      type: 'event',
      eventType: 'SEARCH',
      query: searchQuery,
    };
    void this.createTrackPromise(event);
  }

  /**
   * Sends event about click on Aukro recommendation.
   * There is no check whether split group is really `aukro` in this method,
   * because Aukro recommendation could be used as a fallback even if user's split group is `yusp`.
   * @param itemId ID of the clicked item
   * @param scenarioId scenario type
   * @param mainCategoryIdentifier main category of the clicked item
   */
  public trackClickEvent(itemId: string, scenarioId: YuspRecommendationScenarioType, mainCategoryIdentifier?: string): void {
    let event: YuspClickEventParams = {
      type: 'event',
      eventType: 'CLICK',
      itemId,
      scenarioId,
    };

    if (mainCategoryIdentifier) {
      mainCategoryIdentifier = this.prepareMainCategoryIdentifierParameter(mainCategoryIdentifier);
      event = {
        ...event,
        mainCategoryIdentifier,
      };
    }

    void this.createTrackPromise(event);
  }

  /**
   * The user clicked on a recommended item.
   * @param itemId
   * @param recId
   * @param scenarioId
   * @param mainCategoryIdentifier
   */
  public trackRecClickEvent(
    itemId: string,
    recId: string,
    scenarioId: YuspRecommendationScenarioType,
    mainCategoryIdentifier?: string): void {
    if (this.getSplitGroup() !== 'yusp') {
      return;
    }
    let event: YuspRecClickEventParams = {
      type: 'event',
      eventType: 'REC_CLICK',
      itemId,
      recId,
      scenarioId,
    };

    if (mainCategoryIdentifier) {
      mainCategoryIdentifier = this.prepareMainCategoryIdentifierParameter(mainCategoryIdentifier);
      event = {
        ...event,
        mainCategoryIdentifier,
      };
    }

    void this.createTrackPromise(event);
  }

  public trackShowYuspRecommendation(
    data: YuspRecommendationResultInternal,
    scenarioId: YuspRecommendationScenarioType,
    mainCategoryIdentifier?: string,
  ): void {
    if (!data) {
      return;
    }

    if (this.getSplitGroup() !== 'yusp') {
      return;
    }
    // do not track when W_ITEM_SELLER has less than 4 items
    if (scenarioId === 'W_ITEM_SELLER' && data.items.length < 4) {
      return;
    }
    if (data.items.length > 0) {
      const itemIds = data.items.map((value: YuspItemResultInternal) => value?.itemId?.toString());

      let event: YuspShowRecommendationParams = {
        type: 'event',
        eventType: 'SHOW_RECOMMENDATION',
        recId: data.recommendationId,
        scenarioId,
        itemIds,
      };

      if (mainCategoryIdentifier) {
        mainCategoryIdentifier = this.prepareMainCategoryIdentifierParameter(mainCategoryIdentifier);
        event = {
          ...event,
          mainCategoryIdentifier,
        };
      }

      void this.createTrackPromise(event);
    }
  }

  /**
   * Sends event about show of Aukro recommendation.
   * There is no check whether split group is really `aukro` in this method,
   * because Aukro recommendation could be used as a fallback even if user's split group is `yusp`.
   * @param itemIds shown item IDs
   * @param scenarioId scenario type
   * @param mainCategoryIdentifier main category of showed items
   */
  public trackShowAukroRecommendation(
    itemIds: number[],
    scenarioId: YuspRecommendationScenarioType,
    mainCategoryIdentifier?: string,
  ): void {
    if (itemIds.length > 0) {
      const ids = itemIds.map((value: number) => value.toString());

      let event: YuspShowRecommendationParams = {
        type: 'event',
        eventType: 'SHOW_RECOMMENDATION',
        recId: '',
        scenarioId,
        itemIds: ids,
      };

      if (mainCategoryIdentifier) {
        mainCategoryIdentifier = this.prepareMainCategoryIdentifierParameter(mainCategoryIdentifier);
        event = {
          ...event,
          mainCategoryIdentifier,
        };
      }

      void this.createTrackPromise(event);
    }
  }

  /**
   * Main page - Category boxes (new placements in the future Aukro home page)
   * @param numberLimit
   * @param mainCategory
   * @param categoryIds
   * @returns
   */
  public recommendation_W_MAIN_CATBOX(numberLimit: number, mainCategory: string, categoryIds: string[]):
    Observable<YuspRecommendationResultInternal> {

    const event: YuspRecommendationParams = {
      type: 'recommendation',
      numberLimit,
      scenarioId: 'W_MAIN_CATBOX',
      mainCategory,
      categoryIds,
      resultNames: YuspRecommendationResultNames,
    };

    return this.sendRecommendationRequestAndMap(event);
  }

  /**
   * Item Page (We recommend from this seller) - new placement
   */
  public recommendation_W_ITEM_SELLER(
    numberLimit: number,
    offerId: string,
    sellerId: string,
    groupSize: number,
    groupSeq: number,
  ): Observable<YuspRecommendationResultInternal> {

    const event: YuspRecommendationParams = {
      type: 'recommendation',
      numberLimit,
      scenarioId: 'W_ITEM_SELLER',
      currentItemId: offerId,
      sellerId,
      groupId: 'itempage',
      groupSize,
      groupSeq,
      resultNames: YuspRecommendationResultNames,
    };

    return this.sendRecommendationRequestAndMap(event);
  }

  /**
   * Item Page (You may also like) - new placement
   * @param numberLimit
   * @param offerId
   * @param groupSize
   * @param groupSeq
   * @returns
   */
  public recommendation_W_ITEM_YMAL(numberLimit: number, offerId: string, groupSize: number, groupSeq: number):
    Observable<YuspRecommendationResultInternal> {

    const event: YuspRecommendationParams = {
      type: 'recommendation',
      numberLimit,
      scenarioId: 'W_ITEM_YMAL',
      currentItemId: offerId,
      groupId: 'itempage',
      groupSize,
      groupSeq,
      resultNames: YuspRecommendationResultNames,
    };

    return this.sendRecommendationRequestAndMap(event);
  }

  /**
   * Item Page - only for ended items
   * @param numberLimit
   * @param offerId
   * @param groupSize
   * @param groupSeq
   */
  public recommendation_W_ITEM_ENDED(numberLimit: number, offerId: string, groupSize: number, groupSeq: number):
    Observable<YuspRecommendationResultInternal> {

    const event: YuspRecommendationParams = {
      type: 'recommendation',
      numberLimit,
      scenarioId: 'W_ITEM_ENDED',
      currentItemId: offerId,
      groupId: 'itempage',
      groupSize,
      groupSeq,
      resultNames: YuspRecommendationResultNames,
    };

    return this.sendRecommendationRequestAndMap(event);
  }

  /**
   * Cart page - new placement
   * @param numberLimit
   * @param cartItemIds
   * @returns
   */
  public recommendation_W_CART(numberLimit: number, cartItemIds: string[]): Observable<YuspRecommendationResultInternal> {
    const event: YuspRecommendationParams = {
      type: 'recommendation',
      numberLimit,
      scenarioId: 'W_CART',
      cartItemIds,
      resultNames: YuspRecommendationResultNames,
    };

    return this.sendRecommendationRequestAndMap(event);
  }

  /**
   * No result page - new placement
   * @param numberLimit
   * @param query
   * @returns
   */
  public recommendation_W_NOHIT(numberLimit: number, query: string): Observable<YuspRecommendationResultInternal> {
    const event: YuspRecommendationParams = {
      type: 'recommendation',
      numberLimit,
      scenarioId: 'W_NOHIT',
      query,
      resultNames: YuspRecommendationResultNames,
    };

    return this.sendRecommendationRequestAndMap(event);
  }

  /**
   * 404 page - new placement
   * @param numberLimit
   * @returns
   */
  public recommendation_W_404(numberLimit: number): Observable<YuspRecommendationResultInternal> {
    const event: YuspRecommendationParams = {
      type: 'recommendation',
      numberLimit,
      scenarioId: 'W_404',
      resultNames: YuspRecommendationResultNames,
    };

    return this.sendRecommendationRequestAndMap(event);
  }

  /**
   * After purchase page - new placement
   * @param numberLimit
   * @param purchasedItemIds
   * @returns
   */
  public recommendation_W_AFTER_PURCHASE(numberLimit: number, purchasedItemIds: string[]): Observable<YuspRecommendationResultInternal> {
    const event: YuspRecommendationParams = {
      type: 'recommendation',
      numberLimit,
      scenarioId: 'W_AFTER_PURCHASE',
      purchasedItemIds,
      resultNames: YuspRecommendationResultNames,
    };

    return this.sendRecommendationRequestAndMap(event);
  }

  /**
   * Personal page of a seller (seller page) - new placement
   * @param numberLimit
   * @param sellerId
   * @returns
   */
  public recommendation_W_SELLER(numberLimit: number, sellerId: string): Observable<YuspRecommendationResultInternal> {
    const event: YuspRecommendationParams = {
      type: 'recommendation',
      numberLimit,
      scenarioId: 'W_SELLER',
      sellerId,
      resultNames: YuspRecommendationResultNames,
    };

    return this.sendRecommendationRequestAndMap(event);
  }

  /**
   * Gets split group from `reco1` cookie (takes just the part without version).
   * If the valid split group is not present in the cookie, `null` is returned, and the default split group is resolved later in
   * `getYuspRecommendDisabledFallbackWithoutGravityScript()`.
   * @returns `yusp` or `aukro` if split group is available and valid, `null` otherwise
   */
  public getSplitGroup(): YuspSplitGroupType | Nil {
    const cookie: string | Nil = this.personalizationMeasurementService.getGroup(APP_CONSTANTS.RECO1_COOKIE_SPLIT_GROUP_NAME, true);

    switch (cookie) {
      case PersonalizationConstants.MEASUREMENT_SPLIT_GROUP_VALUE_AUKRO:
        return PersonalizationConstants.MEASUREMENT_SPLIT_GROUP_VALUE_AUKRO;
      case PersonalizationConstants.MEASUREMENT_SPLIT_GROUP_VALUE_YUSP:
        return PersonalizationConstants.MEASUREMENT_SPLIT_GROUP_VALUE_YUSP;
      default:
        return null;
    }
  }

  /**
   * @returns split group from cookie if present and valid, 'aukro' otherwise
   */
  private getSplitGroupOrDefault(): YuspSplitGroupType {
    return this.getSplitGroup() ?? 'aukro';
  }

  public getEndedItemSplitGroup(): EndedItemSplitGroupType | Nil {
    if (this.getSplitGroup() !== 'yusp') {
      return null; // ended-item split group applies just for yusp
    }

    const cookieValue = this.personalizationMeasurementService.getGroup(APP_CONSTANTS.ENDED_ITEM_COOKIE_SPLIT_GROUP_NAME, true);
    return cookieValue as EndedItemSplitGroupType;
  }

  private logEvent(event: any): void {
    const cookieId: string = this.cookieService.get('gr_reco');
    const allowedEventForLogs: YuspEventType[] = ['CLICK', 'REC_CLICK'];

    if (event.eventType && allowedEventForLogs.indexOf(event.eventType) !== -1) {
      this.suggestApiService.saveYuspEventLog$({
        yuspEventsLogDto: {
          cookieId,
          eventType: event.eventType,
          itemIds: event.itemIds,
          recommendationId: event.recId,
          scenario: event.scenarioId,
          splitGroup: this.getSplitGroupOrDefault(),
        },
      }).subscribe();
    }
  }

  private pushEvent(event: YuspEventTrackingParams | YuspRecommendationParams): void {
    if (!event.type) {
      throw new Error('Property type must be set!');
    }

    this.logEvent(event);
    this.window._gravity = this.window._gravity || [];
    this.window._gravity.push(event);
  }

  private createTrackPromise(trackEvent: YuspEventTrackingParams): Promise<YuspEventTrackingParams | Nil> {
    if (!this.platformCommonService.isBrowser) {
      return new Promise<Nil>((resolve) => resolve(null));
    }

    return this.ngZoneUtilService.runOut(() => new Promise(() => {
      if (this.isYuspTrackDisabled()) {
        return null;
      }

      const event: YuspEventTrackingParams = {
        splitGroup: this.getSplitGroupOrDefault(),
        ...trackEvent,
      };

      // set userId if is logged in
      this.userService.getCurrentUserProfileMinimal()
        .pipe(
          take(1),
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe((userProfile: NewUserProfileBaseDto) => {
          if (userProfile) {
            const userLoggedEvent: YuspEventTrackingParams = {
              type: 'set',
              userId: userProfile.userId.toString(),
            };
            // set user into _gravity
            this.pushEvent(userLoggedEvent);
          }
          // push event
          this.pushEvent(event);
        });
    }));
  }

  private isYuspTrackDisabled(): boolean {
    return !this.isPersonalizationAllowed || !this.domainService.isCzLocale();
  }

  private sendRecommendationRequestAndMap(event: YuspRecommendationParams): Observable<YuspRecommendationResultInternal> {
    return from(this.sendRecommendationRequest(event))
      .pipe(
        map((rawYuspData: YuspRecommendationResult) => this.mapYuspRecommendationResult(rawYuspData)),
        mergeMap((internalYuspData: YuspRecommendationResultInternal) =>
          this.yuspPersonalizationPriceExchangeService.exchangeYuspRecommendationResult$(internalYuspData)),
      );
  }

  private sendRecommendationRequest(requestEvent: YuspRecommendationParams): Promise<YuspRecommendationResult> {
    return new Promise((resolve) => {
      const yuspRecommendDisabledFallback: FallbackReason = this.getYuspRecommendDisabledFallbackWithoutGravityScript();
      if (!isNil(yuspRecommendDisabledFallback)) {
        resolve({
          fallbackReason: yuspRecommendDisabledFallback,
        });
        return;
      }

      // workaround YUSP - in case of an error on YUSP side no callback is triggered, but we need to resolve the value for all situations
      const callbackTimeoutSub = this.ngZoneUtilService.timerOut$(DateUtils.convertSecondsToMilliseconds(2))
        .pipe(
          takeUntil(this.ngUnsubscribe),
        )
        .subscribe(() => {
          resolve({
            fallbackReason: this.getYuspRecommendDisabledFallbackWithGravityScript(),
          });
        });

      // Resolve with fallback reason if user has chosen to not give a cookie consent for YUSP (even if it not timed-out yet)
      const cookieConsentCallback: (status: boolean) => void = (status: boolean) => {
        if (status === false) {
          callbackTimeoutSub.unsubscribe();
          resolve({
            fallbackReason: 'YUSP_NOT_CALLED_CONSENT_REJECTED',
          });
        }
      };
      this.cookieConsentService.executeWhenConsentStatusRecognized(CookieConsentVendorEnum.YUSP, cookieConsentCallback);

      const event: YuspRecommendationParams = {
        ...requestEvent,
        callback: (result) => {
          // callback function after push into datalayer and return data into caller
          callbackTimeoutSub.unsubscribe();
          resolve(result);
        },
      };
      this.pushEvent(event);
    });
  }

  /**
   * YUSP shouldn't be called if some conditions are met, e.g. if not in browser, other than Czech locale etc.
   * In all these cases the aukro algorithm should be called instead of YUSP. We want to know the reason why YUSP wasn't called.
   * This method finds the reason and returns it. If there's no reason for not-calling YUSP (YUSP can be called), it returns null.
   */
  private getYuspRecommendDisabledFallbackWithoutGravityScript(): FallbackReason | Nil {
    // order of following checks is important
    if (this.platformCommonService.isServer) {
      return 'YUSP_NOT_CALLED_IS_SERVER';
    } else if (!this.domainService.isCzLocale()) {
      return 'YUSP_NOT_CALLED_NOT_CZ_LOCALE';
    } else if (this.getSplitGroup() === PersonalizationConstants.MEASUREMENT_SPLIT_GROUP_VALUE_AUKRO) {
      return 'YUSP_NOT_CALLED_SPLIT_GROUP_AUKRO';
    } else if (isNil(this.getSplitGroup())) {
      // If `reco1` cookie is missing, recommendation from Aukro should be called immediately (Aukro is default)
      return 'AUKRO_MISSING_RECO1_COOKIE';
    }
    return null;
  }

  /**
   * If YUSP wasn't called within given time interval (2 seconds), the aukro algorithm should be called instead.
   * We want to know the reason why YUSP wasn't called, e.g. user rejected consents, Gravity SDK is not loaded yet etc.
   * This method finds the reason and returns it.
   */
  private getYuspRecommendDisabledFallbackWithGravityScript(): FallbackReason {
    const userStatusForVendor: boolean = this.window.Didomi?.getUserStatusForVendor?.(CookieConsentVendorEnum.YUSP);

    // order of following checks is important
    if (isNil(userStatusForVendor)) {
      return 'YUSP_NOT_CALLED_CONSENT_NOT_DECIDED';
    } else if (!userStatusForVendor) {
      return 'YUSP_NOT_CALLED_CONSENT_REJECTED';
    } else if (isNil(this.window._gravity) || Array.isArray(this.window._gravity)) {
      return 'YUSP_NOT_CALLED_MISSING_GRAVITY_SDK';
    }
    return 'YUSP_NO_RESULT_TOO_LONG_PROCESSING';
  }

  private prepareMainCategoryIdentifierParameter(inputString: string): string {
    return removeAccentsDiacritics(inputString, '', true);
  }

  private mapYuspRecommendationResult(yuspDto: YuspRecommendationResult): YuspRecommendationResultInternal {
    if (isNil(yuspDto.fallbackReason) && isEmpty(yuspDto.items)) {
      // there's no fallback reason, so YUSP was called but didn't return any results
      return {
        fallbackReason: 'YUSP_NO_RESULT',
      };
    }

    return {
      content: yuspDto.content,
      outputNameValues: yuspDto.outputNameValues,
      predictionValues: yuspDto.predictionValues,
      recommendationId: yuspDto.recommendationId,
      items: this.mapYuspItems(yuspDto.items),
      fallbackReason: yuspDto.fallbackReason,
    };
  }

  private mapYuspItems(source: YuspItemResult[]): YuspItemResultInternal[] {
    if (ArrayUtils.isEmpty(source)) {
      return [];
    }

    const items = [...source];
    return items.map((item) => this.mapYuspItem(item));
  }

  private mapYuspItem(source: YuspItemResult): YuspItemResultInternal {

    const yuspRecommendationCurrencyEnum: CurrencyCode = 'CZK';

    return {
      itemId: NumberUtils.parseNumberOrDefault(source.itemId, null),
      type: source.type,
      categoryIdPath: source.categoryIdPath,
      link: source.link,
      imageUrlMedium: source.imageUrlMedium,
      title: source.title,
      sellerAukroPlus: source.sellerAukroPlus,
      freeShipping: source.freeShipping,
      paidPromoBoldTitle: source.paidPromoBoldTitle,
      paidPromoCategoryPage: source.paidPromoCategoryPage,
      paidPromoHighlight: source.paidPromoHighlight,
      paidPromoMainPage: source.paidPromoMainPage,
      paidPromoPriorityList: source.paidPromoPriorityList,
      biddingPrice: { amount: NumberUtils.parseNumberOrDefault(source.biddingPrice, null), currency: yuspRecommendationCurrencyEnum },
      buyNowPrice: { amount: NumberUtils.parseNumberOrDefault(source.buyNowPrice, null), currency: yuspRecommendationCurrencyEnum },
      priceWithShipping: {
        amount: NumberUtils.parseNumberOrDefault(source.priceWithShipping, null),
        currency: yuspRecommendationCurrencyEnum,
      },
      endingTime: source.endingTime,
    };
  }

}
