import { Injectable } from '@angular/core';
import { combineLatestWith, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, skip } from 'rxjs/operators';
import { CurrencyService } from './currency.service';
import { CurrencyConfigElementEnumModel } from '../model/currency-config-element-enum.model';
// TODO: [PDEV-18307] - fix dependency
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { CurrencyEnumParams } from '@api/generated/api/Currency';
import { UserCurrencyPreferenceDataService } from './user-currency-preference.data.service';
import { CurrencyCode } from '../model/currency-code.model';
import isNil from 'lodash-es/isNil';
import { DEFAULT_CURRENCY } from '@shared/currency/const/currency.constants';

@Injectable({
  providedIn: 'root',
})
export class UserCurrencyPreferenceService extends NgUnsubscribe {

  constructor(private readonly currencyService: CurrencyService,
              private readonly userCurrencyPreferenceDataService: UserCurrencyPreferenceDataService,
  ) {
    super();
  }

  /**
   * Emits when preferred currency is changed. Does not emit first value on subscribe.
   */
  public get preferredCurrencyCodeChanged$(): Observable<void> {
    return this.userCurrencyPreferenceDataService.currentCurrencyCode$
      .pipe(
        distinctUntilChanged(),
        map(() => void 0),
        skip(1),
      );
  }

  public get preferredCurrencyCode$(): Observable<CurrencyCode> {
    return this.userCurrencyPreferenceDataService.currentCurrencyCode$
      .pipe(filter((currency) => !isNil(currency)));
  }

  public get preferredCurrencyCodeValue(): CurrencyCode {
    return this.userCurrencyPreferenceDataService.currentCurrencyCodeValue;
  }

  public get preferredCurrencyModel$(): Observable<CurrencyConfigElementEnumModel> {
    return this.userCurrencyPreferenceDataService.currentCurrencyCode$
      .pipe(
        combineLatestWith(this.currencyService.getCurrencies$()),
        map(([userCurrency, allCurrencies]) => this.findByCurrencyCodeOrDefault(allCurrencies, userCurrency)),
      );
  }

  public set preferredCurrencyCode(val: CurrencyCode) {
    this.userCurrencyPreferenceDataService.currentCurrencyCode = val;
  }

  private findByCurrencyCodeOrDefault(
    allCurrencies: CurrencyConfigElementEnumModel[],
    currencyCode: CurrencyEnumParams,
  ): CurrencyConfigElementEnumModel {
    return allCurrencies?.find((currency) => currency?.code === currencyCode) ?? DEFAULT_CURRENCY;
  }

}
