import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Translate2Module } from '@common/translations/translate2.module';
import { TopLineBannerComponent } from '@shared/banner/component/top-line-banner/top-line-banner.component';
import { IsMobileDirective } from '@shared/legacy/directive/is-mobile.directive';
import { BannerCloseButtonComponent } from '@shared/banner/component/banner-close-button/banner-close-button.component';
import { CategoryBannerPopupComponent } from '@shared/banner/component/category-banner-popup/category-banner-popup.component';
import { SvgIconModule } from '@shared/legacy/component/svg-icon/svg-icon.module';
import { BannerComponent } from '@shared/banner/component/banner/banner.component';
import { IconComponent } from '@common/ui-kit/component/icon/component/icon.component';
import { ThirdPartyBannerComponent } from '@shared/banner/component/third-party-banner/third-party-banner.component';
import { ReplaceByPlaceholderOnErrorDirective } from '@common/image-loading/directive/replace-by-placeholder-on-error.directive';
import { InnerHtmlDirective } from '@common/html/directive/inner-html.directive';

@NgModule({
  imports: [
    CommonModule,
    Translate2Module,

    IsMobileDirective,
    SvgIconModule,
    ReplaceByPlaceholderOnErrorDirective,
    IconComponent,
    ThirdPartyBannerComponent,
    BannerComponent,
    InnerHtmlDirective,
  ],
  declarations: [
    BannerCloseButtonComponent,
    CategoryBannerPopupComponent,
    TopLineBannerComponent,
  ],
  exports: [
    BannerComponent,
    CategoryBannerPopupComponent,
    TopLineBannerComponent,
  ],
})
export class BannerModule {
}
