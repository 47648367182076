import { Injectable } from '@angular/core';
import { SessionStorageService } from '@common/services/storage/session-storage.service';
import isNil from 'lodash-es/isNil';
import { ItemSearchFilterExtended } from '../../../../typings/original/internal';
import { ArrayUtils } from '@util/util/array.utils';

type SESSION_SAVED_FILTER = 'DESTINATION_COUNTRY';

export const SESSION_SAVED_FILTER_KEYS: Record<SESSION_SAVED_FILTER, string> = {
  DESTINATION_COUNTRY: 'destinationCountries',
};

@Injectable({
  providedIn: 'root',
})
export class SavedFiltersService {

  constructor(private readonly sessionStorageService: SessionStorageService) {
  }

  public getSavedFilters(): ItemSearchFilterExtended {
    const savedFilters: ItemSearchFilterExtended = {};
    const destinationCountries = this.sessionStorageService.getItem<string[]>(SESSION_SAVED_FILTER_KEYS.DESTINATION_COUNTRY);
    if (!isNil(destinationCountries) && ArrayUtils.isNotEmpty(destinationCountries)) {
      savedFilters.destinationCountries = destinationCountries;
    }
    return savedFilters;
  }

  public clearSavedFilters(): void {
    Object.values(SESSION_SAVED_FILTER_KEYS).forEach((value: string) => this.sessionStorageService.removeItem(value));
  }

  public setSavedFilter(searchFilter: ItemSearchFilterExtended): void {
    if (isNil(searchFilter)) {
      return;
    }
    if (searchFilter.destinationCountries) {
      this.saveToSessionStorage(SESSION_SAVED_FILTER_KEYS.DESTINATION_COUNTRY, searchFilter.destinationCountries);
    }
  }

  private saveToSessionStorage(filterName: string, value: boolean | string | string[]): void {
    if (isNil(filterName) || isNil(value)) {
      return;
    }
    this.sessionStorageService.setItem(filterName, value);
  }

}
