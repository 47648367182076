import { Injectable } from '@angular/core';
import { CacheAware } from '@common/cache/model/cache-aware';
import { CacheService } from '@common/cache/service/cache.service';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CurrencyConfigElementEnumModel } from '../model/currency-config-element-enum.model';
import { ConfiguratorCacheService } from '@shared/services/configurator-cache/configurator-cache.service';
import { ConfigElementEnum } from '@api/generated/defs/ConfigElementEnum';
import { ConfigElementEnumBaseMetadata } from '@api/generated/defs/ConfigElementEnumBaseMetadata';
import { Cacheable } from '@common/cache/decorator/cacheable';
import { CurrencyModelMap } from '@shared/currency/model/currency-model-map.type';
import { CurrencyCode } from '@shared/currency/model/currency-code.model';
import { CurrencyUtil } from '@shared/currency/util/currency.util';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService implements CacheAware {

  constructor(private readonly configuratorCacheService: ConfiguratorCacheService,
              public readonly cacheService: CacheService) {
  }

  @Cacheable('CurrencyService#getCurrencies')
  public getCurrencies$(): Observable<CurrencyConfigElementEnumModel[]> {
    return this.configuratorCacheService.enums<ConfigElementEnumBaseMetadata, CurrencyConfigElementEnumModel>('CURRENCY_ENUM')
      .pipe(
        take(1),
        map((configElementEnum: ConfigElementEnum<ConfigElementEnumBaseMetadata, CurrencyConfigElementEnumModel>) =>
          configElementEnum?.content),
        map((configElementEnumContent: CurrencyConfigElementEnumModel[]) =>
          configElementEnumContent.filter(currency => currency.active)),
      );
  }

  // TODO(PDEV-20494)
  public getCurrenciesMap$(): Observable<CurrencyModelMap> {
    return this.getCurrencies$()
      .pipe(
        map((arr: CurrencyConfigElementEnumModel[]) => CurrencyUtil.currenciesModelArrToMap(arr)),
      );
  }

  // TODO(PDEV-20494)
  public getCurrencyModelByCode$(currencyCode: CurrencyCode): Observable<CurrencyConfigElementEnumModel> {
    return this.getCurrenciesMap$()
      .pipe(
        map((currencyModel) => currencyModel[currencyCode]),
      );
  }

}
