import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslationSource } from '@common/translations/model/translation-source';
import { DialogAlert } from './dialog-alert';
import { getTrackByTranslationSourceFn } from '@util/helper-functions/get-track-by-translation-source-fn';

/**
 * Wraps material dialog title, content and actions
 * @example
 * ```
 * <auk-dialog-content dialogTitle="Title goes here" dialogSubtitle="Subtitle goes here">
 *   <ng-container content>
 *     // Dialog content goes here
 *   </ng-container>
 *
 *   <ng-container actions>
 *     // Dialog actions goes here
 *   </ng-container>
 * </auk-dialog-content>
 * ```
 */
@Component({
  selector: 'auk-dialog-content',
  templateUrl: './dialog-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentComponent {

  public trackByDialogAlertTextFn = getTrackByTranslationSourceFn('text');

  @Input()
  public dialogTitle: TranslationSource = null;

  @Input()
  public dialogSubtitle: TranslationSource = null;

  /**
   * Overflow:inherit in mat-dialog-content is required for using auk-aukro-info-hover (tooltip).
   */
  @Input()
  public isOverflowAuto: boolean = true;

  /**
   * In case that the <ng-container actions> is empty, useless space is in the popup.
   * It's possible to use this flag in order to hide this container.
   */
  @Input()
  public isActionsShown: boolean = true;

  @Input()
  public dialogAlerts: DialogAlert[] = [];

}
