import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'auk-cookie-disclaimer',
  templateUrl: './cookie-disclaimer.component.html',
  styleUrls: ['./cookie-disclaimer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieDisclaimerComponent {
}
