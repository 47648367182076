import { Injectable } from '@angular/core';
import { AppInitializer } from '@util/helper-types/initializer.interface';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { DateAdapter } from '@angular/material/core';
import { DomainService } from '@shared/platform/domain.service';

@Injectable({
  providedIn: 'root',
})
export class AppDateLocaleInitializer implements AppInitializer {

  constructor(
    private readonly dateAdapter: DateAdapter<Date>,
    private readonly domainService: DomainService,
  ) {
  }

  public init(): void {
    this.dateAdapter.setLocale(
      this.domainService.lang,
    );
  }

}
