import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, Optional } from '@angular/core';

import { DropdownDirective } from '@shared/legacy/directive/dropdown/dropdown.directive';
import isNil from 'lodash-es/isNil';
import { DialogDropdownDirective } from '@common/dialog/directive/dialog-dropdown.directive';

@Directive({
  selector: '[aukDropdownContainer]',
})
export class DropdownContainerDirective implements AfterViewInit {

  @HostBinding('style.display') public display: 'none' | 'block';

  constructor(@Optional() public dropdown: DropdownDirective,
              @Optional() public dialogDropdown: DialogDropdownDirective,
              private elementRef: ElementRef) {
  }

  public ngAfterViewInit(): void {
    const dropdownInstance = isNil(this.dialogDropdown) ? this.dropdown : this.dialogDropdown;
    dropdownInstance.container = this;
    dropdownInstance.containerElement = this.elementRef.nativeElement;
  }

  @HostListener('click', ['$event'])
  public onclick(e: MouseEvent): void {
    if (this.dropdown.autoClose) {
      e.stopPropagation();
      this.dropdown.close();
    }
  }

}
