<!--PDEV-22807 class and style attribute will be removed after update all background color of user chip into color combination id-->
<div class="auk-card-chip-wrapper"
  [matTooltip]="chipInfo.tooltip | translateSource"
  matTooltipClass="tooltip-lg"
  matTooltipPosition="above"
  [matTooltipShowDelay]="250"
  [matTooltipDisabled]="!chipInfo.tooltip"
  [class.color-combination]="!!chipInfo.colorCombination"
  [style.background-color]="backgroundColor"
  [style.color]="fontColor"
  >
  @if (getChipTextWithCount) {
    <div
      class="auk-card-chip auk-card-chip-with-count"
      >
      {{ $any(chipInfo).count }}
    </div>
  }
  <div class="auk-card-chip"
    [class.!tw-pl-2]="getChipTextWithCount"
    >
    @if (chipInfo.icon) {
      <auk-icon
        [iconType]="chipInfo.icon.type"
        [source]="chipInfo.icon.source"
        [colorCombination]="chipInfo.icon.colorCombination"
        size="MD"
        />
    }
    <span>{{ chipInfo.label | translateSource }}</span>
  </div>
</div>
