import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { ToastAction } from '@common/toast/model/toast-action';
import { DomainService } from '@shared/platform/domain.service';
import { UserActionType } from '@shared/user-action/model/user-action.type';
import { PaymentApiService } from '@api/aukro-api/api/payment-api.service';
import { HttpContext } from '@angular/common/http';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';

/**
 * Service for verifying user bank account
 */
@Injectable({
  providedIn: 'root',
})
export class BankAccountVerificationService extends NgUnsubscribe {

  constructor(private readonly userTaskService: UserTaskService,
    private readonly domainService: DomainService,
    private readonly paymentApiService: PaymentApiService) {
    super();
  }

  public openBankAccountVerificationDialog(source: string, userAction?: UserActionType, bankAccountNumberToVerify?: string): void {
    this.userTaskService.executeTaskWithCommonAndCustomPayload$(
      'VERIFIED_BANK_ACCOUNT',
      { action: `universal run from source: ${ source }` },
      { source, bankAccountNumberToVerify },
      userAction,
    )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }

  private checkPrerequisitesForNewVerification(source: string): void {
    this.paymentApiService.checkPrerequisitesForNewVerification$({},
      new HttpContext().set(USER_ACTION_TYPE, 'VERIFY_THROUGH_PAYMENT'))
      .subscribe({
        next: () => {
          this.openBankAccountVerificationDialog(source);
        },
      });
  }

  public getUserNotVerifiedForBuyToastAction(source: string): ToastAction[] {
    return this.domainService.isCzLocale() ? [{
      actionCallBack:
        () => this.checkPrerequisitesForNewVerification(source),
      label: { key: 'ALERT_BID_STATUS_USER_NOT_VERIFIED_FOR_BUY_BUTTON' },
      colorCombination: 'WARNING_CONTRAST',
    }] : [];
  }

}
