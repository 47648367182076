import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import isNil from 'lodash-es/isNil';

import { CountryCodes } from '../../app.constants';

import {
  BANK_ACCOUNT_PATTERN,
  CITY_PATTERN,
  CZ_VAT_PATTERN,
  EMAIL_PATTERN,
  NAME_PATTERN,
  PICKUP_POINT_STREET_PATTERN,
  REGISTRATION_LOGIN_PATTERN,
  SK_VAT_PATTERN,
  STREET_PATTERN,
  VAT_FOREIGN_PATTERN,
  WEBHOOK_URL_PATTERN,
  ZIP_CODE_TEXT_MASK_CZ_LIKE,
  ZIP_FOREIGN_PATTERN,
} from './patterns';
import { RegistryCountryItemDto } from '@api/generated/defs/RegistryCountryItemDto';
import { getCountryByIdOrCode } from '@shared/util/method/country-by-id-or-code-util';
import { Nil } from '@util/helper-types/nil';
import { getZipMaskByCountryId } from '@shared/util/method/get-zip-mask-by-county-id.util';
import { MoneyUtils } from '@shared/currency/util/money.utils';

/**
 *
 * @param abstractControl
 */
export function cityNameValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(CITY_PATTERN, 2, 150, 'city', abstractControl, false);
}

/**
 *
 * @param abstractControl
 */
export function personNameValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(NAME_PATTERN, 2, 150, 'personName', abstractControl, false);
}

/**
 *
 * @param abstractControl
 */
export function loginValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(REGISTRATION_LOGIN_PATTERN, 2, 16, 'login', abstractControl, false);
}

/**
 *
 * @param abstractControl
 */
export function emailValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(EMAIL_PATTERN, 5, 254, 'email', abstractControl, false);
}

/**
 *
 * @param abstractControl
 */
export function nullableEmailValidator(abstractControl: AbstractControl): Nil | ValidationErrors {
  if (!abstractControl.dirty || !abstractControl.value) {
    return null;
  }
  return emailValidator(abstractControl);
}

/**
 *
 * @param abstractControl
 */
export function webhookUrlValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(WEBHOOK_URL_PATTERN, 5, 511, 'webhookUrl', abstractControl, false);
}

// todo(radim.ehrlich): Move this functionality direct to personNameValidator and check consequences.
// If empty, field is valid. If value, return personNameValidator() output.
/**
 *
 * @param abstractControl
 */
export function personNameAllowEmptyValidator(abstractControl: AbstractControl): ValidationErrors {
  if (!abstractControl.value) {
    return null;
  }
  return personNameValidator(abstractControl);
}

/**
 *
 * @param abstractControl
 */
export function streetValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(STREET_PATTERN, 2, 150, 'street', abstractControl, false);
}

/**
 *
 * @param abstractControl
 */
export function pickupPointStreetValidator(abstractControl: AbstractControl): ValidationErrors {
  return validatePattern(PICKUP_POINT_STREET_PATTERN, 2, 150, 'street', abstractControl, false);
}

/**
 *
 * @param abstractControl
 */
export function bankAccountNumberValidator(abstractControl: AbstractControl): ValidationErrors | null {
  return validatePattern(BANK_ACCOUNT_PATTERN, 2, 150, 'bankAccountNumber', abstractControl, false);
}

/**
 *
 * @param countryControlPath
 */
export function vatValidator(countryControlPath: string): (abstractControl: AbstractControl) => (null | ValidationErrors) {
  return (abstractControl: AbstractControl) => {
    const { value } = abstractControl;
    const countryControl = abstractControl.root.get(countryControlPath);
    if (value === null) {
      return null;
    }
    if (countryControl) {
      if (countryControl.value && countryControl.value.id === CountryCodes.CZECH_REPUBLIC) { // Czech
        return validatePattern(CZ_VAT_PATTERN, 0, 20, 'vat', abstractControl, false);
      }
      if (countryControl.value && countryControl.value.id === CountryCodes.SLOVAKIA) { // Slovakia
        return validatePattern(SK_VAT_PATTERN, 0, 20, 'vat', abstractControl, false);
      }
    }
    return validatePattern(VAT_FOREIGN_PATTERN, 0, 20, 'vat', abstractControl, false);
  };
}

/**
 *
 * @param minLength
 */
export function numberMinLengthValidator(minLength: number): (abstractControl: AbstractControl) => ValidationErrors {
  return (abstractControl: AbstractControl) => minNumberLength(abstractControl?.value, minLength);
}

/**
 * Validates text-mask https://github.com/text-mask/text-mask
 * @param mask
 * @param validatorName
 */
export function textMaskValidator(mask: (string | RegExp)[], validatorName: string):
  (control: AbstractControl) => (null | Record<string, unknown>) {
  const INVALID_ANSWER = {};
  INVALID_ANSWER[validatorName] = {
    valid: false,
  };
  return (control: AbstractControl) => {
    if (!control.value) {
      // do not validate empty value to allow optional controls
      return null;
    }
    let maskInvalid = control.value.length !== mask.length;
    if (maskInvalid) {
      return INVALID_ANSWER;
    }
    mask.forEach((letterMask, index) => {
      if (typeof letterMask === 'string') {
        if (control.value[index] !== letterMask) {
          maskInvalid = true;
        }
      } else {
        if (!letterMask.test(control.value[index])) {
          maskInvalid = true;
        }
      }
    });
    return maskInvalid ? INVALID_ANSWER : null;
  };
}

/**
 *
 * @param max
 * @param strict
 */
export function maxValue(max: number, strict: boolean = true): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let input = control.value;

    if (MoneyUtils.isMoneyDto(input)) {
      input = input?.amount;
    }

    const isValid = strict ? (input > max) : (input >= max);
    if (isValid) {
      return {
        maxValue: { max },
      };
    } else {
      return null;
    }
  };
}

/**
 *
 * @param min
 * @param strict
 */
export function minValue(min: number, strict: boolean = true): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let input = control.value;

    if (MoneyUtils.isMoneyDto(input)) {
      input = input?.amount;
    }

    const isValid = strict ? (input < min) : (input <= min);

    if (isValid) {
      return {
        minValue: { min },
      };
    } else {
      return null;
    }
  };
}

/**
 *
 * @param count
 */
export function maxNumbersAfterComma(count: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const error = { maxNumbersAfterComma: { count } };
    let value = control.value;

    if (MoneyUtils.isMoneyDto(value)) {
      value = value?.amount;
    }

    value = parseFloat(value);
    if (isFinite(value) && !isNaN(value)) {
      const input = value.toString().split('.');
      if (input[1] && input[1].length > count) {
        return error;
      } else {
        return null;
      }
    }
    return error;
  };
}

const POSTAL_CODE_MIN_LENGTH = 1;
const POSTAL_CODE_MAX_LENGTH = 12;

/**
 *
 * @param countryControlPath
 * @param countries
 */
export function zipCodeValidatorByPattern(countryControlPath: string, countries: RegistryCountryItemDto[]): ValidatorFn {
  return (abstractControl: AbstractControl) => {
    if (isNil(countries)) {
      return {};
    }
    const countryControl = abstractControl.root.get(countryControlPath);
    if (isNil(countryControl)) {
      return {};
    }
    const postcodeRegexp = getCountryByIdOrCode(isNil(countryControl?.value?.id) ?
      countryControl?.value : countryControl?.value?.id, countries)?.postcodeRegexp;
    if (isNil(postcodeRegexp) || !countryControl?.value) {
      return {};
    }
    return validatePattern(
      new RegExp(
        postcodeRegexp,
      ),
      POSTAL_CODE_MIN_LENGTH,
      POSTAL_CODE_MAX_LENGTH,
      'zipCode',
      abstractControl,
      false,
    );
  };
}

/**
 *
 * @param countryControlPath
 */
export function zipCodeValidator(countryControlPath: string): ValidatorFn {
  const validator = textMaskValidator(ZIP_CODE_TEXT_MASK_CZ_LIKE, 'zipCode');
  if (!countryControlPath) {
    return validator;
  }
  return (abstractControl: AbstractControl) => {
    const countryControl = abstractControl.root.get(countryControlPath);
    if (countryControl) {
      if (countryControl.value &&
        !getZipMaskByCountryId(countryControl.value.id || countryControl.value)) {
        return validatePattern(ZIP_FOREIGN_PATTERN, 1, 12, 'zipCode', abstractControl, false);
      }
    }
    return validator(abstractControl);
  };
}

/**
 *
 * @param fieldName
 */
export function multipleCheckboxesOneRequired(fieldName: string = 'checked'): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } => {
    const checked = formArray.controls.filter((formGroup: FormGroup) => formGroup.get(fieldName).value === true);
    if (checked.length === 0) {
      return {
        multipleCheckboxesOneRequired: false,
      };
    }

    return null;
  };
}

/**
 *
 * @param pattern
 * @param minLength
 * @param maxLength
 * @param errorField
 * @param root0
 * @param root0.value
 * @param lengthInNumbers
 */
function validatePattern(pattern: RegExp,
                         minLength: number,
                         maxLength: number,
                         errorField: string,
                         { value }: AbstractControl,
                         lengthInNumbers: boolean): ValidationErrors | null {
  if (typeof value !== 'string' || !pattern.test(value)) {
    return { [errorField]: true };
  }
  return validateLength(value, minLength, maxLength, lengthInNumbers);
}

/**
 *
 * @param value
 * @param minLength
 * @param maxLength
 * @param lengthInNumbers
 */
function validateLength(value: string,
                        minLength: number,
                        maxLength: number,
                        lengthInNumbers: boolean): ValidationErrors | null {
  if (!value.length) {
    return null;
  }
  const length = lengthInNumbers ? value.replace(/[^0-9]/g, '').length : value.length;
  if (length < minLength) {
    return { minlength: true };
  }
  if (length > maxLength) {
    return { maxlength: true };
  }
  return null;
}

/**
 *
 * @param value
 * @param minLength
 */
function minNumberLength(value: number,
                         minLength: number): ValidationErrors | null {
  if (isNil(minLength)) {
    return null;
  }

  if (isNil(value)) {
    return { minlength: true };
  }

  const length = Math.max(Math.floor(Math.log10(Math.abs(value))), 0) + 1;
  if (length < minLength) {
    return { minlength: true };
  }

  return null;
}

/**
 *
 * @param abstractControl
 */
export function trimRequired(abstractControl: AbstractControl): ValidationErrors | null {
  const v = abstractControl.value;
  if (typeof v === 'string') {
    return v.trim() ? null : { required: true };
  }
  return null;
}
