@if (shouldShowApproximation) {
  <div
    class="tw-text-tertiary-500"
    >
    {{ 'CURRENCY_APPROXIMATION_TEXT' | translate }}
    <span>
      {{ price | currency: 'EUR':false }}
    </span>
  </div>
}
