import { Injectable } from '@angular/core';
import { UserActionPrerequisiteFulfillmentService } from './user-action-prerequisite-fulfillment.service';
import { InformationDialogComponent } from '@common/information-dialog/information-dialog.component';
import { map, take } from 'rxjs/operators';
import { Observable, switchMap } from 'rxjs';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';

@Injectable({
  providedIn: 'root',
})
export class UserActionPrerequisiteVerifiedCompanyFulfillmentService
  extends UserActionPrerequisiteFulfillmentService<['VERIFIED_COMPANY']> {

  public readonly prerequisiteCombination: ['VERIFIED_COMPANY'] = ['VERIFIED_COMPANY'];

  constructor(
    private readonly aukMatDialogService: AukMatDialogService,
  ) {
    super();
  }

  public fulfillPrerequisite(): Observable<void> {
    return this.aukMatDialogService.openSimple$(InformationDialogComponent, {
      maxWidth: '400px',
      data: {
        message: { key: 'USER_CHANGING_COMPANY_INFORMATION_DIALOG_MESSAGE' },
        title: { key: 'USER_CHANGING_COMPANY_INFORMATION_DIALOG_TITLE' },
        confirm: { key: 'USER_CHANGING_COMPANY_INFORMATION_DIALOG_CONFIRM' },
      },
      panelClass: ['user-modal-dialog'],
    })
      .pipe(
        switchMap((dialogRef) => dialogRef.afterClosed()),
        take(1),
        map(() => {
          throw new Error('Company account must be verified');
        }),
      );
  }

}
