import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { PlatformService } from '@shared/platform/service/platform.service';
import { ShareDataService } from '@shared/services/share-data/share-data.service';
import { UserService } from '@shared/user/service/user.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import isNil from 'lodash-es/isNil';
import { DisclaimerResult } from './disclaimer-result';

@Component({
  selector: 'auk-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent extends NgUnsubscribe implements OnInit {

  public isRememberActive: boolean = false;
  public areButtonsDisabled: boolean = true;

  private userId: number;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly shareDataService: ShareDataService,
    private readonly platformService: PlatformService,
    public readonly dialogRef: MatDialogRef<DisclaimerComponent>,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.platformService.isServer) {
      return;
    }

    this.userService.getActualStatistics()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((stats) => {
        if (!isNil(stats)) {
          this.userId = stats.userId;
        }
        this.areButtonsDisabled = false;
      });
  }

  public onAllow(): void {
    const result: DisclaimerResult = { consent: true, remember: this.isRememberActive, id: this.userId };

    this.dialogRef.close(result);
  }

  public onDeny(): void {
    this.shareDataService.showSuccessMessageTranslated('ERROR_MESSAGE_CATEGORY_FORBIDEN', 'error');
    void this.router.navigateByUrl('/', { replaceUrl: true });
    this.dialogRef.close(null);
  }

}
