import { Injectable } from '@angular/core';
import { UserActionPrerequisiteTaskFulfillmentService } from '../task-fulfillment/user-action-prerequisite-task-fulfillment.service';
import { UserTaskType } from '../../../user-task/base/model/user-task.type';
import { UserTaskService } from '../../../user-task/base/service/user-task.service';

@Injectable({
  providedIn: 'root',
})
export class UserActionPrerequisiteVerifiedBankAccountFulfillmentService
  extends UserActionPrerequisiteTaskFulfillmentService<['VERIFIED_BANK_ACCOUNT']> {

  public readonly prerequisiteCombination: ['VERIFIED_BANK_ACCOUNT'] = ['VERIFIED_BANK_ACCOUNT'];

  protected readonly taskType: UserTaskType = 'VERIFIED_BANK_ACCOUNT';

  constructor(
    userTaskService: UserTaskService,
  ) {
    super(userTaskService);
  }

}
