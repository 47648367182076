import { Injectable } from '@angular/core';
import { CurrencyEnumParams } from '@api/generated/api/Currency';
import { ExchangeRatesService } from './exchange-rates.service';
import { Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ExchangeRatesDto } from '@api/generated/defs/ExchangeRatesDto';
import isNil from 'lodash-es/isNil';
import { ExchangeRateDto } from '@api/generated/defs/ExchangeRateDto';
// TODO: [PDEV-18307] - fix dependency
import { MoneyDto } from '@api/generated/defs/MoneyDto';
import { DEFAULT_CURRENCY } from '@shared/currency/const/currency.constants';

@Injectable({
  providedIn: 'root',
})
export class ExchangedPriceService {

  constructor(
    private readonly exchangeRatesService: ExchangeRatesService,
  ) {
  }

  public priceMoney(value: MoneyDto, targetCurrency: CurrencyEnumParams): Observable<number> {
    return this.price(value.amount, value.currency, targetCurrency);
  }

  public price(value: number, sourceCurrency: CurrencyEnumParams, targetCurrency: CurrencyEnumParams): Observable<number> {
    if (sourceCurrency === targetCurrency || isNil(value)) {
      return of(value);
    }

    return this.exchangeRatesService.exchangeRates(sourceCurrency)
      .pipe(
        take(1),
        map((exchangeRate: ExchangeRatesDto) => exchangeRate?.rates?.find((rate: ExchangeRateDto) => rate.currency === targetCurrency)),
        filter((rate: ExchangeRateDto) => !isNil(rate)),
        map((exchangeRate: ExchangeRateDto) => value / exchangeRate.rate),
      );
  }

  public toDefaultCurrency(value: MoneyDto): Observable<MoneyDto> {
    return this.price(value.amount, value.currency, DEFAULT_CURRENCY.code)
      .pipe(
        map((price) => ({ amount: price, currency: DEFAULT_CURRENCY.code })),
      );
  }

  /**
   * Method for ensuring given currency rates are used when exchanging price
   * @param money
   * @param rates
   * @param targetCurrency
   */
  public exchange(money: MoneyDto, rates: ExchangeRatesDto, targetCurrency: CurrencyEnumParams): MoneyDto {
    if (isNil(money)) {
      return null;
    }

    // no need to exchange
    if (money.currency === targetCurrency) {
      return money;
    }

    if (money.currency !== rates.currency) {
      throw new Error(`Exchanging with different currency rates! Money: ${ money.currency }, rates: ${ rates.currency } !`);
    }

    const targetRate = rates?.rates?.find((rate: ExchangeRateDto) => rate.currency === targetCurrency);

    if (isNil(targetRate)) {
      throw new Error('Rate for currency:' + targetCurrency + ' not found');
    }

    return this.exchangeWithRate(money, targetRate);
  }

  /**
   * Exchange with rate. WARNING: be sure that you use rate with correct currency
   * @param money
   * @param rate
   */
  private exchangeWithRate(money: MoneyDto, rate: ExchangeRateDto): MoneyDto {
    return { amount: money.amount / rate.rate, currency: rate.currency };
  }

}
