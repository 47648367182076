import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';
import { PlatformService } from '@shared/platform/service/platform.service';
import { DomainCode } from '@shared/platform/domain.model';
import { DomainService } from '@shared/platform/domain.service';
import { WINDOW_OBJECT } from '@util/const/window-object';
import isNil from 'lodash-es/isNil';
import { UrlService } from '@shared/platform/url.service';
import { take, takeUntil } from 'rxjs/operators';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';

@Injectable({
  providedIn: 'root',
})
export class DomainRedirectService extends NgUnsubscribe {

  constructor(
    private readonly router: Router,
    private readonly platformService: PlatformService,
    private readonly domainService: DomainService,
    private readonly urlService: UrlService,
    @Inject(WINDOW_OBJECT) private readonly window: Window,
  ) {
    super();
  }

  public crossDomainRegistrationNotAllowedRedirect(isRegistration: boolean, redirectDomainCode: DomainCode): void {
    if (isRegistration) {
      // Czech translation after / because czech routes are "keys"
      void this.router.navigate(['/registrace']);
    } else {
      // Different behaviour for aps
      if (PlatformCommonService.isNativeApp) {
        void this.domainService.saveDomainPreference(redirectDomainCode);
        return;
      }

      if (redirectDomainCode === 'SK') {
        this.urlService.translateUrl(this.router.url)
          .pipe(
            take(1),
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe({
            next: (translatedRoute: string) => {
              this.openCrossDomain(this.platformService.getDomainUrl(redirectDomainCode), translatedRoute);
            },
          });
      } else {
        this.urlService.reverseTranslateUrl(this.router.url)
          .pipe(
            take(1),
            takeUntil(this.ngUnsubscribe),
          )
          .subscribe({
            next: (translatedRoute: string) => {
              this.openCrossDomain(this.platformService.getDomainUrl(redirectDomainCode), translatedRoute);
            },
          });
      }
    }
  }

  private openCrossDomain(regDomainUrl: string, urlPart: string): void {
    // Open same location if exists, otherwise HP
    this.window.open(regDomainUrl + (!isNil(urlPart) ? urlPart : ''));
  }

}
