import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';

import { VersionDto } from '@api/generated/defs/VersionDto';
import { BuildInfoService } from '@shared/version/service/build-info.service';
import { ShareDataService } from '@shared/services/share-data/share-data.service';
import { PlatformCommonService } from '@common/platform/service/platform-common.service';
import { NgUnsubscribe } from '@util/base-class/ng-unsubscribe.class';

@Component({
  selector: 'auk-build-info',
  templateUrl: 'build-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuildInfoComponent extends NgUnsubscribe implements OnInit, AfterViewInit {

  @HostBinding('class.hidden') private hideVersion: boolean;

  protected versionBE: string;
  protected versionFE: string;

  constructor(
    private readonly buildInfoService: BuildInfoService,
    private readonly shareDataService: ShareDataService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.hideVersion = PlatformCommonService.isProductionMode;
    this.versionFE = this.buildInfoService.version;

    this.shareDataService.getVersion()
      .pipe(
        take(1),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((version: VersionDto) => {
        this.versionBE = version && version.versionImpl;
        this.changeDetectorRef.detectChanges();
      });
  }

  public ngAfterViewInit(): void {
    this.changeDetectorRef.detach();
  }

}
