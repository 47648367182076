import { Injectable } from '@angular/core';
import { DefaultMessageSourceResolvable } from '@api/generated/defs/DefaultMessageSourceResolvable';
import { FieldError } from '@api/generated/defs/FieldError';
import { HttpError } from '@shared/rest/model/http-error';
import { ValidationMessagesWithArguments } from './validation-messages-with-arguments';
import { BA_VALIDATION_MESSAGES_WITH_ARGUMENTS, VALIDATION_MESSAGES } from './bank-account/bank-accounts.helper';
import { ArrayUtils } from '@util/util/array.utils';
import { ErrorCodeMessage } from './error-code-message.interface';
import isNil from 'lodash-es/isNil';
import { Nil } from '@util/helper-types/nil';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {

  public static isAccessDenied(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.security.access_denied');
  }

  public static isNotFulfilledPrerequisitesError(error: HttpError): boolean {
    return ArrayUtils.isNotEmpty(error?.prerequisites);
  }

  public userDoesntExists(error: HttpError): boolean {
    return ArrayUtils.isNotEmpty(error?.body?.errors) && error.body.errors.some((e: DefaultMessageSourceResolvable) =>
      e?.code === 'user.doesnt.exist',
    );
  }

  public crossDomainLoginDisallow(error: HttpError): boolean {
    return ArrayUtils.isNotEmpty(error?.body?.errors) && error.body.errors.some((e: DefaultMessageSourceResolvable) =>
      e?.code === 'validation.security.cross_domain.access_denied',
    );
  }

  public userRegistrationExists(error: HttpError): boolean {
    return (
      ArrayUtils.isNotEmpty(error?.body?.errors) && error.body.errors.some(e => e?.code === 'user.registration.exists') ||
      ArrayUtils.isNotEmpty(error?.body?.fields) && error.body.fields.some(e => e?.code === 'validation.email.used')
    );
  }

  public isEmailDisallowed(error: HttpError): boolean {
    return ArrayUtils.isNotEmpty(error?.body?.errors) && error.body.errors.some((e: DefaultMessageSourceResolvable) =>
      e?.code === 'email.is.not.allowed',
    );
  }

  public facebookAuthorizeFailed(error: HttpError): boolean {
    return (error as unknown as string === 'User cancelled login or did not fully authorize.');
  }

  public userNotRegisteredViaFB(error: HttpError): boolean {
    return error?.body?.errors?.some((e: DefaultMessageSourceResolvable) => (
      e?.code === 'user.not.registered.with.FACEBOOK'),
    ) ?? false;
  }

  public isUserTerminated(error: HttpError): boolean {
    // todo: stop using MessageSourceResolvable on BE (in typings)
    return error?.body?.errors?.some((e: DefaultMessageSourceResolvable) => e?.code === 'user.account.terminated') ?? false;
  }

  public hasPageableOffsetError(error: HttpError): boolean {
    return error?.body?.fields?.some((e: FieldError) => e?.field === 'page' && e?.code === 'validation.pageable.offset.limit');
  }

  public hasItemSearchLengthError(error: HttpError): boolean {
    return error?.body?.fields?.some((e: FieldError) => e?.field === 'text' && e?.code === 'validation.Length');
  }

  public hasPostCodeError(error: HttpError): boolean {
    return error.body?.fields?.some((e: FieldError) =>
      e?.field === 'postCode' && (e?.code === 'postCode.notExists' || e?.code === 'postCode.invalidFormat'));
  }

  public hasBidExpiredItemError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e.code === 'validation.make.bid.expired.item');
  }

  public hasCurrencyMishmashError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.currency.mishmash.conflict');
  }

  public hasBidInactiveItemError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.make.bid.inactive.item');
  }

  public hasBidOnOwnItem(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.make.bid.own.item');
  }

  public hasNoSuccessfulAdmissionError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.make.bid.items.collection.admission') ||
      error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.make.bid.private.item.admission');
  }

  public hasBidPrecisionError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.make.bid.bad.precision');
  }

  public isSellerOutsideEuRestrictionError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.create.offer.outside.eu');
  }

  public isBalanceLimitExceededRestrictionError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.create.offer.balance.limit.exceeded');
  }

  public isBlockSellRestrictionError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.create.offer.block.sell');
  }

  public hasUnreadableImage(error: HttpError | Nil): boolean {
    return error?.body?.fields?.some((e: FieldError | Nil) => e?.code === 'images.unreadableFile') ?? false;
  }

  public static isBankAccountNumberInvalid(error: HttpError | Nil): boolean {
    return error?.body?.fields?.some((e: FieldError | Nil) => e?.code === 'validation.bankAccountNumber.invalid') ?? false;
  }

  public static isIbanInvalid(error: HttpError | Nil): boolean {
    return error?.body?.fields?.some((e: FieldError | Nil) => e?.code === 'validation.iban.invalid') ?? false;
  }

  public isPasswordChangeRequired(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) =>
      ['validation.user.pass.changeRequired', 'validation.user.pass.changeRequiredMigration'].includes(e?.code));
  }

  public isCrossDomainNotAllowed(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) =>
      ['validation.security.cross_domain.access_denied'].includes(e?.code));
  }

  public hasMessageContainsEmailAddressError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'message.cant.contain.email.address');
  }

  public hasBlockedBySellerError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.make.bid.blacklisted');
  }

  public hasNotAllowedToBuyError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.make.bid.allowed.to.buy');
  }

  public hasMessageContainsPhoneNumberError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'message.cant.contain.phone.number');
  }

  public accountBlockedError(error: HttpError): ErrorCodeMessage {
    const errorCode = 'user.account.blocked';
    const hasError = ArrayUtils.isNotEmpty(error?.body?.errors) && error.body.errors.some((e: FieldError) => e.code === errorCode);
    const errorMessage = hasError ? error.body.errors.find((e: FieldError) => e.code === errorCode).defaultMessage : null;
    return { hasError, errorCode, errorMessage };
  }

  public hasUserNotMatchError(error: HttpError): boolean {
    return error.body.errors && error.body.errors.some((e: FieldError) => e.code === 'validation.message.user.not.match');
  }

  public bankAccountValidationError(error: HttpError): string {
    const errorMessage: DefaultMessageSourceResolvable = error.body.errors && error.body.errors
      .find((e: DefaultMessageSourceResolvable) => Object.keys(VALIDATION_MESSAGES).includes(e.defaultMessage));
    if (errorMessage) {
      return VALIDATION_MESSAGES[errorMessage.defaultMessage] as string;
    } else {
      const errorMessageWithArgs: DefaultMessageSourceResolvable = error.body.errors && error.body.errors
        .find((e: DefaultMessageSourceResolvable) => Object.keys(BA_VALIDATION_MESSAGES_WITH_ARGUMENTS).includes(e.code));
      if (errorMessageWithArgs) {
        return ErrorsService.resolveValidationMessageWithArguments(
          BA_VALIDATION_MESSAGES_WITH_ARGUMENTS,
          errorMessageWithArgs.code,
          [errorMessageWithArgs.arguments.join(', ')],
        );
      }
    }
    return null;
  }

  public passContainsLogin(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.pass.contains.login');
  }

  public passContainsFirstName(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.pass.contains.firstName');
  }

  public passContainsLastName(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.pass.contains.lastName');
  }

  public passContainsCompany(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.pass.contains.company');
  }

  public passMatchOld(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.password.matchOld');
  }

  public passHasError(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.field === 'pass');
  }

  public isLoginOrEmailMissing(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'email.missing');
  }

  public isLoginOrEmailInvalid(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'email.badFormat');
  }

  public isEmailNotFound(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'user.email');
  }

  public isLoginNotFound(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'user.login');
  }

  public isLoginLengthExceeded(httpError: HttpError): boolean {
    return httpError?.body?.errors?.some((e: FieldError) => e?.code === 'login.maxLength');
  }

  public isConfirmEmailAlreadyConfirmed(httpError: HttpError): boolean {
    return !isNil(this.getConfirmEmailAlreadyConfirmed(httpError));
  }

  public getConfirmEmailAlreadyConfirmedMessage(httpError: HttpError): string | Nil {
    return this.getConfirmEmailAlreadyConfirmed(httpError)?.defaultMessage;
  }

  private getConfirmEmailAlreadyConfirmed(httpError: HttpError): FieldError | Nil {
    return httpError?.body?.errors?.find((e: FieldError) => e?.code === 'confirm.email.already.confirmed');
  }

  public isConfirmEmailTokenExpired(httpError: HttpError): boolean {
    return !isNil(this.getConfirmEmailTokenExpired(httpError));
  }

  public getConfirmEmailTokenExpiredMessage(httpError: HttpError): string | Nil {
    return this.getConfirmEmailTokenExpired(httpError)?.defaultMessage;
  }

  private getConfirmEmailTokenExpired(httpError: HttpError): FieldError | Nil {
    return httpError?.body?.errors?.find((e: FieldError) => e?.code === 'confirm.email.token.expired');
  }

  /**
   * Returns validation message filled with given arguments.
   * @param messages Validation messages
   * @param code Error code
   * @param args Array of arguments
   * @returns message
   * @example
   * ``` js
   * const MESSAGES: ValidationMessageWithArguments []= ['validation.code': (a: string[]) => `Message with arguments: {$a[0]} and {$a[1]}`];
   * const result: string = resolveValidationMessageWithArguments(MESSAGES, 'validation.code', ['argument 1', 'argument 2']);
   * // result – Message with arguments: argument 1 and argument 2
   * ```
   */
  private static resolveValidationMessageWithArguments(
    messages: ValidationMessagesWithArguments,
    code: string,
    args: string[],
  ): string {
    return messages[code](args);
  }

  /**
   * Gets localized error messages directly form error returned as HTTP response
   * Do not use with endpoints which do not return localized error messages!
   * @param httpError - HTTP error
   * @returns - error message
   */
  public getLocalizedMessageFromHttpError(httpError: HttpError): string {
    return httpError?.body?.errorMessage;
  }

  public bargainingExists(error: HttpError): boolean {
    return error?.body?.errors?.some((e: FieldError) => e?.code === 'validation.bargaining.exists');
  }

  public static alreadyExistsError(error: HttpError): boolean {
    return error?.body?.message === 'Already exists';
  }

}
