import { Directive, Host, Input, OnChanges } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AukSimpleChanges } from '@util/helper-types/simple-changes';
import { RoutingService } from '@common/routing/service/routing.service';

/**
 * Use with Angular's routerLink directive, if you want open link in _blank page (it automatically handles native app routing),
 * where we always want _self navigation and not _blank
 */
@Directive({
  // we need to match Angular's {@link RouterLink} directive selector
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[routerLink]a',
  standalone: true,
})
export class RouterLinkTargetDirective implements OnChanges {

  /**
   * @see {@link RoutingService#resolveTargetAttr}
   */
  @Input() public openInNewTab: boolean = false;

  constructor(
    @Host() private readonly routerLink: RouterLink,
    private readonly routingService: RoutingService,
  ) {
  }

  public ngOnChanges(changes: AukSimpleChanges<typeof this>): void {
    if (changes.openInNewTab) {
      // set Angular's router link directive target
      this.routerLink.target = this.routingService.resolveTargetAttr(this.openInNewTab);
    }
  }

}
