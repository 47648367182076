<div
  cdkScrollable
  class="tw-max-w-[87.5rem] tw-p-8 tw-bg-contrast tw-border tw-shadow-2xl tw-rounded-b-2xl tw-border-solid tw-border-surface-4 tw-grid tw-grid-cols-[repeat(auto-fill,minmax(18.75rem,1fr))] tw-items-start tw-content-start tw-gap-8 tw-justify-center lg:tw-justify-start lg:tw-mx-4"
  >
  @for (canvasItem of canvasMenu.items; track $any(trackByOptionIdFn)($index, canvasItem)) {
    <div class="tw-flex tw-min-w-[14.5rem] tw-gap-4 tw-items-start">
      <a [aukLinkFromHref]="canvasItem.url"
        [enableInAppNavigation]="false"
        (click)="onItemClick($event, canvasItem)"
        class="tw-w-12">
        @if (canvasItem.image) {
          <img
            class="tw-w-full"
            [src]="canvasItem.image"
            [alt]="canvasItem.label | translateSource"
            />
        }
      </a>
      <div class="tw-flex tw-items-start tw-gap-1 tw-flex-col">
        <a
          class="tw-text-sm tw-font-bold hover:tw-decoration-matter-accent-primary !tw-text-contrast-opposite"
          (click)="onItemClick($event, canvasItem)"
          [aukLinkFromHref]="canvasItem.url"
          [enableInAppNavigation]="false"
          >
          {{ canvasItem.label | translateSource }}
        </a>
        @for (item of canvasItem.items; track $any(trackByOptionIdFn)($index, item)) {
          <a
            class="tw-text-sm hover:tw-decoration-matter-accent-primary !tw-text-contrast !tw-text-contrast-opposite"
            (click)="onItemClick($event, $any(item))"
            [aukLinkFromHref]="canvasItem.url"
            [enableInAppNavigation]="false"
            >
            {{ item.label | translateSource }}
          </a>
        }
      </div>
    </div>
  }
</div>
