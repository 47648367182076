@if (item) {
  <a
    aukTestIdentification="basic-item-card"
    class="tw-group item-card"
    [routerLink]="item.link"
    [state]="{ itemsIteratorActive: true }"
    [attr.id]="itemIdAttr"
    [ngClass]="itemCardClasses"
    (click)="onItemClick()"
    [class.horizontal]="isHorizontalView"
  >
    <!--  ACTIVE STATE OVERLAY -->
    <div class="item-card-active-overlay"></div>
    <!--  ./ACTIVE STATE OVERLAY -->
    <!--  CORNER MARKING  -->
    @if (item.markingInfo) {
      <auk-item-card-corner-mark
        class="item-card-corner-mark"
        [colorCombination]="item.markingInfo.colorCombination"
        [cornerMarkIcon]="item.markingInfo.icon"
      />
    }
    <!--  ./CORNER MARKING  -->
    <div class="item-card-image-wrapper" [class.horizontal]="isHorizontalView">
      <!-- MAIN IMAGE -->
      <auk-item-card-image
        [isFreeform]="isHorizontalView"
        [itemCardImage]="item.image"
        [lazyLoadImage]="lazyLoadImage"
      />
      <!-- ./MAIN IMAGE -->
      <!-- IMAGE ACTION -->
      @if (canShowFollowersInfo()) {
        <auk-item-card-followers
          class="item-card-followers-action"
          [followersInfo]="item.followersInfo"
          (followClick)="onFollowClick()"
        />
      }
      <!-- ./IMAGE ACTION -->
      <!-- CHIP - BELOW IMAGE -->
      @if (isVerticalView && item.chips) {
        @for (chip of item.chips; track trackByIndexFn($index)) {
          <auk-chip
            class="item-card-chip"
            [chipInfo]="chip"
            [colorCombination]="chip.colorCombination"
          />
        }
      }
      <!-- ./CHIP - BELOW IMAGE -->
    </div>
    <div class="item-card-body-wrapper" [class.tw-text-matter-neutral-secondary]="item.hasEnded">
      <div class="item-card-body-wrapper-top-container">
        <!-- ABOVE TITLE LABELS -->
        @if (item.aboveTitleLabelsList?.length > 0) {
          <div class="tw-flex tw-flex-wrap tw-gap-2">
            @for (label of item.aboveTitleLabelsList; track trackByIndexFn($index)) {
              <auk-label [label]="label" size="SMALL"/>
            }
          </div>
        }
        <!-- ./ABOVE TITLE LABELS -->
        <!-- TITLE -->
        <auk-item-card-title class="lg:group-hover:tw-underline" [titleModel]="titleModel"/>
        <!-- ./TITLE -->
        <!-- TITLE LABELS SPACE HELPER -->
        @if (!isHorizontalView && !item.aboveTitleLabelsList?.length) {
          <div
            class="tw-h-4 lg:tw-block"
            [class.tw-hidden]="titleModel.dynamicHeightOnMdAndLower"
          ></div>
        }
        <!-- ./TITLE LABELS SPACE HELPER -->
        <!-- CHIP - BELOW TITLE -->
        @if (isHorizontalView && item.chips?.length) {
          <div class="tw-flex tw-flex-wrap tw-gap-1">
            @for (chip of item.chips; track trackByIndexFn($index)) {
              <auk-chip
                [chipInfo]="chip"
                [colorCombination]="chip.colorCombination"
              />
            }
          </div>
        }
        <!-- ./CHIP - BELOW TITLE -->
      </div>
      @if (item.sortScores?.length) {
        <div>
          <span>[{{ item.sortScores.join(', ') }}]</span>
        </div>
      }
      <!-- INFO ROWS -->
      @if (item.infoRowsList?.length) {
        <div class="item-card-body-wrapper-inforow-container">
          @for (infoRow of item.infoRowsList; track trackByIndexFn($index)) {
            <div
              class="item-card-body-wrapper-inforow"
              [class.!tw-text-xs]="isSmallVersionCard"
            >
              <span class="item-card-body-wrapper-inforow-label">
                @if (infoRow.label) {
                  <span
                    [matTooltip]="infoRow.type === 'PRICE' ? (infoRow.label | translateSource) : null"
                    matTooltipClass="tooltip-lg"
                    matTooltipPosition="above"
                    [matTooltipShowDelay]="250"
                  >
                    {{ infoRow.label | translateSource }}
                  </span>
                }
              </span>
              <div class="tw-font-medium tw-max-w-[calc(100%_-_6ch)]">
                @switch (infoRow.type) {
                  <!-- TEXT -->
                  @case ('TEXT') {
                    @if (getTextInfoRow(infoRow); as textInfoRow) {
                      <span>
                        {{ textInfoRow.value | translateSource }}
                      </span>
                    }
                  }
                  <!-- ./TEXT -->
                    <!-- MAIN PRICE -->
                  @case ('PRICE') {
                    @if (getPriceInfoRow(infoRow); as priceInfoRow) {
                      <auk-item-card-price
                        [priceModel]="priceInfoRow.priceItemData"
                        [priceSize]="getTextSize"
                        [itemHasEnded]="item.hasEnded"
                      />
                    }
                  }
                  <!-- ./MAIN PRICE -->
                    <!-- CURRENCY -->
                  @case ('CURRENCY') {
                    @if (getCurrencyInfoRow(infoRow); as currencyInfoRow) {
                      <span>
                        {{ currencyInfoRow.price | currency }}
                      </span>
                    }
                  }
                  <!-- ./CURRENCY -->
                    <!-- RETAIL PRICE -->
                  @case ('RETAIL_PRICE') {
                    @if (getRetailPriceInfoRow(infoRow); as retailPriceInfoRow) {
                      <auk-retail-price
                        [retailPrice]="retailPriceInfoRow.retailPriceItemData"
                      />
                    }
                  }
                  <!-- ./RETAIL PRICE -->
                    <!-- COUNTDOWN -->
                  @case ('COUNTDOWN') {
                    @if (getCountdownInfoRow(infoRow); as countdownInfoRow) {
                      <auk-item-card-timer
                        [timeToEnd]="countdownInfoRow.timeToEnd"
                        [itemHasEnded]="item.hasEnded"
                        (countdownEnd)="onCountdownEnd()"
                      />
                    }
                  }
                  <!-- ./COUNTDOWN -->
                }
              </div>
            </div>
          }
        </div>
      }
      <!-- ./INFO ROWS -->
      <!-- ACTION BUTTON -->
      @if (item.actionButton) {
        <auk-button size="SM" colorCombination="PRIMARY_CONTRAST_FILLED">
          {{ item.actionButton.label | translateSource }}
        </auk-button>
      }
      <!-- ./ACTION BUTTON -->
    </div>
  </a>
}
