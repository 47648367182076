<h2>{{ 'UNPAID_ALERT_COMPONENT_HTML_1' | translate }}</h2>

<p class="m-t-2">
  {{ 'UNPAID_ALERT_COMPONENT_HTML_2' | translate }}
  <a (click)="close()"
     [routerLink]="'/moje-aukro/muj-ucet/danove-doklady'">
    {{ 'UNPAID_ALERT_COMPONENT_HTML_3' | translate }}
  </a>
  {{ 'UNPAID_ALERT_COMPONENT_HTML_4' | translate }}
  <a aukLinkFromHref="https://napoveda.aukro.cz/node/217" [openInNewTab]="true">{{ 'UNPAID_ALERT_COMPONENT_HTML_5' | translate }}</a>.
</p>

<div class="flex-center column-max-tablet m-t-3">
  <a (click)="close()" class="btn-secondary fluid-max-tablet">
    {{ 'UNPAID_ALERT_COMPONENT_HTML_6' | translate }}
  </a>
  <a (click)="close()"
     [routerLink]="'/moje-aukro/muj-ucet/aktualni-zustatek'"
     class="btn-primary m-l-3-min-tablet m-t-2-max-tablet fluid-max-tablet">
    {{ 'UNPAID_ALERT_COMPONENT_HTML_7' | translate }}
  </a>
</div>
