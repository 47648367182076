import { Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogChangeAvatarComponent } from './dialog-images/dialog-change-avatar.component';
import { Observable, switchMap } from 'rxjs';
import { HttpContext } from '@angular/common/http';
import { USER_ACTION_TYPE } from '@shared/user-action/token/user-action-type-http-context.token';
import { map, take } from 'rxjs/operators';
import { UserMeService } from '@api/generated/api/UserMe';
import { AukMatDialogService } from '@shared/dialog/service/auk-mat-dialog-service';

@Injectable({
  providedIn: 'root',
})
export class AvatarService {

  constructor(
    private readonly aukMatDialogService: AukMatDialogService,
    private readonly userMeService: UserMeService,
  ) {
  }

  /**
   * Checks if user can edit avatar.
   *
   * If user cannot edit avatar (cancels popup flow, or BE returns error) it will throw error
   *
   * If user can edit avatar, opens change avatar dialog and returns Observable<true> after dialog is closed
   */
  public openEditAvatarDialogIfCanEdit$(): Observable<boolean> {
    return this.checkIfCanEditAvatar$()
      .pipe(
        switchMap(() => this.openChangeAvatarDialog$()),
        switchMap((dialog) => dialog.afterClosed()),
        take(1),
        map(() => true),
      );
  }

  /**
   * Opens dialog which allows user's avatar changes
   * @private
   */
  private openChangeAvatarDialog$(): Observable<MatDialogRef<DialogChangeAvatarComponent>> {
    return this.aukMatDialogService.openSimple$(
      DialogChangeAvatarComponent,
      {
        panelClass: ['dialog-no-padding', 'dialog-scroll'],
        disableClose: true,
        width: '700px',
        maxWidth: '700px',
        closeOnNavigation: true,
      },
    );
  }

  /**
   * Checks if user can edit avatar
   * @private
   */
  private checkIfCanEditAvatar$(): Observable<boolean> {
    const httpContext = new HttpContext()
      .set(USER_ACTION_TYPE, 'UPLOAD_AVATAR');

    return this.userMeService.checkRestrictions(
      {
        userCheckRestrictionsDto: {
          actionType: 'UPLOAD_AVATAR',
        },
      },
      httpContext,
    )
      .pipe(
        take(1),
        map(() => true),
      );
  }

}
