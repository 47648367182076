<!-- Negative margins should be removed right after old navbar is removed, right now its essential to show margins correctly. -->
<div
  #horizontalHeaderNavBar
  class="hide-mobile-scrollbar tw-flex tw-h-12 tw-w-full tw-max-w-[1400px] tw-mx-auto tw-overflow-x-auto tw-justify-between tw-mt-4 lg:tw-gap-2 lg:!tw-mb-0 lg:!tw-mt-0 lg:tw-px-4 md:tw-overflow-hidden"
  >
  <!-- MD AND LG -->
  @if (!isMdAndLower) {
    <!-- TABS LEFT PART -->
    <auk-tab-menu-container
      [allowTrimTabs]="true"
      class="tw-w-full md:tw-overflow-x-auto"
      (tabClick)="onTabClick($event)"
      (tabHover)="onTabHover($event)"
      (tabHoverEnd)="onTabHoverEnd()"
      [tabs]="itemData?.tabGroupLeft"
      />
    <!-- TABS LEFT PART -->
    <!-- TABS RIGHT PART -->
    <auk-tab-menu-container
      (tabClick)="onTabClick($event)"
      [tabs]="itemData?.tabGroupRight"
      [disableLeftBorder]="isLgAndLower"
      />
    <!-- TABS RIGHT PART -->
  }

  <!-- SM -->
  @if (isMdAndLower) {
    <!-- TABS COMBINED -->
    <auk-tab-menu-container
      (tabClick)="onTabClick($event)"
      (tabHover)="onTabHover($event)"
      (tabHoverEnd)="onTabHoverEnd()"
      [tabs]="combinedTabsForSmAndMd"
      />
    <!-- TABS COMBINED -->
  }
</div>
