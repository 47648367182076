@for (holder of inputIndexes; track holder; let i = $index) {
  <input
    class="m-r-2"
    aukTestIdentification="verification-code-input"
    [class.m-l-2]="!inline"
    (input)="onInput($event, i)"
    (keydown.backspace)="onBackspace(i)"
    (focus)="onInputFocused(i)"
    [attr.inputmode]="onlyDigits ? 'numeric' : null"
    autocomplete="one-time-code"
    #inputElement
    >
}
