import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import isNil from 'lodash-es/isNil';
import { HttpRequestCloneConfig } from '@shared/rest/model/http-request-clone-config';
import { SubbrandSessionDataService } from '@shared/subbrand/repository/subbrand-session-data.service';
import { ActivatedRoute } from '@angular/router';
import { SubbrandType } from '@shared/subbrand/model/subbrand.type';
import { SubbrandQueryParams } from '@shared/subbrand/util/subbrand-query-params';
import { WITH_CREDENTIALS } from '@shared/rest/token/with-credentials-http-context.token';
import { RequestToIgnoreModel } from '@shared/subbrand/model/request-to-ignore.model';

/**
 * Adds subbrand to request headers.
 */
@Injectable()
export class SubbrandInterceptor implements HttpInterceptor {

  /**
   * Request header indicating from witch subbrand request originated. BE responses may differ for different subbrands.
   */
  public static readonly SUBBRAND_HEADER_NAME: string = 'X-Accept-Subbrand';
  /**
   * List of EPs for which "X-Accept-Subbrand" request header won't be added to request if current URL have query
   * params containing "ignoreSubbrand=true".
   */
  private static readonly REQUEST_ROUTES_TO_IGNORE: string[] = ['/searchItemsCommon'];
  /**
   * List of EP URLs and body definitions for which "X-Accept-Subbrand" request header won't be added to request
   * even if current URL doesn't have query params containing "ignoreSubbrand" (unless it contains "subbrand").
   * E.g. if the EP is /searchItemsCommon and the body contains property specialFlagCharityOffers=true, the subbrand should be ignored
   * even if the url doesn't specify that explicitly. However, if the same endpoint would be called and current URL contains "subbrand"
   * query param with a valid value (BAZAAR or ANTIQUE), the "X-Accept-Subbrand" request header will be added to request
   * (it's forced by query param).
   */
  private static readonly REQUESTS_TO_FORCE_IGNORE: RequestToIgnoreModel[] = [{
    url: '/searchItemsCommon',
    bodyProperty: { key: 'specialFlagCharityOffers', value: true },
  }];

  constructor(private readonly subbrandSessionDataService: SubbrandSessionDataService,
              private readonly route: ActivatedRoute) {
  }

  public intercept(request: HttpRequest<string>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const queryParams = this.route.snapshot.queryParams as SubbrandQueryParams;
    const subbrandQueryParam: SubbrandType = queryParams?.subbrand?.toUpperCase() as SubbrandType;
    const isSubbrandForcedByQueryParam: boolean = !isNil(subbrandQueryParam);

    const headerOrSessionSubbrand = isSubbrandForcedByQueryParam ?
      //  Query param subbrand has priority over stored subbrand
      subbrandQueryParam :
      // Stored session subbrand (cookie, user pref.)
      this.subbrandSessionDataService.sessionSubbrand;

    if (this.skipAddingHeader(isSubbrandForcedByQueryParam, headerOrSessionSubbrand, queryParams.ignoreSubbrand, request)) {
      return next.handle(request);
    }

    const requestCloneConfig: HttpRequestCloneConfig = {
      withCredentials: request.context.has(WITH_CREDENTIALS) ? request.context.get(WITH_CREDENTIALS) : true,
    };

    requestCloneConfig.headers = request.headers.set(SubbrandInterceptor.SUBBRAND_HEADER_NAME, headerOrSessionSubbrand);

    return next.handle(request.clone(requestCloneConfig));
  }

  private skipAddingHeader(
    isSubbrandForcedByQueryParam: boolean,
    headerOrSessionSubbrand: SubbrandType,
    ignoreSubbrand: boolean,
    request: HttpRequest<unknown>,
  ): boolean {
    return isNil(headerOrSessionSubbrand) ||
      this.skipAddingHeaderBecauseIgnoreSubbrandQueryParam(ignoreSubbrand, request) ||
      this.skipAddingHeaderBecauseRequestDefinition(isSubbrandForcedByQueryParam, request);
  }

  private skipAddingHeaderBecauseIgnoreSubbrandQueryParam(ignoreSubbrand: boolean, request: HttpRequest<unknown>): boolean {
    return ignoreSubbrand && SubbrandInterceptor.REQUEST_ROUTES_TO_IGNORE.some(route => request.url.endsWith(route));
  }

  private skipAddingHeaderBecauseRequestDefinition(isSubbrandForcedByQueryParam: boolean, request: HttpRequest<unknown>): boolean {
    return !isSubbrandForcedByQueryParam
      && SubbrandInterceptor.REQUESTS_TO_FORCE_IGNORE.some(r => request.url.endsWith(r.url)
      && Object.prototype.hasOwnProperty.call(request.body, r.bodyProperty.key)
      && request.body[r.bodyProperty.key] === r.bodyProperty.value);
  }

}
