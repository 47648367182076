<auk-dialog-content
    [dialogTitle]="payload.title"
>
  <div content [aukInnerHtml]="bodyTranslationSource"></div>

  <ng-container actions>
    <auk-personal-data-step-close-or-set-password-button
        class="tw-w-full"
        [closeButtonText]="payload.closeButtonText"
        [notNowButtonText]="payload.notNowButtonText"
        [setPasswordButtonText]="payload.setPasswordButtonText"
        [setPasswordInfoText]="payload.setPasswordInfoText"
        (closeClick)="onCloseButtonClick()"
        (setPasswordClick)="onSetPasswordButtonClick()"
    ></auk-personal-data-step-close-or-set-password-button>
  </ng-container>
</auk-dialog-content>
