import { Injectable } from '@angular/core';
import { UserActionPrerequisiteTaskFulfillmentService } from '../task-fulfillment/user-action-prerequisite-task-fulfillment.service';
import { UserTaskService } from '@shared/user-task/base/service/user-task.service';
import { UserActionPrerequisiteType } from '../../model/user-action-prerequisite.type';
import { UserTaskType } from '@shared/user-task/base/model/user-task.type';
import { UserActionType } from '../../model/user-action.type';
import { Observable } from 'rxjs';
import { UserTaskPayloadModel } from '@shared/user-task/base/model/user-task-payload.model';

@Injectable({
  providedIn: 'root',
})
export abstract class UserActionPrerequisitePersonalDataFulfillmentService
  <PREREQUISITE_COMBINATION extends UserActionPrerequisiteType[]>
  extends UserActionPrerequisiteTaskFulfillmentService<PREREQUISITE_COMBINATION> {

  protected readonly taskType: UserTaskType = 'PERSONAL_DATA';

  protected abstract readonly customTaskPayload: Partial<UserTaskPayloadModel<typeof this.taskType>>;

  protected constructor(
    protected override userTaskService: UserTaskService,
  ) {
    super(userTaskService);
  }

  public override fulfillPrerequisite(userAction: UserActionType): Observable<void> {
    return this.userTaskService.executeTaskWithCommonAndCustomPayload$(
      this.taskType,
      { action: userAction },
      this.customTaskPayload,
      userAction,
    );
  }

}
