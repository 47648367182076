import { OfferUrlObject } from '@shared/util/product/model/offer-url-object';
import { DateUtils } from '@util/util/date.utils';
import isNil from 'lodash-es/isNil';
import { FavouriteOffersTimeRange } from '@shared/model/favourite-offers-time-range';
import { OfferExposePreview } from '@shared/model/offer-expose-preview';
import { OfferDetailDto, OfferDetailDtoItemTypeEnum } from '@api/aukro-api/model/offer-detail-dto';

export class ProductUtil {

  public static possibleTabs: string[] = [
    'course-description',
    'shipping-and-payment',
    'info-from-seller',
  ];

  public static defaultTab: string = ProductUtil.possibleTabs[0];

  public static getOfferUrlObject(seoUrl: string): OfferUrlObject {

    if (seoUrl.charAt(0) === '/') {
      seoUrl = seoUrl.substring(1);
    }

    seoUrl = seoUrl.split('#')[0];
    seoUrl = seoUrl.split('?')[0];

    const execRes: string[] = (/^(.+)-(i?)([0-9]{10,})(\.html)?$/i).exec(seoUrl);
    if (!execRes || !execRes.length) {
      return null;
    }

    return {
      hasHtmlExtension: !!execRes[4],
      hasIChar: !!execRes[2],
      id: +execRes[3],
      seoUrl: execRes[1],
    };
  }

  public static isRouterSeoUrlMalformed(routerOfferUrlObject: OfferUrlObject, item: { seoUrl?: string; itemId?: number }): boolean {

    if (isNil(routerOfferUrlObject)) {
      console.warn('Cannot determine malformed url, null router offer url object provided');
      return true;
    }

    if (routerOfferUrlObject.hasIChar || routerOfferUrlObject.hasHtmlExtension) {
      return true;
    }

    if (isNil(item)) {
      console.warn(`Cannot determine malformed url, null item object for comparison provided`);
      return false;
    }

    if (routerOfferUrlObject.id !== item.itemId) {
      console.warn(`Cannot determine malformed url, other item object for comparison provided`);
      return false;
    }

    return routerOfferUrlObject.seoUrl !== item.seoUrl;
  }

  public static linkToMaps(location: string, postcode: string): string {
    return `https://mapy.cz?q=${ encodeURIComponent(location + ', ' + postcode) }`;
  }

  public static itemYesterdayFilter(): FavouriteOffersTimeRange {
    return {
      originalExposeAfter: DateUtils.yesterday.from.format(),
      originalExposeBefore: DateUtils.yesterday.to.format(),
    };
  }

  public static previewToOfferDetail(offerExposePreview: OfferExposePreview): OfferDetailDto {
    if (isNil(offerExposePreview)) {
      return {};
    }

    return {
      itemId: offerExposePreview.itemId,
      name: offerExposePreview.itemName,
      price: offerExposePreview.price,
      category: [offerExposePreview.category, ...offerExposePreview.parentCategories],
      startingTime: offerExposePreview.startingTime,
      endingTime: offerExposePreview.endingTime,
      infiniteOrder: offerExposePreview.infiniteOrder,
      startingQuantity: offerExposePreview.startingQuantity,
      itemType: offerExposePreview.offerTypeCode as OfferDetailDtoItemTypeEnum,
      itemLocation: offerExposePreview.itemLocation,
      postCode: offerExposePreview.itemPostcode,
      attributes: offerExposePreview.parameters,
      descriptionInHtml: offerExposePreview.descriptionInHtml,
      newEditor: offerExposePreview.newEditor,
      freeDeliveryLimit: offerExposePreview.freeDeliveryDto?.limit,
      seoUrl: offerExposePreview.seoUrl,
      seller: {
        showName: offerExposePreview.sellerInfo?.userName,
      },
      offerPaymentOptions: {
        paymentViaAukro: offerExposePreview.paymentViaAukro,
        paymentOnline: offerExposePreview.paymentOnline,
        paymentOnDelivery: offerExposePreview.paymentOnDelivery,
        paymentBankTransfer: offerExposePreview.paymentBankTransfer,
      },
      shippingOptions: offerExposePreview.shippingOptions,
      itemImages: offerExposePreview.itemImages,
    };
  }

  public static getOfferUrl(urlObject: OfferUrlObject, idProp?: string): string[] {
    const { seoUrl, itemSeoUrl, itemId, id, itemReferenceId } = urlObject;

    const linkId = idProp
      ? (urlObject[idProp] as number)
      : itemReferenceId || itemId || id;

    const slug: string = seoUrl || itemSeoUrl;

    if (!slug) {
      console.info('There is no available SEO URL for offer ' + linkId.toString() + '.');
    }

    return ['/', `${ slug || 'nabidka' }-${ linkId || 0 }`];
  }

}
