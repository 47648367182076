import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { RestHttpClientService } from '@api/rest/rest-http-client.service';
import * as model from '../model';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
export type CurrencyEnumParams =
  'EUR' |
  'CZK';

/**
 * @deprecated Use generated API files in src/app/api/aukro-api instead
 */
@Injectable({
  providedIn: 'root',
})
export class CurrencyService extends RestHttpClientService {
  constructor(injector: Injector) { super(injector); }

  /**
   * @deprecated Use generated API files in src/app/api/aukro-api instead
   */
  public exchangeRates(currency: CurrencyEnumParams): Observable<model.ExchangeRatesDto> {
    let queryParams = {
      currency
    };
    return this.getGenerated(`/currency`, queryParams);
  }
}
