import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseActionViewComponent } from '../base-action-view/base-action-view.component';
import { TranslationSource } from '@common/translations/model/translation-source';

@Component({
  selector: 'auk-watch-no-email-verified-no-basic-data-view',
  templateUrl: './watch-no-email-verified-no-basic-data-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatchNoEmailVerifiedNoBasicDataViewComponent extends BaseActionViewComponent<'WATCH_NO_EMAIL_VERIFIED_NO_BASIC_DATA'> {

  public get bodyTranslationSource(): TranslationSource {
    return {
      ...this.data?.textContent?.body,
      params: {
        ...this.data?.textContent?.body.params,
        email: this.data?.additionalData?.email,
      },
    };
  }

  public onFillBasicData(): void {
    this.closeDialog('PERSONAL_DATA');
  }

}
