<div aukTestIdentification="mobile-filter-layout" class="tw-flex tw-flex-col tw-gap-y-2">
  <div class="tw-grid tw-gap-2 tw-grid-cols-[repeat(auto-fill,minmax(160px,1fr))]">
    @for (tile of visibleTiles; track tile) {
      <auk-mobile-box-layout
        class=""
        aukTestIdentification="mobile-filter-tile"
        [tile]="tile"
        [isCheckboxTile]="!isSingleSelection"
        (buttonClick)="onButtonClick(tile)"
        />
    }
  </div>

  @if (isToggledMoreSorting) {
    <div class="tw-grid tw-gap-2 tw-grid-cols-[repeat(auto-fill,minmax(160px,1fr))]">
      @for (tile of toggledTiles; track tile) {
        <auk-mobile-box-layout
          class=""
          [tile]="tile"
          aukTestIdentification="mobile-filter-tile"
          [isCheckboxTile]="!isSingleSelection"
          (buttonClick)="onButtonClick(tile)"
          />
      }
    </div>
  }

  @if (tileList && hasMoreThanMinimumThreshold()) {
    <auk-mobile-filters-show-more
      class="tw-py-2"
      aukTestIdentification="mobile-filter-tile"
      [itemsCount]="tileList.length"
      [isToggled]="isShowMoreToggled"
      (toggleChange)="onToggle($event)">
    </auk-mobile-filters-show-more>
  }
</div>
